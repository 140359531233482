import {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {fetchNotifications} from '../Redux/notifications/notificationsActions'

const useNotifications = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const getNotifications = () => {
      dispatch(fetchNotifications())
    }

    setTimeout(() => {
      getNotifications()
    }, 10000)

    const interval = setInterval(() => {
      getNotifications()
    }, 50000)
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [])

  return useSelector((state) => state.notifications, shallowEqual)
}

export default useNotifications
