import {SET_ALL_LANGUAGES, SET_LANGUAGE_ID_ACTIVE} from './languagesTypes'

const setLanguageIdActive = (language) => {
  return {
    type: SET_LANGUAGE_ID_ACTIVE,
    id: language?.id,
  }
}

const setAllLanguages = (
  languages,
  idioma_nativo,
  idioma_activo,
  interface_language,
) => {
  return {
    type: SET_ALL_LANGUAGES,
    languages,
    activeUserLanguage: idioma_activo,
    nativeUserLanguage: idioma_nativo,
    interfaceLanguage: interface_language,
  }
}

export {setAllLanguages, setLanguageIdActive}
