const OnboardingViewerStatus = {
  PASSWORD_SCREEN: 'PASSWORD_SCREEN',
  PERSONAL_SCREEN: 'PERSONAL_SCREEN',
  OBJECTIVE_SCREEN: 'OBJECTIVE_SCREEN',
  START_SUSCRIPTION_TRIAL: 'START_SUSCRIPTION_TRIAL',
  ARTIST_PREFERENCES: 'ARTIST_PREFERENCES',
  REGISTER_SCREEN: 'REGISTER_SCREEN', // SE OCUPA?
  VERIFICATION_SCREEN: 'VERIFICATION_SCREEN', // SE OCUPA?
}

const isOnboardingViewerPassword = (status) =>
  status === OnboardingViewerStatus.PASSWORD_SCREEN
const isOnboardingViewerPersonal = (status) =>
  status === OnboardingViewerStatus.PERSONAL_SCREEN
const isOnboardingViewerObjetive = (status) =>
  status === OnboardingViewerStatus.OBJECTIVE_SCREEN

const isOnboardingViewerStartTrial = (status) =>
  status === OnboardingViewerStatus.START_SUSCRIPTION_TRIAL
const isOnboardingViewerPreferences = (status) =>
  status === OnboardingViewerStatus.ARTIST_PREFERENCES

const PASSWORD = 'password'
const TEXT = 'text'
const EMAIL = 'email'
const DATE = 'date'

export {
  OnboardingViewerStatus,
  isOnboardingViewerPassword,
  isOnboardingViewerPersonal,
  isOnboardingViewerObjetive,
  isOnboardingViewerStartTrial,
  isOnboardingViewerPreferences,
  PASSWORD,
  TEXT,
  EMAIL,
  DATE,
}
