import React, {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import Modal from '../../Modal'
import InviteIcon from './Icons/Invite.svg'
import NotaAgregada from '../../Shared/Done'
import Invite from '../../Pages/welcome/invitations/Invite'

const InviteUser = () => {
  const [inviteModal, setInviteModal] = useState(false)
  const [done, setDone] = useState(false)
  return (
    <>
      <div id="font-icon-community" onClick={() => setInviteModal(true)}>
        <div id="link-community" className="d-flex align-items-center">
          <img src={InviteIcon} alt="" />
          <span>
            <FormattedMessage id={'topbar.invite'} defaultMessage={'Invitar'} />
          </span>
        </div>
      </div>

      {done && (
        <Modal modalType="nota-agregada" show={setDone}>
          <NotaAgregada
            message="Invitacion Enviada"
            show={setDone}
            invitation={setInviteModal}
          />
        </Modal>
      )}
      {inviteModal && (
        <Modal
          modalType="modal-delete-item"
          show={inviteModal}
          closeModal={() => setInviteModal(false)}
          closeRound
          closeClickOutside>
          <Invite successModal={setDone} show={setInviteModal} />
        </Modal>
      )}
    </>
  )
}

export default InviteUser
