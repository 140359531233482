import React from 'react'
import Loadingcoco from './Loadingcoco.gif'
import './LoadingCoco.css'

const LoadingCoco = ({width = '300px', className = ''}) => {
  return (
    <div className={`loading-coco ${className}`}>
      <img style={{width}} src={Loadingcoco} alt="loading" />
    </div>
  )
}

export default LoadingCoco
