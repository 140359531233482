export default {
  en: {
    'sharetrack.search-friend': 'Find friend...',
    'sharetrack.share-to-me': 'Share it to me',
    'sharetrack.next': 'Next',
    'sharetrack.send-message-optional': 'Send a message (optional).',
    'sharetrack.share': 'Share',
    'sharetrack.sharing': 'Sharing...',
    'sharetrack.shared-success': 'Song successfully shared.',
    'sharetrack.error-not-user': 'Error, no user has been selected yet.',
    'sharetrack.error-track-invalid': 'This track is not valid, select another.',
    'sharetrack.error-no-exist': 'The song does not exist',
    'sharetrack.link-copy': 'Copy link',
    'sharetrack.link-copy-success': 'Link successfully copied',
    'sharetrack.link-copy-error': 'Error copying link',
  },
  es: {
    'sharetrack.search-friend': 'Buscar amigo...',
    'sharetrack.share-to-me': 'Compartírmelo a mí',
    'sharetrack.next': 'Siguiente',
    'sharetrack.send-message-optional': 'Manda  un mensaje (opcional).',
    'sharetrack.share': 'Compartir',
    'sharetrack.sharing': 'Compartiendo...',
    'sharetrack.shared-success': 'Canción compartida con exito',
    'sharetrack.error-not-user': 'Error, Aun no hay seleccionado ningun usuario.',
    'sharetrack.error-track-invalid': 'Este track no es valido, seleccione otro.',
    'sharetrack.error-no-exist': 'La canción no existe',
    'sharetrack.link-copy': 'Copiar enlace',
    'sharetrack.link-copy-success': 'Enlace copiado con éxito',
    'sharetrack.link-copy-error': 'Error al copiar link',
  },

  fr: {
    'sharetrack.search-friend': 'Trouver un ami ...',
    'sharetrack.share-to-me': 'Partagez-le moi',
    'sharetrack.next': 'Suivant',
    'sharetrack.send-message-optional': 'Envoyez un message (facultatif).',
    'sharetrack.share': 'Partager',
    'sharetrack.sharing': 'Partage...',
    'sharetrack.shared-success': 'Chanson partagée avec succès',
    'sharetrack.error-not-user':
      'Erreur, aucun utilisateur n`a encore été sélectionné.',
    'sharetrack.error-track-invalid':
      'Cette piste n`est pas valide, sélectionnez-en une autre.',
    'sharetrack.error-no-exist': 'La chanson n`existe pas',
    'sharetrack.link-copy': 'Copier le lien',
    'sharetrack.link-copy-success': 'llien copié avec succès',
    'sharetrack.link-copy-error': 'Erreur lors de la copie du lien',
  },
}
