/*

	Okay. For Spotify Playback, we need a solution for loading external javascripts
	within a specific component (we can't just add a whole bunch of script tags to 
	public/index.html that aren't actually intended for global use). This is my
	solution...

*/

class ScriptLoader {
  constructor(scripts) {
    this.loaded = []
    this.failed = []
    this.pending = []
    this.load(scripts)
  }

  onLoad(success, reject) {
    /* 
			this method allows for actions to be called 
			when the script(s) are successfully/unsuccessfully
			loaded onn the page/DOM
		*/
  }

  load(scripts) {
    scripts.forEach((script) => {
      this.loadSrc(script)
    })
  }

  loadSrc(src) {
    if (this.loaded.indexOf(src) >= 0) {
      return Promise.resolve(src)
    }

    this.pending.push(src)
    return this.scriptTag(src)
      .then((src) => {
        // success
        //console.log(`Loaded script: ${src}`);
        //this.loaded.push(src)
      })
      .catch((error) => {
        // failure
        console.log(`Failed to load script: ${src}`)
        //this.failed.push(error)
      })
  }

  scriptTag(src, cb) {
    return new Promise((resolve, reject) => {
      let resolved = false,
        errored = false,
        body = document.getElementsByTagName('body')[0],
        tag = document.createElement('script')

      tag.type = 'text/javascript'
      tag.async = false // load scripts in order

      const handleCallback = (tag.onreadystatechange = function () {
        if (resolved) {
          return handleLoad()
        }
        if (errored) {
          return handleReject()
        }

        const state = tag.readyState
        if (state === 'complete') {
          handleLoad()
        } else if (state === 'error') {
          handleReject()
        }
      })

      const handleLoad = (evt) => {
        //console.log("handleLoad() called")
        resolved = true
        resolve(src)
      }

      const handleReject = (evt) => {
        //console.log("handleReject() called")
        errored = true
        reject(src)
      }

      if (src.match(/callback=CALLBACK/)) {
        src = src.replace(/(callback=)[^&]+/, `$1cb`)
        cb = window[cb] = handleLoad
      } else {
        tag.addEventListener('load', handleLoad)
      }

      tag.onreadystatechange = handleCallback
      tag.addEventListener('error', handleReject)
      tag.src = src
      body.appendChild(tag)

      return tag
    })
  }
}

export default ScriptLoader
