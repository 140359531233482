import React from 'react'
import {TypeDocuchats} from '../helpers'
import IconCloseDocuchat from './Icons/IconCloseDocuChat.svg'
import IconMaximizar from './Icons/maximizar.svg'
import IconMinimizar from './Icons/minimizar.svg'

const HeaderOptions = ({
  onClose,
  onClickMaximizar,
  type = TypeDocuchats.MINI_CHAT,
  isShowMaximizar = true,
}) => {
  return (
    <div className="contenedor-chat-close">
      <div className="options-chat-buttons">
        {isShowMaximizar && (
          <button className="button-chat-close" onClick={onClickMaximizar}>
            <img
              src={type === TypeDocuchats.MINI_CHAT ? IconMaximizar : IconMinimizar}
              alt="icon close"
            />
          </button>
        )}
        <button className="button-chat-close" onClick={onClose}>
          <img src={IconCloseDocuchat} alt="icon close" />
        </button>
      </div>
    </div>
  )
}

export default HeaderOptions
