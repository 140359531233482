import React from 'react'
import './SelectDomainsTopBar.css'
import useDomain from '../../hooks/useDomain'
import useAlert, {TOAST_TYPE} from '../../hooks/useAlert'
import {changeDomainId} from '../../lib/coco/languagesApi'
import {StatusApi} from '../../lib/coco/helperData'
import {useHistory} from 'react-router-dom'

const SelectDomainsTopBar = ({styles = {}}) => {
  const {domainOptions = [], isDomainActiveById} = useDomain()
  const history = useHistory()
  const {showMessage} = useAlert()

  // no borrar el codigo comentado, pueda ser que se use a futuro.
  // const haveTodosOthersDomains = (domain) => {
  //   const { items_por_atender = 0, flashcards_por_estudiar = 0 } = domain
  //   const mergeItems = items_por_atender + flashcards_por_estudiar
  //   return mergeItems
  // }

  const refreshApp = () => {
    history.go(0)
  }

  const selectDomainId = (domainId) => {
    changeDomainId(domainId).then((response) => {
      if (response.status === StatusApi.OK) {
        refreshApp()
      } else {
        showMessage(
          `${response.message}, no se pudo cambiar el dominio`,
          TOAST_TYPE.ERROR,
        )
      }
    })
  }

  const DomainItem = ({domain, isActive = false /*todos = false*/}) => {
    return (
      <div
        className={`language-item-top-bar ${
          isActive ? 'language-top-bar-active' : ''
        }`}
        onClick={() => !isActive && selectDomainId(domain.id)}>
        {/* {todos > 0 && <div className="badge-Language-item">{todos}</div>} solo esta comentado por si a futuro se usa*/}
        {/* {!isActive && <div className="badge-Language-item" />} */}
        <div className={`language-item-top-bar-title `} title={domain.name}>
          {domain.code}
        </div>
      </div>
    )
  }

  return (
    <div className="languages-top-bar" style={styles}>
      {domainOptions?.length > 0 &&
        domainOptions.map((domain) => (
          <DomainItem
            key={domain.id}
            domain={domain}
            isActive={isDomainActiveById(domain.id)}
          />
        ))}
    </div>
  )
}

export default SelectDomainsTopBar
