import React from 'react'
import './SplashScreen.css'
import CocoSpashAnimation from './Icons/coco_spash_animation.gif'
const SplashScreen = () => {
  return (
    <div className="splash-screen-main">
      <div className="splash-screen-contenido">
        <img
          className="splash-screen-logo"
          src={CocoSpashAnimation}
          alt="Logo coco"
        />
      </div>
    </div>
  )
}

export default SplashScreen
