import {
  FETCH_PLAYLISTS_REQUEST_START,
  FETCH_PLAYLISTS_REQUEST_FINISH,
  FETCH_PLAYLISTS_REQUEST_ERROR,
  FETCH_PLAYLIST_BY_ID_REQUEST_START,
  FETCH_PLAYLIST_BY_ID_REQUEST_FINISH,
  FETCH_PLAYLIST_BY_ID_REQUEST_ERROR,
  DELETE_TRACK_OF_PLAYLIST,
  ADD_PLAYLIST,
  ADD_ONE_TO_PLAYLIST,
  UPDATE_TRACKS_PLAYLIST_FROM_LIST,
} from './playlistTypes'
import {getPlaylists, getPlaylist} from '../../lib/coco/InputApi'

const startPlaylistRequest = () => {
  return {
    type: FETCH_PLAYLISTS_REQUEST_START,
    payload: {},
  }
}

const completePlaylistsRequest = (playlists) => {
  return {
    type: FETCH_PLAYLISTS_REQUEST_FINISH,
    payload: {
      myLike: playlists.myLike,
      playlists: playlists.playlists,
    },
  }
}

const errorPlaylistRequest = (error) => {
  return {
    type: FETCH_PLAYLISTS_REQUEST_ERROR,
    payload: {error},
  }
}

const getMyPlaylists = () => (dispatch) => {
  dispatch(startPlaylistRequest())

  return getPlaylists().then((response) => {
    if (response.message) {
      dispatch(errorPlaylistRequest(response))
    }
    dispatch(completePlaylistsRequest(response))
  })
}

const startPlaylistByIdRequest = () => {
  return {
    type: FETCH_PLAYLIST_BY_ID_REQUEST_START,
    payload: {},
  }
}

const completePlaylistBYIdRequest = (playlist) => {
  return {
    type: FETCH_PLAYLIST_BY_ID_REQUEST_FINISH,
    payload: {
      playlist,
    },
  }
}

const errorPlaylistByIdRequest = (error) => {
  return {
    type: FETCH_PLAYLIST_BY_ID_REQUEST_ERROR,
    payload: {error},
  }
}

const getPlaylistById = (id) => (dispatch) => {
  dispatch(startPlaylistByIdRequest())

  return getPlaylist(id).then((response) => {
    if (response.message) {
      dispatch(errorPlaylistByIdRequest(response))
    }
    dispatch(completePlaylistBYIdRequest(response))
  })
}

const removeTrackOfPlaylist = (spotifyId) => {
  return {
    type: DELETE_TRACK_OF_PLAYLIST,
    payload: {spotifyId},
  }
}

const addPlaylist = (playlist) => {
  return {
    type: ADD_PLAYLIST,
    payload: {playlist},
  }
}

const addOneToPlaylist = (playlistId) => {
  return {
    type: ADD_ONE_TO_PLAYLIST,
    payload: {
      id: playlistId,
    },
  }
}

const updateTrackPlaylist = (track) => {
  return {
    type: UPDATE_TRACKS_PLAYLIST_FROM_LIST,
    payload: {track},
  }
}

export {
  getMyPlaylists,
  getPlaylistById,
  removeTrackOfPlaylist,
  addPlaylist,
  addOneToPlaylist,
  updateTrackPlaylist,
}
