import Axios from 'axios'
import {getHeaders, errorHandler, ApiMessages, StatusApi} from './helperData'
import {
  GET_TRACKS_ALBUM,
  GET_TRACKS_ARTIST,
  INPUTS_STUDIED,
  MIS_ME_GUSTA,
  RECOMENDACIONES,
  SEARCH_MEDIA_TEXT,
  PLAYLISTS,
  PLAYLIST_MEDIATEXT,
  GET_TRACK_BY_SPOTIFY_ID,
  CREATE_REPORT,
  ADD_SONG_LYRICS,
  GET_MEDIA_TEXT,
  SHARE_TRACK,
  GET_NATURAL_VOICE,
  ARTIST_LIKE,
  ARTIST_REMOVE_LIKE,
  CREATE_INPUT_TEXT,
  GET_INPUT_TEXTS,
  GET_LAST_INPUT,
} from '../../Services/EndPointApi'

const getTrackBySpotifyId = (spotifyId) => {
  return Axios.get(`${GET_TRACK_BY_SPOTIFY_ID}/${spotifyId}`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response.data?.media_text) {
        const {media_text} = response.data
        return media_text
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('getTrackBySpotifyId'))
}

const getInputText = () => {
  return Axios.get(GET_INPUT_TEXTS, {
    headers: getHeaders(true),
  })
    .then((response) => {
      // console.log('RESPONSE: ', response)
      if (response.data?.input_texts) {
        const {input_texts} = response.data
        return {input_texts}
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('getInputText'))
}

// El último input text
const getLastInputText = (id = null) => {
  return Axios.get(GET_LAST_INPUT + id, {
    headers: getHeaders(true),
  })
    .then((response) => {
      // console.log('RESPONSE: ', response)
      if (response.data?.input_text) {
        const {input_text} = response.data
        return {input_text}
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('getLastInputText'))
}

const createInputText = (title, body, image = null) => {
  return Axios.post(
    CREATE_INPUT_TEXT,
    {
      media_text: {
        media_type: 'custom',
        title: title,
        body: body,
        image_url: image,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data) {
        return {
          inputText: response.data,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('createInputText'))
}

const getMyInputStudied = () => {
  return Axios.get(INPUTS_STUDIED, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data?.seguir_aprendiendo) {
        const {
          data: {seguir_aprendiendo},
        } = response
        return {
          tracks: seguir_aprendiendo,
        }
      } else {
        return []
      }
    })
    .catch(errorHandler('GetMyInputs'))
}

const addTrackToMyLikes = (
  spotify_id,
  titulo,
  image_url,
  artistas,
  authorization,
) => {
  return Axios.post(
    MIS_ME_GUSTA,
    {
      title: titulo,
      image_url: image_url,
      spotify_id: spotify_id,
      artists: artistas,
    },
    {
      headers: authorization
        ? {
            Authorization: authorization,
            'Content-Type': 'application/json',
          }
        : getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.user_input) {
        const {user_input} = response.data
        return {
          userInput: user_input,
        }
      } else {
        return {
          message: ApiMessages.BadRequest,
        }
      }
    })
    .catch(errorHandler('addTrackToMyLikes'))
}

const deleteTrackToMyLikes = (userInputId, authorization) => {
  return Axios.delete(`${MIS_ME_GUSTA}/${userInputId}`, {
    headers: authorization
      ? {
          Authorization: authorization,
          'Content-Type': 'application/json',
        }
      : getHeaders(true),
  })
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}

const getRecommendations = () => {
  return Axios.get(RECOMENDACIONES, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response.data) {
        const {data} = response
        const {
          artistas_recomendados,
          seguir_aprendiendo,
          tracks_recomendados,
          shared_tracks,
          textos_agregados,
        } = data

        return {
          artistas_recomendados,
          seguir_aprendiendo,
          tracks_recomendados,
          shared_tracks,
          textos_agregados,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('GetRecommendations'))
}

const getMyLikes = () => {
  return Axios.get(MIS_ME_GUSTA, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response.data) {
        const {artistas, tracks} = response.data

        return {
          artists: artistas,
          tracks,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('getMyLikes'))
}

const searchMediatext = (termino, authorization) => {
  return Axios.get(`${SEARCH_MEDIA_TEXT}${termino}`, {
    headers: authorization
      ? {
          Authorization: authorization,
          'Content-Type': 'application/json',
        }
      : getHeaders(true),
  })
    .then((response) => {
      if (response.data) {
        const {
          results: {artistas, tracks},
        } = response.data
        return {
          artists: artistas,
          tracks,
        }
      } else {
        return {
          artists: [],
          tracks: [],
        }
      }
    })
    .catch(errorHandler('Search mediatext'))
}

const getArtistTracks = (spotifyId) => {
  return Axios.get(`${GET_TRACKS_ARTIST}${spotifyId}`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data) {
        const {artist, top_tracks, albums} = response.data
        return {
          artist,
          topTracks: top_tracks,
          albums,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('Get artist'))
}

const getAlbumTracksById = (spotifyIdAlbum) => {
  return Axios.get(`${GET_TRACKS_ALBUM}${spotifyIdAlbum}`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data) {
        const {tracks} = response.data
        return tracks
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('Get album'))
}

const getPlaylists = () => {
  return Axios.get(PLAYLISTS, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response.data) {
        const {playlists, my_likes} = response.data

        return {
          playlists,
          myLike: my_likes,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('get playlists'))
}

const getPlaylist = (id) => {
  return Axios.get(`${PLAYLISTS}/${id}`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response.data) {
        const {tracks, nombre, image_url} = response.data

        return {
          id,
          image_url,
          name: nombre,
          tracks,
          song_count: tracks.length | 0,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('get playlist'))
}

const createPlaylist = (name, imageUrl, descripcion) => {
  return Axios.post(
    PLAYLISTS,
    {
      name,
      image_url: imageUrl || '',
      descripcion,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data) {
        return {
          playlist: response.data,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('Create playlist'))
}

const removeTrackToPlaylist = (id) => {
  return Axios.delete(`${PLAYLIST_MEDIATEXT}/${id}`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response.data) {
        return true
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('Remove track to playlist'))
}

const addTrackToPlaylist = (
  idPlaylist,
  mediatextId,
  spotifyId,
  title,
  artists,
  imageUrl,
) => {
  return Axios.post(
    PLAYLIST_MEDIATEXT,
    {
      media_text_id: mediatextId,
      playlist_id: idPlaylist,
      spotify_id: spotifyId,
      title,
      artists,
      image_url: imageUrl || '',
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.playlist_media_text) {
        const mtd = response.data.playlist_media_text

        return {
          playlist: mtd.playlist,
          track: mtd.media_text,
        }
      } else {
        const mensaje = response.data?.message || ApiMessages.ErrorServer
        return {
          message: `${mensaje}`,
        }
      }
    })
    .catch(errorHandler('Add track to playlist'))
}

const createReportInput = (reports, comment, mediaTextId, reportedLineIndex) => {
  return Axios.post(
    CREATE_REPORT,
    {
      media_text_report: {
        report_type: reports,
        student_comment: comment,
        media_text_id: mediaTextId,
        line_index: reportedLineIndex,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response.data) {
        return {
          data: response.data,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('createReportInput'))
}

const addSongLirics = (mediatextId, body) => {
  return Axios.post(
    ADD_SONG_LYRICS,
    {
      media_text_id: mediatextId,
      body,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data) {
        return {
          data: response.data,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('addSongLirics'))
}

const getOpenSong = (spotifyId, artists, title, imageUrl, itemId = null) => {
  return Axios.post(
    GET_MEDIA_TEXT,
    {
      spotify_id: spotifyId,
      artists,
      title,
      image_url: imageUrl,
      item_id: itemId,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data) {
        return {
          mediaText: response.data,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('openSong'))
}

const shareTrackToUsers = (
  spotifyId,
  artists,
  title,
  imageUrl,
  userIds,
  notes = '',
  domainId = null,
) => {
  return Axios.post(
    SHARE_TRACK,
    {
      spotify_id: spotifyId,
      artists,
      title,
      image_url: imageUrl,
      user_ids: userIds,
      notes,
      domain_id: domainId, // #TODO enviar este params?
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.mediatext) {
        return {
          mediatext: response.data,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('shareTrackToUsers'))
}

const TextToSpeech = (text, langCode = null) => {
  return Axios.get(GET_NATURAL_VOICE, {
    params: {
      text: text,
      lang_code: langCode,
    },
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data?.voice) {
        const {voice} = response.data
        return {
          audio: voice,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('TextToSpeech'))
}

const likeArtist = (spotify_id, image_url, name, authorization) => {
  return Axios.post(
    ARTIST_LIKE,
    {
      spotify_id,
      image_url,
      name,
    },
    {
      headers: authorization
        ? {
            Authorization: authorization,
            'Content-Type': 'application/json',
          }
        : getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.author_like) {
        const {author_like} = response.data
        return {
          authorLike: author_like,
        }
      } else {
        return {message: ApiMessages.NotFound}
      }
    })
    .catch(errorHandler('likeArtist'))
}

const deletelikeArtist = (authorLikeId, authorization) => {
  return Axios.delete(`${ARTIST_REMOVE_LIKE}/${authorLikeId}`, {
    headers: authorization
      ? {
          Authorization: authorization,
          'Content-Type': 'application/json',
        }
      : getHeaders(true),
  })
    .then(() => {
      return {
        status: StatusApi.OK,
      }
    })
    .catch(errorHandler('deletelikeArtist'))
}

export {
  getTrackBySpotifyId,
  getMyInputStudied,
  deleteTrackToMyLikes,
  addTrackToMyLikes,
  getRecommendations,
  getMyLikes,
  searchMediatext,
  getArtistTracks,
  getAlbumTracksById,
  getPlaylists,
  getPlaylist,
  createPlaylist,
  removeTrackToPlaylist,
  addTrackToPlaylist,
  createReportInput,
  addSongLirics,
  getOpenSong,
  shareTrackToUsers,
  TextToSpeech,
  likeArtist,
  deletelikeArtist,
  createInputText,
  getInputText,
  getLastInputText,
}
