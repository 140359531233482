import React, {useState, useEffect} from 'react'
import {uploadPhoto} from '../../../lib/coco/apis'
import {StatusApi} from '../../../lib/coco/helperData'
import DefaultPhotoUser from '../../Shared/DefaultPhotoUser'
import CameraIcon from './Icons/camera.svg'
import {useWorkingIndicator} from '../../../hooks/useBooleanToggler'

const AvatarUploader = ({
  photoObject,
  updatePhotoUrl,
  isStyleBorder = true,
  showImageCamera = false,
}) => {
  const {isWorking, startWork, finishWork} = useWorkingIndicator()

  const [fotoUrl, setFotoUrl] = useState(null)
  useEffect(() => {
    const url = photoObject && photoObject.length > 0 ? photoObject : null
    setFotoUrl(url)
  }, [photoObject])

  const readFile = (e) => {
    let files = e.currentTarget.files //, data = new FormData();
    if (files && files[0]) {
      if (!/image.*/.test(files[0].type)) {
        console.log(`aborted`)
        return
      }
      // make request
      startWork()
      uploadPhoto(files[0]).then((response) => {
        finishWork()
        if (response.status === StatusApi.OK) {
          setFotoUrl(window.URL.createObjectURL(files[0]))
          updatePhotoUrl(window.URL.createObjectURL(files[0]))
        }
      })
    }
  }

  return (
    <>
      <input
        type="file"
        id="file-uploader"
        onChange={readFile}
        style={{display: 'none'}}
        accept="image/*"
      />

      <label
        htmlFor="file-uploader"
        className="my-0 avatar-selector avatar btn icon hello">
        <div
          className="drawer__coco__user__border"
          style={{
            background: !isStyleBorder ? 'transparent' : '',
            cursor: isWorking ? 'wait' : 'pointer',
          }}>
          <DefaultPhotoUser
            className="drawer__coco__user__my__tools"
            photourl={fotoUrl}
          />

          {showImageCamera && (
            <div className="drawer__coco__icon__camera">
              <img src={CameraIcon} alt={CameraIcon} />
            </div>
          )}
        </div>
      </label>
    </>
  )
}

export default AvatarUploader
