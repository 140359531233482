import React from 'react'
import PropTypes from 'prop-types'
import './MessageActions.css'
import IconTranslateMessage from '../Icons/IconTranslateMessage.svg'
import IconReplyMessage from '../Icons/IconReplyMessage.svg'
import IconDeleteMessage from '../Icons/IconDeleteMessage.svg'
import SpinnerCicle from '../../../Animaciones/SpinnerCircle'

const MessageActions = ({
  deleteMessage,
  translateMessage,
  showActionTranslate,
  isCurrentUser,
  direction,
  isWorking,
  setCitedMessage,
  isConversationResolved,
  autoFocusMessageInput,
}) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${
        direction === 'left' ? 'flex-row' : 'flex-row-reverse'
      } d-inline-block ml-2 mr-0`}>
      {showActionTranslate && (
        <span className="span-hover" onClick={translateMessage}>
          {isWorking ? (
            <SpinnerCicle styles={{width: '25px'}} />
          ) : (
            <img src={IconTranslateMessage} alt="translate" />
          )}
        </span>
      )}

      {!isConversationResolved && (
        <>
          <span
            className="span-hover"
            onClick={() => {
              setCitedMessage()
              autoFocusMessageInput && autoFocusMessageInput()
            }}>
            <img src={IconReplyMessage} alt="" />
          </span>

          {isCurrentUser && (
            <span className="span-hover" onClick={deleteMessage}>
              <img src={IconDeleteMessage} alt="" />
            </span>
          )}
        </>
      )}
    </div>
  )
}

MessageActions.propTypes = {
  direction: PropTypes.string.isRequired,
  deleteMessage: PropTypes.func.isRequired,
}

export default MessageActions
