// import * as api from './requests';
import * as actions from './actions'
// import * as types from './actionTypes';
import {API_WS_ROOT} from '../../Services/EndPointApi'

let socket,
  channel = 'PresenceChannel'

const onOpen = (socket, store) => (evt) => {
  console.debug('PRESENCE_WS_OPEN')
  store.dispatch(actions.subscribing())
}

const onClose = (socket, store) => (evt) => {
  console.debug('PRESENCE_WS_CLOSE')
  store.dispatch(actions.wsconnect())
}

const onError = (socket, store) => (evt) => {
  console.debug('PRESENCE_WS_ERROR')
  socket.close()
}

const onMessage = (socket, store) => (evt) => {
  const msg = JSON.parse(evt.data)
  const connectionState = store.getState().conversations.connection.state

  // console.debug(msg);
  if (msg.type === 'ping') {
    return
  }

  if (connectionState === 'READY' && msg.message && msg.identifier && !msg.type) {
    if (msg.message.type === 'presence_state') {
      console.log(msg)
      return store.dispatch(actions.receivedPresenceState(msg.message.users))
    }
    // console.debug(msg);
    let data_key = Object.keys(msg.message)[0]
    return store.dispatch(actions.receivedData(msg.message[data_key]))
  }

  switch (msg.type) {
    case 'welcome': {
      store.dispatch(actions.subscribing())
      const message = {
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: channel,
        }),
      }
      socket.send(JSON.stringify(message))
      break
    }
    case 'confirm_subscription': {
      store.dispatch(actions.ready())
      store.dispatch(actions.fetchCurrentPresenceState())
      break
    }
    default:
      break
  }
}

export default (store) => (next) => (action) => {
  const result = next(action)
  const match = /^WS_PRESENCE(.+)$/.exec(action.type)

  if (!match) {
    return result
  }

  const wsAction = {...action, type: match[1]}
  const connectionState = store.getState().presence.connection.state
  let message
  switch (wsAction.type) {
    case 'FETCH_PRESENCE_STATE': {
      if (connectionState === 'READY') {
        message = {
          command: 'message',
          identifier: JSON.stringify({channel: channel}),
          data: JSON.stringify({
            action: 'fetch_current',
          }),
        }
        socket.send(JSON.stringify(message))
      }
      break
    }
    case 'PRESENCE_CONNECT': {
      if (socket) {
        socket.close()
      }

      const {user_id, token} = wsAction.payload
      console.log(user_id) // solo para quitar el warning de "'user_id' is assigned a value but never used"
      const url = `${API_WS_ROOT}?token=${token}`
      // const url = `${API_WS_ROOT}?token=${getToken().split(" ")[1]}`;

      socket = new WebSocket(url)
      socket.onmessage = onMessage(socket, store)
      socket.onclose = onClose(socket, store)
      socket.onopen = onOpen(socket, store)
      socket.onerror = onError(socket, store)

      store.dispatch(actions.opening())
      break
    }
    case 'PRESENCE_SUBSCRIBE': {
      message = {
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: channel,
        }),
      }

      socket.send(JSON.stringify(message))
      store.dispatch(actions.subscribing())
      break
    }

    default:
      break
  }
  return result
}
