import React from 'react'
import {NavLink} from 'react-router-dom'
import SpotifyLoginButton from '../../Spotify/buttons/SpotifyLoginButton'
import PlusButtonAction from '../../FlashCards/PlusButton/PlusButtonAction'
import './Leftbar.css'
import LinkLogoCoco from './LinkLogoCoco'
import Progress from './Progress'
import NotificationBadge from '../../Shared/NotificationBadge'
import useSpotify from '../../../hooks/useSpotify'
import useProgress from '../../../hooks/useProgress'

//Icons
import {FormattedMessage} from 'react-intl'
import IconHome from './Icons/IconHome.svg'
import NewIconPlaylist from '../../Icons/newIconPlaylist.svg'
import newIconListening from '../../Icons/newIconListening.svg'
import newIconMemorize from '../../Icons/newIconMemorize.svg'
import newIconPractic from '../../Icons/newIconPractic.svg'
import newIconInbox from '../../Icons/newIconInbox.svg'
import amburgerIcon from '../../Icons/amburger.svg'
import rigthMenuIcon from './Icons/rigthMenu.svg'
import leftMenuIcon from '../../Icons/leftMenu.svg'
import InputTypeTextActiveIcon from '../../Icons/IconInputTypeTextActive.svg'
import useWindowSize from '../../../hooks/useWindowSize'

// Icons
const Leftbar = ({
  openPlusButton,
  toggleLeftbar,
  isToggledLeftbar,
  platformState,
  stateModalSpotifyAuth,
}) => {
  const {player} = useSpotify()
  const {height: heightViewPort} = useWindowSize()
  const {isDeviseMobile} = platformState
  const {
    notifications: {inbox = 0},
  } = useProgress()
  const {getStatus, toggleSpotifyModalAuth} = stateModalSpotifyAuth
  const status = getStatus()

  const {review} = useProgress()

  const connectSpotify = () => {
    toggleSpotifyModalAuth(status)
  }

  const NavLinkActive = {
    background: '#304862',
    borderRadius: '0 5px 5px 0',
  }

  const styles = {width: '26px', height: '22px'}

  const stylesLinks = isToggledLeftbar
    ? 'leftbar__nav__links'
    : 'leftbar__nav__links__mini'

  return (
    <nav
      className={`${
        isToggledLeftbar ? 'leftbar__navigation__main' : 'leftbar__navigation__mini'
      }`}>
      <div className="leftbar__navigation__scrolling">
        {isToggledLeftbar ? (
          <>
            <LinkLogoCoco />
            <button
              className="leftbar__navigation__rigth__icon"
              onClick={toggleLeftbar}>
              <img src={leftMenuIcon} alt={leftMenuIcon} />
            </button>
          </>
        ) : (
          <button
            className="leftbar__navigation__menu__container"
            onClick={toggleLeftbar}>
            <img
              className="leftbar__navigation__amburger__icon"
              src={amburgerIcon}
              alt={amburgerIcon}
            />
            <img
              className="leftbar__navigation__amburger__icon__rigth"
              src={rigthMenuIcon}
              alt={rigthMenuIcon}
            />
          </button>
        )}

        <div className="leftbar__progresos__diarios">
          <div className="links-left-bar">
            <div className="mb-4 position-relative">
              <div className="position-relative home-target">
                <NavLink
                  to={'/dashboard'}
                  className={`pl-3 ${stylesLinks}`}
                  activeStyle={NavLinkActive}>
                  <img src={IconHome} alt={IconHome} className="mr-2" />
                  <span>Home</span>
                </NavLink>
                <div className="mr-linear-gradient" />
              </div>

              <div className="mb-3 position-relative playlist-target">
                <NavLink
                  to={'/mis-playlists'}
                  className={`pl-3 ${stylesLinks}`}
                  activeStyle={NavLinkActive}
                  // onClick={() => animateGif(PLAYLIST)}
                >
                  <img
                    style={styles}
                    id="fc-playlist"
                    src={NewIconPlaylist}
                    alt={NewIconPlaylist}
                    className="mr-2"
                  />
                  <span>Playlists</span>
                </NavLink>
                <div className="mr-linear-gradient" />
              </div>
            </div>

            <div className="mb-4 position-relative">
              <div className="position-relative inbox-target">
                <NavLink
                  to={'/inbox'}
                  className={`pl-3 ${stylesLinks}`}
                  activeStyle={NavLinkActive}>
                  <img
                    style={styles}
                    id="fc-inbox"
                    src={newIconInbox}
                    alt={newIconInbox}
                    className="mr-2"
                  />
                  <span>Inbox</span>
                  {inbox > 0 && <NotificationBadge />}
                </NavLink>
                <div className="mr-linear-gradient" />
              </div>
            </div>

            <div className="mb-4 position-relative">
              <div className="position-relative input-target">
                <NavLink
                  to={'/input-text/'}
                  className={`pl-3 ${stylesLinks}`}
                  activeStyle={NavLinkActive}>
                  <img
                    id="fc-input"
                    style={styles}
                    src={InputTypeTextActiveIcon}
                    alt={InputTypeTextActiveIcon}
                    className="mr-2"
                  />

                  <span>
                    <FormattedMessage
                      id={'dashboard.read'}
                      defaultMessage={'Read'}
                    />
                  </span>
                </NavLink>
                <div className="mr-linear-gradient" />
              </div>

              <div className="position-relative input-target">
                <NavLink
                  to={'/media_text'}
                  className={`pl-3 ${stylesLinks}`}
                  activeStyle={NavLinkActive}>
                  <img
                    id="fc-input"
                    style={styles}
                    src={newIconListening}
                    alt={newIconListening}
                    className="mr-2"
                  />

                  <span>
                    <FormattedMessage
                      id={'dashboard.listen'}
                      defaultMessage={'listen'}
                    />
                  </span>
                </NavLink>
                <div className="mr-linear-gradient" />
              </div>

              <div className="position-relative flashcard-target">
                <NavLink
                  to={'/study-flashcard'}
                  className={`pl-3 ${stylesLinks}`}
                  activeStyle={NavLinkActive}>
                  <img
                    id="fc-icon"
                    style={styles}
                    src={newIconMemorize}
                    alt={newIconMemorize}
                    className="mr-2"
                  />

                  <span>
                    <FormattedMessage
                      id={'dashboard.memorize'}
                      defaultMessage={'Memorize'}
                    />
                  </span>

                  {review?.review_progress < 100 && review?.review_total > 0 && (
                    <NotificationBadge />
                  )}
                </NavLink>
                <div className="mr-linear-gradient" />
              </div>

              <div className="position-relative output-target">
                <NavLink
                  to={'/output'}
                  className={`pl-3 ${stylesLinks}`}
                  activeStyle={NavLinkActive}>
                  <img
                    style={styles}
                    id="fc-output"
                    src={newIconPractic}
                    alt={newIconPractic}
                    className="mr-2"
                  />
                  <span>
                    <FormattedMessage
                      id={'dashboard.practice'}
                      defaultMessage={'Practice'}
                    />
                  </span>
                </NavLink>
                <div className="mr-linear-gradient" />
              </div>
            </div>

            {/* Exclusivamente para los maestros */}
            {/* <div className="mb-4 position-relative">
                <div className="position-relative pronunciation-target">
                  <NavLink
                    to={'/audio-phrases'}
                    className={`pl-3 ${stylesLinks}`}
                    activeStyle={NavLinkActive}
                    // onClick={() => animateGif(INPUT)}
                  >
                    <img
                      id="fc-input"
                      style={styles}
                      src={IconPronunciation}
                      alt={IconPronunciation}
                      className="mr-2"
                    />

                    <span>
                      <FormattedMessage
                        id={'dashboard.pronunciation'}
                        defaultMessage={'pronunciation'}
                      />
                    </span>
                  </NavLink>
                  <div className="mr-linear-gradient" />
                </div>
              </div> */}
          </div>
        </div>
      </div>

      {heightViewPort > 750 && !isDeviseMobile && (
        <Progress
          titleWord={
            <FormattedMessage id={'dashboard.listen'} defaultMessage={'Palabras'} />
          }
          titleFlashcard={
            <FormattedMessage
              id={'dashboard.memorize'}
              defaultMessage={'Memorize'}
            />
          }
          titleOutput={
            <FormattedMessage
              id={'dashboard.practice'}
              defaultMessage={'Escribir'}
            />
          }
          isToggledLeftbar={isToggledLeftbar}
        />
      )}

      {!player?.is_active && (
        <SpotifyLoginButton
          onClick={connectSpotify}
          isToggledLeftbar={isToggledLeftbar}
        />
      )}

      <PlusButtonAction
        openPlusButton={openPlusButton}
        isToggledLeftbar={isToggledLeftbar}
      />
    </nav>
  )
}

export default Leftbar
