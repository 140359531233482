import React, {useState, useEffect} from 'react'
import ImageGallery from 'react-image-gallery'
import './ReactGallery.css'

// TODO buscar el index de la imagen select
const ReactGallery = ({messages = [], messageSelect, closeModal}) => {
  const [startIndex, setStartIndex] = useState(0)
  const [images, setImages] = useState([])

  useEffect(() => {
    const getImages = () => {
      const imgsMessages = messages.filter((msg) => msg?.image?.url)
      return (
        imgsMessages.map((m) => {
          return {
            idMessage: m.id,
            original: m.image?.url,
            thumbnail: m.image?.url,
          }
        }) || []
      )
    }

    const images = getImages()

    if (images?.length <= 0) {
      closeModal()
      return
    }

    setImages(images || [])

    const messageFind = images.findIndex(
      (msg) => msg?.idMessage === messageSelect?.id,
    )

    if (messageFind?.original) {
      setStartIndex(messageFind)
    }
  }, [messageSelect, messages, closeModal])

  return (
    <div className="images-gallery-container">
      <ImageGallery
        items={images}
        originalClass="image-original-galley"
        additionalClass="image-original-galley-aditional"
        startIndex={startIndex}
        showBullets
        lazyLoad
      />
    </div>
  )
}

export default ReactGallery
