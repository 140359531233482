import React, {useState} from 'react'
import useUser from '../../../hooks/useUser'
import './AlertTrial.css'
import {useIntl} from 'react-intl'
import PaymentsModal from '../PaymentsModal'
import {getDifferenceBeetweenDays} from '../helpersPlayments'
import useInterval from '../../../hooks/useInterval'
import {useLayoutEffect} from 'react'

const AlertTop = ({
  diffDays,
  setDiffDays,
  setDiffDaysAproximate,
  nowDate,
  dateEnd,
}) => {
  const intl = useIntl()

  const [isShowAlert, setIsShowAlert] = useState(true)

  const getTimeDate = () => {
    const {diffDays, diffDaysAproximate} = getDifferenceBeetweenDays(
      nowDate,
      dateEnd,
    )

    setDiffDays(diffDays)
    setDiffDaysAproximate(diffDaysAproximate)
  }

  useInterval(getTimeDate, 1000)

  return (
    <>
      {isShowAlert && (
        <div className="alert__trial__container animate__animated animate__fadeInDown">
          <p className="alert__trial__text">
            {intl.formatMessage({
              id: 'alert-trial.text-1',
              defaultMessage: 'Your free trial is',
              description: 'Your free trial is',
            })}
            <b>{` ${
              diffDays <= 0
                ? intl.formatMessage({
                    id: 'alert-trial.text-2',
                    defaultMessage: 'finished',
                    description: 'finished',
                  })
                : `${intl.formatMessage({
                    id: 'alert-trial.text-3',
                    defaultMessage: 'will end in',
                    description: 'will end in',
                  })} ${diffDays} ${intl.formatMessage({
                    id: 'alert-trial.day',
                    defaultMessage: 'day',
                    description: 'day',
                  })}${diffDays > 1 ? 's' : ''}.`
            }`}</b>
          </p>

          <button
            className="alert__trial__button"
            onClick={() => setIsShowAlert(false)}>
            x
          </button>
        </div>
      )}
    </>
  )
}

const AlertTrial = () => {
  const {plan_type, trialFinishedAt, isShowModalSuscription} = useUser()

  const nowDate = new Date().getTime()
  const dateEnd = new Date(trialFinishedAt).getTime()

  const [diffDays, setDiffDays] = useState()
  const [diffDaysAproximate, setDiffDaysAproximate] = useState()

  useLayoutEffect(() => {
    const {diffDays, diffDaysAproximate} = getDifferenceBeetweenDays(
      nowDate,
      dateEnd,
    )
    setDiffDays(diffDays)
    setDiffDaysAproximate(diffDaysAproximate)
    // eslint-disable-next-line
  }, [trialFinishedAt])

  const isModalSuscriptionValid = () => {
    if (trialFinishedAt && diffDays <= 3) {
      return true
    } else if (isShowModalSuscription) {
      return true
    }
    return false
  }

  return (
    <>
      {trialFinishedAt && plan_type === 'trial' && diffDays > 3 && (
        <AlertTop
          diffDays={diffDaysAproximate}
          setDiffDays={setDiffDays}
          setDiffDaysAproximate={setDiffDaysAproximate}
          dateEnd={dateEnd}
          nowDate={nowDate}
        />
      )}

      {isModalSuscriptionValid() && <PaymentsModal />}
    </>
  )
}

export default AlertTrial
