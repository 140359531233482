import React from 'react'
import AvatarParticipant from './AvatarParticipant'

export const UsersSelected = ({users, removerUser}) => {
  return (
    <>
      {users?.length > 0 && (
        <div className="participantes-ya-agregados-al-grupo">
          {users.map((user) => (
            <div
              key={user?.user_id}
              className="participantes-ya-agregados-contenedor">
              <div className="participantes-ya-agregados-item">
                <AvatarParticipant
                  photoUrl={user?.photo}
                  title={user?.name || user?.email || 'User'}
                />
                <span
                  onClick={() => removerUser(user)}
                  className="badge-remover-users">
                  x
                </span>
              </div>
              <span className="participantes-selected-name">
                {user?.name || user?.email || 'User'}
              </span>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
