import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PeculiaridadesPrincipal from '../PeculiaridadesTags/PeculiaridadesPrincipal'
import TextareaAutosize from 'react-autosize-textarea/lib'

const Card = styled.div`
  border-radius: 10px;
  background: ${(props) => props.background};
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  background-image: ${(props) =>
    props?.flashcardFrontWithBackgroundImage
      ? 'url("/images/Flashcards/Background.svg")'
      : 'none'};
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`

//Este componente es la parte frontal del flashcard(fondo negro).
const FlashcardFront = (props) => {
  const {
    toggleScreen,
    phraseTranslation,
    width,
    height,
    background,
    flashcardFrontWithBackgroundImage,
    itemizable,
    tags,
  } = props
  const handleChange = (e) => {
    const {getPhraseTranslation} = props
    if (getPhraseTranslation) {
      getPhraseTranslation(e)
    }
  }
  return (
    <Card
      width={width}
      height={height}
      onClick={toggleScreen}
      background={background}
      flashcardFrontWithBackgroundImage={flashcardFrontWithBackgroundImage}
      className="flashcard__mobile__container">
      <TextareaAutosize
        tabIndex="1"
        className="orange-gradient-color"
        onChange={({target}) => handleChange(target.value)}
        value={phraseTranslation}
      />

      {itemizable && (
        <div className="w-100 px-4" style={{color: 'white'}}>
          <div className="contenedor-peculiaridades-listado-scroll">
            <PeculiaridadesPrincipal
              objeto_seleccionado={itemizable}
              specialnote_a_modificar="trans_special_note"
              tag_a_modificar="trans_tags"
              nota_especial_text={
                itemizable?.trans_special_note ? itemizable.trans_special_note : ''
              }
              non_lang_tags={tags.non_lang_tags}
              lang_tags={tags.lang_tags}
              Actualizar_nuevo_objeto={props.setItemizable}
              notificar_estado_peculiaridad={() => {}}
              tipo_tag="front"
            />
          </div>
        </div>
      )}
    </Card>
  )
}

FlashcardFront.propTypes = {
  itemizable: PropTypes.object,
  phraseTranslation: PropTypes.string,
  getPhraseTranslation: PropTypes.func,
  editable: PropTypes.bool,
  toggleScreen: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  background: PropTypes.string,
  flashcardFrontWithBackgroundImage: PropTypes.bool,
}

FlashcardFront.defaultProps = {
  background: 'black',
  flashcardFrontWithBackgroundImage: true,
  toggleScreen: () => {},
  getPhraseTranslation: () => {},
}

export default FlashcardFront
