const emails = [
  'leinadlm95@gmail.com',
  'pacovazquezz90@gmail.com',
  'cristianmenjivar95@gmail.com',
  'diegoavelar@gmail.com',
  'diego2000avelar@gmail.com',
  'michaelsking1993@gmail.com',
  'john@gmail.com',
  'cris@gmail.com',
]

export const isEmailExist = (email) => {
  return emails.includes(email?.toLowerCase())
}
