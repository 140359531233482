export default {
  en: {
    'perfil.badges': 'BADGES',
    'perfil.progress': 'PROGRESS',
    'perfil.followers': 'followers',
    'perfil.following': 'following',
    'perfil.learning': 'Learning',
    'perfil.years': 'Years - from',
    'perfil.native-language': 'Native Language',
    'perfil.no-liking': "There's no likes yet",
    'perfil.week-progress': 'Weekly Progress',
    'perfil.month-progress': 'Monthly Progress',
    'perfil.general-progress': 'General Progress',
    'perfil.no-data': "There's no data",
    'perfil.show-all': 'Show All',
    'perfil.show-less': 'Show Less',
    'perfil.output-send': 'Responses',
    'perfil.history': 'History of Responses',
    'perfil.select': 'Select a Question',
    'perfil.learning-language': "Languages I'm learning",
    'perfil.save': 'Save',
    'perfil.word-day': 'How many words per day do you want to learn?',
    'perfil.flashcard-day': 'How many flashcards per day do you want to add?',
    'perfil.save-password': 'Save password',
    'perfil.old': 'Old password:',
    'perfil.new': 'New password:',
    'perfil.confirm': 'Confirm password:',
    'perfil.edit': 'Edit Profile',
    'perfil.study': 'Study',
    'perfil.change': 'Change Password',
    'perfil.name': 'Name:',
    'perfil.description': 'Description:',
    'perfil.email': 'Email:',
    'perfil.native': 'Native Language:',
    'perfil.place': 'Birthplace:',
    'perfil.birth': 'Birthdate:',
  },
  es: {
    'perfil.badges': 'INSIGNIAS',
    'perfil.progress': 'PROGRESOS',
    'perfil.followers': 'seguidores',
    'perfil.following': 'siguiendo',
    'perfil.learning': 'Aprendiendo',
    'perfil.years': 'Años - de',
    'perfil.native-language': 'Lenguage Nativo',
    'perfil.no-liking': 'Aún no hay likes',
    'perfil.week-progress': 'Progreso Semanal',
    'perfil.month-progress': 'Progreso Mensual',
    'perfil.general-progress': 'Progreso General',
    'perfil.no-data': 'No hay datos',
    'perfil.show-all': 'Ver Todo',
    'perfil.show-less': 'Ver Menos',
    'perfil.output-send': 'Respuestas Enviadas',
    'perfil.history': 'Historial de Respuestas',
    'perfil.select': 'Selecciona una Pregunta',
    'perfil.learning-language': 'Lenguajes Aprendiendo',
    'perfil.save': 'Guardar',
    'perfil.word-day': '¿Cuántas palabras al día quieres aprender?',
    'perfil.flashcard-day': '¿Cuantos flashcards al día quieres agregar?',
    'perfil.save-password': 'Guardar contraseña',
    'perfil.old': 'Anterior contraseña:',
    'perfil.new': 'Nueva contraseña:',
    'perfil.confirm': 'Confirmar contraseña:',
    'perfil.edit': 'Editar Perfil',
    'perfil.study': 'Estudiar',
    'perfil.change': 'Cambiar Contraseña',
    'perfil.name': 'Nombre:',
    'perfil.description': 'Descripcion:',
    'perfil.email': 'Correo:',
    'perfil.native': 'Lenguaje Nativo:',
    'perfil.place': 'Lugar de Nacimiento:',
    'perfil.birth': 'Fecha de Nacimiento:',
  },
  pt: {
    'perfil.badges': 'EMBLEMAS',
    'perfil.progress': 'PROGRESS',
    'perfil.followers': 'seguidores',
    'perfil.following': 'seguindo\n',
    'perfil.learning': 'Aprendizagem',
    'perfil.years': 'Anos - a partir de',
    'perfil.native-language': 'Idioma nativo',
    'perfil.no-liking': 'Ainda não curtiu',
    'perfil.week-progress': 'Progresso semanal',
    'perfil.month-progress': 'Progresso mensal',
    'perfil.general-progress': 'Progresso Geral',
    'perfil.no-data': 'Não há dados',
    'perfil.show-all': 'Ver tudo',
    'perfil.show-less': 'Veja menos\n',
    'perfil.output-send': 'Respostas Enviadas',
    'perfil.history': 'Histórico de respostas',
    'perfil.select': 'Selecione uma pergunta',
    'perfil.learning-language': 'Aprendizagem de línguas',
    'perfil.save': 'Salvar',
    'perfil.word-day': 'Quantas palavras por dia você quer aprender?',
    'perfil.flashcard-day': 'Quantos flashcards por dia você deseja adicionar?',
    'perfil.save-password': 'Salvar senha',
    'perfil.old': 'Senha anterior:',
    'perfil.new': 'Nova senha:',
    'perfil.confirm': 'Confirmar senha:\n',
    'perfil.edit': 'Editar Perfil',
    'perfil.study': 'Estudar',
    'perfil.change': 'Alterar senha',
    'perfil.name': 'Nome:',
    'perfil.description': 'Descrição do produto:',
    'perfil.email': 'Correio:',
    'perfil.native': 'Idioma nativo:',
    'perfil.place': 'Local de nascimento:',
    'perfil.birth': 'Data de nascimento:',
  },
  fr: {
    'perfil.badges': 'BADGES',
    'perfil.progress': 'PROGRÈS',
    'perfil.followers': 'abonnés',
    'perfil.following': 'suivant',
    'perfil.learning': 'Apprentissage',
    'perfil.years': 'Années - à partir de',
    'perfil.native-language': 'Langue maternelle',
    'perfil.no-liking': 'Pas encore de likes',
    'perfil.week-progress': 'Progrès hebdomadaire',
    'perfil.month-progress': 'Progression mensuelle',
    'perfil.general-progress': 'Progrès général',
    'perfil.no-data': 'Pas de données',
    'perfil.show-all': 'Voir tout',
    'perfil.show-less': 'Voir moins',
    'perfil.output-send': 'Réponses envoyées',
    'perfil.history': 'Historique des réponses',
    'perfil.select': 'Sélectionnez une question',
    'perfil.learning-language': 'Apprentissage des langues',
    'perfil.save': 'sauvegarder',
    'perfil.word-day': 'Combien de mots par jour voulez-vous apprendre?',
    'perfil.flashcard-day':
      'Combien de cartes mémoire par jour souhaitez-vous ajouter?',
    'perfil.save-password': 'Enregistrer le mot de passe',
    'perfil.old': 'Mot de passe précédent:',
    'perfil.new': 'nouveau mot de passe:',
    'perfil.confirm': 'Confirmer mot de passe:',
    'perfil.edit': 'Modifier le profil',
    'perfil.study': 'Etudier',
    'perfil.change': 'Changer le mot de passe',
    'perfil.name': 'nom\n:',
    'perfil.description': 'La description:',
    'perfil.email': 'Email:',
    'perfil.native': 'Langue maternelle:',
    'perfil.place': 'Lieu de naissance:',
    'perfil.birth': 'Date de naissance:',
  },
}
