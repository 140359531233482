import React, {useRef, useState} from 'react'
import buzon from '../Icons/buzon.svg'
import MediaText from '../Icons/music.svg'
import UserOutput from '../Icons/outputs.svg'
import UserOutputComment from '../Icons/comentario.svg'
import Flashcard from '../Icons/flashcards.svg'

import RevisedFlashcards from './RevisedFlashcards'
import RevisedMediaTexts from './RevisedMediaTexts'
import UserOutputComments from './UserOutputComments'
import UserOutputCorrections from './UserOutputCorrections'
import {FormattedMessage} from 'react-intl'

import './NotificationsMenu.css'
import Modal from '../Modal/Modal'
import {useDispatch} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'

import {selectOutputByUserOutputId} from '../../Redux/OutputHistory/actions'
import useOnClickOutside from '../../hooks/useClickOutside'
import {readNotification} from '../../lib/coco/notificationsApi'
import {ALERT_TYPES, fullAlert} from '../../Services/Alert'
import {checkReadNotifications} from '../../Redux/notifications/notificationsActions'
import useNotifications from '../../hooks/useNotifications'
import CloseIcon from '../Icons/close.svg'
import {
  isNotificationUserOutputLike,
  isNotificationUserOutputComment,
  isNotificationUserOutputConfirmation,
  NotificationTypes,
} from './notificationsHelpers'
import SpinnerCicle from '../Animaciones/SpinnerCircle'

const NotificationsMenu = () => {
  const [showDialog, setShowDialog] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [selectedNotificationData, setSelectedNotificationData] = useState(null)

  const {notifications, loading} = useNotifications()

  const myRef = useRef()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  useOnClickOutside(myRef, () => setShowDialog(false))

  const toggleDialog = () => {
    setShowDialog(!showDialog)
  }

  const closeModal = () => {
    setShowNotification(false)
  }

  const notificationComponent = () => {
    const {
      flashcards,
      type,
      media_texts,
      user_output,
      output_item,
      notification_id,
    } = selectedNotificationData

    switch (type) {
      case NotificationTypes.FLASHCARD:
        return <RevisedFlashcards flashcards={flashcards} />

      case NotificationTypes.MEDIATEXT:
        return <RevisedMediaTexts MediaTextsNotify={media_texts} />

      case NotificationTypes.USER_OUTPUT:
        return <UserOutputCorrections notificationId={notification_id} />

      case NotificationTypes.COMMENT:
        return (
          <UserOutputComments user_output={user_output} outputItem={output_item} />
        )
      default:
        return null
    }
  }

  const modalNotificationClass = () => {
    const {type} = selectedNotificationData
    switch (type) {
      case NotificationTypes.FLASHCARD:
        return 'notification-flashcards'

      case NotificationTypes.MEDIATEXT:
        return 'notification-media-texts'

      case NotificationTypes.USER_OUTPUT:
        return 'notification-user-output-corrections'

      case NotificationTypes.COMMENT:
        return 'notification-user-output-comments'
      default:
        return null
    }
  }

  const getImageSource = (type) => {
    switch (type) {
      case NotificationTypes.FLASHCARD:
        return Flashcard

      case NotificationTypes.MEDIATEXT:
        return MediaText

      case NotificationTypes.USER_OUTPUT:
        return UserOutput

      case NotificationTypes.COMMENT:
        return UserOutputComment

      case NotificationTypes.USER_OUTPUT_LIKE:
        return UserOutput

      case NotificationTypes.USER_OUTPUT_CONFIRMATION:
        return UserOutput
      default:
        return ''
    }
  }

  const showNotificationById = (notification) => {
    const {id, read: isNotificationRead} = notification
    readNotification(id).then((response) => {
      if (response.notificationData) {
        const {notificationData} = response
        const {type} = notificationData
        const {pathname} = location

        if (!isNotificationRead) {
          dispatch(checkReadNotifications(id)) // marca read.. true
        }

        if (isNotificationUserOutputConfirmation(type)) {
          if (pathname !== '/output') {
            history.push('/output')
          }
          toggleDialog()
          return
        } else if (isNotificationUserOutputComment(type)) {
          dispatch(selectOutputByUserOutputId(notificationData?.user_output?.id)) // seleccionar en redux
          if (pathname !== '/outputs') {
            history.push('/outputs')
          }
          toggleDialog()
          return
        } else if (isNotificationUserOutputLike(type)) {
          dispatch(selectOutputByUserOutputId(notificationData.user_output_id)) // seleccionar en redux
          if (pathname !== '/outputs') {
            history.push('/outputs')
          }
          toggleDialog()
          return
        } else {
          setSelectedNotificationData(notificationData)
          setShowDialog(false)
          setShowNotification(true)
        }
      } else {
        fullAlert(
          ALERT_TYPES.ERROR,
          `${response.message}, no se obtubo la notificación`,
        )
      }
    })
  }

  const unreadNotifications = notifications?.filter((notif) => !notif.read)

  return (
    <div className="contenedor-notificaciones" ref={myRef}>
      <span onClick={() => toggleDialog()} className="text-white buzon">
        <img src={buzon} alt={buzon} />
        {unreadNotifications && unreadNotifications.length > 0 && (
          <span className="alerta-notificacion">
            {unreadNotifications && unreadNotifications.length}
          </span>
        )}
      </span>
      {/*AQUI EMPIEZA EL MENU DONDE SE DESPLIEGAN TODAS LAS NOTIFICACIONES */}
      <div
        style={{visibility: showDialog ? 'visible' : 'hidden'}}
        className="notificaciones">
        <img
          className="cursor-pointer notification-close"
          src={CloseIcon}
          alt="Close-mis-cosas"
          onClick={() => toggleDialog()}
        />

        <div className="notifications-title">
          <FormattedMessage
            id={'notification.title'}
            defaultMessage={'Notifications'}
          />
        </div>

        {loading && (
          <div className="notifications__loading__fetch">
            <SpinnerCicle />
          </div>
        )}

        <div className="notifications-content scroll-coco">
          {notifications &&
            notifications.map((notification) => {
              return (
                <div
                  key={notification.id}
                  className="una-notificacion"
                  onClick={() => showNotificationById(notification)}>
                  <img src={getImageSource(notification.type)} alt="notification" />
                  <span className="notif-mensaje">
                    {notification.messages.map((message, index) =>
                      index % 2 === 0 ? (
                        message === '' ? (
                          message
                        ) : (
                          message + ' '
                        )
                      ) : (
                        <span
                          key={index}
                          style={{fontWeight: 'bold'}}
                          title={message}>
                          {message}
                        </span>
                      ),
                    )}
                  </span>
                  {notification.read ? '' : <span className="not-read" />}
                </div>
              )
            })}
        </div>
      </div>
      {/*AQUI TERMINA EL MENU DONDE SE DESPLIEGAN TODAS LAS NOTIFICACIONES */}

      {/* AQUI EMPIEZA EL MODAL DE UNA NOTIFICACION ESPECIFICA */}
      {showNotification && (
        <Modal
          closeModal={closeModal}
          show={showNotification}
          modalType={modalNotificationClass()}>
          {notificationComponent()}
        </Modal>
      )}
    </div>
  )
}

export default NotificationsMenu
