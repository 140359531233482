import {replaceTrackFromLike} from '../helpers'
import {
  FETCH_MYINPUTS_REQUEST_ERROR,
  FETCH_MYINPUTS_REQUEST_FINISH,
  FETCH_MYINPUTS_REQUEST_START,
  UPDATE_TRACK_MYINPUTS_LIKE,
} from './actionTypes'

const myInputsInitialState = {
  tracks: [],
  loading: false,
  error: null,
}

const myInputsReducer = (state = myInputsInitialState, action) => {
  switch (action.type) {
    case FETCH_MYINPUTS_REQUEST_START:
      return {
        ...state,
        loading: true,
        error: {message: ''},
      }

    case FETCH_MYINPUTS_REQUEST_FINISH:
      return {
        ...state,
        loading: false,
        tracks: action.payload.tracks || [],
      }

    case FETCH_MYINPUTS_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case UPDATE_TRACK_MYINPUTS_LIKE:
      const {tracks} = state
      return {
        ...state,
        tracks: replaceTrackFromLike(action.payload.track, tracks),
      }

    default:
      return state
  }
}

export default myInputsReducer
