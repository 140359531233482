import React, {Component} from 'react'
import './UserOutputCorrections.css'
import {FormattedMessage} from 'react-intl'
import UserOutput from '../Output/UserOutput'
import TranslationFlashcardForm from '../FlashCards/TranslationFlashcardForm'
import {addIndexUserOutputHistory} from '../../Redux/OutputHistory/actions'
import store from '../../Redux/Store'
import {connect} from 'react-redux'
import Modal from '../Modal/Modal'
import Waveform from '../Waveform/Waveform'

const Corrections = 'Corrections'
class UserOutputCorrections extends Component {
  constructor(props) {
    super(props)
    const {options_tutorial} = this.props.user
    const userOutput = this.props.output
    this.state = {
      contenido: Corrections,
      userOutput: userOutput,
      hasOneComment: false,
      iconComment: '/images/OutputVerification/iconCommentDegradado.svg',
      showBtnComment: false,
      backgroundComment: 'transparent',
      showAnteriorButton: false,
      showSiguienteButton: false,

      outputs: null,
      outputParaMostrar: null,
      currentOutputCount: 1,
      totalOutputCount: 1,

      phrase: '',
      phraseTranslation: '',
      phraseId: null,
      translatedPhraseId: null,
      lineIndexInText: null,
      // resourceType: "user-output",
      // userOutputId: null
      indicesYaAgregados: [], //this.props.output.user_output.indices_added,
      showModalTutorial: options_tutorial.ver_correcciones,

      modalFlashcard: false,
    }

    //this.control = this.control.bind(this);
    this.seeComment = this.seeComment.bind(this)
    this.actualizarIndices = this.actualizarIndices.bind(this)
    this.agregarUnFlashcard = this.agregarUnFlashcard.bind(this)
    this.updateIndicesYaAgregados = this.updateIndicesYaAgregados.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    this.showTutorial(prevProps)
  }

  hideTutorial = () => {
    this.setState({
      showModalTutorial: false,
    })
  }

  showTutorial = (prevProps) => {
    const {user} = this.props
    let options_tutorial = user.options_tutorial.ver_correcciones
    let prev_options_tutorial = prevProps.user.options_tutorial.ver_correcciones
    if (options_tutorial !== prev_options_tutorial) {
      this.setState({
        showModalTutorial: options_tutorial,
      })
    }
  }

  updateIndicesYaAgregados(lineIndex) {
    let indicesAgregados = this.state.indicesYaAgregados
    indicesAgregados.push()
    this.setState({indicesYaAgregados: indicesAgregados})

    const {outputParaMostrar} = this.state
    const {user_output} = outputParaMostrar

    store.dispatch(addIndexUserOutputHistory(user_output.id, lineIndex))
  }

  seeComment(titleOutputItem, bodyUserOutput, comment, notificationId) {
    this.setState({
      titleOutputItem: titleOutputItem,
      bodyUserOutput: bodyUserOutput,
      comment: comment,
      notificationId: notificationId,
    })
  }

  actualizarIndices(indexDeLaLinea, userOutputId) {
    let newOutputsDelDia = this.state.outputsDelDia.map((output, index) => {
      if (output.user_output.id !== userOutputId) {
        return output
      } else {
        output.user_output.indices_added.push(indexDeLaLinea)
        return output
      }
    })
    let newOutputParaMostrar = this.state.outputParaMostrar
    newOutputParaMostrar.user_output.indices_added.push(indexDeLaLinea)

    let ventanaAnterior = this.state.ventanaActiva
    this.setState({
      outputParaMostrar: newOutputParaMostrar,
      outputsDelDia: newOutputsDelDia,
      ventanaActiva: 3,
      ventanaAnterior: ventanaAnterior,
    })
  }

  agregarUnFlashcard(
    phrase,
    phraseTranslation,
    lineIndexInText,
    phraseId,
    translatedPhraseId,
  ) {
    this.setState({
      phrase,
      phraseTranslation,
      phraseId,
      translatedPhraseId,
      lineIndexInText,
      modalFlashcard: true,
    })
  }

  hayCorrecciones = () => {
    const correcciones = this.props.output?.user_output?.corrections
    if (correcciones) {
      for (let i in correcciones) {
        if (correcciones[i].status === 'mal') {
          return true
        }
      }
    }

    return false
  }

  render() {
    const output = this.props.output
    const {
      currentOutputCount,
      totalOutputCount,
      contenido,
      phrase,
      phraseTranslation,
      phraseId,
      translatedPhraseId,
      lineIndexInText,
      modalFlashcard,
    } = this.state
    const {notificationId} = this.props

    return (
      <>
        {contenido === Corrections && (
          <>
            {/*La pregunta y un texto descriptivo*/}
            {output && notificationId && (
              <div id="c-titulo-instrucciones">
                <div>
                  {/* No es al título al que se le da clic, es a un botoncito flotante*/}
                  <span id="pregunta">
                    {this.state.hasOneComment && (
                      <div
                        id="icon-comment"
                        style={{background: this.state.backgroundComment}}>
                        <img
                          src={this.state.iconComment}
                          alt="send"
                          onMouseOver={() => {
                            this.setState({
                              iconComment: '/images/Output/iconComment.svg',
                              backgroundComment:
                                'linear-gradient(190.6deg, #1EB8F1 12.28%, #015BBA 91.67%)',
                              showBtnComment: true,
                            })
                          }}
                        />
                      </div>
                    )}
                    {output.output_item.title}
                  </span>
                </div>

                <p id="indicaciones">
                  <FormattedMessage
                    id={'output.check-1'}
                    defaultMessage={'Revisa tus correcciones y agrégalas a tus'}
                  />
                  <b> flashcards </b>
                  <FormattedMessage
                    id={'output.check-2'}
                    defaultMessage={'para seguir aprendiendo.'}
                  />
                </p>
              </div>
            )}
            {/*Lista de outputs items*/}
            <div className="mb-4">
              {output?.user_output?.corrections.length > 1 && (
                <UserOutput
                  userOutputCorrected={output.user_output.corrections}
                  indicesYaAgregados={output.user_output.indices_added}
                  isNotification={true}
                  agregarUnFlashcard={this.agregarUnFlashcard}
                  studentAudio={output?.user_output?.audio_url}
                  teacherAudio={output?.user_output?.audio_teacher_url}
                />
              )}
            </div>

            {/*Audio del estudiante*/}
            {output?.user_output?.audio_url && (
              <div className="w-50">
                <Waveform
                  url={output?.user_output?.audio_url}
                  color="#FE7260"
                  height={56}
                />
              </div>
            )}

            {/*Audio del profesor*/}
            {output?.user_output?.audio_url_teacher && (
              <div className="w-50">
                <Waveform url={output?.user_output?.audio_teacher_url} height={56} />
              </div>
            )}

            {/* El recuento de outputs... */}
            {notificationId && (
              <div className="recuento-de-outputs-corregidos">
                {currentOutputCount + '/' + totalOutputCount}
              </div>
            )}

            {/*Botones "siguiente" y "atrás"*/}
            <div id="c-buttons-back-next">
              {/* TODO: Reemplazar por componente Btn... correspondiente */}
              {currentOutputCount !== 1 && (
                <button
                  className="btn btn-next-output"
                  onClick={() => this.control('back')}>
                  <img src="/images/Output/iconOutputAnterior.svg" alt="send" />{' '}
                  Anterior
                </button>
              )}
              {currentOutputCount !== totalOutputCount && (
                <button
                  className="btn btn-next-output"
                  onClick={() => this.control('next')}>
                  Siguiente{' '}
                  <img src="/images/Output/iconSiguienteOutput.svg" alt="send" />
                </button>
              )}
            </div>
          </>
        )}

        <Modal
          modalType="output"
          show={modalFlashcard}
          closeModal={() => this.setState({modalFlashcard: false})}>
          <TranslationFlashcardForm
            phraseTranslation={phraseTranslation}
            phrase={phrase}
            updateIndicesYaAgregados={this.updateIndicesYaAgregados}
            // item customizado
            paramsItemOptionsRequest={{
              line_index_in_text: lineIndexInText,
              itemizable_id: output?.user_output?.id,
              itemizable_type: 'UserOutput',
              phrase_id: phraseId,
              phrase_translation_id: translatedPhraseId,
            }}
          />
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(UserOutputCorrections)
