import {
  ELEGIR_USER_OUTPUT,
  TOGGLE_SHOW_CORRECTION,
  ADD_INDICE_USER_OUTPUT_HISTORY,
  UPDATE_USER_OUTPUT_COMMENTS,
  GET_USER_OUTPUT_FINISH,
  GET_USER_OUTPUT_START,
  GET_USER_OUTPUT_ERROR,
  UPDATE_USER_OUTPUT_LIKE,
  SELECT_OUTPUT_BY_USER_OUTPUT_ID,
} from './actionTypes'
import {getUserOutputs} from '../../lib/coco/OutputsApi'

const getUserOutputStart = () => {
  return {
    type: GET_USER_OUTPUT_START,
  }
}

const getUserOutputError = (error) => {
  return {
    type: GET_USER_OUTPUT_ERROR,
    error,
  }
}

const getUserOutputFinish = (outputs) => {
  return {
    type: GET_USER_OUTPUT_FINISH,
    outputs: outputs,
  }
}

const getUserOutputHistory = () => (dispatch) => {
  dispatch(getUserOutputStart())

  getUserOutputs().then((response) => {
    if (response.outputs) {
      const {outputs = []} = response
      return dispatch(getUserOutputFinish(outputs))
    } else {
      dispatch(getUserOutputError(response.message))
    }
  })
}

const elegirUserOutputItem = (index) => {
  return {
    type: ELEGIR_USER_OUTPUT,
    index: index,
  }
}

const toggleShowCorrection = () => {
  return {
    type: TOGGLE_SHOW_CORRECTION,
  }
}

const addIndexUserOutputHistory = (id, lineIndex) => {
  return {
    type: ADD_INDICE_USER_OUTPUT_HISTORY,
    id,
    lineIndex,
  }
}

const updateUserOutputComments = (comment, userOutput) => {
  return {
    type: UPDATE_USER_OUTPUT_COMMENTS,
    comment,
    userOutput,
  }
}
const updateUserOutputLike = (userOutputLikeId, userOutput) => {
  return {
    type: UPDATE_USER_OUTPUT_LIKE,
    userOutputLikeId,
    userOutput,
  }
}

const selectOutputByUserOutputId = (userOutputId) => {
  return {
    type: SELECT_OUTPUT_BY_USER_OUTPUT_ID,
    userOutputId,
  }
}

export {
  updateUserOutputLike,
  updateUserOutputComments,
  addIndexUserOutputHistory,
  toggleShowCorrection,
  elegirUserOutputItem,
  getUserOutputHistory,
  selectOutputByUserOutputId,
}
