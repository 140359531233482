export const PRESENCE_CONNECTED = 'PRESENCE_CONNECTED'
export const PRESENCE_DISCONNECTED = 'PRESENCE_DISCONNECTED'

export const CONNECTED_SOCKET = 'CONNECTED_SOCKET'

export const PRESENCE_CONNECTION_OPENING = 'PRESENCE_CONNECTION_OPENING'
export const PRESENCE_CONNECTION_SUBSCRIBING = 'PRESENCE_CONNECTION_SUBSCRIBING'
export const PRESENCE_CONNECTION_READY = 'PRESENCE_CONNECTION_READY'

export const WS_PRESENCE_CONNECT = 'WS_PRESENCE_CONNECT'
export const WS_PRESENCE_SUBSCRIBE = 'WS_PRESENCE_SUBSCRIBE'
export const WS_PRESENCE_DATA = 'WS_PRESENCE_DATA'
export const RECEIVED_PRESENCE_DATA = 'RECEIVED_PRESENCE_DATA'

export const WS_PRESENCE_FETCH_STATE = 'WS_PRESENCE_FETCH_STATE'
export const RECEIVED_PRESENCE_STATE = 'RECEIVED_PRESENCE_STATE'

export const FETCH_PRESENCE = 'FETCH_PRESENCE'
export const FETCH_PRESENCE_SUCCESS = 'FETCH_PRESENCE_SUCCESS'
export const FETCH_PRESENCE_FAILURE = 'FETCH_PRESENCE_FAILURE'
