import Axios from 'axios'
import {DICCIONARIO_LIBRE} from '../../Services/EndPointApi'
import {
  GET_TRANSLATION_AND_EXAMPLES,
  GET_TRANSLATION_AND_EXAMPLES_ERROR,
  LOADING_TRANSLATION,
  LOADING_API,
} from './actionTypes'
import {getToken} from '../../Services/getToken'
import {QuitarCaracteresParaTraducir} from '../../Services/CadenasSplit'

export const getTranslatorAndExamples = (phrase, fromLanguage, toLanguage) => (
  dispatch,
) => {
  const DEVISE_SECRET_KEY = getToken()

  phrase = QuitarCaracteresParaTraducir(phrase)

  Axios.get(
    `${DICCIONARIO_LIBRE}?body=${phrase}&&from_language=${fromLanguage.unicode}&&to_language=${toLanguage.unicode}&&location=translator`,
    {
      headers: {
        Authorization: DEVISE_SECRET_KEY,
        'Content-Type': 'application/json',
      },
    },
  )
    .then((response) => {
      let resultado = response.data
      console.log(resultado)
      if (resultado) {
        return dispatch({
          type: GET_TRANSLATION_AND_EXAMPLES,
          translation: resultado.translation,
          frasesEjemplos: resultado.frases,
        })
      }
    })
    .catch(() => {
      return dispatch({
        type: GET_TRANSLATION_AND_EXAMPLES_ERROR,
      })
    })

  //loading, button rotate
  return dispatch({
    type: LOADING_API,
  })
}

export const loadingPhrase = (phrase) => (dispatch) => {
  //para conocer que la peticion esta en proceso
  return dispatch({
    type: LOADING_TRANSLATION,
    phrase: phrase,
  })
}
