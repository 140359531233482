//constantes de botones activos
const TypesLevelsAnwerOutputs = {
  EASY: 'EASY',
  MEDIUM: 'DIVERTIDAS',
  HARD: 'ANECDOTAS',
  LIBRE: 'LIBRE',
  FRASES: 'FRASES',
  PRONUNCIATION: 'PRONUNCIATION',
}

const UserOutputCorrectionType = {
  BIEN: 'bien',
  MAL: 'mal',
  ININTELIGIBLE: 'ininteligible',
  CORRECTO: 'correcto',
}

const filterOutputsById = (id, outputs = []) => {
  if (outputs?.length > 0) {
    return outputs.filter((output) => output.id !== id)
  } else {
    return []
  }
}

const filtrarAllOutputsById = (
  id = 0,
  easyOutput = [],
  mediumOutput = [],
  hardOutput = [],
) => {
  const easyOutputFilter = filterOutputsById(id, easyOutput)
  const mediumOutputFilter = filterOutputsById(id, mediumOutput)
  const hardOutputFilter = filterOutputsById(id, hardOutput)

  return {
    easyOutput: easyOutputFilter || [],
    mediumOutput: mediumOutputFilter || [],
    hardOutput: hardOutputFilter || [],
  }
}

const getArrayRandom = (array = []) => {
  if (array?.length > 0) {
    return array[Math.floor(Math.random() * array.length)]
  }
  return []
}

const contadorCaracteres = (answer = '') => {
  const counter = answer.length
  if (counter > 500) {
    return false
  } else {
    return {
      counter,
    }
  }
}

export {
  TypesLevelsAnwerOutputs,
  UserOutputCorrectionType,
  filterOutputsById,
  filtrarAllOutputsById,
  getArrayRandom,
  contadorCaracteres,
}
