import React, {useState} from 'react'
import PropTypes from 'prop-types'

import './User.css'
import UserTooltip from './Tooltip/UserTooltip'

const Foto =
  'https://cdn0.iconfinder.com/data/icons/huge-basic-icons-part-3/512/Nick.png'
const User = ({user, label}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <span
      className="created-by-or-verified_by mb-1 mr-1"
      style={{backgroundImage: `url(${user?.photo?.url ? user.photo?.url : Foto} )`}}
      onMouseOver={() => setShowTooltip(true)}
      onMouseOut={() => setShowTooltip(false)}>
      {showTooltip && <UserTooltip label={label} name={user?.name || 'Other(!)'} />}
    </span>
  )
}

User.propTypes = {
  user: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
}

export default User
