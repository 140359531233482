import * as playback from './actionTypes'

export const fetchPlaybackInfo = () => ({
  type: playback.FETCH_PLAYBACK_INFO,
})

export const fetchPlaybackInfoSuccess = (playback_info) => ({
  type: playback.FETCH_PLAYBACK_INFO_SUCCESS,
  playback_info,
})

export const fetchPlaybackInfoError = (error) => ({
  type: playback.FETCH_PLAYBACK_INFO_ERROR,
  error,
})

export const fetchNowPlaying = () => ({
  type: playback.FETCH_NOW_PLAYING,
})

export const fetchNowPlayingSuccess = (now_playing) => ({
  type: playback.FETCH_NOW_PLAYING_SUCCESS,
  now_playing,
})

export const fetchNowPlayingError = (error) => ({
  type: playback.FETCH_NOW_PLAYING_ERROR,
  error,
})

export const setPlaybackPosition = (playback_position, playback_duration) => ({
  type: playback.UPDATE_PLAYBACK_POSITION,
  playback_position,
  playback_duration,
})

export const fetchAudioAnalysis = (track_id) => ({
  type: playback.FETCH_AUDIO_ANALYSIS,
  track_id,
})

export const fetchNextAudioAnalysis = (next_track_id) => ({
  type: playback.FETCH_NEXT_AUDIO_ANALYSIS,
  next_track_id,
})

export const fetchAudioAnalysisSuccess = (analysis) => ({
  type: playback.FETCH_AUDIO_ANALYSIS_SUCCESS,
  analysis,
})

export const fetchAudioAnalysisError = (error) => ({
  type: playback.FETCH_AUDIO_ANALYSIS_ERROR,
  error,
})

export const setPlayerState = (player) => ({
  type: playback.SET_PLAYER_STATE,
  player,
})

export const playerStateChanged = (player) => ({
  type: playback.PLAYER_STATE_CHANGED,
  player,
})

export const playerError = (error) => ({
  type: playback.PLAYER_ERROR,
  error,
})

export const transferPlayback = (device_id) => ({
  type: playback.TRANSFER_PLAYBACK,
  device_id,
})

export const transferPlaybackSuccess = (success) => ({
  type: playback.TRANSFER_PLAYBACK_SUCCESS,
  success,
})

export const transferPlaybackError = (error) => ({
  type: playback.TRANSFER_PLAYBACK_ERROR,
  error,
})

export const fetchPlaybackDevices = () => ({
  type: playback.FETCH_PLAYBACK_DEVICES,
})

export const fetchPlaybackDevicesSuccess = (devices) => ({
  type: playback.FETCH_PLAYBACK_DEVICES_SUCCESS,
  devices,
})

export const fetchPlaybackDevicesError = (error) => ({
  type: playback.FETCH_PLAYBACK_DEVICES_ERROR,
  error,
})

export const playTrack = (track_id, position) => ({
  type: playback.PLAY_TRACK,
  track_id,
  position,
})

export const playTrackSuccess = (track_id) => ({
  type: playback.PLAY_TRACK_SUCCESS,
  track_id,
})

export const playTrackError = (error) => ({
  type: playback.PLAY_TRACK_ERROR,
  error,
})

export const playContext = (context_uri, offset) => ({
  type: playback.PLAY_CONTEXT,
  context_uri,
  offset,
})

export const playContextSuccess = (context_uri, offset) => ({
  type: playback.PLAY_CONTEXT_SUCCESS,
  context_uri,
  offset,
})

export const playContextError = (error) => ({
  type: playback.PLAY_CONTEXT_ERROR,
  error,
})

export const togglePlayback = (is_playing, context_uri, position) => ({
  type: playback.TOGGLE_PLAYBACK,
  is_playing,
  context_uri,
  position,
})

export const togglePlaybackSuccess = (is_playing) => ({
  type: playback.TOGGLE_PLAYBACK_SUCCESS,
  is_playing,
})

export const togglePlaybackError = (error) => ({
  type: playback.TOGGLE_PLAYBACK_ERROR,
  error,
})

export const toggleShuffle = (shuffle_state) => ({
  type: playback.TOGGLE_SHUFFLE,
  shuffle_state,
})

export const toggleShuffleSuccess = (shuffle_state) => ({
  type: playback.TOGGLE_SHUFFLE_SUCCESS,
  shuffle_state,
})

export const toggleShuffleError = (error) => ({
  type: playback.TOGGLE_SHUFFLE_ERROR,
  error,
})

export const toggleRepeat = (repeat_state) => ({
  type: playback.TOGGLE_REPEAT,
  repeat_state,
})

export const toggleRepeatSuccess = (repeat_state) => ({
  type: playback.TOGGLE_REPEAT_SUCCESS,
  repeat_state,
})

export const toggleRepeatError = (error) => ({
  type: playback.TOGGLE_REPEAT_ERROR,
  error,
})

export const changeVolume = (volume_percent) => ({
  type: playback.CHANGE_VOLUME,
  volume_percent,
})

export const changeVolumeSuccess = (volume_percent) => ({
  type: playback.CHANGE_VOLUME_SUCCESS,
  volume_percent,
})

export const changeVolumeError = (error) => ({
  type: playback.CHANGE_VOLUME_ERROR,
  error,
})

export const skipToNextTrack = () => ({
  type: playback.SKIP_TO_NEXT,
})

export const skipToLastTrack = () => ({
  type: playback.SKIP_TO_LAST,
})

export const skipTrackSuccess = () => ({
  type: playback.SKIP_TRACK_SUCCESS,
})

export const trackChanging = (track_id) => ({
  type: playback.TRACK_CHANGING,
  track_id,
})

export const trackChanged = (item) => ({
  type: playback.TRACK_CHANGED,
  item,
})

export const playbackSeek = (position_ms) => ({
  type: playback.PLAYBACK_SEEK,
  position_ms,
})

export const playbackSeekSuccess = () => ({
  type: playback.PLAYBACK_SEEK_SUCCESS,
})

export const playbackSeekError = (error) => ({
  type: playback.PLAYBACK_SEEK_ERROR,
  error,
})

export const fetchLyrics = (title, artist) => ({
  type: playback.FETCH_LYRICS,
  title,
  artist,
})

export const fetchLyricsSuccess = (title, lyrics) => ({
  type: playback.FETCH_LYRICS_SUCCESS,
  title,
  lyrics,
})

export const fetchLyricsError = (error) => ({
  type: playback.FETCH_LYRICS_ERROR,
  error,
})

export const isTrackInLibrary = (track_id) => ({
  type: playback.CHECK_IF_SAVED_TRACK,
  track_id,
})

export const isTrackInLibrarySuccess = (liked) => ({
  type: playback.CHECK_IF_SAVED_TRACK_SUCCESS,
  liked,
})
export const isTrackInLibraryError = (error) => ({
  type: playback.CHECK_IF_SAVED_TRACK_ERROR,
  error,
})
