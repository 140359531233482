import React from 'react'
import './SpotifyLoginButton.css'
import SpotifyIcon from '../icons/SpotifyIcon.svg'
import SpotifyIconSmall from '../icons/Spotify.svg'

const SpotifyLoginButton = ({onClick, isToggledLeftbar}) => {
  return (
    <button className="btn-login-spotify" onClick={onClick}>
      {isToggledLeftbar ? (
        <img src={SpotifyIcon} alt={SpotifyIcon} />
      ) : (
        <img src={SpotifyIconSmall} alt={SpotifyIconSmall} />
      )}
    </button>
  )
}

export default SpotifyLoginButton
