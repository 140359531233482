import * as api from './requests'
import * as actions from './actions'
import * as types from './actionTypes'

const fetchItemsAction = () => (dispatch, getState) => {
  api
    .fetchItems()
    .then((response) => {
      console.log(response)
      dispatch(actions.fetchItemsSuccess(response.data))
    })
    .catch((error) => dispatch(actions.fetchItemsFailure(error)))
}

export default (store) => (next) => (action) => {
  const result = next(action)
  switch (action.type) {
    case types.FETCH_ITEMS:
      store.dispatch(fetchItemsAction())
      break
    default:
      break
  }
  return result
}
