import {ConversationType} from '../lib/coco/helperData'

const getConversationsIncludesUser = (userConver, conversation) => {
  const {participating_users = []} = conversation
  const userExist = participating_users.find(
    (user) => user?.id === userConver?.user_id,
  )
  if (userExist?.id) {
    return true
  }
  return false
}

const findCoversationWithUser = (user, conversations) => {
  if (conversations?.length > 0) {
    const conversationsPersonal = conversations.filter(
      (c) => c.conversation_type === ConversationType.PERSONAL,
    )
    let conversationWithUser = null
    conversationsPersonal.map((conver) => {
      if (getConversationsIncludesUser(user, conver)) {
        conversationWithUser = conver
      }
      return conver
    })
    return conversationWithUser
  }
  return null
}

// revisa si la conversacion pertenece al usuario actual
const isConversationsIncludesCurrentUser = (currentUser, data) => {
  const {user: userOwner, participating_users = []} = data
  const participants = [...participating_users, userOwner]

  const userExist = participants.find((user) => user?.id === currentUser?.id)
  if (userExist?.id) {
    return true
  }
  return false
}

const conversationUpdateHandler = (conversations) => {
  let list = {},
    conversation

  for (var i = 0; i < conversations.length; i++) {
    conversation = conversations[i]
    let id = conversation.id
    let compare = null

    if (id in list) {
      compare = conversations
        .filter((c) => c.id === id)
        .sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))

      conversations.splice(
        conversations.findIndex(
          (c) => c.id === id && c.updated_at === compare[0].updated_at,
        ),
        1,
      )
    } else {
      list[id] = true
    }
  }

  return conversations
}

const marckRealyTrack = (tracks = [], spotifyId) => {
  return tracks.map((track) => {
    if (track.spotify_id === spotifyId) {
      track['state'] = 'realy'
    }
    return {...track}
  })
}

const replaceTrackFromLike = (track, list) => {
  const tracksMapped = list.map((t) => {
    if (t.spotify_id === track.spotify_id) {
      t.id = track.id
      t.user_input_id = track.user_input_id
    }

    return t
  })

  return tracksMapped || []
}

const mergeMyLikes = (tracks = []) => {
  const likes = {
    titulo: 'mis me gusta',
    like: true,
  }
  tracks.splice(2, 0, likes)
  return tracks
}

// retorna un progreso
const getProgressConverter = (finish, total) => {
  const daily_progress = total !== 0 ? (finish * 100) / total : 0
  return Math.round(daily_progress)
}

const MEDIATEXT_IN_LOCAL_STORAGE = 'MEDIATEXT_IN_LOCAL_STORAGE'

// se guarda todo el mediatext en local Storage, cuando el navegador se refresca se pierde
// el mediatext actual, con este metodo se resuelve
const SaveMediaTextInLocalStorage = (mediaText) => {
  const mediaTextLocalStorage = {
    mediaText: mediaText,
  }
  try {
    localStorage.setItem(
      MEDIATEXT_IN_LOCAL_STORAGE,
      JSON.stringify(mediaTextLocalStorage),
    )
  } catch (e) {
    console.debug('ERROR EN LOCAL STORAGE', e)
  }
}

const ObtenerMediatextDeLocalStorage = () => {
  const media_text_de_local_storage = JSON.parse(
    localStorage.getItem(MEDIATEXT_IN_LOCAL_STORAGE),
  )
  return media_text_de_local_storage || null
}

const ActualizarIndicesAgregadosEnLocalStorage = (indicesYaAgregados) => {
  const media_text_de_local_storage = ObtenerMediatextDeLocalStorage()
  if (media_text_de_local_storage) {
    let {mediaText} = media_text_de_local_storage
    mediaText = {
      ...mediaText,
      indices_ya_agregados: indicesYaAgregados,
    }
    SaveMediaTextInLocalStorage(mediaText)
  }
}

const ActualizarLineasEnLocalStorage = (lineas, newWordList) => {
  try {
    const media_text_de_local_storage = ObtenerMediatextDeLocalStorage()
    if (media_text_de_local_storage) {
      let {mediaText} = media_text_de_local_storage
      mediaText = {
        ...mediaText,
        lines: lineas,
        word_list: newWordList,
      }
      // actualizar lineas en localStorage
      SaveMediaTextInLocalStorage(mediaText)
    }
  } catch (error) {
    console.debug('Error al actualizar lineas')
  }
}

// local storage de los tracks en colas
const TRACKS_IN_COLA_PLAYER = 'TRACKS_IN_COLA_PLAYER'

// se guarda todo el mediatext en local Storage, cuando el navegador se refresca se pierde
// el mediatext actual, con este metodo se resuelve
const SaveMediaTracksInColaStorage = (tracks) => {
  try {
    const tracksInCola = {
      tracks,
    }
    localStorage.setItem(TRACKS_IN_COLA_PLAYER, JSON.stringify(tracksInCola))
  } catch (e) {
    console.debug('ERROR EN LOCAL STORAGE', e)
  }
}

const getTracksInColaLocalStorage = () => {
  const tracksInCola = JSON.parse(localStorage.getItem(TRACKS_IN_COLA_PLAYER))
  if (tracksInCola?.tracks) {
    return tracksInCola?.tracks
  } else {
    return []
  }
}

export {
  getConversationsIncludesUser,
  findCoversationWithUser,
  isConversationsIncludesCurrentUser,
  conversationUpdateHandler,
  replaceTrackFromLike,
  mergeMyLikes,
  marckRealyTrack,
  getProgressConverter,
  ActualizarIndicesAgregadosEnLocalStorage,
  ActualizarLineasEnLocalStorage,
  SaveMediaTextInLocalStorage,
  ObtenerMediatextDeLocalStorage,
  SaveMediaTracksInColaStorage,
  getTracksInColaLocalStorage,
}
