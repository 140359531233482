import React from 'react'
import RespuestasDeOtrosUsuarios from '../Output/RespuestasDeUsuarios/RespuestasDeOtrosUsuarios'

const UserOutputComments = ({outputItem, user_output}) => {
  return (
    <RespuestasDeOtrosUsuarios
      outputItemId={outputItem.id}
      isCommentsVisible={true}
      userOutputSelectDefault={user_output.id || null}
    />
  )
}

export default UserOutputComments
