import * as actions from './actionTypes'

export const fetchItems = () => ({
  type: actions.FETCH_ITEMS,
})

export const fetchItemsSuccess = (items) => ({
  type: actions.FETCH_ITEMS_SUCCESS,
  items,
})

export const fetchItemsFailure = (error) => ({
  type: actions.FETCH_ITEMS_FAILURE,
  error,
})

export const selectDomain = (domain) => ({
  type: actions.SELECT_DOMAIN,
  domain,
})

export const AddItemToDomains = (item) => ({
  type: actions.ADD_ITEM_TO_DOMAIN,
  item,
})

export const DeleteItem = (itemId) => ({
  type: actions.DELETE_ITEM,
  itemId,
})

export const createItem = (item) => ({
  type: actions.CREATE_ITEM,
  item,
})

export const createItemSuccess = (item) => ({
  type: actions.CREATE_ITEM_SUCCESS,
  item,
})

export const createItemFailure = (error) => ({
  type: actions.CREATE_ITEM_FAILURE,
  error,
})

export const updateItem = (item) => ({
  type: actions.UPDATE_ITEM,
  item,
})

export const updateItemsSuccess = (item) => ({
  type: actions.UPDATE_ITEM_SUCCESS,
  item,
})

export const updateItemFailure = (error) => ({
  type: actions.UPDATE_ITEM_FAILURE,
  error,
})

export const setActiveDomain = (id) => ({
  type: actions.SET_ACTIVE_DOMAIN,
  active_domain_id: id,
})
