export default {
  en: {
    'output.select-questions': 'Select a Question',
    'output.load5': 'Load 5 More',
    'output.load10': 'Load 10 More',
    'output.load15': 'Load 15 More',
    'output.comment': 'Comment',
    'output.send': 'Send Answer',
    'output.check': 'Check Answer',
    'output.noedit': 'Leave without editing',
    'output.edit': 'Save Changes',
    'flashcard.already-edited':
      'You already verified this flashcard, you can edit it if you wish.',
    'flashcard.congratulations':
      "Congratulations! You finished today's flashcards!.",
    'flashcard.go-rest': 'There are no more flashcards to review! Go rest :)',
    'flashcard.there-is': 'There is',
    'flashcard.there-are': 'There are',
    'flashcard.left': 'flashcards waiting for review',
    'flashcard.done': 'Done',
    'input.select': 'Select a Song',
    'input.finish': 'Done',
  },
  es: {
    'output.select-questions': 'Selecciona una pregunta',
    'output.load5': 'Cargar 5 Más',
    'output.load10': 'Cargar 10 Más',
    'output.load15': 'Cargar 15 Más',
    'output.comment': 'Comentar',
    'output.send': 'Enviar respuesta',
    'output.check': 'Revisar respuesta',
    'output.noedit': 'Salir sin editar',
    'output.edit': 'Guardar Edición',
    'flashcard.already-edited':
      'Esta Flashcard ya lo verificaste, puedes editarlo si deseas.',
    'flashcard.congratulations': '¡Felicidades! Terminaste los flashcards de hoy.',
    'flashcard.go-rest': '¡Ya no hay más flashcards por revisar! Ve a descansar :)',
    'flashcard.there-is': 'Hay',
    'flashcard.there-are': 'Hay',
    'flashcard.left': 'flashcards esperando revisión',
    'flashcard.done': 'Listo',
    'input.select': 'Selecciona una Cancion',
    'input.finish': 'Finalizar',
  },
  pt: {
    'output.select-questions': 'Selecione uma pergunta',
    'output.load5': 'Carregar mais 5',
    'output.load10': 'Carregar mais 10',
    'output.load15': 'Carregar mais 15',
    'output.comment': 'Comente',
    'output.send': 'Enviar resposta',
    'output.check': 'Rever resposta',
    'output.noedit': 'Sair sem editar',
    'output.edit': 'Salvar edição',
    'flashcard.already-edited':
      'Este cartão de memória flash já foi verificado, você pode editá-lo, se desejar.',
    'flashcard.congratulations': 'Parabéns! Você terminou os cartões de hoje.',
    'flashcard.go-rest': 'Não há mais flashcards para revisar! Vá descansar :)',
    'flashcard.there-is': 'Existe',
    'flashcard.there-are': 'Existe',
    'flashcard.left': 'flashcards aguardando revisão',
    'flashcard.done': 'Pronto',
    'input.select': 'Selecione uma música',
    'input.finish': 'Concluir',
  },
  fr: {
    'output.select-questions': 'Sélectionnez une question',
    'output.load5': 'Charger 5 de plus',
    'output.load10': 'Charger 10 de plus',
    'output.load15': 'Charger 15 de plus',
    'output.comment': 'Commentaire',
    'output.send': 'Envoyer une réponse',
    'output.check': 'Revoir la réponse',
    'output.noedit': 'Quitter sans modification',
    'output.edit': "Enregistrer l'édition",
    'flashcard.already-edited':
      'Cette Flashcard a déjà été vérifiée, vous pouvez la modifier si vous le souhaitez.',
    'flashcard.congratulations':
      "Félicitations! Vous avez terminé les cartes mémoire d'aujourd'hui.",
    'flashcard.go-rest': "l n'y a plus de flashcards à revoir! Va te reposer :)",
    'flashcard.there-is': 'Il y a',
    'flashcard.there-are': 'Il y a',
    'flashcard.left': "cartes mémoire en attente d'examen",
    'flashcard.done': 'Prêt',
    'input.select': 'Sélectionnez une chanson',
    'input.finish': 'Terminer',
  },
}
