import React from 'react'
import PropTypes from 'prop-types'
import './Tooltip.css'

const Tooltip = (props) => {
  const {text, position, tooltip, children, styles} = props
  return (
    <div tooltip={tooltip} toltip-position={position} style={styles}>
      {text || children}
    </div>
  )
}

Tooltip.propTypes = {
  position: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.element,
  tooltip: PropTypes.string.isRequired,
}

Tooltip.defaultProps = {
  position: 'top',
  styles: {color: '#FFCA42', display: 'inline-block'},
}

export default Tooltip
