import {
  GET_TRANSLATION_AND_EXAMPLES_ERROR,
  GET_TRANSLATION_AND_EXAMPLES,
  LOADING_TRANSLATION,
  LOADING_API,
} from './actionTypes'

const initialStore = {
  phrase: '',
  translation: '',
  example_sentences: [],
  is_loading: false,
}

export default function (state = initialStore, action) {
  switch (action.type) {
    case LOADING_TRANSLATION: {
      return {
        ...state,
        phrase: action.phrase,
      }
    }

    case LOADING_API: {
      return {
        ...state,
        is_loading: true,
      }
    }

    case GET_TRANSLATION_AND_EXAMPLES: {
      return {
        ...state,
        translation: action.translation,
        example_sentences: action.frasesEjemplos,
        is_loading: false,
      }
    }

    case GET_TRANSLATION_AND_EXAMPLES_ERROR: {
      return {
        ...state,
        traduccion: '',
        example_sentences: [],
        is_loading: false,
      }
    }

    default:
      return state
  }
}
