import {useEffect, useState} from 'react'
import {isNavigator, off, on} from '../helpers/utils'

const nav = isNavigator ? navigator : undefined

const conn = nav && (nav.connection || nav.mozConnection || nav.webkitConnection)

const getConnectionState = (previousState) => {
  const online = nav?.onLine
  const previousOnline = previousState?.online

  return {
    online, // boolean
    previous: previousOnline,
    since: online !== previousOnline ? new Date() : previousState?.since,
    downlink: conn?.downlink, // number
    downlinkMax: conn?.downlinkMax, // number
    effectiveType: conn?.effectiveType, // 'slow-2g' | '2g' | '3g' | '4g'
    rtt: conn?.rtt, // number
    saveData: conn?.saveData, // boolean
    type: conn?.type, // 'bluetooth','cellular', 'ethernet', 'none','wifi','wimax', 'other','unknown';
  }
}

const useNetworkState = (initialState) => {
  const [state, setState] = useState(initialState ?? getConnectionState)

  useEffect(() => {
    const handleStateChange = () => {
      setState(getConnectionState)
    }

    on(window, 'online', handleStateChange, {passive: true})
    on(window, 'offline', handleStateChange, {passive: true})

    if (conn) {
      on(conn, 'change', handleStateChange, {passive: true})
    }

    return () => {
      off(window, 'online', handleStateChange)
      off(window, 'offline', handleStateChange)

      if (conn) {
        off(conn, 'change', handleStateChange)
      }
    }
  }, [])

  return state
}

export default useNetworkState
