import React from 'react'
import PropTypes from 'prop-types'

import './BlueOutlineButton.css'

const BlueOutlineButton = (props) => {
  const {text, active, onClick, onMouseOver, onMouseOut} = props

  return (
    <button
      className={`${active ? 'blue-button' : 'blue-outline-button'} btn-sm`}
      onClick={onClick}
      onMouseOver={onMouseOver && onMouseOver}
      onMouseOut={onMouseOut}>
      {text}
    </button>
  )
}

BlueOutlineButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default BlueOutlineButton
