import React from 'react'
import IconNewUser from './Icons/IconNewUser.svg'

export const ButtonNewParticipant = ({onClick, style = {}}) => {
  return (
    <div className="click-me" onClick={onClick} style={style}>
      <span>
        <img src={IconNewUser} alt="Agregar usuario" />
      </span>
    </div>
  )
}
