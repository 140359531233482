import React, {useEffect, useState} from 'react'
import RespuestaDelUsuario from './RespuestaDelUsuario'
import OtrosUsuarios from './OtrosUsuarios'
import Comentarios from './Comentarios'
import PropTypes from 'prop-types'
import useBooleanToggler, {
  useWorkingIndicator,
} from '../../../hooks/useBooleanToggler'
import {getResponsesOtherStudents} from '../../../lib/coco/OutputsApi'
import {ALERT_TYPES, fullAlert} from '../../../Services/Alert'
import LoadingCoco from '../../Animaciones/LoadingCoco'
import useUser from '../../../hooks/useUser'
import './RespuestasDeOtrosUsuarios.css'

const RespuestasDeOtrosUsuarios = ({
  outputItemId,
  isCommentsVisible = false,
  userOutputSelectDefault = null,
}) => {
  const [activeIndexResponse, setActiveIndexResponse] = useState(isCommentsVisible)
  const [likesCount, setLikesCount] = useState(0)
  const [userOutputLikeId, setUserOutputLikeId] = useState(null)
  const [comments, setComments] = useState([])
  const [userResponse, setUserResponse] = useState({})
  const [user, setUser] = useState(null)
  const [userResponseTitle, setUserResponseTitle] = useState('')
  const [userResponses, setUserResponses] = useState([])
  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const {
    isToggled: IsToogledComments,
    toggle: toggleShowComments,
  } = useBooleanToggler({initialValue: isCommentsVisible})

  const {name: nameCurrentUser, getPhotoCurrentUser} = useUser()

  useEffect(() => {
    if (outputItemId) {
      getStudentsResonses()
    }
    // eslint-disable-next-line
  }, [outputItemId])

  const getStudentsResonses = () => {
    startWork()
    getResponsesOtherStudents(outputItemId).then((response) => {
      finishWork()
      if (response.studentResponses) {
        const {studentResponses = []} = response
        if (studentResponses?.length > 0) {
          let indexToSelect = 0

          if (userOutputSelectDefault) {
            studentResponses.forEach((response, index) => {
              if (response.respuesta.id === userOutputSelectDefault) {
                indexToSelect = index
              }
              return response
            })
          }

          const firstResponse = studentResponses[indexToSelect]
          setActiveIndexResponse(indexToSelect)
          setUserResponseTitle(firstResponse.output_item?.title || '')
          setUser(firstResponse.usuario?.name || '')
          setComments(firstResponse.comentarios)
          setLikesCount(firstResponse.likes_count || 0)
          setUserOutputLikeId(firstResponse.user_output_like_id || null)
          setUserResponse(firstResponse.respuesta)
          setUserResponses(studentResponses)
        }
      } else {
        fullAlert(
          ALERT_TYPES.ERROR,
          `${response.message}, No se obtubieron otras respuestas`,
        )
      }
    })
  }

  const updateUserResponse = (userResponseId) => {
    const userResponse = userResponses[userResponseId]
    setActiveIndexResponse(userResponseId)
    setLikesCount(userResponse.likes_count || 0)
    setUserOutputLikeId(userResponse.user_output_like_id || null)
    setComments(userResponse.comentarios || [])
    setUserResponse(userResponse.respuesta)
    setUser(userResponse.usuario.name || '')
    setUserResponseTitle(userResponse.output_item.title || '')
    // IsToogledComments && toggleShowComments()
  }

  // se debe actualizar el array completo ya que al ir y volver a seleccionar necesita tener ese commnet add
  const updateResponsesComments = (commentsUpdate) => {
    const activeUserResponse = userResponses[activeIndexResponse]
    if (activeUserResponse) {
      userResponses[activeIndexResponse].comentarios = commentsUpdate
    }
  }

  const pushComment = (comment) => {
    const newComment = {
      comment: comment,
      //commenter_id: No es necesario por ahora
      commenter_name: nameCurrentUser,
      //TODO: Agregar la foto del usuario
      commenter_photo: {url: getPhotoCurrentUser()},
      time_difference: '0 min',
    }

    const newComments = [...comments, newComment]
    setComments(newComments)
    updateResponsesComments(newComments)
  }

  const isResponseCurrentAnswer = () => {
    if (userResponse) {
      return true
    } else {
      return false
    }
  }

  const updateLikeUserOutput = (userOutputLike) => {
    const activeUserResponse = userResponses[activeIndexResponse]
    if (activeUserResponse) {
      // suma o quita
      const totalCounts = likesCount + (userOutputLike ? +1 : -1)
      // update array global
      userResponses[activeIndexResponse].likes_count = totalCounts
      userResponses[activeIndexResponse].user_output_like_id = userOutputLike
      // update selects
      setLikesCount(totalCounts)
      setUserOutputLikeId(userOutputLike)
    }
  }

  return (
    <div className="container">
      {isWorking ? (
        <LoadingCoco />
      ) : (
        <>
          {isResponseCurrentAnswer() && (
            <RespuestaDelUsuario
              updateLikeUserOutput={updateLikeUserOutput}
              toggleShowComments={toggleShowComments}
              likesCount={likesCount}
              userOutputLikeId={userOutputLikeId}
              cantidadDeComentarios={comments.length}
              userResponse={userResponse}
              user={user}
              userResponseTitle={userResponseTitle}
            />
          )}

          <div className="list-comments-output">
            <div className="scroll-coco">
              {isResponseCurrentAnswer() && (
                <>
                  {IsToogledComments && (
                    <Comentarios
                      comments={comments}
                      commentableId={userResponse.id ? userResponse.id : 0}
                      commentableType="UserOutput"
                      pushComment={pushComment}
                    />
                  )}
                </>
              )}
              <OtrosUsuarios
                userResponses={userResponses}
                updateUserResponse={updateUserResponse}
                getPhotoCurrentUser={getPhotoCurrentUser}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

RespuestasDeOtrosUsuarios.propTypes = {
  outputItemId: PropTypes.number,
}

export default RespuestasDeOtrosUsuarios
