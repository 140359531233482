import React, {useRef, useState} from 'react'
import './ContextMenuPlaylist.css'
import PointsIcon from './Icons/points.svg'
import {FormattedMessage, useIntl} from 'react-intl'
import useLikeTracks from '../../hooks/useLikeTrack'
import {
  addOneToPlaylist,
  removeTrackOfPlaylist,
} from '../../Redux/PlayLists/playlistActions'
import {addTrackToPlaylist, removeTrackToPlaylist} from '../../lib/coco/InputApi'
import useOnClickOutside from '../../hooks/useClickOutside'
import usePlaylist from '../../hooks/usePlaylist'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import shareIcon from '../Icons/share.svg'
import ContextMenuShareTrack from '../Shared/ContextMenuShareTrack'
import {useModalSpotifyAuth} from '../../hooks/useSpotifyModalAuth'
import useAlert, {TOAST_TYPE} from '../../hooks/useAlert'

const ContextMenuPlaylist = (props) => {
  const {
    shareTrack,
    createNewPlaylist,
    columsShow,
    spotify_id,
    titulo,
    id,
    image_url,
    artistas,
    user_input,
    showMediatext,
    type,
    playlist_media_text_id,
    customIcon,
  } = props
  const {toggleSpotifyModalAuth, isSpotifyConnected} = useModalSpotifyAuth()
  const spotifyStatus = isSpotifyConnected()
  const [isContextActive, setIsContextActive] = useState(false)
  const [isAddPlaylist, setIsAddPlaylist] = useState(false)
  const [isShareTrack, setIsShareTrack] = useState(false)
  const {playlists = []} = usePlaylist()
  const {sendTrackToMyLikes, deleteTrackFromMyLikes} = useLikeTracks()
  const history = useHistory()
  const dispatch = useDispatch()
  const {showMessage} = useAlert()
  const intl = useIntl()

  const myRef = useRef()

  useOnClickOutside(myRef, () => handleClickOutside())

  const toggleMenu = () => {
    if (isContextActive && (isAddPlaylist || isShareTrack)) {
      setIsAddPlaylist(false)
      setIsShareTrack(false)
    }
    setIsContextActive(!isContextActive)
  }

  const toggleSubMenu = () => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
    } else {
      setIsAddPlaylist(!isAddPlaylist)
      setIsShareTrack(false)
    }
  }

  const toggleShareTrack = () => {
    setIsShareTrack(!isShareTrack)
    setIsAddPlaylist(false)
  }

  const handleClickOutside = () => {
    if (!isContextActive) {
      return
    }
    setIsContextActive(false)
    setIsAddPlaylist(false)
    setIsShareTrack(false)
  }

  const addToPlaylist = (playlist) => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
    } else {
      addTrackToPlaylist(
        playlist.id,
        id,
        spotify_id,
        titulo,
        artistas,
        image_url,
      ).then((response) => {
        if (!response.message) {
          // enviar a redux el nuevo track + 1
          dispatch(addOneToPlaylist(id))
          showMessage(
            `${titulo} ${intl.formatMessage({
              id: 'dropdown.add-playlist-success',
              defaultMessage: 'was added to',
            })} ${playlist.name}`,
          )
        } else {
          showMessage(`¡Error! Toma captura: ${response.message}`, TOAST_TYPE.ERROR)
        }
        toggleMenu()
      })
    }
  }

  const removeOfPlaylist = () => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
    } else if (playlist_media_text_id) {
      removeTrackToPlaylist(playlist_media_text_id).then((response) => {
        if (!response.message) {
          dispatch(removeTrackOfPlaylist(spotify_id))
          showMessage(
            `${titulo} ${intl.formatMessage({
              id: 'dropdown.delete-from-playlist-success',
              defaultMessage: 'successfully removed',
            })}`,
          )
        } else {
          showMessage(
            `${response.message}, ${titulo} ${intl.formatMessage({
              id: 'dropdown.delete-from-playlist-error',
              defaultMessage: 'it was not eliminated',
            })}`,
            TOAST_TYPE.ERROR,
          )
        }
      })
    }
    toggleMenu()
  }

  const addLike = () => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
    } else {
      sendTrackToMyLikes(spotify_id, titulo, image_url, artistas)
      toggleMenu()
    }
  }

  const removeLike = () => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
    } else {
      deleteTrackFromMyLikes(spotify_id, user_input, titulo)
      toggleMenu()
    }
  }

  const AddTrackItem = () => {
    return (
      <>
        {!user_input ? (
          <div className="context-menu-playlist-item" onClick={addLike}>
            <FormattedMessage
              id={'dropdown.like'}
              defaultMessage={'Add to my Likes'}
            />
          </div>
        ) : (
          <div className="context-menu-playlist-item" onClick={removeLike}>
            <FormattedMessage
              id={'dropdown.like_delete'}
              defaultMessage={'delete from my Likes'}
            />
          </div>
        )}
        <div className="border-divider-item" />
      </>
    )
  }

  return (
    <div className="contenedor-context-menu-playlist" ref={myRef}>
      <button className="btn-context-menu-playlist" onClick={toggleMenu}>
        <img
          src={customIcon ? customIcon : PointsIcon}
          alt={customIcon ? customIcon : PointsIcon}
        />
      </button>

      {isContextActive && (
        <div className="arrow-over-container">
          <div
            className={`${
              type === 'dashboard'
                ? 'context-menu-playlist-dashboard'
                : 'context-menu-playlist'
            }`}>
            <div className="context-menu-playlist-item" onClick={showMediatext}>
              <FormattedMessage
                id={'dropdown.play'}
                defaultMessage={'Study (Play)'}
              />
            </div>

            <div className="border-divider-item" />

            {columsShow?.sacarPlaylist && (
              <>
                <div
                  className="context-menu-playlist-item"
                  onClick={removeOfPlaylist}>
                  <FormattedMessage
                    id={'dropdown.remove'}
                    defaultMessage={'Remove from Playlist'}
                  />
                </div>
                <div className="border-divider-item"></div>
              </>
            )}

            <div className="context-menu-playlist-item" onClick={toggleSubMenu}>
              <FormattedMessage
                id={'dropdown.add'}
                defaultMessage={'Add to Playlist'}
              />
            </div>

            <div className="border-divider-item" />

            <div className="context-menu-playlist-item" onClick={toggleShareTrack}>
              <FormattedMessage id={'dropdown.shared'} defaultMessage={'Share'} />
              <img src={shareIcon} alt="share" />
            </div>

            <div className="border-divider-item" />

            {artistas?.length > 0 &&
              artistas.map((a) => (
                <div key={a.spotify_id}>
                  <div
                    className="context-menu-playlist-item"
                    onClick={() => history.push(`/artista/${a.spotify_id}`)}
                    title={a.nombre}>
                    <FormattedMessage id={'dropdown.go'} defaultMessage={'Go to'} />
                    {` ${a.nombre}`}
                  </div>
                  <div className="border-divider-item" />
                </div>
              ))}

            {/* si no tiene like, se agrega */}
            <AddTrackItem />
          </div>
        </div>
      )}

      {isAddPlaylist && (
        <div
          className={`${
            type === 'dashboard'
              ? 'context-sub-menu-playlist-dashboard'
              : 'context-sub-menu-playlist'
          }`}>
          {/* solo se mostrara en dashboard */}
          {type === 'dashboard' && (
            <>
              <div className="context-menu-playlist-item" onClick={toggleSubMenu}>
                <FormattedMessage id={'dropdown.back'} defaultMessage={'Go back'} />
              </div>
              <div className="border-divider-item" />
            </>
          )}

          <div className="context-menu-playlist-item" onClick={createNewPlaylist}>
            <FormattedMessage
              id={'dropdown.create-playlist'}
              defaultMessage={'Create Playlist'}
            />
          </div>

          <div className="border-divider-item" />

          {playlists?.length > 0 &&
            playlists.map((playlist, index) => (
              <React.Fragment key={playlist.id}>
                <div
                  className="context-menu-playlist-item"
                  onClick={() => addToPlaylist(playlist)}>
                  {playlist.name}
                </div>
                {playlists.length !== index + 1 && (
                  <div className="border-divider-item" />
                )}
              </React.Fragment>
            ))}
        </div>
      )}

      {isShareTrack && (
        <div
          className={`${
            type === 'dashboard'
              ? 'context-sub-menu-playlist-dashboard'
              : 'context-sub-menu-playlist'
          }`}>
          {/* solo se mostrara en dashboard */}
          {type === 'dashboard' && (
            <>
              <div className="context-menu-playlist-item" onClick={toggleShareTrack}>
                <FormattedMessage id={'dropdown.back'} defaultMessage={'Go back'} />
              </div>
              <div className="border-divider-item" />
            </>
          )}

          <ContextMenuShareTrack shareTrack={shareTrack} spotifyId={spotify_id} />
        </div>
      )}
    </div>
  )
}

export default ContextMenuPlaylist
