import React from 'react'
import {NavLink} from 'react-router-dom'
import './TopBarNavigation.css'

import IconHome from './Icons/IconHome.svg'
import NewIconPlaylist from '../../Icons/newIconPlaylist.svg'
import newIconListening from '../../Icons/newIconListening.svg'
import newIconMemorize from '../../Icons/newIconMemorize.svg'
import newIconPractic from '../../Icons/newIconPractic.svg'
import newIconInbox from '../../Icons/newIconInbox.svg'

const NavLinkActive = {
  background: 'rgba(38, 125, 255, 0.2)',
}

const TopBarNavigation = () => {
  return (
    <div className="topbar__navigation__main">
      <NavLink
        to="/dashboard"
        className="topbar__navigation__navlink home-target"
        activeStyle={NavLinkActive}
        title="Home">
        <img src={IconHome} alt={IconHome} />
      </NavLink>
      <NavLink
        to="/mis-playlists"
        className="topbar__navigation__navlink playlist-target"
        activeStyle={NavLinkActive}
        title="Playlists">
        <img src={NewIconPlaylist} alt={NewIconPlaylist} />
      </NavLink>
      <NavLink
        to="/inbox"
        className="topbar__navigation__navlink inbox-target"
        activeStyle={NavLinkActive}
        title="Inbox">
        <img src={newIconInbox} alt={newIconInbox} />
      </NavLink>
      <NavLink
        to="/media_text"
        className="topbar__navigation__navlink input-target"
        activeStyle={NavLinkActive}
        title="Escuchar">
        <img src={newIconListening} alt={newIconListening} />
      </NavLink>
      <NavLink
        to="/study-flashcard"
        className="topbar__navigation__navlink flashcard-target"
        activeStyle={NavLinkActive}
        title="Memorizar">
        <img src={newIconMemorize} alt={newIconMemorize} />
      </NavLink>
      <NavLink
        to="/output"
        className="topbar__navigation__navlink output-target"
        activeStyle={NavLinkActive}
        title="Practicar">
        <img src={newIconPractic} alt={newIconPractic} />
      </NavLink>
    </div>
  )
}

export default TopBarNavigation
