import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {
  getInputsByParam,
  setParamsSearchInput,
} from '../Redux/searchInputs/searchActions'

const useSearchInputs = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.serchInputs, shallowEqual)

  const dispatchGetInputsNyParams = (params) => {
    dispatch(getInputsByParam(params))
  }

  const dispatchSetParams = (params) => {
    dispatch(setParamsSearchInput(params))
  }

  return {...state, dispatchGetInputsNyParams, dispatchSetParams}
}

export default useSearchInputs
