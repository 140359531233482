import React from 'react'
import './Peculiaridades.css'
import Tooltip from '../Tooltip/Tooltip'

const PeculiaridadNotaEspecial = ({onclick, tooltip, side}) => {
  const titulo = 'notaespecial'
  const is_active = tooltip !== ''
  const funcion_oclick_exist = !!onclick

  return (
    <>
      {funcion_oclick_exist ? (
        <Tooltip tooltip={tooltip ? tooltip : 'Agregar nota.'} position={'buttom'}>
          <button
            className={`btn-peculiaridades btn-nota-especial ${
              is_active && `btn-nota-especial-active`
            } ${side === 'back' && 'black'}`}
            onClick={() => onclick()}>
            {`#${titulo}`} <span></span>{' '}
          </button>
        </Tooltip>
      ) : (
        <Tooltip tooltip={tooltip} position={'top'}>
          <div className="nota-especial-palpitando">{`#${titulo}`}</div>
        </Tooltip>
      )}
    </>
  )
}

export default PeculiaridadNotaEspecial
