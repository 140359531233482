import {getSortedConversations} from '../../Components/Comunidad/helpers'
import {conversationUpdateHandler} from '../helpers'
import * as actions from './actionTypes'

const READY = 'READY'
const OPENING = 'OPENING'
const CLOSED = 'CLOSED'
const SUBSCRIBING = 'SUBSCRIBING'

const initialState = {
  conversations: [], // todas las conversaciones
  activeConversationId: null, // id de conversacion activa
  isMiniChatActive: false, // abrir el minichat
  isHilosActive: false, // hilos activos false, mensajes activos true
  isMiniChatSelectionActive: true, // si esta en seleccionando una conversacion true, si esta en el detalle de la conversacion false
  messageCitado: null, // responder a un mensaje
  errors: [],
  connection: {
    state: CLOSED,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.RECEIVED_CONVERSATIONS: {
      const {activeConversationId} = state
      const {conversations = []} = action

      const conversationsSorted = getSortedConversations(conversations)

      const newActiveConversationId = activeConversationId
        ? activeConversationId
        : conversationsSorted[0]?.id

      return {
        ...state,
        conversations: conversationsSorted,
        activeConversationId: newActiveConversationId,
      }
    }

    case actions.SET_MESSAGE_CITADO: {
      return {
        ...state,
        messageCitado: action.message,
      }
    }

    case actions.REMOVE_MESSAGE_CITADO: {
      return {
        ...state,
        messageCitado: null,
      }
    }

    case actions.RECEIVED_CONVERSATION_DATA: {
      // action.data es una conversación que esta cambiando
      return {
        ...state,
        conversations: conversationUpdateHandler([
          ...state.conversations,
          action.data,
        ]),
      }
    }

    case actions.SET_ACTIVE_CONVERSATION_ID: {
      return {
        ...state,
        activeConversationId: action.conversationId,
        messageCitado: null,
      }
    }

    case actions.SET_MINI_CHAT_ACTIVE: {
      return {
        ...state,
        isMiniChatActive: action.isMiniChatActive,
      }
    }

    case actions.SET_HILOS_ACTIVE: {
      return {
        ...state,
        isHilosActive: action.isHilosActive,
      }
    }

    case actions.SET_IS_MINICHAT_SELECTION_ACTIVE: {
      return {
        ...state,
        isMiniChatSelectionActive: action.isMiniChatSelectionActive,
      }
    }

    case actions.CONVERSATIONS_CONNECTION_OPENING: {
      return {
        ...state,
        connection: {state: OPENING},
      }
    }
    case actions.CONVERSATIONS_CONNECTION_SUBSCRIBING: {
      return {
        ...state,
        connection: {state: SUBSCRIBING},
      }
    }
    case actions.CONVERSATIONS_CONNECTION_READY: {
      return {
        ...state,
        connection: {state: READY},
      }
    }

    default:
      return state
  }
}
