import React from 'react'
import PropTypes from 'prop-types'
import Participants from './Participants'
import DefinirHilo from '../DefinirHilo'
import ResolveThread from './Buttons/ResolveThread'
import newGroupIcon from './Icons/newGroup.svg'
import {
  isConversationPersonal,
  isConversationHilo,
  getOtherParticipantPersonal,
  isConversationGroup,
  getNamesFromParticipants,
  isTeacherInDomainId,
  isConversationResolved,
  getConversationImage,
} from '../helpers'
import AvatarParticipant from './AvatarParticipant'
import {ButtonNewParticipant} from './ButtonNewParticipant'
import CreateItemHelp from './Buttons/CreateItemHelp'

const HeaderDocuchat = ({
  conversation,
  currentUser,
  defineThread,
  setAddParticpantsConversation,
}) => {
  const user = getOtherParticipantPersonal(conversation, currentUser.id)

  const isTeacherInDomain = () => {
    const {domain_options = []} = currentUser
    return isTeacherInDomainId(domain_options, conversation?.domain_id)
  }

  return (
    <div className="chat-canal-header p-3 h-100">
      {conversation?.id && (
        <>
          {isConversationPersonal(conversation) && (
            <>
              <div className="chat-conversation-title">
                <AvatarParticipant
                  photoUrl={user?.photo_url}
                  title={`${user?.name}`}
                  style={{marginRight: '10px'}}
                />
                {user?.name || user?.email || 'User'}
              </div>

              {/* <ButtonNewParticipant onClick={setAddParticpantsConversation} /> */}
            </>
          )}

          {isConversationGroup(conversation) && (
            <>
              <div className="chat-conversation-title">
                <AvatarParticipant
                  photoUrl={{
                    url: getConversationImage(conversation)
                      ? getConversationImage(conversation)
                      : newGroupIcon,
                  }}
                  title={`grupos`}
                  style={{marginRight: '10px'}}
                />
                {conversation?.title || getNamesFromParticipants(conversation)}
              </div>

              <div className="d-flex flex-row align-items-center h-100">
                <ButtonNewParticipant onClick={setAddParticpantsConversation} />

                <div className="contenedor-participants-on-conversation">
                  <Participants
                    participants={conversation?.participating_users || []}
                  />
                </div>
              </div>
            </>
          )}

          {isConversationHilo(conversation) && (
            <>
              <div
                className="chat-conversation-title"
                title={conversation?.id ? conversation?.title : 'Tema'}>
                {conversation?.id ? conversation?.title : 'Tema'}
              </div>
              <div className="d-flex flex-row align-items-center h-100">
                <CreateItemHelp conversation={conversation} />

                {/* <ButtonNewParticipant onClick={setAddParticpantsConversation} /> */}
                <div className="contenedor-participants-on-conversation">
                  <Participants
                    participants={conversation?.participating_users || []}
                  />
                </div>

                {!isConversationResolved(conversation) && isTeacherInDomain() && (
                  <DefinirHilo defineThread={defineThread} />
                )}

                {conversation && isTeacherInDomain() && (
                  <ResolveThread
                    currentUser={currentUser}
                    conversation={conversation}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

HeaderDocuchat.propTypes = {
  conversation: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  defineThread: PropTypes.func.isRequired,
}

HeaderDocuchat.defaultProps = {
  conversation: null,
  defineThread: () => {},
}

export default HeaderDocuchat
