import React, {useState, useRef, useEffect} from 'react'
import MessagesArea from './MessagesArea'
import './ConversationMain.css'
import Modal from '../../Modal/Modal'
import FormDefine from './FormDefine'
import useConversations from '../../../hooks/useConversations'
import useUser from '../../../hooks/useUser'
import {useHistory, useParams} from 'react-router-dom'
import NewMessageForm from './NewMessageForm'
import HeaderOptions from './HeaderOptions'
import {TypeDocuchats} from '../helpers'
import ConversationLeft from './ConversationLeft'
import HeaderDocuchat from './HeaderDocuchat'
import AddParticipants from './AddParticipants'
import {goToScrollIntoView} from '../helpers'

const ConversationMain = () => {
  const {
    conversations = [],
    conversationActive,
    setActiveConversation,
    setMinichatOpen,
    setMinichatClose,
    messageCitado,
    removeCitedMessage,
  } = useConversations()

  const currentUser = useUser()
  const history = useHistory()
  const newMessageRef = useRef()

  const [showNewThread, setShowNewThread] = useState(false)
  const [textMessage, setTextMessage] = useState('')
  const [showDefineThread, setShowDefineThread] = useState(false)
  const [addParticpantsConversation, setAddParticpantsConversation] = useState(false)

  const params = useParams()

  useEffect(() => {
    ListeningConversationParams()
    // eslint-disable-next-line
  }, [params])

  const ListeningConversationParams = () => {
    const {idConversation = 0, idMessage = 0} = params
    if (idConversation > 0) {
      if (parseInt(idConversation)) {
        setActiveConversationFn(parseInt(idConversation))
        // si tambien hay mensaje, busca y scrollea, aun no es exacto
        if (idMessage > 0) {
          setTimeout(() => {
            goToScrollIntoView(parseInt(idMessage))
          }, 2000)
        }
      }
    }
  }

  const goBackScreen = () => {
    history.goBack()
    setMinichatClose()
  }

  const onClickMaximizar = () => {
    history.goBack()
    setMinichatOpen()
  }

  const defineThread = () => {
    setShowDefineThread(true)
  }

  const setActiveConversationFn = (id) => {
    setActiveConversation(id)
    autoFocusMessageInput()
    cleanMessageInput()
  }

  const handleReceivedMessage = (response) => {
    const {message} = response

    const conversation = conversations.find(
      (conversation) => conversation.id === message.conversation_id,
    )
    conversation.messages = [...conversation.messages, message]
  }

  const crearHiloToogle = () => {
    setShowNewThread(!showNewThread)
  }

  const autoFocusMessageInput = () => {
    if (newMessageRef?.current) {
      const ref = newMessageRef.current
      ref.focus()
    }
  }

  const cleanMessageInput = () => {
    setTextMessage('')
  }

  return (
    <div className="contenedor-chat-comunidad-padding">
      <HeaderOptions
        onClose={goBackScreen}
        onClickMaximizar={onClickMaximizar}
        type={TypeDocuchats.DOCUCHAT}
      />

      <div className="contenedor-chat-comunidad">
        <div className="lista-de-hilos p-4" style={{width: '35%'}}>
          {/* lado izquierdo del chat */}
          <ConversationLeft setActiveConversationFn={setActiveConversationFn} />
        </div>

        <div className="conversationsList">
          <HeaderDocuchat
            defineThread={defineThread}
            conversation={conversationActive}
            currentUser={currentUser}
            setAddParticpantsConversation={() => setAddParticpantsConversation(true)}
          />

          {conversationActive?.id && (
            <MessagesArea
              type={TypeDocuchats.DOCUCHAT}
              showModal={crearHiloToogle}
              conversation={conversationActive}
              participants={conversationActive.participating_users}
              online_participants={conversationActive.participating_users.filter(
                (u) => u.is_online,
              )}
              receivedMessage={handleReceivedMessage}
              autoFocusMessageInput={autoFocusMessageInput}
            />
          )}

          {conversationActive && (
            <NewMessageForm
              newMessageRef={newMessageRef}
              textMessage={textMessage}
              setTextMessage={setTextMessage}
              isMiniChat={false}
              conversation_id={conversationActive.id}
              conversation={conversationActive}
              creatingThread={showNewThread}
              typing_participants={conversationActive.participating_users.filter(
                (u) => u.is_typing,
              )}
              currentUser={currentUser}
              messageCitado={messageCitado}
              removeCitedMessage={removeCitedMessage}
              autoFocusMessageInput={autoFocusMessageInput}
            />
          )}
        </div>

        {addParticpantsConversation && conversationActive && (
          <Modal
            modalType="new-thread"
            show={addParticpantsConversation}
            closeModal={() => setAddParticpantsConversation(false)}
            closeRound>
            <AddParticipants
              conversation={conversationActive}
              currentUser={currentUser}
              closeModa={() => setAddParticpantsConversation(false)}
            />
          </Modal>
        )}

        {showDefineThread && conversationActive && (
          <Modal
            modalType="modal-define-thread"
            show={showDefineThread}
            closeModal={() => {
              setShowDefineThread(false)
            }}>
            <FormDefine
              conversation={conversationActive}
              onClose={() => setShowDefineThread(false)}
              currentUser={currentUser}
            />
          </Modal>
        )}
      </div>
    </div>
  )
}

export default ConversationMain
