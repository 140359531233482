import React, {Fragment} from 'react'
import {useState} from 'react'
import useBooleanToggler, {
  useWorkingIndicator,
} from '../../../hooks/useBooleanToggler'
import {difinirConversation} from '../../../lib/coco/conversationApi'
import {ALERT_TYPES, smallAlert} from '../../../Services/Alert'
import BotonConBordeDegradado from '../../Buttons/BotonConBordeDegradado'
import AvatarParticipant from './AvatarParticipant'
import './FormDefine.css'
import BackIcon from './Icons/back.svg'

const FormDefine = ({conversation, onClose, currentUser}) => {
  const {participating_users = []} = conversation

  const {
    isToggled: isParticioantsToggle,
    toggle: toggleParticipants,
  } = useBooleanToggler({initialValue: true})
  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const [sinipsis, setSinopsis] = useState()

  const [userToDefine, setUserToDefine] = useState(null)

  const definirHilo = () => {
    startWork()
    difinirConversation(
      currentUser?.id,
      userToDefine?.id,
      conversation?.id,
      conversation?.title,
      sinipsis,
    ).then((response) => {
      finishWork()
      if (response.item) {
        onClose()
        smallAlert(ALERT_TYPES.SUCCESS, `El hilo se ha definido con exito!`)
      } else {
        smallAlert(ALERT_TYPES.ERROR, `Error: ${response.message}`)
      }
    })
  }

  return (
    <Fragment>
      {/* participantes de las conversacion */}
      {isParticioantsToggle ? (
        <div id="form-define-thread-user-to-define">
          <p id="form-define-thread-question">¿Quén va a definir este termino?</p>
          <div className="scroll-coco participantes-encontrados">
            {participating_users?.length > 0 &&
              participating_users.map((user) => (
                <div key={user?.id} className="contenedor-conversacion-item">
                  <div
                    className="conversation-item-personal click-me"
                    onClick={() => setUserToDefine(user)}>
                    <div className="conversation-item-personal-avatar">
                      <AvatarParticipant
                        photoUrl={user?.photo_url}
                        title={user?.name || user?.email || 'User'}
                      />
                      <span id="title-conversation">
                        {user?.name || user?.email || 'User'}
                      </span>
                    </div>
                  </div>

                  {userToDefine?.id === user?.id && (
                    <span
                      className="status-usuario-agregado"
                      style={{width: '50px'}}>
                      Seleccionado
                    </span>
                  )}
                </div>
              ))}
          </div>

          {userToDefine?.id && (
            <div className="btn-agregar-participantes">
              <BotonConBordeDegradado
                function={toggleParticipants}
                width={208}
                height={37}
                disabled={isWorking}>
                <span>Siguiente</span>
              </BotonConBordeDegradado>
            </div>
          )}
        </div>
      ) : (
        // formulario
        <div id="form-define-thread">
          <div id="form-thread-container">
            <button onClick={toggleParticipants} id="form-define-thread-back">
              <img src={BackIcon} alt={BackIcon} />
            </button>
            <p id="form-thread-title-conversation">{conversation?.title || ''}</p>
          </div>

          <div className="m-4 h-50">
            <textarea
              value={sinipsis}
              onChange={({target}) => setSinopsis(target.value)}
              id="comment-or-synopsis"
              cols="30"
              rows="10"
              placeholder="Agregar un comentario / sinopsis de lo que vas definir."></textarea>
          </div>

          <div className="btn-agregar-participantes">
            <BotonConBordeDegradado function={definirHilo} width={208} height={37}>
              <>{`${isWorking ? 'Definiendo...' : 'Confirmar'}`}</>
            </BotonConBordeDegradado>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default FormDefine
