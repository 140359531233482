const OPEN_SONG_REQUEST_SUCCESS = 'OPEN_SONG_REQUEST_SUCCESS'
const SONG_UPDATE = 'SONG_UPDATE'
const UPDATE_LINES = 'UPDATE_LINES'
const UPDATE_INDICES_YA_AGREGADOS = 'UPDATE_INDICES_YA_AGREGADOS'
const OPEN_INPUT_TEXT = 'OPEN_INPUT_TEXT'
const OPEN_MEDIATEXT_REQUEST_ERROR = 'OPEN_MEDIATEXT_REQUEST_ERROR'
const LOADING_REQUEST_MEDIATEXT = 'LOADING_REQUEST_MEDIATEXT'
const ADD_TRACKS_TO_PLAYER_COLAS = 'ADD_TRACKS_TO_PLAYER_COLAS'

const MediaTextEmpty = {
  lines: [],
  user_language: {active: 'Ingles'},
  image_url: null,
  title: '',
}

export {
  MediaTextEmpty,
  OPEN_SONG_REQUEST_SUCCESS,
  OPEN_INPUT_TEXT,
  SONG_UPDATE,
  UPDATE_LINES,
  UPDATE_INDICES_YA_AGREGADOS,
  LOADING_REQUEST_MEDIATEXT,
  OPEN_MEDIATEXT_REQUEST_ERROR,
  ADD_TRACKS_TO_PLAYER_COLAS,
}
