import {
  FETCH_SEARCH_REQUEST_START,
  FETCH_SEARCH_REQUEST_FINISH,
  FETCH_SEARCH_REQUEST_ERROR,
  UPDATE_TRACK_SEARCH_FROM_LIST,
  SET_PARAMS_SEARCH_INPUT,
} from './searchTypes'
import {searchMediatext} from '../../lib/coco/InputApi'

const setParamsSearchInput = (params) => {
  return {
    type: SET_PARAMS_SEARCH_INPUT,
    params,
  }
}

const startSearchInputsRequest = () => {
  return {
    type: FETCH_SEARCH_REQUEST_START,
    payload: {},
  }
}

const completeSearchInputsRequest = (data) => {
  return {
    type: FETCH_SEARCH_REQUEST_FINISH,
    payload: {data},
  }
}

const errorSearchInputsRequest = (error) => {
  return {
    type: FETCH_SEARCH_REQUEST_ERROR,
    payload: {error},
  }
}

const getInputsByParam = (term) => (dispatch) => {
  dispatch(startSearchInputsRequest())

  return searchMediatext(term).then((response) => {
    if (response.message) {
      dispatch(errorSearchInputsRequest(response))
    } else {
      dispatch(completeSearchInputsRequest(response))
    }
  })
}

const updateTrackSearchInput = (track) => {
  return {
    type: UPDATE_TRACK_SEARCH_FROM_LIST,
    payload: {track},
  }
}

export {getInputsByParam, updateTrackSearchInput, setParamsSearchInput}
