import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import BotonConBordeDegradado from '../Buttons/BotonConBordeDegradado'
import Axios from 'axios'
import {CREATE_SNIPPET} from '../../Services/EndPointApi'
import {getToken} from '../../Services/getToken'
import {smallAlert} from '../../Services/Alert'
import {connect} from 'react-redux'

const styles = {
  phrase: {
    fontWeight: 'normal',
    textAlign: 'justify',
    fontSize: '20px',
    color: '#FFFFFF',
  },
}

const Snippet = (props) => {
  //States
  const [note, setNote] = useState()
  const [snippetTitle, setSnippetTitle] = useState()

  useEffect(() => {
    setSnippetTitle(props.title)
    // eslint-disable-next-line
  }, [])

  const createSnippet = () => {
    const {itemizableId, itemizableType, close} = props

    Axios.post(
      CREATE_SNIPPET,
      {
        item: {
          itemizable_id: itemizableId,
          itemizable_type: itemizableType,
          title: snippetTitle,
          notes: note,
          activity_type: 'bookmark',
          state: 'new',
          user_id: props.currentUser.id,
        },
      },
      {
        headers: {
          Authorization: getToken(),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => {
        if (response.data) {
          close()
          smallAlert('success', 'Se creó un snippet!', 'bottom-end')
        }
      })
      .catch((error) => {
        smallAlert('error', 'No se pudo crear el snippet:  ' + error, 'bottom-end')
      })
  }

  return (
    <div className="w-100 new-conversation-container">
      <div id="new-thread" className="w-100 pt-5 pb-4 px-2 d-flex flex-column">
        <p style={styles.phrase} className="px-3">
          {snippetTitle}
        </p>

        <div id="first-message">
          <textarea
            id="firt-message-area"
            placeholder="Agrega un comentario (opcional)"
            className="p-3"
            onChange={(event) => setNote(event.target.value)}
            value={note}></textarea>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <BotonConBordeDegradado
            width={194}
            height={37}
            fontSize={18}
            fontWeight={500}
            function={createSnippet}>
            <>Listo</>
          </BotonConBordeDegradado>
        </div>
      </div>
    </div>
  )
}

Snippet.propTypes = {
  title: PropTypes.string.isRequired,
  itemizableId: PropTypes.number.isRequired,
  itemizableType: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  currentUser: state.user,
})

export default connect(mapStateToProps)(Snippet)
