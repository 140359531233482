export default {
  en: {
    'auth.init-sesion': 'Login',
    'auth.email': 'Email',
    'auth.password': 'Password',
    'auth.forgot-passwod': 'I forgot my password',
    'auth.next': 'Next',
    'auth.empty-date': 'There is empty data',
    'auth.loading': 'Loading...',
    'auth.warning-email-valid': 'You must write an email with a valid format.',
    'auth.error-login': 'Incorrect email or password',
    'auth.check-email': 'Check your email to reset your password',
    'auth.change-password': 'Change Password',
    'auth.return-login': 'Return to login',
    'auth.error-autorisations': 'You do not have permissions to visit this page',
    'auth.password-edit-success': 'Your password was changed success',
    'auth.warning-password-equal':
      'Passwords must match and must be greater than equal to 6 characters',
    'auth.create-password': 'Create a password',
    'auth.confirm-password': 'Confirm password',
    'auth.welcome': '¡Welcome!',
    'auth.error-password-equal': 'Passwords must be the same',
    'auth.error-password-lenght': 'The password must be at least 6 characters',
    'auth.name': 'Name',
    'auth.birthday': 'Birthday',
    'auth.country': 'Native country',
    'auth.warning-languages-equals':
      'Native Language and Language to be learned should not be the same',
    'auth.languages-native': 'Idioma nativo',
    'auth.languages-learn': 'Idioma a aprender',
    'auth.choose-objets': 'Choose a goal',
    'auth.objective-1': 'Get a better job.',
    'auth.objective-2': 'To be smarter.',
    'auth.objective-3': 'Get to know another culture.',
    'auth.objective-4': 'Enjoy other music.',
    'auth.objective-5': 'Talk to friends in another language.',
    'auth.objective-6': 'Conquer a boy or girl.',
    'auth.preference-search': 'Search for song or artist',
    'auth.preference-search-description':
      'Finally, find and select three or more songs and<br /> artists that you like in the language you want to learn.',
    'auth.preference-error-like': 'Upps something went wrong!',
    'auth.preference-select-song': 'Select three or more songs',
    'auth.preference-start': ' Start',
    'auth.spotify-premium-title':
      'It is necessary to link your <b>Spotify Premium</b> account with Coco so that you can enjoy the full experience.',
    'auth.spotify-premium-enlazar': 'Link',
    'auth.spotify-premium-subtitle':
      '<b>Spotify Premium </b> is required to operate',
  },
  es: {
    'auth.init-sesion': 'Iniciar Sesión',
    'auth.email': 'Correo',
    'auth.password': 'Contraseña',
    'auth.forgot-passwod': 'Olvide mi contraseña',
    'auth.next': 'Siguiente',
    'auth.empty-date': 'Hay datos vacios',
    'auth.loading': 'Cargando...',
    'auth.warning-email-valid': 'Debe escribir un correo con formato valido.',
    'auth.error-login': 'Correo o contraseña incorrecto',
    'auth.check-email': 'Revise su correo, para resetear su contraseña',
    'auth.change-password': 'Cambiar contraseña',
    'auth.return-login': 'Regresar a login',
    'auth.error-autorisations': 'No tienes permisos para visitar esta pagina',
    'auth.password-edit-success': 'Su contraseña se modifico correctamente',
    'auth.warning-password-equal':
      'Las contraseñas deben coincidir y deben ser mayor a igual a 6 caracteres',
    'auth.create-password': 'Crea una contraseña',
    'auth.confirm-password': 'Confirma la contraseña',
    'auth.welcome': '¡Bienvenido!',
    'auth.error-password-equal': 'Las contraseñas deben ser iguales',
    'auth.error-password-lenght': 'La contraseña debe tener al menos 6 caracteres',
    'auth.name': 'Nombre',
    'auth.birthday': 'Cumpleaños',
    'auth.country': 'País natal',
    'auth.warning-languages-equals':
      'Idioma Nativo e Idioma a aprender no deben ser iguales',
    'auth.languages-native': 'Native language',
    'auth.languages-learn': 'Language to learn',
    'auth.choose-objets': 'Elige un objetivo',
    'auth.objective-1': 'Conseguir mejor empleo.',
    'auth.objective-2': 'Ser más inteligente.',
    'auth.objective-3': 'Conocer otra cultura.',
    'auth.objective-4': 'Disfrutar otra música.',
    'auth.objective-5': 'Hablar con amigos en otro idioma.',
    'auth.objective-6': 'Conquistar a un@ chic@.',
    'auth.preference-search': 'Buscar canción o artista',
    'auth.preference-search-description':
      'Por último, busca y selecciona tres o más canciones y <br />artistas que te gusten del idioma que deseas aprender.',
    'auth.preference-error-like': 'Upps algo salio mal!',
    'auth.preference-select-song': ' Selecciona tres o más canciones',
    'auth.preference-start': ' Comenzar',
    'auth.spotify-premium-title':
      'Es necesario enlaza tu cuenta de <b>Spotify Premium</b> con Coco para que puedas disfrutar de la experiencia completa.',
    'auth.spotify-premium-enlazar': 'Enlazar',
    'auth.spotify-premium-subtitle':
      'Es necesario <b>Spotify Premium</b> para operar',
  },

  fr: {
    'auth.init-sesion': 'Iniciar Sesión',
    'auth.email': 'Courrier',
    'auth.password': 'Mot de passe',
    'auth.forgot-passwod': 'J`ai oublié mon mot de passe',
    'auth.next': 'Suivant',
    'auth.empty-date': 'Il y a des données vides',
    'auth.loading': 'Chargement...',
    'auth.warning-email-valid':
      'Vous devez rédiger un e-mail avec un format valide.',
    'auth.error-login': 'Email ou mot de passe incorrect',
    'auth.check-email':
      'Vérifiez votre messagerie pour réinitialiser votre mot de passe',
    'auth.change-password': 'Changer le mot de passe',
    'auth.return-login': 'Revenir à la connexion',
    'auth.error-autorisations': 'Vous n`êtes pas autorisé à visiter cette page',
    'auth.password-edit-success': 'Votre mot de passe a été modifié correctement',
    'auth.warning-password-equal':
      'Les mots de passe doivent correspondre et doivent être supérieurs à 6 caractères',
    'auth.create-password': 'Créer un mot de passe',
    'auth.confirm-password': 'Confirmez le mot de passe',
    'auth.welcome': 'Bienvenue!',
    'auth.error-password-equal': 'Les mots de passe doivent être identiques',
    'auth.error-password-lenght':
      'Le mot de passe doit comporter au moins 6 caractères',
    'auth.name': 'nom',
    'auth.birthday': 'Date d`anniversaire',
    'auth.country': 'Pays natal',
    'auth.warning-languages-equals':
      'La langue maternelle et la langue à apprendre ne doivent pas être les mêmes',
    'auth.languages-native': 'Langue maternelle',
    'auth.languages-learn': 'Langue à apprendre',
    'auth.choose-objets': 'Choisissez un objectif',
    'auth.objective-1': 'Obtenez un meilleur travail.',
    'auth.objective-2': 'Pour être plus intelligent.',
    'auth.objective-3': 'Apprenez à connaître une autre culture.',
    'auth.objective-4': 'Profitez d`autres musiques.',
    'auth.objective-5': 'Parlez à des amis dans une autre langue.',
    'auth.objective-6': 'Conquérir un garçon ou une fille.',
    'auth.preference-search': 'Rechercher une chanson ou un artiste',
    'auth.preference-search-description':
      'Enfin, trouvez et sélectionnez au moins trois chansons et <br /> artistes que vous aimez dans la langue que vous souhaitez apprendre.',
    'auth.preference-error-like': 'Upps quelque chose s`est mal passé!',
    'auth.preference-select-song': 'Sélectionnez trois chansons ou plus',
    'auth.preference-start': ' Début',
    'auth.spotify-premium-title':
      'Il est nécessaire d`associer votre compte <b>Spotify Premium</b> à Coco pour que vous puissiez profiter pleinement de l`expérience.',
    'auth.spotify-premium-enlazar': 'EnlazRelierar',
    'auth.spotify-premium-subtitle':
      '<b> Spotify Premium </b> est requis pour fonctionner',
  },
}
