import {TOGGLE_DICCIONARY, SET_PARAMS_DICCIONARY} from './diccionaryTypes'

const toogleDiccionary = (value) => {
  return {
    type: TOGGLE_DICCIONARY,
    value,
  }
}

const setParamsDiccionary = (
  params,
  wordMark = null,
  mediaTextId = null,
  theMusicWasPlaying = false,
) => {
  return {
    type: SET_PARAMS_DICCIONARY,
    params,
    wordMark,
    mediaTextId,
    theMusicWasPlaying,
  }
}

export {toogleDiccionary, setParamsDiccionary}
