// todas las constantes para usuarios
export const ADD_USER = 'ADD_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const GET_ALL_GENERAL_DATA = 'GET_ALL_GENERAL_DATA'
export const VERIFCAR_USUARIO = 'VERIFCAR_USUARIO'
export const ADD_PLAYLIST = 'ADD_PLAYLIST'
export const GET_MY_PLAYLISTS = 'GET_MY_PLAYLISTS'
export const IS_TEACHING = 'IS_TEACHING'
export const UPDATE_OPTIONS_TUTORIAL = 'UPDATE_OPTIONS_TUTORIAL'
export const ADD_DOMAIN_TO_USER = 'ADD_DOMAIN_TO_USER'
export const SET_ACTIVE_DOMAIN_ID = 'SET_ACTIVE_DOMAIN_ID'
export const TOGGLE_SPOTIFY_AUTH = 'TOGGLE_SPOTIFY_AUTH'
export const TOGGLE_TUTORIAL = 'TOGGLE_TUTORIAL'
export const SET_PHOTO_URL = 'SET_PHOTO_URL'
export const TOGGLE_SUBSCRIPTION_STATUS = 'TOGGLE_SUBSCRIPTION_STATUS'
export const TOGGLE_SNIPPET_MODAL = 'TOGGLE_SNIPPET_MODAL'
export const TOGGLE_FLASHCARD_MODAL = 'TOGGLE_FLASHCARD_MODAL'
export const DASHBOARD_TUTORIAL_STEP = 'DASHBOARD_TUTORIAL_STEP'
export const FLASHCARD_TUTORIAL_CHECKPOINT = 'FLASHCARD_TUTORIAL_CHECKPOINT'
export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA'
export const UPDATE_OPTIONS_NOTIFICATION = 'UPDATE_OPTIONS_NOTIFICATION'
export const TOGGLE_SUSCRIPTION_STRAPI_MODAL = 'TOGGLE_SUSCRIPTION_STRAPI_MODAL'
