import {useState, useEffect} from 'react'
import useOrientationDevice from './useOrientationDevice'

export const Platforms = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
}
// movil: landscape-primary y portrait-primary"
// tablet portrait-primary

const getDevice = (width, type) => {
  if (width <= 576) return Platforms.MOBILE
  else if (width < 991.98 && type === 'landscape-primary') return Platforms.MOBILE
  else if (width <= 991.98) return Platforms.TABLET
  else if (width <= 1199.98 && type === 'landscape-primary') return Platforms.TABLET
  else return Platforms.DESKTOP
}

const usePlatform = () => {
  const {type} = useOrientationDevice()

  const [viewport, setViewport] = useState({
    width: window.innerWidth,
    device: getDevice(window.innerWidth, type),
  })

  useEffect(() => {
    const handleResize = () =>
      setViewport({
        width: window.innerWidth,
        device: getDevice(window.innerWidth, type),
      })
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [type])

  const isDeviseMobile = viewport?.device === Platforms.MOBILE
  const isDeviseTablet = viewport?.device === Platforms.TABLET
  const isDeviseDesktop = viewport?.device === Platforms.DESKTOP

  return {
    ...viewport,
    isDeviseMobile,
    isDeviseTablet,
    isDeviseDesktop,
  }
}

export default usePlatform
