import React from 'react'
import ReproductorSpotify from './ReproductorSpotify'
import './FooterSpotifyPlayer.css'
import {connect} from 'react-redux'
import TopBarNavigation from '../Layouts/MenuItems/TopBarNavigation'
import usePlatform from '../../hooks/usePlatform'

const FooterSpotifyPlayer = ({user, isToggledLeftbar, isKeyBoardActive}) => {
  const {isDeviseDesktop} = usePlatform()

  return (
    <>
      {user.isLogged && (
        <div
          className={`footer-spotify-player ${
            !isToggledLeftbar ? 'footer-spotify-player-big ' : ''
          } ${!isDeviseDesktop && 'footer__with__topbar__navigation'}`}
          style={isKeyBoardActive ? {bottom: '-110px'} : {}}>
          <ReproductorSpotify />
          {!isDeviseDesktop && <TopBarNavigation />}
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  //usuario logeado.
  user: state.user,
})

//conectando. funcion sobre funcion
export default connect(mapStateToProps)(FooterSpotifyPlayer)
