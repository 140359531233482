import React from 'react'
import Seo from '../../Shared/Seo'
import usePlatform from '../../../hooks/usePlatform'
import ConversationMain from './ConversationMain'
import MiniChat from './MiniChat'

const TheadsIndex = () => {
  const {isDeviseMobile} = usePlatform()
  return (
    <>
      <Seo title={'Conversaciones'} />
      {isDeviseMobile ? <MiniChat isShowMaximizar={false} /> : <ConversationMain />}
    </>
  )
}

export default TheadsIndex
