const Colors = {
  DARK: 'dark',
  LIGTH: 'ligth',
}

const BadgeType = {
  SMALL: 'SMALL',
  BIG: 'BIG',
  BIG_WHITH_NUMBER: 'BIG_WHITH_NUMBER',
}

const isValidTrack = (track) => {
  if (
    track?.spotify_id?.trim()?.length > 0 &&
    track?.artistas?.length > 0 &&
    track?.titulo?.trim()?.length > 0
  ) {
    return true
  }

  return false
}

const LANGUAGE_UNICODE_ACTIVE = 'LANGUAGE_UNICODE_ACTIVE'

const setUnicodeActiveInLocalStorage = (unicode) => {
  try {
    localStorage.setItem(LANGUAGE_UNICODE_ACTIVE, unicode)
  } catch (error) {
    //
  }
}

const getUnicodeActiveInLocalStorage = () => {
  try {
    return localStorage.getItem(LANGUAGE_UNICODE_ACTIVE) || null
  } catch (error) {
    return null
  }
}

export {
  Colors,
  isValidTrack,
  BadgeType,
  setUnicodeActiveInLocalStorage,
  getUnicodeActiveInLocalStorage,
}
