const FETCH_MYLIKES_REQUEST_START = 'Mylikes/FETCH_MYLIKES_REQUEST_START'
const FETCH_MYLIKES_REQUEST_FINISH = 'Mylikes/FETCH_MYLIKES_REQUEST_FINISH'
const FETCH_MYLIKES_REQUEST_ERROR = 'Mylikes/FETCH_MYLIKES_REQUEST_ERROR'
const UPDATE_TRACK_MYLIKES_FROM_LIST = 'Mylikes/UPDATE_TRACK_MYLIKES_FROM_LIST'

export {
  FETCH_MYLIKES_REQUEST_START,
  FETCH_MYLIKES_REQUEST_FINISH,
  FETCH_MYLIKES_REQUEST_ERROR,
  UPDATE_TRACK_MYLIKES_FROM_LIST,
}
