import React from 'react'

class TypingIndicator extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dots: ['.', '.', '.', '.'],
      display: '',
      numDots: 0,
    }

    this.tick = this.tick.bind(this)
  }

  componentDidMount() {
    this.dotInterval = setInterval(() => this.tick(), 500)
  }

  tick() {
    const {numDots} = this.state

    this.setState({numDots: (numDots + 1) % 4})
  }

  componentWillUnmount() {
    clearInterval(this.dotInterval)
  }

  render() {
    const {dots, numDots} = this.state
    return (
      <>
        <strong>{dots.slice(0, numDots + 1).join(' ')}</strong>
      </>
    )
  }
}

export default TypingIndicator
