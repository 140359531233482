import React from 'react'
import {connect} from 'react-redux'
// import { API_ROOT, API_WS_ROOT, webSocketToken } from "../../Services/EndPointApi";
import {getToken} from '../../Services/getToken'
// import WebSocketConsumer from './WebSocketConsumer';
// import SocketConsumer from './SocketConsumer';

import {
  receivedMessage /* receivedConversations */,
} from '../../Redux/Conversations/actions'
import {presenceConnected, presenceDisconnected} from '../../Redux/Presence/actions'
// import { socketOpen } from '../../Redux/WebSockets/actions';
// import { verificarUser } from "../../Redux/users/actions";
// import { wsconnect, subscribe } from '../../Redux/Conversations/actions';

class ActionCableConnection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sockets: [],
    }
    // this.cable = this.cable.bind(this)
    this.onReceivedPresence = this.onReceivedPresence.bind(this)
    this.onReceivedConversation = this.onReceivedConversation.bind(this)
  }

  componentDidMount() {
    // const { user_id, dispatch } = this.props;
    // const url = `${API_WS_ROOT}?token=${webSocketToken(user.id)}`;

    // fetch(`${API_ROOT}/conversations`,{
    //     headers: {
    //         'Authorization': getToken(),
    //     }})
    //     .then(res => res.json())
    //     .then(conversations => {
    //         dispatch(receivedConversations(conversations))
    //     });

    // dispatch(wsconnect({user_id: user_id}));
    document.cookie = 'X-Authorization=' + getToken() + '; path=/'
  }

  componentDidUpdate() {
    const {conversations_connection} = this.props
    document.cookie = 'X-Authorization=' + getToken() + '; path=/'

    if (conversations_connection !== 'READY') {
      // dispatch(wsconnect({user: this.props.user}))
    }
  }

  onReceivedConversation(conversation) {
    const {dispatch} = this.props
    dispatch(receivedMessage(conversation))
  }

  onReceivedPresence(presence) {
    const {dispatch} = this.props

    if (!presence.is_online) dispatch(presenceDisconnected(presence))
    if (presence.is_online) dispatch(presenceConnected(presence))
  }

  render() {
    // const { dispatch } = this.props;

    return (
      <>
        {/* <WebSocketConsumer 
                    channel="PresenceChannel" 
                    onReceived={this.onReceivedPresence} /> */}
        {/* <WebSocketConsumer 
                    channel="ConversationsChannel" 
                    onReceived={this.onReceivedConversation} /> */}
        {this.props.children}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  user_id: state.user.id,
  presence: state.presence,
  conversations_connection: state.conversations.connection,
})

export default connect(mapStateToProps)(ActionCableConnection)
