const NON_LETTER_REGEX = /[\W_]/g
const NON_BLANKS = / /g

const removeBlanks = (source = '') => {
  return source.replace(NON_BLANKS, '')
}

const strippedString = (source = '') => {
  const sourceRegex = source.replace(NON_LETTER_REGEX, '')
  return removeBlanks(sourceRegex)
}

const isCorrectAnswer = (correctAnswer = '', answerToCheck = '') => {
  const correctAnswerUppercase = correctAnswer.toUpperCase()
  const answerToCheckUppercase = answerToCheck.toUpperCase()

  return (
    strippedString(correctAnswerUppercase) === strippedString(answerToCheckUppercase)
  )
}

const isWordMarksValid = (word) => {
  if (word.word?.trim() === '' || word.form?.trim() === '') {
    return false
  }

  return true
}

const RemoverParentesis = (frase) => {
  for (let index = 0; index < 3; index++) {
    frase = frase.replace('[...]', '')
  }
  return frase
}

//funccion convierte una oracion a array de palabras
const convertArray = (cadena = '') => {
  return cadena.split(' ') || []
}

const filtroCaracteres = (palabra) => {
  try {
    palabra = palabra
      .replace(/[&#,+()=$~%'":;!¡_|°*?<>{}|]/g, '')
      .replace(/\u201C/g, '')
      .replace(/\u201D/g, '')
      .replace(/\s+/g, '')
    palabra = palabra.replace('/', '')
    palabra = palabra.replace('-', '')
    palabra = palabra.replace('.', '')
    palabra = palabra.replace('...', '')
    palabra = palabra.replace(',', '')
  } catch {
    //error al filtrar
  }

  return palabra
}

const QuitarCaracteresParaTraducir = (palabra) => {
  try {
    palabra = palabra.replace('#', '')
    palabra = palabra.replace('<', '')
    palabra = palabra.replace(';', ',')
  } catch {
    //error al filtrar
  }
  return palabra
}

const compareObjets = (a, b) => {
  var aKeys = Object.keys(a).sort()
  var bKeys = Object.keys(b).sort()
  if (aKeys.length !== bKeys.length) {
    return false
  }
  if (aKeys.join('') !== bKeys.join('')) {
    return false
  }
  for (var i = 0; i < aKeys.length; i++) {
    if (a[aKeys[i]] !== b[bKeys[i]]) {
      return false
    }
  }
  return true
}

export {
  strippedString,
  isCorrectAnswer,
  convertArray,
  filtroCaracteres,
  QuitarCaracteresParaTraducir,
  RemoverParentesis,
  isWordMarksValid,
  compareObjets,
}
