import React from 'react'
import IconAddImage from '../Icons/IconAddImage.svg'
import {createSource} from '../../../../lib/coco/conversationApi'
import {useWorkingIndicator} from '../../../../hooks/useBooleanToggler'
import Reload from '../../../Animaciones/Icons/Reload.svg'

const ImageUpload = ({conversation_id}) => {
  const {isWorking, startWork, finishWork} = useWorkingIndicator()

  const readFile = (event) => {
    const files = event.currentTarget.files
    if (files && files[0] && conversation_id) {
      sendImageToController(files[0])
    }
  }

  const sendImageToController = (file) => {
    if (!/image.*/.test(file.type)) {
      console.log(`Upload aborted! Images only (for now mwahaha).`)
      return
    }

    const data = new FormData()
    data.append('image', file)
    data.append('conversation_id', conversation_id)
    data.append('text', `${file.name}`)

    startWork()
    createSource(data).finally(() => {
      setTimeout(() => {
        finishWork()
      }, 1000)
    })
  }

  return (
    <>
      <div className="file-uploader-image">
        <input type="file" id="file-image-message" onChange={readFile} />
        <label htmlFor="file-image-message" className="btn-send-image-upload-form">
          <img src={!isWorking ? IconAddImage : Reload} alt="Agregar imagen" />
        </label>
      </div>
    </>
  )
}

export default ImageUpload
