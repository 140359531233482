// Función para obtener los númemeros de una cadena.
const getNumbers = (body) => {
  let regex = /(\d+)/g
  return body.match(regex)
}

const construirFecha = (today) => {
  // new Date(parseInt(1616517354, 10) * 1000) forma de calcular fecha
  console.log(today);
  const day = today.getDate()
  const month = today.getMonth() + 1
  const year = today.getFullYear()
  return `${year}-${today.getMonth() <= 9 ? '0' + month : month}-${
    day <= 9 ? '0' + day : day
  }T00:00:00`
}

const getMonthString = (month) => {
  switch (month) {
    case 0:
      return 'En'
    case 1:
      return 'Feb'
    case 2:
      return 'Mz'
    case 3:
      return 'Ab'
    case 4:
      return 'My'
    case 5:
      return 'Jn'
    case 6:
      return 'Jl'
    case 7:
      return 'Ago'
    case 8:
      return 'Sep'
    case 9:
      return 'Oct'
    case 10:
      return 'Nov'
    case 11:
      return 'Dic'

    default:
      break
  }
}

const getDateString = (date = new Date(), character = '.') => {
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  return `${day <= 9 ? '0' + day : day} ${character} ${getMonthString(
    month,
  )} ${character} ${year}`
}

const getDateObjets = (date = new Date()) => {
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  return {
    day,
    month,
    year,
  }
}

export {getNumbers, construirFecha, getDateString, getDateObjets}
