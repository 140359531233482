import React, {useEffect, useState} from 'react'
import CheckPeculiaridad from './CheckPeculiaridad'
import PeculiaridadesMultiSelect from './PeculiaridadesMultiSelect'
import Peculiaridades from './Peculiaridades'
import NotaEspecial from './NotaEspecial'
import PeculiaridadNotaEspecial from './PeculiaridadNotaEspecial'

const PeculiaridadesPrincipal = ({
  notificar_estado_peculiaridad,
  objeto_seleccionado,
  tag_a_modificar,
  lang_tags,
  Actualizar_nuevo_objeto,
  specialnote_a_modificar,
  non_lang_tags,
  nota_especial_text,
  tipo_tag,
}) => {
  const [peculiaridadesChecked, setPeculiaridadesChecked] = useState(false)
  const [noteSpecialShow, setNoteSpecialShow] = useState(false)

  const objectSelectedUniq = objeto_seleccionado

  useEffect(() => {
    setPeculiaridadesChecked(true)
    setNoteSpecialShow(false)
  }, [objeto_seleccionado.id])

  const handleCheckboxChange = () => {
    setPeculiaridadesChecked(!peculiaridadesChecked)
    // notificar el estado del componente Activo o no Activo
    if (notificar_estado_peculiaridad) {
      notificar_estado_peculiaridad(objectSelectedUniq, !peculiaridadesChecked)
    }
  }

  // Busca en el objeto lineas la lsiat de tags segun el tag_a_modificar(tags o trans_tags, etc)
  const isRegionsActive = () => {
    let haveTagsAdd = false
    let tags = objectSelectedUniq[tag_a_modificar]
      ? objectSelectedUniq[tag_a_modificar]
      : []
    if (tags.length > 0) {
      tags.forEach((tag_for) => {
        if (lang_tags.find((region) => region === tag_for)) {
          haveTagsAdd = true
          return
        }
      })
    }

    return haveTagsAdd
  }

  // busca si el tag existe en el onjeto linea
  const isTagActive = (tag) => {
    let es_tag_activo = false
    // modifica de forma automatica segun el tag_a_modificar
    let tags = objectSelectedUniq[tag_a_modificar]
      ? objectSelectedUniq[tag_a_modificar]
      : []
    if (tags.find((t) => t === tag)) {
      es_tag_activo = true
    }
    return es_tag_activo
  }

  // selecciona un tag y se agrega ala linea y luego se envia al padre la linea actualizada
  const seleccionar_tag = (tag) => {
    // modifica de forma automatica segun el tag_a_modificar
    let tags = objectSelectedUniq[tag_a_modificar]
      ? objectSelectedUniq[tag_a_modificar]
      : []
    tags.push(tag)
    // agregar al tag correspondiente
    objectSelectedUniq[tag_a_modificar] = tags

    if (Actualizar_nuevo_objeto) {
      Actualizar_nuevo_objeto({...objectSelectedUniq})
    }
  }

  // elimina el tag de la linea
  const deleteTag = (tag) => {
    let tags = objectSelectedUniq[tag_a_modificar]
      ? objectSelectedUniq[tag_a_modificar]
      : []
    objectSelectedUniq[tag_a_modificar] = tags.filter((t) => t !== tag)
    if (Actualizar_nuevo_objeto) {
      Actualizar_nuevo_objeto({...objectSelectedUniq})
    }
  }

  // agrega una nota especial a la linea
  const addNoteSpecial = (nota_especial) => {
    objectSelectedUniq[specialnote_a_modificar] = nota_especial
    if (Actualizar_nuevo_objeto) {
      Actualizar_nuevo_objeto({...objectSelectedUniq})
    }
    setNoteSpecialShow(false)
  }

  // abre el text para agregar nota especial
  const handleNotaEspecial = () => {
    setNoteSpecialShow(!noteSpecialShow)
  }

  return (
    <>
      <CheckPeculiaridad
        onClick={handleCheckboxChange}
        peculiaridadesChecked={peculiaridadesChecked}
        titulo={'Peculiaridades:'}
        withBorder={true}
        side={tipo_tag}
      />

      {peculiaridadesChecked && !noteSpecialShow && (
        <>
          <PeculiaridadesMultiSelect
            titulo={'region'}
            is_active={isRegionsActive()}
            lang_tags={lang_tags}
            isTagActive={isTagActive}
            tipo={'region'}
            seleccionar_tag={seleccionar_tag}
            eliminar_tag={deleteTag}
            side={tipo_tag}
          />

          {/* las Notas especiales son apartes de los tags */}
          <PeculiaridadNotaEspecial
            onclick={handleNotaEspecial}
            tooltip={nota_especial_text || ''}
            side={tipo_tag}
          />

          {/* non_lang_tags son todos los tags */}
          {non_lang_tags &&
            non_lang_tags.length > 0 &&
            non_lang_tags.map((tag, index) => (
              <Peculiaridades
                key={index}
                titulo={tag}
                is_active={isTagActive(tag)}
                tipo={tag}
                onclick={seleccionar_tag}
                eliminar_tag={deleteTag}
                side={tipo_tag}
              />
            ))}
        </>
      )}

      {noteSpecialShow && peculiaridadesChecked && (
        <NotaEspecial
          onClick={addNoteSpecial}
          value={nota_especial_text || ''}
          side={'front'}
        />
      )}
    </>
  )
}

export default PeculiaridadesPrincipal
