import React from 'react'
import DefaultPhotoUser from '../Shared/DefaultPhotoUser'
import {
  isSettingInformation,
  isSettingNotifications,
  isSettingPaymentsSuscriptions,
  SettingsViewerStatus,
  isSettingSpotify
} from './helpersSettings'

import CardIcon from './icons/card.svg'
import PaymentCardIcon from './icons/paymentCard.svg'
import SwitchIcon from './icons/switch.svg'
import IconSpotify from './icons/IconSpotify.svg'

const ButtonSettingLink = ({onClick, title, icon, isActive}) => {
  return (
    <button
      className={`Layout__setting__links ${
        isActive ? 'Layout__setting__links__active' : ''
      }`}
      onClick={onClick}>
      <img src={icon} alt={icon} />
      <span>{title}</span>
    </button>
  )
}

const SettingOptions = ({setSettingViewer, settingsViewer, currentUser, intl}) => {
  const {name: usernName, getPhotoCurrentUser, subscriptionStatus} = currentUser

  return (
    <div className="Layout__setting__left">
      <div className="Layout__setting__user__data">
        <DefaultPhotoUser
          className="card__form__stripe__information__photo"
          photourl={getPhotoCurrentUser()}
        />

        <div className="user__data__text">
          <p>{usernName}</p>
          <span style={{color: subscriptionStatus ? '#7FE55B' : '#7894b4'}}>{`${
            subscriptionStatus
              ? intl.formatMessage({
                  id: 'engrane.seting-active',
                  defaultMessage: 'Active',
                })
              : intl.formatMessage({
                  id: 'engrane.seting-inactive',
                  defaultMessage: 'Inactive',
                })
          }`}</span>
        </div>
      </div>

      <div className="Layout__setting__links__container">
        <ButtonSettingLink
          onClick={() => setSettingViewer(SettingsViewerStatus.INFORMATION_PERSONAL)}
          title={intl.formatMessage({
            id: 'engrane.seting-personal-information',
            defaultMessage: 'Personal information',
          })}
          icon={CardIcon}
          isActive={isSettingInformation(settingsViewer)}
        />
        <ButtonSettingLink
          onClick={() => setSettingViewer(SettingsViewerStatus.NOTIFICATIONS)}
          title={intl.formatMessage({
            id: 'engrane.seting-notificaciones',
            defaultMessage: 'Notifications',
          })}
          icon={SwitchIcon}
          isActive={isSettingNotifications(settingsViewer)}
        />
        <ButtonSettingLink
          onClick={() =>
            setSettingViewer(SettingsViewerStatus.PAYMENTS_AND_SUBSCRIPTIONS)
          }
          title={intl.formatMessage({
            id: 'engrane.seting-payments',
            defaultMessage: 'xxxx',
          })}
          icon={PaymentCardIcon}
          isActive={isSettingPaymentsSuscriptions(settingsViewer)}
        />
        {/* Configuraciones de spotify */}
        <ButtonSettingLink
          onClick={() =>
            setSettingViewer(SettingsViewerStatus.SPOTYFY)
          }
          title={intl.formatMessage({
            id: 'engrane.spotify-settings',
            defaultMessage: 'Spotify',
          })}
          icon={IconSpotify}
          isActive={isSettingSpotify(settingsViewer)}
        />
      </div>
    </div>
  )
}

export default SettingOptions
