import React, {useState} from 'react'
import {useWorkingIndicator} from '../../../../hooks/useBooleanToggler'
import {
  createItemHelpForHilo,
  verificarItemExist,
} from '../../../../lib/coco/conversationApi'
import Tooltip from '../../../Tooltip/Tooltip'
import ThreadHelpMe from '../Icons/ThreadHelpMe.svg'
import HelpRequested from '../Icons/HelpRequested.svg'
import {useEffect} from 'react'

const CreateItemHelp = ({conversation}) => {
  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const [requestFinish, setRequestFinish] = useState(false)

  useEffect(() => {
    //   TODO buscar alguna forma de validar si ya se ha pedido ayuda
    verificarItemExist(conversation?.id).then((res) => {
      if (res.item) {
        setRequestFinish(true)
      } else {
        setRequestFinish(false)
      }
    })
  }, [conversation])

  const handler = () => {
    if (!requestFinish) {
      if (conversation?.id) {
        startWork()
        createItemHelpForHilo(conversation.id).then((res) => {
          finishWork()
          if (res.item) {
            console.log(res.item)
            setRequestFinish(true)
          } else {
            alert(res.message)
          }
        })
      }
    }
  }

  return (
    <>
      <Tooltip
        tooltip={`${
          isWorking
            ? 'Loading...'
            : requestFinish
            ? 'Ayuda solicitada'
            : 'Pedir ayuda'
        }`}
        position="top">
        <img
          style={{maxWidth: '45px', minHeight: '45px', cursor: 'pointer'}}
          src={requestFinish ? HelpRequested : ThreadHelpMe}
          alt={requestFinish ? HelpRequested : ThreadHelpMe}
          onClick={handler}
        />
      </Tooltip>
    </>
  )
}

export default CreateItemHelp
