export default (store) => (next) => (action) => {
  const result = next(action)
  if (result) {
    /* 
			this is here, because i got really tired of seeing a warning in the javascript console
			for not using the result variable.  

									DO NOT REMOVE THE RESULT VARIABLE!!!
										YOU WILL BREAK THE STORE!

									   ¡NO QUITE LA VARIABLE RESULT!
										    ¡ROMPERÁS LA STORE!
		*/
  }
  //   console.debug(action.type)
}
