import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useWorkingIndicator} from '../../hooks/useBooleanToggler'
import useAlert, {TOAST_TYPE} from '../../hooks/useAlert'
import {
  cancellSubscription,
  checkUserSubscription,
} from '../../lib/coco/subscriptionsApi'
import {toggleSubscriptionStatus} from '../../Redux/users/actions'
import './SettingsPaymentSuscription.css'
import cardPaymentIcon from '../Icons/cardPayment.svg'
import cancelSuscriptionIcon from './icons/cancelSuscription.svg'
import LoadingCoco from '../Animaciones/LoadingCoco'
import Modal from '../Modal'
import WarningAlert from '../Alerts/WarningAlert'
import {StatusApi} from '../../lib/coco/helperData'
import BotonConBordeDegradado from '../Buttons/BotonConBordeDegradado'
import {getDateObjets} from '../../lib/coco/generalHelpers'

const SettingsPaymentSuscription = ({currentUser, intl}) => {
  const {
    subscriptionStatus,
    name,
    dispatchToggleSuscriptionStrapiModal,
  } = currentUser
  const dispatch = useDispatch()
  const {showMessage} = useAlert()
  const [dataStripeSuscription, setDataStripeSuscription] = useState(null)
  const [isShowCancelSuscription, setIsShowCancelSuscription] = useState(null)

  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const {
    isWorking: isWorkingDataSuscription,
    startWork: startWorkDataSuscription,
    finishWork: finishtWorkDataSuscription,
  } = useWorkingIndicator()

  useEffect(() => {
    startWorkDataSuscription()
    checkUserSubscription().then((res) => {
      finishtWorkDataSuscription()
      if (res?.subscription && res?.user) {
        const {subscription, user} = res
        const dataSuscription = {
          currentPeriodStart: getDateObjets(
            new Date(parseInt(subscription?.current_period_start, 10) * 1000),
          ),
          currentPeriodEnd: getDateObjets(
            new Date(parseInt(subscription?.current_period_end, 10) * 1000),
          ),
          created: getDateObjets(
            new Date(parseInt(subscription?.created, 10) * 1000),
          ),
          subscribed: user?.subscribed,
          cardExpMonth: user?.card_exp_month,
          cardExpYear: user?.card_exp_year - 2000, // solo dejar 2021=21
          cardLast4: user?.card_last4,
          planType: user?.plan_type,
        }
        setDataStripeSuscription(dataSuscription)
        console.log(dataSuscription)
        console.log(subscription?.current_period_start)
      } else if (res?.status === StatusApi.Unauthorized) {
        setDataStripeSuscription(null)
      } else {
        showMessage(res?.message, TOAST_TYPE.ERROR)
      }
    })
    // eslint-disable-next-line
  }, [subscriptionStatus])

  // abrir el modal de suscripcion
  const openNewSuscriptionModal = () => {
    dispatchToggleSuscriptionStrapiModal(true)
  }

  const cancellSubscriptionUser = () => {
    setIsShowCancelSuscription(false)
    startWork()
    cancellSubscription().then((response) => {
      finishWork()
      if (response.status) {
        showMessage(
          intl.formatMessage({
            id: 'engrane.payments-cancel-success',
            defaultMessage: 'You have canceled the subscription',
          }),
        )
        dispatch(toggleSubscriptionStatus(false, null))
      } else if (response.message) {
        showMessage(response.message, TOAST_TYPE.ERROR)
      }
    })
  }

  return (
    <>
      <div className="settings__payment__suscription__main">
        <h1 className="Layout__setting__screens__title">
          {intl.formatMessage({
            id: 'engrane.seting-payments',
            defaultMessage: 'Payments and subscriptions',
          })}
        </h1>

        {isWorkingDataSuscription ? (
          <LoadingCoco className="loading-coco-center" />
        ) : (
          <React.Fragment>
            <div className="settings__payment__suscription__description">
              <p>
                {intl.formatMessage({
                  id: 'engrane.payments-suscription-monthly',
                  defaultMessage: 'Monthly subscription',
                })}
              </p>

              {dataStripeSuscription && (
                <p>
                  {`${intl.formatMessage({
                    id: 'engrane.payments-days',
                    defaultMessage: 'The days',
                  })} ${dataStripeSuscription?.created?.day} ${intl.formatMessage({
                    id: 'engrane.payments-days-end',
                    defaultMessage: 'of each month',
                  })}`}
                </p>
              )}
              <p>$15.00</p>
            </div>

            <h2 className="settings__payment__suscription__table__title">
              {intl.formatMessage({
                id: 'engrane.payments-information',
                defaultMessage: 'PAYMENT INFORMATION',
              })}
            </h2>

            <div className="suscription__grid__wrapper">
              <div className="grid__one">
                {intl.formatMessage({
                  id: 'engrane.payments-name-in-card',
                  defaultMessage: 'Name on the card',
                })}
              </div>
              <div className="grid__two">{name}</div>
              <div className="grid__border" />
              <div className="grid__three">
                {intl.formatMessage({
                  id: 'engrane.payments-number-card',
                  defaultMessage: 'Card number',
                })}
              </div>
              <div className="grid__four">
                <img src={cardPaymentIcon} alt={cardPaymentIcon} />
                {`···· ···· ${dataStripeSuscription?.cardLast4 || '····'}`}
              </div>

              {dataStripeSuscription && (
                <div className="grid__five">{`${dataStripeSuscription?.cardExpMonth}/${dataStripeSuscription?.cardExpYear}`}</div>
              )}
            </div>

            {subscriptionStatus ? (
              <div className="settings__payment__cancel__container">
                <button
                  className="settings__payment__cancel__suscription"
                  onClick={() => setIsShowCancelSuscription(true)}
                  disabled={isWorking}
                  style={isWorking ? {cursor: 'wait'} : {}}>
                  <img src={cancelSuscriptionIcon} alt={cancelSuscriptionIcon} />
                  {intl.formatMessage({
                    id: `${
                      isWorking
                        ? 'engrane.payments-canceling-suscription'
                        : 'engrane.payments-cancel-suscription'
                    }`,
                    defaultMessage: `${
                      isWorking ? 'Canceling subscription...' : 'Cancel subscription'
                    }`,
                  })}
                </button>
              </div>
            ) : (
              <div className="settings__payment__cancel__container">
                <BotonConBordeDegradado
                  function={openNewSuscriptionModal}
                  width={296}
                  height={66}
                  background={'#267DFF'}
                  style={{
                    borderRadius: '43px',
                    color: '#FFFFFF',
                    fontWeight: 500,
                    border: '1px #267DFF solid',
                    background: '#267DFF',
                    fontSize: '18px',
                  }}>
                  {intl.formatMessage({
                    id: 'engrane.payments-new-suscription',
                    defaultMessage: 'subscribe',
                  })}
                </BotonConBordeDegradado>
              </div>
            )}
          </React.Fragment>
        )}
      </div>

      {isShowCancelSuscription && (
        <Modal
          modalType="warning-alert"
          show={isShowCancelSuscription}
          closeRound
          closeClickOutside
          closeModal={() => setIsShowCancelSuscription(false)}>
          <WarningAlert
            message={intl.formatMessage({
              id: 'engrane.payments-alert-message',
              defaultMessage: 'Unsubscribe from Coco Languages 😢',
            })}
            titleCancel={intl.formatMessage({
              id: 'engrane.payments-alert-close',
              defaultMessage: 'Keep learning',
            })}
            titleAcept={intl.formatMessage({
              id: 'engrane.payments-alert-acept',
              defaultMessage: 'Yes, cancel',
            })}
            salir={cancellSubscriptionUser}
            seguirRevisando={() => setIsShowCancelSuscription(false)}
          />
        </Modal>
      )}
    </>
  )
}

export default SettingsPaymentSuscription
