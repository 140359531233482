import React from 'react'

const TagView = ({name}) => {
  return (
    <div
      style={{
        color: '#FF6363',
        fontSize: '12px',
        lineHeight: '14px',
        marginRight: '2px',
        marginLeft: '2px',
        marginTop: '2px',
      }}>
      {`#${name}`}
    </div>
  )
}

export default TagView
