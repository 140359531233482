import React from 'react'
import './BottonSelectorDomains.css'
import treePointsIcon from './Icons/treePoints.svg'
import pointsLigth from './Icons/pointsLigth.svg'
import {Colors} from './helpers'

const BottonSelectorDomains = ({
  domainSelect,
  onClick,
  showLanguageTitle = true,
  color = Colors.LIGTH,
}) => {
  const getColor = () => {
    if (color === Colors.DARK) {
      return [treePointsIcon, 'botton-selector-dark']
    }

    return [pointsLigth, 'botton-selector-ligth']
  }

  const [icon, colorButton] = getColor()

  return (
    <>
      {showLanguageTitle && domainSelect && (
        <span className="language-active-text">{domainSelect.name}</span>
      )}
      <button
        title="seleccionar lenguages"
        className={`${colorButton}`}
        onClick={onClick}>
        <img className="open-languages-icon" src={icon} alt="open-languages" />
      </button>
    </>
  )
}

export default BottonSelectorDomains
