import React, {useState} from 'react'
import {useStripe, useElements} from '@stripe/react-stripe-js'
import {subscriptionPlan} from '../../../lib/coco/subscriptionsApi'
import {toggleSubscriptionStatus} from '../../../Redux/users/actions'
import {useDispatch} from 'react-redux'
import {useWorkingIndicator} from '../../../hooks/useBooleanToggler'
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'
import SuscriptionSuccess from './SuscriptionSuccess'
import SuscriptionError from './SuscriptionError'
import {
  OptionsCardStripe,
  SuscriptionStateView,
  isSuscriptionViewRequestSuccess,
  isSuscriptionViewRequestError,
  isSuscriptionViewForm,
} from '../helpersPlayments'
import RigthIcon from '../Icons/rigth.svg'
import {useIntl} from 'react-intl'

const CardForm = ({closeModal, currentUser, custom}) => {
  const intl = useIntl()

  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const [name, setName] = useState(`${currentUser?.name || ''}`)
  const [errorStripe, setErrorStripe] = useState('')
  const [isSuscriptionStatus, setIsSuscriptionStatus] = useState(
    SuscriptionStateView.SUSCRIPTION_VIEW_FORM,
  )

  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (isWorking) {
      return
    }

    startWork()

    if (name?.trim()?.length <= 0) {
      setErrorStripe(
        intl.formatMessage({
          id: 'card-form-strapi.error-name',
          defaultMessage: 'The name is required.',
          description: 'The name is required.',
        }),
      )
      finishWork()
      return
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable, form submission until Stripe.js has loaded.
      // setErrorStripe("Datos no cargados");
      setErrorStripe(
        intl.formatMessage({
          id: 'card-form-strapi.error-strapi',
          defaultMessage: 'Data not loaded',
          description: 'Data not loaded',
        }),
      )
      finishWork()
      return
    }

    const item = elements.getElement(CardNumberElement)

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: item,
    })

    console.log(payload)

    if (payload?.error) {
      const {error} = payload
      setErrorStripe(`${error?.message || 'Error'}`)
      finishWork()
      return
    }

    // validacion para evitar dobles request... !no borrar
    if (isWorking) {
      return
    }

    stripe.createToken(item).then((res) => {
      if (res.token) {
        const planId = process.env.REACT_APP_BASIC_PLAN
        subscriptionPlan(payload, planId, name, res.token, custom).then(
          (response) => {
            finishWork()
            if (response?.status) {
              const {user = {subscribed: true, plan_type: 'premium'}} = response

              setIsSuscriptionStatus(
                SuscriptionStateView.SUSCRIPTION_REQUEST_SUCCESS,
              )

              // dispatch(toggleSubscriptionStatus(true, "trial"));
              dispatch(toggleSubscriptionStatus(true, user.plan_type))

              setTimeout(() => {
                closeModal()
              }, 10000)
            } else if (response.message) {
              setIsSuscriptionStatus(SuscriptionStateView.SUSCRIPTION_REQUEST_ERROR)
            }
          },
        )
      } else {
        if (res?.error?.message) {
          setErrorStripe(res?.error?.message)
          finishWork()
        }
      }
    })
  }

  return (
    <div className="card__form__stripe__container">
      {isSuscriptionViewRequestSuccess(isSuscriptionStatus) && (
        <SuscriptionSuccess onClick={closeModal} intl={intl} />
      )}

      {isSuscriptionViewRequestError(isSuscriptionStatus) && (
        <SuscriptionError
          onClick={() =>
            setIsSuscriptionStatus(SuscriptionStateView.SUSCRIPTION_VIEW_FORM)
          }
          intl={intl}
        />
      )}

      {isSuscriptionViewForm(isSuscriptionStatus) && (
        <>
          <p className="card__form__stripe__title">
            {custom
              ? 'Actualizar Tarjeta'
              : intl.formatMessage({
                  id: 'card-form-strapi.title',
                  defaultMessage: 'Payment details',
                  description: 'Payment details',
                })}
          </p>

          {errorStripe?.trim()?.length > 0 && (
            <p className={`form-stripe-errors animate__bounceIn`}>
              {errorStripe || 'error'}
            </p>
          )}

          <form onSubmit={handleSubmit}>
            <label className="card__form__stripe__labels" style={{width: '100%'}}>
              {intl.formatMessage({
                id: 'card-form-strapi.name',
                defaultMessage: 'Name',
                description: 'Name',
              })}
            </label>
            <input
              className="card__form__stripe__input__data"
              type="text"
              placeholder={intl.formatMessage({
                id: 'card-form-strapi.placeholder-name',
                defaultMessage: 'Full name',
                description: 'Full name',
              })}
              onChange={({target}) => setName(target.value)}
              value={name}
            />
            <label className="card__form__stripe__labels" style={{width: '100%'}}>
              {intl.formatMessage({
                id: 'card-form-strapi.card-number',
                defaultMessage: 'Card number',
                description: 'Card number',
              })}
            </label>
            <CardNumberElement
              className="card__form__stripe__input__data"
              options={OptionsCardStripe}
            />

            <div className="card__form__stripe__flex__space__between">
              <div style={{width: '47%'}}>
                <label className="card__form__stripe__labels">
                  {intl.formatMessage({
                    id: 'card-form-strapi.expedition',
                    defaultMessage: 'Expedition number',
                    description: 'Expedition number',
                  })}
                </label>
                <CardExpiryElement
                  className="card__form__stripe__input__data"
                  options={OptionsCardStripe}
                />
              </div>
              <div style={{width: '47%'}}>
                <label className="card__form__stripe__labels">CVC</label>
                <CardCvcElement
                  className="card__form__stripe__input__data"
                  options={OptionsCardStripe}
                />
              </div>
            </div>

            {!custom && (
              <>
                <div
                  style={{
                    border: '1px solid #7894B4',
                    marginTop: '62px',
                    marginBottom: '54px',
                  }}
                />

                <div
                  className="card__form__stripe__flex__space__between"
                  style={{marginBottom: '16px'}}>
                  <p className="card__form__stripe__labels">
                    {intl.formatMessage({
                      id: 'card-form-strapi.sub-total',
                      defaultMessage: 'Expedition number',
                      description: 'Expedition number',
                    })}
                  </p>
                  <label className="card__form__stripe__labels">$15.00</label>
                </div>
                <div
                  className="card__form__stripe__flex__space__between"
                  style={{marginBottom: '16px'}}>
                  <p className="card__form__stripe__labels">
                    {intl.formatMessage({
                      id: 'card-form-strapi.descuento',
                      defaultMessage: 'Discount',
                      description: 'Discount',
                    })}
                  </p>
                  <label className="card__form__stripe__labels">$0.00</label>
                </div>
                <div
                  className="card__form__stripe__flex__space__between"
                  style={{marginBottom: '16px'}}>
                  <p className="card__form__stripe__labels">
                    {intl.formatMessage({
                      id: 'card-form-strapi.total',
                      defaultMessage: 'Total',
                      description: 'Total',
                    })}
                  </p>
                  <label className="card__form__stripe__labels">$15.00</label>
                </div>
              </>
            )}

            <button
              className="form__stripe__submit"
              type="submit"
              disabled={!stripe || isWorking}
              style={isWorking ? {opacity: 0.5, cursor: 'wait'} : {}}>
              <span>{custom ? '' : '$15.00 usd'}</span>
              <span>
                {custom
                  ? 'Update Card'
                  : intl.formatMessage({
                      id: 'card-form-strapi.pay',
                      defaultMessage: 'Make payment',
                      description: 'Make payment',
                    })}
                <img style={{marginLeft: '10px'}} src={RigthIcon} alt={RigthIcon} />
              </span>
            </button>
          </form>
        </>
      )}
    </div>
  )
}

export default CardForm
