export default {
  en: {
    'dashboard.tracks-shared': 'Your friends recommend you',
    'dashboard.texts-add': 'Added texts',
    'dashboard.listening': 'Keep Listening',
    'dashboard.recomendation': 'Recommended Music',
    'dashboard.artists-recomendation': 'Recommended Artists',
    'dashboard.likes': 'Your Likes',
    'dashboard.read': 'Read',
    'dashboard.practice': 'Practice',
    'dashboard.flashcards': 'Flashcards',
    'dashboard.listen': 'Listen',
    'dashboard.pronunciation': 'Pronounce',
    'dashboard.memorize': 'Memorize',
    'dashboard.daily': 'DAILY PROGRESS',
    'output.search-words': 'Search for a word...',
    'output.add-words': 'Add word ',
    'output.results-words': 'Search results:',
    'output.word-searched': 'Here`s the word you searched for:',
    'output.word-no-exits': 'Oops! We can`t find the word you`re looking for.',
    'output.frecuencie-1': 'Frequency one',
    'output.frecuencie-2': 'Frequency two',
    'output.frecuencie-3': 'Frequency three',
    'output.frecuencie-4': 'Frequency four',
    'output.frecuencie-5': 'Frequency five',
    'output.words-created': 'Words created',
    'output.doing-phrase': 'Make some sentences with the word:',
    'output.other-word': 'ANOTHER WORD',
    'output.selected-word':
      'Select a word from the right and create sentences with it or mark it as learned.',
    'output.alert-warning-message':
      'If you exit the screen, the phrases will be lost.',
    'output.alert-warning-title-exit': 'Leave',
    'output.alert-warning-title-continue': 'Keep checking',
    'output.history': 'Responses History',
    'output.comments': 'Comments',
    'output.corrections': 'Corrections',
    'output.response': 'Edit Response',
    'output.unverified-responses': 'Your uncorrected responses',
    'output.by-verify': 'You can edit your answers before they are verified',
    'output.late-verify':
      'Later you can still edit this same answer, but before it gets reviewed by a teacher',
    'output.show-translate': 'Show Translation',
    'output.hide-translate': 'Hide Translation',
    'output.finish-editing': 'Finish Editing',
    'output.easy': 'Easy',
    'output.medium': 'Medium',
    'output.free': 'Free',
    'output.hard': 'Hard',
    'output.phrases': 'Phrases',
    'output.no-output': 'There are no questions in this category.',
    'output.title': 'Title',
    'output.placeholder':
      'Write a lot! Make mistakes! That way you will learn faster',
    'output.send': 'Send',
    'output.go': 'Continue',
    'output.keep-type': 'Write another',
    'output.send-success': 'Your text was sent successfully!',
    'output.native-teacher':
      'A professional teacher will review it in less than 24 hours.',
    'output.no-responses': 'There are no answers to this questions, yet!',
    'output.other-too': 'Other students also answered this question:',
    'output.help': "Search what you don't know in order to express your ideas.",
    'output.not-found':
      'No example sentences found. Be sure to search in your native language.',
    'output.happen':
      'If it keeps happening, try a shorter sentence ("give", "find", "pass", etc.)',
    'output.example': 'Examples',
    'output.direct': 'Direct Translation',
    'output.response-history': 'Response History',
    'output.empty-response-history': 'Response History is empty',
    'flashcard.deck': 'Add a flashcard to your deck.',
    'output.close': 'Close',
    'output.done': 'Done',
    'output.check-1': 'Review your corrections and add them to your deck',
    'output.check-2': 'to keep learning',
    'dropdown.back': 'Go back',
    'dropdown.play': 'Study (Play)',
    'dropdown.add': 'Add to Playlist',
    'dropdown.remove': 'Remove from Playlist',
    'dropdown.create-playlist': 'Create Playlist',
    'dropdown.go': 'Go to',
    'dropdown.shared': 'Share',
    'dropdown.shared-friend': 'Share with a friend',
    'dropdown.shared-community': 'Share to the community',
    'dropdown.like': 'Add to my Likes',
    'dropdown.like_delete': 'delete from my Likes',
    'dropdown.add-playlist-success': 'was added to',
    'dropdown.delete-from-playlist-success': 'successfully removed',
    'dropdown.delete-from-playlist-error': 'it was not eliminated',
    'notification.title': 'Notifications',
    'notification.mediatext': 'We have verified these songs for you',
    'topbar.community': 'Community',
    'topbar.team': 'Results',
    'topbar.invite': 'Invite',
    'topbar.search': 'Search tracks',
    'drawer.mytools': 'my things',
    'drawer.outputs': 'Answered exercises',
    'drawer.flashcards': 'Flashcards created',
    'drawer.tracks': 'Tracks studied',
    'drawer.words': 'Words learned',
    'drawer.close-sesion': 'Logout',
    'drawer.language-platform': 'Language:',
    'drawer.language-description': 'Select the interface language',
    'drawer.alert-message': 'Logout of coconut 😢',
    'drawer.alert-close': 'Keep learning',
    'drawer.alert-acept': 'Yes, Logout',
  },
  es: {
    'dashboard.tracks-shared': 'Tus amigos te recomiendan',
    'dashboard.texts-add': 'Textos agregados',
    'dashboard.listening': 'Seguir Escuchando',
    'dashboard.recomendation': 'Música Recomendada',
    'dashboard.artists-recomendation': 'Artistas Recomendados',
    'dashboard.likes': 'Tus Me Gusta',
    'dashboard.read': 'Leer',
    'dashboard.practice': 'Practicar',
    'dashboard.flashcards': 'Flashcards',
    'dashboard.listen': 'Escuchar',
    'dashboard.pronunciation': 'Pronunciar',
    'dashboard.memorize': 'Memorizar',
    'dashboard.daily': 'PROGRESO DIARIO',
    'output.search-words': 'Busca una palabra..',
    'output.add-words': 'Agregar palabra ',
    'output.results-words': 'Resultados de búsqueda:',
    'output.word-searched': 'Aquí está la palabra que buscaste:',
    'output.word-no-exits': 'Ups! No encontramos la palabra que estás buscando.',
    'output.frecuencie-1': 'Frecuencia uno',
    'output.frecuencie-2': 'Frecuencia dos',
    'output.frecuencie-3': 'Frecuencia tres',
    'output.frecuencie-4': 'Frecuencia cuantro',
    'output.frecuencie-5': 'Frecuencia cinco',
    'output.words-created': 'Palabras creadas',
    'output.doing-phrase': 'Haz unas oraciones con la palabra:',
    'output.other-word': 'OTRA PALABRA',
    'output.selected-word':
      'Selecciona una palabra de la derecha y crea frases con ella o márcala como aprendida.',
    'output.alert-warning-message':
      'Si sales de la pantalla se perderán las frases.',
    'output.alert-warning-title-exit': 'Salir',
    'output.alert-warning-title-continue': 'Seguir revisando',
    'output.history': 'Historial de Respuestas',
    'output.comments': 'Comentarios',
    'output.corrections': 'Correcciones',
    'output.response': 'Editar Respuesta',
    'output.unverified-responses': 'Tus respuestas no corregidas',
    'output.by-verify': 'Podrás editar tus respuestas antes de que las verifiquen',
    'output.late-verify':
      'Mas tarde aún podrás editar esta misma respuesta, pero antes de que sea revisada por un profesor',
    'output.show-translate': 'Ver Traducción',
    'output.hide-translate': 'Ocultar Traducción',
    'output.finish-editing': 'Terminar Edición',
    'output.easy': 'Fácil',
    'output.medium': 'Intermedio',
    'output.free': 'Libre',
    'output.hard': 'Difícil',
    'output.phrases': 'Frases',
    'output.no-output': 'No hay preguntas en esta categoria',
    'output.title': 'Título',
    'output.placeholder': 'Escribe mucho! Equivócate! Así aprenderás más rápido',
    'output.send': 'Enviar',
    'output.go': 'Seguir',
    'output.keep-type': 'Escribe otra',
    'output.send-success': '¡Tu texto fue enviado con éxito!',
    'output.native-teacher': ' Un profesor nativo lo revisará en menos de 24 hrs.',
    'output.no-responses': '¡Aún no hay respuestas para esta pregunta!',
    'output.other-too': 'Otros alumnos tambien contestaron esta pregunta:',
    'output.help': 'Busca lo que no sepas para expresar tus ideas.',
    'output.not-found':
      'No se encontraron frases de ejemplo. Asegura buscar en tu lengua nativa.',
    'output.happen':
      'Si sigue pasando, prueba con una frase más corta ("dar", "encontrar", "pasar", etc.)',
    'output.example': 'Ejemplos',
    'output.direct': 'Traducción Directa',
    'output.response-history': 'Historial de Respuestas',
    'output.empty-response-history': 'Historial de Respuestas Vacio',
    'flashcard.deck': 'Agrega un flashcard a tu mazo',
    'output.close': 'Cerrar',
    'output.done': 'Listo',
    'output.check-1': 'Revisa tus correcciones y agrégalas a tu mazo',
    'output.check-2': 'para seguir aprendiendo.',
    'dropdown.back': 'Ir atras',
    'dropdown.play': 'Estudiar (Reproducir)',
    'dropdown.add': 'Agregar a playlist',
    'dropdown.remove': 'Eliminar de playlist',
    'dropdown.create-playlist': 'Crear playlist',
    'dropdown.go': 'Ir a',
    'dropdown.shared': 'Compartir',
    'dropdown.shared-friend': 'Compartir con un amigo',
    'dropdown.shared-community': 'Compartir a la comunidad',
    'dropdown.like': 'Agregar a mis likes',
    'dropdown.like_delete': 'Eliminar de mis Likes',
    'dropdown.add-playlist-success': 'se agrego a',
    'dropdown.delete-from-playlist-success': 'eliminado con éxito',
    'dropdown.delete-from-playlist-error': 'no se elimino',
    'notification.title': 'Notificaciones',
    'notification.mediatext': ' Hemos verificado estas canciones para ti',
    'topbar.community': 'Comunidad',
    'topbar.team': 'Resultados',
    'topbar.invite': 'Invitar',
    'topbar.search': 'Buscar musica',
    'drawer.mytools': 'Mis cosas',
    'drawer.outputs': 'Ejercicios contestados',
    'drawer.flashcards': 'Flashcards creadas',
    'drawer.tracks': 'Canciones estudiadas',
    'drawer.words': 'Palabras aprendidas',
    'drawer.close-sesion': 'Cerrar sesión',
    'drawer.language-platform': 'Idioma:',
    'drawer.language-description': 'Selecciona el idioma del la interfaz',
    'drawer.alert-message': 'Salir de coco 😢',
    'drawer.alert-close': 'Seguir aprendiendo',
    'drawer.alert-acept': 'Si, salir',
  },
  fr: {
    'dashboard.tracks-shared': 'Vos amis vous recommandent',
    'dashboard.texts-add': 'Textes ajoutés',
    'dashboard.listening': 'Continuez à écouter',
    'dashboard.recomendation': 'Musique recommandée',
    'dashboard.artists-recomendation': 'Artistes recommandés',
    'dashboard.likes': 'Seus gostos',
    'dashboard.read': 'Lis',
    'dashboard.practice': 'Pratiquer',
    'dashboard.flashcards': 'Flashcards',
    'dashboard.listen': 'Entendre',
    'dashboard.memorize': 'Mémoriser',
    'dashboard.daily': 'PROGRÈS QUOTIDIEN',
    'output.search-words': 'Rechercher un mot ..',
    'output.add-words': 'Ajouter un mot ',
    'output.results-words': 'Résultats de recherche:',
    'output.word-searched': 'Voici le mot que vous avez recherché :',
    'output.word-no-exits': 'Oops! Nous ne trouvons pas le mot que vous cherchez.',
    'output.frecuencie-1': 'Fréquence un',
    'output.frecuencie-2': 'Fréquence deux',
    'output.frecuencie-3': 'Fréquence trois',
    'output.frecuencie-4': 'Fréquence quatre',
    'output.frecuencie-5': 'Fréquence cinq',
    'output.words-created': 'Mots créés',
    'output.doing-phrase': 'Faites des phrases avec le mot :',
    'output.other-word': 'UN AUTRE MOT',
    'output.selected-word':
      'Sélectionnez un mot à droite et créez des phrases avec ou marquez-le comme appris.',
    'output.alert-warning-message':
      'Si vous quittez l1écran, les phrases seront perdues.',
    'output.alert-warning-title-exit': 'sortir',
    'output.alert-warning-title-continue': 'Continuez à vérifier',
    'output.history': 'Historique des réponses',
    'output.comments': 'Rétroaction',
    'output.corrections': 'Corrections',
    'output.response': 'Modifier la réponse\n',
    'output.unverified-responses': 'Vos réponses non corrigées\n',
    'output.by-verify':
      "Vous pouvez modifier vos réponses avant qu'elles ne soient vérifiées",
    'output.late-verify':
      "Plus tard, vous pouvez toujours modifier cette même réponse, mais avant qu'elle ne soit examinée par un enseignant",
    'output.show-translate': 'Voir traduction',
    'output.hide-translate': 'Masquer la traduction',
    'output.finish-editing': "Terminer l'édition",
    'output.easy': 'Facile',
    'output.medium': 'Intermédiaire',
    'output.free': 'Libre',
    'output.hard': 'Difficile',
    'output.no-output': "Il n'y a pas de questions dans cette catégorie.",
    'output.title': 'Titre',
    'output.placeholder':
      'Ecrivez beaucoup! Faire une erreur! Donc vous apprendrez plus vite',
    'output.send': 'Envoyer',
    'output.go': 'Suivez-nous\n',
    'dropdown.shared': 'Partager',
    'dropdown.shared-friend': 'Partager avec un ami',
    'dropdown.shared-community': 'Partager avec la communauté',
    'output.keep-type': 'Écrivez une autre',
    'output.send-success': 'Votre texte a été envoyé avec succès',
    'output.native-teacher':
      "Un professeur natif l'examinera en moins de 24 heures.",
    'output.no-responses': "Il n'y a pas encore de réponse à cette question!",
    'output.other-too': "D'autres étudiants ont également répondu à cette question:",
    'output.help': 'Recherchez ce que vous ne savez pas pour exprimer vos idées.',
    'output.not-found':
      "Aucune phrase d'exemple trouvée. Assurez-vous de rechercher dans votre langue maternelle.",
    'output.happen':
      'Si cela continue, essayez une phrase plus courte ("donner", "trouver", "passer", etc.)',
    'output.example': 'Exemples',
    'output.direct': 'Traduction directe',
    'output.response-history': 'Historique des réponses',
    'output.empty-response-history': 'Historique de réponse vide',
    'flashcard.deck': 'Ajoutez une carte flash à votre deck',
    'output.close': 'Fermer',
    'output.done': 'Prêt',
    'output.check-1': 'Passez en revue vos corrections et ajoutez-les à votre',
    'output.check-2': 'pour continuer à apprendre.',
    'dropdown.back': 'retourner',
    'dropdown.play': 'Étude (jouer)',
    'dropdown.add': 'Ajouter à la playlist',
    'dropdown.remove': 'Supprimer de la playlist',
    'dropdown.create-playlist': 'Créer une playlist',
    'dropdown.go': 'Allez à\n',
    'dropdown.like': 'Ajouter à mes likes',
    'dropdown.like_delete': 'supprimer de mes goûts',
    'dropdown.add-playlist-success': 'a été ajouté à',
    'dropdown.delete-from-playlist-success': 'supprimé avec succès',
    'dropdown.delete-from-playlist-error': 'il n`a pas été éliminé',
    'notification.title': 'Notifications',
    'notification.mediatext': 'Nous avons vérifié ces chansons pour vous',
    'topbar.invite': 'Inviter',
    'topbar.community': 'Communauté',
    'topbar.team': 'Resulte',
    'topbar.search': 'rechercher de la musique',
    'drawer.mytools': 'mes choses',
    'drawer.outputs': 'Exercices avec réponse',
    'drawer.flashcards': 'Flashcards créés',
    'drawer.tracks': 'Chansons étudiées',
    'drawer.words': 'Mots appris',
    'drawer.close-sesion': 'Fermer la session',
    'drawer.language-platform': 'Idiom:',
    'drawer.language-description': 'Sélectionnez la langue de l`interface',
    'drawer.alert-message': 'Sortez de la noix de coco 😢',
    'drawer.alert-close': 'Continue',
    'drawer.alert-acept': 'Oui sors',
  },
}
