import React from 'react'
import '../Output/MessageSuccess.css'
import PropTypes from 'prop-types'

const SuccessMessageFlashcard = ({message, description, footerComponent}) => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column h-100">
      <img
        className="add-frases-success-animation"
        src={
          'https://firebasestorage.googleapis.com/v0/b/assets-a3461.appspot.com/o/main%2FGIF_TRUCK.gif?alt=media&token=a66ef610-982e-4042-a4d8-91b0deb1e4ba'
        }
        alt="ilustración chidísima de un automóvil en las nubes, con estrellas al fondo"
      />

      <div className="text-center my-2">
        <h1 className="title-message">{message}</h1>
        <h6 className="text-white">{description}</h6>
      </div>

      {footerComponent && footerComponent}
    </div>
  )
}

SuccessMessageFlashcard.propTypes = {
  message: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  footerComponent: PropTypes.element,
}

SuccessMessageFlashcard.defaultProps = {
  message: '',
  description: '',
  footerComponent: <></>,
}

export default SuccessMessageFlashcard
