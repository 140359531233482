import React, {Component} from 'react'
import './RevisedMediaTexts.css'
import TrackItem from '../DashboardEstudiante/TrackItem'
import {FormattedMessage} from 'react-intl'

class RevisedMediaTexts extends Component {
  render() {
    const {MediaTextsNotify} = this.props

    console.log('media_texts de notificaciones: ', MediaTextsNotify)

    return (
      <div className="contenedor-notificaciones-inputs">
        <h1 className="notificaciones-inputs-titulo">
          <FormattedMessage
            id={'notification.mediatext'}
            defaultMessage={'Hemos verificado estas canciones para ti'}
          />
        </h1>

        <div className="contenedor-notificaciones-inputs-tracks">
          {MediaTextsNotify && MediaTextsNotify.length > 0
            ? MediaTextsNotify.map((notificacion, index) => (
                <div key={index} className="notificaciones-inputs-track-item">
                  <TrackItem
                    uri={notificacion.uri}
                    id={notificacion.id}
                    spotify_id={notificacion.spotify_id}
                    titulo={notificacion.titulo}
                    image_url={notificacion.image_url}
                    user_input_id={notificacion.user_input_id}
                    artistas={notificacion.artistas}
                  />
                </div>
              ))
            : ''}
        </div>
      </div>
    )
  }
}

export default RevisedMediaTexts
