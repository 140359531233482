const FETCH_NOTIFICATIONS_REQUEST_START =
  'notifications/FETCH_NOTIFICATIONS_REQUEST_START'
const FETCH_NOTIFICATIONS_REQUEST_FINISH =
  'notifications/FETCH_NOTIFICATIONS_REQUEST_FINISH'
const FETCH_NOTIFICATIONS_REQUEST_ERROR =
  'notifications/FETCH_NOTIFICATIONS_REQUEST_ERROR'
const CHECK_NOTIFICATION_READED = 'notifications/CHECK_NOTIFICATION_READED'

export {
  FETCH_NOTIFICATIONS_REQUEST_START,
  FETCH_NOTIFICATIONS_REQUEST_FINISH,
  FETCH_NOTIFICATIONS_REQUEST_ERROR,
  CHECK_NOTIFICATION_READED,
}
