import React, {useRef, useState} from 'react'
import './ListEmojis.css'
import {EmogisList} from '../../../Services/EmojisApi/EmojisList'
import useOnClickOutside from '../../../hooks/useClickOutside'

const ListEmojis = ({toogleEmogis, seleccionarEmoji, autoFocusMessageInput}) => {
  const [text, setText] = useState('')
  const myRef = useRef()

  const handleClickOutside = () => {
    toogleEmogis && toogleEmogis()
  }

  useOnClickOutside(myRef, () => handleClickOutside())

  const filtrarEmojis = ({target}) => {
    setText(target.value)
  }

  const selectEmoji = (e) => {
    seleccionarEmoji(e)
    if (autoFocusMessageInput) autoFocusMessageInput()
  }

  const emojis = EmogisList

  let emojisFilter = emojis

  if (text !== '' && emojis && emojis.length > 0) {
    emojisFilter = emojis.filter(
      (e) =>
        e.unicodeName.toString().includes(text) ||
        e.group.toString().includes(text) ||
        e.subGroup.toString().includes(text),
    )
  }

  return (
    <div className="contenedor-listado-emojis" ref={myRef}>
      <div className="buscardor-emojis">
        <input
          autoFocus
          className="buscador-emojis-text"
          type="text"
          placeholder="Search emogi"
          onChange={filtrarEmojis}
        />
      </div>

      <div className="listado-emojis scroll-coco">
        {emojisFilter &&
          emojisFilter.length > 0 &&
          emojisFilter.map((e) => (
            <div
              key={e.codePoint}
              title={e.unicodeName}
              className="emogi-item"
              onClick={() => selectEmoji(e)}>
              {e.character}
            </div>
          ))}
      </div>
    </div>
  )
}

export default ListEmojis
