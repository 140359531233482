import React, {useState, Fragment, useEffect} from 'react'
import './ExampleSentences.css'

import BotonConBordeDegradado from '../Buttons/BotonConBordeDegradado'
import PronounceSentence from './PronounceSentence'

const ExampleSentences = ({example_sentences, addFlashcard = false}) => {
  const [isShowFullTranslations, setIsShowFullTranslations] = useState(false)
  const [translations, setTranslations] = useState([])

  useEffect(() => {
    if (example_sentences?.exact_matches?.length > 0) {
      const exactMatches = example_sentences.exact_matches[0]
      setIsShowFullTranslations(false)
      setTranslations(exactMatches.translations || [])
    }
  }, [example_sentences])

  const getTranslations = () => {
    if (isShowFullTranslations) {
      return translations || []
    }
    return translations.slice(0, 4) || []
  }

  const translationsExample = getTranslations()

  const isBottonPlusViisble = () => {
    if (translations && translationsExample) {
      const translationCount =
        translations.filter((tl) => tl.examples?.length > 0) || []
      const examplesCount =
        translationsExample.filter((tl) => tl.examples?.length > 0) || []

      if (translationCount?.length > examplesCount?.length) {
        return true
      }
    }
  }

  return (
    <Fragment>
      <ul className="list-unstyled scroll scroll-phrases">
        {translationsExample &&
          translationsExample.length > 0 &&
          translationsExample.map((frase, index) => (
            <div key={index}>
              {frase.examples &&
                frase.examples.length > 0 &&
                frase.examples.map((oracion, index2) => (
                  <div key={index2}>
                    {!addFlashcard && (
                      <h6 className="text-info mx-3">{` ${frase.text}`}</h6>
                    )}
                    <li className="media my-1">
                      <img
                        className="mr-1"
                        src="/images/Output/verified.svg"
                        alt="progress"
                      />
                      <div className="media-body">
                        <div className="traduccion-help">
                          {oracion.target}
                          <PronounceSentence oracion={oracion.target} width="18" />
                        </div>
                        <p className="phrase-help">{oracion.source}</p>
                      </div>

                      {addFlashcard && (
                        <button
                          className="btn"
                          onClick={() =>
                            addFlashcard(oracion.target, oracion.source)
                          }>
                          <img src="/images/addFlashcard.svg" alt="flashcard" />
                        </button>
                      )}
                    </li>
                  </div>
                ))}
            </div>
          ))}
      </ul>

      {isBottonPlusViisble() && (
        <div className="contendor-example-ver-mas">
          <BotonConBordeDegradado
            function={() => setIsShowFullTranslations(!isShowFullTranslations)}
            width={100}
            height={35}>
            <span>{!isShowFullTranslations ? 'Ver más' : 'Ver menos'}</span>
          </BotonConBordeDegradado>
        </div>
      )}
    </Fragment>
  )
}

export default ExampleSentences
