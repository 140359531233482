import React from 'react'
import PropTypes from 'prop-types'
import './ConfirmDelete.css'

import IconDelete from './Icons/IconDelete.svg'

const ConfirmDelete = (props) => {
  const {confirmDelete, message, isDeleteWorking = false} = props

  return (
    <div className="d-flex justify-content-center flex-column h-100">
      <div className="h-75 d-flex justify-content-center align-items-center flex-column">
        <img src={IconDelete} alt="" className="mb-3" />
        <h5 className="msg-confirmation">{message}</h5>
      </div>

      <div className="h-25 d-flex justify-content-center align-items-center">
        <button
          className="btn btn-transparent mx-3 px-4"
          onClick={() => confirmDelete(false)}>
          Cancelar
        </button>

        <button
          className="btn btn-outline-white mx-3 px-4"
          onClick={() => confirmDelete(true)}>
          {isDeleteWorking ? 'Deleting' : 'Eliminar'}
        </button>
      </div>
    </div>
  )
}

ConfirmDelete.propTypes = {
  confirmDelete: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  isDeleteWorking: PropTypes.bool.isRequired,
}

export default ConfirmDelete
