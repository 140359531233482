import * as actions from './actionTypes'
import {isConversationsIncludesCurrentUser} from '../helpers'

export const receivedMessage = (conversation) => ({
  type: actions.RECEIVED_MESSAGE,
  conversation,
})

export const receivedData = (data) => (dispatch, getState) => {
  const currentUser = getState()?.user

  const responseBol = isConversationsIncludesCurrentUser(currentUser, data)

  // if (responseBol) {
  //   try {
  //     const {conversations} = getState()?.conversations
  //     const {id: idNewConversation, messages: messagesNew = []} = data
  //     const conversationFind = conversations.find((c) => c.id === idNewConversation)

  //     const messagesOld = conversationFind?.messages || []

  //     if (messagesNew?.length > messagesOld?.length && messagesNew?.length > 0) {
  //       const idsNewMenssageNew = messagesNew.map((m) => m.id)
  //       const idsNewMenssageOld = messagesOld.map((m) => m.id)

  //       const differenceMessageId = idsNewMenssageNew
  //         .filter((x) => !idsNewMenssageOld.includes(x))
  //         .concat(idsNewMenssageOld.filter((x) => !idsNewMenssageNew.includes(x)))

  //       if (differenceMessageId?.length > 0) {
  //         const messageById = messagesNew.find(
  //           (m) => m.id === differenceMessageId[0],
  //         )
  //         const {user = {id: 0}} = messageById
  //         if (currentUser?.id !== user?.id) {
  //           showMessage(
  //             `${user?.name || user?.email}: ${messageById.text}`,
  //             POSITIONS_ALERT.TOP_END,
  //             4000,
  //           )
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  if (responseBol) {
    return dispatch({
      type: actions.RECEIVED_CONVERSATION_DATA,
      data,
    })
  }
}

export const setMessageCitado = (message) => ({
  type: actions.SET_MESSAGE_CITADO,
  message,
})

export const setIsMiniChatSelectionActive = (isMiniChatSelectionActive) => ({
  type: actions.SET_IS_MINICHAT_SELECTION_ACTIVE,
  isMiniChatSelectionActive,
})

export const removeMessageCitado = () => ({
  type: actions.REMOVE_MESSAGE_CITADO,
})

export const receivedConversations = (conversations) => ({
  type: actions.RECEIVED_CONVERSATIONS,
  conversations,
})

export const setActiveConversationId = (conversationId) => ({
  type: actions.SET_ACTIVE_CONVERSATION_ID,
  conversationId,
})

export const setIsMiniChatActive = (isMiniChatActive) => ({
  type: actions.SET_MINI_CHAT_ACTIVE,
  isMiniChatActive: Boolean(isMiniChatActive),
})

export const setIsHilosActive = (isHilosActive) => ({
  type: actions.SET_HILOS_ACTIVE,
  isHilosActive: Boolean(isHilosActive),
})

// export const newConnection = (payload) => ({
//     type: actions.NEW_CONNECTION,
//     payload
// })

export const fetchConversations = () => ({
  type: actions.FETCH_CONVERSATIONS,
})

export const opening = () => ({
  type: actions.CONVERSATIONS_CONNECTION_OPENING,
})

export const subscribing = () => ({
  type: actions.CONVERSATIONS_CONNECTION_SUBSCRIBING,
})

export const ready = () => ({
  type: actions.CONVERSATIONS_CONNECTION_READY,
})

export const wsconnect = (payload) => ({
  type: actions.WS_CONVERSATIONS_CONNECT,
  payload,
})

export const subscribe = (payload) => ({
  type: actions.WS_CONVERSATIONS_SUBSCRIBE,
  payload,
})

export const isTyping = (user, conversation) => ({
  type: actions.WS_CONVERSATIONS_TYPING_START,
  user,
  conversation,
})

export const stoppedTyping = (user, conversation) => ({
  type: actions.WS_CONVERSATIONS_TYPING_STOP,
  user,
  conversation,
})

export const sendMessage = (
  user_id,
  conversation_id,
  text,
  mentions,
  image,
  audio,
) => ({
  type: actions.WS_SEND_CHAT_MESSAGE,
  user_id,
  conversation_id,
  text,
  mentions,
  image,
  audio,
})

export const createConversation = (user_id, title) => ({
  type: actions.WS_CREATE_CONVERSATION,
  user_id,
  title,
})
