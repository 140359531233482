import React from 'react'
import {
  disablePlayBackRepeat,
  setPlaybackRepeat,
} from '../../../Redux/spotify/requests/player'
import {useDispatch} from 'react-redux'
import RepeatIcon from '../icons/RepeatIcon.svg'
import RepeatIconActive from '../icons/RepeatActiveIcon.svg'
import {useModalSpotifyAuth} from '../../../hooks/useSpotifyModalAuth'
import {useSelector} from 'react-redux'

const Repeat = () => {
  const {toggleSpotifyModalAuth, isSpotifyConnected} = useModalSpotifyAuth()
  const spotifyStatus = isSpotifyConnected()
  const {
    player: {repeat_mode},
  } = useSelector((state) => state.spotify)
  const dispatch = useDispatch()
  const repeat = () => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
    } else {
      if (repeat_mode > 0) {
        disablePlayBackRepeat()
      } else {
        dispatch(setPlaybackRepeat)
      }
    }
  }
  const imgSource = repeat_mode > 0 ? RepeatIconActive : RepeatIcon
  return (
    <button onClick={() => repeat()} style={{background: 'none', marginLeft: '7px'}}>
      <img src={imgSource} alt="repeat" />
    </button>
  )
}

export default Repeat
