import React from 'react'
import {useIntl} from 'react-intl'
import ImageSearch from './Images/Search.svg'

const SearchPicture = () => {
  const intl = useIntl()
  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100">
      <p className="text-initial" style={{width: '300px'}}>
        {intl.formatMessage({
          id: 'diccionary.search-description',
          defaultMessage: 'Look up any word you want to know.',
        })}
      </p>
      <img src={ImageSearch} alt={ImageSearch} style={{width: '80%'}} />
    </div>
  )
}

export default SearchPicture
