const FETCH_MYINPUTS_REQUEST_START = 'MyInputs/FETCH_MYINPUTS_REQUEST_START'
const FETCH_MYINPUTS_REQUEST_FINISH = 'MyInputs/FETCH_MYINPUTS_REQUEST_FINISH'
const FETCH_MYINPUTS_REQUEST_ERROR = 'MyInputs/FETCH_MYINPUTS_REQUEST_ERROR'
const UPDATE_TRACK_MYINPUTS_LIKE = 'MYInputs/UPDATE_TRACK_MYINPUTS_LIKE'

export {
  FETCH_MYINPUTS_REQUEST_START,
  FETCH_MYINPUTS_REQUEST_FINISH,
  FETCH_MYINPUTS_REQUEST_ERROR,
  UPDATE_TRACK_MYINPUTS_LIKE,
}
