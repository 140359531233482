import React, {useEffect, useState, useRef} from 'react'
import './RespuestaDelUsuario.css'
import PropTypes from 'prop-types'
import Tooltip from '../../Tooltip/Tooltip'
import Modal from '../../Modal'
import TranslationFlashcardForm from '../../FlashCards/TranslationFlashcardForm'
import {
  createUserOutputLike,
  deleteUserOutputLike,
} from '../../../lib/coco/OutputsApi'
import SelectionContextMenu from '../../ContextMenu/SelectionContextMenu'
import ContextWrapper from '../../ContextMenu/ContextWrapper'
import useAlert, {TOAST_TYPE} from '../../../hooks/useAlert'
import {UserOutputCorrectionType} from '../helpersOutputs'

const RespuestaDelUsuario = (props) => {
  const {showMessage} = useAlert()
  //Props
  const {
    updateLikeUserOutput,
    updateIndicesYaAgregados,
    likesCount: likesCountProps,
    userOutputLikeId: userOutputLikeIdProps,
    cantidadDeComentarios,
    user,
    userResponseTitle,
    perfilDeUsuario,
    mostrarTitulo,
    toggleShowComments,
    userResponse,
  } = props

  //States
  const [likesCount, setLikesCount] = useState(likesCountProps)
  const [userOutputLikeId, setUserOutputLikeId] = useState(userOutputLikeIdProps)
  const [mostrarModalFlashcard, setMostrarModalFlashcard] = useState(false)
  const [phrase, setPhrase] = useState('')
  const [phraseTranslation, setPhraseTranslation] = useState('')
  const [lineIndexInText, setLineIndexInText] = useState(null)
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [selectedText, setSelectedText] = useState('')
  const [contextMenuPosition, setContextMenuPosition] = useState(null)
  const contextRef = useRef(null)

  useEffect(() => {
    setLikesCount(likesCountProps)
    setUserOutputLikeId(userOutputLikeIdProps)
  }, [likesCountProps, userOutputLikeIdProps])

  const updateParentLike = (idLike) => {
    if (updateLikeUserOutput) {
      updateLikeUserOutput(idLike)
    }
  }

  const like = () => {
    createUserOutputLike(userResponse.id).then((response) => {
      if (response.userOutputLike) {
        const {
          userOutputLike: {id},
        } = response

        setUserOutputLikeId(id)
        setLikesCount(likesCount + 1)
        updateParentLike(id)
      } else {
        showMessage(`${response.message}, No se pudo dar like`, TOAST_TYPE.ERROR)
      }
    })
  }

  const dislike = () => {
    deleteUserOutputLike(userOutputLikeId).then((response) => {
      if (!response.message) {
        setUserOutputLikeId(null)
        setLikesCount(likesCount - 1)
        updateParentLike(null)
      } else {
        showMessage(
          `${response.message}, No se pudo eliminar el like`,
          TOAST_TYPE.ERROR,
        )
      }
    })
  }

  const selectText = () => {
    if (window.getSelection) {
      const text = window.getSelection().toString()
      if (text !== '') {
        setSelectedText(text)
        setShowContextMenu(true)
        //setMostrarModalFlashcard(false)
      }
    }
  }

  const myRef = () => {
    return contextRef
  }

  //La respuesta de los otros usuarios viene dividida en frases por lo tanto es necesario recontruir la
  const recontruirRespuesta = (oracion, index) => {
    const phrase =
      oracion.status === UserOutputCorrectionType.MAL
        ? oracion.corrected_sentence
        : oracion.original_sentence
    const phraseTranslation = UserOutputCorrectionType.MAL
      ? oracion.corrected_translation
      : oracion.original_translation

    if (oracion.status !== UserOutputCorrectionType.ININTELIGIBLE) {
      return (
        <span
          id="oracion-de-respuesta"
          onClick={() =>
            crearFlashcardDeUnaOracion(phrase, phraseTranslation, index)
          }
          onMouseUp={() => {
            selectText(index)
            setContextMenuPosition(index)
          }}>
          {phrase}&nbsp;
          {contextMenuPosition === index && showContextMenu && (
            <ContextWrapper
              close={() => setShowContextMenu(false)}
              myRef={() => myRef()}>
              <SelectionContextMenu text={selectedText} />
            </ContextWrapper>
          )}
        </span>
      )
    } else {
      // ininteligible: puede ser una pregunta del maestro para tratar de descifrar la respuesta del usuario.
      return <Tooltip text={oracion.original_sentence} tooltip="Frase ambigüa" />
    }
  }

  const crearFlashcardDeUnaOracion = (phrase, phraseTranslation, index) => {
    if (selectedText === '') {
      setMostrarModalFlashcard(true)
      setPhrase(phrase)
      setPhraseTranslation(phraseTranslation)
      setLineIndexInText(index)
    }
  }

  return (
    <div className="responses__other__users__main">
      {!perfilDeUsuario && (
        <div className="mb-3 text-white" style={{fontSize: '20px'}}>
          <span className="font-weight-bold text-capitalize">{user}</span>
          <span> respondió:</span>
        </div>
      )}

      {mostrarTitulo && (
        <h5 id="output-item" className="text-truncate">
          {userResponseTitle}
        </h5>
      )}

      <p id="respuesta-del-usuario">
        {userResponse.oraciones &&
          userResponse.oraciones.map((oracion, index) => {
            return recontruirRespuesta(oracion, index)
          })}
      </p>

      <div className="mb-3 p-0">
        {userOutputLikeId ? (
          <span className="mr-5 p-0" onClick={dislike}>
            <img
              src="/images/Output/Like.svg"
              className="mr-2 cursor-pointer"
              alt="Like"
            />
            {perfilDeUsuario ? (
              <span id="cantidad-de-likes">{likesCount}</span>
            ) : (
              <span id="cantidad-de-likes">{`${likesCount} ${
                likesCount !== 1 ? 'likes' : 'like'
              }`}</span>
            )}
          </span>
        ) : (
          <span className="mr-5 p-0" onClick={like}>
            <img
              src="/images/Output/Dislike.svg"
              className="mr-2 cursor-pointer"
              alt="Dislike"
            />
            {perfilDeUsuario ? (
              <span id="cantidad-de-likes">{likesCount}</span>
            ) : (
              <span id="cantidad-de-likes">{`${likesCount} ${
                likesCount !== 1 ? 'likes' : 'like'
              }`}</span>
            )}
          </span>
        )}

        {/*Todo: Mostrar los comentarios*/}
        <span className="mr-5 p-0 cursor-pointer" onClick={toggleShowComments}>
          <img
            src="/images/Output/Comment.svg"
            alt="Comment"
            className="mr-2 cursor-pointer"
          />
          {perfilDeUsuario ? (
            <span id="cantidad-de-comentarios">{cantidadDeComentarios}</span>
          ) : (
            <span id="cantidad-de-comentarios">
              {`${cantidadDeComentarios} ${
                cantidadDeComentarios !== 1 ? 'comentarios' : 'comentario'
              }`}
            </span>
          )}
        </span>
      </div>

      <Modal
        modalType="output"
        show={mostrarModalFlashcard}
        closeModal={() => setMostrarModalFlashcard(false)}>
        <TranslationFlashcardForm
          phraseTranslation={phraseTranslation}
          phrase={phrase}
          updateIndicesYaAgregados={updateIndicesYaAgregados}
          paramsItemOptionsRequest={{
            line_index_in_text: lineIndexInText,
            itemizable_id: userResponse?.id,
            itemizable_type: 'UserOutput',
            phrase_id: null,
            phrase_translation_id: null,
          }}
        />
      </Modal>
    </div>
  )
}

RespuestaDelUsuario.propTypes = {
  userResponseTitle: PropTypes.string.isRequired,
  likesCount: PropTypes.number.isRequired,
  // TODO: En lugar de pasarle el arreglo completo de comentarios solo le pasaré la cantidad de comentarios.
  cantidadDeComentarios: PropTypes.number.isRequired,
  userResponse: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
  perfilDeUsuario: PropTypes.bool,
  mostrarTitulo: PropTypes.bool,
}

RespuestaDelUsuario.defaultProps = {
  perfilDeUsuario: false,
  mostrarTitulo: true,
}

export default RespuestaDelUsuario
