import React from 'react'
import Player from './Player'
import LikeButton from './buttons/LikeButton'
import PlayButton from './buttons/PlayButton'
import SeekBack from './buttons/SeekBack'
import ProgressBar from './ProgressBar'
import './ReproductorSpotify.css'
import ShowKaraoke from './buttons/ShowKaraoke'
import {Link} from 'react-router-dom'
import useSpotify from '../../hooks/useSpotify'
import useLanguages from '../../hooks/useLanguages'
import useMediaText from '../../hooks/useMediaText'
import Repeat from './buttons/Repeat'
import SkipToLast from './buttons/SkipToLast'
import SkipToNext from './buttons/SkipToNext'
import DefaultIconTrack from '../Icons/DefaultIconTrack.svg'

const ReproductorSpotify = () => {
  const {showKaraokeFromPlayer} = useMediaText()
  const {languageActive} = useLanguages()
  const stateSpotify = useSpotify()
  const {
    player: {
      track_window: {current_track},
      is_active,
    },
  } = stateSpotify

  const showKaraoke = () => {
    showKaraokeFromPlayer()
  }

  const image_url = current_track.album.images[0].url
    ? current_track.album.images[0].url
    : DefaultIconTrack

  const lang = languageActive?.unicode ? languageActive.unicode : 'es'
  const title =
    lang === 'es'
      ? 'Estudiar'
      : lang === 'fr'
      ? 'Etudier'
      : lang === 'pt'
      ? 'Estudar'
      : 'Study'
  const song_title = lang === 'es' ? 'Titulo de Cancion' : 'Song Title'
  const go = lang === 'es' ? 'Ir a' : 'Go to'

  return (
    <Player>
      <div className="reproductor-spotify">
        <div className="reproductor-item-info">
          <img
            src={image_url}
            alt={image_url}
            className="foto rounded-circle"
            title={`${title} ${current_track.name}`}
            onClick={() => showKaraoke()}
            style={{cursor: 'pointer'}}
          />
          <div className="like-music-reproductor">
            <LikeButton
              spotify_id={current_track.id}
              titulo={current_track.name}
              image_url={image_url}
              artistas={current_track.artists}
              isPlayerActive={is_active}
            />
          </div>
          <div className="information__track__player">
            <h6
              className="player__track__title text-truncate"
              title={`${title} ${current_track.name}` || song_title}
              onClick={() => showKaraoke()}>
              {current_track.name || ''}
            </h6>
            <div
              className="text-artist-contenedor text-truncate"
              title={
                current_track.artists.map((a) => a.name).join(', ') || 'artist name'
              }>
              {/* .replace("spotify:artist:", "") */}
              {(current_track.artists &&
                current_track.artists.length > 0 &&
                current_track.artists.map((a, index) => (
                  <span key={index}>
                    <Link
                      key={index}
                      className="text-artist"
                      to={`/artista/${
                        (a.uri !== undefined &&
                          a.uri.replace('spotify:artist:', '')) ||
                        ''
                      }`}
                      title={`${go} ${a.name}`}>
                      {a.name}
                    </Link>
                    {index < current_track.artists.length - 1 ? ', ' : ''}
                  </span>
                ))) ||
                'artist name'}
            </div>
          </div>
        </div>
        <div className="reproductor-item-controls">
          <SkipToLast />
          <PlayButton />
          <SkipToNext />
          <SeekBack />
        </div>
        <div className="reproductor-item-progreso">
          <ProgressBar />
        </div>

        <div className="reproductor-item-controls-secondary">
          <Repeat />
          <ShowKaraoke showKaraoke={showKaraoke} />
        </div>
      </div>
    </Player>
  )
}

export default ReproductorSpotify
