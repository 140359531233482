import React from 'react'
import PronounceSentence from '../Translator/PronounceSentence'

const DiccionaryOxfordLemma = ({diccionaryOxford, wordMark}) => {
  return (
    <React.Fragment>
      {diccionaryOxford?.lemma && (
        <div className="diccionary__headers__lemma">
          {diccionaryOxford?.lemma}
          <PronounceSentence
            oracion={diccionaryOxford?.lemma}
            width="16"
            langCode={wordMark?.lang_code}
          />
        </div>
      )}

      {diccionaryOxford?.translations && (
        <div className="diccionary__headers__translations__text">
          {diccionaryOxford?.translations?.length > 0 &&
            diccionaryOxford?.translations.map((t, index) =>
              index === diccionaryOxford?.translations?.length - 1 ? (
                <span key={index}>{` ${t.translation}`}</span>
              ) : (
                <span key={index}>{` ${t.translation}`} &#183;</span>
              ),
            )}
        </div>
      )}
    </React.Fragment>
  )
}

export default DiccionaryOxfordLemma
