const DiccionaryType = {
  COCO: 'coco',
  OXFORD: 'oxford',
  GOOGLE: 'google',
}

const isDiccionaryCoco = (status) => status === DiccionaryType.COCO
const isDiccionaryOxford = (status) => status === DiccionaryType.OXFORD
const isDiccionaryGoogle = (status) => status === DiccionaryType.GOOGLE

export {DiccionaryType, isDiccionaryCoco, isDiccionaryOxford, isDiccionaryGoogle}
