import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../Tooltip/Tooltip'
import Modal from '../Modal'
import TranslationFlashcardForm from '../FlashCards/TranslationFlashcardForm'
import BotonRedondoConDegradado from '../Buttons/BotonRedondoConDegradado'
import YaAgregado from '../Buttons/YaAgregado'
import ListadoTags from '../FlashCards/ListadoTags'
import PronounceSentence from '../Translator/PronounceSentence'
import {isCorrectAnswer} from '../../Services/CadenasSplit'
import SelectionContextMenu from '../ContextMenu/SelectionContextMenu'
import {useComponentVisible} from '../../hooks/useComponentVisible'
import useAlert, {TOAST_TYPE} from '../../hooks/useAlert'
import iconPlus from '../Icons/iconPlus.svg'
import Waveform from '../Waveform/Waveform'
import './UserOutput.css'
import {UserOutputCorrectionType} from './helpersOutputs'

//Es una lista de frases(el output del usuario dividido)
//Si se quiere, cada frase se puede agragar como flashcard
//Para agregar un flashcard se abre un modal
//Luego de agregar un flashcard se muestra en mensage de enviado/exito
const UserOutput = (props) => {
  const {showMessage} = useAlert()
  const {
    userOutputId,
    userOutputCorrected,
    style,
    studentAudio,
    teacherAudio,
  } = props

  const {
    arrayRef,
    isComponentVisible,
    setIsComponentVisible,
    setIndexCurrentRef,
  } = useComponentVisible(false, userOutputCorrected)

  const [phrase, setPhrase] = useState('')
  const [phraseTranslation, setPhraseTranslation] = useState('')
  const [phraseId, setPhraseId] = useState(null)
  const [translatedPhraseId, setTranslatedPhraseId] = useState(null)
  const [lineIndexInText, setLineIndexInText] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [indicesYaAgregados, setIndicesYaAgregados] = useState(
    props.indicesYaAgregados,
  )
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [contextMenuPosition, setContextMenuPosition] = useState(false)
  const [selectedText, setSelectedText] = useState('')

  const selectText = () => {
    if (window.getSelection) {
      const text = window.getSelection().toString()
      if (text !== '' || text) {
        setSelectedText(text)
        setShowContextMenu(true)
      }
    }
  }

  const updateIndicesYaAgregados = (lineIndex) => {
    const newIndexsAdd = [...indicesYaAgregados, lineIndex]
    indicesYaAgregados.push(lineIndex) // para que memorise el index en el objeto padre, No quitar
    setIndicesYaAgregados(newIndexsAdd)
  }

  useEffect(() => {
    const {indicesYaAgregados} = props
    setIndicesYaAgregados(indicesYaAgregados)
    // eslint-disable-next-line
  }, [userOutputId])

  // El status de cada item es representado con un icono diferente.
  const renderIcon = (status) => {
    switch (status) {
      case UserOutputCorrectionType.BIEN:
        return (
          <img
            style={{marginRight: '8px'}}
            src="/images/iconCaritaFeliz.svg"
            alt="iconCaritaFeliz"
          />
        )
      case UserOutputCorrectionType.MAL:
        return (
          <img
            style={{marginRight: '8px'}}
            id="icon-calendar-o"
            src="/images/iconSignoDeExclamacion.svg"
            alt="iconSignoDeExclamacion"
          />
        )
      case UserOutputCorrectionType.ININTELIGIBLE:
        return (
          <img
            style={{marginRight: '8px'}}
            id="icon-calendar-o"
            src="/images/iconSignoDeInterrogacion.svg"
            alt="iconSignoDeInterrogacion"
          />
        )
      default:
        return
    }
  }

  const findWordInOriginalSentence = (word, correction) => {
    const originalSentenceSplited = correction?.original_sentence?.split(' ') || []

    const include = originalSentenceSplited.find((msg) => isCorrectAnswer(msg, word))
    return !!include
  }

  const getColorUserInputCorrection = (correction) => {
    if (correction.original_sentence?.trim()?.length > 0) {
      const correctionSplited = correction?.corrected_sentence?.split(' ') || []
      return correctionSplited.map((word) => {
        const isInclude = findWordInOriginalSentence(word, correction)
        return (
          <span
            style={{
              color: isInclude ? 'white' : '#F2C94C',
              marginRight: '4px',
              fontSize: '19px',
            }}
            title={`${!isInclude ? 'Corregida \ud83e\udd2a' : 'Bien \ud83d\ude04'}`}>
            {`${word} `}
          </span>
        )
      })
    }
    return correction?.corrected_sentence || ''
  }

  const renderTeacherResponse = (correction, status, index) => {
    switch (status) {
      case UserOutputCorrectionType.BIEN:
        return (
          <p
            id="teacher-response"
            ref={(el) => (arrayRef.current[index] = el)}
            className="position-relative m-0"
            style={{color: '#AEF205'}}
            onMouseUp={() => {
              setIndexCurrentRef(index)
              selectText()
              setIsComponentVisible(true)
              setContextMenuPosition(index)
            }}>
            {correction.corrected_sentence}
            <PronounceSentence
              oracion={correction.corrected_sentence}
              width={'16'}
            />

            {contextMenuPosition === index && isComponentVisible && showContextMenu && (
              <div style={{position: 'absolute', top: 0, left: 0}}>
                <SelectionContextMenu
                  text={selectedText}
                  closeContextMenu={() => {
                    setShowContextMenu(false)
                  }}
                  itemizableId={props.userOutputId}
                  itemizableType="UserOutput"
                />
              </div>
            )}
          </p>
        )
      case UserOutputCorrectionType.MAL:
        // La bocina solo aparecerá si algún teacher a hecho una corrección expresando la manera correcta de la frase.
        return (
          <div id="teacher-response">
            <div style={{color: 'white'}}>
              {getColorUserInputCorrection(correction)}
            </div>
            <PronounceSentence
              oracion={correction.corrected_sentence}
              width={'16'}
            />
          </div>
        )
      case UserOutputCorrectionType.ININTELIGIBLE:
        return (
          <p id="teacher-response" style={{color: '#01BFF4'}}>
            {correction.special_note}
          </p>
        )
      default:
        return
    }
  }

  const addFlashcard = (
    phrase,
    phraseTranslation,
    index,
    phrase_id = null,
    translated_phrase_id = null,
  ) => {
    if (props.isNotification) {
      props.agregarUnFlashcard(
        phrase,
        phraseTranslation,
        index,
        phrase_id,
        translated_phrase_id,
      )
    } else {
      setShowModal(true)
      setPhrase(phrase)
      setPhraseTranslation(phraseTranslation)
      setPhraseId(phrase_id)
      setTranslatedPhraseId(translated_phrase_id)
      setLineIndexInText(index)
    }
  }

  return (
    <>
      <div className="user__output__line__main" style={style}>
        {userOutputCorrected?.length > 0 &&
          userOutputCorrected.map((correction, index) => (
            <div className="user__output__line" key={correction?.sentence_index}>
              {renderIcon(correction.status)}

              <div className="user__output__line__text">
                <ListadoTags
                  tags={correction.tags}
                  special_note={correction.special_note}
                />
                <p className="user__output__line__original_sentence">
                  {correction.original_sentence}
                  {correction.status === UserOutputCorrectionType.CORRECTO && (
                    <PronounceSentence
                      oracion={correction.original_sentence}
                      width={'16'}
                    />
                  )}
                </p>
                {renderTeacherResponse(correction, correction.status, index)}
              </div>

              <div style={{width: '10%'}}>
                <div id="c-crear-flashcard-output">
                  <div className="mr-2">
                    {/* Componente del botón  */}
                    {indicesYaAgregados.includes(index) ? (
                      <Tooltip tooltip="Ya agregado">
                        <YaAgregado externalRadius={31.46} internalRadius={25.01} />
                      </Tooltip>
                    ) : (
                      correction.status !==
                        UserOutputCorrectionType.ININTELIGIBLE && (
                        <BotonRedondoConDegradado
                          externalRadius={31.46}
                          internalRadius={25.01}
                          active={false}
                          action={() => {
                            if (
                              correction.status === UserOutputCorrectionType.BIEN
                            ) {
                              addFlashcard(
                                correction.corrected_sentence,
                                correction.corrected_translation,
                                correction.sentence_index,
                                correction.phrase_id,
                                correction.phrase_translation_id,
                              )
                            } else if (
                              correction.status === UserOutputCorrectionType.MAL
                            ) {
                              addFlashcard(
                                correction.corrected_sentence,
                                correction.corrected_translation,
                                correction.sentence_index,
                                correction.phrase_id,
                                correction.phrase_translation_id,
                              )
                            } else {
                              showMessage(
                                `Esta frase aun no ha sido revisada.`,
                                TOAST_TYPE.WARNING,
                              )
                            }
                          }}>
                          <img src={iconPlus} alt={iconPlus} />
                        </BotonRedondoConDegradado>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        <div>
          {studentAudio && (
            <div className="w-50">
              <Waveform url={studentAudio} color="#FE7260" height={56} />
            </div>
          )}

          {/*Audio del profesor*/}
          {teacherAudio && (
            <div className="w-50">
              <Waveform url={teacherAudio} height={56} />
            </div>
          )}
        </div>
        {/*Audio del estudiante*/}
      </div>

      <Modal
        modalType="output"
        show={showModal}
        closeModal={() => setShowModal(false)}
        closeClickOutside
        closePressEsc
        closeRound>
        <TranslationFlashcardForm
          phraseTranslation={phraseTranslation}
          phrase={phrase}
          updateIndicesYaAgregados={() => updateIndicesYaAgregados(lineIndexInText)}
          // item customizado
          paramsItemOptionsRequest={{
            line_index_in_text: lineIndexInText,
            itemizable_id: userOutputId,
            itemizable_type: 'UserOutput',
            phrase_id: phraseId,
            phrase_translation_id: translatedPhraseId,
          }}
        />
      </Modal>
    </>
  )
}

UserOutput.defaultProps = {
  style: {},
}

UserOutput.propTypes = {
  userOutputId: PropTypes.number.isRequired,
  indicesYaAgregados: PropTypes.array,
  userOutputCorrected: PropTypes.object.isRequired,
  style: PropTypes.object,
}

export default UserOutput
