import React from 'react'
import {playbackSeek} from '../../../Redux/spotify/actions/playback'
import GoToBack from '../icons/goToBack.svg'
import useLanguages from '../../../hooks/useLanguages'
import useSpotify from '../../../hooks/useSpotify'
import {useDispatch} from 'react-redux'

const SeekBack = ({imagenIcon}) => {
  const {languageActive} = useLanguages()
  const {player} = useSpotify()
  const dispatch = useDispatch()

  const handleClick = () => {
    const then = new Date(player.timestamp)
    const now = new Date()
    const been_playing = now - then

    let seek_position
    if (!player.paused) {
      if (been_playing > 0) {
        seek_position = been_playing + player.position - 10000
      } else {
        seek_position = 0
      }
    } else {
      seek_position = player.position - 10000
    }
    if (seek_position < 0) {
      seek_position = 0
    }

    dispatch(playbackSeek(seek_position))
  }

  const lang = languageActive?.unicode ? languageActive.unicode : 'es'

  const title =
    lang === 'es'
      ? 'Retroceder 10 segundos'
      : lang === 'fr'
      ? 'Reculer de 10 secondes'
      : lang === 'pt'
      ? 'Volte 10 segundos'
      : 'Go back 10 seconds'

  return (
    <button
      title={title}
      className={`${!imagenIcon ? 'player__item__button' : 'player__item__karaoke'}`}
      onClick={handleClick}>
      <img src={`${imagenIcon ? imagenIcon : GoToBack}`} alt="back" />
    </button>
  )
}

export default SeekBack
