import React, {useLayoutEffect, useState} from 'react'
import useLikeTracks from '../../../hooks/useLikeTrack'
import usePlaylist from '../../../hooks/usePlaylist'
import useMyInputs from '../../../hooks/useMyInputs'
import useDashboard from '../../../hooks/useDashboard'
import useMyLikes from '../../../hooks/useMyLikes'
import useArtist from '../../../hooks/useArtist'
import useSearchInputs from '../../../hooks/useSearchInputs'

import HeartIcon from '../../PlayList/Tracks/HeartIcon'
import {getTrackBySpotifyId} from '../../../lib/coco/InputApi'
import {fullAlert, ALERT_TYPES} from '../../../Services/Alert'
import {useModalSpotifyAuth} from '../../../hooks/useSpotifyModalAuth'

const LikeButton = ({spotify_id, titulo, image_url, artistas, isPlayerActive}) => {
  const {
    playlistOpen: {
      playlist: {tracks: tracksPlaylist = []},
    },
  } = usePlaylist()
  const {tracks: myInputTrack} = useMyInputs()
  const {
    data: {LearningTracks, recommendedTracks, sharedTracks},
  } = useDashboard()
  const {
    data: {tracks: likeTracks},
  } = useMyLikes()
  const {
    data: {tracks: searchData},
  } = useSearchInputs()
  const {
    data: {topTracks: topTracksArtist},
    albumsTracks = [],
  } = useArtist()

  const [userInputId, setUserInputId] = useState(null)
  const [isRequestCalled, setIsRequestCalled] = useState(false)
  const {sendTrackToMyLikes, deleteTrackFromMyLikes} = useLikeTracks()

  // validar si tiene spotify activo
  const {toggleSpotifyModalAuth, isSpotifyConnected} = useModalSpotifyAuth()
  const spotifyStatus = isSpotifyConnected()

  const [animation, setAnimation] = useState('')

  const animationHeart = (animationType) => {
    setAnimation(`animate__animated ${animationType}`)
    setTimeout(() => {
      setAnimation('')
    }, 1000)
  }

  useLayoutEffect(() => {
    searchUserInputFromRedux()
    // eslint-disable-next-line
  }, [
    spotify_id,
    LearningTracks,
    recommendedTracks,
    sharedTracks,
    searchData,
    likeTracks,
    topTracksArtist,
    myInputTrack,
    albumsTracks,
    tracksPlaylist,
  ])

  // debe ser asincrona para que venda la data limpia.
  const AgregarAmisLikes = () => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
      return
    }

    if (isPlayerActive) {
      if (spotify_id && titulo && image_url && artistas?.length > 0) {
        //conversion de array de artistas para que tenga el mismo formato para enviar a open song
        const nuevosArtistas = artistas.map(
          (a) =>
            (a = {
              spotify_id: a.uri.replace('spotify:artist:', ''),
              nombre: a.name,
              image_url: '',
            }),
        )

        setUserInputId(Math.random() * -1)
        animationHeart('animate__heartBeat')

        sendTrackToMyLikes(spotify_id, titulo, image_url, nuevosArtistas).then(
          (response) => {
            if (response?.userInput) {
              const {id: userInputId} = response?.userInput
              setUserInputId(userInputId)
            }
          },
        )
      }
    } else {
      fullAlert(ALERT_TYPES.WARNING, `EL player no esta activo`)
    }
  }

  // debe ser asincrona
  const EliminarDeMisLikes = () => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
      return
    }

    if (isPlayerActive) {
      if (spotify_id && userInputId && titulo) {
        animationHeart('animate__jello')
        setUserInputId(null)
        deleteTrackFromMyLikes(spotify_id, userInputId, titulo).then((response) => {
          if (response) {
            setUserInputId(null)
          }
        })
      }
    } else {
      fullAlert(ALERT_TYPES.WARNING, `EL player no esta activo`)
    }
  }

  // este metodo hace un busqeda en todo redux para encontrar el user_input_id, en muy largo talves se pueda refactorizar.
  // un voluntario??
  const searchUserInputFromRedux = () => {
    let resultado = null

    const allTrackReduxMergued = [
      ...topTracksArtist,
      ...likeTracks,
      ...searchData,
      ...LearningTracks,
      ...recommendedTracks,
      ...sharedTracks,
      ...tracksPlaylist,
      ...myInputTrack,
    ]

    if (!resultado) {
      resultado = findUserInputfromTracksList(allTrackReduxMergued, spotify_id)
    }

    if (!resultado) {
      albumsTracks.map((album) => {
        const {tracks: tracksFromAlbum = []} = album
        resultado = findUserInputfromTracksList(tracksFromAlbum, spotify_id)
        return album
      })
    }

    // si encontro el track se actualizara solo si es distinto el user_input_id al actual
    // ya que si no se valida esto causa un Bucle infinitoooooooooooooooooooooo + 100000
    if (resultado) {
      const {user_input_id: userInputNew} = resultado
      // actualiza solo si es distinto
      if (userInputNew !== userInputId) {
        setUserInputId(userInputNew)
      }
    } else if (spotify_id?.trim()?.length > 0 && !isRequestCalled) {
      // si el backend ya se llamo 1 no se ejecutará
      // buscar en backend
      setIsRequestCalled(true)
      getTrackBySpotifyId(spotify_id).then((response) => {
        if (!response.message) {
          const {user_input_id} = response
          setUserInputId(user_input_id)
        }
      })
    }
  }

  const findUserInputfromTracksList = (tracks = [], spotify_id) => {
    if (tracks?.length > 0) {
      const trandFind = tracks.find((track) => track.spotify_id === spotify_id)
      return trandFind || null
    } else {
      return null
    }
  }

  return (
    <div className="go-up like-music-reproductor my-1">
      <HeartIcon
        titulo={titulo}
        tiene_like={userInputId}
        EliminarDeMisLikes={EliminarDeMisLikes}
        AgregarAmisLikes={AgregarAmisLikes}
        className={`${animation}`}
      />
    </div>
  )
}

export default LikeButton
