import {useLayoutEffect, useState} from 'react'

const useWindowSize = () => {
  const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0
  const innerHeight = typeof window !== 'undefined' ? window.innerHeight : 0

  const [width, setWidth] = useState(innerWidth)
  const [height, setHeigth] = useState(innerHeight)

  const handleResize = () => {
    setWidth(window.innerWidth)
    setHeigth(window.innerHeight)
  }

  useLayoutEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return {
    width,
    height,
  }
}

export default useWindowSize

/*
  1. Este hook lo he creado con la idea de ser utilizado al momento de comenzar coco de forma "responsive" 
     en tablet, mobile, y distintos tamaños de PC.
  2. la idea seria estar escuchando el ancho de la pantalla y conforme al ancho poder ocultar o mostrar distintos
     componentes
  3. ejemplo:
  
     const {height, width} = useWindowSize()

     return (<div className="x">
              { width > 700 ?
                <Componente1 />
                :
                <Componente2 />
              }
            </div>
     )

  att: Cristhian Menjivar
*/
