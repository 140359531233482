import React from 'react'
import Tooltip from '../../Tooltip/Tooltip'
import AvatarParticipant from './AvatarParticipant'

const styleCount = {
  color: 'white',
  fontSize: '20px',
  fontWeight: 'bold',
  paddingTop: '7px',
  marginLeft: '5px',
}

const Participants = ({participants = []}) => {
  const getFirstTree = participants?.length > 0 ? participants.slice(0, 3) : []
  const countOthers =
    participants?.length > 0 ? participants?.length - getFirstTree?.length : 0

  return (
    <>
      {getFirstTree?.length > 0 &&
        getFirstTree.map((pa) => (
          <Tooltip tooltip={`${pa.name || pa.email}`} key={pa?.id}>
            <AvatarParticipant
              photoUrl={pa.photo_url}
              title={`${pa.name || pa.email}`}
            />
          </Tooltip>
        ))}

      {countOthers > 0 && <div style={styleCount}>{`+ ${countOthers}`}</div>}
    </>
  )
}

export default Participants
