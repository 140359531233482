import {useDispatch} from 'react-redux'
import {toggleSpotifyAuthModal} from '../Redux/users/actions'
import useSpotify from './useSpotify'
import useUser from './useUser'

const useModalSpotifyAuth = () => {
  const userState = useUser()
  const {player} = useSpotify()

  const dispatch = useDispatch()
  const {auth_modal} = userState
  const toggleSpotifyModalAuth = () => {
    dispatch(toggleSpotifyAuthModal(auth_modal))
  }

  const isSpotifyConnected = () => {
    if (player.is_active) {
      return true
    }
    return false
  }

  const getStatus = () => {
    if (auth_modal) {
      return true
    }
    return false
  }

  return {
    ...userState,
    getStatus,
    toggleSpotifyModalAuth,
    isSpotifyConnected,
  }
}

export {useModalSpotifyAuth}
