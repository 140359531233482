import {connectRouter} from 'connected-react-router'
import {combineReducers} from 'redux'

import usersReducer from './users/reducer'
import spotifyReducer from './spotify/reducers/index'
import MediatextReducer from './MediaText/MediatextReducer'
import FlashcardReducer from './Flashcard/FlashcardReducer'
import TranslatorReducer from './Translator/TranslatorReducer'
import PerfilReducer from './perfil/PerfilReducer'
import OutputHistoryReducer from './OutputHistory/OutputHistoryReducer'
import DashboardTeacherReducer from './DashboardTeacher/DashboardTeacherReducer'
import ItemsReducer from './Items/ItemsReducer'
import PresenceReducer from './Presence/PresenceReducer'
import ConversationsReducer from './Conversations/ConversationsReducer'
import MyInputsReducer from './MyInputsStudied/MyInputsReducer'
import dashboardReducer from './dashboard/dashboardReducer'
import MyLikesReducer from './MyLikes/MyLikesReducer'
import searchReducer from './searchInputs/searchReducer'
import artistsReducer from './artists/artistsReducer'
import playlistsReducer from './PlayLists/PlayListReducer'
import notificationsReducer from './notifications/notificationsReducer'
import languagesReducer from './languages/languagesReducer'
import progressReducer from './progress/progressReducer'
import diccionaryReducer from './Diccionary/diccionaryReducer'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    spotify: spotifyReducer,
    user: usersReducer,
    progress: progressReducer,
    mediatext: MediatextReducer,
    playlist: playlistsReducer,
    flashcard: FlashcardReducer,
    translator: TranslatorReducer,
    perfil: PerfilReducer,
    output_history: OutputHistoryReducer,
    dashboard_teacher: DashboardTeacherReducer,
    items: ItemsReducer,
    presence: PresenceReducer,
    conversations: ConversationsReducer,
    myInputs: MyInputsReducer,
    dashboard: dashboardReducer,
    myLikes: MyLikesReducer,
    serchInputs: searchReducer,
    artist: artistsReducer,
    notifications: notificationsReducer,
    languages: languagesReducer,
    diccionary: diccionaryReducer,
  })
