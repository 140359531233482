import React, {useState, useRef} from 'react'
import './MiniChat.css'
import './ConversationMain.css'

import {useHistory} from 'react-router-dom'
import useConversations from '../../../hooks/useConversations'
import MessagesArea from './MessagesArea'
import useUser from '../../../hooks/useUser'
import NewMessageForm from './NewMessageForm'
import HeaderOptions from './HeaderOptions'
import HeaderMiniChat from './HeaderMiniChat'
import {
  getOtherParticipantPersonal,
  isConversationGroup,
  isConversationHilo,
  isConversationPersonal,
  TypeDocuchats,
  getNamesFromParticipants,
  isTeacherInDomainId,
} from '../helpers'
import ConversationLeft from './ConversationLeft'
import {ButtonNewParticipant} from './ButtonNewParticipant'
import Modal from '../../Modal'
import AddParticipants from './AddParticipants'
import FormDefine from './FormDefine'
import OptionsConversation from './OptionsMiniChat'
import usePlatform from '../../../hooks/usePlatform'

const MiniChat = ({isShowMaximizar = true}) => {
  const {isDeviseMobile} = usePlatform()
  const currentUser = useUser()
  const {
    conversationActive,
    setActiveConversation,
    setMinichatClose,
    messageCitado,
    removeCitedMessage,
    openMiniChatSelectionActive,
    closeMiniChatSelectionActive,
    isMiniChatSelectionActive,
  } = useConversations()

  const newMessageRef = useRef()
  const [textMessage, setTextMessage] = useState('')

  const [showDefineThread, setShowDefineThread] = useState(false)

  const [addParticpantsConversation, setAddParticpantsConversation] = useState(false)

  const history = useHistory()

  const goToConversations = () => {
    history.push('/conversations')
    setMinichatClose()
  }

  const setActiveConversationId = (idConversation) => {
    setActiveConversation(idConversation)
    closeMiniChatSelectionActive()
    autoFocusMessageInput()
    cleanMessageInput()
  }

  const closeMiniChat = () => {
    setMinichatClose()
    openMiniChatSelectionActive() // verver al modo de lista de conversaciones
  }

  const isTeacherInDomain = () => {
    const {domain_options = []} = currentUser
    return isTeacherInDomainId(domain_options, conversationActive?.domain_id)
  }

  const userFromConversation = getOtherParticipantPersonal(
    conversationActive,
    currentUser?.id,
  )

  const autoFocusMessageInput = () => {
    if (newMessageRef?.current) {
      const ref = newMessageRef.current
      ref.focus()
    }
  }

  const cleanMessageInput = () => {
    setTextMessage('')
  }

  return (
    <>
      <div className="mini-chat-principal-fixed">
        {!isDeviseMobile && (
          <HeaderOptions
            onClose={closeMiniChat}
            onClickMaximizar={goToConversations}
            type={TypeDocuchats.MINI_CHAT}
            isShowMaximizar={isShowMaximizar}
          />
        )}

        {isConversationHilo(conversationActive) && (
          <OptionsConversation
            conversation={conversationActive}
            isTeacherFromDomain={isTeacherInDomain()}
            currentUser={currentUser}
            setShowDefineThread={setShowDefineThread}
          />
        )}

        {isMiniChatSelectionActive ? (
          <div className={`mini-chat-content mini-chat-content-firt-screen`}>
            <ConversationLeft setActiveConversationFn={setActiveConversationId} />
          </div>
        ) : (
          <div className="mini-chat-content">
            {isConversationPersonal(conversationActive) && (
              <HeaderMiniChat
                onClick={openMiniChatSelectionActive}
                title={userFromConversation?.name || userFromConversation?.email}
                style={{padding: '20px', paddingBottom: '15px'}}
                rigthComponent={
                  <ButtonNewParticipant
                    onClick={setAddParticpantsConversation}
                    style={{marginLeft: '20px'}}
                  />
                }
              />
            )}

            {isConversationGroup(conversationActive) && (
              <HeaderMiniChat
                onClick={openMiniChatSelectionActive}
                title={
                  conversationActive?.title
                    ? conversationActive?.title
                    : getNamesFromParticipants(conversationActive)
                }
                style={{padding: '20px'}}
                rigthComponent={
                  <ButtonNewParticipant onClick={setAddParticpantsConversation} />
                }
              />
            )}

            {isConversationHilo(conversationActive) && (
              <HeaderMiniChat
                onClick={openMiniChatSelectionActive}
                title={conversationActive?.title ? conversationActive?.title : ''}
                style={{padding: '20px'}}
              />
            )}

            {conversationActive?.id && (
              <MessagesArea
                type={TypeDocuchats.MINI_CHAT}
                conversation={conversationActive}
                participants={conversationActive.participating_users}
                online_participants={conversationActive.participating_users.filter(
                  (u) => u.is_online,
                )}
                autoFocusMessageInput={autoFocusMessageInput}
              />
            )}

            {conversationActive && (
              <NewMessageForm
                newMessageRef={newMessageRef}
                textMessage={textMessage}
                setTextMessage={setTextMessage}
                isMiniChat={true}
                conversation_id={conversationActive.id}
                conversation={conversationActive}
                typing_participants={conversationActive.participating_users.filter(
                  (u) => u.is_typing,
                )}
                currentUser={currentUser}
                messageCitado={messageCitado}
                removeCitedMessage={removeCitedMessage}
                autoFocusMessageInput={autoFocusMessageInput}
              />
            )}
          </div>
        )}
      </div>

      {showDefineThread && conversationActive && (
        <Modal
          modalType="modal-define-thread"
          show={showDefineThread}
          closeModal={() => {
            setShowDefineThread(false)
          }}>
          <FormDefine
            conversation={conversationActive}
            onClose={() => setShowDefineThread(false)}
            currentUser={currentUser}
          />
        </Modal>
      )}

      {addParticpantsConversation && conversationActive && (
        <Modal
          modalType="new-thread"
          show={addParticpantsConversation}
          closeModal={() => setAddParticpantsConversation(false)}
          closeRound>
          <AddParticipants
            conversation={conversationActive}
            currentUser={currentUser}
            closeModa={() => setAddParticpantsConversation(false)}
          />
        </Modal>
      )}
    </>
  )
}

export default MiniChat
