import {
  GET_NEW_FLASHCARD,
  UPDATE_CORRECT_FLASHARDS,
  UPDATE_TROPHIES_WONDS,
  CHANGE_ANSWER,
  CHANGE_FLASHCARD_ACTIVE,
  YEAH_FINISH_FLASHCARD,
  FLASHCARD_VIEWS,
  GET_NEW_FLASHCARD_START,
} from './actionTypes'

const initialStore = {
  finish_flashcards: false,
  flashcard_activa: FLASHCARD_VIEWS.STUDY_FLASHCARDS,
  flashcard: {
    prompt: '',
    answer: '',
    hint: '',
    title: '',
    summary: '',
    notes: '',
    study_buttons: {},
    tags: '',
  },
  cartas_resueltas: 0,
  trofeos_ganados: [],
  respuesta: '',
  loading: false,
}

export default function (state = initialStore, action) {
  switch (action.type) {
    case GET_NEW_FLASHCARD_START: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_NEW_FLASHCARD: {
      return {
        ...state,
        flashcard: action.flashcard,
        respuesta: '',
        finish_flashcards: false,
        loading: false,
      }
    }

    case UPDATE_CORRECT_FLASHARDS: {
      return {
        ...state,
        cartas_resueltas: action.cartas_resueltas,
      }
    }

    case UPDATE_TROPHIES_WONDS: {
      return {
        ...state,
        trofeos_ganados: action.trofeos_ganados,
      }
    }

    case CHANGE_ANSWER: {
      return {
        ...state,
        respuesta: action.respuesta,
      }
    }

    case CHANGE_FLASHCARD_ACTIVE: {
      return {
        ...state,
        flashcard_activa: action.flashcard_activa,
      }
    }

    case YEAH_FINISH_FLASHCARD: {
      return {
        ...state,
        finish_flashcards: true,
      }
    }

    default:
      return state
  }
}
