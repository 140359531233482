import React, {useState, useEffect} from 'react'
import ImageCocoSpotify from './Images/ImageCocoSpotify.svg'
import styles from './SpotifySettings.module.css'
import {getToken} from '../../Services/getToken'
import {smallAlert} from '../../Services/Alert'
import {UNLINK_SPOTIFY_ACCOUNT} from '../../Services/EndPointApi'
import Axios from 'axios'
import {useModalSpotifyAuth} from '../../hooks/useSpotifyModalAuth'
import {getSpotifyAuthUrl} from '../../Redux/spotify/requests/player'

const SpotifySettings = (props) => {
  const {intl} = props
  // TODO: INICIALIZAR EL BOTÓN DE SPOTIFY

  const stateModalSpotifyAuth = useModalSpotifyAuth()
  const {isSpotifyConnected} = stateModalSpotifyAuth
  const spotify = isSpotifyConnected()

  console.log('STATUS', spotify)

  // States
  const [spotifyButtonStatus, setSpotifyButtonStatus] = useState(spotify)

  const [linkUrl, setLinkUrl] = useState('')
  const linkToSpotify = () => {
    window.location = linkUrl
  }

  useEffect(() => {
    getSpotifyAuthUrl().then((response) => {
      setLinkUrl(response.data.redirect_url)
    })
  }, [])

  const unlinkSpotifyAccount = () => {
    Axios.post(
      UNLINK_SPOTIFY_ACCOUNT,
      {},
      {
        headers: {
          Authorization: getToken(),
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => {
        if (response.data) {
          smallAlert(
            'success',
            `${intl.formatMessage({
              id: 'engrane.successful-linking-spotify-account',
              defaultMessage:
                'Your Coco account has been successfully unlinked to your Spotify account.',
            })}`,
            'bottom-end',
          )
          // Guardar nueva configuración del usuario en Redux
          setSpotifyButtonStatus(false)
          // Refrescar el navegador
        }
      })
      .catch((error) => {
        smallAlert(
          'error',
          `${intl.formatMessage({
            id: 'engrane.error-unlinking-spotify-account',
            defaultMessage:
              'Could not unlink your account with spotify, please try again or contact us.',
          })}` + error,
          'bottom-end',
        )
      })
  }

  return (
    <div className={styles['spotify-settings']}>
      <img src={ImageCocoSpotify} alt={ImageCocoSpotify} className="mb-5" />
      <p
        className={`${styles.text} mb-5`}
        style={{width: spotifyButtonStatus ? '300px' : '200px'}}>
        {spotifyButtonStatus
          ? intl.formatMessage({
              id: 'engrane.link-coco-and-spotify-accounts',
              defaultMessage: 'Your Spotify account is linked to Coco.',
            })
          : intl.formatMessage({
              id: 'engrane.unlink-coco-and-spotify-accounts',
              defaultMessage:
                'You need to link your Spotify account with Coco to enjoy the full experience.',
            })}
      </p>
      <div className="settings__button__container">
        <button
          className="settings__button__main"
          // disabled={!isFormHaveChange}
          // style={!isFormHaveChange ? {cursor: 'no-drop', opacity: 0.5} : {}}
          disabled={false}
          style={false ? {cursor: 'no-drop', opacity: 0.5} : {}}
          // type="submit"
          onClick={spotifyButtonStatus ? unlinkSpotifyAccount : linkToSpotify}>
          {spotifyButtonStatus
            ? `${intl.formatMessage({
                id: 'engrane.unlink-spotify-account',
                defaultMessage: 'Unlink',
              })}`
            : `${
                // TODO: Poner un loading.
                intl.formatMessage({
                  id: 'engrane.link-spotify-account',
                  defaultMessage: 'Link',
                })
              }`}
        </button>
      </div>
    </div>
  )
}

SpotifySettings.propTypes = {}

export default SpotifySettings
