import Axios from 'axios'
import {
  DICCIONARIO_LIBRE,
  GET_API_DICTIONARY,
  GET_PHRASES,
  GET_TRADUCTOR,
} from '../../Services/EndPointApi'
import {ApiMessages, errorHandler, getHeaders} from './helperData'

const getPhrasesDiccionaryCoco = (params) => {
  return Axios.get(`${GET_PHRASES}?word=${params}`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data) {
        return {
          data: response.data,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('getPhrasesDiccionaryCoco'))
}

const getDiccionaryExact = (workMark) => {
  return Axios.get(
    `${GET_API_DICTIONARY}?palabra=${workMark.form}&lang_code=${workMark.lang_code}&lemma=${workMark.lemma}&grammatical_category=${workMark.part_of_speech}`,
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.results) {
        return {
          diccionary: response.data.results,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('getDiccionaryExact'))
}

const getDiccionaryLibre = (phrase) => {
  return Axios.get(`${DICCIONARIO_LIBRE}?body=${phrase}&&location=output`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data) {
        const {frases, translation, to_lang_code} = response.data

        return {
          frases,
          translation,
          langCodeTranslation: to_lang_code,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('getDiccionaryLibre'))
}

const getDiccionaryDefinitions = (param, langCode = 'en_US') => {
  const langCodeBuild = langCode === 'en' ? 'en_US' : langCode

  return Axios.get(
    `https://api.dictionaryapi.dev/api/v2/entries/${langCodeBuild}/${param}`,
  )
    .then((response) => {
      if (response?.data) {
        return {
          data: response.data,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('getDiccionaryDefinitions'))
}

const traductorBody = (body, fromLanguage, toLanguage, location = 'custom') => {
  return Axios.get(
    `${GET_TRADUCTOR}?body=${body}&&from_language=${fromLanguage}&&to_language=${toLanguage}&&location=${location}`,
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.translation) {
        const {translation} = response.data
        return {
          translation,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('traductorBody'))
}

export {
  getPhrasesDiccionaryCoco,
  getDiccionaryExact,
  getDiccionaryLibre,
  traductorBody,
  getDiccionaryDefinitions,
}
