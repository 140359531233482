import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {EditorState, getDefaultKeyBinding, RichUtils} from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import createMentionPlugin, {defaultSuggestionsFilter} from 'draft-js-mention-plugin'
import {stateToHTML} from 'draft-js-export-html'
import 'draft-js-mention-plugin/lib/plugin.css'
import 'draft-js/dist/Draft.css'
import _ from 'lodash'

import EntryComponent from './EntryComponent'
import MentionComponent from './MentionConponent'
import {getToken} from '../../../Services/getToken'
import axios from 'axios'
import {USER_BY_AT} from '../../../Services/EndPointApi'
import themeMentionSuggestions from './themeMentionSuggestions.module.css'
import 'react-calendar/dist/Calendar.css'

const cmdState = {
  handled: 'handled',
  notHandled: 'not-handled',
}

const positionSuggestions = ({state, props}) => {
  let transform
  let transition

  if (state.isActive && props.suggestions.length > 0) {
    transform = 'scaleY(1)'
    transition = 'all 0.25s cubic-bezier(.3,1.2,.2,1)'
  } else if (state.isActive) {
    transform = 'scaleY(0)'
    transition = 'all 0.25s cubic-bezier(.3,1,.2,1)'
  }

  return {
    transform,
    transition,
  }
}

class PostEditor extends Component {
  constructor() {
    super()
    this.mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionComponent: MentionComponent, // since we want to remove the entire name at once.
      theme: themeMentionSuggestions,
      positionSuggestions,
      supportWhitespace: true,
    })
    this.state = {
      editorState: EditorState.createEmpty(),
      suggestions: [],
      userMentions: [],
      /*Para guardar la fecha*/
      date: new Date(),
      calendar: false,
    }
  }

  reset = () => {
    this.setState({
      editorState: EditorState.createEmpty(),
    })
  }

  onAddMention = (mention) => {
    this.setState({
      userMentions: [...this.state.userMentions, mention],
    })
  }

  onChange = (editorState) => {
    this.setState({
      editorState,
    })
  }

  onSearchChange = ({value}) => {
    const TOKEN = getToken()

    axios
      .get(`${USER_BY_AT}/${value}`, {
        headers: {
          Authorization: TOKEN,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.data) {
          const {users = []} = response.data
          const usersSuggestions = users.map((user) => ({
            id: user.user_id,
            name:
              user.nickname?.trim()?.length > 0 ? user.nickname : 'usuario de coco',
            avatar: user.photo?.url ? user.photo.url : null,
          }))

          this.setState({
            suggestions: defaultSuggestionsFilter(value, usersSuggestions),
          })
        }
      })
  }

  keyBindingFn = (e) => {
    // retrun custom commands on keyPress if required
    return getDefaultKeyBinding(e)
  }

  toHtml = () => {
    const contentState = this.state.editorState.getCurrentContent()
    const options = {
      // eslint-disable-next-line consistent-return
      entityStyleFn: (entity) => {
        const entityType = entity.get('type')?.toLowerCase()
        if (entityType === 'mention') {
          const data = entity.getData()
          return {
            element: 'span',
            attributes: {
              'data-mention-id': _.get(data, 'mention.id'),
              class: 'mention_class',
            },
            style: {
              // Put styles here...
            },
          }
        }
      },
    }
    return stateToHTML(contentState, options)
  }

  handleKeyCommand = (command) => {
    // handle custom command here;

    const newState = RichUtils.handleKeyCommand(this.state.editorState, command)
    if (newState) {
      this.onChange(newState)
      return cmdState.handled
    }
    return cmdState.notHandled
  }

  render() {
    const {MentionSuggestions} = this.mentionPlugin
    const plugins = [this.mentionPlugin]
    const {placeholder} = this.props

    return (
      <>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={plugins}
          // keyBindingFn={this.keyBindingFn}
          handleKeyCommand={this.handleKeyCommand}
          placeholder={placeholder}
          ref={(element) => {
            this.editor = element
          }}
        />

        <MentionSuggestions
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
          entryComponent={EntryComponent}
          onAddMention={this.onAddMention}
        />
      </>
    )
  }
}

PostEditor.propTypes = {
  /**
   * className {string} - className applied to top most Wrapper
   */
  className: PropTypes.string,
  /**
   * style {object} - inline style to be applied to top most Wrapper
   */
  style: PropTypes.object,
  /**
   * placeholder {string} - placeholder to display when editor has no text
   */
  placeholder: PropTypes.string,
}

export default PostEditor
