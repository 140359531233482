import useUser from './useUser'

const useDomain = () => {
  const {domain_options = [], activeDomainId} = useUser()

  const getDomainActive = () => {
    if (domain_options?.length > 0 && activeDomainId) {
      const DomainActive = domain_options.find((lang) => lang.id === activeDomainId)

      if (DomainActive) {
        return DomainActive
      } else {
        return domain_options[0]
      }
    }

    return null
  }

  const isDomainActiveById = (id) => {
    if (id === activeDomainId) {
      return true
    }
  }

  const domainActive = getDomainActive()

  return {
    domainOptions: domain_options,
    getDomainActive,
    domainActive,
    isDomainActiveById,
  }
}

export default useDomain
