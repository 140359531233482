import React from 'react'
import '../FlashCards/FlashCards.css'
import {FormattedMessage} from 'react-intl'
import BotonConBordeDegradado from '../Buttons/BotonConBordeDegradado'

const AddFraseSuccess = ({titulo, tituloBoton, handleClose}) => {
  return (
    <div className="add-frases-success-container">
      <img
        className="add-frases-success-animation"
        src={
          'https://firebasestorage.googleapis.com/v0/b/assets-a3461.appspot.com/o/main%2FGIF_TRUCK.gif?alt=media&token=a66ef610-982e-4042-a4d8-91b0deb1e4ba'
        }
        alt="ilustración chidísima de un automóvil en las nubes, con estrellas al fondo"
      />
      <br />
      <br />
      <div className="mensaje-de-exito-agregar-fc orange-text-gradient text-center">
        <FormattedMessage
          id={'plusbutton.message-sucess'}
          defaultMessage={'¡Tu flashcard fue enviada con éxito.!'}
        />
      </div>

      {titulo && (
        <div
          style={{
            color: '#F2F2F2',
            textAlign: 'center',
            fontSize: '15px',
          }}>
          {titulo}
        </div>
      )}

      <div className="flashcard-salir-btn-container">
        <BotonConBordeDegradado function={handleClose} width={250} height={37}>
          <span>{`${tituloBoton ? tituloBoton : 'Salir'}`}</span>
        </BotonConBordeDegradado>
      </div>
    </div>
  )
}

export default AddFraseSuccess
