const SettingsViewerStatus = {
  INFORMATION_PERSONAL: 'INFORMATION_PERSONAL',
  NOTIFICATIONS: 'NOTIFICATIONS',
  PAYMENTS_AND_SUBSCRIPTIONS: 'PAYMENTS_AND_SUBSCRIPTIONS',
  SPOTYFY: 'SPOTIFY'
}

const isSettingInformation = (status) =>
  status === SettingsViewerStatus.INFORMATION_PERSONAL
const isSettingNotifications = (status) =>
  status === SettingsViewerStatus.NOTIFICATIONS
const isSettingPaymentsSuscriptions = (status) =>
  status === SettingsViewerStatus.PAYMENTS_AND_SUBSCRIPTIONS
const isSettingSpotify = (status) =>
  status === SettingsViewerStatus.SPOTYFY
  

export {
  SettingsViewerStatus,
  isSettingInformation,
  isSettingNotifications,
  isSettingPaymentsSuscriptions,
  isSettingSpotify
}
