import {
  CHANGE_SCREEN,
  GET_PERFIL_INFO,
  FILTER_FLASHCARD,
  COMMENT_OUTPUTS,
  EDIT_PERFIL,
  EDIT_AVATAR,
  CHANGE_PASS,
} from './actionTypes'
import axios from 'axios'
import {getToken} from '../../Services/getToken'
import {
  GET_PERFIL_DATA,
  ENVIAR_COMENTARIO,
  PERFIL_MANAGE,
  CHANGE_PASSWORD,
} from '../../Services/EndPointApi'
export const changeScreen = (index) => (dispatch) => {
  return dispatch({
    type: CHANGE_SCREEN,
    index: index,
  })
}

export const editPerfil = (profile, id) => (dispatch) => {
  const DEVISE_SECRET_KEY = getToken()
  axios
    .post(
      PERFIL_MANAGE,
      {
        profile: profile,
      },
      {
        headers: {
          Authorization: DEVISE_SECRET_KEY,
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      console.log('ok')
      if (response.data) {
        console.log(response.data)
        return dispatch({
          type: EDIT_PERFIL,
          my_perfil: response.data,
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const changePassword = (data) => (dispatch) => {
  console.log('ok')
  const DEVISE_SECRET_KEY = getToken()
  axios
    .patch(
      CHANGE_PASSWORD,
      {
        user: {
          data,
        },
      },
      {
        headers: {
          Authorization: DEVISE_SECRET_KEY,
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      console.log('ok')
      if (response.data) {
        return dispatch({
          type: CHANGE_PASS,
          my_perfil: response.data,
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const editAvatar = (foto, id) => (dispatch) => {
  console.log('avatar')
  const DEVISE_SECRET_KEY = getToken()
  axios
    .put(
      PERFIL_MANAGE + '/' + id,
      {
        profile: foto,
      },
      {
        headers: {
          Authorization: DEVISE_SECRET_KEY,
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      if (response.data) {
        console.log(response.data)
        return dispatch({
          type: EDIT_AVATAR,
          my_perfil: response.data,
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getPerfilData = () => (dispatch) => {
  const DEVISE_SECRET_KEY = getToken()
  axios
    .get(GET_PERFIL_DATA, {
      headers: {
        Authorization: DEVISE_SECRET_KEY,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (response.data) {
        return dispatch({
          type: GET_PERFIL_INFO,
          perfil_info: response.data,
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

export const filterFlashcard = (param) => (dispatch) => {
  return dispatch({
    type: FILTER_FLASHCARD,
    param: param,
  })
}

export const comentarOutput = (comment, user_output_id) => (dispatch) => {
  const DEVISE_SECRET_KEY = getToken()
  axios
    .post(
      ENVIAR_COMENTARIO,
      {
        user_output_comment: {
          comment: comment,
          user_output_id: user_output_id,
        },
      },
      {
        headers: {
          Authorization: DEVISE_SECRET_KEY,
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      if (response.data) {
        console.log(response.data)
        return dispatch({
          type: COMMENT_OUTPUTS,
          comentario: response.data,
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
