import {useEffect} from 'react'

const useKeyDownEvent = (keyPressEsc) => {
  useEffect(() => {
    window.addEventListener('keydown', keyPressEsc)
    return () => window.removeEventListener('keydown', keyPressEsc)
    // eslint-disable-next-line
  }, [])
}

export default useKeyDownEvent
