import Axios from 'axios'
import React, {useLayoutEffect, useState} from 'react'
import {useWorkingIndicator} from '../../hooks/useBooleanToggler'
import {UNSPLASH_ENDPOINT, UNSPLASH_ID} from '../../Services/EndPointApi'
import LoadingPage from '../Animaciones/LoadingPage'
import {useDebounce} from 'use-debounce'

const QUERIES = [
  'funny',
  'cats',
  'paris',
  'buildings',
  'music',
  'pop',
  'rap',
  'hiphop',
  'beatles',
]

const ListImagesUnsplash = ({query, onClickImage, imageSelect, style = {}}) => {
  const [images, setImages] = useState([])
  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const [value] = useDebounce(query, 500)

  useLayoutEffect(() => {
    getImages()
    // eslint-disable-next-line
  }, [value])

  const getImages = () => {
    const myQuery =
      query?.trim()?.length > 0
        ? query
        : QUERIES[Math.floor(Math.random() * QUERIES.length)]
    startWork()
    Axios.get(`${UNSPLASH_ENDPOINT}?query=${myQuery}&client_id=${UNSPLASH_ID}`).then(
      (res) => {
        finishWork()
        if (res?.data?.results) {
          const {results = []} = res.data
          setImages(results)
        }
      },
    )
  }

  return (
    <>
      {isWorking ? (
        <LoadingPage style={{height: 'auto'}} />
      ) : (
        <>
          {images?.length > 0 &&
            images.map((image) => (
              <div
                key={image?.id}
                className="block-image"
                style={{
                  backgroundImage: `url(${image?.urls?.small})`,
                  width: '120.33px',
                  height: '120.33px',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  margin: '6.55px',
                  border:
                    imageSelect === image?.urls?.small
                      ? '1px #ff5562 solid'
                      : 'none',
                  cursor: 'pointer',
                  ...style,
                }}
                onClick={() => onClickImage(image?.urls?.small)}></div>
            ))}
        </>
      )}
    </>
  )
}

export default ListImagesUnsplash
