import React from 'react'
import './LoadingPage.css'

const LoadingPage = ({style}) => {
  return (
    <div className="loading__page__wrapp" style={style}>
      <div className="loading__page__container">
        <div className="loading__spinner__page">
          <div className="loading__page__bubble__1"></div>
          <div className="loading__page__bubble__2"></div>
        </div>
      </div>
    </div>
  )
}

export default LoadingPage
