import React from 'react'
import ReloadIcon from './Icons/Reload.svg'

const SpinnerCicle = ({styles = {width: '35px'}, className = ''}) => {
  return (
    <img style={styles} className={className} src={ReloadIcon} alt="ReloadIcon" />
  )
}

export default SpinnerCicle
