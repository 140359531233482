import React, {useState} from 'react'
import CustomFlashcard from '../CustomFlashcard'
import IconFlashcard from '../IconsPlusButton/flashcard.svg'
import IconFlashcardActive from '../IconsPlusButton/flashcardActive.svg'
import IconTraductor from '../IconsPlusButton/traductor.svg'
import IconTraductorActive from '../IconsPlusButton/traductorActive.svg'
import IconNote from '../IconsPlusButton/IconNote.svg'
import IconNoteActive from '../IconsPlusButton/IconNoteActive.svg'
import Plus from '../IconsPlusButton/plus.svg'

import './PlusButtonModal.css'
import Traductor from '../../Translator/Traductor'
import AddFraseSuccess from '../../Messages/AddFraseSuccess'
import {FormattedMessage} from 'react-intl'
import LinkLogoCoco from '../../Layouts/MenuItems/LinkLogoCoco'
import Nota from '../../Notas/Nota'
import {useDispatch} from 'react-redux'
import {toggleFlashcardModal} from '../../../Redux/users/actions'
import CreatePortalModal from '../../Modal/CreatePortalModal'
//constantes
export const PlusButtonScrean = {
  FLASHCARD: 'FLASHCARD',
  TRADUCTOR: 'TRADUCTOR',
  DICCIONARIO: 'DICCIONARIO',
  MESSAGE_SUCCESS_FLASHCARD: 'MESSAGE_SUCCESS_FLASHCARD',
  NOTA: 'NOTA',
}

const SuccessFlashcard = ({setCurrentScreen}) => {
  return (
    <div className="contendor-mensaje-flashcard">
      <div className="contendor-mensaje-flashcard-item">
        <FormattedMessage
          id="flashcard.add-new"
          defaultMessage="Agregar nueva Flashcard">
          {(title) => (
            <AddFraseSuccess
              titulo=""
              tituloBoton={title}
              handleClose={() => setCurrentScreen(PlusButtonScrean.FLASHCARD)}
            />
          )}
        </FormattedMessage>
      </div>
    </div>
  )
}

const ButtonItem = ({title, onClick, isActiveButton, iconDefault, iconActive}) => {
  return (
    <button onClick={onClick}>
      {iconDefault && iconActive && (
        <img src={isActiveButton ? iconActive : iconDefault} alt="icon" />
      )}

      <span className={`${isActiveButton && 'btn-plus-button-active'}`}>
        {title}
      </span>
    </button>
  )
}

const HeaderPlusButton = ({changeView, isActiveButton, closeFullModal}) => {
  return (
    <div className="topbar__nav__plusbutton">
      <LinkLogoCoco className="plus__logo__coco__custom" />

      <div className="btn__plus__button__container">
        <ButtonItem
          title="Flashcard"
          onClick={() => changeView(PlusButtonScrean.FLASHCARD)}
          isActiveButton={isActiveButton(PlusButtonScrean.FLASHCARD)}
          iconDefault={IconFlashcard}
          iconActive={IconFlashcardActive}
        />

        <ButtonItem
          title={
            <FormattedMessage
              id={'plusbutton.translator'}
              defaultMessage={'Traductor'}
            />
          }
          onClick={() => changeView(PlusButtonScrean.TRADUCTOR)}
          isActiveButton={isActiveButton(PlusButtonScrean.TRADUCTOR)}
          iconDefault={IconTraductor}
          iconActive={IconTraductorActive}
        />

        <ButtonItem
          title={<FormattedMessage id={'plusbutton.note'} defaultMessage={'Nota'} />}
          onClick={() => changeView(PlusButtonScrean.NOTA)}
          isActiveButton={isActiveButton(PlusButtonScrean.NOTA)}
          iconDefault={IconNote}
          iconActive={IconNoteActive}
        />
      </div>

      <img
        className="plus__button__close__button"
        onClick={closeFullModal}
        src={Plus}
        alt={Plus}
      />
    </div>
  )
}

const PlusButton = ({onClose, isAnimationShow}) => {
  const [currentScreen, setCurrentScreen] = useState(PlusButtonScrean.FLASHCARD)
  const [title, setTitle] = useState('')
  const [summary, setSummary] = useState('')
  const [imageCustom, setImageCustom] = useState(null)

  const changeView = (vista) => {
    setCurrentScreen(vista)
  }
  const dispatch = useDispatch()

  const closeFullModal = () => {
    dispatch(toggleFlashcardModal(false))
    onClose()
  }

  const isActiveButton = (vista) => {
    return vista === currentScreen
  }

  return (
    <CreatePortalModal elementId="modal-root">
      <div
        className={`plus__button__full__screen ${
          !isAnimationShow && 'close__plus__button__animation'
        }`}>
        <HeaderPlusButton
          changeView={changeView}
          isActiveButton={isActiveButton}
          closeFullModal={closeFullModal}
        />

        <div className="plus__button__main">
          {isActiveButton(PlusButtonScrean.FLASHCARD) && (
            <CustomFlashcard
              changeView={changeView}
              title={title}
              summary={summary}
              setTitle={setTitle}
              setSummary={setSummary}
              setImageCustom={setImageCustom}
              imageCustom={imageCustom}
            />
          )}

          {isActiveButton(PlusButtonScrean.TRADUCTOR) && <Traductor />}

          {isActiveButton(PlusButtonScrean.MESSAGE_SUCCESS_FLASHCARD) && (
            <SuccessFlashcard setCurrentScreen={setCurrentScreen} />
          )}

          {isActiveButton(PlusButtonScrean.NOTA) && <Nota />}
        </div>
      </div>
    </CreatePortalModal>
  )
}

export default PlusButton
