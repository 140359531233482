import React from 'react'
import {useState} from 'react'
import DefaultPhotoUser from '../../Shared/DefaultPhotoUser'
import {useIntl} from 'react-intl'
import {getDateString} from '../../../lib/coco/generalHelpers'

const CardInformationUser = ({currentUser}) => {
  const intl = useIntl()
  const nowDate = new Date()

  const {name: nameUser, getPhotoCurrentUser, subscriptionStatus} = currentUser

  const [errorsCoupon, setErrorsCoupon] = useState('')
  const [coupon, setCoupon] = useState('')

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setErrorsCoupon(
      intl.formatMessage({
        id: 'card-information-user.cod-error',
        defaultMessage: 'The wrong code is inactive or does not exist.',
        description: 'The wrong code is inactive or does not exist.',
      }),
    )
  }

  return (
    <div className="card__form__stripe__information__user">
      <div className="card__form__stripe__information__header">
        <div>
          <DefaultPhotoUser
            className="card__form__stripe__information__photo"
            photourl={getPhotoCurrentUser()}
          />
          <span className="card__form__stripe__information__name">{nameUser}</span>
        </div>

        {subscriptionStatus ? (
          <span className="card__form__stripe__information__status_active">
            {intl.formatMessage({
              id: 'card-information-user.active',
              defaultMessage: 'Active',
              description: 'Active',
            })}
          </span>
        ) : (
          <span className="card__form__stripe__information__status_inactive">
            {intl.formatMessage({
              id: 'card-information-user.inactive',
              defaultMessage: 'Inactive',
              description: 'Inactive',
            })}
          </span>
        )}
      </div>

      <div className="card__form__stripe__information__datails">
        <h1 className="card__form__stripe__information__datails__title">
          {intl.formatMessage({
            id: 'card-information-user.datail-title',
            defaultMessage: 'Purchase details',
            description: 'Purchase details',
          })}
        </h1>
        <div
          style={{
            border: '1px solid #7894B4',
            marginTop: '29px',
            marginBottom: '46px',
          }}
        />

        <div className="card__form__stripe__information__datails__item">
          <span>
            {intl.formatMessage({
              id: 'card-information-user.datail-1',
              defaultMessage: 'Personal attention from teachers.',
              description: 'Personal attention from teachers.',
            })}
          </span>
          <span>$9.00</span>
        </div>

        <div className="card__form__stripe__information__datails__item">
          <span>
            {intl.formatMessage({
              id: 'card-information-user.datail-2',
              defaultMessage: 'Spaced repeating card system.',
              description: 'Spaced repeating card system.',
            })}
          </span>
          <span>$3.00</span>
        </div>

        <div className="card__form__stripe__information__datails__item">
          <span>
            {intl.formatMessage({
              id: 'card-information-user.datail-3',
              defaultMessage: 'Lyrics of thousands of songs.',
              description: 'Lyrics of thousands of songs.',
            })}
          </span>
          <span>$3.00</span>
        </div>

        <div
          style={{
            border: '1px solid #7894B4',
            marginTop: '39px',
            marginBottom: '46px',
          }}
        />

        <p className="card__form__stripe__information__datails__footer__title">
          {intl.formatMessage({
            id: 'card-information-user.cod-coupon',
            defaultMessage: 'Do you have a discount code?',
            description: 'Do you have a discount code?',
          })}
        </p>
        <form
          className="card__form__stripe__information__datails__footer__form"
          onSubmit={handleSubmit}>
          <input
            className="card__form__stripe__information__datails__footer__input"
            style={{
              border: `${
                errorsCoupon?.trim()?.length > 0 ? '1px solid #ff5562' : '0'
              }`,
            }}
            placeholder={intl.formatMessage({
              id: 'card-information-user.cod-placeholder',
              defaultMessage: 'Enter the code',
              description: 'Enter the code',
            })}
            value={coupon}
            onChange={({target}) => setCoupon(target.value)}
          />

          <button
            className="card__form__stripe__information__datails__footer__submit"
            type="submit"
            disabled={coupon?.trim()?.length <= 0}
            style={
              coupon?.trim()?.length > 0
                ? {opacity: 1}
                : {opacity: 0.5, cursor: 'no-drop'}
            }>
            {intl.formatMessage({
              id: 'card-information-user.apply',
              defaultMessage: 'Apply',
              description: 'Apply',
            })}
          </button>
        </form>

        <small className="card__form__stripe__information__datails__footer__error">
          {errorsCoupon?.trim()?.length > 0 && errorsCoupon}
        </small>
      </div>

      <p className="card__form__stripe__information__datails__footer__date">
        {intl.formatMessage({
          id: 'card-information-user.start-suscription',
          defaultMessage: 'Your subscription would start on',
          description: 'Your subscription would start on',
        })}
        <br /> <b>{getDateString(nowDate, ' ')}</b>
      </p>
    </div>
  )
}

export default CardInformationUser
