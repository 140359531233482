import {
  FETCH_ARTIST_REQUEST_START,
  FETCH_ALBUM_REQUEST_START,
  FETCH_ARTIST_REQUEST_FINISH,
  FETCH_ARTIST_REQUEST_ERROR,
  UPDATE_TRACKS_ARTIST_FROM_LIST,
  FETCH_ALBUM_REQUEST_FINISH,
} from './artistsTypes'
import {replaceTrackFromLike} from '../helpers'

const artistsInitialState = {
  data: {
    artist: null,
    topTracks: [],
  },
  albums: [],
  albumsTracks: [],
  loading: false,
  error: {
    message: '',
  },
}

const artistsReducer = (state = artistsInitialState, action) => {
  switch (action.type) {
    case FETCH_ALBUM_REQUEST_START:
      return {
        ...state,
        loading: true,
        error: {message: ''},
      }

    case FETCH_ARTIST_REQUEST_START:
      return {
        ...artistsInitialState,
        loading: true,
      }

    case FETCH_ARTIST_REQUEST_FINISH:
      const {artist, topTracks = [], albums = []} = action.payload

      return {
        ...state,
        loading: false,
        data: {
          artist,
          topTracks,
        },
        albums,
        albumsTracks: [],
      }

    case FETCH_ALBUM_REQUEST_FINISH:
      const {albumsTracks: albumsTracksState = [], albums: albumsState} = state
      const {tracks, album} = action.payload

      const albumsTracksStateNew = [...albumsTracksState, {album, tracks}]
      const newAlbums = albumsState.filter((a) => a.spotify_id !== album.spotify_id)

      return {
        ...state,
        loading: false,
        albums: newAlbums || [],
        albumsTracks: albumsTracksStateNew || [],
      }

    case FETCH_ARTIST_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case UPDATE_TRACKS_ARTIST_FROM_LIST:
      const {artist: artistData, topTracks: topTracksData} = state.data
      const {albumsTracks = []} = state

      const newAlbumsTracks = albumsTracks.map((at) => {
        const {tracks: tracksFromAlbum = []} = at
        const tracksmapped = replaceTrackFromLike(
          action.payload.track,
          tracksFromAlbum,
        )
        return {
          ...at,
          tracks: tracksmapped,
        }
      })

      return {
        ...state,
        data: {
          topTracks: replaceTrackFromLike(action.payload.track, topTracksData),
          artist: artistData,
        },
        albumsTracks: newAlbumsTracks,
      }
    default:
      return state
  }
}

export default artistsReducer
