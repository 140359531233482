import {replaceTrackFromLike} from '../helpers'
import {
  FETCH_MYLIKES_REQUEST_START,
  FETCH_MYLIKES_REQUEST_FINISH,
  FETCH_MYLIKES_REQUEST_ERROR,
  UPDATE_TRACK_MYLIKES_FROM_LIST,
} from './mylLikesTypes'

const mylikesInitialState = {
  data: {
    artists: [],
    tracks: [],
  },
  loading: false,
  error: {
    message: '',
  },
}

const mylikesReducer = (state = mylikesInitialState, action) => {
  switch (action.type) {
    case FETCH_MYLIKES_REQUEST_START:
      return {
        ...state,
        loading: true,
        error: {message: ''},
      }

    case FETCH_MYLIKES_REQUEST_FINISH:
      const {data: dataRequest} = action.payload
      const {data: dataPrevios} = state

      let newState = {}

      if (
        dataPrevios.artists?.length === dataRequest.artists?.length &&
        dataPrevios.tracks?.length === dataRequest.tracks?.length
      ) {
        newState = {
          loading: false,
        }
      } else {
        newState = {
          loading: false,
          data: dataRequest,
        }
      }

      return {
        ...state,
        ...newState,
      }

    case FETCH_MYLIKES_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case UPDATE_TRACK_MYLIKES_FROM_LIST:
      const {artists, tracks} = state.data

      return {
        ...state,
        data: {
          tracks: replaceTrackFromLike(action.payload.track, tracks),
          artists,
        },
      }
    default:
      return state
  }
}

export default mylikesReducer
