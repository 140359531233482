import React, {useEffect} from 'react'
import './SearchBar.css'
import useSearchInputs from '../../../hooks/useSearchInputs'
import {useDebounce} from 'use-debounce'
import {useIntl} from 'react-intl'
import lupaMobile from '../Icons/lupaMobile.svg'

const SearchBar = ({
  isDeviseMobile,
  isPathnameSearchMediatext,
  validatePathname,
}) => {
  const intl = useIntl()

  const {
    loading,
    params = '',
    dispatchSetParams,
    dispatchGetInputsNyParams,
  } = useSearchInputs()

  const [value] = useDebounce(params, 500)

  useEffect(() => {
    makeSearch()
    // eslint-disable-next-line
  }, [value])

  const onChangeParams = (text) => {
    dispatchSetParams(text)
    validatePathname()
  }

  const makeSearch = () => {
    if (loading) {
      return
    }

    if (params?.length >= 2) {
      dispatchGetInputsNyParams(params)
    }
  }

  const handleKeyPress = ({which}) => {
    if (which === 13) {
      dispatchGetInputsNyParams(params)
    }
  }

  return (
    <>
      {!isPathnameSearchMediatext() && isDeviseMobile ? (
        <img onClick={validatePathname} src={lupaMobile} alt={lupaMobile} />
      ) : (
        <input
          onClick={validatePathname}
          className="search-selector"
          type="search-mediatext"
          placeholder={intl.formatMessage({
            id: 'topbar.search',
            defaultMessage: 'Buscar musica',
          })}
          onChange={({target}) => onChangeParams(target.value)}
          onKeyPress={handleKeyPress}
          autoFocus={isPathnameSearchMediatext()}
          value={isPathnameSearchMediatext() ? params : ''}
        />
      )}
    </>
  )
}

export default SearchBar
