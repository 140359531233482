import React from 'react'

const DefinirHilo = ({defineThread}) => {
  return (
    <div className="h-100 ml-4">
      <div
        id="btn-outline-define-thread"
        className="btn-outline-linear-gradient-define-thread h-100"
        onClick={defineThread}>
        <span>Definir</span>
      </div>
    </div>
  )
}

export default DefinirHilo
