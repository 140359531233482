import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {
  getAllProgress,
  setPhotoUrl,
  toggleSuscriptionStrapiModal,
  updateNotificationOptions,
  updateOptionsTutorial,
  updateprofileData,
} from '../Redux/users/actions'

const useUser = () => {
  const dispatch = useDispatch()
  const userState = useSelector((state) => state.user, shallowEqual)

  const {photo = {url: null}, options_tutorial = {}} = userState
  // si el user no tiene foto, sele agregara la de spotify por default
  const getPhotoCurrentUser = () => {
    if (photo?.url) {
      return photo.url
    }
    return null
  }

  //   dispatch to redux
  const dispachUpdateProfileData = (profile) => {
    dispatch(updateprofileData(profile))
  }

  const dispatchUpdatePhotoUrl = (photoUrl) => {
    dispatch(setPhotoUrl(photoUrl))
  }

  const dispatchNotificationsOptions = (options) => {
    dispatch(updateNotificationOptions(options))
  }

  // obtener los progresos del usuario
  const dispatchGetAllProgress = () => {
    dispatch(getAllProgress())
  }

  // cambiar el status de abrir modal de suscription
  const dispatchToggleSuscriptionStrapiModal = (status) => {
    dispatch(toggleSuscriptionStrapiModal(status))
  }

  // cambiar el status de tutoriales
  const dispatchUpdateTutorials = (params = {}) => {
    dispatch(
      updateOptionsTutorial({
        ...options_tutorial,
        ...params,
      }),
    )
  }

  return {
    ...userState,
    getPhotoCurrentUser,
    dispachUpdateProfileData,
    dispatchUpdatePhotoUrl,
    dispatchNotificationsOptions,
    dispatchGetAllProgress,
    dispatchToggleSuscriptionStrapiModal,
    dispatchUpdateTutorials,
  }
}

export default useUser
