import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Styles
const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 15px;
  bottom: 15px;

  padding: 0;
  margin: 0;
  border-radius: 50%;
  width: 40.75px;
  height: 40.75px;
  background: black;
  box-shadow: ${(props) => (props.active ? 'none' : '2px -3px 3px #333435')};

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 0;
    width: 32.39px;
    height: 32.39px;
    border-radius: 50%;
    color: black;
    border: solid 2px transparent;

    background-image: linear-gradient(-45deg, #ff5962 16.06%, #fd935e 91.7%),
      linear-gradient(-45deg, #ff5962 16.06%, #fd935e 91.7%),
      linear-gradient(-45deg, #ff5962 16.06%, #fd935e 91.7%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: ${(props) => (props.active ? 'none' : '2px 1000px 1px black inset')};

    &:hover {
      box-shadow: none;
    }
  }
`

// El componente a exportar, el botón ya estilizado
const BtnPista = (props) => {
  const {active, styleButton = {}} = props

  return (
    <Button id="btn-pista" active={active} style={styleButton}>
      <div className="btn pistitlla" onClick={props.action}>
        <img src="/images/Output/iconPista.svg" alt="send" />
      </div>
    </Button>
  )
}

// Validación de props
BtnPista.propTypes = {
  active: PropTypes.bool,
  action: PropTypes.func.isRequired,
}

// Valores por default para las props
BtnPista.defaultProps = {
  active: false,
}

export default BtnPista
