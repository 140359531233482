export default {
  en: {
    'tutorial.title.step.1': 'Flashcards',
    'tutorial.next': 'Next',
    'daily.steps': '3 Daily step method',
    'tutorial.thread': 'We have not discovered the magic bullet.',
    'tutorial.instruction':
      'The most important polyglots and <br /> all the people who have reached fluency agree that there are 3 <br /> important daily steps for learning a new language:',
    'tutorial.consume':
      'Consume a lot of content in the language <br /> you want to learn.',
    'tutorial.write': 'Write and speak <br /> a lot -- with feedback.',
    'tutorial.practice': 'Review what you learn with <br /> spaced repetition.',
    'tutorial.remember': 'Remember',
    'tutorial.recommended':
      'Practice this 3-step learning method every day to reach fluency.',
    'tutorial.use':
      'Coco is an application that unites these 3 steps <br /> in one place.',
    'tutorial.question': 'What do you have to do in the application?',
    'tutorial.explore':
      'Explore and listen to a lot of music and learn from the lyrics.',
    'tutorial.linking':
      'We will link your Spotify account so that you have all the music you want <br /> at hand.',
    'tutorial.writeo': 'Write, write and write to practice the language.',
    'tutorial.section':
      'We have a section where you can express all your ideas <br /> and in doing so, a teacher will review them and help you improve.',
    'tutorial.memorize': 'Memorize vocabulary with spaced repetition.',
    'tutorial.hand':
      'You will have flashcards on hand that you can study <br /> with a spaced repetition algorithm.',
    'tutorial.start': 'Start',
  },
  es: {
    'tutorial.title.step.1': 'Flashcards',
    'tutorial.next': 'Siguiente',
    'daily.steps': 'El método de 3 pasos diarios.',
    'tutorial.thread': 'No hemos descubierto el hilo negro.',
    'tutorial.instruction':
      'Los políglotas más importantes y <br /> todas las personas que han llegado a la fluidez coinciden en que hay 3 <br /> pasos importantes para poder aprender un idioma nuevo:',
    'tutorial.consume':
      'Consumir mucho contenido del idioma <br /> que quieren aprender.',
    'tutorial.write': ' Escribir y conversar <br /> muchísimo.',
    'tutorial.practice': 'Repasar lo aprendido con <br /> repetición espaciada.',
    'tutorial.remember': 'Recuerda',
    'tutorial.recommended':
      'Practica todos los días nuestro método de aprendizaje de 3 pasos y llega a la fluidéz.',
    'tutorial.use':
      'Coco es una aplicación que une estos 3 pasos <br /> en un solo lugar.',
    'tutorial.question': '¿Qué tienes que hacer en la aplicación?',
    'tutorial.explore':
      'Explorar y escuchar muchísima música y aprender de su letra.',
    'tutorial.linking':
      'Enlazaremos tu cuenta de Spotify para que tengas a la mano toda <br /> la música que quieras.',
    'tutorial.writeo': 'Escribir, escribir y escribir para practicar el idioma.',
    'tutorial.section':
      'Tenemos una sección para que puedas expresar todas tus ideas <br /> y al hacerlo, un profesor las revisará y te ayudará a mejorar.',
    'tutorial.memorize': 'Memorizar vocabulario con repetición espaciada.',
    'tutorial.hand':
      'Tendrás a la mano cartas de memorización que podrás estudiar <br /> con un algoritmo de repetición espaciada.',
    'tutorial.start': 'Comenzar',
  },
  fr: {
    'tutorial.title.step.1': 'Flashcards',
    'tutorial.next': 'Suivant',
    'daily.steps': 'La méthode quotidienne en 3 étapes.',
    'tutorial.thread': "Nous n'avons pas découvert le fil noir.",
    'tutorial.instruction':
      "Les polyglottes les plus importants et <br /> toutes les personnes qui ont atteint la maîtrise conviennent qu'il y a 3 <br /> étapes importantes pour pouvoir apprendre une nouvelle langue:",
    'tutorial.consume':
      "Consommez beaucoup de contenu dans la langue <br /> qu'ils souhaitent apprendre.",
    'tutorial.write': 'Écrivez et parlez beaucoup <br /> répétition espacée.',
    'tutorial.practice':
      "Passez en revue ce qu'ils ont appris avec <br /> répétition espacée.",
    'tutorial.remember': 'Rappelles toi',
    'tutorial.recommended':
      "Pratiquez chaque jour notre méthode d'apprentissage en 3 étapes et devenez fluide.",
    'tutorial.use':
      'Coco est une application qui réunit ces 3 étapes <br /> en un seul endroit.',
    'tutorial.question': "Que devez-vous faire dans l'application?",
    'tutorial.explore':
      'Explorez et écoutez beaucoup de musique et apprenez de ses paroles.',
    'tutorial.linking':
      'Nous lierons votre compte Spotify afin que vous ayez toute la musique que vous souhaitez <br /> à portée de main.',
    'tutorial.writeo': 'Écrivez, écrivez et écrivez pour pratiquer la langue.',
    'tutorial.section':
      'Nous avons une section pour que vous puissiez exprimer toutes vos idées <br /> et ce faisant, un enseignant les examinera et vous aidera à vous améliorer.',
    'tutorial.memorize': 'Mémorisez le vocabulaire avec des répétitions espacées.',
    'tutorial.hand':
      'Vous aurez sous la main des cartes mémoire que vous pourrez étudier <br /> avec un algorithme de répétition espacée.',
    'tutorial.start': 'commencer',
  },
}
