import React, {useState, useEffect} from 'react'
import './DiccionarioLibre.css'

import CloseDictionary from './Icons/CloseDictionary.svg'
import useAlert, {TOAST_TYPE} from '../../hooks/useAlert'
import CreatePortalModal from '../Modal/CreatePortalModal'
import {
  getDiccionaryDefinitions,
  getDiccionaryExact,
  getPhrasesDiccionaryCoco,
} from '../../lib/coco/DiccionaryAPi'
import {useDebounce} from 'use-debounce'
import useDiccionary from '../../hooks/useDiccionary'
import LoadingPage from '../Animaciones/LoadingPage'
import OtherDictionaries from './OtherDictionaries'
import SearchPicture from './SearchPicture'
import {useWorkingIndicator} from '../../hooks/useBooleanToggler'
import BodyDictionary from './BodyDictionary'
import {
  isDiccionaryCoco,
  isDiccionaryOxford,
  isDiccionaryGoogle,
  DiccionaryType,
} from './helperDictionary'
import HeaderDictionaries from './HeaderDictionaries'
import DiccionaryGoogle from './DiccionaryGoogle'
import SearchDictionary from './SearchDictionary'

import PropTypes from 'prop-types'
import DiccionaryCoco from './DiccionaryCoco'
import DiccionaryOxfordLemma from './DiccionaryOxfordLemma'
import DiccionaryCocoSearchWord from './DiccionaryCocoSearchWord'
import useSpotify from '../../hooks/useSpotify'

const DiccionarioLibre = () => {
  const {dispatchCloseDiccionary: closeDiccionary} = useDiccionary()

  const {stopTracksSpotify} = useSpotify()
  const {
    params,
    dispatchSetParamsDiccionary,
    wordMark = null,
    mediaTextId = null,
    theMusicWasPlaying = false,
  } = useDiccionary()

  const [diccionartTypeSelected, setDiccionartTypeSelected] = useState(
    DiccionaryType.COCO,
  )

  const [phrases, setPhrases] = useState([]) // frases de coco
  const [diccionaryOxford, setDiccionaryOxford] = useState(null) // frases de oxford
  const [definitionsGoogle, setDefinitionsGoogle] = useState([]) // definiciones
  const [phraseIdsAgregados, setPhraseIdsAgregados] = useState([]) // phrase ids agregados

  const [requestExists, setRequestExists] = useState(false)
  const [traductionDirecta, setTraductionDirecta] = useState('')
  const [showPhraseRequest, setShowPhraseRequest] = useState(false)
  const [searchedWord, setSearchedWord] = useState(params || '')
  const {showMessage} = useAlert()
  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const {
    isWorking: isWorkingOxford,
    startWork: startWorkOxford,
    finishWork: finishWorkOxford,
  } = useWorkingIndicator()
  const {
    isWorking: isWorkingGoogle,
    startWork: startWorkGoogle,
    finishWork: finishWorkGoogle,
  } = useWorkingIndicator()

  const [value] = useDebounce(params, 500)

  useEffect(() => {
    setDiccionartTypeSelected(DiccionaryType.COCO)
    setDiccionaryOxford(null)
  }, [wordMark])

  useEffect(() => {
    getPhrases()
    // eslint-disable-next-line
  }, [value])

  const checkIfPlayerWasPlaying = () => {
    // stopTracksSpotify
    if (theMusicWasPlaying) {
      stopTracksSpotify()
    }

    closeDiccionary()
  }

  const onChangeText = (text) => {
    // update text, but other params null, because need lemma and others.
    // theMusicWasPlaying is equal.
    dispatchSetParamsDiccionary(text, null, null, theMusicWasPlaying)
  }

  const getPhrases = async () => {
    if (params?.trim()?.length > 0) {
      startWork()
      getPhrasesDiccionaryCoco(params).then((res) => {
        if (res?.data) {
          const {
            phrases = [],
            phrase_ids_agregados = [],
            request_exists = false,
            translation = '',
          } = res?.data

          if (params !== '') setSearchedWord(params)
          setShowPhraseRequest(phrases?.length > 0 ? false : true)
          setPhrases(phrases)
          setPhraseIdsAgregados(phrase_ids_agregados)
          setRequestExists(request_exists)
          setTraductionDirecta(translation)
          if (phrases?.length <= 0 && wordMark?.lemma) {
            setDiccionartTypeSelected(DiccionaryType.OXFORD)
            getPhrasesOxford()
          }
        } else if (res?.message) {
          showMessage(res.message, TOAST_TYPE.ERROR)
          setPhrases([])
          if (wordMark?.lemma) {
            setDiccionartTypeSelected(DiccionaryType.OXFORD)
            getPhrasesOxford()
          }
        }

        finishWork()
      })
    }
  }

  const getPhrasesOxford = () => {
    if (wordMark) setDiccionartTypeSelected(DiccionaryType.OXFORD)
    // validacion para que no se repita la busqueda cuando diccionaryOxford
    // ya se haya cargado con el wordMark.lemma anteriormente
    if (diccionaryOxford?.lemma !== wordMark?.lemma) {
      startWorkOxford()
      getDiccionaryExact(wordMark).then((res) => {
        finishWorkOxford()
        if (res?.diccionary) {
          const {example_sentences, translations, lemma = ''} = res.diccionary
          setDiccionaryOxford({
            lemma,
            exampleSentences: example_sentences,
            translations,
          })
        }
      })
    }
  }

  const getDefinitions = () => {
    startWorkGoogle()
    setDiccionartTypeSelected(DiccionaryType.GOOGLE)
    getDiccionaryDefinitions(wordMark?.lemma, wordMark?.lang_code).then((res) => {
      finishWorkGoogle()
      if (res?.data) {
        // insertar magia de michael para combinar los dos arreglos.
        setDefinitionsGoogle(res.data)
        // setDefinitionsGoogle(res?.data[0])
      }
    })
  }

  const updateIndicesAddPhrasesCoco = (index) => {
    setPhraseIdsAgregados([...phraseIdsAgregados, index])
  }

  return (
    <CreatePortalModal elementId="diccionary-root">
      <div id="diccionary-coco" className="diccionary-open">
        <div className="main-diccionary">
          <div className="diccionary-header">
            <div
              className="header-diccionary-close"
              onClick={checkIfPlayerWasPlaying}>
              <img src={CloseDictionary} alt={CloseDictionary} />
            </div>

            <SearchDictionary
              getPhrases={getPhrases}
              value={params}
              onChangeText={onChangeText}
            />

            {wordMark && (
              <HeaderDictionaries
                diccionartTypeSelected={diccionartTypeSelected}
                setDiccionartTypeSelected={setDiccionartTypeSelected}
                getDefinitions={getDefinitions}
                getPhrasesOxford={getPhrasesOxford}
              />
            )}

            {isDiccionaryCoco(diccionartTypeSelected) && (
              <>
                {isWorking ? (
                  <LoadingPage />
                ) : (
                  <DiccionaryCocoSearchWord
                    searchedWord={searchedWord}
                    phrases={phrases}
                    wordMark={wordMark}
                    traductionDirecta={traductionDirecta}
                  />
                )}
              </>
            )}

            {isDiccionaryOxford(diccionartTypeSelected) && (
              <>
                {!isWorkingOxford ? (
                  <DiccionaryOxfordLemma
                    diccionaryOxford={diccionaryOxford}
                    wordMark={wordMark}
                  />
                ) : (
                  <LoadingPage />
                )}
              </>
            )}

            {isDiccionaryGoogle(diccionartTypeSelected) && isWorkingGoogle && (
              <LoadingPage />
            )}
          </div>
          {isDiccionaryCoco(diccionartTypeSelected) && !isWorking && (
            <DiccionaryCoco
              mediaTextId={mediaTextId}
              params={params}
              phrases={phrases || []}
              requestExists={requestExists}
              setRequestExists={setRequestExists}
              showPhraseRequest={showPhraseRequest}
              wordMark={wordMark}
              phraseIdsAgregados={phraseIdsAgregados}
              updateIndicesAddPhrasesCoco={updateIndicesAddPhrasesCoco}
            />
          )}
          {isDiccionaryOxford(diccionartTypeSelected) && !isWorkingOxford && (
            <BodyDictionary
              wordMark={wordMark}
              mediaTextId={mediaTextId}
              phrases={diccionaryOxford?.exampleSentences || []}
            />
          )}
          {isDiccionaryGoogle(diccionartTypeSelected) && !isWorkingGoogle && (
            <DiccionaryGoogle definitionsGoogle={definitionsGoogle} />
          )}
          {isDiccionaryCoco(diccionartTypeSelected) &&
            phrases?.length <= 0 &&
            params?.trim()?.length <= 0 && <SearchPicture />}
          {params?.trim()?.length > 0 && <OtherDictionaries word={params} />}
        </div>
      </div>
    </CreatePortalModal>
  )
}

DiccionarioLibre.propTypes = {
  params: PropTypes.string,
  setParamsDiccionary: PropTypes.func.isRequired,
}

DiccionarioLibre.defaultProps = {
  params: '',
  setParamsDiccionary: () => {},
}

export default DiccionarioLibre
