import {getToken} from './getToken'
import jwt from 'json-web-token'
import {getVariables} from './constants'

// get all constants
const {API, API_WS, DEVISE_SECRET_KEY} = getVariables()

//endpoint base
export const API_BASE = API

// Spotify
export const LINK_SPOTIFY_ACCOUNT = API + '/link-spotify-account'
export const UNLINK_SPOTIFY_ACCOUNT = API + '/unlink-spotify-account'

//crear domnains
export const DOMAINS = API + '/api/v1/domains' //post

// **PLAY LIST
export const GET_TRACKS_ARTIST = API + '/spotify/get_tracks_artist/' // + idSpotify Artista
export const GET_TRACKS_ALBUM = API + '/spotify/album_tracks/' // + idSpotify album

//DICCIONARIO GENERAL
export const DICCIONARIO_LIBRE = API + '/api/v1/search/diccionario_libre'

// DICTIONARY
export const GET_PHRASES = API + '/api/v1/phrases'
export const PEDIR_FRASES = API + '/api/v1/pedir_frases'
export const CREAR_FRASES = API + '/api/v1/create_phrases_teacher'

//obtiene respuestas de los estudiantes de una pregunta
export const IS_TEACHING_USER = API + '/api/v1/users' //+ id de usuario
export const GET_STUDENTS_RESPONSES = API + '/api/v1/user_responses/' //+ id
// obtiene OutputVerification Items (diverido, profundo, anécdota)
export const GET_OUTPUT_ITEMS = API + '/api/v1/output_items'
//guada un OutputVerification User(resuestas del estudiante)
export const SAVE_USER_OUTPUT = API + '/api/v1/user_outputs'
export const COMFIRM_USER_OUTPUT = API + '/api/v1/confirm_translation'
// obtiene todos los outputs del usuario
export const USER_OUTPUTS = API + '/api/v1/user_outputs'
export const SHOW_USER_OUTPUT = API + '/api/v1/user_outputs/'
export const MY_OUTPUTS = API + '/api/v1/user_outputs'
//export const GET_UNCORRECTED_USER_OUTPUTS = API + "/api/v1/user_outputs/my_uncorrected_outputs";
export const UPDATE_UNCORRECTED_USER_OUTPUT = API + '/api/v1/user_outputs/'

// *** WORDS***
const WORDS_PATH = '/api/v1/words/'
export const GET_WORDS = API + WORDS_PATH
export const UPDATE_WORDS = API + WORDS_PATH
export const SHOW_WORDS = API + '/api/v1/search_word/?word='
export const CREATE_WORDS = API + WORDS_PATH
export const LEARNED_TERM = API + WORDS_PATH + 'check_work'
export const SAVE_OUTPUT_PHRASES = API + WORDS_PATH + 'create_phrases'
export const GET_INFLECTIONS = API + '/api/v1/inflections'

// *** MEDIA TEXT ***
//endpoint para obtener resultados de significados del diccionario, METODO Get
export const GET_API_DICTIONARY = API + '/api/v1/search/diccionario_exacto'
//endpoint para guardar medio humano(texto), METODO POST
export const SAVE_MEDIO_HUMANO = API + '/api/v1/media_texts'
//Se espera la traducción de la canción ingresada manualmente.
export const ADD_SONG_LYRICS = API + '/api/v1/add_text/'
//show translations
export const SHOW_TRANSLATION = API + '/show_translation'
export const CREATE_INPUT_TEXT = API + '/api/v1/add_custom_text'
export const GET_INPUT_TEXTS = API + '/api/v1/input_texts'
export const GET_LAST_INPUT = API + '/api/v1/last_input_text/?id='

// USER OUTPUT COMMENTS
export const CREATE_USER_OUTPUT_COMMENT = API + '/api/v1/comments'
export const lIKE_USER_OUTPUT = API + '/api/v1/user_output_likes'
export const DISLIKE_USER_OUTPUT = API + '/api/v1/user_output_likes/'

// **** FLASHCARDS ****
export const SAVE_FLASHCARD_TRANSLATION = API + '/api/v1/items'
export const FLASHCARDS = API + '/api/v1/items'
export const FLASHCARDS_REVISADOS = API + '/api/v1/cards_revisados'
export const FLASHCARDS_MARCAR_VISTOS = API + '/api/v1/marcar_cards_como_vistos'
export const FLASHCARDS_YA_AGREGADO = API + '/api/v1/ya_agregado'
//traducir
export const GET_TRADUCTOR = API + '/api/v1/search/translate' //?body="body"
//enviar respuesta, repeticion espaciada
export const SEND_ANSWER_FLASHCARD = API + '/api/v1/study_card'
//obtener targeta a estudiar
export const GET_CARD_STUDY = API + '/api/v1/study_card'
//guardar custom flashcards, metodo:post
export const CREATE_FLASHCARD = API + '/api/v1/items'
//editar custom flashcard: metodo:put
export const UPDATE_FLASHCARD = API + '/api/v1/items'

//destruir una flashcard o item.
export const DESTROY_ITEM = API + '/api/v1/items'

// controlador memorize o item.
export const MEMORIZED_TERMS = API + '/api/v1/memorized_terms'

// **** FRACES *****
//agregar frase

// *** CONVERTERS ***
export const GET_NATURAL_VOICE = API + '/api/v1/converters/text_to_speech'

// **** USUARIO ***
//loguerase
export const LOGIN = API + '/login'
//logout
export const LOGOUT = API + '/logout'
//crear nueva cuenta
export const SIGNUP = API + '/signup'

// **** LANGUAGES ****
//guardar lenguaje nativo y lenguaje a estudiar
export const SAVE_USER_LANGUAGES = API + '/api/v1/language_roles'
//guardar lenguaje a aprender
export const SAVE_LEARNING_LANGUAGES = API + '/api/v1/create_learning_language'
//obtener todos los lenguajes
export const GET_LANGUAGES = API + '/api/v1/languages'

// **** RESET PASSWORD ****
//confirmar email : post   Y restaurar password : put
export const RESET_PASSWORD = API + '/password'

//Buscar mediatext por medio de un termino
export const SEARCH_MEDIA_TEXT = API + '/api/v1/search/media/?termino='
// /api/v1/user_inputs/:id
//get progress bar
export const PROGRESS = API + '/api/v1/progress/today'

// retornar listas de reproduccion
export const MY_PLAYLIST = API + '/api/v1/my_playlist'
export const RECOMENDACIONES = API + '/api/v1/recomendaciones'
export const INPUTS_STUDIED = API + '/api/v1/music_studied'

//preguntas API
export const PREGUNTAS_API = API + '/api/v1/output_to_correct'
export const MIS_ME_GUSTA = API + '/api/v1/user_inputs'
export const ARTIST_LIKE = API + '/api/v1/author_likes'
export const ARTIST_REMOVE_LIKE = API + '/api/v1/author_likes' // /:id de author like
export const PLAYLISTS = API + '/api/v1/playlists'

//agregar y quitar track de un playlist
export const PLAYLIST_MEDIATEXT = API + '/api/v1/playlist_media_texts'

export const GET_TRACK_BY_SPOTIFY_ID = `${API}/api/v1/get_track`

// enviar respuestas de outputs
export const SEND_ANSWER = API + '/api/v1/correct_output'
export const GET_ANSWER = API + '/api/v1/output_to_correct' // + /:user_id

export const TEACHER_DASHBOARD = API + '/api/v1/teacher_dashboard'
export const SOLICITAR_CONTEXTOS = API + '/api/v1/contextos_solicitados/' //+ :user_id
export const COMMUNITY = API + '/api/v1/community'

// verificar inputs
export const GET_INPUT_TO_VERIFIFY = API + '/api/v1/songs_to_verify'
export const VERIFY_INPUT = API + '/api/v1/correct_input' // media_texts/:id/edit
export const CORRECT_REPORT_INPUT = API + '/api/v1/manejar_reporte' // media_texts/:id/edit

// inputs
export const GET_MEDIA_TEXT = API + '/api/v1/open_song'
export const CREATE_REPORT = API + '/api/v1/media_text_reports'
export const SHARE_TRACK = API + '/api/v1/recommend_song'

//verificar flashcards
export const ASSIGN_CARDS = API + '/api/v1/assign_cards'
export const VERIFY_CARD = API + '/api/v1/verify_card'
export const CARDS_LEFT_FOR_VERIFICATION =
  API + '/api/v1/cards_left_for_verification'

// verificar output
export const CREATE_OUTPUT_ITEM = API + '/api/v1/output_items'

//perfil endpoints
export const GET_PERFIL_DATA = API + '/api/v1/profiles/0' // poner 0 porque se usa el usuario...
export const ENVIAR_COMENTARIO = API + '/api/v1/comments'
export const PERFIL_MANAGE = API + '/api/v1/update_profile'
export const DELETE_PHOTO = API + '/api/v1/profiles/delete_foto'
export const UPLOAD_PHOTO = API + '/api/v1/profiles/upload_photo'
export const GET_NOTIFICATIONS = API + '/api/v1/notifications' // index
export const READ_NOTIFICATION = API + '/api/v1/notifications' // + '/id'. Update.
export const TEACHER_PROFILE = API + '/api/v1/teacher_profile'

//apis externas
//unplash
export const UNSPLASH_ID = process.env.REACT_APP_UNSPLASH_API_KEY
export const UNSPLASH_ENDPOINT = 'https://api.unsplash.com/search/photos'

//change password
export const CHANGE_PASSWORD = API + '/api/v1/users/update_password'
export const CHANGE_LANGUAGE_GOAL = API + '/api/v1/change_learning_goals'
export const CHANGE_OPTIONS_TUTORIAL = API + '/api/v1/options_tutorial'
export const UPDATE_NOTIFICATIONS_SETTINGS = API + '/api/v1/notifications_settings'
export const UPDATE_PUSH_SUBSCRIPTION = API + '/api/v1/push'

//endpoints de prueba
export const LOGGING_API = API + '/api/v1/logger/error'
export const SET_LANGUAGE_INTERFACE = API + '/api/v1/set_interface'
export const GET_LANGUAGE_INTERFACE = API + '/api/v1/get_interface'

export const CHANGE_ACTIVE_DOMAIN = API + '/api/v1/change_active_domain'

export const CONFIRMATION_ACCOUNT = API + '/confirm'
export const WORDS_LEARNED = API + '/api/v1/words_learned'

//dashboard y todo flujo de guru(crear un item, actualizarlo, obtener listado de itrms)
export const ITEMS = API + '/api/v1/yo' //post, put, get
export const CREATE_ITEM = API + '/api/v1/items'
export const UPDATE_ITEM = API + '/api/v1/items/'

// para chat
export const API_ROOT = API
export const API_WS_ROOT = API_WS
export const HEADERS = {
  Authorization: getToken(),
  'Content-Type': 'application/json',
}

// Community
const rutaPost = API + '/api/v1/posts'

export const CREAR_PREGUNTA = rutaPost
export const LISTA_DE_PREGUNTAS = rutaPost
export const ACTUALIZAR_PREGUNTA = rutaPost
export const COMENTAR_PREGUNTA = API + '/api/v1/comments'
export const MOSTRAR_PREGUNTA = rutaPost
export const AGREGAR_RESPUESTA = API + '/api/v1/responses'
export const VOTAR = API + '/api/v1/votes'
export const PERFIL_DE_USUARIO = API + '/api/v1/community_profile'
export const BUSCAR_EN_COMUNIDAD = API + '/api/v1/search_posts/?termino='
export const RESOLVER_POST = API + '/api/v1/resolver_post'

// Items
export const MOSTRAR_ITEMS = API + '/api/v1/items/'
export const COMENTAR_ITEM = API + '/api/v1/comments'
export const PROGRAMAR_ITEM = API + '/api/v1/items/'
export const ELIMINAR_ITEM = API + '/api/v1/items/'
export const OBTENER_ITEMS = API + '/api/v1/yo'
export const CREATE_SNIPPET = API + '/api/v1/create_snippet'
export const MARK_ITEM_AS_READ = API + '/api/v1/mark_as_read'

// Notes
export const CREAR_NOTA = API + '/api/v1/create_note'
export const USER_BY_AT = API + '/api/v1/users_by_at'

// Flowboard
export const ACTIONS = API + '/api/v1/actions/'
// Generales
export const UPDATE_PROFILE = `${API}/api/v1/update_profile/`
export const TO_ACTIONS = API + '/api/v1/to_actions'
export const ACTIONS_PERSONAL = API + '/api/v1/actions/'
export const ACTIONS_PUBLICO = API + '/api/v1/actions/'

export const INBOX_PERSONAL = API + '/api/v1/inbox_mio' //mi_inbox
export const INBOX_NUESTRO = API + '/api/v1/inbox_nuestro'
export const SHOW_ITEM = API + '/api/v1/items'

export const RETROALIMENTAR_OUTPUT = API + '/api/v1/correct_output'
export const REVISAR_INPUT = API + '/api/v1/correct_input'
export const CORREGIR_FLASHCARD = API + '/api/v1/verify_card'

// Generales
export const COMENTAR = API + '/api/v1/comments'
export const BOOKMARK = API + '/api/v1/items/' // Es lo mismo que UPDATE_ITEM.

//DocuChat
export const DELETE_MESSAGE = API + '/messages/'

export const INVITATIONS = `${API}/invitation`
// 90 days challenge
export const TOGGLE_TEACHER = `${API}/api/v1/toggle_teacher`
export const CHALLENGE_DATA = `${API}/api/v1/progress/challenge_table`

// splash demos
export const GET_FLASHCARDS_DEMOS = `${API}/api/v1/splash/study_flashcard`
export const GET_OUTPUTS_DEMOS = `${API}/api/v1/splash/study_output`
export const GET_INPUTS_DEMOS = `${API}/api/v1/splash/study_song`
export const GET_CONTACT_BY_EMAIL = `${API}/api/v1/splash/contact_exist`
export const SEND_CONTACT_SPLASH = `${API}/api/v1/splash/contact`

export const webSocketToken = (user_id) => {
  const algo = 'HS256'

  return jwt.encode(DEVISE_SECRET_KEY, {user_id: user_id}, algo, (err, token) => {
    return token
  })
}

// COMUNIDAD
export const BUSCAR_PERSONA = API + '/api/v1/search_person'
export const SEGUIR = API + '/api/v1/followers/'
export const DEJAR_DE_SEGUIR = API + '/api/v1/followers/'
export const GET_USER_CONTENT = API + '/api/v1/get_user_content/'
export const GET_USER_MATCHES = API + '/api/v1/get_user_matches/'

// Subscriptions
export const START_TRIAL = `${API}/api/v1/trial`
export const SUBSCRIBE = `${API}/api/v1/subscriptions`
export const UPDATE_CARD = `${API}/api/v1/update_card`
export const CANCEL_SUBSCRIPTION = `${API}/api/v1/subscriptions`
export const CHECK_SUBSCRIPTION = `${API}/api/v1/check_subscription`
// update_card

// Nuevos endpoinds de Audio-Phrases
export const GET_PHRASE_TO_PRONOUNCE = API + '/api/v1/get_phrase_to_pronounce'
export const SAVE_NATIVE_AUDIO = API + '/api/v1/save_native_audio'
