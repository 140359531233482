import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {addTracksToPlayerColas} from '../Redux/MediaText/actions'
import {
  playerLoading,
  playTrack,
  togglePlayback,
} from '../Redux/spotify/actions/player'

const useSpotify = () => {
  const spotify = useSelector((state) => state.spotify, shallowEqual)
  const dispatch = useDispatch()

  const {
    player: {
      track_window: {current_track},
      paused,
      position: positionMs,
    },
  } = spotify
  const isPlayinTrack = !paused

  const isTrackInPlayer = (uri) => current_track.uri === uri

  const isTrackActive = (uri) => {
    if (current_track && uri) {
      if (isTrackInPlayer(uri) && isPlayinTrack) {
        return true
      }
    }
    return false
  }

  const playTrackValidate = (uri) => {
    if (uri) {
      if (current_track?.uri === uri) {
        stopTracksSpotify()
      } else {
        dispatchPlayTrack(uri)
      }
    }
  }

  const stopTracksSpotify = () => {
    dispatch(togglePlayback(isPlayinTrack, current_track.uri, positionMs))
  }

  const dispatchPlayTrack = (uri) => {
    dispatch(playerLoading(true))
    dispatch(playTrack(uri, 0))
  }

  // para next y previous tracks que estan en media_text
  const dispatchAddTracksToPlayerColas = (tracks) => {
    dispatch(addTracksToPlayerColas(tracks))
  }

  return {
    ...spotify,
    isTrackInPlayer,
    isTrackActive,
    playTrackValidate,
    stopTracksSpotify,
    dispatchPlayTrack,
    dispatchAddTracksToPlayerColas,
  }
}

export default useSpotify
