import {useDispatch} from 'react-redux'
import {addTrackToMyLikes, deleteTrackToMyLikes} from '../lib/coco/InputApi'
import {updateTrackArtists} from '../Redux/artists/artistsActions'
import {updateTrackDashboard} from '../Redux/dashboard/dashboardActions'
import {updateTrackMyInputs} from '../Redux/MyInputsStudied/actions'
import {updateTrackMyLikes} from '../Redux/MyLikes/mylLikesActions'
import {updateTrackPlaylist} from '../Redux/PlayLists/playlistActions'
import {updateTrackSearchInput} from '../Redux/searchInputs/searchActions'

const useLikeTracks = () => {
  const dispatch = useDispatch()

  const updateAllTracksLikes = (mediatextId, spotifyId, userInputId) => {
    dispatch(
      updateTrackMyInputs({
        spotify_id: spotifyId,
        id: mediatextId,
        user_input_id: userInputId,
      }),
    ) // my-inputs
    dispatch(
      updateTrackDashboard({
        spotify_id: spotifyId,
        id: mediatextId,
        user_input_id: userInputId,
      }),
    ) // dasgboard
    dispatch(
      updateTrackMyLikes({
        spotify_id: spotifyId,
        id: mediatextId,
        user_input_id: userInputId,
      }),
    ) // Mylikes
    dispatch(
      updateTrackSearchInput({
        spotify_id: spotifyId,
        id: mediatextId,
        user_input_id: userInputId,
      }),
    ) // search inputs
    dispatch(
      updateTrackArtists({
        spotify_id: spotifyId,
        id: mediatextId,
        user_input_id: userInputId,
      }),
    ) // artist
    dispatch(
      updateTrackPlaylist({
        spotify_id: spotifyId,
        id: mediatextId,
        user_input_id: userInputId,
      }),
    ) // 1 playlist
  }

  const updateAllTracksDisLikes = (spotifyId) => {
    dispatch(
      updateTrackMyInputs({
        spotify_id: spotifyId,
        id: null,
        user_input_id: null,
      }),
    ) // my-inputs
    dispatch(
      updateTrackDashboard({
        spotify_id: spotifyId,
        id: null,
        user_input_id: null,
      }),
    ) // dashboard
    dispatch(
      updateTrackMyLikes({
        spotify_id: spotifyId,
        id: null,
        user_input_id: null,
      }),
    ) // Mylikes
    dispatch(
      updateTrackSearchInput({
        spotify_id: spotifyId,
        id: null,
        user_input_id: null,
      }),
    ) // search inputs
    dispatch(
      updateTrackArtists({
        spotify_id: spotifyId,
        id: null,
        user_input_id: null,
      }),
    ) // artist
    dispatch(
      updateTrackPlaylist({
        spotify_id: spotifyId,
        id: null,
        user_input_id: null,
      }),
    ) // 1 playlist
  }

  const sendTrackToMyLikes = (spotifyId, title, imageUrl, artist) => {
    updateAllTracksLikes(Math.random() * -1, spotifyId, Math.random() * -1)

    return addTrackToMyLikes(spotifyId, title, imageUrl, artist).then((response) => {
      if (response?.userInput) {
        const {userInput} = response
        const {media_text_id, id} = userInput
        updateAllTracksLikes(media_text_id, spotifyId, id)
        //retornar por si se ocupa en likeButton(player)
        return {
          userInput,
        }
      }
    })
  }

  const deleteTrackFromMyLikes = (spotifyId, userInput, title) => {
    updateAllTracksDisLikes(spotifyId)

    return deleteTrackToMyLikes(userInput).then((response) => {
      if (response) {
        updateAllTracksDisLikes(spotifyId)
        return true
      }
    })
  }

  return {
    sendTrackToMyLikes,
    deleteTrackFromMyLikes,
  }
}

export default useLikeTracks
