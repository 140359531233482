import React from 'react'
import useBooleanToggler from '../../../hooks/useBooleanToggler'
import Modal from '../../Modal/Modal'
import IconNewThread from './Icons/IconNewThread.svg'
import NewConversationForm from './NewConversationForm'

const CreateConversationButton = ({setActiveConversationFn}) => {
  const {
    isToggled: isToggleConversation,
    toggle: toggleConversation,
  } = useBooleanToggler()

  const createConversationFinish = (id) => {
    setActiveConversationFn(id)
    toggleConversation()
  }

  return (
    <>
      <div
        id="btn-new-thread"
        className="d-flex justify-content-center align-items-center my-4"
        onClick={toggleConversation}>
        <span id="btn-img-new-thread" className="mr-2">
          <img src={IconNewThread} alt="Crear nuevo hilo" />
        </span>
        <span id="btn-title-new-thread">Crear nuevo hilo</span>
      </div>

      {isToggleConversation && (
        <Modal
          modalType="new-thread"
          show={isToggleConversation}
          closeModal={toggleConversation}>
          <NewConversationForm
            createConversationSuccess={createConversationFinish}
          />
        </Modal>
      )}
    </>
  )
}

export default CreateConversationButton
