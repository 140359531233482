import * as React from 'react'
import PropTypes from 'prop-types'
import {useState, useEffect} from 'react'
import styled, {css} from 'styled-components'
import IconNext from './Icons/Next.svg'
import IconPrev from './Icons/Prev.svg'
import './Calendar.css'

const Header = styled.div`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 0.5rem;

  display: flex;
  justify-content: space-between;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  font-weight: normal;
  font-size: 25px;
  color: white;
`

const Button = styled.div`
  cursor: pointer;
`

const Footer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: white;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
`

const Day = styled.div`
  width: calc(300px / 7);
  height: calc(300px / 7);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;

  ${(props) =>
    props.isToday &&
    css`
      border-radius: 50%;
      border: none;
    `}

  ${(props) =>
    props.isSelected &&
    css`
      border-radius: 50%;
      background: linear-gradient(46.35deg, #ff5562 14.22%, #fd9d5d 90.1%);
    `}
`

const Calendar = (props) => {
  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const DAYS_OF_THE_WEEK = ['L', 'M', 'M', 'J', 'V', 'S', 'D']
  const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]

  const today = new Date()
  const [date, setDate] = useState(today)
  const [day, setDay] = useState(date.getDate())
  const [month, setMonth] = useState(date.getMonth())
  const [year, setYear] = useState(date.getFullYear())
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date))

  useEffect(() => {
    setDay(date.getDate())
    setMonth(date.getMonth())
    setYear(date.getFullYear())
    setStartDay(getStartDayOfMonth(date))
  }, [date])

  function getStartDayOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay()
  }

  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
  }

  const days = isLeapYear ? DAYS_LEAP : DAYS

  const {getDate} = props

  return (
    <div className="calendar__main">
      <Header>
        <Button onClick={() => setDate(new Date(year, month - 1, day))}>
          <img src={IconPrev} alt="" />
        </Button>
        <div>
          {MONTHS[month]} {year}
        </div>
        <Button onClick={() => setDate(new Date(year, month + 1, day))}>
          <img src={IconNext} alt="" />
        </Button>
      </Header>

      <hr style={{border: '1px solid #FFFFFF'}} />

      <div className="calendar__body__container">
        <div className="calendar__body">
          {DAYS_OF_THE_WEEK.map((d) => (
            <Day key={d}>
              <span style={{color: 'white'}}>
                <strong>{d}</strong>
              </span>
            </Day>
          ))}
          {Array(days[month] + (startDay - 1))
            .fill(null)
            .map((_, index) => {
              const d = index - (startDay - 2)
              return (
                <Day
                  key={index}
                  isToday={d === today.getDate()}
                  isSelected={d === day}
                  onClick={() => setDate(new Date(year, month, d))}>
                  {d > 0 ? d : ''}
                </Day>
              )
            })}
        </div>
      </div>

      <hr style={{border: '1px solid #FFFFFF'}} />

      <Footer>
        <div
          id="btn-outline-calendar"
          className="btn-outline-linear-gradient-calendar"
          onClick={() => {
            getDate(date)
          }}>
          <span>Programar</span>
        </div>
      </Footer>
    </div>
  )
}

Calendar.propTypes = {
  getDate: PropTypes.func,
}

export default Calendar
