import Auth from './Auth/messages'
import Chat from './Chat/messages'
import Diccionary from './Diccionary/messages'
import Engrane from './Engrane/messages'
import Flashcard from './Flashcard/messages'
import Mediatext from './Mediatext/messages'
import Payments from './Payments/messages'
import Perfil from './Perfil/messages'
import ShareTrack from './ShareTrack/messages'
import Splash from './Splash/messages'
import Student from './Student/messages'
import Teacher from './Teacher/messages'
import Tutorial from './Tutorial/messages'

// de esta forma se tendra global el provider de mensages
const languages = () => {
  const messagesIngles = {
    ...Auth.en,
    ...Chat.en,
    ...Diccionary.en,
    ...Engrane.en,
    ...Flashcard.en,
    ...Mediatext.en,
    ...Payments.en,
    ...Perfil.en,
    ...ShareTrack.en,
    ...Splash.en,
    ...Student.en,
    ...Teacher.en,
    ...Tutorial.en,
  }

  const messagesSpanish = {
    ...Auth.es,
    ...Chat.es,
    ...Diccionary.es,
    ...Engrane.es,
    ...Flashcard.es,
    ...Mediatext.es,
    ...Payments.es,
    ...Perfil.es,
    ...ShareTrack.es,
    ...Splash.es,
    ...Student.es,
    ...Teacher.es,
    ...Tutorial.es,
  }

  const messagesFrench = {
    ...Auth.fr,
    ...Chat.fr,
    ...Diccionary.fr,
    ...Engrane.fr,
    ...Flashcard.fr,
    ...Mediatext.fr,
    ...Payments.fr,
    ...Perfil.fr,
    ...ShareTrack.fr,
    ...Splash.fr,
    ...Student.fr,
    ...Teacher.fr,
    ...Tutorial.fr,
  }

  const messages = {
    en: messagesIngles,
    es: messagesSpanish,
    fr: messagesFrench,
  }

  return {messages}
}

export default languages
