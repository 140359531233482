import React from 'react'
import styles from './Modal.module.css'
import PropTypes from 'prop-types'
import IconCloseRound from '../Icons/IconCloseRound.svg'
import IconCloseMobil from '../Icons/IconCloseMobil.svg'
import {useRef} from 'react'
import useOnClickOutside from '../../hooks/useClickOutside'
import usePlatform from '../../hooks/usePlatform'
import CreatePortalModal from './CreatePortalModal'
import useKeyDownEvent from '../../hooks/useKeyDownEvent'

const Modal = ({
  show,
  modalType,
  children,
  closeModal,
  closeRound,
  closeClickOutside,
  isShowCloseButton = true,
  closePressEsc = false,
}) => {
  const {isDeviseMobile} = usePlatform()

  const keyPressEsc = (event) => {
    if (closePressEsc)
      if (event.key === 'Escape') {
        if (closeModal) closeModal()
      }
  }

  useKeyDownEvent(keyPressEsc)

  const modalForTheComponent = () => {
    if (isDeviseMobile) return styles['modal__full__version__mobile']
    switch (modalType) {
      case 'output':
        return styles['output-modal']
      case 'media-text':
        return styles['media-text-modal']
      case 'modal-playlist':
        return styles['modal-crear-playlist']
      case 'modal-report':
        return styles['modal-report']
      case 'modal-diccionary':
        return styles['modal-diccionary']
      case 'modal-edit-perfil':
        return styles['modal-perfil']
      case 'notification-flashcards':
        return styles['notification-flashcards']
      case 'notification-media-texts':
        return styles['notification-media-texts']
      case 'notification-user-output-corrections':
        return styles['notification-user-output-corrections']
      case 'notification-user-output-comments':
        return styles['notification-user-output-comments']
      case 'modal-de-frecuencia':
        return styles['modal-de-frecuencia']
      case 'output-confirmation':
        return styles['output-confirmation']
      case 'tutorial':
        return styles['modal-tutorial']
      case 'new-thread':
        return styles['modal-new-thread']
      case 'procesar-items':
        return styles['modal-procesar-items']
      case 'nota-agregada':
        return styles['modal-nota-agregada']
      case 'modal-item-comment':
        return styles['modal-item-comment']
      case 'modal-delete-item':
        return styles['modal-delete-item']
      case 'free-dictionary':
        return styles['free-dictionary']
      case 'modal-confirm-delete':
        return styles['modal-confirm-delete']
      case 'modal-define-thread':
        return styles['modal-define-thread']
      case 'modal-share-tracks':
        return styles['modal-share-tracks']
      case 'warning-alert':
        return styles['delete-alert']
      case 'delete-alert':
        return styles['warning-alert']
      case 'alert':
        return styles['alert']
      case 'item-verification':
        return styles['item-verification']
      case 'item-preview-hilo':
        return styles['item-preview-hilo']
      case 'pay-confirmation':
        return styles['pay-confirmation']
      default:
      case 'snippet':
        return styles['snippet']
      case 'modal-galley-images':
        return styles['modal-galley-images']
      case 'modal-tutoriales-outputs-mediatexs':
        return styles['modal-tutoriales-outputs-mediatexs']
      case 'pay-confirmation_preview':
        return styles['pay-confirmation_preview']
      case 'spotify-conect-form':
        return styles['spotify-conect-form']
      case 'calendario_modal':
        return styles['calendario_modal']
      case 'modal__setting__acount':
        return styles['modal__setting__acount']
      case 'modal__full__version__mobile':
        return styles['modal__full__version__mobile']
    }
  }

  const ref = useRef()

  useOnClickOutside(ref, () => closeClickOutside && closeModal())

  if (!show) {
    return null
  }

  const stylesContent = styles['modal-content'] + ' ' + modalForTheComponent()

  const styleClose = isDeviseMobile ? {left: '10px', top: '14px'} : {right: '0'}

  return (
    <CreatePortalModal elementId="modal-root">
      <div className={styles.modal}>
        <div className={styles['modal-dialog']}>
          <div ref={ref} className={stylesContent}>
            {isShowCloseButton && closeModal && (
              <button
                style={{zIndex: '100', position: 'absolute', ...styleClose}}
                className="text-white btn btn-link animacion-growing-up"
                onClick={closeModal}>
                {isDeviseMobile ? (
                  <img src={IconCloseMobil} alt={IconCloseMobil} />
                ) : (
                  <>
                    {closeRound ? (
                      <img src={IconCloseRound} alt={IconCloseRound} />
                    ) : (
                      'close X'
                    )}
                  </>
                )}
              </button>
            )}

            {children}
          </div>
        </div>
      </div>
    </CreatePortalModal>
  )
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeRound: PropTypes.bool,
  closeClickOutside: PropTypes.bool,
  children: PropTypes.element.isRequired,
  modalType: PropTypes.string,
  closeModal: PropTypes.func,
}

Modal.defaulProps = {
  show: false,
  closeRound: false,
  closeClickOutside: false,
}

export default Modal
