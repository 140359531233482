import React, {Fragment, useRef, useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import './Message.css'
import MessageActions from './MessageActions/MessageActions'
import Modal from '../../Modal'
import ConfirmDelete from '../../ConfirmDelete/ConfirmDelete'
import Waveform from '../../Waveform/Waveform'
import '../../Waveform/styles.css'

import IconGoogleTranslate from './Icons/IconGoogleTranslate.svg'
import useLanguages from '../../../hooks/useLanguages'
import {useWorkingIndicator} from '../../../hooks/useBooleanToggler'
import {fullAlert, ALERT_TYPES} from '../../../Services/Alert'
import {traductorBody} from '../../../lib/coco/DiccionaryAPi'
import {deleteMessageById} from '../../../lib/coco/conversationApi'
import {StatusApi} from '../../../lib/coco/helperData'
import {goToScrollIntoView, isConversationResolved} from '../helpers'
import useConversations from '../../../hooks/useConversations'
import SpinnerCicle from '../../Animaciones/SpinnerCircle'

const MessageSource = ({url}) => {
  const [urlImage, setUrlImage] = useState(url)
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    setUrlImage(url)
  }, [url])

  const onError = () => {
    setUrlImage(null)
    setTimeout(() => {
      setDate(new Date())
      setUrlImage(url)
    }, 1000)
  }

  return (
    <Fragment>
      {urlImage?.length > 0 ? (
        <img
          loading="lazy"
          src={`${urlImage}?${date}`}
          alt={`...`}
          className="message-image-chat"
          onError={onError}
        />
      ) : (
        <div className="message-image-loading">
          <SpinnerCicle />
        </div>
      )}
    </Fragment>
  )
}

const Message = ({
  message,
  isCurrentUser,
  messageStyle,
  messageCitadoStyle,
  showGalleyImages,
  autoFocusMessageInput,
}) => {
  const {nativeUserLanguage, activeUserLanguage} = useLanguages()
  const {text, image, audio_msg, created_format} = message
  const [translatedMessage, setTranslatedMessage] = useState('')
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false)
  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const {
    isWorking: isDeleteWorking,
    startWork: startDeleteWork,
    finishWork: finishDeleteWork,
  } = useWorkingIndicator()
  const iconsRef = useRef()

  const {setCitedMessage, conversationActive} = useConversations()

  const showMessage = () => {
    if (image?.url) {
      return (
        <div onClick={() => showGalleyImages(message)} className="click-me">
          <MessageSource url={image.url} />
        </div>
      )
    } else if (audio_msg?.url) {
      return (
        <div className={`${messageStyle}`}>
          <Waveform url={audio_msg.url} />
        </div>
      )
    } else if (text !== null) {
      return (
        <div className={`${messageStyle}`}>
          {message?.cited_message?.id && (
            <p
              title="Ir al mensaje"
              onClick={() => goToScrollIntoView(message?.cited_message?.id)}
              className={messageCitadoStyle}>
              {message?.cited_message?.text}
            </p>
          )}
          <p className="text-content-message">{text}</p>
          {translatedMessage?.length > 0 && (
            <p className="translated-message">
              <img
                src={IconGoogleTranslate}
                alt="Google translate"
                className="mr-2"
              />
              {translatedMessage}
            </p>
          )}

          <span className="text-message-created">{created_format}</span>
        </div>
      )
    }
  }

  const showIcons = () => (iconsRef.current.style.visibility = 'visible')
  const hideIcons = () => (iconsRef.current.style.visibility = 'hidden')

  const deleteMessage = () => setModalConfirmDelete(true)

  const confirmDelete = (confirmation) => {
    if (confirmation) {
      startDeleteWork()
      deleteMessageById(message.id).then((response) => {
        finishDeleteWork()
        if (response.status === StatusApi.OK) {
          fullAlert(ALERT_TYPES.SUCCESS, `El item se ha eliminado exitosamente`)
          setModalConfirmDelete(false)
        } else {
          fullAlert(ALERT_TYPES.ERROR, `Error: ${response.message}`)
          setModalConfirmDelete(false)
        }
      })
    } else {
      setModalConfirmDelete(false)
    }
  }

  // Traducir
  const translateMessage = () => {
    if (translatedMessage === '') {
      startWork()
      traductorBody(
        message.text,
        activeUserLanguage.unicode,
        nativeUserLanguage.unicode,
      ).then((response) => {
        finishWork()
        if (response.translation) {
          setTranslatedMessage(response.translation)
        } else {
          fullAlert(ALERT_TYPES.ERROR, `Error: ${response.message}`)
        }
      })
    }
  }

  const messageOrientation = !isCurrentUser ? 'flex-row' : 'flex-row-reverse'

  const showActionTranslate = () => {
    if (
      image?.url === null &&
      audio_msg?.url === null &&
      text !== null &&
      translatedMessage?.length <= 0
    ) {
      return true
    }

    return false
  }

  return (
    <>
      {/*flex-row-reverse*/}
      <div
        className={`d-flex justify-content-start ${messageOrientation} align-items-center`}
        onMouseOver={showIcons}
        onMouseOut={hideIcons}>
        <div
          className={`d-flex align-items-start justify-content-center flex-column`}>
          {showMessage()}
        </div>

        <div
          ref={iconsRef}
          className="d-inline-block"
          style={{visibility: 'hidden'}}>
          <MessageActions
            direction="left"
            showActionTranslate={showActionTranslate()}
            deleteMessage={deleteMessage}
            translateMessage={translateMessage}
            setCitedMessage={() => setCitedMessage(message)}
            isConversationResolved={isConversationResolved(conversationActive)}
            isCurrentUser={isCurrentUser}
            isWorking={isWorking}
            autoFocusMessageInput={autoFocusMessageInput}
          />
        </div>
      </div>

      {modalConfirmDelete && (
        <Modal
          show={modalConfirmDelete}
          modalType="modal-confirm-delete"
          closeModal={() => setModalConfirmDelete(false)}>
          <ConfirmDelete
            confirmDelete={confirmDelete}
            message="¿Quieres eliminar este mensaje?"
            isDeleteWorking={isDeleteWorking}
          />
        </Modal>
      )}
    </>
  )
}

Message.propTypes = {
  message: PropTypes.object.isRequired,
}

export default Message
