import {
  CHANGE_SCREEN,
  GET_PERFIL_INFO,
  FILTER_FLASHCARD,
  COMMENT_OUTPUTS,
  EDIT_PERFIL,
  CREATE_PERFIL,
  EDIT_AVATAR,
  CHANGE_PASS,
} from './actionTypes'
const initialStore = {
  screen: 'flashcard',
  screens: ['actividad', 'progresos', 'outputs', 'flashcards'],
  index_select: 2,
  perfil_info: {},
  comentario: {},
  my_perfil: {},
}

export default function (state = initialStore, action) {
  switch (action.type) {
    case CHANGE_SCREEN: {
      return {
        ...state,
        index_select: action.index,
      }
    }
    case GET_PERFIL_INFO: {
      return {
        ...state,
        perfil_info: action.perfil_info,
      }
    }
    case FILTER_FLASHCARD: {
      let nuevo_perfil_info = state.perfil_info
      const result = nuevo_perfil_info.filter(
        (item) => item.title.toUpperCase() !== action.param.toUpperCase(),
      )
      return {
        ...state,
        perfil_info: result,
      }
    }
    case COMMENT_OUTPUTS: {
      return {
        ...state,
        comentario: action.comentario,
      }
    }
    case CREATE_PERFIL: {
      return {
        ...state,
        my_perfil: action.my_perfil,
      }
    }
    case EDIT_PERFIL: {
      return {
        ...state,
        my_perfil: action.my_perfil,
      }
    }
    case EDIT_AVATAR: {
      return {
        ...state,
        my_perfil: action.my_perfil,
      }
    }
    case CHANGE_PASS: {
      return {
        ...state,
        my_perfil: action.my_perfil,
      }
    }
    default:
      return state
  }
}
