import React, {useState /*useEffect*/} from 'react'
import './AudioRecorder.css'
import AudioAnalyser from 'react-audio-analyser'
import {createSource} from '../../../../lib/coco/conversationApi'
import {useWorkingIndicator} from '../../../../hooks/useBooleanToggler'
import SpinnerCicle from '../../../Animaciones/SpinnerCircle'

import WhiteMicro from '../Icons/WhiteMicro.svg'
import MicroAnimated from '../Icons/MicroAnimated.gif'

const AudioRecorder = ({
  conversation_id,
  handler,
  widthAudio = '40px',
  style = {},
}) => {
  const [stat, setStat] = useState()
  const [audiosrc] = useState()
  const {isWorking, startWork, finishWork} = useWorkingIndicator()

  const controlAudio = (status) => {
    setStat(status)
  }

  const audioThreadsHandler = (blob) => {
    let data = new FormData()
    data.append('audio_msg', blob)
    data.append('conversation_id', conversation_id)
    data.append('text', 'Audio Recording')
    // create audio
    startWork()
    createSource(data).then(() => finishWork())
  }

  const audioOutputHandler = (blob) => {
    if (handler) {
      handler(blob)
    }
  }

  const audioProps = {
    status: stat,
    audioType: 'audio/webm',
    audioSrc: audiosrc,
    timeslice: 1000,
    startCallback: () => {
      // start
    },
    stopCallback: (blob) => {
      if (conversation_id) {
        audioThreadsHandler(blob)
      } else {
        audioOutputHandler(blob)
      }
    },
    width: 80,
    height: 30,
    backgroundColor: 'green',
  }

  const isRecording = () => {
    if (stat === 'recording') {
      return true
    }
  }

  return (
    <div style={style}>
      {isWorking ? (
        <SpinnerCicle
          className="spiner-send-message-audio-form"
          //styles={{ width: "30px", marginRight: "15px" }}
        />
      ) : (
        <AudioAnalyser {...audioProps}>
          <button
            className="btn-send-message-audio-form"
            style={{borderRadius: '50%'}}
            onClick={() => controlAudio(isRecording() ? 'inactive' : 'recording')}>
            {isRecording() ? (
              <img
                src={MicroAnimated}
                style={{width: widthAudio, height: widthAudio}}
                alt="pause"
              />
            ) : (
              <img
                src={WhiteMicro}
                alt="microphone"
                style={{width: widthAudio, height: widthAudio}}
              />
            )}
          </button>
        </AudioAnalyser>
      )}
    </div>
  )
}

export default AudioRecorder
