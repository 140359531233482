import {SET_ALL_LANGUAGES, SET_LANGUAGE_ID_ACTIVE} from './languagesTypes'

const DEFAULT_LANGUAGES = [
  {
    id: 1,
    name: 'Inglés',
    unicode: 'en',
  },
  {
    id: 2,
    name: 'Español',
    unicode: 'es',
  },
  {
    id: 3,
    name: 'Francés',
    unicode: 'fr',
  },
]

const languagesInitialState = {
  languages: [...DEFAULT_LANGUAGES],
  languageIdActive: DEFAULT_LANGUAGES[1].id,
  activeUserLanguage: {},
  nativeUserLanguage: {},
}

const languagesReducer = (state = languagesInitialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE_ID_ACTIVE:
      return {
        ...state,
        languageIdActive: action.id,
      }

    case SET_ALL_LANGUAGES:
      const {
        languages = [],
        activeUserLanguage,
        nativeUserLanguage,
        interfaceLanguage = 'es',
      } = action

      let findIdLanguage = null
      if (languages?.length > 0) {
        findIdLanguage = languages.find((l) => l.unicode === interfaceLanguage)
      }

      return {
        ...state,
        loading: false,
        languages: languages.length > 0 ? languages : DEFAULT_LANGUAGES,
        activeUserLanguage,
        nativeUserLanguage,
        languageIdActive: findIdLanguage?.id ? findIdLanguage.id : 2,
      }

    default:
      return state
  }
}

export default languagesReducer
