import React, {useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import './Topbar.css'
import NotificationsMenu from '../../Notifications/NotificationsMenu.jsx'
import SearchBar from '../../MediaText/SearchBar/SearchBar'
import Drawer from './Drawer'
import misCosasIcon from './Icons/MisCosas.svg'
import SelectDomainsTopBar from '../../Shared/SelectDomainsTopBar'
import {FormattedMessage} from 'react-intl'
import TeamIcon from './Icons/Team.svg'
import ComunidadIcon from './Icons/comunidad.svg'
import LogoCoquito from './Icons/LogoCoquito.svg'
import useUser from '../../../hooks/useUser'
import {isEmailExist} from './utils/grantEmail'
import NetworksAlert from '../../Alerts/NetworksAlert'
import InviteUser from './InviteUser'

const Topbar = ({isToggledLeftbar, platformState, isKeyBoardActive}) => {
  const {isDeviseDesktop, isDeviseMobile} = platformState
  const [misCosas, setMisCosas] = useState(false)
  const {email} = useUser()
  const grants = isEmailExist(email)
  const cerrarMisCosas = () => {
    setMisCosas(false)
  }

  const history = useHistory()

  const isPathnameSearchMediatext = () => history?.location.pathname === '/search'

  const validatePathname = () => {
    if (!isPathnameSearchMediatext()) {
      history.push('/search')
    }
  }

  const hiddenControlsInMobile = () => {
    return isPathnameSearchMediatext() && isDeviseMobile && isKeyBoardActive
  }

  return (
    <div
      className={`topbar__nav ${!isToggledLeftbar ? 'topbar__nav__min' : ''} ${
        !isDeviseDesktop ? 'topbar__nav__mobile' : ''
      }`}>
      <div className="topbard-content-left">
        {!isToggledLeftbar && (
          <Link to="/dashboard">
            <img
              className="topbar__nav__min__logo"
              src={LogoCoquito}
              alt={LogoCoquito}
            />
          </Link>
        )}

        <SearchBar
          isDeviseMobile={isDeviseMobile}
          isPathnameSearchMediatext={isPathnameSearchMediatext}
          validatePathname={validatePathname}
        />
        {!isDeviseMobile && <SelectDomainsTopBar />}
      </div>

      {!hiddenControlsInMobile() && (
        <div className="topbar-nav-controls">
          <NetworksAlert />
          {!isDeviseMobile && grants && (
            <>
              <div id="font-icon-community">
                <Link
                  id="link-community"
                  className="d-flex align-items-center"
                  to="/results">
                  <img src={TeamIcon} alt="Team" />
                  <span>
                    <FormattedMessage id={'topbar.team'} defaultMessage={'Equipo'} />
                  </span>
                </Link>
              </div>
              <InviteUser />
            </>
          )}

          {grants && (
            <div id="font-icon-community" className="comunity-target">
              <Link
                id="link-community"
                className="d-flex align-items-center"
                to="/community">
                <img src={ComunidadIcon} alt={ComunidadIcon} />
                <span>
                  <FormattedMessage
                    id={'topbar.community'}
                    defaultMessage={'Comunidad'}
                  />
                </span>
              </Link>
            </div>
          )}

          <div className="mr-3">
            <NotificationsMenu />
          </div>
          <div id="font-icon-menu" className="cursor-pointer">
            <img src={misCosasIcon} alt="" onClick={() => setMisCosas(true)} />
          </div>
        </div>
      )}

      <Drawer isDrawerVisible={misCosas} cerrar={cerrarMisCosas} />
    </div>
  )
}

export default Topbar
