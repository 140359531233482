import {marckRealyTrack, replaceTrackFromLike} from '../helpers'
import {
  FETCH_DASHBOARD_REQUEST_ERROR,
  FETCH_DASHBOARD_REQUEST_FINISH,
  FETCH_DASHBOARD_REQUEST_START,
  UPDATE_TRACK_DASHBOARD_FROM_LIST,
  CHECK_TRACK_STATE_REALY,
} from './dashboardTypes'

const dashboardInitialState = {
  data: {
    LearningTracks: [],
    recommendedTracks: [],
    sharedTracks: [],
    recommendedArtists: [],
    inputTexts: [],
  },
  loading: false,
  error: {
    message: '',
  },
}

const dashboardReducer = (state = dashboardInitialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_REQUEST_START:
      return {
        ...state,
        loading: true,
        error: {message: ''},
      }

    case FETCH_DASHBOARD_REQUEST_FINISH:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }

    case FETCH_DASHBOARD_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case UPDATE_TRACK_DASHBOARD_FROM_LIST:
      const {
        LearningTracks,
        recommendedTracks,
        recommendedArtists,
        sharedTracks,
      } = state.data

      return {
        ...state,
        data: {
          LearningTracks: replaceTrackFromLike(action.payload.track, LearningTracks),
          recommendedTracks: replaceTrackFromLike(
            action.payload.track,
            recommendedTracks,
          ),
          sharedTracks: replaceTrackFromLike(action.payload.track, sharedTracks),
          recommendedArtists,
        },
      }

    case CHECK_TRACK_STATE_REALY:
      const {sharedTracks: sharedTracksState} = state.data

      const sharedTracksMapped = marckRealyTrack(sharedTracksState, action.spotifyId)

      return {
        ...state,
        data: {
          ...state.data,
          sharedTracks: sharedTracksMapped,
        },
      }

    default:
      return state
  }
}

export default dashboardReducer
