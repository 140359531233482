import React from 'react'
import {useIntl} from 'react-intl'
import IconPhrasesSearch from './Icons/IconPhrasesSearch.svg'

const SearchDictionary = ({onChangeText, getPhrases, value}) => {
  const intl = useIntl()
  return (
    <div className="search-diccionary-container">
      <img
        className="search-diccionary-icon"
        src={IconPhrasesSearch}
        alt={IconPhrasesSearch}
      />

      <input
        className="search-diccionary-input"
        placeholder={intl.formatMessage({
          id: 'diccionary.write-word',
          defaultMessage: 'Write a word...',
        })}
        value={value}
        onChange={({target}) => onChangeText(target.value)}
        onKeyUp={(event) => {
          if (event.keyCode === 13) {
            getPhrases(event.target.value)
          }
        }}
      />
    </div>
  )
}

export default SearchDictionary
