import {
  FETCH_NOTIFICATIONS_REQUEST_ERROR,
  FETCH_NOTIFICATIONS_REQUEST_START,
  FETCH_NOTIFICATIONS_REQUEST_FINISH,
  CHECK_NOTIFICATION_READED,
} from './notificationsTypes'
import {getNotifications} from '../../lib/coco/notificationsApi'

const fetchNotificationsRequestFinish = (notifications) => {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST_FINISH,
    notifications,
  }
}

const fetchNotificationsRequestStart = () => {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST_START,
  }
}

const fetchNotificationsRequestError = (error) => {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST_ERROR,
    error,
  }
}

const fetchNotifications = () => (dispatch) => {
  dispatch(fetchNotificationsRequestStart())
  return getNotifications().then((response) => {
    if (response.notifications) {
      dispatch(fetchNotificationsRequestFinish(response.notifications))
    } else {
      fetchNotificationsRequestError(response.message)
    }
  })
}

const checkReadNotifications = (id) => {
  return {
    type: CHECK_NOTIFICATION_READED,
    id,
  }
}

export {fetchNotifications, fetchNotificationsRequestFinish, checkReadNotifications}
