export const RECEIVED_CONVERSATION = 'RECEIVED_CONVERSATION'
export const RECEIVED_CONVERSATIONS = 'RECEIVED_CONVERSATIONS'
export const RECEIVED_MESSAGE = 'RECEIVED_MESSAGE'

export const FETCH_CONVERSATIONS = 'FETCH_CONVERSATIONS'
export const FETCH_CONVERSATIONS_SUCCESS = 'FETCH_CONVERSATIONS_SUCCESS'
export const FETCH_CONVERSATIONS_FAILURE = 'FETCH_CONVERSATIONS_FAILURE'

export const CONVERSATIONS_CONNECTION_OPENING = 'CONVERSATIONS_CONNECTION_OPENING'
export const CONVERSATIONS_CONNECTION_SUBSCRIBING =
  'CONVERSATIONS_CONNECTION_SUBSCRIBING'
export const CONVERSATIONS_CONNECTION_READY = 'CONVERSATIONS_CONNECTION_READY'

export const WS_CONVERSATIONS_CONNECT = 'WS_CONVERSATIONS_CONNECT'
export const WS_CONVERSATIONS_SUBSCRIBE = 'WS_CONVERSATIONS_SUBSCRIBE'
export const WS_CONVERSATIONS_RECEIVED_DATA = 'WS_CONVERSATIONS_RECEIVED_DATA'
export const RECEIVED_CONVERSATION_DATA = 'RECEIVED_CONVERSATION_DATA'

export const WS_CONVERSATIONS_TYPING_START = 'WS_CONVERSATIONS_TYPING_START'
export const WS_CONVERSATIONS_TYPING_STOP = 'WS_CONVERSATIONS_TYPING_STOP'

export const WS_SEND_CHAT_MESSAGE = 'WS_SEND_CHAT_MESSAGE'
export const WS_CREATE_CONVERSATION = 'WS_CREATE_CONVERSATION'

export const SET_ACTIVE_CONVERSATION_ID = 'SET_ACTIVE_CONVERSATION_ID'
export const SET_IS_MINICHAT_SELECTION_ACTIVE = 'SET_IS_MINICHAT_SELECTION_ACTIVE'
export const SET_MINI_CHAT_ACTIVE = 'SET_MINI_CHAT_ACTIVE'
export const SET_HILOS_ACTIVE = 'SET_HILOS_ACTIVE'
export const SET_MESSAGE_CITADO = 'SET_MESSAGE_CITADO'
export const REMOVE_MESSAGE_CITADO = 'REMOVE_MESSAGE_CITADO'
