import React from 'react'
import './FlashCards.css'
import fondoLlenarElEspacioSelect from '../Icons/fondoLlenarElEspacioSelect.svg'
import fondoTraduccionSelect from '../Icons/fondoTraduccionSelect.svg'
import {FormattedMessage} from 'react-intl'

const TRADUCTION = 'TRADUCTION'
const SPACE = 'SPACE'

const SelectFlashcardType = ({cambiarVentana}) => {
  let llenarElEspacioStyle = {
    backgroundImage: `url(${fondoLlenarElEspacioSelect})`,
  }
  let traduccionStyle = {backgroundImage: `url(${fondoTraduccionSelect})`}

  return (
    <React.Fragment>
      <h3 className="my-3 text-center text-secondary orange-text-gradient">
        <FormattedMessage
          id={'flashcard.phrase'}
          defaultMessage={'Agrega esta frase a tus flashcards'}
        />
      </h3>
      <br />
      <br />
      <h6 className="text-center flashcard-option-subtitle">
        <FormattedMessage
          id={'flashcard.choice'}
          defaultMessage={'Selecciona una Opcion:'}
        />
      </h6>
      <br />
      <div className="flashcard-options-container text-center">
        <div
          style={traduccionStyle}
          className="flashcard-option animacion-growing-up"
          onClick={() => cambiarVentana(TRADUCTION)}>
          <span className="orange-text-gradient">
            <FormattedMessage
              id={'flashcard.translate'}
              defaultMessage={'Traducir frase'}
            />
          </span>
        </div>
        <div
          style={llenarElEspacioStyle}
          className="flashcard-option animacion-growing-up"
          onClick={() => cambiarVentana(SPACE)}>
          <span className="orange-text-gradient">
            <FormattedMessage
              id={'flashcard.fill'}
              defaultMessage={'Llenar el espacio'}
            />
          </span>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </React.Fragment>
  )
}

export default SelectFlashcardType
