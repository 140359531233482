export const TOKEN = localStorage.getItem('TOKEN_LOCAL_STORAGE')

let ApiType = ''

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  //production
  ApiType = 'https://coco-sprint-3.herokuapp.com'
} else {
  //development
  ApiType = 'http://localhost:3001'
}

export const API_URL = ApiType
