export default {
  en: {
    'chat.title': 'Chat',
    'chat.search-message': 'Search messages...',
    'chat.new-message': 'New message',
    'chat.new-group': 'Create group',
    'chat.new-message-description': 'Who do you want to chat with?',
    'chat.new-group-description': 'Who is going to be part of this group?',
    'chat.search-people': 'Search person...',
    'chat.user-add': 'Aggregate',
    'chat.user-next': 'Next',
    'chat.user-name-group': 'Group name',
    'chat.user-add-image': 'Add Image',
    'chat.create-group': 'Create',
    'chat.create-group-loading': 'Creating...',
    'chat.new-participant-add': 'Add',
    'chat.new-participant-add-loading': 'Adding...',
    'chat.whrite-message': 'Write messages...',
    'chat.is-typing': 'is typing',
    'chat.is-teacher': 'Teacher',
  },
  es: {
    'chat.title': 'Chat',
    'chat.search-message': 'Buscar mensajes...',
    'chat.new-message': 'Nuevo mensaje',
    'chat.new-group': 'Crear grupo',
    'chat.new-message-description': '¿Con quién quieres chatear?',
    'chat.new-group-description': '¿Quién va a formar parte de este grupo?',
    'chat.search-people': 'Buscar persona...',
    'chat.user-add': 'Agregado',
    'chat.user-next': 'Siguiente',
    'chat.user-name-group': 'Nombre del grupo',
    'chat.user-add-image': 'Agregar imagen',
    'chat.create-group': 'Crear',
    'chat.create-group-loading': 'Creando...',
    'chat.new-participant-add': 'Agregar',
    'chat.new-participant-add-loading': 'Agregando...',
    'chat.whrite-message': 'Escribir mensajes...',
    'chat.is-typing': 'esta escribiendo',
    'chat.is-teacher': 'Profesor',
  },

  fr: {
    'chat.title': 'Discuter',
    'chat.search-message': 'Rechercher des messages...',
    'chat.new-message': 'Nouveau message',
    'chat.new-group': 'Créer un groupe',
    'chat.new-message-description': 'Avec qui voulez-vous discuter?',
    'chat.new-group-description': 'Qui fera partie de ce groupe?',
    'chat.search-people': 'Rechercher une personne...',
    'chat.user-add': 'Agrégat',
    'chat.user-next': 'Suivant',
    'chat.user-name-group': 'Nom de groupe',
    'chat.user-add-image': 'Ajouter une image',
    'chat.create-group': 'Créer',
    'chat.create-group-loading': 'Création...',
    'chat.new-participant-add': 'Ajouter',
    'chat.new-participant-add-loading': 'Ajouter...',
    'chat.whrite-message': 'Rédiger des messages...',
    'chat.is-typing': 'est en train d`écrire',
    'chat.is-teacher': 'professeur',
  },
}
