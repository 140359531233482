import React from 'react'
import './ConversationItem.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {
  getOtherParticipantPersonal,
  isConversationGroup,
  isConversationHilo,
  isConversationPersonal,
  getNamesFromParticipants,
  getConversationImage,
} from '../helpers'
import AvatarParticipant from './AvatarParticipant'
import newGroupIcon from './Icons/newGroup.svg'
import teacherIcon from './Icons/teacher.svg'
import {isReadedConversation} from '../helpers'
import Tooltip from '../../Tooltip/Tooltip'
import {useIntl} from 'react-intl'

const ConversationItem = ({
  conversation,
  setActiveConversation,
  isActive,
  currentUserId,
  className = '',
  style = {},
  isTeacherConversation = false,
}) => {
  const intl = useIntl()
  // const getFirtMessage = () => {
  //   if (conversation?.messages?.length > 0) {
  //     return conversation.messages[conversation.messages.length - 1]
  //   }
  //   return null
  // }

  // const firtMessage = getFirtMessage()

  const usuario = getOtherParticipantPersonal(conversation, currentUserId)

  const isReadConversation = isReadedConversation(conversation, currentUserId)

  return (
    <li
      key={conversation?.id}
      className={`contenedor-hilos-item ${className}`}
      onClick={() => setActiveConversation(conversation?.id)}
      style={style}>
      <div
        className={`contenedor-conversacion-item ${
          isActive ? 'conversation-is-selected' : ''
        }`}>
        {isConversationPersonal(conversation) && usuario && (
          <>
            <div className="conversation-item-personal">
              <div className="conversation-item-personal-avatar">
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    marginRight: '7px',
                    background: isReadConversation ? 'transparent' : '#FF5562',
                  }}
                />
                <AvatarParticipant
                  photoUrl={usuario?.photo_url}
                  title={`${usuario?.name}`}
                />

                <span id="title-conversation">
                  {usuario?.name || usuario?.email}
                </span>

                {isTeacherConversation && (
                  <Tooltip
                    tooltip={intl.formatMessage({
                      id: 'chat.is-teacher',
                      defaultMessage: 'Teacher',
                    })}
                    position="top">
                    <img
                      className="conversation-item-teacher-icon"
                      src={teacherIcon}
                      alt={teacherIcon}
                    />
                  </Tooltip>
                )}
              </div>

              <span className="status-time-hilo">
                {conversation?.updated_format}
              </span>
            </div>
          </>
        )}

        {isConversationGroup(conversation) && (
          <div className="conversation-item-personal">
            <div className="conversation-item-personal-avatar">
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  marginRight: '7px',
                  background: isReadConversation ? 'transparent' : '#FF5562',
                }}
              />
              <AvatarParticipant
                photoUrl={{
                  url: getConversationImage(conversation)
                    ? getConversationImage(conversation)
                    : newGroupIcon,
                }}
                title={`grupos`}
              />

              <span id="title-conversation">
                {conversation?.title
                  ? conversation?.title
                  : getNamesFromParticipants(conversation)}
              </span>
            </div>

            <span className="status-time-hilo">{conversation?.updated_format}</span>
          </div>
        )}

        {isConversationHilo(conversation) && (
          <>
            <div style={{width: '80%'}}>
              <div className="conversation-titles">
                <p
                  id="title-conversation"
                  className={`${
                    conversation.resolved
                      ? 'conversation-resolved'
                      : 'conversation-no-resolved'
                  }`}
                  title={conversation?.title}>
                  {conversation?.title || ''}
                </p>
                {/* {firtMessage && (
                  <p id="first-message">
                    {firtMessage?.text ? firtMessage.text : ''}
                  </p>
                )} */}
              </div>
            </div>
            <div
              style={{width: '20%'}}
              className="d-flex justify-content-end align-items-end flex-column">
              <p className="status-resolved-hilo">
                {conversation?.resolved ? <FontAwesomeIcon icon={faCheck} /> : ''}
              </p>
              <span className="status-time-hilo">
                {conversation?.updated_format}
              </span>
            </div>
          </>
        )}
      </div>
    </li>
  )
}

export default ConversationItem
