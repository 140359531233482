import Axios from 'axios'
import {
  CHANGE_ACTIVE_DOMAIN,
  GET_LANGUAGE_INTERFACE,
  SET_LANGUAGE_INTERFACE,
} from '../../Services/EndPointApi'
import {ApiMessages, errorHandler, getHeaders, StatusApi} from './helperData'

const setLanguage = (lang) => {
  return Axios.put(
    SET_LANGUAGE_INTERFACE,
    {
      user: {
        interface_language: lang,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response.data) {
        return {
          lang: response.data,
        }
      } else {
        return {
          status: StatusApi.NotFound,
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('setLanguage'))
}

const getLanguage = () => {
  return Axios.get(GET_LANGUAGE_INTERFACE, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response.data) {
        return {
          lang: response.data,
        }
      } else {
        return {
          status: StatusApi.NotFound,
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('getLanguage'))
}

const changeDomainId = (domainId) => {
  return Axios.put(
    CHANGE_ACTIVE_DOMAIN,
    {
      domain_id: domainId,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response.data) {
        return {
          status: StatusApi.OK,
        }
      } else {
        return {
          status: StatusApi.NotFound,
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('changeDomainId'))
}

export {setLanguage, getLanguage, changeDomainId}
