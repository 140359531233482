import React, {useState} from 'react'
import './layout.css'
import Topbar from './MenuItems/Topbar'
import Leftbar from './MenuItems/Leftbar'
import PlusButtonModal from '../FlashCards/PlusButton/PlusButtonModal'
import {useDispatch} from 'react-redux'
import {toggleFlashcardModal} from '../../Redux/users/actions'
import useBooleanToggler from '../../hooks/useBooleanToggler'
import usePlatform from '../../hooks/usePlatform'
import FooterSpotifyPlayer from '../Spotify/FooterSpotifyPlayer'
import DiccionaryButtonFloat from '../Diccionary/DiccionaryButtonFloat'
import ButtonsMobileFloat from './MenuItems/ButtonsMobileFloat'
import Modal from '../Modal'
import SpotifyModal from '../Shared/SpotifyModal'
import {useModalSpotifyAuth} from '../../hooks/useSpotifyModalAuth'
import useKeyBoard from '../../hooks/useKeyBoard'
import ButtonMinichatFloat from '../Comunidad/Chat/ButtonMinichatFloat'
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

const LayoutGeneral = ({children}) => {
  const dispatch = useDispatch()
  // const [voiceOn, setVoiceOn] = useState(false)
  const platformState = usePlatform()

  const {isDeviseDesktop} = platformState
  const {isKeyBoardActiveOnMobile} = useKeyBoard()

  const stateModalSpotifyAuth = useModalSpotifyAuth()
  const {getStatus, toggleSpotifyModalAuth} = stateModalSpotifyAuth
  const status = getStatus()

  const [isPlusButtonOpen, setIsPlusButtonOpen] = useState(false)
  const [isAnimationShow, setIsAnimationShow] = useState(false)
  const {isToggled: isToggledLeftbar, toggle: toggleLeftbar} = useBooleanToggler({
    initialValue: true,
  })

  const openPlusButton = () => {
    console.log('aqui')
    dispatch(toggleFlashcardModal(true))
    setIsPlusButtonOpen(true)
    setIsAnimationShow(true)
  }

  const modalTogleClose = () => {
    if (isAnimationShow) {
      setIsAnimationShow(false)
      setTimeout(() => {
        setIsPlusButtonOpen(false)
      }, 950)
    }
  }
  /*
  const commands = [
    {
      command: 'Open Plus',
      callback: () => openPlusButton()
    },
    {
      command: 'clear',
      callback: ({ resetTranscript }) => resetTranscript()
    }
]

const { transcript, resetTranscript } = useSpeechRecognition({ commands })
console.log(transcript)

if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null
}

const listen = () => {
    SpeechRecognition.startListening({ continuous: true })
    setVoiceOn(true)
}

const stopListening = () => {
    SpeechRecognition.stopListening()
    setVoiceOn(false)
}
*/

  return (
    <>
      <div className="layout__general__main">
        {isDeviseDesktop && (
          <Leftbar
            isToggledLeftbar={isToggledLeftbar}
            toggleLeftbar={toggleLeftbar}
            openPlusButton={openPlusButton}
            platformState={platformState}
            stateModalSpotifyAuth={stateModalSpotifyAuth}
          />
        )}

        <main className="layout__general__panelD">
          <Topbar
            isToggledLeftbar={isToggledLeftbar}
            platformState={platformState}
            isKeyBoardActive={isKeyBoardActiveOnMobile}
          />

          <div className="layout__general__main__content">{children}</div>
        </main>
      </div>

      {isPlusButtonOpen && (
        <PlusButtonModal
          isAnimationShow={isAnimationShow}
          onClose={() => modalTogleClose()}
        />
      )}

      <FooterSpotifyPlayer
        isToggledLeftbar={isToggledLeftbar}
        isKeyBoardActive={isKeyBoardActiveOnMobile}
      />

      {isDeviseDesktop ? (
        <>
          <DiccionaryButtonFloat />
          <ButtonMinichatFloat />
          {/*  <div style={{width:"20px", height:"20px", color: "white", background:"red", position: "absolute", bottom: '300px', right: '60px', cursor: 'pointer'}}>
        {!voiceOn ? <button onClick={listen} className="voice-button">Comandos de voz</button> : <button onClick={stopListening} className="voice-button">Turn Off Voice Command</button>}
      </div>*/}
        </>
      ) : (
        <ButtonsMobileFloat
          openPlusButton={openPlusButton}
          stateModalSpotifyAuth={stateModalSpotifyAuth}
          isKeyBoardActive={isKeyBoardActiveOnMobile}
        />
      )}

      {/* modales */}
      {status && (
        <Modal
          modalType="spotify-conect-form"
          show={status}
          closeRound
          closeModal={() => toggleSpotifyModalAuth(false)}
          closeClickOutside>
          <SpotifyModal />
        </Modal>
      )}
    </>
  )
}

export default LayoutGeneral
