const FETCH_SEARCH_REQUEST_START = 'SearchInputs/FETCH_SEARCH_REQUEST_START'
const FETCH_SEARCH_REQUEST_FINISH = 'SearchInputs/FETCH_SEARCH_REQUEST_FINISH'
const FETCH_SEARCH_REQUEST_ERROR = 'SearchInputs/FETCH_SEARCH_REQUEST_ERROR'
const UPDATE_TRACK_SEARCH_FROM_LIST = 'SearchInputs/UPDATE_TRACK_SEARCH_FROM_LIST'
const SET_PARAMS_SEARCH_INPUT = 'SearchInputs/SET_PARAMS_SEARCH_INPUT'

export {
  FETCH_SEARCH_REQUEST_START,
  FETCH_SEARCH_REQUEST_FINISH,
  FETCH_SEARCH_REQUEST_ERROR,
  UPDATE_TRACK_SEARCH_FROM_LIST,
  SET_PARAMS_SEARCH_INPUT,
}
