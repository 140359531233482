import React from 'react'
import './SpinnerBox.css'
import LoadingSpinner from './Icons/loading_spinner.svg'

const SpinnerBox = ({type = 'points', className = '', styleSpinner = {}}) => {
  switch (type) {
    case 'circle':
      return (
        <div
          className={`spinner-border text-success ${className}`}
          style={styleSpinner}
        />
      )
    case 'points':
      return (
        <img
          className={`loading-spinner-box ${className}`}
          style={styleSpinner}
          src={LoadingSpinner}
          alt={LoadingSpinner}
        />
      )

    default:
      break
  }
}

export default SpinnerBox
