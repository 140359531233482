import createRootReducer from './reducers'
import {applyMiddleware, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import {createBrowserHistory} from 'history'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import loggerMiddleware from './loggerMiddleware'
import spotifyPlayerMiddleware from './spotify/middleware/player'
import itemsMiddleware from './Items/middleware'
import presenceMiddleware from './Presence/PresenceMiddleware'
import conversationsMiddleware from './Conversations/ConversationMiddleware'
import {ENV_PROCESS} from '../Services/constants'

export const history = createBrowserHistory()

const isProduction = () => process.env.REACT_APP_ENV === ENV_PROCESS.PRODUCTION

export default function configureStore(preloadedState) {
  const applyMiddlewareBuild = applyMiddleware(
    thunk,
    routerMiddleware(history),
    loggerMiddleware,
    spotifyPlayerMiddleware,
    itemsMiddleware,
    presenceMiddleware,
    conversationsMiddleware,
  )

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    isProduction()
      ? applyMiddlewareBuild
      : composeWithDevTools(applyMiddlewareBuild),
  )

  return store
}
