import React, {Suspense, lazy} from 'react'

import {Redirect, Route, Switch} from 'react-router-dom'
import AlertTrial from '../Components/Payments/Trial/AlertTrial'
import LayoutGeneral from '../Components/Layouts/LayoutGeneral'
import TheadsIndex from '../Components/Comunidad/Chat/TheadsIndex'
import LoadingPage from '../Components/Animaciones/LoadingPage'
import useSuscriptionNotification from '../hooks/useSuscriptionNotification'
import Pronounce from '../Components/Pronounce/Pronounce'

const RoutePrivate = lazy(() => import('../Components/Routes/RoutePrivate'))
const RoutePublic = lazy(() => import('../Components/Routes/RoutePublic'))
const RouteTeacher = lazy(() => import('../Components/Routes/RouteTeacher'))
const Dashboard = lazy(() => import('../Components/Pages/Dashboard'))
const Search = lazy(() => import('../Components/MediaText/SearchMediaText'))
const MisMeGusta = lazy(() => import('../Components/PlayList/MisMeGusta'))
const MisPlaylists = lazy(() => import('../Components/PlayList/MisPlaylists'))
const Playlist = lazy(() => import('../Components/PlayList/Playlist'))
const Principal = lazy(() => import('../Components/Output/Principal'))
const MediaTextPrincipal = lazy(() =>
  import('../Components/MediaText/MediaTextPrincipal'),
)
const SharedTrackRedirect = lazy(() =>
  import('../Components/MediaText/SharedTrackRedirect'),
)
const InputTextContainer = lazy(() =>
  import('../Components/MediaText/InputText/InputTextContainer'),
)
const ArtistPlayList = lazy(() => import('../Components/PlayList/ArtistPlayList'))
const StudyFlashcardPrincipal = lazy(() =>
  import('../Components/FlashCards/StudyFlashcard/StudyFlashcardPrincipal'),
)
const ResponseHistory = lazy(() => import('../Components/Output/ResponseHistory'))
const FlashcardIndex = lazy(() => import('../Components/FlashCards/FlashcardIndex'))
const PalabrasAprendidas = lazy(() =>
  import('../Components/PalabrasAprendidas/PalabrasAprendidas'),
)
const MyInputsStudied = lazy(() => import('../Components/MediaText/MyInputsStudied'))
const Comunidad = lazy(() => import('../Components/Comunidad/Comunidad'))

const Community = lazy(() => import('../Components/Feed/Main'))
const Inbox = lazy(() => import('../Components/Flowboard/Inbox'))
const Results = lazy(() => import('../Components/team/Results'))
const DashboardTeacher = lazy(() =>
  import('../Components/DashboardTeacher/DashboardTeacher'),
)

const AuthRoutes = () => {
  useSuscriptionNotification()
  return (
    <>
      <AlertTrial />
      <LayoutGeneral>
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            <RoutePublic path="/" exact component={Dashboard} />
            <RoutePrivate path="/teacher" exact component={Comunidad} />
            <RoutePrivate path="/community" exact component={Community} />
            <RoutePrivate path="/dashboard" exact component={Dashboard} />
            <RoutePrivate
              path="/conversations/:idConversation/:idMessage"
              exact
              component={TheadsIndex}
            />
            <RoutePrivate path="/conversations" exact component={TheadsIndex} />
            {/* rutas de input */}
            <RoutePrivate
              path="/input-text/:id"
              exact
              component={InputTextContainer}
            />
            <RoutePrivate path="/audio-phrases" exact component={Pronounce} />
            <RoutePrivate path="/input-text" exact component={InputTextContainer} />
            <RoutePrivate path="/media_text" exact component={MediaTextPrincipal} />
            <RoutePrivate
              path="/shared/:spotify_id"
              exact
              component={SharedTrackRedirect}
            />
            <RoutePrivate path="/results" exact component={Results} />
            <RoutePrivate path="/mis-me-gusta" exact component={MisMeGusta} />
            <RoutePrivate path="/mis-playlists" exact component={MisPlaylists} />
            <RoutePrivate path="/playlist/:id" exact component={Playlist} />
            <RoutePrivate path="/my-inputs" exact component={MyInputsStudied} />
            <RoutePrivate path="/search" exact component={Search} />
            <RoutePrivate
              path="/artista/:spotify_id"
              exact
              component={ArtistPlayList}
            />
            <RoutePrivate
              path="/words-learned"
              exact
              component={PalabrasAprendidas}
            />
            {/* rutas de output */}
            <RoutePrivate path="/output" exact component={Principal} />
            <RoutePrivate path="/outputs" exact component={ResponseHistory} />

            {/* rutas de review */}
            <RoutePrivate path="/flashcards" exact component={FlashcardIndex} />
            <RoutePrivate
              path="/study-flashcard"
              exact
              component={StudyFlashcardPrincipal}
            />
            {/* Flowboard */}
            <RoutePrivate path="/inbox" exact component={Inbox} />

            {/* ruta de profesor */}
            <RouteTeacher
              path="/payments-teacher"
              exact
              component={DashboardTeacher}
            />

            {/* si no se encuentra la ruta, llegará a este: */}
            <Route component={() => <Redirect to="/dashboard" />} />
          </Switch>
        </Suspense>
      </LayoutGeneral>
    </>
  )
}

export default AuthRoutes
