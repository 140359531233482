import React, {useState} from 'react'
import './NotaEspecial.css'

const NotaEspecial = ({onClick, side, value}) => {
  const [noteSpecial, setNoteSpecial] = useState(value || '')

  //evento enter
  const eventEnter = (event) => {
    if (event.which === 13) {
      onClick(noteSpecial, side)
    }
  }

  return (
    <div className="contenedor-nota-especial mb-4">
      <textarea
        autoFocus
        className="nota-especial-text"
        placeholder={`Agrega un comentario.`}
        onChange={({target}) => setNoteSpecial(target.value)}
        value={noteSpecial}
        onKeyPress={(event) => eventEnter(event)}
      />
      <div
        className="nota-especial-footer orange-text-gradient"
        onClick={() => onClick(noteSpecial, side)}>
        ¡Listo!
      </div>
    </div>
  )
}

export default NotaEspecial
