import React, {useEffect, useState} from 'react'
import {getUsers} from '../../../lib/coco/itemsApi'
import AvatarParticipant from './AvatarParticipant'
import SearchConversations from './SearchConversations'
import BotonConBordeDegradado from '../../Buttons/BotonConBordeDegradado'
import {useWorkingIndicator} from '../../../hooks/useBooleanToggler'
import {useDebounce} from 'use-debounce'
import {addParticipantsToConversation} from '../../../lib/coco/conversationApi'
import {ALERT_TYPES, fullAlert} from '../../../Services/Alert'
import './AddParticipants.css'
import {isUserWasAdd} from '../helpers'
import {UsersSelected} from './UsersSelected'
import {useIntl} from 'react-intl'

const AddParticipants = ({currentUser, conversation, closeModa}) => {
  const intl = useIntl()
  const [termUser, setTermUser] = useState('')
  const [users, setUsers] = useState([])
  const [usersSelected, setUsersSelected] = useState([])
  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const [value] = useDebounce(termUser, 500)

  const isUserInclude = (user) => {
    const {participating_users} = conversation
    const isUserInclude = participating_users.find((u) => u?.id === user?.user_id)
    if (isUserInclude?.id) {
      return true
    } else {
      return false
    }
  }

  const agregarAConversacion = () => {
    if (usersSelected?.length > 0) {
      const idsUser = usersSelected.map((user) => user.user_id)
      startWork()
      addParticipantsToConversation(conversation?.id, idsUser).then((response) => {
        finishWork()
        if (response.data) {
          closeModa()
        } else {
          fullAlert(
            ALERT_TYPES.ERROR,
            `Error: ${response.status}, ${response.message}`,
          )
        }
      })
    }
  }

  useEffect(() => {
    const getUsersAPi = () => {
      getUsers(termUser).then((response) => {
        if (response.users) {
          const users = response.users.filter(
            (user) => user.user_id !== currentUser?.id,
          )
          setUsers(users.length > 0 ? users : [])
        }
      })
    }

    getUsersAPi()
    // eslint-disable-next-line
  }, [value, currentUser])

  const addUser = (user) => {
    if (!isUserInclude(user)) {
      if (!isUserWasAdd(usersSelected, user?.user_id)) {
        setUsersSelected([...usersSelected, user])
      }
    }
  }

  const removerUser = (user) => {
    setUsersSelected(usersSelected.filter((u) => u?.user_id !== user?.user_id))
  }

  return (
    <div className="add-participants-main">
      <UsersSelected users={usersSelected} removerUser={removerUser} />

      <SearchConversations
        onChange={setTermUser}
        value={termUser}
        placeholder={intl.formatMessage({
          id: 'chat.search-people',
          defaultMessage: 'Search person...',
        })}
      />
      <div className="scroll-coco participantes-encontrados">
        {users?.length > 0 &&
          users.map((user) => (
            <div key={user?.user_id} className="contenedor-conversacion-item">
              <div
                className="conversation-item-personal click-me"
                onClick={() => addUser(user)}>
                <div className="conversation-item-personal-avatar">
                  <AvatarParticipant
                    photoUrl={user?.photo}
                    title={user?.name || user?.email || 'User'}
                  />
                  <span id="title-conversation">
                    {user?.name || user?.email || 'User'}
                  </span>
                </div>
              </div>

              {(isUserInclude(user) ||
                isUserWasAdd(usersSelected, user?.user_id)) && (
                <span className="status-usuario-agregado" style={{width: '50px'}}>
                  {intl.formatMessage({
                    id: 'chat.user-add',
                    defaultMessage: 'Aggregate',
                  })}
                </span>
              )}
            </div>
          ))}
      </div>

      {usersSelected?.length > 0 && (
        <div className="btn-agregar-participantes">
          <BotonConBordeDegradado
            function={agregarAConversacion}
            width={208}
            height={37}
            disabled={isWorking}>
            <>{`${
              isWorking
                ? intl.formatMessage({
                    id: 'chat.new-participant-add-loading',
                    defaultMessage: 'Adding...',
                  })
                : intl.formatMessage({
                    id: 'chat.new-participant-add',
                    defaultMessage: 'Add',
                  })
            }`}</>
            {/* chat.new-participant-add */}
          </BotonConBordeDegradado>
        </div>
      )}
    </div>
  )
}

export default AddParticipants
