import * as actions from '../actions/actionTypes'

var initialState = {
  track_window: {
    current_track: {
      name: 'Titulo...',
      id: '',
      uri: '',
      artists: [{name: 'Artista...'}],
      album: {
        images: [{url: null}],
      },
    },
    next_tracks: {},
    previous_tracks: {},
  },
  context: {
    uri: '',
    metadata: {context_description: ''},
  },
  paused: true,
  shuffle: true,
  repeat_mode: 2,
  position: 0,
  duration: 0,
  timestamp: 0,
  device_id: '',
  is_active: false,
  errors: {
    init: false,
    auth: false,
    account: false,
    playback: false,
    retries: 0,
  },
  loading: false,
  seeking: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CAMBIAR_PROGRESO_MUSICA_MILISEGUNDOS: {
      return {
        ...state,
        position: action.progress_ms,
      }
    }

    case actions.SET_PLAYER_STATE: {
      return {
        ...state,
        ...action.player,
        is_active: true,
        errors: {
          init: false,
          auth: false,
          account: false,
          playback: false,
          retries: 0,
        },
      }
    }
    case actions.PLAYER_ERROR: {
      return {
        ...state,
        is_active: false,
      }
    }
    case actions.PLAYER_INIT_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          init: true,
        },
        is_active: false,
      }
    }
    case actions.PLAYER_INIT_RETRY: {
      return {
        ...state,
        errors: {
          ...state.errors,
          init: false,
          ...state.errors.retries++,
        },
        is_active: false,
      }
    }
    case actions.PLAYER_AUTH_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          auth: true,
        },
        is_active: false,
      }
    }
    case actions.PLAYER_AUTH_RETRY: {
      return {
        ...state,
        errors: {
          ...state.errors,
          auth: false,
          ...state.errors.retries++,
        },
        is_active: false,
      }
    }
    case actions.PLAYER_ACCT_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          account: true,
        },
        is_active: false,
      }
    }
    case actions.PLAYBACK_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          playback: true,
        },
        is_active: false,
      }
    }
    case actions.TRACK_CHANGED: {
      return {
        ...state,
      }
    }
    case actions.PLAYER_LOADING: {
      return {
        ...state,
        loading: action.is_loading,
      }
    }
    case actions.PLAYER_READY: {
      return {
        ...state,
        loading: false,
      }
    }
    case actions.TRANSFER_PLAYBACK_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case actions.TOGGLE_PLAYBACK_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case actions.PLAY_TRACK_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case actions.PLAY_CONTEXT_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case actions.SKIP_TRACK_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case actions.PLAYBACK_SEEK_SUCCESS: {
      return {
        ...state,
        loading: false,
        seeking: false,
      }
    }
    case actions.TOGGLE_REPEAT_SUCCESS: {
      return {
        ...state,
        repeat_state: action.repeat_mode,
      }
    }
    default:
      return state
  }
}
