import axios from 'axios'
import {API_URL, TOKEN} from './config'

export const getSpotifyAuthUrl = () => {
  return axios({
    method: 'get',
    url: API_URL + '/auth/spotify/link',
    headers: {
      Accept: 'application/json',
      Authorization: TOKEN,
    },
  })
}

export const getAccessToken = () => {
  return axios({
    method: 'get',
    url: API_URL + '/spotify/token',
    headers: {
      Accept: 'application/json',
      Authorization: TOKEN,
    },
  })
}

export const getDevices = () => {
  return axios({
    method: 'get',
    url: API_URL + '/spotify/player/devices',
    headers: {Authorization: TOKEN},
  })
}

export const transferPlayback = (deviceId) => {
  return axios({
    method: 'get',
    url: API_URL + `/spotify/player/transfer_playback?device_id=${deviceId}`,
    headers: {Authorization: TOKEN},
  })
}

export const playTrack = (track_uri, position = null) => {
  const route =
    position === null
      ? `/spotify/player/play/track?track_uri=${track_uri}`
      : `/spotify/player/play/track?track_uri=${track_uri}&position=${position}`
  return axios({
    method: 'get',
    url: API_URL + route,
    headers: {Authorization: TOKEN},
  })
}

export const playPlaylistTrack = (playlist_uri, offset) => {
  return axios({
    method: 'get',
    url:
      API_URL +
      `/spotify/player/play/playlist?playlist_uri=${playlist_uri}&offset=${offset}`,
    headers: {Authorization: TOKEN},
  })
}

export const playContext = (context_uri, offset = 0) => {
  return axios({
    method: 'get',
    url:
      API_URL +
      `/spotify/player/play/context?context_uri=${context_uri}&offset=${offset}`,
    headers: {Authorization: TOKEN},
  })
}

export const pausePlayback = () => {
  return axios({
    method: 'get',
    url: API_URL + '/spotify/player/pause',
    headers: {Authorization: TOKEN},
  })
}

export const resumePlayback = (context_uri = null, position = null) => {
  return axios({
    method: 'get',
    url:
      API_URL +
      `/spotify/player/resume?context_uri=${context_uri}&position=${position}`,
    //data: { context_uri: context_uri, device_id: deviceId, position: position },
    headers: {Authorization: TOKEN},
  })
}

export const playbackSeek = (position_ms, device_id = null) => {
  return axios({
    method: 'post',
    url: API_URL + '/spotify/player/playback_seek',
    data:
      device_id === null
        ? {position_ms: position_ms}
        : {device_id: device_id, position_ms: position_ms},
    headers: {Authorization: TOKEN},
  })
}

export const skipToNextTrack = () => {
  return axios({
    method: 'get',
    url: API_URL + '/spotify/player/next',
    headers: {Authorization: TOKEN},
  })
}

export const skipToLastTrack = () => {
  return axios({
    method: 'get',
    url: API_URL + '/spotify/player/last',
    headers: {Authorization: TOKEN},
  })
}

export const shufflePlayback = () => {
  return axios({
    method: 'get',
    url: API_URL + '/spotify/player/shuffle',
    headers: {Authorization: TOKEN},
  })
}

export const setPlaybackRepeat = (repeat_mode = 'track') => {
  return axios({
    method: 'get',
    url:
      API_URL +
      `/spotify/player/set_repeat?state=${encodeURIComponent(repeat_mode)}`,
    headers: {Authorization: TOKEN},
  })
}

export const disablePlayBackRepeat = () => {
  return axios({
    method: 'get',
    url: API_URL + `/spotify/player/repeat_off`,
    headers: {Authorization: TOKEN},
  })
}

/*
	SAVE TRACKS TO 'MY MUSIC' SECTION OF LIBRARY
*/
export const saveTracksToLibrary = (track_ids) => {
  return axios({
    method: 'get',
    url:
      API_URL +
      `/spotify/library/tracks/save?track_ids=${encodeURIComponent(track_ids)}`,
    headers: {Authorization: TOKEN, Accept: 'application/json'},
  })
}

/*
	REMOVE TRACKS FROM THE 'MY MUSIC' SECTION OF LIBRARY
*/
export const removeTracksFromLibrary = (track_ids) => {
  return axios({
    method: 'get',
    url:
      API_URL +
      `/spotify/library/tracks/remove?track_ids=${encodeURIComponent(track_ids)}`,
    headers: {Authorization: TOKEN, Accept: 'application/json'},
  })
}

/*
	GET TRACKS FROM 'MY MUSIC' SECTION OF LIBRARY
*/
export const getTracksFromLibrary = (limit = null, offset = null) => {
  return axios({
    method: 'get',
    url: API_URL + `/spotify/library/tracks`,
    headers: {Authorization: TOKEN, Accept: 'application/json'},
  })
}

/*
	CHECK IF A TRACK IS IN MY MUSIC SECTION OF LIBRARY
*/
export const isSavedTrack = (track_id) => {
  return axios({
    method: 'get',
    url:
      API_URL +
      `/spotify/library/tracks/saved?track_id=${encodeURIComponent(track_id)}`,
    headers: {Authorization: TOKEN, Accept: 'application/json'},
  })
}
