import {
  ADD_USER,
  LOGOUT_USER,
  VERIFCAR_USUARIO,
  GET_ALL_GENERAL_DATA,
  IS_TEACHING,
  UPDATE_OPTIONS_TUTORIAL,
  ADD_DOMAIN_TO_USER,
  SET_ACTIVE_DOMAIN_ID,
  TOGGLE_SPOTIFY_AUTH,
  TOGGLE_TUTORIAL,
  SET_PHOTO_URL,
  TOGGLE_SUBSCRIPTION_STATUS,
  TOGGLE_SNIPPET_MODAL,
  TOGGLE_FLASHCARD_MODAL,
  DASHBOARD_TUTORIAL_STEP,
  FLASHCARD_TUTORIAL_CHECKPOINT,
  TOGGLE_SUSCRIPTION_STRAPI_MODAL,
  UPDATE_PROFILE_DATA,
  UPDATE_OPTIONS_NOTIFICATION,
} from './actionTypes'

const initialStore = {
  profile: null,
  name: '',
  email: '',
  isLogged: false,
  id: null,
  spotify_hash: {
    credentials: {
      token: '',
    },
    images: [{url: null}],
  },
  spotify_connected: false, // si la cuenta esta activa 'premium'
  idioma_activo: {},
  idioma_nativo: {},
  domain_options: [],
  activeDomainId: null,
  is_teacher: false,
  is_teaching: false,
  options_tutorial: {}, // status para todos los tutoriales
  photo: {url: null},
  auth_modal: false, // de spotify
  dashboardTutorialStatus: false, // turorial de dashboard
  referedBy: null, // referido
  daysLeft: null, // dias restantes 'statico'
  subscriptionStatus: null, // true si la suscriptcion esta vigente, si es trial sera falso aunque tenga aun dias disponible
  plan_type: null, // premium o trial
  trialFinishedAt: null, // fecha que terminara el trial (new Data() <= trialFinishedAt)
  isShowModalSuscription: false, // abrir el modal para suscripciones de stripe
  flashcardModalStatus: false,
  stepNumber: null,
  toggleFlashcardCheckpoint: false,
  notifications_options: [
    {internal_notifications: false},
    {email_notifications: false},
    {push_notifications: false},
  ],
  // pagos a teachers
  balanceDue: 0.0,
  balancePaid: 0.0,
}

const userReducer = (state = initialStore, action) => {
  switch (action.type) {
    case SET_PHOTO_URL:
      return {
        ...state,
        photo: {url: action.photoUrl},
      }

    case SET_ACTIVE_DOMAIN_ID:
      return {
        ...state,
        activeDomainId: action.domainId,
      }

    case ADD_DOMAIN_TO_USER: {
      const {domain_options} = state
      const {domain} = action

      return {
        ...state,
        domain_options: [...domain_options, domain],
      }
    }

    case IS_TEACHING: {
      return {
        ...state,
        is_teaching: action.is_teaching,
      }
    }

    case VERIFCAR_USUARIO: {
      let {user} = action
      const {spotify_hash} = user
      let spotify_connected = false

      if (Object.getOwnPropertyNames(spotify_hash).length !== 0) {
        spotify_connected = true
      } else {
        user = {
          ...user,
          spotify_hash: {
            credentials: {
              token: '',
            },
          },
        }
      }
      return {
        ...state,
        ...user,
        isLogged: true,
        spotify_connected: spotify_connected,
      }
    }

    case ADD_USER: {
      return {
        ...state,
        ...action.user,
        isLogged: true,
      }
    }

    case LOGOUT_USER: {
      return {
        ...state,
        isLogged: false,
        spotify_hash: {},
        spotify_connected: false,
      }
    }

    case GET_ALL_GENERAL_DATA: {
      const {
        idioma_activo,
        idioma_nativo,
        domain_options,
        active_domain,
        is_teacher,
        is_teaching,
        options_tutorial,
        photo,
        name,
        days,
        subscription_status,
        refered_by,
        trial_finished_at,
        profile,
        balance_due = 0,
        balance_paid = 0,
      } = action.generalData

      const domainActiveId = active_domain
        ? active_domain.id
        : domain_options[0]
        ? domain_options[0].id
        : null

      return {
        ...state,
        idioma_activo,
        idioma_nativo,
        domain_options: [...domain_options],
        activeDomainId: domainActiveId,
        is_teacher,
        is_teaching,
        options_tutorial,
        photo,
        name,
        daysLeft: days,
        subscriptionStatus: subscription_status,
        referedBy: refered_by,
        trialFinishedAt: trial_finished_at,
        profile,
        balanceDue: balance_due?.toFixed(2),
        balancePaid: balance_paid?.toFixed(2),
      }
    }

    case UPDATE_PROFILE_DATA: {
      const {profile} = action
      return {
        ...state,
        profile,
        name: profile?.nombre || '',
        photo: {url: profile?.foto?.url || null},
      }
    }

    case UPDATE_OPTIONS_NOTIFICATION: {
      return {
        ...state,
        notifications_options: action.notificationsOptions,
      }
    }

    case UPDATE_OPTIONS_TUTORIAL: {
      return {
        ...state,
        options_tutorial: action.options_tutorial,
      }
    }

    case TOGGLE_SPOTIFY_AUTH: {
      return {
        ...state,
        auth_modal: action.auth_modal,
      }
    }

    case TOGGLE_SUBSCRIPTION_STATUS: {
      const {subscriptionStatus, planType} = action

      return {...state, subscriptionStatus, plan_type: planType}
    }

    case TOGGLE_TUTORIAL: {
      return {
        ...state,
        dashboardTutorialStatus: action.dashboardTutorialStatus,
      }
    }

    case TOGGLE_SNIPPET_MODAL: {
      return {
        ...state,
        snippetModalStatus: action.snippetModalStatus,
      }
    }

    case TOGGLE_FLASHCARD_MODAL: {
      return {
        ...state,
        flashcardModalStatus: action.flashcardModalStatus,
      }
    }
    case DASHBOARD_TUTORIAL_STEP: {
      return {
        ...state,
        stepNumber: action.stepNumber,
      }
    }

    case FLASHCARD_TUTORIAL_CHECKPOINT: {
      return {
        ...state,
        toggleFlashcardCheckpoint: action.toggleFlashcardCheckpoint,
      }
    }

    case TOGGLE_SUSCRIPTION_STRAPI_MODAL: {
      return {
        ...state,
        isShowModalSuscription: action.isShowModalSuscription,
      }
    }

    default:
      return state
  }
}

export default userReducer
