import * as actions from './actionTypes'

export const opening = () => ({
  type: actions.PRESENCE_CONNECTION_OPENING,
})

export const subscribing = () => ({
  type: actions.PRESENCE_CONNECTION_SUBSCRIBING,
})

export const ready = () => ({
  type: actions.PRESENCE_CONNECTION_READY,
})

export const wsconnect = (payload) => ({
  type: actions.WS_PRESENCE_CONNECT,
  payload,
})

export const subscribe = (payload) => ({
  type: actions.WS_PRESENCE_SUBSCRIBE,
  payload,
})

export const receivedData = (data) => ({
  type: actions.RECEIVED_PRESENCE_DATA,
  data,
})

export const receivedPresenceState = (data) => ({
  type: actions.RECEIVED_PRESENCE_STATE,
  data,
})

export const presenceConnected = (presence) => ({
  type: actions.PRESENCE_CONNECTED,
  presence,
})

export const presenceDisconnected = (presence) => ({
  type: actions.PRESENCE_DISCONNECTED,
  presence,
})

export const fetchCurrentPresenceState = () => ({
  type: actions.WS_PRESENCE_FETCH_STATE,
})

export const fetchPresence = () => ({
  type: actions.FETCH_PRESENCE,
})

export const fetchPresenceSuccess = (presence) => ({
  type: actions.FETCH_PRESENCE,
  presence,
})

export const fetchPresenceFailure = (error) => ({
  type: actions.FETCH_PRESENCE_FAILURE,
  error,
})
