import {
  FETCH_MYLIKES_REQUEST_START,
  FETCH_MYLIKES_REQUEST_FINISH,
  FETCH_MYLIKES_REQUEST_ERROR,
  UPDATE_TRACK_MYLIKES_FROM_LIST,
} from './mylLikesTypes'
import {getMyLikes} from '../../lib/coco/InputApi'

const startMylikesRequest = () => {
  return {
    type: FETCH_MYLIKES_REQUEST_START,
    payload: {},
  }
}

const completeMylikesRequest = (data) => {
  return {
    type: FETCH_MYLIKES_REQUEST_FINISH,
    payload: {data},
  }
}

const errorMylikesRequest = (error) => {
  return {
    type: FETCH_MYLIKES_REQUEST_ERROR,
    payload: {error},
  }
}

const getUserInputs = () => (dispatch) => {
  dispatch(startMylikesRequest())

  return getMyLikes().then((response) => {
    if (response.message) {
      dispatch(errorMylikesRequest(response))
    } else {
      dispatch(completeMylikesRequest(response))
    }
  })
}

const updateTrackMyLikes = (track) => {
  return {
    type: UPDATE_TRACK_MYLIKES_FROM_LIST,
    payload: {track},
  }
}

export {getUserInputs, updateTrackMyLikes}
