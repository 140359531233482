const ENV_PROCESS = {
  PRODUCTION: 'production',
  STAGING: 'staging',
}

const getVariables = () => {
  let API = process.env.REACT_APP_API,
    API_WS = process.env.REACT_APP_API_WS,
    SECRET_KEY_BASE = process.env.REACT_APP_SECRET_KEY_BASE,
    DEVISE_SECRET_KEY = process.env.REACT_APP_DEVISE_SECRET_KEY,
    ENVIRONMENT = process.env.REACT_APP_ENV,
    REACT_APP_ONESIGNAL_APP_ID = process.env.REACT_APP_ONESIGNAL_APP_ID,
    REACT_APP_CLIENT_URL = process.env.REACT_APP_CLIENT_URL

  return {
    API,
    API_WS,
    SECRET_KEY_BASE,
    DEVISE_SECRET_KEY,
    REACT_APP_ONESIGNAL_APP_ID,
    REACT_APP_CLIENT_URL,
    ENVIRONMENT,
  }
}

export {getVariables, ENV_PROCESS}
