import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import useAlert, {TOAST_TYPE} from '../../hooks/useAlert'
import useNetworkState from '../../hooks/useNetworkState'
import noSignalIcon from './Icons/no-wifi.png'
import './NetworksAlert.css'

const NetworksAlert = () => {
  const history = useHistory()
  const {online: isNetwordOnline = false, effectiveType} = useNetworkState()
  const [counterRender, setCounterRender] = useState(0)

  const {showMessage} = useAlert({blockNotificationsUser: false})

  useEffect(() => {
    setCounterRender((value) => value + 1)
  }, [])

  useEffect(() => {
    if (counterRender > 0) {
      showMessage(
        `${
          isNetwordOnline
            ? 'De nuevo en linea: ' + effectiveType
            : 'No hay conección a internet'
        }`,
        `${isNetwordOnline ? TOAST_TYPE.INFO : TOAST_TYPE.WARNING}`,
      )

      if (isNetwordOnline) {
        setTimeout(() => {
          history.go(0)
        }, 1500)
      }
    }
    // eslint-disable-next-line
  }, [isNetwordOnline])

  return (
    <>
      {!isNetwordOnline && (
        <img
          className="networks__alert__main "
          src={noSignalIcon}
          alt="network"
          title="No hay conección a internet"
        />
      )}
    </>
  )
}
export default NetworksAlert
