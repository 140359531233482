import React from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'
import './styles.css'
import CardForm from './chunks/CardForm'
import CardInformationUser from './Cards/CardInformationUser'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const CheckOutForm = ({closeModal, currentUser, custom = false}) => {
  return (
    <Elements stripe={stripePromise}>
      <div className="card__view__stripe">
        {!custom && <CardInformationUser currentUser={currentUser} />}
        <CardForm
          currentUser={currentUser}
          closeModal={closeModal}
          custom={custom}
        />
      </div>
    </Elements>
  )
}

export default CheckOutForm
