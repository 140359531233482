import React from 'react'
import {connect} from 'react-redux'

import {getAccessToken} from '../../Redux/spotify/requests/player'

import {smallAlert, ALERT_TYPES, POSITIONS_ALERT} from '../../Services/Alert'

import {
  playerStateChanged,
  toggleRepeat,
  playerError,
  transferPlayback,
  playerLoading,
  playerReady,
  playerInitError,
  playerInitRetry,
  playerAuthError,
  playerAuthRetry,
  playerAcctError,
  playbackError,
} from '../../Redux/spotify/actions/player'

import ScriptLoader from './ScriptLoader'

class Player extends React.Component {
  state = {
    sdkLoaded: false,
    sdkSource: 'https://sdk.scdn.co/spotify-player.js',
  }

  scriptLoader = () => {
    return new ScriptLoader([this.state.sdkSource])
  }

  createPlayer = () => {
    const {spotify_premium, spotify_connected} = this.props

    if (spotify_premium && spotify_connected) {
      this.props.dispatch(playerLoading(true))
      if (!this.state.sdkLoaded) {
        this.scriptLoader().onLoad(
          (window.onSpotifyWebPlaybackSDKReady = () =>
            this.setState({sdkLoaded: true}, this.playerInit())),
        )
      } else {
        this.playerInit()
      }
    }
  }

  playerInit = () => {
    const {dispatch /* repeat_mode */} = this.props

    this.player = new window.Spotify.Player({
      name: playerName(),
      getOAuthToken: (cb) => {
        getAccessToken()
          .then((response) => cb(response.data.access_token))
          .catch((error) => dispatch(playerError(error)))
      },
    })

    this.player.addListener('initialization_error', (error) =>
      dispatch(playerInitError(error)),
    )
    this.player.addListener('authentication_error', (error) =>
      dispatch(playerAuthError(error)),
    )
    this.player.addListener('account_error', (error) =>
      dispatch(playerAcctError(error)),
    )
    this.player.addListener('playback_error', (error) =>
      dispatch(playbackError(error)),
    )

    this.player.addListener('player_state_changed', (player) => {
      dispatch(playerStateChanged(player))
    })
    this.player.addListener('not_ready', (player) => dispatch(playerLoading(true)))

    this.player.addListener('ready', (player) => {
      dispatch(playerReady())
      dispatch(playerLoading(false))
      dispatch(transferPlayback(player.device_id))
      dispatch(playerStateChanged(player))
    })

    this.player.connect()
  }

  handleAuthError = () => {
    //console.log("Player Authentication Error: Invalid Access Token")
    var body = document.getElementsByTagName('body')[0]
    var sdkScripts = [...document.getElementsByTagName('script')].filter(
      (tag) => tag.src === this.state.sdkSource,
    )

    sdkScripts.forEach((script) => body.removeChild(script))
    this.props.dispatch(playerAuthRetry())

    if (this.props.retries < 3) {
      if (
        [...document.getElementsByTagName('script')].filter(
          (tag) => tag.src === this.state.sdkSource,
        ).length === 0
      ) {
        this.setState({sdkLoaded: false})
      }

      this.createPlayer()
    }
  }

  handleInitializationError = () => {
    this.props.dispatch(playerInitRetry())

    'MediaKeys' in window || 'WebKitMediaKeys' in window || 'MSMediaKeys' in window
      ? this.handleAuthError()
      : console.log(
          'Spotify Player Initialization Error: Browser does not support EME protection -- playback unavailable.',
        )

    if (this.props.loading) {
      this.props.dispatch(playerLoading(false))
    }
  }

  handleAccountError = () => {
    if (this.props.spotify_product === 'premium') {
      smallAlert(
        ALERT_TYPES.ERROR,
        'Spotify Player Account Error: Try re-linking your spotify account',
        POSITIONS_ALERT.BOTTOM_END,
        5000,
      )
    } else {
      // smallAlert(
      //   ALERT_TYPES.ERROR,
      //   'Spotify Player Account Error: Spotify Playback requires a Spotify Premium Account.',
      //   POSITIONS_ALERT.BOTTOM_END,
      //   5000,
      // )
    }

    if (this.props.loading) {
      this.props.dispatch(playerLoading(false))
    }
  }

  handlePlaybackError = () => {
    smallAlert(
      ALERT_TYPES.ERROR,
      'Spotify Player Playback Error: Failed to load track/context information.',
      POSITIONS_ALERT.BOTTOM_END,
      5000,
    )
    if (this.props.loading) {
      this.props.dispatch(playerLoading(false))
    }
  }

  componentDidMount() {
    if (this.props.spotify_connected) {
      this.createPlayer()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      auth_error,
      init_error,
      account_error,
      playback_error,
      context,
      repeat_mode,
      dispatch,
    } = this.props
    //spotify_connected, spotify_premium
    const sdkScripts = [...document.getElementsByTagName('script')].filter(
      (tag) => tag.src === this.state.sdkSource,
    )

    if (sdkScripts.length === 0 && this.state.sdkLoaded) {
      this.setState({sdkLoaded: false}, () => this.createPlayer())
    }

    if (this.props.retries < 4) {
      if (auth_error) {
        this.handleAuthError()
      }

      if (init_error) {
        this.handleInitializationError()
      }
    } else {
      if (this.props.loading) {
        this.props.dispatch(playerLoading(false))
      }
      if (this.props.spotify_connected) {
        // this.createPlayer();
      }
    }

    if (account_error) {
      this.handleAccountError()
    }

    if (playback_error) {
      this.handlePlaybackError()
    }

    // console.log(repeat_mode)
    if (repeat_mode !== 1 && context && context.uri === null) {
      dispatch(toggleRepeat('track'))
    } else if (context && context.uri !== null && repeat_mode === 1) {
      dispatch(toggleRepeat('context'))
    }
  }

  render() {
    return <div>{this.props.children}</div>
  }
}

const playerName = () => {
  return process.env.NODE_ENV === 'production' ? 'Coco Player' : 'Coco Dev Player'
}

const mapStateToProps = (state) => ({
  player: state.spotify.player,
  repeat_mode: state.spotify.player.repeat_mode,
  context_uri: state.spotify.player.context.uri,
  next_tracks: state.spotify.player.track_window.next_tracks,
  init_error: state.spotify.player.errors.init,
  auth_error: state.spotify.player.errors.auth,
  account_error: state.spotify.player.errors.account,
  playback_error: state.spotify.player.errors.playback,
  loading: state.spotify.player.loading,
  retries: state.spotify.player.errors.retries,
  spotify_premium: state.user.spotify_hash.product === 'premium',
  spotify_connected: state.user.spotify_connected,
  user: state.user,
  spotify_token: state.user.spotify_hash.credentials.token,
})

export default connect(mapStateToProps)(Player)
