import React, {useEffect} from 'react'
import {useRef} from 'react'
import useBooleanToggler from '../../../hooks/useBooleanToggler'
import useOnClickOutside from '../../../hooks/useClickOutside'
import DiccionarioLibre from '../../Diccionary/DiccionarioLibre'
import './ButtonsMobileFloat.css'
import lupaButtonIcon from './Icons/lupaButton.svg'
import plusButtonIcon from './Icons/plusButton.svg'
import SpotifyIconSmall from '../../Icons/SpotifySmall.svg'
import floatMenuMobil from './Icons/floatMenuMobil.svg'
import minichatIcon from './Icons/minichat.svg'
import useSpotify from '../../../hooks/useSpotify'
import useDiccionary from '../../../hooks/useDiccionary'
import {Link} from 'react-router-dom'

const ButtonsMobileFloat = ({
  openPlusButton,
  stateModalSpotifyAuth,
  isKeyBoardActive,
}) => {
  const {isToggled, toggle} = useBooleanToggler({initialValue: false})

  const {
    isDiccionaryOpen,
    params,
    dispatchOpenDiccionary,
    dispatchCloseDiccionary,
    dispatchSetParamsDiccionary,
  } = useDiccionary()

  const {player} = useSpotify()

  const refFloat = useRef()

  useEffect(() => {
    const validateParams = () => {
      if (params?.trim()?.length > 0 && !isDiccionaryOpen) {
        dispatchOpenDiccionary()
      }
    }
    validateParams()
    // eslint-disable-next-line
  }, [params])

  const handlerOutside = () => {
    if (isToggled) {
      toggle()
    }
  }

  useOnClickOutside(refFloat, handlerOutside)

  const {getStatus, toggleSpotifyModalAuth} = stateModalSpotifyAuth
  const status = getStatus()

  const connectSpotify = () => {
    toggleSpotifyModalAuth(status)
  }

  return (
    <>
      <div
        className="buttons__mobile__float"
        ref={refFloat}
        style={isKeyBoardActive ? {right: '-90px'} : {}}>
        {isToggled && (
          <>
            {!player?.is_active && (
              <button className="button__mobile__item" onClick={connectSpotify}>
                <img src={SpotifyIconSmall} alt={SpotifyIconSmall} />
              </button>
            )}
            <button
              className="button__mobile__item"
              onClick={dispatchOpenDiccionary}>
              <img src={lupaButtonIcon} alt={lupaButtonIcon} />
            </button>

            <Link className="button__mobile__item" to="/conversations">
              <img
                src={minichatIcon}
                alt={minichatIcon}
                className="button__mobile__item__chat"
              />
            </Link>

            <button className="button__mobile__item" onClick={openPlusButton}>
              <img src={plusButtonIcon} alt={plusButtonIcon} />
            </button>
          </>
        )}

        <button
          className="button__mobile__item__main plus-button-target"
          onClick={toggle}>
          <img src={floatMenuMobil} alt={floatMenuMobil} />
        </button>
      </div>

      {/* {isMiniChatActive && <MiniChat isShowMaximizar={false} />} */}

      {isDiccionaryOpen && (
        <DiccionarioLibre
          params={params}
          isOpenDiccionary={isDiccionaryOpen}
          closeDiccionary={dispatchCloseDiccionary}
          setParamsDiccionary={dispatchSetParamsDiccionary}
        />
      )}
    </>
  )
}

export default ButtonsMobileFloat
