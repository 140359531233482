import Noty from 'noty'

export const TOAST_TYPE = {
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error',
  SUCCESS: 'success',
}

export const LAYOUT = {
  BOTTOMLEFT: 'topLeft',
  BOTTOMRIGTH: 'bottomRight',
  TOPRIGTH: 'topRight',
  TOPLEFT: 'topLeft',
}

const defaultOptions = {
  blockNotificationsUser: true,
}

// este hooks valida si las notificaiones internas en coco estan activas se ejecuta
const useAlert = (options = defaultOptions) => {
  const internalsNotification = {}

  const showMessage = (
    text,
    type = TOAST_TYPE.SUCCESS,
    modal = true,
    layout = LAYOUT.BOTTOMRIGTH,
    timeout = '3000',
  ) => {
    if (internalsNotification || !options?.blockNotificationsUser) {
      new Noty({
        type: type,
        theme: 'bootstrap-v4',
        layout: layout,
        text: text,
        timeout: timeout,
        modal,
      }).show()
    } else {
      console.log('Notify desabled')
    }
  }
  return {
    showMessage,
  }
}

export default useAlert
