import React, {Fragment, useEffect, useState} from 'react'
import useBooleanToggler, {
  useWorkingIndicator,
} from '../../../hooks/useBooleanToggler'
import useConversations from '../../../hooks/useConversations'
import useUser from '../../../hooks/useUser'
import useAlert, {TOAST_TYPE} from '../../../hooks/useAlert'
import {useDebounce} from 'use-debounce'
import {getUsers} from '../../../lib/coco/itemsApi'
import {createConversationPersonal} from '../../../lib/coco/conversationApi'
import Conversations from './Conversations'
import CreateConversationButton from './CreateConversationButton'
import CreateMessagesAndGroup from './CreateMessagesAndGroup'
import HeaderMiniChat from './HeaderMiniChat'
import SearchConversations from './SearchConversations'
import SelecctorTypesConversation from './SelecctorTypesConversation'
import {ConversationType} from '../../../lib/coco/helperData'
import {findCoversationWithUser} from '../../../Redux/helpers'
import AvatarParticipant from './AvatarParticipant'
import {
  isUserWasAdd,
  TypeCreateConversation,
  getUrlFileImage,
  isValidFormatImage,
} from '../helpers'
import BotonConBordeDegradado from '../../Buttons/BotonConBordeDegradado'
import {UsersSelected} from './UsersSelected'
import cameraIcon from './Icons/camera.svg'
import {useDispatch} from 'react-redux'
import {receivedData} from '../../../Redux/Conversations/actions'
import {useIntl} from 'react-intl'

const CreateNewConversationUser = ({
  toggleNewMessage,
  currentUserId,
  conversations,
  setActiveConversation,
  type = TypeCreateConversation.PERSONAL,
  intl,
}) => {
  const dispatch = useDispatch()
  const {showMessage} = useAlert()
  const [users, setUsers] = useState([])
  const [usersGroups, setUsersGroups] = useState([])
  const [termUser, setTermUser] = useState('')
  const [nameGroup, setNameGroup] = useState('')
  const [imageGroup, setImageGroup] = useState(null)
  const [imageGroupUrl, setImageGroupUrl] = useState(null)
  const [value] = useDebounce(termUser, 500)

  const {
    isWorking: isWorkingGroup,
    startWork: startWorkGroup,
    finishWork: finishWorkGroup,
  } = useWorkingIndicator()
  const {
    isWorking: isWorkingNewMessageUser,
    startWork: startWorkNewMessageUser,
    finishWork: finishWorkNewMessageUser,
  } = useWorkingIndicator()
  const {
    isToggled: isNextGroupStep,
    toggle: toggleNextGroupStep,
  } = useBooleanToggler()

  useEffect(() => {
    const getUsersAPi = () => {
      getUsers(termUser).then((response) => {
        if (response?.users) {
          const users = response.users.filter(
            (user) => user.user_id !== currentUserId,
          )
          setUsers(users.length > 0 ? users : [])
        }
      })
    }

    getUsersAPi()
    // eslint-disable-next-line
  }, [value, currentUserId])

  const addUserToSelect = (user) => {
    if (!isUserWasAdd(usersGroups, user?.user_id)) {
      setUsersGroups([...usersGroups, user])
    }
  }

  const removerUser = (user) => {
    setUsersGroups(usersGroups.filter((u) => u?.user_id !== user?.user_id))
  }

  const nextGroupStep = () => {
    toggleNextGroupStep()
  }

  const OnChangeImageGroup = (event) => {
    const files = event?.currentTarget?.files
    if (files?.length > 0) {
      const imageFile = files[0]
      // validar tipo image
      if (!isValidFormatImage(imageFile)) {
        showMessage(`Debe seleccionar un formato de imagen`, TOAST_TYPE.INFO)
        return
      }
      setImageGroup(imageFile)
      setImageGroupUrl(getUrlFileImage(imageFile))
    }
  }

  const createConversationGroup = () => {
    if (!isWorkingGroup) {
      const usersIds = usersGroups.map((u) => u.user_id)
      if (usersIds?.length > 0) {
        startWorkGroup()
        createConversationPersonal(
          ConversationType.GROUP,
          usersIds,
          nameGroup,
          imageGroup,
        ).then((response) => {
          finishWorkGroup()
          if (response?.conversation) {
            const {conversation} = response
            dispatch(receivedData(conversation)) // actualizo la conversacion en redux, antes que el socket responda
            setActiveConversation(conversation?.id)
            toggleNewMessage()
          } else {
            showMessage(
              `Error: ${response.status}, ${response.message}`,
              TOAST_TYPE.ERROR,
            )
          }
        })
      }
    }
  }

  // buscar o crear conversacion con un usuario
  const createConversationUser = (user) => {
    if (isWorkingNewMessageUser) return
    // #1 buscar conversacion
    const conver = findCoversationWithUser(user, conversations)
    if (conver?.id) {
      setActiveConversation(conver?.id)
      toggleNewMessage()
    } else {
      // #crear
      const usersIds = [user?.user_id]
      if (user?.user_id) {
        startWorkNewMessageUser()
        createConversationPersonal(ConversationType.PERSONAL, usersIds).then(
          (response) => {
            finishWorkNewMessageUser()
            if (response.conversation) {
              const {conversation} = response
              dispatch(receivedData(conversation)) // actualizo la conversacion en redux, antes que el socket responda
              setActiveConversation(conversation.id)
              toggleNewMessage()
            } else {
              showMessage(
                `Error: ${response.status}, ${response.message}`,
                TOAST_TYPE.ERROR,
              )
            }
          },
        )
      }
    }
  }

  const ListUserAPi = ({
    usersApi = [],
    selectUser,
    usersSelected = [],
    style = {},
  }) => {
    return (
      <div className="scroll-coco" style={style}>
        {usersApi?.length > 0 &&
          usersApi.map((user) => (
            <div key={user?.user_id} className="contenedor-conversacion-item">
              <div
                className="conversation-item-personal click-me"
                onClick={() => selectUser(user)}>
                <div className="conversation-item-personal-avatar">
                  <AvatarParticipant
                    photoUrl={user?.photo}
                    title={user?.nickname || 'User'}
                  />
                  <span id="title-conversation">{user?.nickname || 'User'}</span>
                </div>

                {isUserWasAdd(usersSelected, user?.user_id) && (
                  <span className="status-usuario-agregado" style={{width: '50px'}}>
                    {intl.formatMessage({
                      id: 'chat.user-add',
                      defaultMessage: 'Aggregate',
                    })}
                  </span>
                )}
              </div>
            </div>
          ))}
      </div>
    )
  }

  return (
    <>
      {isNextGroupStep ? (
        <>
          <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
            <HeaderMiniChat
              onClick={nextGroupStep}
              title={intl.formatMessage({
                id: 'chat.new-group',
                defaultMessage: 'Create group',
              })}
              style={{border: 'none', marginBottom: '10px', marginTop: '10px'}}
            />

            <div className="create-conversation-camera-container">
              <input
                type="file"
                style={{display: 'none'}}
                id="file-image-group"
                onChange={OnChangeImageGroup}
              />
              <label
                style={{backgroundImage: `url(${imageGroupUrl && imageGroupUrl})`}}
                className="create-conversation-camera-icon"
                htmlFor="file-image-group">
                <img src={cameraIcon} alt="camera" />
                <p>
                  {intl.formatMessage({
                    id: 'chat.user-add-image',
                    defaultMessage: 'Add Image',
                  })}
                </p>
              </label>
            </div>

            <input
              className="create-conversation-name-group"
              type="text"
              onChange={({target}) => setNameGroup(target.value)}
              value={nameGroup}
              placeholder={intl.formatMessage({
                id: 'chat.user-name-group',
                defaultMessage: 'Group name',
              })}
              autoFocus
            />
          </div>

          {nameGroup?.trim()?.length > 0 && (
            <div className="btn-create-group-container">
              <BotonConBordeDegradado
                function={createConversationGroup}
                width={150}
                height={39}
                disabled={isWorkingGroup}>
                <>{`${
                  isWorkingGroup
                    ? intl.formatMessage({
                        id: 'chat.create-group-loading',
                        defaultMessage: 'Creating',
                      })
                    : intl.formatMessage({
                        id: 'chat.create-group',
                        defaultMessage: 'Create',
                      })
                }`}</>
              </BotonConBordeDegradado>
            </div>
          )}
        </>
      ) : (
        <Fragment>
          <div style={{marginLeft: '20px', marginRight: '20px'}}>
            <HeaderMiniChat
              onClick={toggleNewMessage}
              title={
                type === TypeCreateConversation.PERSONAL
                  ? intl.formatMessage({
                      id: 'chat.new-message',
                      defaultMessage: 'New message',
                    })
                  : intl.formatMessage({
                      id: 'chat.new-group',
                      defaultMessage: 'Create group',
                    })
              }
              style={{border: 'none', marginBottom: '10px', marginTop: '10px'}}
            />

            {type === TypeCreateConversation.GRUPO && usersGroups?.length > 0 && (
              <UsersSelected users={usersGroups} removerUser={removerUser} />
            )}

            <p style={{color: '#ffffff', fontSize: '18px'}}>
              {type === TypeCreateConversation.PERSONAL
                ? intl.formatMessage({
                    id: 'chat.new-message-description',
                    defaultMessage: 'Who do you want to chat with?',
                  })
                : intl.formatMessage({
                    id: 'chat.new-group-description',
                    defaultMessage: 'Who is going to be part of this group?',
                  })}
            </p>
            <SearchConversations
              onChange={setTermUser}
              value={termUser}
              placeholder={intl.formatMessage({
                id: 'chat.search-people',
                defaultMessage: 'Search person...',
              })}
            />
          </div>

          {type === TypeCreateConversation.PERSONAL ? (
            <ListUserAPi
              usersApi={users}
              selectUser={createConversationUser}
              style={{marginLeft: '10px', marginRight: '10px'}}
            />
          ) : (
            <>
              <ListUserAPi
                usersApi={users}
                selectUser={addUserToSelect}
                usersSelected={usersGroups}
                style={{marginLeft: '5px', marginRight: '5px'}}
              />

              {usersGroups?.length > 0 && (
                <div className="btn-create-group-container">
                  <BotonConBordeDegradado
                    function={nextGroupStep}
                    width={150}
                    height={39}>
                    <>
                      {intl.formatMessage({
                        id: 'chat.user-next',
                        defaultMessage: 'Next',
                      })}
                    </>
                  </BotonConBordeDegradado>
                </div>
              )}
            </>
          )}
        </Fragment>
      )}
    </>
  )
}

const ConversationLeft = ({setActiveConversationFn}) => {
  const intl = useIntl()
  const {
    conversations = [],
    conversationActive,
    // setHilosClose,
    // setHilosOpen,
    param: titleSearchConversation,
    setParam: setTitleConversationsParam,
    paramMessage,
    setParamMessage,
    isHilosActive,
  } = useConversations()
  const {id: currentUserId} = useUser()

  const {
    isToggled: isNewMessageActive,
    toggle: toggleNewMessage,
  } = useBooleanToggler()
  const {isToggled: isNewGroupActive, toggle: toogleGroup} = useBooleanToggler()

  const isCloseAll = () => {
    if (!isNewMessageActive) {
      if (!isNewGroupActive) {
        return true
      }
    }

    return false
  }

  return (
    <>
      {isNewMessageActive && (
        <CreateNewConversationUser
          currentUserId={currentUserId}
          toggleNewMessage={toggleNewMessage}
          conversations={conversations}
          setActiveConversation={setActiveConversationFn}
          type={TypeCreateConversation.PERSONAL}
          intl={intl}
        />
      )}

      {isNewGroupActive && (
        <CreateNewConversationUser
          currentUserId={currentUserId}
          toggleNewMessage={toogleGroup}
          conversations={conversations}
          setActiveConversation={setActiveConversationFn}
          type={TypeCreateConversation.GRUPO}
          intl={intl}
        />
      )}

      {isCloseAll() && (
        <>
          {/* selector de hilos o messages */}
          <SelecctorTypesConversation
          // setHilosClose={setHilosClose}
          // isHilosActive={isHilosActive}
          // setHilosOpen={setHilosOpen}
          />

          {/*Buscator de hilos*/}
          {isHilosActive ? (
            <SearchConversations
              onChange={setTitleConversationsParam}
              value={titleSearchConversation}
              placeholder={'Buscar hilos...'}
            />
          ) : (
            <SearchConversations
              onChange={setParamMessage}
              value={paramMessage}
              placeholder={intl.formatMessage({
                id: 'chat.search-message',
                defaultMessage: 'Search messages...',
              })}
            />
          )}

          {/*Para crear un hilo*/}
          {isHilosActive ? (
            <CreateConversationButton
              setActiveConversationFn={setActiveConversationFn}
            />
          ) : (
            <CreateMessagesAndGroup
              newMessage={toggleNewMessage}
              newGroup={toogleGroup}
              intl={intl}
            />
          )}

          {/*Lista de todos los hilos*/}
          {isHilosActive ? (
            <Conversations
              conversations={conversations}
              conversationActive={conversationActive}
              setActiveConversation={setActiveConversationFn}
            />
          ) : (
            <Conversations
              conversations={conversations}
              conversationActive={conversationActive}
              setActiveConversation={setActiveConversationFn}
            />
          )}
        </>
      )}
    </>
  )
}

export default ConversationLeft
