import {
  FETCH_DASHBOARD_REQUEST_FINISH,
  FETCH_DASHBOARD_REQUEST_START,
  FETCH_DASHBOARD_REQUEST_ERROR,
  UPDATE_TRACK_DASHBOARD_FROM_LIST,
  CHECK_TRACK_STATE_REALY,
} from './dashboardTypes'
import {getRecommendations} from '../../lib/coco/InputApi'
import {mergeMyLikes} from '../helpers'

const checkTrackStateRealy = (spotifyId) => {
  return {
    type: CHECK_TRACK_STATE_REALY,
    spotifyId,
  }
}
const startDashboardRequest = () => {
  return {
    type: FETCH_DASHBOARD_REQUEST_START,
    payload: {},
  }
}

const completeDashboardRequest = (data) => {
  return {
    type: FETCH_DASHBOARD_REQUEST_FINISH,
    payload: {data},
  }
}

const errorDashboardRequest = (error) => {
  return {
    type: FETCH_DASHBOARD_REQUEST_ERROR,
    payload: {error},
  }
}

const getDataDashboard = () => (dispatch) => {
  dispatch(startDashboardRequest())

  return getRecommendations().then((response) => {
    if (response.message) {
      dispatch(errorDashboardRequest(response))
    } else {
      const {
        artistas_recomendados,
        seguir_aprendiendo,
        tracks_recomendados,
        shared_tracks,
        textos_agregados,
      } = response

      const seguirAprendiendoMerged = mergeMyLikes(seguir_aprendiendo)

      const data = {
        LearningTracks: seguirAprendiendoMerged,
        recommendedArtists: artistas_recomendados,
        recommendedTracks: tracks_recomendados,
        sharedTracks: shared_tracks,
        inputTexts: textos_agregados,
      }

      dispatch(completeDashboardRequest(data))
    }
  })
}

const updateTrackDashboard = (track) => {
  return {
    type: UPDATE_TRACK_DASHBOARD_FROM_LIST,
    payload: {track},
  }
}

export {getDataDashboard, updateTrackDashboard, checkTrackStateRealy}
