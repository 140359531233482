import {SET_ALL_PROGRESS, SUBTRACT_ONE_TO_NOTIFICATIONS_INBOX} from './actionTypes'

const setAllProgress = (review, output, input, inbox, actions) => {
  return {
    type: SET_ALL_PROGRESS,
    review,
    output,
    input,
    inbox,
    actions,
  }
}

const subtractOneToNotificationsInbox = () => {
  return {
    type: SUBTRACT_ONE_TO_NOTIFICATIONS_INBOX,
  }
}

export {setAllProgress, subtractOneToNotificationsInbox}
