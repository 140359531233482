import React from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import useConversations from '../../../hooks/useConversations'
import useUser from '../../../hooks/useUser'
import {thereAreNewConversationsOrMessages} from '../helpers'
import MiniChat from './MiniChat'

const ButtonFloat = ({setMinichatOpen}) => {
  const intl = useIntl()

  const title = intl.formatMessage({
    id: 'chat.title',
    defaultMessage: 'Chat',
  })
  return (
    <div
      title={title}
      className="btn-hilos-float truncar__text__line"
      onClick={setMinichatOpen}
      style={{maxWidth: '80%'}}>
      {title}
    </div>
  )
}

const ButtonMinichatFloat = () => {
  const {conversations, setMinichatOpen, isMiniChatActive} = useConversations()
  const {id: currentUserId} = useUser()

  const {pathname} = useLocation()

  const linksDisabledThread = ['/conversations', '/settings']

  const isValidRender = () => {
    const pathSplit = pathname.split('/')
    if (pathSplit[1] === 'conversations') {
      return false
    }

    if (!linksDisabledThread.includes(pathname)) {
      return true
    }
    return false
  }

  const countConversations = thereAreNewConversationsOrMessages(
    conversations,
    currentUserId,
  )

  if (!isValidRender()) return null

  return (
    <>
      {isMiniChatActive ? (
        <MiniChat />
      ) : (
        <div className="contenedor-btn-hilos" onClick={setMinichatOpen}>
          {countConversations?.length > 0 && (
            <span className="btn-hilos-counts">{`${
              countConversations?.length > 99 ? '99+' : countConversations?.length
            }`}</span>
          )}
          <ButtonFloat setMinichatOpen={setMinichatOpen} />
        </div>
      )}
    </>
  )
}

export default ButtonMinichatFloat
