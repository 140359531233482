import {LOADING_DASHBOARD_TEACHER, SHOW_PERFIL_USER} from './actionTypes'

const initialUserState = {
  nombre: '',
  foto: {url: null},
  items: [],
  canciones: [],
  preguntas: [],
}

const initialStore = {
  explore: [],
  profesores: [],
  seguidores: [],
  seguidos: [],
  users: [],
  usuarioSelected: initialUserState,
}

export default function (state = initialStore, action) {
  switch (action.type) {
    case LOADING_DASHBOARD_TEACHER: {
      return {
        ...state,
        explore: action.explore,
        profesores: action.profesores,
        seguidores: action.seguidores,
        seguidos: action.seguidos,
        users: action.users,
      }
    }

    case SHOW_PERFIL_USER: {
      return {
        ...state,
        usuarioSelected: action.usuarioSelected,
      }
    }

    default:
      return state
  }
}
