import React, {useState} from 'react'
import useLanguages from '../../hooks/useLanguages'
import useClickOutside from '../../hooks/useClickOutside'
import './ChangeLanguagePlatform.css'
import languagesIcon from './Icons/languages.svg'
import {useRef} from 'react'
import {setLanguage} from '../../lib/coco/languagesApi'
import {ALERT_TYPES, smallAlert} from '../../Services/Alert'
import {useIntl} from 'react-intl'

const ButtonToggle = ({changeLanguageTitle, onClick, intl, languageActive}) => {
  return (
    <button
      title={changeLanguageTitle}
      className="change-language-platform-botton"
      onClick={onClick}>
      <img
        className="change-language-platform-icon"
        src={languagesIcon}
        alt="wordl"
      />
      <div>
        {intl.formatMessage({
          id: 'drawer.language-platform',
          defaultMessage: 'Language:',
        })}
      </div>
      <div className="change-language-platform-name">
        {`${languageActive?.name ? languageActive.name : 'Español'}`}
      </div>
    </button>
  )
}

const LanguageItem = ({language, onClick}) => {
  return (
    <div
      className="change-language-item-title"
      title={language.name}
      onClick={onClick}>
      {language.name}
    </div>
  )
}

const ListLanguages = ({
  changeLanguageTitle,
  languages = [],
  selectLanguageId,
  setIsToggle,
}) => {
  const ref = useRef()
  useClickOutside(ref, () => setIsToggle(false))

  return (
    <div className="change-language-platform" ref={ref}>
      <p className="change-language-platform-title">{changeLanguageTitle}</p>
      {languages?.length > 0 &&
        languages.map((language) => (
          <LanguageItem
            key={language.id}
            language={language}
            onClick={() => selectLanguageId(language)}
          />
        ))}
    </div>
  )
}

const ChangePlatformLanguage = () => {
  const intl = useIntl()
  const {
    languages = [],
    languageActive,
    dispatchSetLanguageIdActive,
  } = useLanguages()
  const [isToggle, setIsToggle] = useState(false)

  const selectLanguageId = (language) => {
    setLanguage(language.unicode).then((response) => {
      if (response.lang) {
        dispatchSetLanguageIdActive(language)
        setIsToggle(false)
      } else {
        smallAlert(
          ALERT_TYPES.WARNING,
          `${response.message}: No se pudo cambiar el lenguaje`,
        )
      }
    })
  }

  const changeLanguageTitle = intl.formatMessage({
    id: 'drawer.language-description',
    defaultMessage: 'Select the interface language',
  })

  return (
    <div className="change-language-platform-container">
      <ButtonToggle
        changeLanguageTitle={changeLanguageTitle}
        onClick={() => setIsToggle(true)}
        intl={intl}
        languageActive={languageActive}
      />
      {isToggle && (
        <ListLanguages
          languages={languages}
          selectLanguageId={selectLanguageId}
          changeLanguageTitle={changeLanguageTitle}
          setIsToggle={setIsToggle}
        />
      )}
    </div>
  )
}

export default ChangePlatformLanguage
