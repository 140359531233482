import React from 'react'
import {useState} from 'react'
import {
  SettingsViewerStatus,
  isSettingInformation,
  isSettingNotifications,
  isSettingPaymentsSuscriptions,
  isSettingSpotify
} from './helpersSettings'
import './LayoutSetting.css'
import SettingsInformation from './SettingsInformation'
import SettingsNotifications from './SettingsNotifications'
import SettingsPaymentSuscription from './SettingsPaymentSuscription'
import IconCloseRound from '../Icons/IconCloseRound.svg'
import SettingOptions from './SettingOptions'
import useUser from '../../hooks/useUser'
import {useIntl} from 'react-intl'
import SpotifySettings from './SpotifySettings'

const GoBackButton = ({onClick}) => {
  return (
    <button className="Layout__setting__close" onClick={onClick}>
      <img src={IconCloseRound} alt={IconCloseRound} />
    </button>
  )
}

const LayoutSetting = ({closeModal}) => {
  const currentUser = useUser()
  const intl = useIntl()

  const [settingsViewer, setSettingViewer] = useState(
    SettingsViewerStatus.INFORMATION_PERSONAL,
  )

  const closeViewSettings = () => {
    // se podria validar si ha cambiado datos para poder ejecutarlo
    closeModal()
  }

  return (
    <div className="Layout__setting__main">
      <SettingOptions
        setSettingViewer={setSettingViewer}
        settingsViewer={settingsViewer}
        currentUser={currentUser}
        intl={intl}
      />

      <div className="Layout__setting__rigth">
        <GoBackButton onClick={closeViewSettings} />

        {isSettingInformation(settingsViewer) && (
          <SettingsInformation currentUser={currentUser} intl={intl} />
        )}
        {isSettingNotifications(settingsViewer) && (
          <SettingsNotifications currentUser={currentUser} intl={intl} />
        )}
        {isSettingPaymentsSuscriptions(settingsViewer) && (
          <SettingsPaymentSuscription currentUser={currentUser} intl={intl} />
        )}
        {isSettingSpotify(settingsViewer) && (
          <SpotifySettings currentUser={currentUser} intl={intl} />
        )}
      </div>
    </div>
  )
}

export default LayoutSetting
