import React, {Component, Fragment} from 'react'
import '../MediaText/MediaText.css'
import './Preview.css'
import {CREATE_FLASHCARD} from '../../Services/EndPointApi'
import axios from 'axios'
import store from '../../Redux/Store'
import {connect} from 'react-redux'
import {updateLines} from '../../Redux/MediaText/actions'
import {getToken} from '../../Services/getToken'
import enviar from '../Icons/enviar.svg'
import fondoLlenarElEspacioStudy from '../Icons/fondoLlenarElEspacioStudy.svg'
import fondoTraduccionStudy from '../Icons/fondoTraduccionStudy.svg'
import BtnPista from '../Buttons/BtnPista'
import Swal from 'sweetalert2'
import {FormattedMessage} from 'react-intl'
import {getAllProgress} from '../../Redux/users/actions'

class Preview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIndex: null,
      showHint: false,
    }
  }

  setIndex(index) {
    this.setState({selectedIndex: index})
  }

  toggleHint() {
    this.setState({
      showHint: !this.state.showHint,
    })
  }

  clozeWords(fraseOriginal) {
    return fraseOriginal.split(' ').map((word, index) => (
      <span key={index} onClick={() => this.setIndex(index)}>
        {this.state.selectedIndex === index ? '____ ' : word + ' '}
      </span>
    ))
  }

  answerWords(fraseOriginal) {
    return fraseOriginal.split(' ').map((word, index) => (
      <span
        key={index}
        style={{
          fontWeight: this.state.selectedIndex === index ? 'bold' : 'normal',
        }}>
        {word + ' '}
      </span>
    ))
  }

  static shuffle(array) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  generateHint() {
    if (this.props.format === 'cloze') {
      return this.props.oracion.translation
    } else {
      return Preview.shuffle(this.props.oracion.example.split(' ')).join(', ')
    }
  }

  saveFlashcard() {
    const DEVISE_SECRET_KEY = getToken()
    const palabra = this.props.palabra
    // const token = palabra.token;
    const oracion = this.props.oracion.example
    const traduccion = this.props.oracion.translation
    const mediatext = this.props.mediatext
    const fuente = this.props.fuente
    if (this.props.format === 'cloze' && this.state.selectedIndex === null) {
      Swal.fire({
        type: 'error',
        title: `Te faltó un paso!`,
        html:
          '<h4>Para estudiar con llenar el espacio, tienes que seleccionar una palabra</h4>',
        toast: true,
        position: 'bottom-end',
        timer: 3000,
      })
    } else {
      const item = {
        activity_type: 'memorize',
        title: traduccion,
        summary: oracion,
        format: this.props.format,
        start_index: this.state.selectedIndex,
        end_index: this.state.selectedIndex,
        source_url: fuente,
        term_id: palabra.term_id,
        line_index_in_text: palabra.line_index,
        itemizable_id: this.props.mediaTextId,
        itemizable_type: 'MediaText',
        form: palabra.form,
        lemma: palabra.lemma,
        part_of_speech: palabra.part_of_speech,
      }

      const params = {
        item,
        lang_code: palabra.lang_code,
      }

      axios
        .post(
          CREATE_FLASHCARD,
          {
            ...params,
          },
          {
            headers: {
              Authorization: DEVISE_SECRET_KEY,
              'Content-Type': 'application/json',
            },
          },
        )
        .then((response) => {
          if (response.data) {
            if (this.props.isInMediaText === true) {
              store.dispatch(updateLines(mediatext?.mediaTextSong?.lines, palabra))
            }

            store.dispatch(getAllProgress())
            this.props.requestFlashcardSuccess()
          }
        })
        .catch(() => {})
    }
  }

  render() {
    let format = this.props.format
    let example = this.props.oracion.example
    let translation = this.props.oracion.translation
    let backgroundStyle = {
      backgroundImage: `url(${
        format === 'cloze' ? fondoLlenarElEspacioStudy : fondoTraduccionStudy
      })`,
    }

    return (
      <Fragment>
        <div className="flashcard-preview-container text-center">
          <div style={backgroundStyle} className="flashcard-side front">
            <div className="instrucciones-de-cloze">
              {format === 'cloze' && (
                <FormattedMessage
                  id={'flashcard.word-fill'}
                  defaultMessage={'"Selecciona la palabra a llenar.'}
                />
              )}
            </div>
            <div className="orange-text-gradient front-txt">
              {format === 'cloze' ? this.clozeWords(example) : translation}
            </div>
            <div className="pista-flashcard">
              {this.state.showHint && this.generateHint()}
            </div>
            <BtnPista
              action={() => this.toggleHint()}
              active={this.state.showHint ? true : false}
            />
          </div>

          <div className="flashcard-side back">
            <span className="answer-txt">
              {format === 'cloze' ? this.answerWords(example) : example}
              {format === 'cloze' && (
                <span className="original-sentence">{translation}</span>
              )}
            </span>
          </div>
        </div>
        <div className="flashcard-step-btn-container">
          <button
            className="flashcard-step-btn"
            onClick={() => this.saveFlashcard()}>
            <span>
              <FormattedMessage id={'flashcard.add'} defaultMessage={'Agregar'} />{' '}
              &nbsp;
              <img
                style={{position: 'relative', top: '2px'}}
                src={enviar}
                alt="enviar // send"
              />
            </span>
          </button>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  mediatext: state.mediatext,
})

export default connect(mapStateToProps)(Preview)
