import React from 'react'
import {useIntl} from 'react-intl'
import useLanguages from '../../hooks/useLanguages'
import iconCr from './Icons/iconCr.svg'
import iconWr from './Icons/iconWr.svg'

const getNameInInglish = (unicode) => {
  switch (unicode) {
    case 'en':
      return 'english'
    case 'es':
      return 'spanish'
    case 'fr':
      return 'french'
    default:
      return 'english'
  }
}

const OtherDictionaries = ({word}) => {
  const {activeUserLanguage, nativeUserLanguage} = useLanguages()
  const intl = useIntl()

  const openOtherDictionary = (link) => {
    window.open(link, 'dictionary', 'width=500,height=500') // "width=500,height=500"
  }
  return (
    <div
      className="text-white"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'calc(100% - 20px)',
        border: '1px solid #FFFFFF',
        boxSizing: 'border-box',
        borderRadius: '7px',
        padding: '5px 0 5px 5px',
      }}>
      <div
        className="truncar__text__line"
        style={{
          fontWeight: 'bold',
        }}>
        <span
          style={{
            textTransform: 'uppercase',
            textDecoration: 'underline',
          }}>
          {`${word} `}
        </span>
        {intl.formatMessage({
          id: 'diccionary.others-diccionaries',
          defaultMessage: 'in another dictionary:',
        })}
      </div>

      <div style={{marginLeft: '5px', width: '200px'}}>
        <span
          style={{cursor: 'pointer'}}
          onClick={() =>
            openOtherDictionary(
              `https://context.reverso.net/translation/${getNameInInglish(
                activeUserLanguage?.unicode,
              )}-${getNameInInglish(nativeUserLanguage?.unicode)}/${word}`,
            )
          }>
          <img src={iconCr} alt={iconCr} style={{width: '55px'}} />
        </span>
        <span
          onClick={() =>
            openOtherDictionary(
              `https://www.wordreference.com/${activeUserLanguage?.unicode}${nativeUserLanguage?.unicode}/${word}`,
            )
          }
          style={{marginLeft: '5px', cursor: 'pointer'}}>
          <img src={iconWr} alt={iconWr} style={{width: '55px'}} />
        </span>
      </div>
    </div>
  )
}

export default OtherDictionaries
