import Axios from 'axios'
import {
  SUBSCRIBE,
  CANCEL_SUBSCRIPTION,
  CHECK_SUBSCRIPTION,
  START_TRIAL,
  UPDATE_CARD,
} from '../../Services/EndPointApi'
import {errorHandler, StatusApi, getHeaders} from './helperData'

const checkUserSubscription = () => {
  return Axios.get(CHECK_SUBSCRIPTION, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data?.subscription && response?.data?.user) {
        const {subscription, user} = response?.data
        return {
          subscription,
          user,
        }
      } else {
        return {
          status: StatusApi.Unauthorized,
        }
      }
    })
    .catch(errorHandler('checkSubscriptions'))
}

const startTrial = async (id) => {
  return await Axios.put(`${START_TRIAL}/${id}`, {
    headers: getHeaders(),
  })
    .then((response) => {
      const {data} = response
      if (data) {
        return {
          data,
        }
      } else {
        return {
          message: 'No se pudo inicializar su cuenta como trial',
        }
      }
    })
    .catch(errorHandler('startTrial'))
}

const cancellSubscription = async (id) => {
  return await Axios.delete(CANCEL_SUBSCRIPTION, {
    headers: getHeaders(true),
  })
    .then((response) => {
      const {data} = response
      if (data) {
        return {
          data,
          status: true,
        }
      } else {
        return {
          message: 'No se pudo cancelar su subscripcion',
          status: false,
        }
      }
    })
    .catch(errorHandler('cancellSubscription'))
}

const subscriptionPlan = async (
  payload,
  plan_id,
  name = '',
  token,
  custom = false,
) => {
  const URL = custom ? UPDATE_CARD : SUBSCRIBE
  return await Axios.post(
    URL,
    {
      user: payload,
      stripeToken: token.id,
      plan_id,
      name,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      console.log(response.data)
      if (response?.data?.user) {
        const {message, user} = response.data
        return {
          message,
          user,
          status: StatusApi.OK,
        }
      } else {
        return {
          message: 'Error no se pudo crear la suscripcion',
        }
      }
    })
    .catch(errorHandler('sendUserInvitation'))
}

export {checkUserSubscription, startTrial, cancellSubscription, subscriptionPlan}
