import {useEffect, useState} from 'react'
import {useSelector, shallowEqual, useDispatch} from 'react-redux'
import {useDebounce} from 'use-debounce/lib'
import {
  findOneConversation,
  findActiveConversation,
  filterByParticipants,
} from '../helpers/utils/conversations'
import {
  setActiveConversationId,
  setIsHilosActive,
  setIsMiniChatActive,
  setMessageCitado,
  removeMessageCitado,
  setIsMiniChatSelectionActive,
} from '../Redux/Conversations/actions'

const useConversations = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.conversations, shallowEqual)
  const {conversations, activeConversationId} = state
  const [param, setParam] = useState('')
  const [paramMessage, setParamMessage] = useState('')
  const [conversationsFilter, setConversationsFilter] = useState([])
  const [debounceparamMessage] = useDebounce(paramMessage, 500)

  const filterConversations = () => {
    if (debounceparamMessage?.trim()?.length > 0) {
      const convsFilter = filterByParticipants(conversations, debounceparamMessage)
      setConversationsFilter(convsFilter || [])
    } else {
      setConversationsFilter([])
    }
  }

  useEffect(() => {
    filterConversations()
    // eslint-disable-next-line
  }, [debounceparamMessage])

  const conversationActive = activeConversationId
    ? findActiveConversation(conversations, activeConversationId)
    : null

  const setActiveConversation = (conversationId) => {
    dispatch(setActiveConversationId(conversationId))
  }

  const setMinichatClose = () => {
    dispatch(setIsMiniChatActive(false))
  }

  const setMinichatOpen = () => {
    dispatch(setIsMiniChatActive(true))
  }

  const setHilosClose = () => {
    dispatch(setIsHilosActive(false))
  }

  const setHilosOpen = () => {
    dispatch(setIsHilosActive(true))
  }

  const setCitedMessage = (message) => {
    dispatch(setMessageCitado(message))
  }

  const removeCitedMessage = () => {
    dispatch(removeMessageCitado())
  }

  // aciones para minichat: seleccionar conversacion y ver detalle
  const openMiniChatSelectionActive = () => {
    dispatch(setIsMiniChatSelectionActive(true))
  }

  const closeMiniChatSelectionActive = () => {
    dispatch(setIsMiniChatSelectionActive(false))
  }

  const findConversationById = (idConversation) => {
    return findOneConversation(conversations, idConversation)
  }

  return {
    ...state,
    conversations:
      paramMessage?.trim()?.length > 0 ? conversationsFilter : conversations,
    conversationActive,
    setActiveConversation,
    setMinichatClose,
    setMinichatOpen,
    setHilosClose,
    setHilosOpen,
    param,
    setParam,
    paramMessage,
    setParamMessage,
    setCitedMessage,
    removeCitedMessage,
    openMiniChatSelectionActive,
    closeMiniChatSelectionActive,
    findConversationById,
  }
}

export default useConversations
