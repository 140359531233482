import {TOGGLE_DICCIONARY, SET_PARAMS_DICCIONARY} from './diccionaryTypes'

const diccionaryInitialState = {
  isDiccionaryOpen: false,
  params: '',
  wordMark: null,
  mediaTextId: null,
  theMusicWasPlaying: false,
}

const diccionaryReducer = (state = diccionaryInitialState, action) => {
  switch (action.type) {
    case TOGGLE_DICCIONARY:
      return {
        ...state,
        isDiccionaryOpen: action.value,
      }
    case SET_PARAMS_DICCIONARY:
      return {
        ...state,
        params: action.params,
        wordMark: action.wordMark,
        mediaTextId: action.mediaTextId,
        theMusicWasPlaying: action.theMusicWasPlaying,
      }
    default:
      return state
  }
}

export default diccionaryReducer
