import {getProgressConverter} from '../helpers'
import {SET_ALL_PROGRESS, SUBTRACT_ONE_TO_NOTIFICATIONS_INBOX} from './actionTypes'

const initialStore = {
  review: {
    review_finish: 0,
    review_total: 0,
    review_progress: 0,
  },
  output: {
    output_finish: 0,
    output_total: 0,
    output_progress: 0,
  },
  input: {
    input_finish: 0,
    input_total: 0,
    input_progress: 0,
  },
  notifications: {
    inbox: 0,
    actions: 0,
  },
}

const progressReducer = (state = initialStore, action) => {
  switch (action.type) {
    case SUBTRACT_ONE_TO_NOTIFICATIONS_INBOX: {
      const {notifications} = state
      return {
        ...state,
        notifications: {
          inbox: (notifications?.inbox || 0) - 1,
          actions: notifications?.actions || 0,
        },
      }
    }

    case SET_ALL_PROGRESS: {
      const {review, output, input, inbox = 0, actions = 0} = action
      //flashcards
      const review_finish = review[0]
      const review_total = review[1]
      const review_progress = getProgressConverter(review_finish, review_total)

      //outputs
      const output_finish = output[0]
      const output_total = output[1]
      const output_progress = getProgressConverter(output_finish, output_total)

      //inputs
      const input_finish = input[0]
      const input_total = input[1]
      const input_progress = getProgressConverter(input_finish, input_total)

      return {
        ...state,
        review: {
          review_finish,
          review_total,
          review_progress,
        },
        output: {
          output_finish,
          output_total,
          output_progress,
        },
        input: {
          input_finish,
          input_total,
          input_progress,
        },
        notifications: {
          inbox,
          actions,
        },
      }
    }

    default:
      return state
  }
}

export default progressReducer
