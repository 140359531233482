import Axios from 'axios'
import {
  CHANGE_OPTIONS_TUTORIAL,
  GET_NOTIFICATIONS,
  READ_NOTIFICATION,
  UPDATE_NOTIFICATIONS_SETTINGS,
  UPDATE_PUSH_SUBSCRIPTION,
} from '../../Services/EndPointApi'
import {ApiMessages, errorHandler, getHeaders, StatusApi} from './helperData'

const getNotifications = () => {
  return Axios.get(GET_NOTIFICATIONS, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data?.notifications) {
        const {notifications} = response.data
        return {
          notifications,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('getNotifications'))
}

const updateTutorials = (params) => {
  return Axios.post(
    CHANGE_OPTIONS_TUTORIAL,
    {
      option: params,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data) {
        const {options_tutorial} = response.data
        return {
          options_tutorial,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('updateTutorial'))
}

const readNotification = (id) => {
  return Axios.put(
    `${READ_NOTIFICATION}/${id}`,
    {},
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.notification_data) {
        const {
          data: {notification_data},
        } = response
        return {
          notificationData: notification_data,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('readNotification'))
}

// para ajustes de notificaciones
const updateNotificationsSettings = (notifications_options) => {
  return Axios.post(
    UPDATE_NOTIFICATIONS_SETTINGS,
    {
      notifications_options,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data) {
        console.log("response.data", response.data)
        return {
          notificationsOptions: response.data,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('updateTutorial'))
}

const suscribePushNotification = (pushId) => {
  return Axios.post(
    UPDATE_PUSH_SUBSCRIPTION,
    {
      push_id: pushId,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then(() => {
      return {
        status: StatusApi.OK,
      }
    })
    .catch(errorHandler('subscribeNotification'))
}

export {
  getNotifications,
  readNotification,
  updateTutorials,
  updateNotificationsSettings,
  suscribePushNotification,
}
