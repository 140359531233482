import Axios from 'axios'
import {getHeaders, errorHandler, ApiMessages} from './helperData'
import {
  CREAR_NOTA,
  USER_BY_AT,
  LISTA_DE_PREGUNTAS,
  MOSTRAR_PREGUNTA,
  VOTAR,
  PERFIL_DE_USUARIO,
  BUSCAR_EN_COMUNIDAD,
  DESTROY_ITEM,
  INBOX_PERSONAL,
  INBOX_NUESTRO,
  UPDATE_ITEM,
  SHOW_ITEM,
  ELIMINAR_ITEM,
  RETROALIMENTAR_OUTPUT,
  REVISAR_INPUT,
  CORREGIR_FLASHCARD,
  MARK_ITEM_AS_READ,
  CORRECT_REPORT_INPUT,
} from '../../Services/EndPointApi'

// apis para flowboard

const getItems = (inboxType, domainActiveId) => {
  return Axios.get(
    `${
      inboxType === 'Personal' ? INBOX_PERSONAL : INBOX_NUESTRO
    }?domain_id=${domainActiveId}`,
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.inbox_items) {
        const {inbox_items} = response.data
        return {
          items: inbox_items,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('getItems'))
}

// MARK_ITEM_AS_READ

const markItemAsRead = (itemId) => {
  return Axios.put(
    MARK_ITEM_AS_READ,
    {
      item_id: itemId,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.item) {
        const {item} = response.data
        return {
          item,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('markItemAsRead'))
}

const acionarItem = (item, state = 'ready', userId) => {
  return Axios.put(
    UPDATE_ITEM + `${item.id}`,
    {
      item: {
        state,
        user_id: userId,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.item) {
        const {item} = response.data
        return {
          item,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('acionarItem'))
}

const getItemizableById = (itemId) => {
  return Axios.get(`${SHOW_ITEM}/${itemId}`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data) {
        const {itemizable, user, item} = response.data

        return {
          item,
          itemizable,
          user,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('getItemizableById'))
}

const deleteItemById = (itemId) => {
  return Axios.delete(`${ELIMINAR_ITEM}/${itemId}`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response.data) {
        return {
          data: response.data,
        }
      } else {
        return {
          message: ApiMessages.NotFound,
        }
      }
    })
    .catch(errorHandler('deleteItemById'))
}

const feedbackOutput = (
  itemId,
  itemizableId,
  marked_sentences,
  audio,
  teacherComment = '',
) => {
  let data = new FormData()

  data.append('item_id', itemId)
  data.append('id', itemizableId)
  data.append('marked_sentences', JSON.stringify(marked_sentences))
  data.append('audio_output_teacher', audio)

  //Este comentario solo
  if (teacherComment !== '') data.append('comentario', teacherComment)

  return Axios.post(RETROALIMENTAR_OUTPUT, data, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response.data) {
        return {data: response.data}
      } else {
        return {message: ApiMessages.NoContent}
      }
    })
    .catch(errorHandler('feedbackOutput'))
}

const checkInput = (mediatextId, itemId, markedLines = []) => {
  return Axios.post(
    REVISAR_INPUT,
    {
      media_text: {
        id: mediatextId,
        item_id: itemId,
        marked_lines: markedLines,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response.data) {
        return {data: response.data}
      } else {
        return {message: ApiMessages.NotFound}
      }
    })
    .catch(errorHandler('checkInput'))
}

const correctReportInput = (mediatextId, itemId, markedLines = []) => {
  return Axios.post(
    CORRECT_REPORT_INPUT,
    {
      media_text: {
        id: mediatextId,
        item_id: itemId,
        marked_lines: markedLines,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response.data) {
        return {data: response.data}
      } else {
        return {message: ApiMessages.NotFound}
      }
    })
    .catch(errorHandler('correctReportInput'))
}

const checkFlashcard = (itemizable, phrase, phraseTranslation, itemId) => {
  return Axios.put(
    CORREGIR_FLASHCARD,
    {
      item: {
        id: itemizable.id,
        is_verified: true,
        phrase: phrase,
        phrase_translation: phraseTranslation,
        tags: itemizable.tags ? itemizable.tags : [],
        special_note: itemizable.special_note ? itemizable.special_note : '',
        trans_tags: itemizable.trans_tags ? itemizable.trans_tags : [],
        trans_special_note: itemizable.trans_special_note
          ? itemizable.trans_special_note
          : '',
        item_id: itemId,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response.data) {
        return {data: response.data}
      } else {
        return {message: ApiMessages.NotFound}
      }
    })
    .catch(errorHandler('checkFlashcard'))
}

const createNote = (title, body, atUsersIds, selectedDate, domainId) => {
  return Axios.post(
    CREAR_NOTA,
    {
      title,
      notes: body,
      at_users_ids: atUsersIds,
      next_action_date: selectedDate,
      domain_id: domainId,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response.data) {
        return {
          status: true,
        }
      } else {
        return {
          message: 'No se pudo crear la nota',
        }
      }
    })
    .catch(errorHandler('createNote'))
}

const sendVote = (vote, votableType, votableId) => {
  return Axios.post(
    VOTAR,
    {
      vote: {
        up: vote,
        votable_type: votableType,
        votable_id: votableId,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      const {data} = response
      if (data) {
        const {vote} = data
        return {
          vote,
        }
      } else {
        return {
          message: 'No se pudo efectuar el voto',
        }
      }
    })
    .catch(errorHandler('votar'))
}

const getUsers = (term = '') => {
  return Axios.get(`${USER_BY_AT}?term=${term}`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      const {data} = response
      if (data) {
        const {users} = data
        return {
          users,
        }
      } else {
        return {
          message: 'No se obtener los usuarios',
        }
      }
    })
    .catch(errorHandler('getUsers'))
}

const getProfile = (userId) => {
  return Axios.get(
    PERFIL_DE_USUARIO,
    {
      params: {
        id: userId,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      const {data} = response
      if (data) {
        return {
          data,
          questions: data.user_questions,
        }
      } else {
        return {
          message: 'No se pudo obtener el perfil',
        }
      }
    })
    .catch(errorHandler('getProfile'))
}

const getCommunity = (term) => {
  return Axios.get(BUSCAR_EN_COMUNIDAD + term, {
    headers: getHeaders(true),
  })
    .then((response) => {
      const {data} = response
      if (data) {
        const {users, posts} = data
        return {
          users,
          posts,
        }
      } else {
        return {
          message: 'No se pudo obtener la comunidad',
        }
      }
    })
    .catch(errorHandler('getCommunity'))
}

const getQuestions = () => {
  return Axios.get(LISTA_DE_PREGUNTAS, {
    headers: getHeaders(true),
  })
    .then((response) => {
      const {data} = response
      if (data) {
        const {posts} = data
        return {
          posts,
        }
      } else {
        return {
          message: 'No se obtener las Preguntas',
        }
      }
    })
    .catch(errorHandler('getQuestions'))
}

const showQuestions = async (id) => {
  return await Axios.get(`${MOSTRAR_PREGUNTA}/${id}`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      const {data} = response
      if (data) {
        const {post} = data
        return {
          post,
        }
      } else {
        return {
          message: 'No se mostrar las Preguntas',
        }
      }
    })
    .catch(errorHandler('showQuesions'))
}

const deleteItem = (id) => {
  return Axios.delete(`${DESTROY_ITEM}/${id}`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data?.item) {
        const {data} = response
        return {
          item: data?.item,
        }
      } else {
        return {
          message: 'No se destruyo el item',
        }
      }
    })
    .catch(errorHandler('deleteItem'))
}

export {
  getItems,
  markItemAsRead,
  acionarItem,
  getItemizableById,
  deleteItemById,
  feedbackOutput,
  checkInput,
  correctReportInput,
  checkFlashcard,
  createNote,
  getUsers,
  getQuestions,
  showQuestions,
  sendVote,
  getProfile,
  getCommunity,
  deleteItem,
}
