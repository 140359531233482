import axios from 'axios'
import {getToken} from '../../Services/getToken'
import {ITEMS} from '../../Services/EndPointApi'

const TOKEN_AUTH = {Authorization: getToken()}

export const fetchItems = () => {
  return axios({
    method: 'get',
    url: ITEMS,
    headers: {
      Accept: 'application/json',
      Authorization: getToken(),
    },
  })
}

export const createItem = (item) => {
  return fetch(ITEMS, {
    method: 'POST',
    headers: TOKEN_AUTH,
    body: item,
  })
}
