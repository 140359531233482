import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Button = styled.button`
  margin: 0;
  min-width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.br}px;
  border: solid 1px transparent;
  background-image: linear-gradient(175deg, #ff5962 16.06%, #fd935e 91.7%),
    linear-gradient(175deg, #ff5962 16.06%, #fd935e 91.7%),
    linear-gradient(175deg, #ff5962 16.06%, #fd935e 91.7%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px ${(props) => props.background} inset;
  padding: 0 3px 0 3px:
  color: white;
  font-weight: ${(props) => props.fontWeight} !important;
  font-size: ${(props) => props.fontSize}px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: none;
    color: white;
    cursor: pointer;
  }
`

const borderRadius = (shape) => {
  switch (shape) {
    case 'rectangle':
      return 7
    case 'circle':
      return 0
    case 'ellipse':
      return 50
    default:
      return 0
  }
}

const BotonConBordeDegradado = (props) => {
  const {
    shape,
    width,
    height,
    fontSize,
    fontWeight,
    background,
    disabled,
    style,
  } = props

  return (
    <Button
      {...props}
      disabled={disabled}
      className="btn btn-primary"
      shape={shape}
      br={borderRadius(shape)}
      width={width}
      height={height}
      fontSize={fontSize}
      fontWeight={fontWeight}
      background={background}
      style={style}
      onClick={props.function}>
      {props.children}
    </Button>
  )
}

BotonConBordeDegradado.propTypes = {
  shape: PropTypes.string,
  function: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  background: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
}

BotonConBordeDegradado.defaultProps = {
  shape: 'rectangle',
  fontSize: 14,
  fontWeight: 400 /* Esta medida equivale a "normal" */,
  background: '#1B2734',
  disabled: false,
  style: {},
}

export default BotonConBordeDegradado
