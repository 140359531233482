import {
  FETCH_PLAYLISTS_REQUEST_START,
  FETCH_PLAYLISTS_REQUEST_FINISH,
  FETCH_PLAYLISTS_REQUEST_ERROR,
  EMPTY_PLAYLISTS_DATA,
  PLAYLIST_EMPTY,
  EMPTY_ERROR,
  FETCH_PLAYLIST_BY_ID_REQUEST_START,
  FETCH_PLAYLIST_BY_ID_REQUEST_FINISH,
  FETCH_PLAYLIST_BY_ID_REQUEST_ERROR,
  DELETE_TRACK_OF_PLAYLIST,
  ADD_PLAYLIST,
  ADD_ONE_TO_PLAYLIST,
  UPDATE_TRACKS_PLAYLIST_FROM_LIST,
} from './playlistTypes'
import {replaceTrackFromLike} from '../helpers'

const DEFAULT_PLAYLIST_OPEN = {
  playlist: PLAYLIST_EMPTY,
  loading: true,
  error: EMPTY_ERROR,
}

const playlistsInitialState = {
  myLike: EMPTY_PLAYLISTS_DATA.myLike,
  playlists: EMPTY_PLAYLISTS_DATA.playlists,
  loading: false,
  error: EMPTY_ERROR,
  playlistOpen: DEFAULT_PLAYLIST_OPEN,
}

const playlistsReducer = (state = playlistsInitialState, action) => {
  switch (action.type) {
    case ADD_ONE_TO_PLAYLIST:
      const mapedPlaylists = state.playlists.map((p) => {
        if (p.id === action.payload.id) {
          return {
            ...p,
            song_count: p.song_count + 1,
          }
        } else {
          return p
        }
      })

      return {
        ...state,
        playlists: mapedPlaylists,
      }

    case ADD_PLAYLIST:
      return {
        ...state,
        playlists: [action.payload.playlist, ...state.playlists],
      }

    case FETCH_PLAYLISTS_REQUEST_START:
      return {
        ...state,
        loading: true,
        error: EMPTY_ERROR,
      }

    case FETCH_PLAYLISTS_REQUEST_FINISH:
      return {
        ...state,
        loading: false,
        ...action.payload,
      }

    case FETCH_PLAYLISTS_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        ...action.payload.error,
      }

    // PlaylistOpen
    case FETCH_PLAYLIST_BY_ID_REQUEST_START:
      return {
        ...state,
        playlistOpen: DEFAULT_PLAYLIST_OPEN,
      }

    case FETCH_PLAYLIST_BY_ID_REQUEST_FINISH:
      return {
        ...state,
        playlistOpen: {
          ...state.playlistOpen,
          ...action.payload,
          loading: false,
        },
      }

    case FETCH_PLAYLIST_BY_ID_REQUEST_ERROR:
      return {
        ...state,
        playlistOpen: {
          ...state.playlistOpen,
          loading: false,
          error: action.payload.error,
        },
      }

    // delete track of playlist
    case DELETE_TRACK_OF_PLAYLIST:
      const {playlist} = state.playlistOpen
      let newTracks
      if (playlist.tracks) {
        newTracks = playlist.tracks.filter(
          (t) => t.spotify_id !== action.payload.spotifyId,
        )
      }
      return {
        ...state,
        playlistOpen: {
          ...state.playlistOpen,
          playlist: {
            ...playlist,
            tracks: newTracks,
          },
        },
      }

    case UPDATE_TRACKS_PLAYLIST_FROM_LIST:
      const {playlistOpen} = state

      const {tracks = []} = playlistOpen.playlist || []
      const tracksMapped = replaceTrackFromLike(action.payload.track, tracks)

      const newPlaylist = {
        ...playlistOpen.playlist,
        tracks: tracksMapped,
      }

      return {
        ...state,
        playlistOpen: {
          ...playlistOpen,
          playlist: newPlaylist,
        },
      }

    default:
      return state
  }
}

export default playlistsReducer
