export const GET_NEW_FLASHCARD = 'GET_NEW_FLASHCARD'
export const GET_NEW_FLASHCARD_START = 'GET_NEW_FLASHCARD_START'

export const UPDATE_CORRECT_FLASHARDS = 'UPDATE_CORRECT_FLASHARDS'
export const UPDATE_TROPHIES_WONDS = 'UPDATE_TROPHIES_WONDS'
export const CHANGE_ANSWER = 'CHANGE_ANSWER'
export const CHANGE_FLASHCARD_ACTIVE = 'CHANGE_FLASHCARD_ACTIVE'
export const YEAH_FINISH_FLASHCARD = 'YEAH_FINISH_FLASHCARD'

export const FLASHCARD_VIEWS = {
  ANSWER_UNKNOW: 'ANSWER_UNKNOW',
  ANSWER_FAIL: 'ANSWER_FAILS',
  ANSWER_SUCCESS: 'ANSWER_SUCCESS',
  STUDY_FLASHCARDS: 'STUDY_FLASHCARDS',
  SHOW_FLASHCARD_REVISADAS: 'SHOW_FLASHCARD_REVISADAS',
}
