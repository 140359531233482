import {
  FETCH_MYINPUTS_REQUEST_ERROR,
  FETCH_MYINPUTS_REQUEST_FINISH,
  FETCH_MYINPUTS_REQUEST_START,
  UPDATE_TRACK_MYINPUTS_LIKE,
} from './actionTypes'
import {getMyInputStudied} from '../../lib/coco/InputApi'

const startMyInputsRequest = () => {
  return {
    type: FETCH_MYINPUTS_REQUEST_START,
    payload: {},
  }
}

const completeMyInputsRequest = (tracks = []) => {
  return {
    type: FETCH_MYINPUTS_REQUEST_FINISH,
    payload: {tracks},
  }
}

const errorMyInputsRequest = (error) => {
  return {
    type: FETCH_MYINPUTS_REQUEST_ERROR,
    payload: {error},
  }
}

const getMyInputsStudied = () => (dispatch) => {
  dispatch(startMyInputsRequest())

  return getMyInputStudied().then((response) => {
    if (response?.error) {
      dispatch(errorMyInputsRequest(response.error))
    } else {
      dispatch(completeMyInputsRequest(response.tracks))
    }
  })
}

const updateTrackMyInputs = (track) => {
  return {
    type: UPDATE_TRACK_MYINPUTS_LIKE,
    payload: {track},
  }
}

export {getMyInputsStudied, updateTrackMyInputs}
