import React, {Component} from 'react'
import BtnPista from '../Buttons/BtnPista'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PeculiaridadesPrincipal from '../PeculiaridadesTags/PeculiaridadesPrincipal'
import TextareaAutosize from 'react-autosize-textarea/lib'

// Styles
const Card = styled.div`
  border-radius: 10px;
  background: white;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  display: flex;

  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`

//Este componente es la parte trasera del flashcard(fondo blanco).
class FlashcardBack extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
  }

  activate = () => {
    this.setState({
      active: !this.state.active,
    })
  }

  render() {
    const {active} = this.state
    const {
      itemizable,
      setItemizable,
      tags,
      phrase,
      showTrackButton,
      toggleScreen,
      width,
      height,
      // editable,
      getPhrase,
    } = this.props

    return (
      <>
        <Card
          width={width}
          height={height}
          onClick={toggleScreen}
          className="flashcard__mobile__container">
          <TextareaAutosize
            value={phrase}
            className="gray-color"
            onChange={(event) => {
              if (getPhrase) getPhrase(event.target.value)
            }}
          />
          {itemizable && (
            <div className="w-100 px-4" style={{color: 'white'}}>
              <div className="contenedor-peculiaridades-listado-scroll">
                <PeculiaridadesPrincipal
                  objeto_seleccionado={itemizable}
                  specialnote_a_modificar="special_note"
                  tag_a_modificar="tags"
                  nota_especial_text={
                    itemizable?.special_note ? itemizable.special_note : ''
                  }
                  non_lang_tags={tags.non_lang_tags}
                  lang_tags={tags.lang_tags}
                  Actualizar_nuevo_objeto={setItemizable}
                  notificar_estado_peculiaridad={() => {}}
                  tipo_tag="back"
                />
              </div>
            </div>
          )}

          {showTrackButton && (
            <BtnPista
              action={() => this.activate()}
              active={active ? true : false}
            />
          )}
        </Card>
      </>
    )
  }
}

FlashcardBack.propTypes = {
  phrase: PropTypes.string.isRequired,
  showTrackButton: PropTypes.bool,
  toggleScreen: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  editable: PropTypes.bool,
}

FlashcardBack.defaultProps = {
  showTrackButton: false,
  editable: true,
  getPhrase: () => {},
  toggleScreen: () => {},
}

export default FlashcardBack
