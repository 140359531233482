// tipos de vistas para el timer
const StateSuscription = {
  PREVIEW_SUSCRIPTION_72: 72,
  PREVIEW_SUSCRIPTION_48: 48,
  PREVIEW_SUSCRIPTION_24: 24,
  PREVIEW_SUSCRIPTION_COMPLETED: 0,
  PREVIEW_SUCCESS: 'GOOD',
}

// muestra vista de el form, cuando la suscripcion se realiza con exito y cuando falla
const SuscriptionStateView = {
  SUSCRIPTION_REQUEST_ERROR: 'SUSCRIPTION_REQUEST_ERROR',
  SUSCRIPTION_REQUEST_SUCCESS: 'SUSCRIPTION_REQUEST_SUCCESS',
  SUSCRIPTION_VIEW_FORM: 'SUSCRIPTION_VIEW_FORM',
}

const isSuscriptionViewRequestSuccess = (state) => {
  return state === SuscriptionStateView.SUSCRIPTION_REQUEST_SUCCESS
}
const isSuscriptionViewRequestError = (state) => {
  return state === SuscriptionStateView.SUSCRIPTION_REQUEST_ERROR
}
const isSuscriptionViewForm = (state) => {
  return state === SuscriptionStateView.SUSCRIPTION_VIEW_FORM
}

const isSuscriptionStatePreview72 = (state) => {
  return state === StateSuscription.PREVIEW_SUSCRIPTION_72
}

const isSuscriptionStatePreview48 = (state) => {
  return state === StateSuscription.PREVIEW_SUSCRIPTION_48
}
const isSuscriptionStatePreview24 = (state) => {
  return state === StateSuscription.PREVIEW_SUSCRIPTION_24
}
const isSuscriptionStatePreviewComplete = (state) => {
  return state === StateSuscription.PREVIEW_SUSCRIPTION_COMPLETED
}

const isSuscriptionStatePreviewSuccess = (state) => {
  return state === StateSuscription.PREVIEW_SUCCESS
}

const timeDiffCalc = (dateFuture) => {
  const date_now = new Date()

  let seconds = Math.floor((dateFuture - date_now) / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)
  let days = Math.floor(hours / 24)

  hours = hours - days * 24
  minutes = minutes - days * 24 * 60 - hours * 60
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60

  return {
    hours: days * 24 + hours,
    minutes,
    seconds,
  }
}

const calculateSuscriptionPreview = (hours, minutes, seconds) => {
  if (hours > 71) {
    return StateSuscription.PREVIEW_SUCCESS
  } else if (hours < 72 && hours >= 48) {
    return StateSuscription.PREVIEW_SUSCRIPTION_72
  } else if (hours < 48 && hours >= 24) {
    return StateSuscription.PREVIEW_SUSCRIPTION_48
  } else if (hours < 24 && hours >= 0 && minutes >= 0 && seconds > 0) {
    return StateSuscription.PREVIEW_SUSCRIPTION_24
  } else if (hours <= 0 && minutes >= 0 && seconds > 0) {
    return StateSuscription.PREVIEW_SUSCRIPTION_COMPLETED
  }
}

const getDifferenceBeetweenDays = (firtDate, seconddate) => {
  const nowDate = new Date(firtDate).getTime()
  const dateEnd = new Date(seconddate).getTime()

  const diff = dateEnd - nowDate

  const diffDays = diff / (1000 * 60 * 60 * 24)
  const diffDaysAproximate = diffDays >= 0 ? Math.round(diffDays) : 0

  return {diffDays, diffDaysAproximate}
}

const OptionsCardStripe = {
  style: {
    base: {
      fontSize: ' 20px',
      lineHeight: '32px',
      color: '#8e929a',
      fontWeight: 'normal',
      '::placeholder': {
        fontSize: ' 20px',
        lineHeight: '32px',
        color: '#8e929a',
        fontWeight: 'normal',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

export {
  timeDiffCalc,
  StateSuscription,
  SuscriptionStateView,
  isSuscriptionViewRequestSuccess,
  isSuscriptionViewRequestError,
  isSuscriptionViewForm,
  calculateSuscriptionPreview,
  getDifferenceBeetweenDays,
  isSuscriptionStatePreview72,
  isSuscriptionStatePreview48,
  isSuscriptionStatePreview24,
  isSuscriptionStatePreviewComplete,
  isSuscriptionStatePreviewSuccess,
  OptionsCardStripe,
}
