import React, {useState} from 'react'
import Phrase from './Phrase'
import useBooleanToggler from '../../hooks/useBooleanToggler'
import Modal from '../Modal'
import TranslationFlashcardForm from '../FlashCards/TranslationFlashcardForm'
import useMediaText from '../../hooks/useMediaText'
import {useIntl} from 'react-intl'
import useDiccionary from '../../hooks/useDiccionary'
import useSpotify from '../../hooks/useSpotify'

const BodyDictionary = ({
  phrases = [],
  wordMark,
  mediaTextId,
  phraseIdsAgregados = [],
  updateIndicesAddPhrasesCoco,
}) => {
  const {
    dispatchCloseDiccionary: closeDiccionary,
    theMusicWasPlaying,
  } = useDiccionary()
  const {stopTracksSpotify} = useSpotify()
  const intl = useIntl()
  const {dispatchUpdateLines} = useMediaText()
  const [phraseSelected, setPhraseSelected] = useState(null)
  const {isToggled: isAddFlashcard, toggle: toggleAddFlashcard} = useBooleanToggler()

  // abrir modal de flashcard
  const openAddFlashcard = (phrase) => {
    setPhraseSelected(phrase)
    toggleAddFlashcard()
  }

  // cierra todo
  const closeAddFlashcard = () => {
    // validate player
    // stopTracksSpotify
    if (theMusicWasPlaying) {
      stopTracksSpotify()
    }
    closeDiccionary()
  }

  // solo cerrar el modal de flashcard
  const continueAddFlashcard = () => {
    toggleAddFlashcard()
  }

  // actualizar las palabra estudiadas cuando sea desde un mediatext
  // recibe el memorizedTerm para actualizar la lista de palabras
  const updateIndicesYaAgregados = (memorizedTerm) => {
    if (wordMark) {
      dispatchUpdateLines(wordMark, memorizedTerm?.id)
    }

    if (updateIndicesAddPhrasesCoco) {
      updateIndicesAddPhrasesCoco(phraseSelected?.phrase_id)
    }

    setPhraseSelected(null)
  }

  return (
    <>
      <div className="diccionary-body scroll-coco">
        {phrases?.length &&
          phrases.map((phrase, index) => {
            return (
              <Phrase
                key={phrase?.phrase_id || index}
                phrase={phrase?.phrase}
                phraseTranslation={phrase?.phrase_translation}
                createdBy={phrase?.created_by || null}
                verifiedBy={phrase?.verified_by || null}
                addToFlashcard={() => openAddFlashcard(phrase)}
                isPhraseAdd={phraseIdsAgregados.includes(phrase?.phrase_id)}
                intl={intl}
              />
            )
          })}
      </div>

      {isAddFlashcard && (
        <Modal
          modalType="output"
          show={isAddFlashcard}
          closeClickOutside
          closePressEsc
          closeRound
          closeModal={continueAddFlashcard}>
          <TranslationFlashcardForm
            phrase={phraseSelected?.phrase}
            phraseTranslation={phraseSelected?.phrase_translation}
            showButtonsOptions
            closeModal={closeAddFlashcard}
            continueAddFlashcard={continueAddFlashcard}
            // el siguiente param e para enviar atributos extras al request
            paramsItemOptionsRequest={
              wordMark
                ? {
                    term_id: wordMark?.term_id,
                    line_index_in_text: wordMark?.line_index,
                    form: wordMark?.form,
                    lemma: wordMark?.lemma,
                    part_of_speech: wordMark?.part_of_speech,
                    itemizable_id: mediaTextId,
                    itemizable_type: 'MediaText',
                    phrase_id: phraseSelected?.phrase_id,
                  }
                : {
                    itemizable_type: 'Phrase',
                    phrase_id: phraseSelected?.phrase_id,
                    phrase_translation_id: phraseSelected?.phrase_translation_id,
                  }
            }
            paramsLangCodeItemRequest={
              wordMark && {
                lang_code: wordMark?.lang_code,
              }
            }
            updateIndicesYaAgregados={updateIndicesYaAgregados}
          />
        </Modal>
      )}
    </>
  )
}

export default BodyDictionary
