// eslint-disable-next-line
const REGPHONE = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/
const REGEMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

const ValidateEmail = (email) => {
  if (REGEMAIL.test(email)) {
    return true
  }
  return false
}

const ValidateUSPhoneNumber = (phoneNumber) => {
  if (REGPHONE.test(phoneNumber)) {
    return true
  } else {
    return false
  }
}

const ValidateNumbers = (numbers) => {
  var regex_numeros = /^[0-9]+$/
  if (regex_numeros.test(numbers)) {
    return true
  } else {
    return false
  }
}

const comparePassword = (value1, value2) => {
  if (value1 === value2) {
    return true
  }
  return false
}

const validateLength = (value, length) => {
  if (value?.length >= length) {
    return true
  }
  return false
}

const existValue = (value) => {
  if (value) {
    return true
  }
  return false
}

const restrictLength = (val) => {
  if (validateLength(val, 1) && val.length < 2) {
    return true
  }
  return false
}

const tokenValidation = (token) => {
  if (token != null && token?.length > 0) {
    return true
  }
  return false
}

const PreferencesType = {
  TRACK: 'TRACK',
  ARTIST: 'ARTIST',
}

// local storage para onboarding data

const ONBOARDING_DATA_STORAGE = 'ONBOARDING_DATA_STORAGE'

const setOnboardingDataLocalStorage = (data) => {
  localStorage.setItem(ONBOARDING_DATA_STORAGE, JSON.stringify(data))
}

const getOnboardingDataStorage = () => {
  return JSON.parse(localStorage.getItem(ONBOARDING_DATA_STORAGE))
}

const deleteOnboardingDataStorage = () => {
  localStorage.removeItem(ONBOARDING_DATA_STORAGE)
}

export {
  ValidateEmail,
  ValidateUSPhoneNumber,
  ValidateNumbers,
  comparePassword,
  validateLength,
  existValue,
  restrictLength,
  tokenValidation,
  PreferencesType,
  setOnboardingDataLocalStorage,
  getOnboardingDataStorage,
  deleteOnboardingDataStorage,
}
