import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {
  toogleDiccionary,
  setParamsDiccionary,
} from '../Redux/Diccionary/diccionaryActions'

const useDiccionary = () => {
  const stateDiccionary = useSelector((state) => state.diccionary, shallowEqual)

  const dispatch = useDispatch()

  // dispatch a store
  const dispatchOpenDiccionary = () => {
    dispatch(toogleDiccionary(true))
  }
  const dispatchCloseDiccionary = () => {
    dispatch(toogleDiccionary(false))
    dispatch(setParamsDiccionary(''))
  }
  const dispatchSetParamsDiccionary = (
    params,
    wordMark,
    mediaTextId,
    theMusicWasPlaying,
  ) => {
    dispatch(setParamsDiccionary(params, wordMark, mediaTextId, theMusicWasPlaying))
  }

  return {
    ...stateDiccionary,
    dispatchOpenDiccionary,
    dispatchCloseDiccionary,
    dispatchSetParamsDiccionary,
  }
}

export default useDiccionary
