import React, {useEffect, useState} from 'react'

import InputRange from 'react-input-range'
import {useModalSpotifyAuth} from '../../hooks/useSpotifyModalAuth'
import './Range.css'
import 'react-input-range/lib/css/index.css'

const Range = ({value, maxValue, handleOnChange}) => {
  const [valueRange, setValueRange] = useState(value)
  const [isMoving, setIsMoving] = useState(false)

  const {toggleSpotifyModalAuth, isSpotifyConnected} = useModalSpotifyAuth()
  const spotifyStatus = isSpotifyConnected()

  useEffect(() => {
    if (!isMoving) {
      setValueRange(value)
    }
  }, [value, isMoving])

  const onChangeComplete = (values) => {
    handleOnChange(values)
    setIsMoving(false)
  }

  const onChangeStart = () => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
      return
    }
    setIsMoving(true)
  }

  const onChange = (values) => {
    setValueRange(values)
  }

  return (
    <InputRange
      style={{position: 'relative'}}
      maxValue={maxValue}
      minValue={0}
      value={valueRange || 0}
      onChangeStart={onChangeStart}
      onChangeComplete={onChangeComplete}
      onChange={onChange}
    />
  )
}

export default Range
