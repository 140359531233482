import React from 'react'
import PronounceSentence from '../Translator/PronounceSentence'

const DiccionaryCocoSearchWord = ({
  phrases,
  searchedWord,
  wordMark,
  traductionDirecta = '',
}) => {
  return (
    <>
      {phrases?.length > 0 && (
        <div className="searched-word">
          {searchedWord}
          <PronounceSentence oracion={searchedWord} langCode={wordMark?.lang_code} />

          <p className="searched-word-traduccion-directa">{traductionDirecta}</p>
        </div>
      )}
    </>
  )
}

export default DiccionaryCocoSearchWord
