import {useState} from 'react'
import {compareObjets} from '../Services/CadenasSplit'

const clearValue = (value) => {
  switch (typeof value) {
    case 'string':
      return ''
    case 'number':
      return 0
    case 'boolean':
      return false
    case 'object':
      return []

    default:
      break
  }

  return ''
}

const useForm = (initialState = {}) => {
  const [form, setForm] = useState(initialState)

  const cleanForm = () => {
    const keysForm = Object.keys(form)
    let newFormValues = {
      ...form,
    }
    keysForm.forEach((element) => {
      newFormValues[element] = clearValue(element)
    })

    setForm({
      ...form,
      ...newFormValues,
    })
  }

  const handleChange = ({target}) => {
    const {name, value} = target

    setForm({
      ...form,
      [name]: value,
    })
  }

  // funcion para saber si los valores son distintos a los por defaults.
  const isFormEqualToInitialState = () => {
    return compareObjets(initialState, form)
  }

  const isFormHaveChange = !isFormEqualToInitialState() // true si ha cambiado la data

  return {
    form,
    handleChange,
    cleanForm,
    isFormEqualToInitialState,
    isFormHaveChange,
    ...form,
  }
}

export default useForm

// este custom hook: useForm(), se utiliza para reutilizar
// logica siempre que se necesite construir componentes de formularios
// EJEMPLO:

// const initialState = {
//   email: "cris@gmail.com",
//   password: "xyz",
// };

// const { handleChange, email, password, form } = useForm(initialState);

// const handleSubmit = (ev) => {
//   ev.preventDefault();
//   console.log(form);
// };

// return (
//   <form onSubmit={handleSubmit}>
//     <input onChange={handleChange} name="email" value={email} />
//     <input onChange={handleChange} name="password" value={password} />
//   </form>
// );
