import {useEffect, useState} from 'react'
import usePlatform from './usePlatform'

const useKeyBoard = () => {
  const [isKeyBoardActive, SetIsKeyBoardActive] = useState(false)
  const {isDeviseMobile} = usePlatform()

  useEffect(() => {
    const focusHandler = (event) => {
      const type = event.target.nodeName?.toLowerCase()
      if (type === 'input' || type === 'textarea') {
        SetIsKeyBoardActive(true)
      }
    }

    const blurHandler = (event) => {
      const type = event.target.nodeName?.toLowerCase()
      if (type === 'input' || type === 'textarea') {
        SetIsKeyBoardActive(false)
      }
    }

    document.body.addEventListener('focus', focusHandler, true)
    document.body.addEventListener('blur', blurHandler, true)

    return () => {
      document.body.removeEventListener('focus', focusHandler, true)
      document.body.removeEventListener('blur', blurHandler, true)
    }
  }, [])

  const keyBoardActiveOnMobile = () => {
    if (isDeviseMobile && isKeyBoardActive) return true
    return false
  }

  const isKeyBoardActiveOnMobile = keyBoardActiveOnMobile()

  return {
    isKeyBoardActive,
    isKeyBoardActiveOnMobile,
  }
}

export default useKeyBoard
