import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import useAlert, {TOAST_TYPE} from '../../hooks/useAlert'
import useCopyToClipboard from '../../hooks/useCopyClipboard'
import userIcon from '../Icons/user.svg'
import './ContextMenuShareTrack.css'

const ContextMenuShareTrack = ({shareTrack, spotifyId}) => {
  const [state, copyToClipboard] = useCopyToClipboard()

  const {showMessage} = useAlert()
  const intL = useIntl()

  const handlerCopy = () => {
    if (spotifyId) {
      copyToClipboard(`${process.env.REACT_APP_FRONT_END_BASE}/shared/${spotifyId}`)
    }
  }

  useEffect(() => {
    if (state?.value?.trim()?.length > 0) {
      showMessage(
        intL.formatMessage({
          id: 'sharetrack.link-copy-success',
          defaultMessage: 'successfully copied',
        }),
      )
    }

    if (state?.error?.trim()?.length > 0) {
      showMessage(
        intL.formatMessage({
          id: 'sharetrack.link-copy-error',
          defaultMessage: 'Error copying link',
        }),
        TOAST_TYPE.ERROR,
      )
    }
    // eslint-disable-next-line
  }, [state])

  return (
    <>
      <div
        className="context-menu-menu-share-track-item"
        onClick={() => shareTrack()}>
        <span className="menu-menu-share-track-item-text">
          {intL.formatMessage({
            id: 'dropdown.shared-friend',
            defaultMessage: 'Share with friend',
          })}
        </span>
        <img className="menu-menu-share-track-item-icon" src={userIcon} alt="user" />
      </div>

      {spotifyId && (
        <div className="context-menu-menu-share-track-item" onClick={handlerCopy}>
          {intL.formatMessage({
            id: 'sharetrack.link-copy',
            defaultMessage: 'Copy link.',
          })}
        </div>
      )}
    </>
  )
}

export default ContextMenuShareTrack
