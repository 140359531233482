import React from 'react'
import {useWorkingIndicator} from '../../hooks/useBooleanToggler'
import './SettingsNotifications.css'
import renderHtml from 'react-render-html'
import useForm from '../../hooks/useForm'
import {updateNotificationsSettings} from '../../lib/coco/notificationsApi'
import useAlert, {TOAST_TYPE} from '../../hooks/useAlert'

const Toggle = ({status, changeStatus, intl}) => {
  return (
    <>
      <span className="toggle__notification__toggle__status">{`${
        status
          ? intl.formatMessage({
              id: 'engrane.notifications-on',
              defaultMessage: 'ON',
            })
          : intl.formatMessage({
              id: 'engrane.notifications-off',
              defaultMessage: 'OFF',
            })
      }`}</span>

      <div className="toggle__notification__main" onClick={changeStatus}>
        <input type="checkbox" checked={status} onChange={changeStatus} />
        <div
          className={`${
            status
              ? 'toggle__notification__slider'
              : 'toggle__notification__slider__inactive'
          }`}
        />
      </div>
    </>
  )
}

const NotificationOption = ({
  title,
  description,
  showBorder = true,
  status,
  changeStatus,
  intl,
}) => {
  return (
    <div
      className="notification__option__main"
      style={!showBorder ? {borderBottom: 0} : {}}>
      <div className="notification__option__texts">
        <h2>{title}</h2>
        <p>{renderHtml(description)}</p>
      </div>

      <Toggle status={status} changeStatus={changeStatus} intl={intl} />
    </div>
  )
}

const SettingsNotifications = ({currentUser, intl}) => {
  console.log("currentUser", currentUser)
  const {showMessage} = useAlert()

  const { notifications_options, dispatchNotificationsOptions } = currentUser
  const { internal_notifications, email_notifications, push_notifications } = notifications_options

  const initialState = {
    cocoNotitication: internal_notifications,
    emailNotitication: email_notifications,
    pushNotitication: push_notifications,
  }

  const {isWorking} = useWorkingIndicator()

  const {
    cocoNotitication,
    emailNotitication,
    pushNotitication,
    handleChange,
    isFormHaveChange,
  } = useForm(initialState)

  const handleSubmit = (ev) => {
    ev.preventDefault()
    if (isFormHaveChange) {

      const optionsData = {
        email_notifications: emailNotitication || false,
        push_notifications: pushNotitication || false,
        internal_notifications: cocoNotitication || false,
      }

      updateNotificationsSettings(optionsData).then((res) => {
        if (res.notificationsOptions) {
          showMessage(
            intl.formatMessage({
              id: 'engrane.notifications-save-success',
              defaultMessage: 'Data save success!',
            }),
            TOAST_TYPE.SUCCESS,
            false,
          )
          dispatchNotificationsOptions(optionsData)
        } else {
          showMessage(res.message, TOAST_TYPE.ERROR, false)
        }
      })
    }
  }

  return (
    <div>
      <h1 className="Layout__setting__screens__title">
        {intl.formatMessage({
          id: 'engrane.seting-notificaciones',
          defaultMessage: 'Notifications',
        })}
      </h1>

      <form onSubmit={handleSubmit}>
        <div className="setting__notifications__box">
          {/* <NotificationOption
            title={intl.formatMessage({
              id: 'engrane.notifications-in-coco',
              defaultMessage: 'INSIDE COCO.',
            })}
            description={intl.formatMessage({
              id: 'engrane.notifications-in-coco-description',
              defaultMessage:
                'All notifications and alerts that <br /> are within the application.',
            })}
            status={cocoNotitication}
            changeStatus={() =>
              handleChange({
                target: {name: 'cocoNotitication', value: !cocoNotitication},
              })
            }
            intl={intl}
          /> */}
          <NotificationOption
            title={intl.formatMessage({
              id: 'engrane.notifications-in-email',
              defaultMessage: 'VIA EMAIL.',
            })}
            description={intl.formatMessage({
              id: 'engrane.notifications-in-email-description',
              defaultMessage:
                'We will keep you informed of <br /> all the news and your progress.',
            })}
            status={emailNotitication}
            changeStatus={() =>
              handleChange({
                target: {
                  name: 'emailNotitication',
                  value: !emailNotitication,
                },
              })
            }
            intl={intl}
          />
          <NotificationOption
            title={intl.formatMessage({
              id: 'engrane.notifications-in-push',
              defaultMessage: 'PUSH NOTIFICATIONS.',
            })}
            description={intl.formatMessage({
              id: 'engrane.notifications-in-push-description',
              defaultMessage:
                'Receive notifications to your phone <br /> or computer.',
            })}
            status={pushNotitication}
            changeStatus={() =>
              handleChange({
                target: {name: 'pushNotitication', value: !pushNotitication},
              })
            }
            showBorder={false}
            intl={intl}
          />
        </div>

        <div className="settings__button__container">
          <button
            className="settings__button__main"
            disabled={!isFormHaveChange}
            style={!isFormHaveChange ? {cursor: 'no-drop', opacity: 0.5} : {}}
            type="submit">
            {`${
              isWorking
                ? intl.formatMessage({
                    id: 'engrane.edit-password-saving',
                    defaultMessage: 'Saving...',
                  })
                : intl.formatMessage({
                    id: 'engrane.edit-password-save-changes',
                    defaultMessage: 'Save Changes',
                  })
            }`}
          </button>
        </div>
      </form>
    </div>
  )
}

export default SettingsNotifications
