import React, {useRef, useState} from 'react'
import './Drawer.css'
import {Link, useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {logoutUser, setPhotoUrl} from '../../../Redux/users/actions'
import useOnClickOutside from '../../../hooks/useClickOutside'
import useUser from '../../../hooks/useUser'
import {FormattedMessage, useIntl} from 'react-intl'
import ChangePlatformLanguage from '../../Shared/changePlatformLanguage'
import CerrarIcon from '../../Icons/close.svg'
import CerrarSesionIcon from './Icons/CerrarSesion.svg'
import {logout} from '../../../lib/coco/apis'
import {StatusApi} from '../../../lib/coco/helperData'
import {deleteToken} from '../../../Services/getToken'
import AvatarUploader from './AvatarUploader'
import usePlatform from '../../../hooks/usePlatform'
import useSpotify from '../../../hooks/useSpotify'
import SettingButtons from '../../Settings/SettingButtons'
import {isEmailExist} from './utils/grantEmail'
import CreatePortalModal from '../../Modal/CreatePortalModal'
import WarningAlert from '../../Alerts/WarningAlert'
import Modal from '../../Modal'

const Drawer = ({cerrar, isDrawerVisible}) => {
  const useInt = useIntl()
  const {name, email, getPhotoCurrentUser, is_teaching, plan_type} = useUser()

  const grants = isEmailExist(email)

  const {
    stopTracksSpotify,
    player: playerSpotify = {
      paused: true,
    },
  } = useSpotify()
  const myRef = useRef()
  const dispatch = useDispatch()
  const history = useHistory()

  const {isDeviseMobile} = usePlatform()
  const [showWarningAlert, setShowWarningAlert] = useState(false)

  const getPhotoUser = () => {
    const photo = getPhotoCurrentUser()
    if (photo) {
      return photo
    }

    return null
  }

  const handleClickOutside = () => {
    cerrar && cerrar()
  }

  useOnClickOutside(myRef, () => handleClickOutside())

  const closeSession = () => {
    const isPlayinTrack = !playerSpotify?.paused
    if (isPlayinTrack) {
      stopTracksSpotify()
    }
    setShowWarningAlert(false)
    logout().then((response) => {
      if (response.status === StatusApi.OK) {
        deleteToken()
        dispatch(logoutUser())
        history.push('/login')
      }
    })
  }

  const updatePhotoUrl = (photoUrl) => {
    dispatch(setPhotoUrl(photoUrl))
  }

  const photoUser = getPhotoUser()

  return (
    <>
      <CreatePortalModal elementId="drawer-root">
        <div
          className={`drawer__coco__main ${
            isDrawerVisible ? 'drawer__coco__open' : 'drawer__coco__close'
          }`}
          ref={myRef}>
          <div className="drawer__coco__gradient">
            <div>
              <div className="drawer__coco__flex">
                <img
                  style={{cursor: 'pointer'}}
                  src={CerrarIcon}
                  alt="Close-mis-cosas"
                  onClick={cerrar}
                />
                <ChangePlatformLanguage />
              </div>

              <div className="drawer__coco__user">
                <AvatarUploader
                  photoObject={photoUser}
                  updatePhotoUrl={updatePhotoUrl}
                />
                <div className="drawer__coco__user__name">
                  <p>{name || 'User name'}</p>
                  <div className="mb-4">{plan_type}</div>
                </div>
                {is_teaching && (
                  <Link to="/payments-teacher" onClick={handleClickOutside}>
                    Ir a perfil
                  </Link>
                )}
              </div>
            </div>

            {/*Links para outputs contestados, flashcards creadas, canciones estudiadas y palabras aprendidas*/}
            <div className="drawer__coco__links">
              <h4 className="drawer__coco__my__tools">
                <FormattedMessage
                  id={'drawer.mytools'}
                  defaultMessage={'Mis cosas'}
                />
              </h4>

              <div className="mb-3">
                <Link
                  className="link-mis-cosas"
                  to="/outputs"
                  onClick={handleClickOutside}>
                  <FormattedMessage
                    id={'drawer.outputs'}
                    defaultMessage={'ejercicios contestados'}
                  />
                </Link>
              </div>

              <div className="mb-3">
                <Link
                  className="link-mis-cosas"
                  to="/flashcards"
                  onClick={handleClickOutside}>
                  <FormattedMessage
                    id={'drawer.flashcards'}
                    defaultMessage={'Flashcards creadas'}
                  />
                </Link>
              </div>

              <div className="mb-3">
                <Link
                  className="link-mis-cosas"
                  to="/my-inputs"
                  onClick={handleClickOutside}>
                  <FormattedMessage
                    id={'drawer.tracks'}
                    defaultMessage={'Canciones estudiadas'}
                  />
                </Link>
              </div>

              <div className="mb-3">
                <Link
                  className="link-mis-cosas"
                  to="/words-learned"
                  onClick={handleClickOutside}>
                  <FormattedMessage
                    id={'drawer.words'}
                    defaultMessage={'Palabras aprendidas'}
                  />
                </Link>
              </div>

              {isDeviseMobile && grants && (
                <div className="mb-3">
                  <Link
                    className="link-mis-cosas"
                    to="/results"
                    onClick={handleClickOutside}>
                    <FormattedMessage id={'topbar.team'} defaultMessage={'Equipo'} />
                  </Link>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-between">
              <span
                id="cerrar-sesion"
                onClick={() => {
                  setShowWarningAlert(true)
                  cerrar()
                }}>
                <img src={CerrarSesionIcon} alt="Close" className="mr-1" />
                <FormattedMessage
                  id={'drawer.close-sesion'}
                  defaultMessage={'Cerrar sesión'}
                />
              </span>

              <SettingButtons />
            </div>
          </div>
        </div>
      </CreatePortalModal>

      {showWarningAlert && (
        <Modal
          modalType="warning-alert"
          show={showWarningAlert}
          closeRound
          closeClickOutside
          closeModal={() => setShowWarningAlert(false)}>
          <WarningAlert
            message={useInt.formatMessage({
              id: 'drawer.alert-message',
              defaultMessage: 'Salir de coco 😢',
            })}
            titleCancel={useInt.formatMessage({
              id: 'drawer.alert-close',
              defaultMessage: 'Seguir aprendiendo',
            })}
            titleAcept={useInt.formatMessage({
              id: 'drawer.alert-acept',
              defaultMessage: 'Si, salir',
            })}
            salir={closeSession}
            seguirRevisando={() => setShowWarningAlert(false)}
          />
        </Modal>
      )}
    </>
  )
}

export default Drawer
