import {
  ADD_USER,
  LOGOUT_USER,
  VERIFCAR_USUARIO,
  GET_ALL_GENERAL_DATA,
  IS_TEACHING,
  UPDATE_OPTIONS_TUTORIAL,
  ADD_DOMAIN_TO_USER,
  SET_ACTIVE_DOMAIN_ID,
  TOGGLE_SPOTIFY_AUTH,
  TOGGLE_TUTORIAL,
  SET_PHOTO_URL,
  TOGGLE_SUBSCRIPTION_STATUS,
  TOGGLE_SNIPPET_MODAL,
  TOGGLE_FLASHCARD_MODAL,
  DASHBOARD_TUTORIAL_STEP,
  FLASHCARD_TUTORIAL_CHECKPOINT,
  UPDATE_PROFILE_DATA,
  UPDATE_OPTIONS_NOTIFICATION,
  TOGGLE_SUSCRIPTION_STRAPI_MODAL,
} from './actionTypes'

import {setToken} from '../../Services/getToken'
import {setAllLanguages} from '../languages/languagesActions'

import * as conversations from '../Conversations/actions'
import * as presence from '../Presence/actions'
import {getPerfilData} from '../perfil/actions'
import {getGeneralDataUser} from '../../lib/coco/apis'
import {getMyPlaylists} from '../PlayLists/playlistActions'
import {setAllProgress} from '../progress/actions'

const toggleSnippetModal = (value) => ({
  type: TOGGLE_SNIPPET_MODAL,
  snippetModalStatus: value,
})

const toggleCheckpoint = (value) => ({
  type: FLASHCARD_TUTORIAL_CHECKPOINT,
  toggleFlashcardCheckpoint: value,
})

const setStepNumber = (step_number) => ({
  type: DASHBOARD_TUTORIAL_STEP,
  stepNumber: step_number,
})

const toggleFlashcardModal = (value) => ({
  type: TOGGLE_FLASHCARD_MODAL,
  flashcardModalStatus: value,
})
//cuando se logea y es exitose agrega al estado global el usuario
const setPhotoUrl = (photoUrl) => ({
  type: SET_PHOTO_URL,
  photoUrl,
})

const addUser = (user) => ({
  type: ADD_USER,
  user: user,
})

const toggleSubscriptionStatus = (subscriptionStatus, planType) => ({
  type: TOGGLE_SUBSCRIPTION_STATUS,
  subscriptionStatus,
  planType,
})

const toggleDashboardTutorial = (value) => ({
  type: TOGGLE_TUTORIAL,
  dashboardTutorialStatus: value,
})

const setActiveDomainId = (domainId) => ({
  type: SET_ACTIVE_DOMAIN_ID,
  domainId,
})

const addDomainToUser = (domain) => ({
  type: ADD_DOMAIN_TO_USER,
  domain,
})

const IsTeaching = (status) => (dispatch) => {
  return dispatch({
    type: IS_TEACHING,
    is_teaching: status,
  })
}

// deslogear usuario
const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  }
}

const getAllProgress = () => (dispatch) => {
  getGeneralDataUser().then((response) => {
    if (response.generalData) {
      const {generalData} = response
      const {
        languages = [],
        idioma_activo,
        idioma_nativo,
        interface_language,
        review,
        output,
        input,
        inbox,
        actions,
      } = generalData

      if (languages) {
        dispatch(
          setAllLanguages(
            languages,
            idioma_nativo,
            idioma_activo,
            interface_language,
          ),
        )
      }

      dispatch(setAllProgress(review, output, input, inbox, actions))

      return dispatch({
        type: GET_ALL_GENERAL_DATA,
        generalData,
      })
    }
  })
}

const updateprofileData = (profile) => {
  return {
    type: UPDATE_PROFILE_DATA,
    profile,
  }
}

const verificarUser = (user, authorization) => (dispatch) => {
  setToken(authorization)
  dispatch(
    conversations.wsconnect({
      user_id: user.id,
      token: authorization.split(' ')[1],
    }),
  )
  dispatch(
    presence.wsconnect({user_id: user.id, token: authorization.split(' ')[1]}),
  )

  dispatch(getAllProgress()) //todos los progresos del usuario y sus lenguajes(nativo,activo)
  dispatch(getMyPlaylists()) //todos los playlist del usuario
  dispatch(getPerfilData()) // datos del perfil

  return dispatch({
    type: VERIFCAR_USUARIO,
    user: user,
  })
}

const updateOptionsTutorial = (options_tutorial) => (dispatch) => {
  return dispatch({
    type: UPDATE_OPTIONS_TUTORIAL,
    options_tutorial: options_tutorial,
  })
}

const updateNotificationOptions = (notificationsOptions) => {
  return {
    type: UPDATE_OPTIONS_NOTIFICATION,
    notificationsOptions,
  }
}

const toggleSpotifyAuthModal = (auth) => (dispatch) => {
  return dispatch({
    type: TOGGLE_SPOTIFY_AUTH,
    auth_modal: !auth,
  })
}

const toggleSuscriptionStrapiModal = (isShowModalSuscription = false) => {
  return {
    type: TOGGLE_SUSCRIPTION_STRAPI_MODAL,
    isShowModalSuscription,
  }
}

export {
  setPhotoUrl,
  addDomainToUser,
  IsTeaching,
  addUser,
  logoutUser,
  verificarUser,
  getAllProgress,
  setActiveDomainId,
  updateOptionsTutorial,
  toggleSpotifyAuthModal,
  toggleDashboardTutorial,
  toggleSubscriptionStatus,
  toggleSnippetModal,
  toggleFlashcardModal,
  setStepNumber,
  toggleCheckpoint,
  updateprofileData,
  updateNotificationOptions,
  toggleSuscriptionStrapiModal,
}
