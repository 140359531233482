import React, {useState} from 'react'
import './Progress.css'
import CircularProgressbarAll from '../../ProgresosGenerales/CircularProgressbarAll'
import useProgress from '../../../hooks/useProgress'
import {getProgressConverter} from '../../../Redux/helpers'
import miniProgressIcon from './Icons/miniProgress.svg'

const Progress = ({titleWord, titleFlashcard, titleOutput, isToggledLeftbar}) => {
  const [showProgress, setShowProgress] = useState(false)
  const {review, output, input} = useProgress()

  const getAllProgress = () => {
    const totalFinish =
      (input.input_finish > input.input_total
        ? input.input_total
        : input.input_finish) +
      review.review_finish + // porque actualmente no hay manera de estudiar más flashcards del total por estudiarse
      (output.output_finish > output.output_total
        ? output.output_total
        : output.output_finish)
    const totalItems = input.input_total + review.review_total + output.output_total

    const totalProgress = getProgressConverter(totalFinish, totalItems)
    return {
      totalFinish,
      totalItems,
      totalProgress,
    }
  }

  const {totalFinish, totalItems, totalProgress} = getAllProgress()

  return (
    <div
      id="container-progress"
      className="d-flex align-items-center"
      onMouseOver={() => {
        setShowProgress(true)
      }}
      onMouseOut={() => {
        setShowProgress(false)
      }}>
      <div className="progress-all-general-container">
        <div style={{width: '100px'}}>
          {isToggledLeftbar ? (
            <CircularProgressbarAll
              progreso={totalProgress}
              cantidad={`${totalFinish}/${totalItems}`}
              textForPercentage={(pct) => pct}
              color={'CircularProgressbar-path-input'}
            />
          ) : (
            <img src={miniProgressIcon} alt={miniProgressIcon} />
          )}
        </div>
      </div>

      <div
        className={`${
          showProgress
            ? 'd-inline-flex align-items-center justify-content-around'
            : 'd-none'
        }`}>
        <div
          className="d-flex justify-content-center flex-column align-items-center mx-4"
          style={{width: '70px'}}>
          <CircularProgressbarAll
            progreso={input.input_progress}
            cantidad={`${input.input_finish}/${input.input_total}`}
            textForPercentage={(pct) => pct}
            color={'CircularProgressbar-path-input'}
          />
          <div className="description-IOR">{titleWord}</div>
        </div>

        <div
          className="d-flex justify-content-center flex-column align-items-center mx-4"
          style={{width: '70px'}}>
          <CircularProgressbarAll
            progreso={review.review_progress}
            cantidad={`${review.review_finish}/${review.review_total}`}
            textForPercentage={(pct) => pct}
            color={'CircularProgressbar-path-flashcard'}
          />
          <div className="description-IOR">{titleFlashcard}</div>
        </div>

        <div
          className="d-flex justify-content-center flex-column align-items-center ml-4 mr-5"
          style={{width: '70px'}}>
          <CircularProgressbarAll
            progreso={output.output_progress}
            cantidad={`${output.output_finish}/${output.output_total}`}
            /*titulo="Outputs"*/
            textForPercentage={(pct) => pct}
            color={'CircularProgressbar-path-output'}
          />
          <div className="description-IOR">{titleOutput}</div>
        </div>
      </div>
    </div>
  )
}

export default Progress
