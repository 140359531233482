import React, {useState, useEffect} from 'react'
import useUser from '../../hooks/useUser'
import Modal from '../Modal'
import CardPay from './Cards/CardPay' // preview antes de pagar 'timer'
import CheckOutForm from './CheckoutForm' // formulario de stripe
import './Card.css'

const PaymentsModal = () => {
  const [cardPay, setCardPay] = useState(false)
  const [formPay, setFormPay] = useState(false)
  const currentUser = useUser()
  const {
    subscriptionStatus,
    plan_type,
    trialFinishedAt,
    isShowModalSuscription,
    dispatchToggleSuscriptionStrapiModal,
  } = currentUser

  useEffect(() => {
    setCardPay(!subscriptionStatus)
    if (subscriptionStatus === 'trial') setFormPay(true)
    setCardPay(!subscriptionStatus)
  }, [subscriptionStatus])

  // cerrar el modal de suscripcion stripePromise
  const closeModalSuscriptionStripe = () => {
    setFormPay(false)
    dispatchToggleSuscriptionStrapiModal() // close state user
  }

  const isOpenModalCheckOutForm = () => {
    if (isShowModalSuscription) return true
    if (formPay) return true
    return false
  }

  return (
    <>
      {cardPay && !isShowModalSuscription && (
        <Modal modalType="pay-confirmation_preview" show={cardPay}>
          <CardPay
            omitProcess={setCardPay}
            nextModal={setFormPay}
            planType={plan_type}
            trialFinishedAt={trialFinishedAt}
            setCardPay={setCardPay}
          />
        </Modal>
      )}

      {isOpenModalCheckOutForm() && (
        <Modal
          modalType="pay-confirmation"
          show={formPay | isShowModalSuscription}
          closeModal={closeModalSuscriptionStripe}
          closeRound>
          <CheckOutForm
            currentUser={currentUser}
            closeModal={closeModalSuscriptionStripe}
          />
        </Modal>
      )}
    </>
  )
}

export default PaymentsModal
