import React from 'react'
import useLanguages from '../../../hooks/useLanguages'

const ShowKaraoke = ({showKaraoke}) => {
  const {languageActive} = useLanguages()
  const lang = languageActive?.unicode ? languageActive.unicode : 'es'
  const title =
    lang === 'es'
      ? 'Estudiar'
      : lang === 'pt'
      ? 'Estudar'
      : lang === 'fr'
      ? 'Etudier'
      : 'Study'

  return (
    <button title={title} className="player__item__button" onClick={showKaraoke}>
      <img src="/images/Reproductor/expandir.svg" alt="option" />
    </button>
  )
}

export default ShowKaraoke
