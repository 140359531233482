import React from 'react'
import useLanguages from '../../../hooks/useLanguages'
import {useModalSpotifyAuth} from '../../../hooks/useSpotifyModalAuth'
import useSpotify from '../../../hooks/useSpotify'
import useMediaText from '../../../hooks/useMediaText'
import {useHistory} from 'react-router-dom'
// import { skipToLastTrack } from '../../../Redux/spotify/actions/playback'

const findPreviousTracks = (tracksColas = [], currentTrack) => {
  const indexCurrentTrack = tracksColas.findIndex(
    (track) => track?.uri === currentTrack?.uri,
  )

  if (tracksColas[indexCurrentTrack - 1]) {
    // retornar el anterior track
    return tracksColas[indexCurrentTrack - 1]
  } else {
    // retorna el ultimo track
    return tracksColas[tracksColas.length - 1]
  }
}

const SkipToNext = () => {
  const history = useHistory()
  const {toggleSpotifyModalAuth, isSpotifyConnected} = useModalSpotifyAuth()
  const spotifyStatus = isSpotifyConnected()
  const {languageActive} = useLanguages()
  const {player, playTrackValidate} = useSpotify()
  const {tracksPlayerColas = [], dispatchOpenSong} = useMediaText()

  const lang = languageActive?.unicode ? languageActive.unicode : 'es'
  const title =
    lang === 'es'
      ? 'Anterior'
      : lang === 'pt'
      ? ''
      : lang === 'fr'
      ? 'Précédente'
      : 'Prev'
  const prevSong = () => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
    } else {
      // dispatch(skipToLastTrack())
      const currentTrack = player?.track_window?.current_track
      const trackNext = findPreviousTracks(tracksPlayerColas, currentTrack)
      if (trackNext?.uri) {
        playTrackValidate(trackNext.uri)
        // hacer open song
        if (history.location.pathname === '/media_text') {
          dispatchOpenSong(trackNext, false)
        }
      }
    }
  }

  const havePreviousTracks = tracksPlayerColas?.length > 1

  return (
    <button
      title={title}
      className="player__item__button"
      onClick={() => prevSong()}
      disabled={!havePreviousTracks}
      style={!havePreviousTracks ? {cursor: 'not-allowed', opacity: 0.5} : {}}>
      <img src="/images/Reproductor/left.svg" alt="last" />
    </button>
  )
}

export default SkipToNext
