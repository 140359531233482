import {
  ELEGIR_USER_OUTPUT,
  TOGGLE_SHOW_CORRECTION,
  ADD_INDICE_USER_OUTPUT_HISTORY,
  UPDATE_USER_OUTPUT_COMMENTS,
  GET_USER_OUTPUT_START,
  GET_USER_OUTPUT_FINISH,
  GET_USER_OUTPUT_ERROR,
  UPDATE_USER_OUTPUT_LIKE,
  SELECT_OUTPUT_BY_USER_OUTPUT_ID,
} from './actionTypes'

const initialStore = {
  userOutputIdActive: 0,
  outputs: [],
  showCorrections: true,
  loading: true,
  errors: '',
}

export default function (state = initialStore, action) {
  switch (action.type) {
    case ADD_INDICE_USER_OUTPUT_HISTORY: {
      let {outputs = []} = state

      const {id, lineIndex} = action

      if (outputs?.length > 0) {
        outputs = outputs.map((item) => {
          let {user_output} = item

          if (id === user_output.id) {
            let {indices_added} = user_output
            indices_added = [...indices_added, lineIndex]
            user_output.indices_added = indices_added
            item.user_output = user_output
          }
          return item
        })
      }

      return {
        ...state,
        outputs,
      }
    }

    case GET_USER_OUTPUT_START: {
      return {
        ...state,
        loading: true,
        errors: '',
      }
    }

    case GET_USER_OUTPUT_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.error,
      }
    }

    case GET_USER_OUTPUT_FINISH: {
      const {userOutputIdActive} = state
      const {outputs} = action

      let firtOutputId = 0

      if (userOutputIdActive) {
        const firtOutputFind = outputs.find(
          (out) => out?.user_output?.id === userOutputIdActive,
        )

        if (firtOutputFind) {
          firtOutputId = firtOutputFind?.user_output?.id
        } else if (outputs[0]) {
          firtOutputId = outputs[0]?.user_output?.id
        }
      }

      return {
        ...state,
        outputs,
        userOutputIdActive: firtOutputId,
        loading: false,
      }
    }

    case ELEGIR_USER_OUTPUT: {
      const {outputs} = state
      const {index} = action
      const output = outputs[index]

      return {
        ...state,
        userOutputIdActive: output?.user_output?.id,
        showCorrections: true,
      }
    }

    case SELECT_OUTPUT_BY_USER_OUTPUT_ID: {
      const {userOutputId} = action
      return {
        ...state,
        userOutputIdActive: userOutputId,
        showCorrections: false,
      }
    }

    case TOGGLE_SHOW_CORRECTION: {
      return {
        ...state,
        showCorrections: !state.showCorrections,
      }
    }

    case UPDATE_USER_OUTPUT_COMMENTS: {
      const {outputs = []} = state
      const {
        comment,
        userOutput: {
          comments: {comentarios = []},
          user_output,
        },
      } = action

      const commnetsMerged = [...comentarios, comment]

      const outputsMapped = outputs.map((output) => {
        if (output.user_output.id === user_output.id) {
          output.comments.comentarios = commnetsMerged
        }
        return output
      })

      return {
        ...state,
        outputs: outputsMapped,
      }
    }

    case UPDATE_USER_OUTPUT_LIKE: {
      const {outputs = []} = state
      const {
        userOutputLikeId,
        userOutput: {user_output},
      } = action

      const outputsMapped = outputs.map((output) => {
        if (output.user_output.id === user_output.id) {
          output.comments.user_output_like_id = userOutputLikeId
          output.comments.likes_count =
            output.comments.likes_count + (userOutputLikeId ? +1 : -1)
        }
        return output
      })

      return {
        ...state,
        outputs: outputsMapped,
      }
    }

    default:
      return state
  }
}
