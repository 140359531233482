import React, {useEffect, useRef, useState} from 'react'
import IconPlay from './Icons/IconPlay.svg'
import IconPause from './Icons/IconPause.svg'
import WaveSurfer from 'wavesurfer.js'
import PropTypes from 'prop-types'

import IconRemoveAudio from './Icons/IconRemoveAudio.svg'
import SpinnerCircle from '../Animaciones/SpinnerCircle'

const formWaveSurferOptions = (ref, color) => ({
  container: ref,
  waveColor: '#fff',
  progressColor: color,
  cursorColor: color,
  barWidth: 2,
  barRadius: 3,
  responsive: true,
  height: 56,
  barMinHeight: 1,
  barGap: 2,
  barHeight: 1,
  normalize: true,
  partialRender: true,
})

const Waveform = (props) => {
  // Props
  const {url, color, deleteAudio, showAudioFrequency} = props

  // States
  const waveformRef = useRef(null)
  const wavesurfer = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [volume, setVolume] = useState(0.8)
  const [isLoading, setIsLoading] = useState(true)

  const [urlWave, setUrlWave] = useState(url)
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    setUrlWave(url)
    setTimeout(() => {
      setUrlWave(url)
      setDate(new Date())
    }, 1000)
  }, [url])

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    if (showAudioFrequency) {
      setIsPlaying(false)
      const options = formWaveSurferOptions(waveformRef.current, color)
      wavesurfer.current = WaveSurfer.create(options)

      wavesurfer.current.load(url)

      wavesurfer.current.on('ready', function () {
        // https://wavesurfer-js.org/docs/methods.html
        if (wavesurfer.current) {
          wavesurfer.current.setVolume(volume)
          setVolume(volume)
        }
      })

      // audio termina su reproduccion
      wavesurfer.current.on('finish', () => {
        setIsPlaying(false)
      })

      wavesurfer.current.on('pause', () => {
        setIsPlaying(false)
      })

      wavesurfer.current.on('play', () => {
        setIsPlaying(true)
      })

      wavesurfer.current.on('loading', (data) => {
        if (data === 100) {
          setIsLoading(false)
        }
      })

      // Removes events
      return () => wavesurfer.current.destroy()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [urlWave, date])

  const handlePlayPause = () => {
    if (wavesurfer?.current) {
      wavesurfer.current.playPause()
    }
  }

  return (
    <div className="w-100 d-flex align-items-center position-relative mb-2">
      <div className="w-auto mr-4">
        {isLoading ? (
          <SpinnerCircle />
        ) : (
          <button id="btn-waveform" onClick={handlePlayPause}>
            <img
              src={!isPlaying ? IconPlay : IconPause}
              alt={!isPlaying ? 'pause' : 'play'}
            />
          </button>
        )}
      </div>

      {showAudioFrequency && (
        <div className="w-100">
          <div id="waveform" ref={waveformRef} />
        </div>
      )}

      {deleteAudio && (
        <div className="ml-3 position-absolute" style={{right: -40}}>
          <img src={IconRemoveAudio} alt={IconRemoveAudio} onClick={deleteAudio} />
        </div>
      )}
    </div>
  )
}

Waveform.propTypes = {
  url: PropTypes.string.isRequired,
  color: PropTypes.string,
  height: PropTypes.number,
  showDeleteButton: PropTypes.bool,
  deleteAudio: PropTypes.func,
  showAudioFrequency: PropTypes.bool,
}

Waveform.defaultProps = {
  color: '#2FB0FF',
  height: 56,
  showDeleteButton: false,
  showAudioFrequency: true,
}

export default Waveform
