import * as api from '../requests/player'

import {
  playerLoading,
  setPlayerState,
  playbackError,
  playTrackSuccess,
  playTrackError,
  playContextSuccess,
  playContextError,
  trackChanging,
  //trackChanged,
  // transferPlayback,
  transferPlaybackSuccess,
  transferPlaybackError,
  togglePlaybackSuccess,
  togglePlaybackError,
  skipTrackSuccess,
  playbackSeekSuccess,
  playbackSeekError,
  toggleRepeatSuccess,
  toggleRepeatError,
  seeking,
} from '../actions/player'

import {
  TRANSFER_PLAYBACK,
  PLAYER_STATE_CHANGED,
  PLAY_TRACK,
  PLAY_CONTEXT,
  TOGGLE_PLAYBACK,
  SKIP_TO_NEXT,
  SKIP_TO_LAST,
  TOGGLE_REPEAT,
  TRACK_CHANGING,
  PLAYBACK_SEEK, //,
  //CHECK_IF_SAVED_TRACK
} from '../actions/actionTypes'

const playerStateChangedAction = (player) => (dispatch, getState) => {
  let prevPlayer = getState().spotify.player

  // check whether the track, or context has changed
  if (prevPlayer && Object.getOwnPropertyNames(prevPlayer).length > 0) {
    if (
      player &&
      player.track_window &&
      prevPlayer.track_window &&
      player.track_window.current_track.id !==
        prevPlayer.track_window.current_track.id
    ) {
      dispatch(trackChanging(player.track_window.current_track.id))
    }
  }

  dispatch(setPlayerState(player))
}

const togglePlaybackAction = (is_playing, context_uri = null, position = null) => (
  dispatch,
  getState,
) => {
  dispatch(playerLoading(true))
  if (is_playing) {
    return api
      .pausePlayback()
      .then((response) => {
        dispatch(togglePlaybackSuccess(!is_playing))
      })
      .catch((error) => {
        dispatch(togglePlaybackError(error))
      })
  } else {
    return (
      api
        .resumePlayback(context_uri, position)
        .then((response) => {
          dispatch(togglePlaybackSuccess(!is_playing))
        })
        //.then(() => dispatch(fetchPlaybackInfo()))
        .catch((error) => {
          dispatch(togglePlaybackError(error))
        })
    )
  }
}

const skipToNextTrackAction = () => (dispatch, getState) => {
  dispatch(playerLoading(true))
  return api
    .skipToNextTrack()
    .then((response) => {
      dispatch(skipTrackSuccess(response.data))
    })
    .catch((error) => {
      dispatch(playbackError())
    })
  //.then(() => dispatch(trackChanging(getState().spotify.player.track_window.current_track.id)))
}

const skipToLastTrackAction = () => (dispatch, getState) => {
  dispatch(playerLoading(true))
  return api
    .skipToLastTrack()
    .then((response) => {
      dispatch(skipTrackSuccess(response.data))
    })
    .catch((error) => {
      dispatch(playbackError())
    })
  //.then(() => dispatch(trackChanging(getState().spotify.player.track_window.current_track.id)))
}

const transferPlaybackAction = (device_id) => (dispatch, getState) => {
  dispatch(playerLoading(true))
  return api
    .transferPlayback(device_id)
    .then((response) => {
      dispatch(transferPlaybackSuccess(true))
    })
    .catch((error) => {
      dispatch(transferPlaybackError(error))
    })
}

const playContextAction = (context_uri, offset = 0) => (dispatch, getState) => {
  dispatch(playerLoading(true))
  return api
    .playContext(context_uri, offset)
    .then((response) => {
      dispatch(playContextSuccess(context_uri, offset))
      dispatch(togglePlaybackSuccess(true))
    })
    .catch((error) => {
      dispatch(playContextError(error))
    })
}

const playTrackAction = (track_id) => (dispatch, getState) => {
  return api
    .playTrack(track_id)
    .then((response) => {
      dispatch(playTrackSuccess(track_id))
      dispatch(trackChanging(track_id))
      dispatch(togglePlaybackSuccess(true))
    })
    .catch((error) => {
      dispatch(playTrackError(error))
    })
}

const trackChangingAction = (track_id) => (dispatch, getState) => {
  //dispatch(fetchAudioAnalysis(track_id))
  //api.getTrack(track_id)
  //.then((response) => dispatch(fetchLyricsAction(response.data.name, response.data.artists[0].name)))
  //.then(() => dispatch(fetchPlaybackInfo()))
  //.then(() => dispatch(fetchNowPlaying()))					// NEEDED FOR VISUALIZER
  //.then((response) => dispatch(trackChanged(response.data)))
}

const playbackSeekAction = (position_ms) => (dispatch, getState) => {
  dispatch(playerLoading(true))
  dispatch(seeking())
  return api
    .playbackSeek(position_ms)
    .then(() => dispatch(playbackSeekSuccess()))
    .catch((error) => dispatch(playbackSeekError(error)))
}

const toggleRepeatAction = (repeat_state = 'track') => (dispatch, getState) => {
  // console.log("Repeat State: " + next_repeat _state);
  // dispatch(toggleRepeatSuccess(repeat_state))

  return api
    .setPlaybackRepeat(repeat_state)
    .then((response) => {
      dispatch(toggleRepeatSuccess(repeat_state))
    })
    .catch((error) => {
      dispatch(toggleRepeatError(error))
    })
}

export default (store) => (next) => (action) => {
  const result = next(action)
  switch (action.type) {
    case TRANSFER_PLAYBACK: {
      return store.dispatch(transferPlaybackAction(action.device_id))
    }
    case PLAYER_STATE_CHANGED: {
      return store.dispatch(playerStateChangedAction(action.player))
    }
    case PLAY_TRACK: {
      return store.dispatch(playTrackAction(action.track_id))
    }
    case PLAY_CONTEXT: {
      return store.dispatch(playContextAction(action.context_uri, action.offset))
    }
    case TOGGLE_PLAYBACK: {
      return store.dispatch(
        togglePlaybackAction(action.is_playing, action.context_uri, action.position),
      )
    }
    case SKIP_TO_NEXT: {
      return store.dispatch(skipToNextTrackAction())
    }
    case SKIP_TO_LAST: {
      return store.dispatch(skipToLastTrackAction())
    }
    case TOGGLE_REPEAT: {
      return store.dispatch(toggleRepeatAction(action.repeat_mode))
    }
    case TRACK_CHANGING: {
      return store.dispatch(trackChangingAction(action.track_id))
    }
    case PLAYBACK_SEEK: {
      return store.dispatch(playbackSeekAction(action.position_ms))
    }
    default:
      break
  }

  return result
}
