import React, {useEffect} from 'react'
import DiccionarioLibre from './DiccionarioLibre'
import SearchFloatIcon from './Icons/SearchFloat.svg'
import {useLocation} from 'react-router-dom'
import PropTypes from 'prop-types'
import useDiccionary from '../../hooks/useDiccionary'

const DiccionaryButtonFloat = () => {
  const {isDiccionaryOpen, params, dispatchOpenDiccionary} = useDiccionary()

  const {pathname} = useLocation()

  useEffect(() => {
    const validateParams = () => {
      if (params?.trim()?.length > 0 && !isDiccionaryOpen) {
        dispatchOpenDiccionary()
      }
    }
    validateParams()
    // eslint-disable-next-line
  }, [params])

  const linksDisabledThread = ['/conversations', '/settings']

  const isValidRender = () => {
    if (!linksDisabledThread.includes(pathname)) {
      return true
    }
    return false
  }

  return (
    <>
      {isValidRender() && (
        <div
          style={{position: 'fixed', bottom: '95px', right: '0', cursor: 'pointer'}}
          onClick={dispatchOpenDiccionary}>
          <img
            src={SearchFloatIcon}
            alt={SearchFloatIcon}
            className="search-float-target"
            style={{width: '75px'}}
          />
        </div>
      )}
      {isDiccionaryOpen && <DiccionarioLibre />}
    </>
  )
}

DiccionaryButtonFloat.propTypes = {
  word: PropTypes.string,
}

DiccionaryButtonFloat.defaultProps = {
  word: '',
}

export default DiccionaryButtonFloat
