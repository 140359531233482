import React from 'react'
import useClickOutside from '../../hooks/useClickOutside'
import './SelectDomains.css'
import checkedIcon from './Icons/checked.svg'
import noCheckedIcon from './Icons/noChecked.svg'
// import { useDispatch } from 'react-redux';
import {useRef} from 'react'
import {Colors} from './helpers'
import useDomain from '../../hooks/useDomain'
// import { setActiveDomainId } from '../../Redux/users/actions';

const SelectDomains = ({
  onClose,
  theme = Colors.DARK,
  domainSelect,
  setDomainSelect,
}) => {
  const {domainOptions = []} = useDomain()

  const ref = useRef()
  useClickOutside(ref, () => onClose())

  const selectDomain = (domain) => {
    setDomainSelect(domain)
    onClose()
  }

  const isDomainActiveById = (id) => {
    if (id === domainSelect?.id) {
      return true
    }
  }

  const getColorTitle = () => {
    if (theme === Colors.DARK) {
      return 'language-item-title-dark'
    } else {
      return 'language-item-title-ligth'
    }
  }

  const DomainItem = ({domain, isActive = false, colorTitle}) => {
    return (
      <div className="language-item" onClick={() => selectDomain(domain)}>
        <img
          className="language-item-icon"
          src={isActive ? checkedIcon : noCheckedIcon}
          alt="check"
        />
        <div className={`language-item-title ${colorTitle}`} title={domain.name}>
          {domain.name}
        </div>
      </div>
    )
  }

  const colorTitle = getColorTitle()

  return (
    <div className="select-languages-container" ref={ref}>
      <p className="select-languages-title">Selécciona un dominio</p>
      <div className="select-languages-list">
        {domainOptions?.length > 0 &&
          domainOptions.map((domain) => (
            <DomainItem
              key={domain.id}
              domain={domain}
              isActive={isDomainActiveById(domain.id)}
              colorTitle={colorTitle}
            />
          ))}
      </div>
    </div>
  )
}

export default SelectDomains
