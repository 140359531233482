const FETCH_DASHBOARD_REQUEST_START = 'dashboard/FETCH_DASHBOARD_REQUEST_START'
const FETCH_DASHBOARD_REQUEST_FINISH = 'dashboard/FETCH_DASHBOARD_REQUEST_FINISH'
const FETCH_DASHBOARD_REQUEST_ERROR = 'dashboard/FETCH_DASHBOARD_REQUEST_ERROR'

const UPDATE_TRACK_DASHBOARD_FROM_LIST = 'dashboard/UDATE_TRACK_FROM_LIST'
const CHECK_TRACK_STATE_REALY = 'dashboard/CHECK_TRACK_STATE_REALY'

export {
  FETCH_DASHBOARD_REQUEST_START,
  FETCH_DASHBOARD_REQUEST_FINISH,
  FETCH_DASHBOARD_REQUEST_ERROR,
  UPDATE_TRACK_DASHBOARD_FROM_LIST,
  CHECK_TRACK_STATE_REALY,
}
