const FETCH_ARTIST_REQUEST_START = 'artists/FETCH_ARTIST_REQUEST_START'
const FETCH_ALBUM_REQUEST_START = 'artists/FETCH_ALBUM_REQUEST_START'
const FETCH_ARTIST_REQUEST_FINISH = 'artists/FETCH_ARTIST_REQUEST_FINISH'
const FETCH_ALBUM_REQUEST_FINISH = 'artists/FETCH_ALBUM_REQUEST_FINISH'
const FETCH_ARTIST_REQUEST_ERROR = 'artists/FETCH_ARTIST_REQUEST_ERROR'
const UPDATE_TRACKS_ARTIST_FROM_LIST = 'artists/UPDATE_TRACKS_ARTIST_FROM_LIST'

export {
  FETCH_ARTIST_REQUEST_START,
  FETCH_ALBUM_REQUEST_START,
  FETCH_ARTIST_REQUEST_FINISH,
  FETCH_ARTIST_REQUEST_ERROR,
  UPDATE_TRACKS_ARTIST_FROM_LIST,
  FETCH_ALBUM_REQUEST_FINISH,
}
