import React from 'react'
import BackIcon from './Icons/back.svg'

const HeaderMiniChat = ({
  title = 'Conversation',
  onClick,
  style = {},
  rigthComponent = null,
}) => {
  return (
    <div className="header-mini-chat-container" style={style}>
      <div className="header-mini-chat-back-title">
        <button onClick={onClick} className="header-mini-chat-btn">
          <img src={BackIcon} alt={BackIcon} />
        </button>
        <span className="header-mini-chat-title">{title}</span>
      </div>
      {rigthComponent && rigthComponent}
    </div>
  )
}

export default HeaderMiniChat
