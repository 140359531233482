import store from '../Redux/Store'
import {
  getTracksFromLocalStorage,
  mediaTextInLocalStorage,
} from '../Redux/MediaText/actions'
import {verificarUser} from '../Redux/users/actions'

// carga todos los datos iniciales y tambien verifica que
// el usuario este autorizado
const initStore = (user, authorization) => {
  if (user && authorization) {
    store.dispatch(verificarUser(user, authorization)) //verifica si la sesion del usaurio es válida
  }
  // busca mediatext en local storage
  store.dispatch(mediaTextInLocalStorage())
  store.dispatch(getTracksFromLocalStorage())
}

export default initStore
