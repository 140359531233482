import React, {useState, useEffect} from 'react'
import './SpotifyModal.css'
import SpotifyIcon from './Icons/Spotify.svg'
import BotonConBordeDegradado from '../Buttons/BotonConBordeDegradado'
import {getSpotifyAuthUrl} from '../../Redux/spotify/requests/player'
import {useIntl} from 'react-intl'
import renderHtml from 'react-render-html'

const SpotifyModal = () => {
  const [linkUrl, setLinkUrl] = useState('')
  const linkToSpotify = () => {
    window.location = linkUrl
  }

  useEffect(() => {
    getSpotifyAuthUrl().then((response) => {
      setLinkUrl(response.data.redirect_url)
    })
  }, [])

  const intl = useIntl()

  return (
    <div className="container-spotify">
      <img src={SpotifyIcon} alt="spotify Icon" />
      <div className="container-spotify-text">
        {renderHtml(
          intl.formatMessage({
            id: 'auth.spotify-premium-title',
            defaultMessage:
              'It is necessary to link your <b> Spotify Premium </b> account with Coco so that you can enjoy the full experience.',
          }),
        )}
      </div>
      <div className="container-spotify-enlazar">
        <BotonConBordeDegradado
          width={208}
          height={37}
          shape="rectangulo"
          function={linkToSpotify}>
          <>
            {intl.formatMessage({
              id: 'auth.spotify-premium-enlazar',
              defaultMessage: 'Link',
            })}
          </>
        </BotonConBordeDegradado>
      </div>

      <div className="container-spotify-text">
        {renderHtml(
          intl.formatMessage({
            id: 'auth.spotify-premium-subtitle',
            defaultMessage: '<b>Spotify Premium </b> is required to operate',
          }),
        )}
      </div>
    </div>
  )
}

export default SpotifyModal
