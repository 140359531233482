import React from 'react'
import DefaultPhotoUserIcon from '../Icons/DefaultPhotoUser.svg'

const DefaultPhotoUser = (props) => {
  const {photourl = null} = props

  return (
    <div
      className="drawer__coco__user__my__tools"
      style={{
        backgroundImage: `url(${
          photourl?.length > 0 ? photourl : DefaultPhotoUserIcon
        })`,
        cursor: 'pointer',
      }}
      {...props}
    />
  )
}

export default DefaultPhotoUser
