import PropTypes from 'prop-types'
import React from 'react'
import themeMentionSuggestions from './themeMentionSuggestions.module.css'
import DefaultPhotoUser from '../../Shared/DefaultPhotoUser'

const EntryComponent = (props) => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props

  return (
    <div {...parentProps}>
      <div className={themeMentionSuggestions.mentionSuggestionsEntryContainer}>
        <div
          className={themeMentionSuggestions.mentionSuggestionsEntryContainerLeft}>
          {mention.avatar?.trim()?.length > 0 ? (
            <img
              src={mention.avatar}
              className={theme.mentionSuggestionsEntryAvatar}
              role="presentation"
              alt="avatar"
            />
          ) : (
            <DefaultPhotoUser
              className={theme.mentionSuggestionsEntryAvatar}
              role="presentation"
            />
          )}
        </div>

        <div
          data-role-id={`${mention.id}`}
          className={themeMentionSuggestions.mentionSuggestionsEntryContainerRight}>
          <div className={themeMentionSuggestions.mentionSuggestionsEntryText}>
            {mention.name}
          </div>
        </div>
      </div>
    </div>
  )
}

EntryComponent.propTypes = {
  mention: PropTypes.shape({
    name: PropTypes.string,
    mentionId: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({
    mentionSuggestionsEntryContainer: PropTypes.string,
    mentionSuggestionsEntryContainerRight: PropTypes.string,
    mentionSuggestionsEntryText: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  searchValue: PropTypes.string,
  isFocused: PropTypes.bool,
}

export default EntryComponent
