import {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {filterLinesEmpty} from '../helpers'
import {checkTrackStateRealy} from '../Redux/dashboard/dashboardActions'
import {
  openInputText,
  openSong,
  updateIndicesYaAgregados,
  updateLines,
  updateLinesAndDesabledLearnedWords,
} from '../Redux/MediaText/actions'
import useAlert, {TOAST_TYPE} from './useAlert'
import useSpotify from './useSpotify'
import {useModalSpotifyAuth} from './useSpotifyModalAuth'

const useMediaText = () => {
  const {showMessage} = useAlert()
  const dispatch = useDispatch()
  const history = useHistory()
  const {toggleSpotifyModalAuth, isSpotifyConnected} = useModalSpotifyAuth()
  const spotifyStatus = isSpotifyConnected()
  const {player = {position: 0}, isTrackActive, playTrackValidate} = useSpotify()
  const {position: positionPlayer = 0} = player

  const state = useSelector((state) => state.mediatext, shallowEqual)

  const {mediaTextSong: mediatext} = state

  const {
    lines: linesMediatext = [],
    indices_ya_agregados: indicesYaAgregados = [],
  } = mediatext

  const [currentLineActive, setCurrentLineActive] = useState(null)

  useEffect(() => {
    getCurrentLine()
    // eslint-disable-next-line
  }, [positionPlayer])

  const getCurrentLine = () => {
    //Si trae linesMediatext
    if (linesMediatext?.length > 0) {
      // Busqueda de la linea segun su rango de tiempo
      const newLine = linesMediatext.find((line, index) => {
        const nextLine = linesMediatext[index + 1] ? linesMediatext[index + 1] : null //siguiente linea para hacer calculo
        let durationNextLine = 0 // duracion de linea siguiente
        //asignando valores a linea siguiente si tiene tiempo, sino 3 segundo por defecto a partir de linea actual de busqueda
        if (nextLine?.start_time_in_milliseconds) {
          durationNextLine = nextLine?.start_time_in_milliseconds //asignacion
        } else {
          // significa que ya no hay linesMediatext siguientes asi que le damos 3 segundos mas
          durationNextLine = line?.start_time_in_milliseconds + 3000
        }
        // validacion de rangos de tiempos
        if (
          positionPlayer > line?.start_time_in_milliseconds &&
          positionPlayer < durationNextLine
        ) {
          return line //linea encontrada en su posicion
        } else {
          return null // no se encontro ninguna con este rango
        }
      })
      //si existe
      if (newLine) {
        //valida si line y translation no son vacios (linesMediatext VACIAS), quitar espacios para ser exactos
        if (newLine?.line?.trim() === '' || newLine?.translation?.trim() === '') {
          // si la linea esta vacia pero en state aun no hay linea_seleccionada, damos posicion 0
          if (!currentLineActive) {
            setCurrentLineActive(linesMediatext[0] || null)
          }
          return // romper
        }
        // debe validar si la linea actual de state es distinta a la linea encontrada, sino se valida causa bucles infinitos
        if (newLine !== currentLineActive) {
          setCurrentLineActive(newLine || null)
        }
      } else {
        // si la linea encontrada es nula dar posicion cero, ojo en metodo anterior se esta haciendo esta validacion. pero esta es necesaria al recargar pagina
        const primer_linea_defecto = linesMediatext[0]
        if (primer_linea_defecto !== currentLineActive) {
          setCurrentLineActive(primer_linea_defecto || null)
          return
        }
      }
    }
    // }
  }

  // dispatchs
  // actualiza los index de las lineas agregadas
  const dispatchAddNewIndexInMediaText = (lineIndex) => {
    dispatch(updateIndicesYaAgregados([...indicesYaAgregados, lineIndex]))
  }

  //ver karaoke
  const dispatchOpenSong = (track, validatePlayer = true) => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
    } else {
      dispatch(
        openSong(
          track.spotify_id,
          track?.artistas | [],
          track?.titulo,
          track?.image_url,
        ),
      )
      if (validatePlayer && !isTrackActive(track.uri)) {
        playTrackValidate(track.uri)
      }

      if (track?.item_id && track?.state === 'new') {
        dispatch(checkTrackStateRealy(track?.spotify_id))
      }
      history.push('/media_text')
    }
  }

  const dispatchUpdateLines = (wordMark, memorizeId = null) => {
    dispatch(updateLines(wordMark, memorizeId))
  }

  const dispatchDesabledLearnedWords = (wordMark) => {
    dispatch(updateLinesAndDesabledLearnedWords(wordMark))
  }

  const dispatchOpenInputText = (inputText) => {
    dispatch(openInputText(inputText))
  }

  // open song con un track de el player
  const showKaraokeFromPlayer = () => {
    if (player?.is_active && player?.track_window?.current_track?.id) {
      const {
        track_window: {current_track},
      } = player

      const spotify_id = current_track?.id
      const nameTrack = current_track?.name
      const urlImageTrack =
        current_track?.album?.images?.length > 0
          ? current_track.album.images[0].url
          : null
      const {artists = []} = current_track

      if (
        spotify_id !== '' &&
        nameTrack !== '' &&
        urlImageTrack &&
        artists?.length > 0
      ) {
        const nuevosArtistas = artists.map(
          (a) =>
            (a = {
              spotify_id: a.uri.replace('spotify:artist:', ''),
              nombre: a.name,
              image_url: '',
            }),
        )

        const trackBuild = {
          spotify_id,
          artistas: nuevosArtistas,
          titulo: nameTrack,
          image_url: urlImageTrack,
          uri: current_track.uri,
        }

        dispatchOpenSong(trackBuild, false)
      } else {
        showMessage(`No hay un track en el reproductor`, TOAST_TYPE.WARNING)
      }
    } else {
      showMessage(`EL player no esta activo`, TOAST_TYPE.WARNING)
    }
  }

  const linesFilter = filterLinesEmpty(linesMediatext)

  return {
    ...state,
    currentLineActive:
      currentLineActive || (linesMediatext?.length > 0 && linesMediatext[0]),
    lines: linesFilter,
    dispatchAddNewIndexInMediaText,
    dispatchOpenSong,
    showKaraokeFromPlayer,
    dispatchUpdateLines,
    dispatchDesabledLearnedWords,
    dispatchOpenInputText,
  }
}

export default useMediaText
