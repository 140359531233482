export const getErrorsFlashcard = (errors) => {
  let newErrors = {
    titleExist: false,
  }

  try {
    if (errors?.title[0]) {
      newErrors.titleExist = errors?.title[0] === 'has already been taken'
    }
  } catch (error) {}

  return newErrors
}
