import React, {Fragment} from 'react'
import Like from '../Artists/IconsLikeAutor/Like.svg'
import LikeWhite from '../Artists/IconsLikeAutor/Corazon_white.svg'

const HeartIcon = ({
  titulo,
  tiene_like,
  EliminarDeMisLikes,
  AgregarAmisLikes,
  className = '',
}) => {
  return (
    <Fragment>
      {tiene_like ? (
        <img
          className={className}
          src={Like}
          alt="liked"
          title={`Eliminar ${titulo} de mis favoritos`}
          onClick={() => EliminarDeMisLikes()}
        />
      ) : (
        <img
          className={className}
          src={LikeWhite}
          alt="not liked"
          title={`Agregar ${titulo} a mis favoritos`}
          onClick={() => AgregarAmisLikes()}
        />
      )}
    </Fragment>
  )
}

export default HeartIcon
