import React from 'react'
import {getDateString} from '../../../lib/coco/generalHelpers'
import './SuscriptionSuccess.css'
import IconSuscriptionSuccess from '../Icons/suscriptionSuccess.svg'
import renderHTML from 'react-render-html'

const SuscriptionSuccess = ({onClick, intl}) => {
  const date = new Date()
  return (
    <div className="suscription__success__main">
      <div className="suscription__success__title__container">
        <div className="suscription__success__title animate__animated animate__pulse animate__delay-3s animate__infinite">
          {intl.formatMessage({
            id: 'suscription-success.thanks',
            defaultMessage: 'Thank you!',
            description: 'Thank you!',
          })}
        </div>
      </div>

      <div>
        <p className="suscription__success__text">
          {renderHTML(
            intl.formatMessage({
              id: 'suscription-success.text-1',
              defaultMessage:
                'We are excited to accompany you <br /> on your journey to fluidity.',
              description:
                'We are excited to accompany you <br /> on your journey to fluidity.',
            }),
          )}
        </p>
        <p className="suscription__success__text">
          {renderHTML(
            intl.formatMessage({
              id: 'suscription-success.text-2',
              defaultMessage:
                'Keep enjoying the benefits of the App <br /> and achieve all your goals.',
              description:
                'Keep enjoying the benefits of the App <br /> and achieve all your goals.',
            }),
          )}
        </p>
      </div>

      <p className="suscription__success__text__date">
        {renderHTML(
          intl.formatMessage({
            id: 'suscription-success.text-3',
            defaultMessage: 'Your subscription will begin <br /> on',
            description: 'Your subscription will begin <br /> on',
          }),
        )}
        <b>{` ${getDateString(date, ' ')}`}</b>
      </p>

      <button className="suscription__success__button" onClick={onClick}>
        <span>
          {intl.formatMessage({
            id: 'suscription-success.text-4',
            defaultMessage: 'Successful payment',
            description: 'Successful payment',
          })}
        </span>
        <img src={IconSuscriptionSuccess} alt={IconSuscriptionSuccess} />
      </button>
    </div>
  )
}

export default SuscriptionSuccess
