import React from 'react'
import TraductorIcon from './IconsButton/TraductorIcon.svg'
import './BotonTraductorLoading.css'

const BotonTraductorLoading = (props) => {
  const {onClick, is_loading = false, type} = props
  return (
    <button
      {...props}
      disabled={is_loading}
      className={`${
        type === 'traductor'
          ? 'btn btn-traductor-general-dark'
          : 'btn btn-traductor-general-blue'
      }`}
      onClick={onClick}>
      <img
        src={TraductorIcon}
        alt="traducir"
        className={`${is_loading && 'rotating'}`}
      />
    </button>
  )
}

export default BotonTraductorLoading
