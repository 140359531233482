import React, {useState} from 'react'
import PropTypes from 'prop-types'
import './Comentarios.css'
import useUser from '../../../hooks/useUser'
import {createCommentUserOutput} from '../../../lib/coco/OutputsApi'
import {ALERT_TYPES, fullAlert} from '../../../Services/Alert'
import {useWorkingIndicator} from '../../../hooks/useBooleanToggler'
import SpinnerBox from '../../Animaciones/SpinnerBox'
import DefaultPhotoUserIcon from '../../Icons/DefaultPhotoUser.svg'

const Comentarios = ({comments, commentableId, commentableType, pushComment}) => {
  const [commestText, setCommentText] = useState('')
  const refsNombresDeLosComentaristas = []
  const refsFotosDeLosComentaristas = []

  const {id: idCurrentUser = null, name: nameCurrentUser} = useUser()
  const {isWorking, startWork, finishWork} = useWorkingIndicator()

  // si el user no tiene foto, sele agregara la de spotify por default
  const getPhotoComment = ({commenter_photo = null}) => {
    if (commenter_photo?.url?.length > 0) {
      return commenter_photo?.url
    } else {
      return DefaultPhotoUserIcon
    }
  }

  const getNameComment = ({commenter_id, commenter_name = null}) => {
    if (commenter_name) {
      return commenter_name
    } else if (commenter_id === idCurrentUser) {
      return `${nameCurrentUser}`
    }

    return 'user'
  }

  const mostrarNombreDelComentarista = (index) => {
    refsNombresDeLosComentaristas[`name${index}`].style.visibility = 'visible'
  }

  const ocultarNombreDelComentarista = (index) => {
    refsNombresDeLosComentaristas[`name${index}`].style.visibility = 'hidden'
  }

  // Crea un comentario.
  const comentar = () => {
    if (isWorking) {
      fullAlert(ALERT_TYPES.SUCCESS, `Cargando comentario`)
      return
    }
    if (commestText === '' || commestText === null) {
      fullAlert(ALERT_TYPES.WARNING, `Necesita escribir un comentario`)
      return false
    }
    startWork()
    createCommentUserOutput(commestText, commentableId, commentableType).then(
      (response) => {
        finishWork()
        if (response.comment) {
          pushComment(commestText)
          setCommentText('')
        } else {
          fullAlert(ALERT_TYPES.ERROR, `${response.message}, No se pudo comentar`)
        }
      },
    )
  }

  return (
    <>
      {/* Lista de los comentarios */}
      <div className="m-4">
        {comments.length > 0 &&
          comments.map((comment, index) => (
            <div key={index} className="d-flex align-items-center">
              <div
                id="foto-del-comentarista"
                style={{backgroundImage: `url(${getPhotoComment(comment)})`}}
                ref={(ref) => {
                  refsFotosDeLosComentaristas[`foto${index}`] = ref
                }}
                onMouseOver={() => mostrarNombreDelComentarista(index)}
                onMouseOut={() => ocultarNombreDelComentarista(index)}>
                <span
                  ref={(ref) => {
                    refsNombresDeLosComentaristas[`name${index}`] = ref
                  }}
                  id="nombre-del-comentarista">
                  {getNameComment(comment)}
                </span>
              </div>
              <span id="comentario-del-usuario" className="my-1">
                {comment.comment}
              </span>
              <span id="fecha-del-comentario">{comment.time_difference}</span>
            </div>
          ))}
      </div>

      {isWorking && (
        <div className="loading-comments">
          <SpinnerBox />
        </div>
      )}
      <input
        id="input-comentario"
        className="mb-5"
        type="text"
        placeholder="Escribe un comentario"
        onChange={({target}) => setCommentText(target.value)}
        value={commestText}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            comentar()
          }
        }}
      />
    </>
  )
}

Comentarios.propTypes = {
  comments: PropTypes.array.isRequired,
  commentableId: PropTypes.number.isRequired,
  commentableType: PropTypes.string.isRequired,
  pushComment: PropTypes.func,
}

export default Comentarios
