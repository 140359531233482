const TOKEN_LOCAL_STORAGE = 'TOKEN_LOCAL_STORAGE'

const getToken = () => {
  try {
    if (localStorage.getItem(TOKEN_LOCAL_STORAGE)?.trim().length > 0) {
      return localStorage.getItem(TOKEN_LOCAL_STORAGE)
    }
  } catch {}

  return ''
}

const setToken = (token) => {
  localStorage.setItem(TOKEN_LOCAL_STORAGE, token)
}

const deleteToken = () => {
  localStorage.removeItem(TOKEN_LOCAL_STORAGE)
}

const togglePlatformLanguage = (lang) => {
  localStorage.setItem('lang', lang)
}

export {getToken, deleteToken, setToken, togglePlatformLanguage}
