import React, {useState} from 'react'
import {useWorkingIndicator} from '../../../../hooks/useBooleanToggler'
import {existValue, ValidateEmail} from '../helpers/helpers'
import './Invite.css'
import {sendUserInvitation} from '../../../../lib/coco/apis'
import useAlert, {TOAST_TYPE} from '../../../../hooks/useAlert'

export const Invite = ({show, successModal}) => {
  const {showMessage} = useAlert()

  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const [email, setEmail] = useState('')
  const send = () => {
    if (ValidateEmail(email) && existValue(email)) {
      startWork()
      sendUserInvitation(email).then((response) => {
        finishWork()
        const {status} = response
        if (status) {
          setEmail('')
          successModal(true)
          show(false)
          showMessage('Invitacion realizada con exito')
        } else if (response.message) {
          showMessage(response.message, TOAST_TYPE.ERROR)
        }
      })
    } else {
      showMessage('Debes agregar un email valido', TOAST_TYPE.WARNING)
    }
  }
  return (
    <div className="container-invitation">
      <div>
        <div className="title-invitation">A quien Quieres Invitar?</div>
        <div>
          <input
            placeholder="Email"
            className="email-invitation"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
      </div>

      <div>
        <button className="send-invitation" onClick={send} disabled={isWorking}>
          {isWorking ? 'Cargando' : 'Enviar'}
        </button>
      </div>
    </div>
  )
}

export default Invite
