import React, {useState} from 'react'
import useLikeTracks from '../../../hooks/useLikeTrack'
import {useModalSpotifyAuth} from '../../../hooks/useSpotifyModalAuth'
import HeartIcon from './HeartIcon'

const Heart = ({spotify_id, titulo, image_url, artistas, user_input}) => {
  const {toggleSpotifyModalAuth, isSpotifyConnected} = useModalSpotifyAuth()
  const spotifyStatus = isSpotifyConnected()
  const {sendTrackToMyLikes, deleteTrackFromMyLikes} = useLikeTracks()
  const [animation, setAnimation] = useState('')

  const animationHeart = (animationType) => {
    setAnimation(`animate__animated ${animationType}`)
    setTimeout(() => {
      setAnimation('')
    }, 1000)
  }

  // debe ser asincrona para que venda la data limpia.
  const AgregarAmisLikes = () => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
    } else {
      animationHeart('animate__heartBeat')
      sendTrackToMyLikes(spotify_id, titulo, image_url, artistas)
    }
  }

  // debe ser asincrona
  const EliminarDeMisLikes = () => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
    } else {
      animationHeart('animate__jello')
      deleteTrackFromMyLikes(spotify_id, user_input, titulo)
    }
  }

  return (
    <div className="my-1" style={{fontSize: '21px', cursor: 'pointer'}}>
      <HeartIcon
        titulo={titulo}
        tiene_like={user_input}
        EliminarDeMisLikes={EliminarDeMisLikes}
        AgregarAmisLikes={AgregarAmisLikes}
        className={`${animation}`}
      />
    </div>
  )
}

export default Heart
