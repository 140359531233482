import React from 'react'
import './CheckPeculiaridad.css'
import checkedIcon from './Icons/checked.svg'
import NoCheckedIcon from './Icons/NoChecked.svg'
import NoCheckedSinBorder from './Icons/NoCheckedSinBorder.svg'
import checkedsinBorder from './Icons/checkedSinBorder.svg'

const CheckPeculiaridad = ({tipo, peculiaridadesChecked, onClick, titulo, side}) => {
  const getIconCheck = () => {
    if (tipo === 'region') {
      return peculiaridadesChecked ? checkedsinBorder : NoCheckedSinBorder
    }
    return peculiaridadesChecked ? checkedIcon : NoCheckedIcon
  }
  return (
    <div className="seleccionar-peculiaridades" onClick={() => onClick(side)}>
      <img
        className={`${
          tipo === 'region' ? 'peculiaridad-icono-region' : 'peculiaridad-icono'
        }`}
        src={getIconCheck()}
        alt="icon"
      />
      <span
        className={`${
          tipo === 'region' ? 'peculiaridades-region-text' : 'peculiaridades-text'
        } ${side === 'back' && 'black'}`}>
        {titulo}
      </span>
    </div>
  )
}

export default CheckPeculiaridad
