import {combineReducers} from 'redux'

//import playbackReducer from './playback_info';
//import nowPlayingReducer from './now_playing';
import playerReducer from './player'

export default combineReducers({
  //playback_info: playbackReducer,
  //now_playing: nowPlayingReducer,
  player: playerReducer,
})
