import React from 'react'
import './SuscriptionError.css'
import warningIcon from '../Icons/warning.svg'
import renderHTML from 'react-render-html'

const SuscriptionError = ({onClick, intl}) => {
  return (
    <div className="suscription__error__main">
      <div className="suscription__error__titles__container">
        <div className="suscription__error__text">
          <img src={warningIcon} alt={warningIcon} />
          <span>
            {renderHTML(
              intl.formatMessage({
                id: 'suscription-error.text-1',
                defaultMessage: 'The payment was declined by <br /> your bank.',
                description: 'The payment was declined by <br /> your bank.',
              }),
            )}
          </span>
        </div>
        <p className="suscription__error_text__secondary">
          {renderHTML(
            intl.formatMessage({
              id: 'suscription-error.text-2',
              defaultMessage:
                'Contact your bank to clarify the <br /> problem or try another payment method.',
              description:
                'Contact your bank to clarify the <br /> problem or try another payment method.',
            }),
          )}
        </p>
      </div>

      <button onClick={onClick} className="suscription__error__button">
        {intl.formatMessage({
          id: 'suscription-error.text-3',
          defaultMessage: 'Try again',
          description: 'Try again',
        })}
      </button>
    </div>
  )
}

export default SuscriptionError
