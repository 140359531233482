import React from 'react'
import {connect, useDispatch} from 'react-redux'
import PropTypes from 'prop-types'
import './SelectionContextMenu.css'
import IconFlashcard from './Icons/IconFlashcard.svg'
import Modal from '../Modal'
import TranslationFlashcardForm from '../FlashCards/TranslationFlashcardForm'
import Snippet from './Snippet'
import useUser from '../../hooks/useUser'
import {toggleFlashcardModal, toggleSnippetModal} from '../../Redux/users/actions'

const SelectionContextMenu = (props) => {
  //Props
  const {text, itemizableId, itemizableType} = props
  //States
  const {snippetModalStatus, flashcardModalStatus} = useUser()

  const dispatch = useDispatch()

  const crearFlashcard = () => {
    dispatch(toggleFlashcardModal(true))
  }

  return (
    <>
      <div className="selection-context-menu px-3 py-3">
        <div>
          <div
            className="context-menu-action px-3 py-2 mb-3"
            onClick={() => crearFlashcard()}>
            <div className="icon-selection-context-menu mr-2">
              <img src={IconFlashcard} alt="" />
            </div>
            Flashcard
          </div>
        </div>

        <div className="context-menu-triangle" />
      </div>
      {
        <Modal
          modalType="snippet"
          show={snippetModalStatus}
          closeModal={() => dispatch(toggleSnippetModal(false))}>
          <Snippet
            title={text}
            itemizableId={itemizableId}
            itemizableType={itemizableType}
            close={() => dispatch(toggleSnippetModal(false))}
          />
        </Modal>
      }
      {
        <Modal
          modalType="output"
          show={flashcardModalStatus}
          closeModal={() => {
            dispatch(toggleFlashcardModal(false))
          }}>
          <TranslationFlashcardForm
            phrase={text}
            phraseTranslation=""
            paramsItemOptionsRequest={{
              line_index_in_text: 0,
              itemizable_id: null,
              itemizable_type: 'MediaText',
            }}
          />
        </Modal>
      }
    </>
  )
}

SelectionContextMenu.propTypes = {
  text: PropTypes.string.isRequired,
  closeContextMenu: PropTypes.func.isRequired,
  itemizableId: PropTypes.number.isRequired,
  itemizableType: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  selectedDomain: state.items.domainsSelected,
  user: state.user,
})

export default connect(mapStateToProps)(SelectionContextMenu)
