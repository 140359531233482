import Axios from 'axios'
import {getErrorsFlashcard} from '../../helpers/utils/items'
import {
  CREATE_FLASHCARD,
  FLASHCARDS,
  GET_CARD_STUDY,
  SEND_ANSWER_FLASHCARD,
  FLASHCARDS_REVISADOS,
  FLASHCARDS_MARCAR_VISTOS,
  FLASHCARDS_YA_AGREGADO,
} from '../../Services/EndPointApi'
import {ApiMessages, errorHandler, getHeaders} from './helperData'

const getFlashcardsRevisados = () => {
  return Axios.get(FLASHCARDS_REVISADOS, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data?.flashcard_items) {
        const {flashcard_items} = response.data
        return {
          flashcardItems: flashcard_items,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('getFlashcardsRevisados'))
}

const getFlashcardsMarcarVistos = (idsItems) => {
  return Axios.patch(
    FLASHCARDS_MARCAR_VISTOS,
    {
      ids: idsItems,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.message) {
        const {message} = response.data
        return {
          message,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('getFlashcardsMarcarVistos'))
}

const getFlashcards = () => {
  return Axios.get(FLASHCARDS, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data?.items) {
        const {items} = response.data
        return {
          items,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('getFlashcards'))
}

const getFlashcardToStudy = () => {
  return Axios.patch(
    GET_CARD_STUDY,
    {
      item_id: null,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.card_to_study) {
        const {card_to_study} = response.data
        return {
          flashcard: card_to_study,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('getFlashcardToStudy'))
}

const sendFlashcard = (itemId, quality) => {
  return Axios.patch(
    SEND_ANSWER_FLASHCARD,
    {
      item_id: itemId,
      quality,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.card_to_study) {
        const {card_to_study} = response.data
        return {
          flashcard: card_to_study,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('sendFlashcard'))
}

const createFlashcardItem = (params) => {
  return Axios.post(
    CREATE_FLASHCARD,
    {
      ...params,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.item) {
        return {
          item: response?.data?.item,
          memorizedTerm: response?.data?.memorized_term,
        }
      } else if (response?.data?.error) {
        const {error} = response?.data
        const errors = getErrorsFlashcard(error)
        return {
          errors,
        }
      } else {
        return {
          message: ApiMessages.BadRequest,
        }
      }
    })
    .catch(errorHandler('createFlashcardItem'))
}

const getFlashcardYaAgregado = (phrase) => {
  return Axios.get(`${FLASHCARDS_YA_AGREGADO}?phrase=${phrase}`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data?.items) {
        const {items} = response.data
        return {
          items,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('getFlashcardYaAgregado'))
}

export {
  getFlashcardsRevisados,
  getFlashcardsMarcarVistos,
  getFlashcards,
  getFlashcardToStudy,
  sendFlashcard,
  createFlashcardItem,
  getFlashcardYaAgregado,
}
