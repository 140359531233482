export default {
  en: {
    'splash.login': 'Login',
    'splash.request-access': 'REQUEST ACCESS',
    'splash.home-title': 'LEARN A LANGUAGE <br /> WITH 30 MINUTES <br /> A DAY.',
    'splash.home-description':
      'Coco is an app that will help you organize<br/> your journey towards fluency with 3 daily steps:',
    'splash.home-item-1': 'Learning from music that you love.',
    'splash.home-item-2':
      'Expressing yourself freely with topics that interest you - with professional feedback.',
    'splash.home-item-3': 'Reviewing with memory cards.',
    'splash.take-advantage': 'TAKE ADVANTAGE OF YOUR',
    'splash.free-times': 'FREE TIME',
    'splash.stuyd-wherever':
      'Study wherever and whenever you want with our mobile app.',
    'splash.download-it-now': 'COMING SOON ON ANDROID AND iOS.',
    'splash.launching.title': 'THE GREAT BEGINNING.',
    'splash.launching.subtitle':
      'We are ready for the first version of Coco to see the light and we want you to be part of this great beginning.',
    'splash.launching.paragraph-1':
      'Coco was born with the idea of changing the way people learn languages.',
    'splash.launching.paragraph-2':
      'We realized that with traditional methods people suffer from trying to memorize and memorize and memorize things over and over again without success, and that the topics and content used for learning tend to be extremely boring and unrealistic.',
    'splash.launching.paragraph-3':
      'Even if you are a free and self-taught person (like many of us), it is difficult to find someone who will give you good feedback and who will help you and guide you along the way.',
    'splash.launching.paragraph-4':
      'With a clear idea, we set out on the adventure of creating an application with an efficient method. It has been a difficult and challenging road but extremely fascinating.',
    'splash.launching.paragraph-5':
      'Today we are ready for the first version of Coco to see the light and we want you to be part of this great beginning.',
    'splash.launching.paragraph-6':
      'We want to accompany you on the way to your goals and dedicate all our effort to help you achieve them.',
    'splash.launching.read-more': 'Read more',
    'splash.launching.founder': 'CEO & Founder',
    'splash.metod.title': 'THE 3-STEP DAILY METHOD.',
    'splash.metod.description':
      '<i>We have not discovered the magic bullet. </i> Polyglots and all who have reached fluency agree that there are 3 important daily steps to learn a new language:',
    'splash.metod.card-1':
      'Consume a lot of content in the language they want to learn.',
    'splash.metod.card-2': 'Write and talk a lot.',
    'splash.metod.card-3':
      "Review what they've learned in the past with spaced repetition.",
    'splash.metod.section-1':
      'IN COCO YOU WILL FIND <br /> THESE 3 STEPS IN ONE PLACE. <br />',
    'splash.metod.section-2': 'IT IS EASY TO USE IN 3 SIMPLE STEPS',
    'splash.metod.section-line-1':
      'Explore and listen to a lot of music and learn from its lyrics.',
    'splash.metod.section-line-1-description':
      'We will link your Spotify account so that you have all the music you want on hand.',
    'splash.metod.section-line-2':
      'Write, write and write to practice the language.',
    'splash.metod.section-line-2-description':
      'We have a section so you can express all your ideas and upon doing so, a teacher will correct you to help you improve.',
    'splash.metod.section-line-3': 'Memorize vocabulary with spaced repetition.',
    'splash.metod.section-line-3-description':
      'You will have flashcards on hand that you can study using a spaced repetition algorithm.',
    'splash.metod.section-banner':
      'START YOUR JOURNEY TODAY <br /> TOWARDS FLUENCY.',
    'splash.metod.section-3': 'LEARN WITH MUSIC.',
    'splash.metod.section-3-description':
      'To learn a language you need to expose yourself to many sounds repeatedly. With the music you love, you can learn pronunciation and acquire new vocabulary. <br/><br/> We will show you the lyrics of the songs you want to listen to, and you select the words you want to learn, and add it to your memory deck with a useful phrase, which you can review later with spaced repetition.',
    'splash.metod.section-4': 'PRACTICE <br /> THE LANGUAGE.',
    'splash.metod.section-4-description':
      'We give you a place where you can <br /> express yourself freely in the language and thus put it to use. <br /> A native <br /> teacher will give you feedback.',
    'splash.metod.section-5': 'REVIEW MEMORY<br />CARDS.',
    'splash.metod.section-5-description':
      'All the phrases and words you learn WILL be <br /> put into a deck of flashcards. <br /> <br /> With our intelligent spaced repetition algorithm, <br /> you will be able to memorize phrases in an <br / > efficient way.',
    'splash.content-input.title': 'LEARN <br/>WITH MUSIC',
    'splash.content-input.subtitle':
      'We have a whole world of songs <br/> with which you can learn. 😎',
    'splash.content-input.subtitle-2':
      'To learn a language you need <br/>to expose yourself to many sounds <br/>repeatedly.',
    'splash.content-input.subtitle-3':
      'With the music of your choice you can <br/>learn pronunciation and acquire <br/>new vocabulary.',
    'splash.content-output.title': 'EXPRESS YOURSELF FREELY',
    'splash.content-output.subtitle':
      'You will be able to immediately put everything you have learned into practice. <br /> In this section you will have the opportunity to express yourself in the language you are learning, and a native teacher will give you feedback and some tips.',
    'splash.content-flashcard.subtitle':
      'All the phrases and words that <br/>you learn can be put into a <br/>deck of memory cards (flashcards).',
    'splash.content-flashcard.subtitle-2':
      'With our smart spaced <br/>repetition algorithm, you can <br/>memorize phrases efficiently. <br/>',
    'splash.content-flashcard.more': '[Learn more]',
    'splash.hilos.title': 'RESOLVE ALL<br/>YOUR QUESTIONS',
    'splash.hilos.subtitle':
      'Chat in real time with native speakers <br/> to clarify any questions about the language you are <br/> learning.',
    'splash.hilos.flow-with-the-world': 'FLOW WITH THE WORLD',
    'splash.footer.indice': 'INDEX',
    'splash.footer.home': 'HOME',
    'splash.footer.launching': 'LAUNCHING',
    'splash.footer.metod': 'METHOD',
    'splash.footer.hilos': 'THREADS',
    'splash.footer.contact': 'CONTACT',
    'splash.footer.team': 'TEAM',
    'splash.footer.contact-we': 'CONTACT US',
    'splash.footer.your-email': 'Your mail',
    'splash.footer.your-message': 'Your message',
    'splash.footer.copy-right': 'Copyright ©',
    'splash.footer.copy-right1': ' MyCoco. All rights reserved.',
    'splash.show-demo': 'SHOW DEMO',
    'login.welcome': 'WELCOME!',
    'login.lemma-1': "We're happy",
    'login.lemma-2': "that you're here again.",
    'login.email': 'Email/Phone',
    'login.password': 'Password',
    'login.remember': 'Remember me',
    'login.forgot': 'I forgot my password',
    'login.login': 'Login',
    'login.signup': 'Sign up',
    'register.account': 'I already have an account',
    'register.name': 'Name',
    'register.phone': 'Phone Number',
    'register.email': 'Email',
    'register.password': 'Password',
    'register.passwordconfirm': 'Password Confirmation',
    'language.select': 'Select Language',
    'language.setting': 'Save settings',
    'language.native': 'Select your native language',
    'language.study': 'What language do you want to study?',
    'request-access.paragraph-1':
      'We are launching the first version of Coco. <br /> An innovative method to learn languages <br /> in an agile, organized and fun way.',
    'request-access.paragraph-2':
      'We are looking for people committed to <br /> their goals and in return they will have hyper-personalized attention. ✨',
    'request-access.paragraph-3':
      'We will be very happy that you are part of the <br /> beginning of this great project.',
    'request-access.paragraph-4': 'See you soon.',
    'request-access.paragraph-5': 'Flow<br />  with<br />  the<br />  world.',
    'request-access.name': 'Name',
    'request-access.age': 'Age',
    'request-access.email': 'Email',
    'request-access.search-country': 'Search country',
    'request-access.form-description':
      'We want to get to know you a little more. Tell us what your goals are and why you would like to learn a new language.',
    'request-access.message': 'Message',
    'request-access.message-button-access': 'Request access',
    'request-access.thanks': 'Thanks!',
    'request-access.success-description':
      'If you were selected, we will send you an invitation to the email <br /> you provided us.',
    'request-access.success-button-gohome': 'Back to Home',
    'request-access.error-name-required': 'Your name is required',
    'request-access.error-email-required': 'Email is required',
    'request-access.error-message-required': 'A message is required',
    'request-access.error-email-invalid': 'The email is not valid',
    // para los demos
    'flashacard-demo.congratulation': 'Congratulations!',
    'flashacard-demo.message': 'you finished your flashcards for today.',
  },
  es: {
    'splash.login': 'Iniciar sesión',
    'splash.request-access': 'SOLICITAR ACCESO',
    'splash.home-title': 'APRENDE IDIOMAS<br /> CON 30 MINUTOS<br /> AL DÍA.',
    'splash.home-description':
      'Coco es una app que te ayudará a organizar<br/> tu viaje hacia la fluidez con 3 pasos diarios:',
    'splash.home-item-1': 'Escuchando música que te encante.',
    'splash.home-item-2': 'Expresándote libremente con temas que te interesen.',
    'splash.home-item-3': 'Repasando con cartas de memorización.',
    'splash.take-advantage': 'APROVECHA TUS',
    'splash.free-times': 'TIEMPOS LIBRES',
    'splash.stuyd-wherever':
      'Estudia en donde quieras y cuando quieras con nuestra app móvil.',
    'splash.download-it-now': 'PRÓXIMAMENTE EN ANDROID Y iOS.',
    'splash.launching.title': 'El GRAN COMIENZO.',
    'splash.launching.subtitle':
      'Estamos listos para que la primera versión de Coco vea la luz y queremos que tú formes parte de este gran comienzo.',
    'splash.launching.paragraph-1':
      'Coco nace con la idea de cambiar la forma en que las personas aprenden idiomas.',
    'splash.launching.paragraph-2':
      'Nos dimos cuenta que con los métodos tradicionales la gente sufre trando de memorizar y memorizar y memorizar las cosas una y otra vez sin tener éxito, y que los temas y contenidos para aprender tienden a ser sumamente aburridos y poco reales.',
    'splash.launching.paragraph-3':
      'Incluso, si eres una persona libre y autodidácta, (como muchos de nosotros), es difícil encontra a alguien que te de buen feedback y que te ayude y te guíe en el camino.',
    'splash.launching.paragraph-4':
      'Con una idea clara nos lanzamos a la aventura de crear una aplicación con un método eficáz. Ha sido un camino difícil y de muchos retos pero extremadamente fascinante.',
    'splash.launching.paragraph-5':
      'Hoy estamos listos para que la primera versión de Coco vea la luz y queremos que tú formes parte de este gran comienzo.',
    'splash.launching.paragraph-6':
      'Queremos acompañarte en el camino hacia tus metas y dedicar todo nuestro esfuerzo en que las logres.',
    'splash.launching.read-more': 'Leer más',
    'splash.launching.founder': 'CEO & Fundador',
    'splash.metod.title': 'EL MÉTODO DE 3 PASOS DIARIOS.',
    'splash.metod.description':
      '<i>No hemos descubierto el hilo negro.</i> Los políglotas más importantes y todas las personas que han llegado a la fluidez coinciden en que hay 3 pasos importantes para poder aprender un idioma nuevo:',
    'splash.metod.card-1':
      'Consumir mucho contenido del idioma que quieren aprender.',
    'splash.metod.card-2': 'Escribir y conversar muchísimo.',
    'splash.metod.card-3': 'Repasar lo aprendido con repetición espaciada.',
    'splash.metod.section-1':
      'EN COCO ENCONTRARÁS<br /> LOS 3 PASOS EN UN SOLO<br /> LUGAR.',
    'splash.metod.section-2': 'ES FÁCIL DE USAR EN 3 SIMPLES PASOS',
    'splash.metod.section-line-1':
      'Explorar y escuchar muchísima música y aprender de su letra.',
    'splash.metod.section-line-1-description':
      'Enlazaremos tu cuenta de Spotify para que tengas a la mano toda la música que quieras.',
    'splash.metod.section-line-2':
      'Escribir, escribir y escribir para practicar el idioma.',
    'splash.metod.section-line-2-description':
      'Tenemos una sección para que puedas expresar todas tus ideas y al hacerlo, un profesor las revisará y te ayudará a mejorar.',
    'splash.metod.section-line-3': 'Memorizar vocabulario con repetición espaciada.',
    'splash.metod.section-line-3-description':
      'Tendrás a la mano cartas de memorización que podrás estudiar con un algoritmo de repetición espaciada.',
    'splash.metod.section-banner': 'EMPIEZA HOY TU VIAJE<br />HACIA LA FLUIDEZ.',
    'splash.metod.section-3': 'APRENDER CON MÚSICA.',
    'splash.metod.section-3-description':
      'Para aprender un idioma necesitas exponerte a muchos sonidos repetidamente. Con la música que prefieras podrás aprender pronunciación y conseguir nuevo vocabulario.<br/><br/> Te mostraremos las letras de las canciones que quieras escuchar y solo tendrás que seleccionar la palabra que quieras aprender y se guardará para que la repases después y así irás llenando tu vocabulario.',
    'splash.metod.section-4': 'PRACTICAR <br /> EL IDIOMA.',
    'splash.metod.section-4-description':
      'Tenemos un lugar en donde podrás<br />expresarte libremente para poner<br />en práctica el idioma y un profesor<br />nativo te dará feedback.',
    'splash.metod.section-5': 'REPASAR CARTAS<br />DE MEMORIZACIÓN.',
    'splash.metod.section-5-description':
      'Todas las frases y palabras que aprendas las podrás<br />meter a un mazo de tarjetas de memorización.<br /><br />Con nuestro algoritmo inteligente de repetición<br />espaciada, podrás memorizar frases de manera<br />eficiente y sin tanto esfuerzo.',
    'splash.content-input.title': 'APRENDE <br/>CON MÚSICA',
    'splash.content-input.subtitle':
      'Tenemos todo un mundo de canciones<br/> con las que podrás aprende. 😎',
    'splash.content-input.subtitle-2':
      'Para aprender un idioma necesitas <br/>exponerte a muchos sonidos <br/>repetidamente.',
    'splash.content-input.subtitle-3':
      'Con la música que prefieras podrás <br/>aprender pronunciación y conseguir <br/>nuevo vocabulario.',
    'splash.content-output.title': 'EXPRÉSATE LIBREMENTE',
    'splash.content-output.subtitle':
      'Podrás poner en práctica de inmediato todo lo que has aprendido.<br /> En esta sección tendrás la oportunidad de expresarte ene el idioma que estés aprendiendo y un profesor nativo te dará retroalimentación y algunos tips.',
    'splash.content-flashcard.subtitle':
      'Todas las frases y palabras que <br/>aprendas las podrás meter a un <br/>mazo de tarjetas de memorización.',
    'splash.content-flashcard.subtitle-2':
      'Con nuestro algoritmo inteligente <br/>de repetición espaciada, podrás <br/>memorizar frases de manera <br/>eficiente y sin tanto esfuerzo.',
    'splash.content-flashcard.more': '[Conoce más]',
    'splash.hilos.title': 'RESUELVE TODAS<br/>TUS DUDAS',
    'splash.hilos.subtitle':
      'Chatea en tiempo real con profesores nativos<br/>para aclarar cualquier duda del idioma que estés<br/>aprendiendo. ',
    'splash.hilos.flow-with-the-world': 'FLUYE CON EL MUNDO',
    'splash.footer.indice': 'INDICE',
    'splash.footer.home': 'INICIO',
    'splash.footer.launching': 'LANZAMIENTO',
    'splash.footer.metod': 'MÉTODO',
    'splash.footer.hilos': 'HILOS',
    'splash.footer.contact': 'CONTACTO',
    'splash.footer.team': 'EQUIPO',
    'splash.footer.contact-we': 'CONTÁCTANOS',
    'splash.footer.your-email': 'Tu Correo',
    'splash.footer.your-message': 'Tu mensaje',
    'splash.footer.copy-right': 'Copyright ©',
    'splash.footer.copy-right1': ' MyCoco. Todos los derechos reservados.',
    'splash.show-demo': 'MOSTRAR DEMO',
    'login.welcome': '¡BIENVENIDO!',
    'login.lemma-1': 'Nos sentimos contentos',
    'login.lemma-2': 'de que estés aquí de nuevo.',
    'login.email': 'Correo/Telefono',
    'login.password': 'Contraseña',
    'login.remember': 'Recordarme',
    'login.forgot': 'Olvidé mi contraseña',
    'login.login': 'Iniciar Sesion',
    'login.signup': 'Registrarse',
    'register.account': 'Ya tengo una cuenta',
    'register.name': 'Nombre',
    'register.phone': 'Número Telefonico',
    'register.email': 'Correo',
    'register.password': 'Contraseña',
    'register.passwordconfirm': 'Confirmar Contraseña',
    'language.select': 'Seleccionar Idioma',
    'language.setting': 'Guardar Configuraciones',
    'language.native': 'Selecciona tu idioma natal',
    'language.study': '¿Qué idioma deseas estudiar?',
    'request-access.paragraph-1':
      'Estamos lanzado la primera versión de Coco.<br /> Un método innovador para aprender idiomas <br /> de manerá ágil, organizada y divertida.',
    'request-access.paragraph-2':
      'Estamos buscando personas comprometidas <br /> con sus metas y a cambio tendrán atención <br /> hiper personalizada. ✨',
    'request-access.paragraph-3':
      'Estaremos muy felices de que formes parte <br /> del inicio de este gran proyecto.',
    'request-access.paragraph-4': 'Hasta pronto.',
    'request-access.paragraph-5': 'Fluye <br /> con <br /> el <br /> mundo.',
    'request-access.name': 'Nombre',
    'request-access.age': 'Edad',
    'request-access.email': 'Correo',
    'request-access.search-country': 'Buscar pais',
    'request-access.form-description':
      'Queremos conocerte un poco más. Cuéntanos cuáles son tus objetivos y por qué te gustaría aprender un idioma nuevo.',
    'request-access.message': 'Mensaje',
    'request-access.message-button-access': 'Solicitar acceso',
    'request-access.thanks': '¡Gracias!',
    'request-access.success-description':
      'Si fuiste seleccionado, te mandaremos una invitación al correo eléctronico <br /> que nos proporcionaste.',
    'request-access.success-button-gohome': 'Regresar a Inicio',
    'request-access.error-name-required': 'El nombre es obligatorio',
    'request-access.error-email-required': 'El correo es obligatorio',
    'request-access.error-message-required': 'El mensaje es obligatorio',
    'request-access.error-email-invalid': 'El correo no es correcto',
    // para los demos
    'flashacard-demo.congratulation': '¡Felicidades!',
    'flashacard-demo.message': 'terminaste las Flashcards de hoy.',
  },
  fr: {
    'splash.login': "S'identifier",
    'splash.request-access': "DEMANDE D'ACCÈS",
    'splash.home-title':
      'APPRENEZ LES LANGUES <br /> AVEC 30 MINUTES <br /> PAR JOUR.',
    'splash.home-description':
      'Coco est une application qui vous aidera à organiser<br/> votre parcours vers la fluidité en 3 étapes quotidiennes:',
    'splash.home-item-1': 'Écouter de la musique que vous aimez.',
    'splash.home-item-2':
      'Expressing yourself freely with topics that interest you.',
    'splash.home-item-3': 'Révision avec des cartes mémoire.',
    'splash.take-advantage': 'PROFITEZ DE VOTRE',
    'splash.free-times': 'TEMPS LIBRE',
    'splash.stuyd-wherever':
      'Étudiez où et quand vous le souhaitez avec notre application mobile.',
    'splash.download-it-now': 'À VENIR SUR ANDROID ET iOS',
    'splash.launching.title': 'LE GRAND DÉBUT.',
    'splash.launching.subtitle':
      'Nous sommes prêts pour la première version de Coco pour voir le jour et nous voulons que vous fassiez partie de ce grand début.',
    'splash.launching.paragraph-1':
      "Coco est né avec l'idée de changer la façon dont les gens apprennent les langues.",
    'splash.launching.paragraph-2':
      "Nous avons réalisé qu'avec les méthodes traditionnelles, les gens souffrent d'essayer de mémoriser, de mémoriser et de mémoriser des choses encore et encore sans succès, et que les sujets et le contenu à apprendre ont tendance à être extrêmement ennuyeux et irréalistes.",
    'splash.launching.paragraph-3':
      "Même si vous êtes une personne libre et autodidacte (comme beaucoup d'entre nous), il est difficile de trouver quelqu'un qui vous donnera de bons retours et qui vous aidera et vous guidera tout au long du processus.",
    'splash.launching.paragraph-4':
      "Avec une idée claire, nous nous sommes lancés dans l'aventure de créer une application avec une méthode efficace. Ce fut une route difficile et difficile mais extrêmement fascinante.",
    'splash.launching.paragraph-5':
      "Aujourd'hui, nous sommes prêts pour la première version de Coco pour voir le jour et nous voulons que vous fassiez partie de ce grand début.",
    'splash.launching.paragraph-6':
      'Nous voulons vous accompagner sur le chemin de vos objectifs et consacrer tous nos efforts pour les atteindre.',
    'splash.launching.read-more': 'Lire plus',
    'splash.launching.founder': 'PDG et fondateur',
    'splash.metod.title': 'LA MÉTHODE QUOTIDIENNE EN 3 ÉTAPES.',
    'splash.metod.description':
      '<i> Nous n`avons pas découvert le fil noir. </i> Les polyglottes les plus importants et toutes les personnes qui maîtrisent parfaitement s`accordent à dire qu`il y a 3 étapes importantes pour pouvoir apprendre une nouvelle langue:',
    'splash.metod.card-1':
      'Consommez beaucoup de contenu dans la langue qu`ils souhaitent apprendre.',
    'splash.metod.card-2': 'Écrivez et parlez beaucoup.',
    'splash.metod.card-3':
      'Passez en revue ce qu`ils ont appris avec des répétitions espacées.',
    'splash.metod.section-1':
      'DANS COCO, VOUS TROUVEZ <br /> LES 3 ÉTAPES EN UN SEUL ENDROIT. <br />',
    'splash.metod.section-2': 'IL EST FACILE À UTILISER EN 3 ÉTAPES SIMPLES',
    'splash.metod.section-line-1':
      'Explorez et écoutez beaucoup de musique et apprenez de ses paroles.',
    'splash.metod.section-line-1-description':
      'Nous lierons votre compte Spotify afin que vous ayez toute la musique que vous voulez sous la main.',
    'splash.metod.section-line-2':
      'Écrivez, écrivez et écrivez pour pratiquer la langue.',
    'splash.metod.section-line-2-description':
      'Nous avons une section pour que vous puissiez exprimer toutes vos idées et ce faisant, un enseignant les examinera et vous aidera à vous améliorer.',
    'splash.metod.section-line-3':
      'Mémorisez le vocabulaire avec des répétitions espacées.',
    'splash.metod.section-line-3-description':
      'Vous aurez sous la main des cartes mémoire que vous pourrez étudier avec un algorithme de répétition espacée.',
    'splash.metod.section-banner':
      'COMMENCEZ VOTRE VOYAGE AUJOURD`HUI <br /> VERS LA FLUIDITÉ.',
    'splash.metod.section-3': 'APPRENEZ AVEC LA MUSIQUE.',
    'splash.metod.section-3-description':
      'Pour apprendre une langue, vous devez vous exposer à de nombreux sons à plusieurs reprises. Avec la musique que vous préférez, vous pouvez apprendre la prononciation et obtenir un nouveau vocabulaire.<br/><br/> Nous vous montrerons les paroles des chansons que vous souhaitez écouter et il vous suffit de sélectionner le mot que vous voulez apprendre et il sera sauvegardé pour à revoir plus tard et ainsi vous remplirez votre vocabulaire.',
    'splash.metod.section-4': 'PRATIQUE <br /> LA LANGUE.',
    'splash.metod.section-4-description':
      'Nous avons un espace où vous pouvez <br /> vous exprimer librement pour mettre en pratique la <br /> langue et un professeur <br /> natif vous donnera un retour d’information.',
    'splash.metod.section-5': 'EXAMINER LES CARTES<br /> MÉMOIRE.',
    'splash.metod.section-5-description':
      'Toutes les phrases et mots que vous apprenez peuvent être <br /> mis dans un jeu de cartes mémoire. <br /> <br /> Grâce à notre algorithme intelligent de répétition <br /> espacée, vous pouvez mémoriser des phrases dans un <br / > efficace et sans effort.',
    'splash.content-input.title': 'APPRENEZ <br/>AVEC LA MUSIQUE',
    'splash.content-input.subtitle':
      'Nous avons tout un monde de chansons <br/> avec lesquelles vous pouvez apprendre. 😎',
    'splash.content-input.subtitle-2':
      'Pour apprendre une langue, <br/>vous devez vous exposer à plusieurs sons à <br/>plusieurs reprises.',
    'splash.content-input.subtitle-3':
      'Avec la musique de votre choix, vous pouvez apprendre <br/>la prononciation et obtenir un <br/>nouveau vocabulaire.',
    'splash.content-output.title': 'EXPRIMEZ-VOUS LIBREMENT',
    'splash.content-output.subtitle':
      "Vous serez en mesure de mettre en pratique tout ce que vous avez appris immédiatement. <br /> Dans cette section, vous aurez l'opportunité de vous exprimer dans la langue que vous apprenez et un professeur natif vous donnera des commentaires et des conseils.",
    'splash.content-flashcard.subtitle':
      'Toutes les phrases et tous <br/>les mots que vous apprenez peuvent être <br/>placés dans un jeu de cartes mémoire.',
    'splash.content-flashcard.subtitle-2':
      'Grâce à notre algorithme de <br/>répétition espacée intelligent, <br/>vous pouvez mémoriser des phrases <br/>efficacement et sans effort.',
    'splash.content-flashcard.more': '[Apprendre encore plus]',
    'splash.hilos.title': 'RÉSOLVEZ TOUS<br/>VOS DOUTES',
    'splash.hilos.subtitle':
      'Discutez en temps réel avec des locuteurs natifs <br/> pour clarifier tout doute sur la langue que vous <br/> apprenez.',
    'splash.hilos.flow-with-the-world': 'FLUX AVEC LE MONDE',
    'splash.footer.indice': 'INDICE',
    'splash.footer.home': 'DOMICILE',
    'splash.footer.launching': 'LANCEMENT',
    'splash.footer.metod': 'MÉTHODE',
    'splash.footer.hilos': 'FILS',
    'splash.footer.contact': 'CONTACT',
    'splash.footer.team': 'ÉQUIPE',
    'splash.footer.contact-we': 'NOUS CONTACTER',
    'splash.footer.your-email': 'Ton courrier',
    'splash.footer.your-message': 'Ton message',
    'splash.footer.copy-right': 'Copyright ©',
    'splash.footer.copy-right1': ' MyCoco. Tous les droits sont réservés.',
    'splash.show-demo': 'MONTRER LA DÉMO',
    'login.welcome': 'BIENVENUE\n!',
    'login.lemma-1': 'Nous nous sentons heureux',
    'login.lemma-2': 'que vous êtes de nouveau ici.',
    'login.email': 'Email / Téléphone',
    'login.password': 'Mot de passe',
    'login.remember': 'Souviens toi de moi',
    'login.forgot': "J'ai oublié mon mot de passe",
    'login.login': 'Commencer la session',
    'login.signup': "S'inscrire",
    'register.account': "J'ai déjà un compte",
    'register.name': 'nom',
    'register.phone': 'Numéro de téléphone',
    'register.email': 'Email',
    'register.password': 'Mot de passe',
    'register.passwordconfirm': 'Confirmer mot de passe',
    'language.select': 'Sélectionnez la langue',
    'language.setting': 'Enregistrer les paramètres',
    'language.native': 'Sélectionnez votre langue maternelle',
    'language.study': 'Quelle langue souhaitez-vous étudier?\n',
    'request-access.paragraph-1':
      'Nous lançons la première version de Coco. <br /> Une méthode innovante pour apprendre les langues <br /> de manière agile, organisée et ludique.',
    'request-access.paragraph-2':
      "Nous recherchons des personnes engagées dans <br /> leurs objectifs et en retour, elles bénéficieront d'une attention hyper-personnalisée. ✨",
    'request-access.paragraph-3':
      'Nous serons très heureux que vous fassiez partie du <br /> début de ce grand projet.',
    'request-access.paragraph-4': 'À bientôt.',
    'request-access.paragraph-5': 'Flux <br /> avec <br /> le <br /> monde.',
    'request-access.name': 'nom',
    'request-access.age': 'Âge',
    'request-access.email': 'Courrier',
    'request-access.search-country': 'Rechercher un pays',
    'request-access.form-description':
      'Nous voulons vous connaître un peu plus. Dites-nous quels sont vos objectifs et pourquoi vous aimeriez apprendre une nouvelle langue.',
    'request-access.message': 'Message',
    'request-access.message-button-access': "Demande d'accès",
    'request-access.thanks': '¡Je vous remercie!',
    'request-access.success-description':
      "Si vous avez été sélectionné, nous vous enverrons une invitation à l'adresse email <br /> que vous nous avez fournie.",
    'request-access.success-button-gohome': 'De retour à la maison',
    'request-access.error-name-required': 'Le nom est obligatoire',
    'request-access.error-email-required': 'Le courrier est requis',
    'request-access.error-message-required': 'Le message est requis',
    'request-access.error-email-invalid': "L'email n'est pas correct",
    // para los demos
    'flashacard-demo.congratulation': 'Toutes nos félicitations!',
    'flashacard-demo.message': "vous avez terminé les flashcards pour aujourd'hui.",
  },
}
