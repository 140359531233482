import React from 'react'
import {useIntl} from 'react-intl'
import {
  DiccionaryType,
  isDiccionaryCoco,
  isDiccionaryGoogle,
  isDiccionaryOxford,
} from './helperDictionary'

const HeaderDictionaries = ({
  diccionartTypeSelected,
  setDiccionartTypeSelected,
  getPhrasesOxford,
  getDefinitions,
}) => {
  const intl = useIntl()
  return (
    <div className="diccionary__headers__buttons">
      <button
        className={`btn__header__dictionary ${
          isDiccionaryCoco(diccionartTypeSelected) &&
          'btn__header__dictionary__active'
        }`}
        onClick={() => setDiccionartTypeSelected(DiccionaryType.COCO)}>
        Coco
      </button>
      <button
        className={`btn__header__dictionary ${
          isDiccionaryOxford(diccionartTypeSelected) &&
          'btn__header__dictionary__active'
        }`}
        onClick={getPhrasesOxford}>
        Oxford
      </button>
      <button
        className={`btn__header__dictionary ${
          isDiccionaryGoogle(diccionartTypeSelected) &&
          'btn__header__dictionary__active'
        }`}
        onClick={getDefinitions}>
        {intl.formatMessage({
          id: 'diccionary.definitions',
          defaultMessage: 'Definitions',
        })}
      </button>
    </div>
  )
}

export default HeaderDictionaries
