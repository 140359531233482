import React from 'react'
import {IntlProvider} from 'react-intl'
import useLanguages from '../../hooks/useLanguages'
import languages from '../../MultiLingual/languages'

const IntLProviderMain = ({children}) => {
  const {languageActive} = useLanguages()

  const {messages} = languages()
  const unicode = languageActive?.unicode || 'es'

  return (
    <IntlProvider locale={unicode} messages={messages[unicode]}>
      {children}
    </IntlProvider>
  )
}

export default IntLProviderMain
