import React from 'react'
import './NotificationBadge.css'
import {BadgeType} from './helpers'

const defaultStyle = {
  marginLeft: '5px',
}

const NotificationBadge = ({
  number = null,
  type = BadgeType.SMALL,
  styles = {...defaultStyle},
}) => {
  const getNumber = () => {
    if (number <= 0) {
      return null
    } else if (number > 5) {
      return '+5'
    }
    return `${number}`
  }

  const BadgeWhithNumber = () => {
    return (
      <span className="notification-badge-whit-number" style={styles}>
        {number && (
          <span className="notification-badge-number">{getNumber()?.trim()}</span>
        )}
      </span>
    )
  }

  const getContentMain = () => {
    switch (type) {
      case BadgeType.SMALL:
        return <span className="notification-badge-small" style={styles} />
      case BadgeType.BIG:
        return <span className="notification-badge-big" style={styles} />
      case BadgeType.BIG_WHITH_NUMBER:
        return <BadgeWhithNumber />
      default:
        break
    }
  }

  return getContentMain()
}

export default NotificationBadge
