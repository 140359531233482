export default {
  en: {
    'mediatext.song': 'Song',
    'mediatext.artist': 'Artist',
    'mediatext.genre': 'Genre',
    'mediatext.add-lyrics': 'Enter the lyrics of the song.',
    'mediatext.contribution':
      'Your contribution will help you and other students to enjoy this song.',
    'mediatext.frecuency': 'Frequency of use.',
    'mediatext.frecuency-repeat':
      'In a language, there are words that are repeated many times, and others that are not used as often.',
    'mediatext.focus':
      "Focus on the most frequent words and you'll be amazed at how fast you'll advance.",
    'mediatext.green': 'Green:',
    'mediatext.turquoise': 'Turquoise:',
    'mediatext.blue': 'Blue:',
    'mediatext.purple': 'Purple:',
    'mediatext.green-explane':
      "We'll show you the most frequent words (from word 1 to 300). You'll be able to understand 65% of spoken language*",
    'mediatext.turquoise-explane':
      "We'll show you the 2nd frequency group (from word 301 to 1000). You'll be able to understand 85% of spoken language*",
    'mediatext.blue-explane':
      "We'll show you the 3rd frequency group (from word 1001 to 2000). You'll be able to understand 92% of spoken language*",
    'mediatext.purple-explane':
      "We will show you the less frequent words (from word 2001 to 5000). You'll be able to understand 95% of spoken language*",
    'mediatext.got-it': 'Got it',
    'mediatext.sound': 'Sound',
    'mediatext.grammar': 'Grammar',
    'mediatext.definition': 'Definition',
    'mediatext.close': 'Close X',
    'mediatext-report-close': 'Close',
    'mediatext.report-success': 'Your report was sent successfully',
    'mediatext.send': 'Send',
    'mediatext.no-dictionary': "There's no Dictionary",
    'mediatext.comment': 'Add Comment',
    'mediatext.other': 'Other',
    'mediatext.bad-translation': 'Bad Translation',
    'mediatext.bad-grammar': 'Bad grammar',
    'mediatext.bad-sound': "The sound doesn't match",
    'mediatext.reason': 'Select the reason for your report.',
    'mediatext.next': 'Next',
    'mediatext.new': 'New Playlist',
    'mediatext.songs': 'songs',
    'mediatext.song1': 'song',
    'mediatext.playlist': 'My Playlists',
    'mediatext.create': 'Create Playlist',
    'mediatext.search': 'Search an image',
    'mediatext.image-save': 'Save image',
    'mediatext.name': 'Playlist name',
    'mediatext.description': 'Playlist description',
    'mediatext.search-image': 'Search Image',
    'mediatext.playlist-success-created': 'Playlist created!',
    'mediatext.playlist-error': 'The playlist was not created',
    'mediatext.playlist-title-required': 'Title required',
    'mediatext.term': 'Search term',
    'dashboard.likes': 'Your likes',
    'mediatext.no-lyrics': "We can't find the lyrics for this song.",
    'mediatext.help':
      'Help us to enter the lyrics of the song so that you and more students can enjoy.',
    'mediatext.type': 'Enter the lyrics of a song in its original language',
    'mediatext.word-learned': 'Word already learned',
    'mediatext.already-added': 'Already added',
    'mediatext.type-text': 'Text',
    'mediatext.search-music': 'Find your first song',
    'mediatext.search-button': 'Search',
    'mediatext.text-add-new': 'Add new text',
    'mediatext.text-add-image': 'Add cover',
    'mediatext.text-add-texto': 'Add Text',
    'mediatext.text-title': 'Title',
    'mediatext.text-description':
      'Write a lot! Make a mistake! Then you will learn faster',
    'mediatext.text-save-button': 'Upload text',
    'mediatext.text-search-image': 'Search an image',
    'mediatext.text-search-save': 'Save',
    'mediatext.text-error-title': 'The title is required.',
    'mediatext.text-error-description': 'Description is required.',
    'mediatext.text-save-success': 'Text added successfully!',
    'mediatext.is-verified': 'Verified',
    'mediatext.not-verified': 'Not verified',
    'mediatext.alert-language-title': 'This song is on',
    'mediatext.alert-language-other': 'another language',
    'mediatext.alert-language-message':
      'The song you selected is not in the language you are learning and may have some issues.',
    'mediatext.alert-language-search-other': 'Find another song',
    'mediatext.alert-language-continue': 'Continue',
    'mediatext.actions-scrolling-active': 'Auto scroll active',
    'mediatext.actions-scrolling-inactive': 'Auto scroll disabled',
    'mediatext.actions-show-karaoke': 'Show karaoke',
    'mediatext.actions-show-lines': 'Show complete lines',
    'mediatext.actions-share': 'Share track',
    'mediatext.actions-report': 'Report track',
    'mediatext.lines-equals-edited': 'Updated lines',
    'mediatext.list-word-delete': 'Word removed from your learning list',
    'mediatext.list-word-add': 'Word added to your learning list',
    'mediatext.list-word-title': 'Words included in the song',
    'mediatext.list-word-level': 'LEVEL WORDS',
    'mediatext.list-word-empty': 'There are no words on this frequency.',
  },
  es: {
    'mediatext.song': 'Canción',
    'mediatext.artist': 'Artista',
    'mediatext.genre': 'Género',
    'mediatext.add-lyrics': 'Ingresa la letra de la canción.',
    'mediatext.contribution':
      'Tu contribución ayudará a que tú y más alumnos disfruten esta canción.',
    'mediatext.frecuency': 'Frecuencia de uso.',
    'mediatext.frecuency-repeat':
      'En un idioma, hay palabras que se repiten muchas veces, y otras que se ocupan en pocas ocaciones.',
    'mediatext.focus':
      'Enfócate en las palabras más repetitivas y sorpréndete de lo rápido que avanzarás.',
    'mediatext.green': 'Verde:',
    'mediatext.turquoise': 'Turquesa:',
    'mediatext.blue': 'Azul:',
    'mediatext.purple': 'Morada:',
    'mediatext.green-explane':
      'Te mostraremos las palabras más frecuentes (de la palabra 1 a la 300). Podrás entender el 65% del idioma hablado*',
    'mediatext.turquoise-explane':
      'Te mostraremos la frecuencia 2 (de la palabra 301 a la 1000). Entenderás el 85% del idioma hablado*',
    'mediatext.blue-explane':
      'Te mostraremos la frecuencia 3 (de la palabra 1001 a la 2000). Entenderás el 92% del idioma hablado*',
    'mediatext.purple-explane':
      'Te mostraremos las palabras menos frecuenctes (de la palabra 2001 a la 5000). Aprenderás el 95% del idioma*',
    'mediatext.got-it': 'Entendido',
    'mediatext.sound': 'Sonido',
    'mediatext.grammar': 'Grámatica',
    'mediatext.definition': 'Definición',
    'mediatext.close': 'Cerrar X',
    'mediatext-report-close': 'Cerrar',
    'mediatext.report-success': 'Tu reporte fue enviado con éxito',
    'mediatext.send': 'Enviar',
    'mediatext.no-dictionary': 'No hay Diccionario',
    'mediatext.comment': 'Agregar TeacherComment',
    'mediatext.other': 'Otro',
    'mediatext.bad-translation': 'Mala traducción',
    'mediatext.bad-grammar': 'Mala Ortografia',
    'mediatext.bad-sound': 'El sonido no coincide',
    'mediatext.reason': 'Selecciona el motivo de tu reporte.',
    'mediatext.next': 'Siguiente',
    'mediatext.new': 'Nuevo Playlist',
    'mediatext.songs': 'canciones',
    'mediatext.song1': 'cancion',
    'mediatext.playlist': 'Mis playlists',
    'mediatext.create': 'Crear Playlist',
    'mediatext.search': 'Buscar Imagen',
    'mediatext.image-save': 'Guardar imagen',
    'mediatext.name': 'Nombre del playlist',
    'mediatext.description': 'Descripcion del playlist',
    'mediatext.search-image': 'Buscar Imagen',
    'mediatext.playlist-success-created': '¡Playlist creado!',
    'mediatext.playlist-error': 'El playlist no se creo',
    'mediatext.playlist-title-required': 'Titulo requerido',
    'mediatext.term': 'Termino de busqueda',
    'dashboard.likes': 'Tus me gusta',
    'mediatext.no-lyrics': 'No encontramos la letra de esta canción.',
    'mediatext.help':
      'Ayudanos a ingresar la letra de la canción para que tú y más alumnos puedan disfrutar.',
    'mediatext.type': 'Ingrese la letra de una canción en su idioma original',
    'mediatext.word-learned': 'Palabra ya aprendida',
    'mediatext.already-added': 'Ya agregado',
    'mediatext.type-text': 'Texto',
    'mediatext.search-music': 'Busca tu primera canción',
    'mediatext.search-button': 'Buscar',
    'mediatext.text-add-new': 'Agregar texto nuevo',
    'mediatext.text-add-image': 'Agregar portada',
    'mediatext.text-add-texto': 'Agregar Texto',
    'mediatext.text-title': 'Titulo',
    'mediatext.text-description':
      '¡Escribe mucho! ¡Cometer un error! Entonces aprenderás más rápido',
    'mediatext.text-save-button': 'Subir texto',
    'mediatext.text-search-image': 'Busca una imagen',
    'mediatext.text-search-save': 'Guardar',
    'mediatext.text-error-title': 'El titulo es requerido.',
    'mediatext.text-error-description': 'La descripción es requerida.',
    'mediatext.text-save-success': 'Texto agregado con éxito!',
    'mediatext.is-verified': 'Verificada',
    'mediatext.not-verified': 'No verificada',
    'mediatext.alert-language-title': 'Esta canción está en',
    'mediatext.alert-language-other': 'otro idioma',
    'mediatext.alert-language-message':
      'La canción que seleccionaste no está en el idioma que estás aprendiendo y es posible que presente algunos problema.',
    'mediatext.alert-language-search-other': 'Buscar otra canción',
    'mediatext.alert-language-continue': 'Continuar',
    'mediatext.actions-scrolling-active': 'Auto scroll activo',
    'mediatext.actions-scrolling-inactive': 'Auto scroll inactivo',
    'mediatext.actions-show-karaoke': 'Ver karaoke',
    'mediatext.actions-show-lines': 'Ver lineas completas',
    'mediatext.actions-share': 'Compartir musica',
    'mediatext.actions-report': 'Reportar musica',
    'mediatext.lines-equals-edited': 'Lineas actualizadas',
    'mediatext.list-word-delete': 'Palabra eliminada de tu lista de aprendizaje',
    'mediatext.list-word-add': 'Palabra agregada a tu lista de aprendizaje',
    'mediatext.list-word-title': 'Palabras incluidas en la canción',
    'mediatext.list-word-level': 'PALABRAS DE NIVEL',
    'mediatext.list-word-empty': 'No hay palabras en esta frecuencia.',
  },
  fr: {
    'mediatext.song': 'Chanson',
    'mediatext.artist': 'Artiste',
    'mediatext.genre': 'Genre',
    'mediatext.add-lyrics': 'Entrez les paroles de la chanson.',
    'mediatext.contribution':
      "Votre contribution vous aidera, vous et plus d'élèves, à apprécier cette chanson.",
    'mediatext.frecuency': "Fréquence d'utilisation.",
    'mediatext.frecuency-repeat':
      "Dans une langue, il y a des mots qui se répètent plusieurs fois, et d'autres qui sont traités en quelques occasions.",
    'mediatext.focus':
      'Concentrez-vous sur les mots les plus répétitifs et étonnez-vous de la vitesse à laquelle vous avancerez.',
    'mediatext.green': 'Vert:',
    'mediatext.turquoise': 'Turquoise:',
    'mediatext.blue': 'Bleu:',
    'mediatext.purple': 'pourpre:',
    'mediatext.green-explane':
      'Nous vous montrerons les mots les plus fréquents (du mot 1 au 300). Vous pourrez comprendre 65% de la langue parlée *',
    'mediatext.turquoise-explane':
      'Nous vous montrerons la fréquence 2 (du mot 301 à 1000). Vous comprendrez 85% de la langue parlée *',
    'mediatext.blue-explane':
      'Nous vous montrerons la fréquence 3 (du mot 1001 à 2000). Vous comprendrez 92% de la langue parlée *',
    'mediatext.purple-explane':
      'Nous vous montrerons les mots les moins fréquents (du mot 2001 à 5000). Vous apprendrez 95% de la langue *',
    'mediatext.got-it': 'Compris',
    'mediatext.sound': 'Son',
    'mediatext.grammar': 'gramatica',
    'mediatext.definition': 'Définition',
    'mediatext.close': 'Fermer X',
    'mediatext-report-close': 'Fermer',
    'mediatext.report-success': 'Votre rapport a été envoyé avec succès',
    'mediatext.send': 'Envoyer',
    'mediatext.no-dictionary': "Il n'y a pas de dictionnaire",
    'mediatext.comment': 'Ajouter un commentaire',
    'mediatext.other': 'Autre',
    'mediatext.bad-translation': 'Mauvaise traduction',
    'mediatext.bad-grammar': 'Mauvaise orthographe',
    'mediatext.bad-sound': 'Le son ne correspond pas',
    'mediatext.reason': 'Sélectionnez la raison de votre rapport.',
    'mediatext.next': 'Suivant',
    'mediatext.new': 'Nouvelle liste de lecture',
    'mediatext.songs': 'chansons',
    'mediatext.song1': 'chanson',
    'mediatext.playlist': 'Mes listes de playlist',
    'mediatext.create': 'Créer une playlist\n',
    'mediatext.search': "Recherche d'image",
    'mediatext.image-save': 'Sauvegarder image',
    'mediatext.name': 'Nom de la playlist',
    'mediatext.description': 'Description de la playlist',
    'mediatext.search-image': 'image de recherche',
    'mediatext.playlist-success-created': 'Playlist créée!',
    'mediatext.playlist-error': "La playlist n'a pas été créée",
    'mediatext.playlist-title-required': 'Titre requis',
    'mediatext.term': 'Terme de recherche',
    'dashboard.likes': "Je t'aime",
    'mediatext.no-lyrics':
      'Nous ne pouvons pas trouver les paroles de cette chanson.',
    'mediatext.help':
      "Aidez-nous à saisir les paroles de la chanson pour que vous et plus d'élèves puissiez en profiter.",
    'mediatext.type': "Entrez les paroles d'une chanson dans sa langue d'origine",
    'mediatext.word-learned': 'Mot déjà appris',
    'mediatext.already-added': 'Déjà ajouté',
    'mediatext.type-text': 'Texte',
    'mediatext.search-music': 'Trouvez votre première chanson',
    'mediatext.search-button': 'Chercher',
    'mediatext.text-add-new': 'Ajouter un nouveau texte',
    'mediatext.text-add-image': 'Ajouter une couverture',
    'mediatext.text-add-texto': 'Ajouter du texte',
    'mediatext.text-title': 'Titre',
    'mediatext.text-description':
      'Écrivez beaucoup! Faire une erreur! Alors tu apprendras plus vite',
    'mediatext.text-save-button': 'Télécharger du texte',
    'mediatext.text-search-image': 'Rechercher une image',
    'mediatext.text-search-save': 'sauvegarder',
    'mediatext.text-error-title': 'Le titre est obligatoire.',
    'mediatext.text-error-description': 'Une description est requise.',
    'mediatext.text-save-success': 'Texte ajouté avec succès!',
    'mediatext.is-verified': 'Vérifié',
    'mediatext.not-verified': 'non vérifié',
    'mediatext.alert-language-title': 'Cette chanson est allumée',
    'mediatext.alert-language-other': 'une autre langue',
    'mediatext.alert-language-message':
      'The song you selected is not in the language you are learning and may have some issues.',
    'mediatext.alert-language-search-other': 'Une autre chanson',
    'mediatext.alert-language-continue': 'continuer',
    'mediatext.actions-scrolling-active': 'Activo de défilement automatique',
    'mediatext.actions-scrolling-inactive': 'Défilement automatique désactivé',
    'mediatext.actions-show-karaoke': 'regarder le karaoké',
    'mediatext.actions-show-lines': 'Voir les lignes complètes',
    'mediatext.actions-share': 'Partager de la musique',
    'mediatext.actions-report': 'Signaler de la musique',
    'mediatext.lines-equals-edited': 'Lignes mises à jour',
    'mediatext.list-word-delete': 'Mot supprimé de votre liste d`apprentissage',
    'mediatext.list-word-add': 'Word ajouté à votre liste d`apprentissage',
    'mediatext.list-word-title': 'Mots inclus dans la chanson',
    'mediatext.list-word-level': 'MOTS DE NIVEAU',
    'mediatext.list-word-empty': 'Il n`y a pas de mots sur cette fréquence.',
  },
}
