import React, {useState, useEffect} from 'react'
import './Peculiaridades.css'

const Peculiaridades = ({titulo, is_active, onclick, eliminar_tag, side, tipo}) => {
  const [colorTag, setColorTag] = useState('region')

  useEffect(() => {
    const lista = [
      'region',
      'slang',
      'vulgar',
      'supervivencia',
      'formal',
      'extraño',
      'anticuado',
    ]
    let color_obtenido = lista.find((t) => tipo === t)

    if (!color_obtenido) {
      const lista_tags = lista_de_colores_aleatorios()
      color_obtenido = lista_tags[Math.floor(Math.random() * lista_tags.length)]
    }
    setColorTag(color_obtenido)
  }, [tipo])

  const Obtener_colorTag = () => {
    return `btn-${colorTag} ${is_active && `btn-${colorTag}-active`}`
  }

  // colores extras que se asignaran de forma aleatoria
  const lista_de_colores_aleatorios = () => {
    return ['verde', 'rojo', 'gris', 'rosybrown', 'seagreen', 'tomato', 'turquoise']
  }

  // agrega o elimina un tag
  const Agregar_Eliminar_tag = () => {
    const funcion_tag = is_active ? eliminar_tag : onclick
    // si existe alguna funcion se ejecuta
    if (funcion_tag) {
      funcion_tag(titulo, side)
    }
  }

  return (
    <button
      className={`btn-peculiaridades ${Obtener_colorTag()} ${
        side === 'back' && 'black'
      }`}
      onClick={() => Agregar_Eliminar_tag()}>{`#${titulo}`}</button>
  )
}

export default Peculiaridades
