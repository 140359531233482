import React from 'react'
import {Link} from 'react-router-dom'
import './TrackItem.css'

import Heart from '../PlayList/Tracks/Heart'
import ContextMenuPlaylist from '../ContextMenu/ContextMenuPlaylist'
import noteIcon from '../Icons/note.svg'
import DefaultPhotoUserIcon from '../Icons/DefaultPhotoUser.svg'
import DefaultIconTrack from '../Icons/DefaultIconTrack.svg'
import useLanguages from '../../hooks/useLanguages'
import MisLikesIcon from '../Icons/MisLikes.svg'
import {FormattedMessage} from 'react-intl'
import PlayIcon from '../Icons/play.svg'
import PauseIcon from '../Icons/pause.svg'

const TrackItem = ({
  shareTrack,
  createNewPlaylist,
  id,
  uri,
  spotify_id,
  titulo,
  image_url,
  user_input_id,
  artistas,
  mis_likes,
  authorCount,
  songCount,
  isShared = false,
  state,
  userName,
  userPhoto,
  itemNote = '',
  showMediatext,
  showControlsItems = true,
  showLinksArtist = true,
  classNameContainer = '',
  classNameImage = '',
  classNameTitle = '',
  playSong,
  stopSong,
  isActive = false,
}) => {
  const isValidNote = () => {
    return itemNote?.trim().length > 0
  }

  const {languageActive} = useLanguages()
  const lang = languageActive?.unicode ? languageActive.unicode : 'es'
  const go = lang === 'es' ? 'Ir a' : 'Go to'

  if (mis_likes) {
    return (
      <Link
        to="/mis-me-gusta"
        className={`contenedor-track-item ${classNameContainer}`}>
        <div
          className={`contenedor-track-item-fondo-mylikes orange-gradient ${classNameImage}`}>
          <img src={MisLikesIcon} alt={MisLikesIcon} />
        </div>

        <div className="track-item-information track-item-new-margin-normal">
          <p className={`track-item-titulo ${classNameTitle}`}>
            <FormattedMessage id={'dashboard.likes'} defaultMessage={'Your Likes'} />
          </p>
          <p className="track-item-artista-link" style={{marginTop: '5px'}}>
            {`${songCount} song${songCount > 1 || songCount === 0 ? 's' : ''} / `}
            {`${authorCount} artist${
              authorCount > 1 || authorCount === 0 ? 's' : ''
            }`}
          </p>
        </div>
      </Link>
    )
  } else {
    return (
      <div
        onClick={showMediatext}
        className={`contenedor-track-item ${classNameContainer}`}>
        <div
          className={`contenedor-track-item-fondo ${classNameImage}`}
          style={{
            width: '100%',
            backgroundImage: `url(${image_url ? image_url : DefaultIconTrack})`,
          }}>
          {showControlsItems && (
            <div className="contendor-track-items">
              <div onClick={(e) => e.stopPropagation()} className="track-item-like">
                <Heart
                  id={id}
                  user_input={user_input_id}
                  spotify_id={spotify_id}
                  titulo={titulo}
                  image_url={image_url}
                  artistas={artistas}
                />
              </div>

              <div onClick={(e) => e.stopPropagation()} className="track-item-play">
                <button
                  className="btn-controles-track"
                  onClick={() => (isActive ? stopSong() : playSong())}>
                  <img
                    src={isActive ? PauseIcon : PlayIcon}
                    alt={isActive ? PauseIcon : PlayIcon}
                    className={
                      isActive ? 'stop-icon-controles' : 'play-icon-controles'
                    }
                  />
                </button>
              </div>

              <div onClick={(e) => e.stopPropagation()} className="track-item-menu">
                <ContextMenuPlaylist
                  type={'dashboard'}
                  id={id}
                  user_input={user_input_id}
                  spotify_id={spotify_id}
                  titulo={titulo}
                  image_url={image_url}
                  artistas={artistas}
                  showMediatext={showMediatext}
                  columsShow={{sacarPlaylist: false}}
                  shareTrack={shareTrack}
                  createNewPlaylist={createNewPlaylist}
                />
              </div>
            </div>
          )}

          {isShared && (
            <div className="track-item-photo-user-container">
              <div
                onClick={(e) => e.stopPropagation()}
                className="track-item-photo-user"
                style={{
                  backgroundImage: `url(${
                    userPhoto?.url ? userPhoto.url : DefaultPhotoUserIcon
                  })`,
                }}>
                {isValidNote() ? (
                  <>
                    <img
                      className="track-item-note-icon"
                      src={noteIcon}
                      alt="note"
                    />
                    <div className="track-item-tooltip">
                      <p className="track-item-tooltip-name">
                        {userName || 'Usuario de coco'}
                      </p>
                      <p title={itemNote} className="track-item-tooltip-note">
                        {itemNote}
                      </p>
                    </div>
                  </>
                ) : (
                  <div className="track-item-tooltip-name-user">{userName}</div>
                )}
              </div>
            </div>
          )}
        </div>

        <div onClick={(e) => e.stopPropagation()} className="track-item-information">
          <div
            className={`${
              isShared ? 'track-item-new-margin-top' : 'track-item-new-margin-normal'
            }`}>
            {isShared && state === 'new' && (
              <div className="track-item-new" title="no leido" />
            )}
            <p
              className={`track-item-titulo ${classNameTitle}`}
              onClick={showMediatext}>
              {titulo}
            </p>
          </div>

          {showLinksArtist ? (
            <div>
              {artistas?.length > 0
                ? artistas.map((a, index) => (
                    <Link
                      className="track-item-artista-link"
                      key={index}
                      to={`/artista/${a.spotify_id}`}
                      title={`${go} ${a.nombre}`}>
                      {`${a.nombre}${index < artistas.length - 1 ? ', ' : ''}`}
                    </Link>
                  ))
                : 'artist name'}
            </div>
          ) : (
            <div className="track__item__artists__names">
              {artistas?.length > 0 && artistas.map((a) => a?.nombre).join(', ')}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default TrackItem
