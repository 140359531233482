import React, {useEffect, useState} from 'react'
import './CustomFlashcard.css'
import BotonTraductorLoading from '../Buttons/BotonTraductorLoading'
import BotonConBordeDegradado from '../Buttons/BotonConBordeDegradado'
import {FormattedMessage, useIntl} from 'react-intl'
import {QuitarCaracteresParaTraducir} from '../../Services/CadenasSplit'
import {PlusButtonScrean} from './PlusButton/PlusButtonModal'
import {traductorBody} from '../../lib/coco/DiccionaryAPi'
import {
  createFlashcardItem,
  getFlashcardYaAgregado,
} from '../../lib/coco/flashcardsApi'
import useUser from '../../hooks/useUser'
import useBooleanToggler, {useWorkingIndicator} from '../../hooks/useBooleanToggler'
import SelectDomains from '../Shared/SelectDomains'
import BottonSelectorDomains from '../Shared/BottonSelectorDomains'
import {Colors} from '../Shared/helpers'
import useDomain from '../../hooks/useDomain'
import useAlert, {TOAST_TYPE} from '../../hooks/useAlert'
import TextareaAutosize from 'react-autosize-textarea'
import ListImagesUnsplash from '../PlayList/ListImagesUnsplash'
import {useDebounce} from 'use-debounce'

const CustomFlashcard = ({
  title,
  summary,
  setTitle,
  setSummary,
  setImageCustom,
  imageCustom,
  changeView,
}) => {
  const intl = useIntl()
  const {idioma_nativo, dispatchGetAllProgress} = useUser()
  const {isToggled: isToggledLanguages, toggle: toggleDomains} = useBooleanToggler()
  const {showMessage} = useAlert()
  const [query, setQuery] = useState('')
  const [debounceSummary] = useDebounce(summary, 2000)

  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const {
    isWorking: isWorkingFlashard,
    startWork: startWorkFlashcard,
    finishWork: finishWorkFlashcard,
  } = useWorkingIndicator()

  const {domainActive} = useDomain()
  const [domainSelect, setDomainSelect] = useState(domainActive)

  // hace una busqueda y retorna si hay items parecidos
  useEffect(() => {
    const searchPhrasesEquals = () => {
      getFlashcardYaAgregado(summary).then((res) => {
        if (res?.items?.length > 0) {
          showMessage(
            intl.formatMessage({
              id: 'flashcard.duplicate-summary',
              defaultMessage: 'There is already the same flashcard in your deck',
            }),
            TOAST_TYPE.WARNING,
            false,
          )
        }
      })
    }

    searchPhrasesEquals()
    // eslint-disable-next-line
  }, [debounceSummary])

  const GetTraductor = () => {
    let fromLanguage,
      toLanguage,
      body = title

    if (!domainSelect) {
      showMessage(
        intl.formatMessage({
          id: 'flashcard.domain-select-empty',
          defaultMessage: 'There is no domain selected',
        }),
        TOAST_TYPE.WARNING,
      )
      return
    }

    if (idioma_nativo.unicode === domainSelect.code) {
      showMessage(
        intl.formatMessage({
          id: 'flashcard.languages-diferent',
          defaultMessage: 'Languages must be different',
        }),
        TOAST_TYPE.WARNING,
      )
      return
    }

    //hacer condiciones
    if (title?.trim()?.length > 0) {
      fromLanguage = idioma_nativo.unicode
      toLanguage = domainSelect.code
      body = title
    } else if (summary?.trim()?.length > 0) {
      fromLanguage = domainSelect.code
      toLanguage = idioma_nativo.unicode
      body = summary
    } else {
      showMessage(
        intl.formatMessage({
          id: 'flashcard.write-phrase',
          defaultMessage: 'Write a phrase on your flashcard',
        }),
        TOAST_TYPE.WARNING,
      )
      return
    }

    // quita los caracteres que dan problemas
    body = QuitarCaracteresParaTraducir(body)
    startWork()
    traductorBody(body, fromLanguage, toLanguage).then((response) => {
      finishWork()
      if (response.translation) {
        const {translation} = response

        if (title === '') {
          setTitle(translation)
        } else {
          setSummary(translation)
        }
      } else {
        showMessage(
          `${intl.formatMessage({
            id: 'flashcard.error-translate',
            defaultMessage: 'Translation error',
          })}, ${response.message}`,
          TOAST_TYPE.ERROR,
        )
      }
    })
  }

  const saveFlashcard = () => {
    if (!domainSelect) {
      showMessage(
        intl.formatMessage({
          id: 'flashcard.domain-select-empty',
          defaultMessage: 'There is no domain selected',
        }),
        TOAST_TYPE.WARNING,
      )
      return
    }

    if (title === '' || summary === '') {
      showMessage(
        intl.formatMessage({
          id: 'flashcard.write-phrase',
          defaultMessage: 'Write a phrase on your flashcard',
        }),
        TOAST_TYPE.WARNING,
      )
      return
    }

    const params = {
      title,
      summary,
      activity_type: 'memorize',
      tags: [],
      format: 'custom_format',
      domain_id: domainSelect.id,
    }

    startWorkFlashcard()
    createFlashcardItem(params).then((response) => {
      finishWorkFlashcard()
      if (response?.item) {
        setTitle('')
        setSummary('')
        returnCurrentView()
        dispatchGetAllProgress()
      } else if (response?.errors) {
        const {errors} = response
        if (errors?.titleExist) {
          showMessage(
            `${intl.formatMessage({
              id: 'flashcard.error-exits-db',
              defaultMessage:
                'This flashcard has already been added, add a different one',
            })}`,
            TOAST_TYPE.WARNING,
          )
        }
      } else {
        showMessage(
          `${intl.formatMessage({
            id: 'flashcard.error-create-db',
            defaultMessage: 'Error creating your flashcard, try later',
          })}, ${response.message}`,
          TOAST_TYPE.ERROR,
        )
      }
    })
  }

  const returnCurrentView = () => {
    changeView(PlusButtonScrean.MESSAGE_SUCCESS_FLASHCARD)
    setTimeout(() => {
      changeView(PlusButtonScrean.FLASHCARD)
    }, 5000)
  }

  //event keys
  const eventKeys = (event) => {
    if (event.which === 13) {
      GetTraductor()
    }
  }

  return (
    <div className="custom__flashcard__container">
      <div>
        <h3 className="custom__flashcard__title">
          <FormattedMessage
            id={'plusbutton.deck'}
            defaultMessage={'Add a flashcard to your deck.'}
          />
        </h3>

        <div className="add__flashcard__mazo__main">
          {/* flashcard negra */}
          <div id={`custom-flashcard-front`} className="create-target">
            <TextareaAutosize
              tabIndex="1"
              className="orange-gradient-color"
              placeholder={`[${idioma_nativo.name || ''}]`}
              onKeyPress={eventKeys}
              onChange={({target}) => setTitle(target.value)}
              value={title}
              autoFocus
            />
          </div>

          <div className="contenedor-btn-traductor translate-target">
            <div className="contenedor-btn-traductor-group">
              <BotonTraductorLoading
                onClick={() => GetTraductor()}
                is_loading={isWorking}
                type="flashcard"
              />
              <h6 className="title-traductor-flashcard">
                <FormattedMessage
                  id={'plusbutton.translate'}
                  defaultMessage={'Translate'}
                />
              </h6>
            </div>
          </div>

          {/* flashcard blanca */}
          <div id={`custom-flashcard-back`}>
            {isToggledLanguages ? (
              <SelectDomains
                domainSelect={domainSelect}
                setDomainSelect={setDomainSelect}
                onClose={toggleDomains}
                theme={Colors.LIGTH}
              />
            ) : (
              <>
                <div className="button-open-selector-languages">
                  <BottonSelectorDomains
                    domainSelect={domainSelect}
                    onClick={toggleDomains}
                    showLanguageTitle={false}
                  />
                </div>
                <TextareaAutosize
                  tabIndex="2"
                  className="orange-gradient-color"
                  placeholder={`[${domainSelect?.id ? domainSelect.name : ''}]`}
                  onKeyPress={eventKeys}
                  onChange={({target}) => setSummary(target.value)}
                  value={summary}
                />
              </>
            )}
          </div>
        </div>

        <div className="contenedor-btn-send-flashcard add-target">
          <BotonConBordeDegradado
            function={() => saveFlashcard()}
            width={126}
            height={37}
            disabled={isWorkingFlashard}>
            {isWorkingFlashard ? (
              'loading...'
            ) : (
              <FormattedMessage id={'plusbutton.add'} defaultMessage={'Add'} />
            )}
          </BotonConBordeDegradado>
        </div>
      </div>

      <div className="search__image__custom__main">
        <div className="personalizar-flashcard">
          <h6 className="text-white">
            <FormattedMessage
              id={'plusbutton.put'}
              defaultMessage={'Put an image on your flashcard'}
            />
          </h6>
        </div>
        <div className="text-left">
          <FormattedMessage id="flashcard.image" defaultMessage="Search image">
            {(placeholder) => (
              <input
                type="search-custom-flashcard"
                placeholder={placeholder}
                value={query}
                onChange={({target}) => setQuery(target.value)}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="search__images__list">
          <ListImagesUnsplash
            query={query}
            onClickImage={setImageCustom}
            imageSelect={imageCustom}
            style={{width: '66.39px', height: '66.39px', margin: '5px'}}
          />
        </div>
      </div>
    </div>
  )
}

export default CustomFlashcard
