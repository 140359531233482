import React from 'react'
import ResolvedIcon from '../Icons/resolved.svg'
import {resolverAndReopenConversation} from '../../../../lib/coco/conversationApi'

const ResolveThread = ({currentUser, conversation}) => {
  const isResolvedConversation = conversation.resolved

  const handleClick = () => {
    resolverAndReopenConversation(
      conversation.id,
      isResolvedConversation,
      currentUser.id,
    )
  }

  return (
    <>
      {conversation.resolved ? (
        <button
          className="btn btn-danger btn-sm mx-2"
          style={{height: '35px'}}
          onClick={handleClick}>
          Re-Open
        </button>
      ) : (
        <img
          className="mx-2 click-me"
          onClick={handleClick}
          style={{width: '35px', height: '35px', marginLeft: '10px'}}
          src={ResolvedIcon}
          alt={ResolvedIcon}
        />
      )}
    </>
  )
}

export default ResolveThread
