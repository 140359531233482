import axios from 'axios'
import {API_ROOT} from '../../Services/EndPointApi'
import {getToken} from '../../Services/getToken'

export const fetchConversations = () => {
  return axios({
    url: `${API_ROOT}/conversations`,
    method: 'get',
    headers: {Authorization: getToken()},
  })
}
