import React from 'react'
import {connect} from 'react-redux'

import {playbackSeek} from '../../Redux/spotify/actions/player'
import {cambia_progreso_del_reporductor} from '../../Redux/spotify/actions/player'
import 'react-input-range/lib/css/index.css'
import Range from './Range'
class ProgressBar extends React.Component {
  componentDidMount() {
    this.songInterval = setInterval(() => this.tick(), 100)
  }

  componentWillUnmount() {
    clearInterval(this.songInterval)
  }

  handleOnChange = (value) => {
    const {duration_ms, dispatch, loading} = this.props
    if (loading) {
      return
    } else {
      const progressValue = duration_ms * (value / 100)
      dispatch(playbackSeek(Math.round(progressValue)))
      dispatch(cambia_progreso_del_reporductor(progressValue))
    }
  }

  tick = () => {
    const {
      is_playing,
      seeking,
      loading,
      progress_ms,
      duration_ms,
      dispatch,
    } = this.props

    if (is_playing && !(seeking || loading)) {
      if (progress_ms - duration_ms >= 0) {
        this.songEnded()
      } else {
        dispatch(cambia_progreso_del_reporductor(progress_ms + 100))
      }
    }
  }

  songEnded = () => {
    const {dispatch} = this.props

    clearInterval(this.songInterval)
    dispatch(cambia_progreso_del_reporductor(0))
    this.songInterval = setInterval(() => this.tick(), 100)
  }

  render() {
    const {duration_ms, progress_ms} = this.props
    const progress_mins = parseInt(progress_ms / 1000 / 60)
    const progress_secs = parseInt((progress_ms / 1000) % 60)
    const duration_mins = parseInt((duration_ms - progress_ms) / 1000 / 60)
    const duration_secs = parseInt(((duration_ms - progress_ms) / 1000) % 60)

    return (
      <div className="spotify__progress__main">
        <div className="spotify__progress__timers">
          {progress_mins}.{progress_secs < 10 ? `0${progress_secs}` : progress_secs}
        </div>
        <div className="spotify__progress__container">
          <Range
            value={(progress_ms / duration_ms) * 100}
            handleOnChange={this.handleOnChange}
            maxValue={100}
          />
        </div>
        <div className="spotify__progress__timers">
          {duration_mins}.{duration_secs < 10 ? `0${duration_secs}` : duration_secs}
        </div>
      </div>
    )
  }
}

ProgressBar.defaultProps = {
  progress_ms: 0,
  duration_ms: 0,
  is_playing: false,
}

const mapStateToProps = (state) => ({
  is_playing: !state.spotify.player.paused,
  progress_ms: state.spotify.player.position,
  duration_ms: state.spotify.player.duration,
  seeking: state.spotify.player.seeking,
  loading: state.spotify.player.loading,
  player: state.spotify.player,
})

export default connect(mapStateToProps)(ProgressBar)
