import React from 'react'
import useOnClickOutside from '../../hooks/useClickOutside'
import useUser from '../../hooks/useUser'

const ContextWrapper = ({children, myRef, close}) => {
  const {snippetModalStatus, flashcardModalStatus} = useUser()

  useOnClickOutside(myRef, () => handlerContext())

  const handlerContext = () => {
    if (flashcardModalStatus || snippetModalStatus) {
      return
    } else {
      close()
    }
  }

  return (
    <div style={{position: 'absolute', top: 0, right: 0}} ref={myRef}>
      {children}
    </div>
  )
}

export default ContextWrapper
