import {
  FETCH_SEARCH_REQUEST_START,
  FETCH_SEARCH_REQUEST_FINISH,
  FETCH_SEARCH_REQUEST_ERROR,
  UPDATE_TRACK_SEARCH_FROM_LIST,
  SET_PARAMS_SEARCH_INPUT,
} from './searchTypes'
import {replaceTrackFromLike} from '../helpers'

const searchInputsInitialState = {
  params: '',
  data: {
    artists: [],
    tracks: [],
  },
  loading: false,
  error: {
    message: '',
  },
}

const searchInputsReducer = (state = searchInputsInitialState, action) => {
  switch (action.type) {
    case SET_PARAMS_SEARCH_INPUT:
      return {
        ...state,
        params: action.params,
      }

    case FETCH_SEARCH_REQUEST_START:
      return {
        ...state,
        loading: true,
        error: {message: ''},
      }

    case FETCH_SEARCH_REQUEST_FINISH:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }

    case FETCH_SEARCH_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case UPDATE_TRACK_SEARCH_FROM_LIST:
      const {artists, tracks} = state.data

      return {
        ...state,
        data: {
          tracks: replaceTrackFromLike(action.payload.track, tracks),
          artists,
        },
      }
    default:
      return state
  }
}

export default searchInputsReducer
