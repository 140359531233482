export default {
  en: {
    // ** card-pay-preview **
    // title header
    'card-pay.title-1': 'Your free trial is about to end.',
    'card-pay.title-2':
      'If you subscribe now you will not lose the time you have left on the trial.',
    'card-pay.title-3': 'Your free trial has very little time left.',
    'card-pay.title-4': 'Your free trial is over.',
    'card-pay.title-5': 'Free Trial',
    // para el timer
    'card-pay.title-timer-1': 'Your test will end in:',
    'card-pay.title-timer-2': 'Your free trial has:',
    'card-pay.title-timer-3': 'Time remaining:',
    // body
    'card-pay.title-body-1':
      'Subscribe now and continue to achieve <br/> all your goals.',
    'card-pay.title-body-2':
      'Continue enjoying the benefits <br/> of Coco and <b> keep meeting your goals.<b/>',
    'card-pay.title-body-3':
      "Don't be left without the help of the <br/> teachers! subscribe now and continue <br/> enjoying the benefits.",
    // button
    'card-pay.button-1': 'Go to the payment module',
    'card-pay.button-2': 'I want to subscribe',
    'card-pay.button-3': 'Subscribe now',
    // omitir
    'card-pay.omitir': 'Maybe later',
    // ** form-information **
    'card-information-user.active': 'Active',
    'card-information-user.inactive': 'Inactive',
    'card-information-user.datail-title': 'Purchase details',
    'card-information-user.datail-1': 'Personal attention from teachers.',
    'card-information-user.datail-2':
      'A system for spaced repetition to streamline vocabulary with spaced repetition.',
    'card-information-user.datail-3': 'Lyrics of thousands of songs.',
    'card-information-user.cod-coupon': 'Do you have a discount code?',
    'card-information-user.cod-placeholder': 'Enter the code',
    'card-information-user.apply': 'Aplicar',
    'card-information-user.cod-error':
      'The wrong code is inactive or does not exist.',
    'card-information-user.start-suscription': 'Your subscription would start on',
    // ** form-strapi **
    'card-form-strapi.error-name': 'The name is required.',
    'card-form-strapi.error-strapi': 'Data not loaded',
    'card-form-strapi.title': 'Payment details',
    'card-form-strapi.name': 'Name',
    'card-form-strapi.placeholder-name': 'Full name',
    'card-form-strapi.card-number': 'Card number',
    'card-form-strapi.expedition': 'Expedition number',
    'card-form-strapi.sub-total': 'Subtotal',
    'card-form-strapi.descuento': 'Discount',
    'card-form-strapi.total': 'Total',
    'card-form-strapi.pay': 'Make payment',
    // ** alert trial **
    'alert-trial.text-1': 'Your free trial is',
    'alert-trial.text-2': 'finished',
    'alert-trial.text-3': 'will end in ',
    'alert-trial.day': 'day',
    // ** card trial onboarding **
    'card-trial.features-1': 'Feedback from bilingual teachers.',
    'card-trial.features-2':
      'Personalized attention from teachers to <br/> solve all your doubts.',
    'card-trial.features-3': 'Thousands of song lyrics.',
    'card-trial.features-4': 'Spaced repeating letters.',
    'card-trial.features-5': 'Organization system.',
    'card-trial.price-description':
      '<b> Take advantage of 20 free days </b> <br /> and enjoy the following Benefits:',
    'card-trial.start': 'Start free trial',
    'card-trial.footer':
      '$ 15 usd per month at the end of your trial. <br /><b> You do not have to add your card at this time. </b>',
    // ** suscription success **
    'suscription-success.thanks': 'Thank you!',
    'suscription-success.text-1':
      'We are excited to accompany you <br /> on your journey to fluidity.',
    'suscription-success.text-2':
      'Keep enjoying the benefits of the App <br /> and achieve all your goals.',
    'suscription-success.text-3': 'Your subscription will begin <br /> on',
    'suscription-success.text-4': 'Successful payment',
    // ** suscription error **
    'suscription-error.text-1': 'The payment was declined by <br /> your bank.',
    'suscription-error.text-2':
      'Contact your bank to clarify the <br /> problem or try another payment method.',
    'suscription-error.text-3': 'Try again',
  },
  es: {
    // ** card-pay-preview **
    // title header
    'card-pay.title-1': 'Tu prueba gratis está a punto de terminar.',
    'card-pay.title-2':
      'Si te suscribes ahora no perderás el tiempo que te queda de prueba.',
    'card-pay.title-3': 'A tu prueba gratis le queda muy poco tiempo.',
    'card-pay.title-4': 'Tu prueba gratis ya finalizó.',
    'card-pay.title-5': 'Prueba gratis.',
    // para el timer
    'card-pay.title-timer-1': 'Tu prueba terminará en :',
    'card-pay.title-timer-2': 'A tu prueba gratis le quedan:',
    'card-pay.title-timer-3': 'Quedan:',
    // body
    'card-pay.title-body-1':
      'Suscríbete ahora y sigue cumpliendo <br/>todos tus objetivos.',
    'card-pay.title-body-2':
      'Continúa disfrutando los beneficios <br/>de Coco y <b>sigue cumpliendo tus metas.<b/>',
    'card-pay.title-body-3':
      'No te quedes sin la ayuda de los <br/>profesores. Suscríbete ahora y sigue <br/>disfrutando de los beneficios.',
    // button
    'card-pay.button-1': 'Ir al modulo de pago',
    'card-pay.button-2': 'Quiero suscribirme',
    'card-pay.button-3': 'Suscríbete ahora',
    // omitir
    'card-pay.omitir': 'Quizá después',
    // ** form-information **
    'card-information-user.active': 'Activo',
    'card-information-user.inactive': 'Inactivo',
    'card-information-user.datail-title': 'Detalles compra',
    'card-information-user.datail-1': 'Atención personalizada de profesores.',
    'card-information-user.datail-2': 'Sistema de cartas de repetición espacada.',
    'card-information-user.datail-3': 'Letra de miles de canciones.',
    'card-information-user.cod-coupon': '¿Tienes un código de descuento?',
    'card-information-user.cod-placeholder': 'Ingresa el código',
    'card-information-user.apply': 'Aplicar',
    'card-information-user.cod-error':
      'El código incorrecto, está inactivo o no existe.',
    'card-information-user.start-suscription': 'Tu suscripción comenzaría el',
    // ** form-strapi **
    'card-form-strapi.error-name': 'El nombre es requerido.',
    'card-form-strapi.error-strapi': 'Datos no cargados',
    'card-form-strapi.title': 'Detalles de pago',
    'card-form-strapi.name': 'Nombre',
    'card-form-strapi.placeholder-name': 'Nombre Completo',
    'card-form-strapi.card-number': 'Número de tarjeta',
    'card-form-strapi.expedition': 'Numero de expedición',
    'card-form-strapi.sub-total': 'Subtotal',
    'card-form-strapi.descuento': 'Descuento',
    'card-form-strapi.total': 'Total',
    'card-form-strapi.pay': 'Realizar pago',
    // ** alert trial **
    'alert-trial.text-1': 'Tu prueba gratis se',
    'alert-trial.text-2': 'termino',
    'alert-trial.text-3': 'terminará en ',
    'alert-trial.day': 'día',
    // ** card trial onboarding **
    'card-trial.features-1': 'Retroalimentación de profesores bilingües.',
    'card-trial.features-2':
      'Atención personalizada de profesores para<br/> resolver todas tus dudas.',
    'card-trial.features-3': 'Miles de letras de canciones.',
    'card-trial.features-4': 'Cartas de repetición espaciada.',
    'card-trial.features-5': 'Sistema de organización.',
    'card-trial.price-description':
      '<b>Aprovecha 20 días gratis</b> <br />y disfruta los siguientes beneficios:',
    'card-trial.start': 'Empezar prueba gratis',
    'card-trial.footer':
      '$15 usd al mes al finalizar tu prueba. <br /><b>No tienes que agregar tu tarjeta en este momento.</b>',
    // ** suscription success **
    'suscription-success.thanks': '¡Gracias!',
    'suscription-success.text-1':
      'Estamos emocionados por acompañarte <br /> en tu viaje a la fluidéz.',
    'suscription-success.text-2':
      'Sigue disfrutando los beneficios de la App <br /> y logra todos tus objetivos.',
    'suscription-success.text-3': 'Tu suscripción comenzará <br /> el',
    'suscription-success.text-4': 'Pago exitoso',
    // ** suscription error **
    'suscription-error.text-1': 'El pago fue declinado por <br /> su banco.',
    'suscription-error.text-2':
      'Contacte a sus banco para aclarar el <br /> problema o intente con otro método de pago.',
    'suscription-error.text-3': 'Intentar de nuevo',
  },
  pt: {},
  fr: {},
}
