const NotificationTypes = {
  FLASHCARD: 'Flashcard',
  MEDIATEXT: 'MediaText',
  COMMENT: 'Comment',
  USER_OUTPUT: 'UserOutput',
  USER_OUTPUT_LIKE: 'UserOutputLike',
  USER_OUTPUT_CONFIRMATION: 'UserOutputConfirmation',
}

const isNotificationUserOutputConfirmation = (type) => {
  return type === NotificationTypes.USER_OUTPUT_CONFIRMATION
}

const isNotificationUserOutputLike = (type) => {
  return type === NotificationTypes.USER_OUTPUT_LIKE
}

const isNotificationUserOutputComment = (type) => {
  return type === NotificationTypes.COMMENT
}

export {
  NotificationTypes,
  isNotificationUserOutputLike,
  isNotificationUserOutputComment,
  isNotificationUserOutputConfirmation,
}
