import React, {useEffect} from 'react'
import './PlusButtonAction.css'
import Plus from '../IconsPlusButton/plus.svg'
import plusBottonBig from '../IconsPlusButton/plusBottonBig.svg'

const FullModalFlashcard = ({openPlusButton, isToggledLeftbar}) => {
  useEffect(() => {
    const handleModal = () => {
      openPlusButton && openPlusButton()
    }

    document.body.addEventListener('keydown', (e) => {
      if (getOS() === 'Windows') {
        if (e.altKey && e.code === 'KeyJ') {
          e.preventDefault()
          handleModal()
        }
      } else {
        //e.altKey
        if (e.altKey && e.code === 'KeyJ') {
          e.preventDefault()
          handleModal()
        }
      }
    })

    document.body.addEventListener('keydown', (e) => {
      if (getOS() === 'Windows') {
        if (e.altKey && e.code === 'KeyH') {
          e.preventDefault()
          handleModal()
        }
      } else {
        //e.altKey
        if (e.altKey && e.code === 'KeyH') {
          e.preventDefault()
          handleModal()
        }
      }
    })
    // eslint-disable-next-line
  }, [])

  const getOS = () => {
    let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows'
    } else if (/Android/.test(userAgent)) {
      os = 'Android'
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux'
    }
    return os
  }

  return (
    <>
      {isToggledLeftbar ? (
        <button className="btn__plus__button" onClick={openPlusButton}>
          <img className="plus-button-target" src={Plus} alt={Plus} />
        </button>
      ) : (
        <button className="btn__plus__button__absolute" onClick={openPlusButton}>
          <img
            className="plus-button-target"
            src={plusBottonBig}
            alt={plusBottonBig}
          />
        </button>
      )}
    </>
  )
}
export default FullModalFlashcard
