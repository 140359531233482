import React, {useState} from 'react'
import './NewConversationForm.css'
import SelectDomains from '../../Shared/SelectDomains'
import {Colors} from '../../Shared/helpers'
import useDomain from '../../../hooks/useDomain'
import BottonSelectorDomains from '../../Shared/BottonSelectorDomains'
import useBooleanToggler, {
  useWorkingIndicator,
} from '../../../hooks/useBooleanToggler'
import useAlert, {TOAST_TYPE} from '../../../hooks/useAlert'
import {createConversation} from '../../../lib/coco/conversationApi'
import BotonConBordeDegradado from '../../Buttons/BotonConBordeDegradado'
import TextareaAutosize from 'react-autosize-textarea'
import {receivedData} from '../../../Redux/Conversations/actions'
import {useDispatch} from 'react-redux'

const NewConversationForm = ({createConversationSuccess}) => {
  const {showMessage} = useAlert()
  const [title, setTitle] = useState('')
  const [firstMessage, setFirstMessage] = useState('')
  const {domainActive} = useDomain()
  const [domainSelect, setDomainSelect] = useState(domainActive)
  const {isToggled: isToggledDomains, toggle: toggleDomains} = useBooleanToggler()
  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const dispatch = useDispatch()

  const createNewConversation = () => {
    if (isWorking) {
      return
    }
    if (!domainSelect) {
      showMessage('No hay un dominio seleccionado', TOAST_TYPE.WARNING)
      return
    }

    if (!title.length > 0) {
      showMessage('Debes ingresar un título', TOAST_TYPE.WARNING)
      return
    }

    startWork()
    createConversation(title, firstMessage, domainSelect.id).then((response) => {
      finishWork()
      if (response.conversation) {
        const {conversation} = response
        dispatch(receivedData(conversation)) // actualizo la conversacion en redux, antes que el socket responda
        createConversationSuccess(conversation.id)
        showMessage('Hilo creado con exito', TOAST_TYPE.SUCCESS, false)
      } else {
        showMessage(`${response.message}, error al crear el hilo`, TOAST_TYPE.ERROR)
      }
    })
  }

  return (
    <div className="w-100 new-conversation-container">
      <div id="new-thread" className="w-100 pt-5 pb-4 px-2 d-flex flex-column">
        <TextareaAutosize
          id="thread-title"
          type="text"
          onChange={(event) => setTitle(event.target.value)}
          className="mb-2 p-3"
          placeholder="Título del hilo"
          autoFocus
        />

        <div id="first-message">
          <textarea
            id="firt-message-area"
            placeholder="Primer mensaje del hilo"
            className="p-3"
            onChange={(event) => setFirstMessage(event.target.value)}></textarea>

          <div id="selector-languages-new-conversation">
            {isToggledDomains ? (
              <SelectDomains
                theme={Colors.DARK}
                domainSelect={domainSelect}
                setDomainSelect={setDomainSelect}
                onClose={toggleDomains}
              />
            ) : (
              <BottonSelectorDomains
                domainSelect={domainSelect}
                onClick={toggleDomains}
                color={Colors.DARK}
              />
            )}
          </div>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <BotonConBordeDegradado
            width={194}
            height={37}
            fontSize={18}
            fontWeight={500}
            function={createNewConversation}
            disabled={isWorking}>
            <>{isWorking ? 'Loading...' : 'Abrir hilo'}</>
          </BotonConBordeDegradado>
        </div>
      </div>
    </div>
  )
}

export default NewConversationForm
