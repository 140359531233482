export const includeParamsOnTitle = (title = '', params = '') => {
  const titleUppercase = title.toUpperCase()
  const paramsUppercase = params.toUpperCase()
  return titleUppercase?.includes(paramsUppercase)
}

export const findOneConversation = (conversations, conversationId) => {
  return conversations.find((conver) => conver?.id === conversationId)
}

export const findActiveConversation = (conversations, activeConversation) => {
  return conversations.find(
    (conversation) => conversation?.id === activeConversation,
  )
}

export const filterConversations = (conversations = [], param) => {
  if (conversations?.length > 0) {
    return conversations.filter((conver) =>
      includeParamsOnTitle(conver?.title || '', param),
    )
  } else {
    return []
  }
}

export const filterByParticipants = (conversations = [], param) => {
  let conversationsFind = []
  if (conversations?.length > 0) {
    conversations.filter((conver) => {
      // busqueda por title, cuando es grupo
      if (includeParamsOnTitle(conver?.title || '', param)) {
        conversationsFind = [...conversationsFind, conver]
      } else {
        // busqueda por cada participante cuando es personal
        const {participating_users = []} = conver
        const users = participating_users.filter(
          (p) =>
            includeParamsOnTitle(p?.name || '', param) ||
            includeParamsOnTitle(p?.email || '', param),
        )
        if (users?.length > 0) {
          conversationsFind = [...conversationsFind, conver]
        }
      }
      return conver
    })

    return conversationsFind
  } else {
    return []
  }
}
