import React, {useState} from 'react'
import PropTypes from 'prop-types'

import IconWarning from './Icons/IconWarning.svg'
import BlueOutlineButton from '../Buttons/BlueOutlineButton'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'between',
    alignItems: 'center',
    width: '100%',
    height: '100% !important',
    minHeight: '257px',
    backgroundColor: '#213040',
    padding: '10px',
    borderRadius: '7px',
  },
  title: {
    marginTop: '15px',
    fontSize: '23px',
    lineHeight: '1.2',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  message: {
    fontWeight: 300,
    fontSize: '22px',
    lineHeight: '1.2',
    textAlign: 'center',
    color: '#FFFFFF',
    marginTop: '20px',
    marginBottom: '40px',
  },
  icon: {
    width: '46px',
    height: '46px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5px 0 5px 0',
  },
}

const WarningAlert = (props) => {
  const {
    title,
    message,
    salir,
    seguirRevisando,
    titleCancel,
    titleAcept,
    styleContainer,
    styleTitle,
    styleMessage,
    styleButtonsContainer,
  } = props

  const [active, setActive] = useState(true)

  return (
    <div style={{...styles.container, ...styleContainer}}>
      <img style={styles.icon} src={IconWarning} alt={IconWarning} />

      {title && <div style={{...styles.title, ...styleTitle}}>{title}</div>}

      <div style={{...styles.message, ...styleMessage}}>{message}</div>

      <div style={{...styles.buttonsContainer, ...styleButtonsContainer}}>
        <div className="mr-2">
          <BlueOutlineButton
            text={titleAcept}
            onClick={salir}
            onMouseOver={() => setActive(false)}
            onMouseOut={() => setActive(true)}
          />
        </div>
        <BlueOutlineButton
          text={titleCancel}
          onClick={seguirRevisando}
          active={active}
        />
      </div>
    </div>
  )
}

WarningAlert.defaultProps = {
  title: null,
  message: '',
  titleCancel: 'Salir',
  titleAcept: 'Seguir revisando',
  styleContainer: {},
  styleTitle: {},
  styleMessage: {},
  styleButtonsContainer: {},
}

WarningAlert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  salir: PropTypes.func.isRequired,
  seguirRevisando: PropTypes.func.isRequired,
  titleCancel: PropTypes.string.isRequired,
  titleAcept: PropTypes.string.isRequired,
  styleContainer: PropTypes.object,
  styleTitle: PropTypes.object,
  styleMessage: PropTypes.object,
  styleButtonsContainer: PropTypes.object,
}

export default WarningAlert
