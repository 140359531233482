import {
  FETCH_NOTIFICATIONS_REQUEST_ERROR,
  FETCH_NOTIFICATIONS_REQUEST_START,
  FETCH_NOTIFICATIONS_REQUEST_FINISH,
  CHECK_NOTIFICATION_READED,
} from './notificationsTypes'

const notificationsInitialState = {
  notifications: [],
  loading: false,
  error: {
    message: '',
  },
}

const notificationsReducer = (state = notificationsInitialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST_START:
      return {
        ...state,
        loading: true,
        error: {message: ''},
      }

    case FETCH_NOTIFICATIONS_REQUEST_FINISH:
      return {
        ...state,
        loading: false,
        notifications: action.notifications,
      }

    case FETCH_NOTIFICATIONS_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    case CHECK_NOTIFICATION_READED:
      let {notifications = []} = state
      const {id} = action

      notifications = notifications.map((notify) => {
        if (notify.id === id) {
          notify.read = true
        }

        return notify
      })

      return {
        ...state,
        notifications,
      }

    default:
      return state
  }
}

export default notificationsReducer
