import React from 'react'
import PropTypes from 'prop-types'
import './UserTooltip.css'

const UserTooltip = (props) => {
  const {label, name} = props

  return (
    <div className="container-user-tooltip">
      <did className="label-user-tooltip mb-1">{label}</did>
      <did className="user-name-tooltip">{name}</did>

      <div className="tooltip-direction"></div>
    </div>
  )
}

UserTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default UserTooltip
