import React, {useState} from 'react'
import BocinaIcon from './Icons/bocina.svg'
import SpinnerBox from '../Animaciones/SpinnerBox'
import {TextToSpeech} from '../../lib/coco/InputApi'
import useBooleanToggler, {useWorkingIndicator} from '../../hooks/useBooleanToggler'

const PronounceSentence = ({oracion, langCode, width = '16', uri = null}) => {
  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const [sonido, setSonido] = useState(uri)
  const {isToggled, toggle} = useBooleanToggler({initialValue: uri ? true : false})

  const playAudio = (audio) => {
    if (audio && audio?.paused) {
      if (sonido) {
        if (isToggled) sonido.playbackRate = 0.5
        else sonido.playbackRate = 1
        sonido.play()
      } else {
        audio.play()
      }

      toggle()
    }
  }

  const getTraduccion = () => {
    if (sonido) {
      playAudio(sonido)
    } else if (!isWorking) {
      startWork()
      TextToSpeech(oracion, langCode).then((response) => {
        finishWork()
        if (response.audio) {
          const {audio} = response
          const naturalVoice = new Audio('data:audio/wav;base64,' + audio)
          setSonido(naturalVoice)
          playAudio(naturalVoice)
        }
      })
    }
  }

  const style = {
    width: `${width}px`,
    minWidth: `${width}px`,
  }

  return (
    <>
      {isWorking ? (
        <SpinnerBox />
      ) : (
        <img
          style={style}
          src={BocinaIcon}
          alt="Natural voice"
          className="ml-2 click-me"
          title="Escuchar"
          onClick={getTraduccion}
        />
      )}
    </>
  )
}

export default PronounceSentence
