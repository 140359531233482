export default {
  en: {
    'engrane.seting-active': 'Active',
    'engrane.seting-inactive': 'Inactive',
    'engrane.seting-personal-information': 'Personal information',
    'engrane.seting-notificaciones': 'Notifications',
    'engrane.seting-payments': 'Payments and subscriptions',
    'engrane.edit-password-incorrect': 'The passwords are wrong!',
    'engrane.edit-password-success': 'Password edited!',
    'engrane.edit-password-edit': 'Edit password',
    'engrane.edit-password-after': 'Old Password',
    'engrane.edit-password-new': 'New Password',
    'engrane.edit-password-saving': 'Saving...',
    'engrane.edit-password-save-changes': 'Save Changes',
    'engrane.edit-password-cancel': 'Cancel',
    'engrane.edit-perfil-success': 'Profile saved successfully!',
    'engrane.edit-perfil-name': 'NAME',
    'engrane.edit-perfil-nacionality': 'NATIONALITY',
    'engrane.edit-perfil-birthday': 'BIRTHDAY',
    'engrane.edit-perfil-phone': 'TELEPHONE',
    'engrane.edit-perfil-email': 'EMAIL',
    'engrane.edit-perfil-password': 'PASSWORD',
    'engrane.notifications-on': 'ON',
    'engrane.notifications-off': 'OFF',
    'engrane.notifications-save-success': 'Data saved successfully!',
    'engrane.notifications-in-coco': 'INSIDE COCO.',
    'engrane.notifications-in-coco-description':
      'All notifications and alerts that <br /> are within the application.',
    'engrane.notifications-in-email': 'VIA EMAIL.',
    'engrane.notifications-in-email-description':
      'We will keep you informed of <br /> all the news and your progress.',
    'engrane.notifications-in-push': 'PUSH NOTIFICATIONS.',
    'engrane.notifications-in-push-description':
      'Receive notifications to your phone <br /> or computer.',
    'engrane.payments-suscription-monthly': 'Monthly subscription',
    'engrane.payments-days': 'The days',
    'engrane.payments-days-end': 'of each month',
    'engrane.payments-information': 'PAYMENT INFORMATION',
    'engrane.payments-name-in-card': 'Name on the card',
    'engrane.payments-number-card': 'Card number',
    'engrane.payments-cancel-suscription': 'Cancel subscription',
    'engrane.payments-canceling-suscription': 'Canceling subscription...',
    'engrane.payments-cancel-success': 'You have canceled the subscription',
    'engrane.payments-new-suscription': 'Subscribe',
    'engrane.payments-alert-message': 'Unsubscribe from Coco Languages 😢',
    'engrane.payments-alert-close': 'Keep learning',
    'engrane.payments-alert-acept': 'Yes, cancel',
    // Spotify
    'engrane.spotify-settings': 'Spotify',
    'engrane.link-coco-and-spotify-accounts':
      'Your Spotify account is linked to Coco.',
    'engrane.unlink-coco-and-spotify-accounts':
      'You need to link your Spotify account with Coco to enjoy the full experience.',
    'engrane.link-spotify-account': 'Link',
    'engrane.unlink-spotify-account': 'UnLink',
    'engrane.successful-linking-spotify-account':
      'Your Coco account has been successfully unlinked to your Spotify account.',
    'engrane.error-unlinking-spotify-account':
      'Could not unlink your account with spotify, please try again or contact us.',
  },
  es: {
    'engrane.seting-active': 'Activo',
    'engrane.seting-inactive': 'Inactivo',
    'engrane.seting-personal-information': 'Información personal',
    'engrane.seting-notificaciones': 'Notificaciones',
    'engrane.seting-payments': 'Pagos y suscripciones',
    'engrane.edit-password-incorrect': 'Las contraseñas son incorrectas!',
    'engrane.edit-password-success': 'Contraseña editada!',
    'engrane.edit-password-edit': 'Editar contraseña',
    'engrane.edit-password-after': 'Contraseña anterior',
    'engrane.edit-password-new': 'Nueva contraseña',
    'engrane.edit-password-saving': 'Guardando...',
    'engrane.edit-password-save-changes': 'Guardar cambios',
    'engrane.edit-password-cancel': 'Cancelar',
    'engrane.edit-perfil-success': 'Perfil guardado con exito!',
    'engrane.edit-perfil-name': 'NOMBRE',
    'engrane.edit-perfil-nacionality': 'NACIONALIDAD',
    'engrane.edit-perfil-birthday': 'CUMPLEAÑOS',
    'engrane.edit-perfil-phone': 'TELÉFONO',
    'engrane.edit-perfil-email': 'CORREO ELECTRÓNICO',
    'engrane.edit-perfil-password': 'CONTRASEÑA',
    'engrane.notifications-on': 'ON',
    'engrane.notifications-off': 'OFF',
    'engrane.notifications-save-success': 'Datos guardados con exito!',
    'engrane.notifications-in-coco': 'DENTRO DE COCO.',
    'engrane.notifications-in-coco-description':
      'Todas las notificaciones y alertas que <br /> están dentro de la aplicación.',
    'engrane.notifications-in-email': 'POR CORREO ELECTRÓNICO.',
    'engrane.notifications-in-email-description':
      'Te mantendremos informados de <br />todas las novedades y tus progresos.',
    'engrane.notifications-in-push': 'NOTIFICACIONES PUSH.',
    'engrane.notifications-in-push-description':
      'Recibe notificaciones a tu teléfono <br /> o cumputadora.',
    'engrane.payments-suscription-monthly': 'Suscripción mensual',
    'engrane.payments-days': 'Los días',
    'engrane.payments-days-end': 'de cada mes',
    'engrane.payments-information': 'INFORMACIÓN DE PAGO',
    'engrane.payments-name-in-card': 'Nombre en la tarjeta',
    'engrane.payments-number-card': 'Número de tarjeta',
    'engrane.payments-cancel-suscription': 'Cancelar suscripción',
    'engrane.payments-canceling-suscription': 'Cancelando suscripción...',
    'engrane.payments-cancel-success': 'Tu suscripción ha sido cancelada.',
    'engrane.payments-new-suscription': 'Suscribirse',
    'engrane.payments-alert-message': 'Cancelar suscripción de Coco Languages 😢',
    'engrane.payments-alert-close': 'Seguir aprendiendo',
    'engrane.payments-alert-acept': 'Si, cancelar',
    // Spotify
    'engrane.spotify-settings': 'Spotify',
    'engrane.link-coco-and-spotify-accounts':
      'Tu cuenta de Spotify está vinculada a Coco.',
    'engrane.unlink-coco-and-spotify-accounts':
      'Tienes que vincular tu cuenta de Spotify con Coco para disfrutar de la experiencia completa.',
    'engrane.link-spotify-account': 'Enlazar',
    'engrane.unlink-spotify-account': 'Desenlazar',
    'engrane.successful-linking-spotify-account':
      'Tu cuenta de Coco ha sido desvinculada con éxito de tu cuenta de Spotify.',
    'engrane.error-unlinking-spotify-account':
      'No se ha podido desvincular tu cuenta de Spotify, inténtalo de nuevo o ponte en contacto con nosotros.',
  },
  fr: {
    'engrane.seting-active': 'actif',
    'engrane.seting-inactive': 'Inactif',
    'engrane.seting-personal-information': 'Information personnelle',
    'engrane.seting-notificaciones': 'Notifications',
    'engrane.seting-payments': 'Paiements et abonnements',
    'engrane.edit-password-incorrect': 'Les mots de passe sont faux!',
    'engrane.edit-password-success': 'Mot de passe modifié!',
    'engrane.edit-password-edit': 'Modifier le mot de passe',
    'engrane.edit-password-after': 'ancien mot de passe',
    'engrane.edit-password-new': 'nouveau mot de passe',
    'engrane.edit-password-saving': 'Économie...',
    'engrane.edit-password-save-changes': 'Enregistrer les modifications',
    'engrane.edit-password-cancel': 'Annuler',
    'engrane.edit-perfil-success': 'Profil enregistré avec succès!',
    'engrane.edit-perfil-name': 'NOM',
    'engrane.edit-perfil-nacionality': 'NATIONALITÉ',
    'engrane.edit-perfil-birthday': 'DATE D`ANNIVERSAIRE',
    'engrane.edit-perfil-phone': 'TÉLÉPHONE',
    'engrane.edit-perfil-email': 'COURRIER ÉLECTRONIQUE',
    'engrane.edit-perfil-password': 'LE MOT DE PASSE',
    'engrane.notifications-on': 'AU',
    'engrane.notifications-off': 'DÉSACTIVÉ',
    'engrane.notifications-save-success': 'Données enregistrées avec succès!',
    'engrane.notifications-in-coco': 'À L`INTÉRIEUR DE COCO.',
    'engrane.notifications-in-coco-description':
      'Toutes les notifications et alertes qui <br /> se trouvent dans l`application.',
    'engrane.notifications-in-email': 'PAR EMAIL.',
    'engrane.notifications-in-email-description':
      'Nous vous tiendrons au courant de <br /> toutes les actualités et vos progrès.',
    'engrane.notifications-in-push': 'NOTIFICATIONS PUSH.',
    'engrane.notifications-in-push-description':
      'Recevez des notifications sur votre téléphone <br /> ou votre ordinateur.',
    'engrane.payments-suscription-monthly': 'Souscription mensuelle',
    'engrane.payments-days': 'Jour',
    'engrane.payments-days-end': 'de chaque mois',
    'engrane.payments-information': 'INFORMATIONS DE PAIEMENT',
    'engrane.payments-name-in-card': 'Nom sur la Carte',
    'engrane.payments-number-card': 'Numéro de carte',
    'engrane.payments-cancel-suscription': 'Annuler l`abonnement',
    'engrane.payments-canceling-suscription': 'Annulation de l`abonnement...',
    'engrane.payments-cancel-success': 'Vous avez annulé l`abonnement',
    'engrane.payments-new-suscription': 'S`inscrire',
    'engrane.payments-alert-message': 'Se désabonner de Coco Languages 😢',
    'engrane.payments-alert-close': 'Continue',
    'engrane.payments-alert-acept': 'Oui, annuler',
    // Spotify
    'engrane.spotify-settings': 'Spotify',
    'engrane.link-coco-and-spotify-accounts': 'Votre compte Spotify est lié à Coco.',
    'engrane.unlink-coco-and-spotify-accounts':
      "Vous devez lier votre compte Spotify à Coco pour profiter pleinement de l'expérience.",
    'engrane.link-spotify-account': 'Lien',
    'engrane.unlink-spotify-account': 'Détacher',
    'engrane.successful-linking-spotify-account':
      'Votre compte Coco a été dissocié avec succès de votre compte Spotify.',
    'engrane.error-unlinking-spotify-account':
      'Impossible de dissocier votre compte de spotify, veuillez réessayer ou nous contacter.',
  },
}
