import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Styles
const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  width: ${(props) => props.externalRadius}px;
  height: ${(props) => props.externalRadius}px;
  background: ${(props) => props.bg};
  box-shadow: ${(props) => (props.active ? 'none' : props.bsd)};

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    width: ${(props) => props.internalRadius}px;
    height: ${(props) => props.internalRadius}px;
    border: solid 1px transparent;
    background-image: linear-gradient(225deg, #ff5962 16.06%, #fd935e 91.7%),
      linear-gradient(225deg, #ff5962 16.06%, #fd935e 91.7%),
      linear-gradient(225deg, #ff5962 16.06%, #fd935e 91.7%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: ${(props) =>
      props.active ? 'box-shadow: none' : '2px 1000px 1px #0E141B inset'};
    &:hover {
      box-shadow: none;
      cursor: pointer;
    }
  }
`

// Porque los botones de Paco... unos tienen una sombra para allá y otros para acá
const fnBoxShadowDirection = (direction) => {
  switch (direction) {
    case 'left':
      return '1.5px -1.5px 1.5px #31455c, -1.5px 1.5px 1.5px #0b1015'
    case 'right':
      return '-1.5px -1.5px 3px #31455c, 5px 5px 3px #0b1015'
    default:
      return
  }
}

// El componente a exportar, el botón ya estilizado
const BotonRedondoConDegradado = (props) => {
  const {
    externalRadius,
    internalRadius,
    background: bg,
    children,
    boxShadowDirection,
    active,
    ...res
  } = props

  return (
    <Button
      bg={bg}
      externalRadius={externalRadius}
      internalRadius={internalRadius}
      bsd={fnBoxShadowDirection(boxShadowDirection)}
      active={active}>
      <div className="btn" onClick={props.action} {...res}>
        {children}
      </div>
    </Button>
  )
}

// Validación de props
BotonRedondoConDegradado.propTypes = {
  background: PropTypes.string,
  externalRadius: PropTypes.number,
  internalRadius: PropTypes.number,
  boxShadowDirection: PropTypes.string,

  children: PropTypes.element,
  active: PropTypes.bool,
  function: PropTypes.func,
}

// Valores por default para las props
BotonRedondoConDegradado.defaultProps = {
  background: '#0E141B',
  active: false,
  boxShadowDirection: 'right',
}

export default BotonRedondoConDegradado
