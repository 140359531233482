import React, {useState} from 'react'
import PropTypes from 'prop-types'
import './Phrase.css'
import User from './User'
import BotonRedondoConDegradado from '../Buttons/BotonRedondoConDegradado'
import IconGoogleTranslate from './Icons/IconGoogleTranslate.svg'
import PronounceSentence from '../Translator/PronounceSentence'
import iconPlus from '../Icons/iconPlus.svg'
import Tooltip from '../Tooltip/Tooltip'
import YaAgregado from '../Buttons/YaAgregado'

const Phrase = ({
  addToFlashcard,
  phrase,
  phraseTranslation,
  createdBy,
  verifiedBy,
  isPhraseAdd,
  intl,
}) => {
  const [showUsers, setShowUsers] = useState('hidden')

  return (
    <>
      <div
        className="phrase-container"
        onMouseOver={() => {
          setShowUsers('visible')
        }}
        onMouseOut={() => {
          setShowUsers('hidden')
        }}>
        <div className="justify-content-start" style={{marginRight: '12px'}}>
          <div
            className="d-flex position-absolute"
            style={{visibility: showUsers, top: -22}}>
            {/*TODO: Si las creo un maestro solo aparecería uno.*/}
            {createdBy && (
              <User
                user={createdBy}
                label={intl.formatMessage({
                  id: 'diccionary.created-by',
                  defaultMessage: 'Created by:',
                })}
              />
            )}

            {verifiedBy && (
              <User
                user={verifiedBy}
                label={intl.formatMessage({
                  id: 'diccionary.veried-by',
                  defaultMessage: 'Verified by:',
                })}
              />
            )}
          </div>

          <div className="phrase-dictionary d-flex">
            {phrase}

            <PronounceSentence oracion={phrase} />

            {/*<span className="ml-2" onClick={()=> {}}>*/}
            {/*  <img src={IconNaturalVoice} alt={IconNaturalVoice}/>*/}
            {/*</span>*/}
          </div>

          <div className="phrase-translation-dictionary position-relative">
            <span className="mr-1">
              <img src={IconGoogleTranslate} alt={IconGoogleTranslate} />
            </span>

            {phraseTranslation}
          </div>
        </div>
        <div>
          {isPhraseAdd ? (
            <Tooltip
              tooltip={intl.formatMessage({
                id: 'diccionary.ya-add',
                defaultMessage: 'Already added',
              })}
              position="left">
              <YaAgregado externalRadius={31.46} internalRadius={25.01} />
            </Tooltip>
          ) : (
            <BotonRedondoConDegradado
              externalRadius={31.46}
              internalRadius={25.01}
              active={false}
              action={addToFlashcard}
              title={intl.formatMessage({
                id: 'diccionary.add-mazo',
                defaultMessage: 'Add to deck',
              })}>
              <img src={iconPlus} alt={iconPlus} />
            </BotonRedondoConDegradado>
          )}
        </div>
      </div>
    </>
  )
}

Phrase.propTypes = {
  phrase: PropTypes.string.isRequired,
  phraseTranslation: PropTypes.string.isRequired,
  addToFlashcard: PropTypes.func.isRequired,
}

export default Phrase
