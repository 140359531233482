import React from 'react'
import BodyDictionary from './BodyDictionary'
import CocoPhrases from './CocoPhrases'

const DiccionaryCoco = ({
  showPhraseRequest,
  params,
  requestExists,
  setRequestExists,
  phrases,
  wordMark,
  mediaTextId,
  phraseIdsAgregados,
  updateIndicesAddPhrasesCoco,
  intl,
}) => {
  return (
    <>
      {showPhraseRequest && params?.length > 0 && (
        <CocoPhrases
          word={params}
          requestExists={requestExists}
          setRequestExists={setRequestExists}
        />
      )}

      <BodyDictionary
        phrases={phrases || []}
        wordMark={wordMark}
        mediaTextId={mediaTextId}
        phraseIdsAgregados={phraseIdsAgregados}
        updateIndicesAddPhrasesCoco={updateIndicesAddPhrasesCoco}
        intl={intl}
      />
    </>
  )
}

export default DiccionaryCoco
