import {removeObjetsDuplicates} from '../../helpers'
import {
  OPEN_SONG_REQUEST_SUCCESS,
  OPEN_INPUT_TEXT,
  SONG_UPDATE,
  UPDATE_LINES,
  LOADING_REQUEST_MEDIATEXT,
  UPDATE_INDICES_YA_AGREGADOS,
  MediaTextEmpty,
  OPEN_MEDIATEXT_REQUEST_ERROR,
  ADD_TRACKS_TO_PLAYER_COLAS,
} from './actionTypes'

const initialStore = {
  mediaTextSong: MediaTextEmpty,
  isLoading: false,
  inputText: null, // current input text
  errors: null,
  tracksPlayerColas: [], // tracks de player Next y Previous.
}

export default function (state = initialStore, action) {
  switch (action.type) {
    case UPDATE_INDICES_YA_AGREGADOS: {
      const {mediaTextSong} = state
      const newMediaTextSong = {
        ...mediaTextSong,
        indices_ya_agregados: action.indicesAgregados || [],
      }

      return {
        ...state,
        mediaTextSong: newMediaTextSong,
      }
    }

    case UPDATE_LINES: {
      return {
        ...state,
        mediaTextSong: action.mediaTextSong,
        inputText: action.inputText,
      }
    }

    case LOADING_REQUEST_MEDIATEXT: {
      return {
        ...state,
        mediaTextSong: {lines: []},
        isLoading: true,
        errors: null,
      }
    }

    case OPEN_SONG_REQUEST_SUCCESS: {
      return {
        ...state,
        mediaTextSong: action.mediaText,
        isLoading: false,
        errors: null,
      }
    }
    case OPEN_MEDIATEXT_REQUEST_ERROR: {
      return {
        ...state,
        errors: action.errors,
        isLoading: false,
      }
    }

    case OPEN_INPUT_TEXT: {
      return {
        ...state,
        inputText: action.inputText,
      }
    }

    case SONG_UPDATE: {
      return {
        ...state,
        mediaTextSong: action.mediaText,
        isLoading: false,
      }
    }

    // cases de player Next y Previous.
    case ADD_TRACKS_TO_PLAYER_COLAS: {
      const {tracks: newTracks} = action

      const tracksCleaned = removeObjetsDuplicates(newTracks)

      return {
        ...state,
        tracksPlayerColas: tracksCleaned,
      }
    }

    // add one track to cola player

    default:
      return state
  }
}
