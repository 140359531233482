import * as actions from './actionTypes'

export const setPlayerState = (player) => ({
  type: actions.SET_PLAYER_STATE,
  player,
})

export const playerStateChanged = (player) => ({
  type: actions.PLAYER_STATE_CHANGED,
  player,
})

export const playerError = (error, type) => ({
  type: actions.PLAYER_ERROR,
  error,
  error_type: type,
})

export const playerInitError = () => ({
  type: actions.PLAYER_INIT_ERROR,
})

export const playerInitRetry = () => ({
  type: actions.PLAYER_INIT_RETRY,
})

export const playerAuthError = () => ({
  type: actions.PLAYER_AUTH_ERROR,
})

export const playerAuthRetry = () => ({
  type: actions.PLAYER_AUTH_RETRY,
})

export const playerAcctError = () => ({
  type: actions.PLAYER_ACCT_ERROR,
})

export const playbackError = () => ({
  type: actions.PLAYBACK_ERROR,
})

export const trackChanging = (track_id) => ({
  type: actions.TRACK_CHANGING,
  track_id,
})

//export const trackChanged = (item) => ({
//	type: actions.TRACK_CHANGED,
//	item
//})

export const playerLoading = (is_loading) => ({
  type: actions.PLAYER_LOADING,
  is_loading,
})

export const playerReady = () => ({
  type: actions.PLAYER_READY,
})

export const transferPlayback = (device_id) => ({
  type: actions.TRANSFER_PLAYBACK,
  device_id,
})

export const transferPlaybackSuccess = (success) => ({
  type: actions.TRANSFER_PLAYBACK_SUCCESS,
  success,
})

export const transferPlaybackError = (error) => ({
  type: actions.TRANSFER_PLAYBACK_ERROR,
  error,
})

export const fetchPlaybackDevices = () => ({
  type: actions.FETCH_PLAYBACK_DEVICES,
})

export const fetchPlaybackDevicesSuccess = (devices) => ({
  type: actions.FETCH_PLAYBACK_DEVICES_SUCCESS,
  devices,
})

export const fetchPlaybackDevicesError = (error) => ({
  type: actions.FETCH_PLAYBACK_DEVICES_ERROR,
  error,
})

export const playTrack = (track_id, position) => ({
  type: actions.PLAY_TRACK,
  track_id,
  position,
})

export const playTrackSuccess = (track_id) => ({
  type: actions.PLAY_TRACK_SUCCESS,
  track_id,
})

export const playTrackError = (error) => ({
  type: actions.PLAY_TRACK_ERROR,
  error,
})

export const playContext = (context_uri, offset) => ({
  type: actions.PLAY_CONTEXT,
  context_uri,
  offset,
})

export const playContextSuccess = (context_uri, offset) => ({
  type: actions.PLAY_CONTEXT_SUCCESS,
  context_uri,
  offset,
})

export const playContextError = (error) => ({
  type: actions.PLAY_CONTEXT_ERROR,
  error,
})

export const togglePlayback = (is_playing, context_uri, position) => ({
  type: actions.TOGGLE_PLAYBACK,
  is_playing,
  context_uri,
  position,
})

export const togglePlaybackSuccess = (is_playing) => ({
  type: actions.TOGGLE_PLAYBACK_SUCCESS,
  is_playing,
})

export const togglePlaybackError = (error) => ({
  type: actions.TOGGLE_PLAYBACK_ERROR,
  error,
})

export const skipToNextTrack = () => ({
  type: actions.SKIP_TO_NEXT,
})

export const skipToLastTrack = () => ({
  type: actions.SKIP_TO_LAST,
})

export const skipTrackSuccess = () => ({
  type: actions.SKIP_TRACK_SUCCESS,
})

export const playbackSeek = (position_ms) => ({
  type: actions.PLAYBACK_SEEK,
  position_ms,
})

export const seeking = () => ({
  type: actions.SEEKING,
})

export const playbackSeekSuccess = () => ({
  type: actions.PLAYBACK_SEEK_SUCCESS,
})

export const playbackSeekError = (error) => ({
  type: actions.PLAYBACK_SEEK_ERROR,
  error,
})

export const toggleRepeat = (repeat_mode) => ({
  type: actions.TOGGLE_REPEAT,
  repeat_mode,
})

export const toggleRepeatSuccess = (repeat_mode) => ({
  type: actions.TOGGLE_REPEAT_SUCCESS,
  repeat_mode,
})

export const toggleRepeatError = (error) => ({
  type: actions.TOGGLE_REPEAT_ERROR,
  error,
})

export const isTrackInLibrary = (track_id) => ({
  type: actions.CHECK_IF_SAVED_TRACK,
  track_id,
})

export const isTrackInLibrarySuccess = (in_library) => ({
  type: actions.CHECK_IF_SAVED_TRACK_SUCCESS,
  in_library,
})
export const isTrackInLibraryError = (error) => ({
  type: actions.CHECK_IF_SAVED_TRACK_ERROR,
  error,
})

// CAMBIA EL PROGRESO DEL REPRODUCTOR
export const cambia_progreso_del_reporductor = (progress_ms) => ({
  type: actions.CAMBIAR_PROGRESO_MUSICA_MILISEGUNDOS,
  progress_ms,
})
