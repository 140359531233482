export const noop = () => {}

export const on = (obj, ...args) => {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...args)
  }
}

export const off = (obj, ...args) => {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...args)
  }
}

export const parseTimeRanges = (ranges) => {
  const result = []

  for (let i = 0; i < ranges.length; i++) {
    result.push({
      start: ranges.start(i),
      end: ranges.end(i),
    })
  }

  return result
}

export const updateLinesLemmaLearned = (lines, wordMark, status = true) => {
  if (lines?.length > 0) {
    return lines.map((line) => {
      try {
        line.word_marks = line?.word_marks.map((word) => {
          if (word?.term_id === wordMark?.term_id) {
            word.lemma_learned = status
            if (wordMark?.form === word?.form) {
              word.form_learned = status
            }
          }
          return word
        })
      } catch (err) {
        return {
          error: new Error(err),
        }
      }
      return line
    })
  } else {
    return []
  }
}

export const updateMeorizeIdToWordList = (
  frencuencia = [],
  wordMark,
  memorizeId,
) => {
  if (frencuencia?.length > 0) {
    const array =
      frencuencia.map((word) => {
        if (
          word?.term_id === wordMark?.term_id &&
          word?.form?.trim() === wordMark?.form?.trim()
        ) {
          word.memorized_term_id = memorizeId
        }

        return word
      }) || []

    return array
  }

  return []
}

export const isBrowser = typeof window !== 'undefined'

export const isNavigator = typeof navigator !== 'undefined'
