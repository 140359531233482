import React from 'react'
import {useState} from 'react'
import {useEffect} from 'react'
import {
  timeDiffCalc,
  StateSuscription,
  calculateSuscriptionPreview,
  isSuscriptionStatePreview72,
  isSuscriptionStatePreview48,
  isSuscriptionStatePreview24,
  isSuscriptionStatePreviewComplete,
  isSuscriptionStatePreviewSuccess,
} from '../helpersPlayments'
import Emogi1 from '../Icons/Emogi1.svg'
import Emogi2 from '../Icons/Emogi2.svg'
import Emogi3 from '../Icons/Emogi3.svg'
import Emogi4 from '../Icons/Emogi4.svg'
import closeModal from '../Icons/closeModal.svg'
import renderHTML from 'react-render-html'
import {useIntl} from 'react-intl'

const TimerComponent = ({planType, trialFinishedAt, calculateTimer}) => {
  useEffect(() => {
    const interval = setInterval(() => {
      calculateTimer()
    }, 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [planType, trialFinishedAt])

  return null
}

const CardPay = ({
  omitProcess,
  nextModal,
  planType,
  trialFinishedAt,
  setCardPay,
}) => {
  const intl = useIntl()
  const [hours, setHours] = useState('00')
  const [minutes, setMinutes] = useState('00')
  const [seconds, setSeconds] = useState('00')

  useEffect(() => {
    // TODO ver forma de hacer funcionar esto. al cerrar el modal ya no funciona
    if (hours === '72' && minutes === '00' && seconds === '00') {
      setCardPay(true)
    } else if (hours === '42' && minutes === '00' && seconds === '00') {
      setCardPay(true)
    } else if (hours === '24' && minutes === '00' && seconds === '00') {
      setCardPay(true)
    } else if (hours === '00' && minutes === '00' && seconds === '00') {
      setCardPay(true)
    }

    // if (hours === "70" && minutes === "58" && seconds === "00") {
    //   setCardPay(true);
    // }

    // console.log({
    //   hours,
    //   minutes,
    //   seconds,
    // });
    // eslint-disable-next-line
  }, [minutes])

  const [stateSuscription, setStateSuscription] = useState(
    StateSuscription.PREVIEW_SUCCESS,
  )

  const startTrialProcess = () => {
    nextModal(true)
    omitProcess(false)
  }

  const calculateTimer = () => {
    const newYearsDate = new Date(trialFinishedAt)
    const {hours, minutes, seconds} = timeDiffCalc(newYearsDate)

    const suscriptionPreview = calculateSuscriptionPreview(hours, minutes, seconds)

    setStateSuscription(suscriptionPreview)
    if (suscriptionPreview === StateSuscription.PREVIEW_SUSCRIPTION_COMPLETED) {
      setHours('00')
      setMinutes('00')
      setSeconds('00')
    } else {
      setHours(`${hours}`)
      setMinutes(`${minutes > 9 ? minutes : `0${minutes}`}`)
      setSeconds(`${seconds > 9 ? seconds : `0${seconds}`}`)
    }
  }

  return (
    <div className="card__pay__container">
      {stateSuscription !== StateSuscription.PREVIEW_SUSCRIPTION_COMPLETED && (
        <TimerComponent
          planType={planType}
          trialFinishedAt={trialFinishedAt}
          calculateTimer={calculateTimer}
        />
      )}
      <div className="card__pay__header">
        <img
          className="card__pay__header__close__modal"
          src={closeModal}
          alt={closeModal}
          onClick={() => omitProcess(false)}
        />
        <div className="card__pay__header__emogi">
          {isSuscriptionStatePreview72(stateSuscription) && (
            <img src={Emogi1} alt={Emogi1} />
          )}
          {isSuscriptionStatePreview48(stateSuscription) && (
            <img src={Emogi2} alt={Emogi2} />
          )}
          {isSuscriptionStatePreview24(stateSuscription) && (
            <img src={Emogi3} alt={Emogi3} />
          )}
          {isSuscriptionStatePreviewComplete(stateSuscription) && (
            <img src={Emogi4} alt={Emogi4} />
          )}
          {isSuscriptionStatePreviewSuccess(stateSuscription) && (
            <img src={Emogi2} alt={Emogi2} />
          )}
        </div>
        <p className="card__pay__header__title">
          {isSuscriptionStatePreview72(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.title-1',
              defaultMessage: 'Your free trial is about to end.',
              description: 'Your free trial is about to end.',
            })}

          {isSuscriptionStatePreview48(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.title-2',
              defaultMessage:
                'If you subscribe now you will not lose the time you have left on the trial.',
              description:
                'If you subscribe now you will not lose the time you have left on the trial.',
            })}
          {isSuscriptionStatePreview24(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.title-3',
              defaultMessage: 'Your free trial has very little time left.',
              description: 'Your free trial has very little time left.',
            })}
          {isSuscriptionStatePreviewComplete(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.title-4',
              defaultMessage: 'Your free trial is over.',
              description: 'Your free trial is over.',
            })}
          {isSuscriptionStatePreviewSuccess(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.title-5',
              defaultMessage: 'Free Trial',
              description: 'Free Trial',
            })}
        </p>
      </div>

      <div>
        <p className="card__pay__timer_title">
          {isSuscriptionStatePreview72(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.title-timer-1',
              defaultMessage: 'Your test will end in:',
              description: 'Your test will end in:',
            })}
          {isSuscriptionStatePreview24(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.title-timer-1',
              defaultMessage: 'Your test will end in:',
              description: 'Your test will end in:',
            })}
          {isSuscriptionStatePreview48(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.title-timer-2',
              defaultMessage: 'Your free trial has:',
              description: 'Your free trial has:',
            })}

          {isSuscriptionStatePreviewComplete(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.title-timer-3',
              defaultMessage: 'They remain:',
              description: 'They remain:',
            })}
          {isSuscriptionStatePreviewSuccess(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.title-timer-3',
              defaultMessage: 'They remain:',
              description: 'They remain:',
            })}
        </p>
        <h2 className="card__pay__timer_text">{`${hours}:${minutes}:${seconds}`}</h2>
      </div>

      <p className="card__pay__body__text">
        {isSuscriptionStatePreview72(stateSuscription) &&
          renderHTML(
            intl.formatMessage({
              id: 'card-pay.title-body-1',
              defaultMessage:
                'Subscribe now and continue to achieve <br/> all your goals.',
              description:
                'Subscribe now and continue to achieve <br/> all your goals.',
            }),
          )}
        {isSuscriptionStatePreview48(stateSuscription) &&
          renderHTML(
            intl.formatMessage({
              id: 'card-pay.title-body-2',
              defaultMessage:
                'Continue enjoying the benefits <br/> of Coco and <b> keep meeting your goals.<b/>',
              description:
                'Continue enjoying the benefits <br/> of Coco and <b> keep meeting your goals.<b/>',
            }),
          )}
        {isSuscriptionStatePreview24(stateSuscription) &&
          renderHTML(
            intl.formatMessage({
              id: 'card-pay.title-body-2',
              defaultMessage:
                'Continue enjoying the benefits <br/> of Coco and <b> keep meeting your goals.<b/>',
              description:
                'Continue enjoying the benefits <br/> of Coco and <b> keep meeting your goals.<b/>',
            }),
          )}
        {isSuscriptionStatePreviewComplete(stateSuscription) &&
          renderHTML(
            intl.formatMessage({
              id: 'card-pay.title-body-3',
              defaultMessage:
                "Don't be left without the help of the <br/> teachers. subscribe now and continue <br/> enjoying the benefits.",
              description:
                "Don't be left without the help of the <br/> teachers. subscribe now and continue <br/> enjoying the benefits.",
            }),
          )}
        {isSuscriptionStatePreviewSuccess(stateSuscription) &&
          renderHTML(
            intl.formatMessage({
              id: 'card-pay.title-body-2',
              defaultMessage:
                'Continue enjoying the benefits <br/> of Coco and <b> keep meeting your goals.<b/>',
              description:
                'Continue enjoying the benefits <br/> of Coco and <b> keep meeting your goals.<b/>',
            }),
          )}
      </p>

      <div>
        <button
          className="card__pay__button__pay"
          onClick={() => startTrialProcess()}>
          {isSuscriptionStatePreview72(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.button-1',
              defaultMessage: 'Go to the payment module',
              description: 'Go to the payment module',
            })}
          {isSuscriptionStatePreview48(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.button-1',
              defaultMessage: 'Go to the payment module',
              description: 'Go to the payment module',
            })}
          {isSuscriptionStatePreview24(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.button-3',
              defaultMessage: 'Subscribe now',
              description: 'Subscribe now',
            })}
          {isSuscriptionStatePreviewComplete(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.button-2',
              defaultMessage: 'I want to subscribe',
              description: 'I want to subscribe',
            })}
          {isSuscriptionStatePreviewSuccess(stateSuscription) &&
            intl.formatMessage({
              id: 'card-pay.button-2',
              defaultMessage: 'I want to subscribe',
              description: 'I want to subscribe',
            })}
        </button>

        <div
          className="card__pay__button__omitir"
          onClick={() => omitProcess(false)}>
          {intl.formatMessage({
            id: 'card-pay.omitir',
            defaultMessage: 'Maybe later',
            description: 'Maybe later',
          })}
        </div>
      </div>
    </div>
  )
}

export default CardPay
