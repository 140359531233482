import * as actions from './actions'
import * as types from './actionTypes'
import * as api from './requests'
import {API_WS_ROOT} from '../../Services/EndPointApi'

let socket,
  channel = 'ConversationsChannel'

const onOpen = (socket, store) => (evt) => {
  console.debug('CONVERSATION WS OPEN')
  store.dispatch(actions.subscribing())
}

const onClose = (socket, store) => (evt) => {
  console.debug('CONVERSATION WS CLOSE')
  store.dispatch(actions.wsconnect())
}

const onError = (socket, store) => (evt) => {
  console.debug('CONVERSATION WS ERROR')
  socket.close()
}

const onMessage = (socket, store) => (evt) => {
  const msg = JSON.parse(evt.data)
  const connectionState = store.getState().conversations.connection.state

  // console.debug(msg);
  if (msg.type === 'ping') {
    return
  }

  if (connectionState === 'READY' && msg.message && msg.identifier && !msg.type) {
    // console.debug(msg);
    let data_key = Object.keys(msg.message)[0]
    store.dispatch(actions.receivedData(msg.message[data_key]))
  }

  switch (msg.type) {
    case 'welcome': {
      store.dispatch(actions.subscribing())
      const message = {
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: channel,
        }),
      }
      socket.send(JSON.stringify(message))
      break
    }
    case 'confirm_subscription': {
      store.dispatch(actions.ready())
      api
        .fetchConversations()
        .then((response) =>
          store.dispatch(actions.receivedConversations(response.data)),
        )
        .catch((err) => console.debug(err))
      break
    }
    default:
      break
  }
}

const fetchConversationsAction = () => (dispatch) => {
  return api
    .fetchConversations()
    .then((response) => dispatch(actions.receivedConversations(response.data)))
    .catch((err) => console.debug(err))
}

export default (store) => (next) => (action) => {
  const result = next(action)
  const match = /^WS_(.+)$/.exec(action.type)

  if (!match) {
    return result
  }

  const wsAction = {...action, type: match[1]}
  const connectionState = store.getState().conversations.connection.state
  let message
  switch (wsAction.type) {
    case 'SEND_CHAT_MESSAGE': {
      // console.debug(wsAction);
      message = {
        command: 'message',
        identifier: JSON.stringify({channel: channel}),
        data: JSON.stringify({
          action: 'chat_message',
          params: JSON.stringify({
            text: wsAction.text,
            // image: wsAction.image,
            // audio: wsAction.audio,
            email_mentions: wsAction.emailMatches || [],
            conversation_id: wsAction.conversation_id,
            user_id: wsAction.user_id,
          }),
        }),
      }
      if (connectionState === 'READY') socket.send(JSON.stringify(message))

      break
    }
    case 'CONVERSATIONS_TYPING_START': {
      // console.debug(wsAction);
      message = {
        command: 'message',
        identifier: JSON.stringify({channel: channel}),
        data: JSON.stringify({
          action: 'is_typing',
          params: JSON.stringify({
            user_id: wsAction.user.id,
            is_typing: true,
            conversation_id: wsAction.conversation,
          }),
        }),
      }
      if (connectionState === 'READY') socket.send(JSON.stringify(message))
      break
    }
    case 'CONVERSATIONS_TYPING_STOP': {
      message = {
        command: 'message',
        identifier: JSON.stringify({channel: channel}),
        data: JSON.stringify({
          action: 'is_typing',
          params: JSON.stringify({
            user_id: wsAction.user.id,
            is_typing: false,
            conversation_id: wsAction.conversation,
          }),
        }),
      }
      if (connectionState === 'READY') socket.send(JSON.stringify(message))
      break
    }
    case 'CONVERSATIONS_CONNECT': {
      if (socket) {
        socket.close()
      }
      const token = wsAction?.payload?.token ? wsAction.payload.token : null

      if (token) {
        const url = `${API_WS_ROOT}?token=${token}`

        socket = new WebSocket(url)
        socket.onmessage = onMessage(socket, store)
        socket.onclose = onClose(socket, store)
        socket.onopen = onOpen(socket, store)
        socket.onerror = onError(socket, store)

        store.dispatch(actions.opening())
      }

      break
    }
    case 'CONVERSATIONS_SUBSCRIBE': {
      const msg = {
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: channel,
        }),
      }

      socket.send(JSON.stringify(msg))
      store.dispatch(actions.subscribing())
      break
    }

    default:
      next(action)
  }

  switch (action.type) {
    case types.FETCH_CONVERSATIONS:
      return store.dispatch(fetchConversationsAction())
    default:
      break
  }

  return result
}
