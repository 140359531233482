export const LOGIN = 'LOGIN'

export const FETCH_PLAYBACK_INFO = 'FETCH_PLAYBACK_INFO'
export const FETCH_PLAYBACK_INFO_SUCCESS = 'FETCH_PLAYBACK_INFO_SUCCESS'
export const FETCH_PLAYBACK_INFO_ERROR = 'FETCH_PLAYBACK_INFO_ERROR'

export const FETCH_NOW_PLAYING = 'FETCH_NOW_PLAYING'
export const FETCH_NOW_PLAYING_SUCCESS = 'FETCH_NOW_PLAYING_SUCCESS'
export const FETCH_NOW_PLAYING_ERROR = 'FETCH_NOW_PLAYING_ERROR'

export const UPDATE_PLAYBACK_POSITION = 'UPDATE_PLAYBACK_POSITION'

export const TRANSFER_PLAYBACK = 'TRANSFER_PLAYBACK'
export const TRANSFER_PLAYBACK_SUCCESS = 'TRANSFER_PLAYBACK_SUCCESS'
export const TRANSFER_PLAYBACK_ERROR = 'TRANSFER_PLAYBACK_ERROR'

export const PLAYER_STATE_CHANGED = 'PLAYER_STATE_CHANGED'
export const SET_PLAYER_STATE = 'SET_PLAYER_STATE'
export const PLAYER_ERROR = 'PLAYER_ERROR'

export const PLAY_TRACK = 'PLAY_TRACK'
export const PLAY_TRACK_SUCCESS = 'PLAY_TRACK_SUCCESS'
export const PLAY_TRACK_ERROR = 'PLAY_TRACK_ERROR'

export const PLAY_CONTEXT = 'PLAY_CONTEXT'
export const PLAY_CONTEXT_SUCCESS = 'PLAY_CONTEXT_SUCCESS'
export const PLAY_CONTEXT_ERROR = 'PLAY_CONTEXT_ERROR'

export const TOGGLE_PLAYBACK = 'TOGGLE_PLAYBACK'
export const TOGGLE_PLAYBACK_SUCCESS = 'TOGGLE_PLAYBACK_SUCCESS'
export const TOGGLE_PLAYBACK_ERROR = 'TOGGLE_PLAYBACK_ERROR'

export const TOGGLE_SHUFFLE = 'TOGGLE_SHUFFLE'
export const TOGGLE_SHUFFLE_SUCCESS = 'TOGGLE_SHUFFLE_SUCCESS'
export const TOGGLE_SHUFFLE_ERROR = 'TOGGLE_SHUFFLE_ERROR'

export const TOGGLE_REPEAT = 'TOGGLE_REPEAT'
export const TOGGLE_REPEAT_SUCCESS = 'TOGGLE_REPEAT_SUCCESS'
export const TOGGLE_REPEAT_ERROR = 'TOGGLE_REPEAT_ERROR'

export const CHANGE_VOLUME = 'CHANGE_VOLUME'
export const CHANGE_VOLUME_SUCCESS = 'CHANGE_VOLUME_SUCCESS'
export const CHANGE_VOLUME_ERROR = 'CHANGE_VOLUME_ERROR'

export const SKIP_TO_NEXT = 'SKIP_TO_NEXT'
export const SKIP_TO_LAST = 'SKIP_TO_LAST'
export const SKIP_TRACK_SUCCESS = 'SKIP_TRACK_SUCCESS'

export const TRACK_CHANGING = 'TRACK_CHANGING'
export const TRACK_CHANGED = 'TRACK_CHANGED'

export const PLAYBACK_SEEK = 'PLAYBACK_SEEK'
export const SEEKING = 'SEEKING'
export const PLAYBACK_SEEK_SUCCESS = 'PLAYBACK_SEEK_SUCCESS'
export const PLAYBACK_SEEK_ERROR = 'PLAYBACK_SEEK_ERROR'

export const FETCH_LYRICS = 'FETCH_SONG_LYRICS'
export const FETCH_LYRICS_SUCCESS = 'FETCH_SONG_LYRICS_SUCCESS'
export const FETCH_LYRICS_ERROR = 'FETCH_SONG_LYRICS_ERROR'

// SEARCH ACTIONS
export const SEARCH_SPOTIFY = 'SEARCH_SPOTIFY'
export const SEARCH_SPOTIFY_SUCCESS = 'SEARCH_SPOTIFY_SUCCESS'
export const SEARCH_SPOTIFY_ERROR = 'SEARCH_SPOTIFY_ERROR'
export const SEARCH_SPOTIFY_RESET = 'SEARCH_SPOTIFY_RESET'

//      AUDIO ANALYSIS & VISUALIZATION
export const FETCH_AUDIO_ANALYSIS = 'FETCH_AUDIO_ANALYSIS'
export const FETCH_NEXT_AUDIO_ANALYSIS = 'FETCH_NEXT_AUDIO_ANALYSIS'
export const FETCH_AUDIO_ANALYSIS_SUCCESS = 'FETCH_AUDIO_ANALYSIS_SUCCESS'
export const FETCH_AUDIO_ANALYSIS_ERROR = 'FETCH_AUDIO_ANALYSIS_ERROR'

export const BEGIN_VISUALIZATION = 'BEGIN_VISUALIZATION'
export const END_VISUALIZATION = 'END_VISUALIZATION'

// PLAYLIST ACTIONS
export const FETCH_PLAYLIST = 'FETCH_PLAYLIST'
export const FETCH_PLAYLIST_SUCCESS = 'FETCH_PLAYLIST_SUCCESS'
export const FETCH_PLAYLIST_ERROR = 'FETCH_PLAYLIST_ERROR'

export const CHECK_IF_SAVED_TRACK = 'CHECK_IF_SAVED_TRACK'
export const CHECK_IF_SAVED_TRACK_SUCCESS = 'CHECK_IF_SAVED_TRACK_SUCCESS'
export const CHECK_IF_SAVED_TRACK_ERROR = 'CHECK_IF_SAVED_TRACK_ERROR'

/*
		ERROR ACTIONS
*/
export const PLAYER_INIT_ERROR = 'PLAYER_INIT_ERROR'
export const PLAYER_INIT_RETRY = 'PLAYER_INIT_RETRY'
export const PLAYER_AUTH_ERROR = 'PLAYER_AUTH_ERROR'
export const PLAYER_AUTH_RETRY = 'PLAYER_AUTH_RETRY'
export const PLAYER_ACCT_ERROR = 'PLAYER_ACCT_ERROR'
export const PLAYBACK_ERROR = 'PLAYBACK_ERROR'

/*
		PLAYBACK DEVICES
*/
export const FETCH_PLAYBACK_DEVICES = 'FETCH_PLAYBACK_DEVICES'
export const FETCH_PLAYBACK_DEVICES_SUCCESS = 'FETCH_PLAYBACK_DEVICES_SUCCESS'
export const FETCH_PLAYBACK_DEVICES_ERROR = 'FETCH_PLAYBACK_DEVICES_ERROR'

/*  
		PLAYER LOADING ACTIONS
*/
export const PLAYER_LOADING = 'PLAYER_LOADING'
export const PLAYER_READY = 'PLAYER_READY'

/*
	CAMBIA EL PROGRESO DE LA MUSICA EN MILI-SEGUNDOS
*/
export const CAMBIAR_PROGRESO_MUSICA_MILISEGUNDOS =
  'CAMBIAR_PROGRESO_MUSICA_MILISEGUNDOS'
