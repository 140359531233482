import {getToken} from '../../Services/getToken'

const getHeaders = (withAuthorization = false) => {
  const authorization = withAuthorization
    ? {
        Authorization: getToken(),
      }
    : {}

  return {
    ...authorization,
    'Content-Type': 'application/json',
  }
}

const getHeadersOnboarding = (authorization) => {
  return {
    Authorization: authorization,
    'Content-Type': 'application/json',
  }
}

const ApiMessages = {
  ErrorServer: 'Server error, Intente más tarde',
  OK: 'Ok',
  Created: 'Created',
  Accepted: 'Accepted',
  NoContent: 'No Content',
  BadRequest: 'BadRequest',
  Unauthorized: 'Unauthorized',
  Forbidden: 'Forbidden',
  NotFound: 'NotFound',
}

const StatusApi = {
  OK: 200,
  Created: 201,
  Accepted: 202,
  NoContent: 204,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
}

// tipos de conversaciones en backend (enum)
const ConversationType = {
  HILO: 'hilo',
  PERSONAL: 'personal',
  GROUP: 'grupo',
}

const errorHandler = (operation, message = ApiMessages.ErrorServer) => {
  return (error) => {
    console.error(operation, error)
    return {
      message,
    }
  }
}

export {
  getHeaders,
  getHeadersOnboarding,
  errorHandler,
  ApiMessages,
  StatusApi,
  ConversationType,
}
