import React from 'react'

const Definitions = ({definitions}) => {
  return (
    <>
      <h2>{definitions?.word}</h2>
      {definitions?.meanings?.length > 0 &&
        definitions.meanings.map((partOfSpeechMeanings, index) => (
          <React.Fragment key={index}>
            <h3 style={{fontWeight: 'bold'}}>
              {partOfSpeechMeanings?.partOfSpeech}
            </h3>

            {partOfSpeechMeanings?.definitions?.length > 0 &&
              partOfSpeechMeanings.definitions.map((definition, index) => (
                <React.Fragment key={index}>
                  <div>
                    {index + 1}. {definition?.definition}
                  </div>
                  <em style={{marginLeft: '16px'}}>{definition?.example}</em>
                  <div style={{fontSize: '10px', marginLeft: '16px'}}>
                    synonyms:
                    {definition?.synonyms?.length > 0 &&
                      definition.synonyms.map((synonym, index) => (
                        <span key={index}>{synonym}, </span>
                      ))}
                  </div>
                  <br />
                  <br />
                </React.Fragment>
              ))}
          </React.Fragment>
        ))}
    </>
  )
}

const DiccionaryGoogle = ({definitionsGoogle}) => {
  return (
    <div className="diccionary-body scroll-coco text-info">
      {definitionsGoogle?.length > 0 &&
        definitionsGoogle.map((definitions) => (
          <Definitions definitions={definitions} />
        ))}
    </div>
  )
}

export default DiccionaryGoogle
