import React from 'react'
import PropTypes from 'prop-types'
import './Done.css'

const Done = ({message, invitation, show}) => {
  const close = () => {
    if (invitation && show) {
      show(false)
      invitation(true)
    }
  }
  return (
    <div id="alerta-de-nota-agregada">
      <img src="/images/Notas/NotaAgregada.svg" alt="" className="mb-2" />
      <h6 id="msg-nota-agregada" onClick={() => close()}>
        {message}
      </h6>
    </div>
  )
}

Done.propTypes = {
  message: PropTypes.string,
  invitation: PropTypes.func,
  show: PropTypes.func,
}

export default Done
