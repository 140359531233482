import React from 'react'
import PropTypes from 'prop-types'
import './CircularProgressbarAll.css'
import {connect} from 'react-redux'

const MIN_PERCENTAGE = 0
const MAX_PERCENTAGE = 100
const MAX_X = 100
const MAX_Y = 100
const FULL_RADIUS = 50
const CENTER_X = 50
const CENTER_Y = 50

class CircularProgressbarAll extends React.Component {
  componentWillUnmount() {
    clearTimeout(this.initialTimeout)
    window.cancelAnimationFrame(this.requestAnimationFrame)
  }

  getBackgroundPadding() {
    if (this.props.background) {
      // default padding to be the same as strokeWidth
      // compare to null because 0 is falsy
      if (this.props.backgroundPadding == null) {
        return this.props.strokeWidth
      }
      return this.props.backgroundPadding
    }
    // don't add padding if not displaying background
    return 0
  }

  getPathDescription() {
    const radius = this.getPathRadius()
    const rotation = this.props.counterClockwise ? 1 : 0

    // Move to center of canvas
    // Relative move to top canvas
    // Relative arc to bottom of canvas
    // Relative arc to top of canvas
    return `
      M ${CENTER_X},${CENTER_Y}
      m 0,-${radius}
      a ${radius},${radius} ${rotation} 1 1 0,${2 * radius}
      a ${radius},${radius} ${rotation} 1 1 0,-${2 * radius}
    `
  }

  getPathStyles() {
    const {progreso} = this.props
    const diameter = Math.PI * 2 * this.getPathRadius()
    const truncatedPercentage = Math.min(
      Math.max(progreso ? progreso : 0, MIN_PERCENTAGE),
      MAX_PERCENTAGE,
    )
    const dashoffset = ((100 - truncatedPercentage) / 100) * diameter

    return {
      strokeDasharray: `${diameter}px ${diameter}px`,
      strokeDashoffset: `${
        this.props.counterClockwise ? -dashoffset : dashoffset
      }px`,
    }
  }

  getPathRadius() {
    // the radius of the path is defined to be in the middle, so in order for the path to
    // fit perfectly inside the 100x100 viewBox, need to subtract half the strokeWidth
    return FULL_RADIUS - this.props.strokeWidth / 2 - this.getBackgroundPadding()
  }

  render() {
    const {
      progreso,
      cantidad,
      className,
      classes,
      styles,
      strokeWidth,
      color,
    } = this.props

    const classForPercentage = this.props.classForPercentage
      ? this.props.classForPercentage(progreso)
      : ''
    const pathDescription = this.getPathDescription()

    // classes.path = "CircularProgressbar-path-flashcard";
    // classes.path = "CircularProgressbar-path-input";
    classes.path = 'CircularProgressbar-path-output' //default

    classes.path = color

    return (
      <svg
        className={`${classes.root} ${className} ${classForPercentage}`}
        viewBox={`0 0 ${MAX_X} ${MAX_Y}`}>
        {this.props.background ? (
          <circle
            className={classes.background}
            style={styles.background}
            cx={CENTER_X}
            cy={CENTER_Y}
            r={FULL_RADIUS}
          />
        ) : null}
        <path
          className={classes.trail}
          style={styles.trail}
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
        />
        <path
          className={classes.path}
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
          style={Object.assign({}, styles.path, this.getPathStyles())}
        />
        ,
        {cantidad ? (
          <text
            className={classes.text}
            style={styles.text}
            x={CENTER_X}
            y={CENTER_Y}>
            {cantidad}
          </text>
        ) : null}
      </svg>
    )
  }
}

CircularProgressbarAll.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  styles: PropTypes.objectOf(PropTypes.object),
  strokeWidth: PropTypes.number,
  background: PropTypes.bool,
  backgroundPadding: PropTypes.number,
  initialAnimation: PropTypes.bool,
  counterClockwise: PropTypes.bool,
  classForPercentage: PropTypes.func,
  title: PropTypes.string,
}

CircularProgressbarAll.defaultProps = {
  strokeWidth: 7,
  className: '',
  classes: {
    root: 'CircularProgressbar',
    trail: 'CircularProgressbar-trail',
    title: 'CircularProgressbar-title',
    text: 'CircularProgressbar-text',
    percsign: 'CircularProgressbar-percsign',
    background: 'CircularProgressbar-background',
  },
  styles: {
    root: {},
    trail: {},
    path: {},
    title: {},
    text: {},
    percsign: {},
    background: {},
  },
  background: true,
  backgroundPadding: null,
  initialAnimation: true,
  counterClockwise: true,
  classForPercentage: null,
  title: null,
}

const mapStateToProps = (state) => ({
  progress: state.user.all_progress,
})

const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(CircularProgressbarAll)
