import {useEffect} from 'react'
import {StatusApi} from '../lib/coco/helperData'
import {suscribePushNotification} from '../lib/coco/notificationsApi'
import {getVariables} from '../Services/constants'

const {REACT_APP_ONESIGNAL_APP_ID, REACT_APP_CLIENT_URL} = getVariables()

const useSuscriptionNotification = () => {
  useEffect(() => {
    initPushNotification()
    updateSubscription()
    // eslint-disable-next-line
  }, [])

  const initPushNotification = () => {
    var OneSignal = window.OneSignal || []
    OneSignal.push(function () {
      // If we're on an unsupported browser, do nothing
      if (!OneSignal.isPushNotificationsSupported()) {
        console.log('no soportado')
        return
      }

      OneSignal.init({
        appId: REACT_APP_ONESIGNAL_APP_ID,
        // autoRegister: false
        autoResubscribe: true,
        notifyButton: {
          enable: true,
        },
        welcomeNotification: {
          title: 'Gracias por Suscribirte!',
          message: 'Ahora no te perderas noticias nuevas 😊!',
          url: REACT_APP_CLIENT_URL /* Leave commented for the notification to not open a window on Chrome and Firefox (on Safari, it opens to your webpage) */,
        },
      })
    })
  }

  const getSubscriptionState = async () => {
    if (!window.OneSignal) return

    return Promise.all([window.OneSignal.isPushNotificationsEnabled()]).then(
      (result) => {
        const isPushEnabled = result[0]
        return isPushEnabled
      },
    )
  }

  const updateSubscription = async () => {
    const isPushEnabled = await getSubscriptionState()

    if (isPushEnabled) {
      window.OneSignal.getUserId((id) => sendSubscription(id))
    } else {
      window.OneSignal.showNativePrompt()
    }
  }

  const sendSubscription = (pushId) => {
    if (pushId?.trim()?.length > 0) {
      suscribePushNotification(pushId).then((res) => {
        if (res.status !== StatusApi.OK) {
          console.debug('fallo subscription')
        }
      })
    }
  }
}

export default useSuscriptionNotification
