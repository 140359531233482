import React, {useRef} from 'react'
import useBooleanToggler from '../../../hooks/useBooleanToggler'
import useOnClickOutside from '../../../hooks/useClickOutside'
import treePointsVerticalIcon from './Icons/treePointsVertical.svg'
import checkHiloIcon from './Icons/checkHilo.svg'
import {resolverAndReopenConversation} from '../../../lib/coco/conversationApi'
import {isConversationResolved} from '../helpers'
import CreateItemHelp from './Buttons/CreateItemHelp'

const OptionsConversation = ({
  conversation,
  currentUser,
  isTeacherFromDomain,
  setShowDefineThread,
}) => {
  const {isToggled, toggle} = useBooleanToggler()
  const ref = useRef()

  useOnClickOutside(ref, () => {
    isToggled && toggle()
  })

  const handleClick = () => {
    resolverAndReopenConversation(
      conversation.id,
      isConversationResolved(conversation),
      currentUser.id,
    )
  }

  return (
    <div className="mini-chat-options-conversation" ref={ref}>
      <button className="mini-chat-btn-float" onClick={toggle}>
        <img src={treePointsVerticalIcon} alt="options" />
      </button>
      {isToggled && (
        <div className="minichat__btn__container">
          {!isConversationResolved(conversation) && isTeacherFromDomain && (
            <button
              onClick={() => setShowDefineThread(true)}
              className="mini-chat-btn-float"
              style={{border: '2px solid salmon'}}>
              Def.
            </button>
          )}

          {isTeacherFromDomain && (
            <button
              className="mini-chat-btn-float"
              style={{
                border: `2px solid ${
                  !isConversationResolved(conversation) ? '#7FE55B' : 'salmon'
                }`,
              }}
              onClick={handleClick}
              title={
                isConversationResolved(conversation) ? 'Re-Open hilo' : 'Cerrar hilo'
              }>
              {!isConversationResolved(conversation) ? (
                <img src={checkHiloIcon} alt="check" />
              ) : (
                'R-O'
              )}
            </button>
          )}

          <div className="mini-chat-btn-pedir-ayuda-float">
            <CreateItemHelp conversation={conversation} />
          </div>
        </div>
      )}
    </div>
  )
}

export default OptionsConversation
