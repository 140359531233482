import React from 'react'
import newMessageIcon from './Icons/newMessage.svg'
import newGroupIcon from './Icons/newGroup.svg'

const CreateMessagesAndGroup = ({newMessage, newGroup, intl}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}>
      <div
        id="btn-new-thread"
        className="d-flex justify-content-center align-items-center"
        onClick={newMessage}>
        <span id="btn-img-new-thread" className="mr-2">
          <img src={newMessageIcon} alt="Crear nuevo hilo" />
        </span>
        <span id="btn-title-new-thread">
          {intl.formatMessage({
            id: 'chat.new-message',
            defaultMessage: 'New message',
          })}
        </span>
      </div>

      <div
        id="btn-new-thread"
        className="d-flex justify-content-center align-items-center my-4"
        onClick={newGroup}>
        <span id="btn-img-new-thread" className="mr-2">
          <img src={newGroupIcon} alt="Crear nuevo hilo" />
        </span>
        <span id="btn-title-new-thread">
          {intl.formatMessage({
            id: 'chat.new-group',
            defaultMessage: 'Create group',
          })}
        </span>
      </div>
    </div>
  )
}

export default CreateMessagesAndGroup
