import {getArtistTracks, getAlbumTracksById} from '../../lib/coco/InputApi'
import {
  FETCH_ARTIST_REQUEST_FINISH,
  FETCH_ARTIST_REQUEST_START,
  FETCH_ALBUM_REQUEST_START,
  FETCH_ARTIST_REQUEST_ERROR,
  UPDATE_TRACKS_ARTIST_FROM_LIST,
  FETCH_ALBUM_REQUEST_FINISH,
} from './artistsTypes'

const startArtistsRequest = () => {
  return {
    type: FETCH_ARTIST_REQUEST_START,
    payload: {},
  }
}

const startAlbumRequest = () => {
  return {
    type: FETCH_ALBUM_REQUEST_START,
    payload: {},
  }
}

const completeArtistsRequest = (data) => {
  return {
    type: FETCH_ARTIST_REQUEST_FINISH,
    payload: {
      artist: data.artist,
      topTracks: data.topTracks,
      albums: data.albums,
    },
  }
}

const completeAlbumRequest = (tracks, album) => {
  return {
    type: FETCH_ALBUM_REQUEST_FINISH,
    payload: {tracks, album},
  }
}

const errorArtistsRequest = (error) => {
  return {
    type: FETCH_ARTIST_REQUEST_ERROR,
    payload: {error},
  }
}

const getArtistBySpotifyId = (spotifyId) => (dispatch) => {
  dispatch(startArtistsRequest())

  return getArtistTracks(spotifyId).then((response) => {
    if (response.message) {
      dispatch(errorArtistsRequest(response))
    }
    dispatch(completeArtistsRequest(response))
  })
}

const getAlbumById = (album) => (dispatch) => {
  dispatch(startAlbumRequest())
  return getAlbumTracksById(album.spotify_id).then((response) => {
    if (response.message) {
      dispatch(errorArtistsRequest(response))
    }
    dispatch(completeAlbumRequest(response, album))
  })
}

const updateTrackArtists = (track) => {
  return {
    type: UPDATE_TRACKS_ARTIST_FROM_LIST,
    payload: {track},
  }
}

export {getArtistBySpotifyId, updateTrackArtists, getAlbumById}
