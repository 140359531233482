import React from 'react'
import {Link} from 'react-router-dom'
import LogoCoquitoIcon from './Icons/LogoCoquito.svg'

const LinkLogoCoco = ({className = '', ...res}) => {
  return (
    <Link to="/dashboard">
      <img
        src={LogoCoquitoIcon}
        className={`leftbar__logo__coco ${className}`}
        alt="coco"
        {...res}
      />
    </Link>
  )
}

export default LinkLogoCoco
