import Axios from 'axios'
import {
  LOGIN,
  SIGNUP,
  GET_LANGUAGES,
  CONFIRMATION_ACCOUNT,
  UPDATE_PROFILE,
  SAVE_USER_LANGUAGES,
  RESET_PASSWORD,
  INVITATIONS,
  LOGOUT,
  PROGRESS,
  UPLOAD_PHOTO,
  CHANGE_PASSWORD,
} from '../../Services/EndPointApi'
import {ApiMessages, errorHandler, StatusApi, getHeaders} from './helperData'

const getLanguagesApi = async () => {
  return await Axios.get(GET_LANGUAGES, {
    headers: getHeaders(),
  })
    .then((response) => {
      if (response?.data) {
        const {data} = response
        return {
          data,
        }
      } else {
        return {
          message: 'Error no se pudieron obtener los Lenguajes',
        }
      }
    })
    .catch(errorHandler('GetLanguages'))
}

const confirmAccount = async (email, pin) => {
  return await Axios.put(
    CONFIRMATION_ACCOUNT,
    {
      confirmation: {
        email,
        pin,
      },
    },
    {
      headers: getHeaders(),
    },
  )
    .then((response) => {
      const {data} = response
      if (!data) {
        return {
          status: true,
        }
      } else {
        return {
          message: 'Error no se pudo confirmar la cuenta',
        }
      }
    })
    .catch(errorHandler('ConfirmAccount'))
}

const acceptInvitation = async (token, password, passwordConfirmation) => {
  return await Axios.put(
    INVITATIONS,
    {
      user: {
        invitation_token: token,
        password,
        password_confirmation: passwordConfirmation,
      },
    },
    {
      headers: getHeaders(),
    },
  )
    .then((response) => {
      const {data} = response

      if (data) {
        const {user} = data
        return {
          user,
        }
      } else {
        return {
          message: 'Error no se pudo aceptar la invitacion',
        }
      }
    })
    .catch(errorHandler('AcceptInvitation'))
}

const sendUserInvitation = async (email) => {
  return await Axios.post(
    INVITATIONS,
    {
      user: {
        email,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data) {
        return {
          status: StatusApi.OK,
        }
      } else {
        return {
          message: 'Error no se pudo crear la invitacion',
        }
      }
    })
    .catch(errorHandler('sendUserInvitation'))
}

const resetPassword = async (email) => {
  return await Axios.post(
    RESET_PASSWORD,
    {email},
    {
      headers: getHeaders(),
    },
  )
    .then((response) => {
      const {data} = response

      if (data) {
        return {
          status: true,
        }
      } else {
        return {
          message: 'Error no se pudo iniciar el proceso de reseteo',
        }
      }
    })
    .catch(errorHandler('ResetPassword'))
}

const changePassword = async (token, password, password_confirmation) => {
  return await Axios.put(
    RESET_PASSWORD,
    {
      reset_password_token: token,
      password,
      password_confirmation,
    },
    {
      headers: getHeaders(),
    },
  )
    .then((response) => {
      const {data} = response

      if (data) {
        return {
          status: true,
        }
      } else {
        return {
          message: 'Error no se pudo cambiar la contraseña',
        }
      }
    })
    .catch(errorHandler('ChangePassword'))
}

const updateProfile = (id, profile) => {
  return Axios.post(
    UPDATE_PROFILE + id,
    {
      profile,
    },
    {
      headers: getHeaders(),
    },
  )
    .then((response) => {
      const {data} = response

      if (data) {
        return {
          data,
        }
      } else {
        return {
          message: 'No se pudo agregar los datos a su cuenta',
        }
      }
    })
    .catch(errorHandler('UpdateProfile'))
}

const saveLanguagesApi = (user_id, nativeLanguage, learningLanguage) => {
  return Axios.post(
    SAVE_USER_LANGUAGES,
    {
      userlanguage: {
        user_id,
        language_id: nativeLanguage,
      },
      learnlanguage: {
        language_id: learningLanguage,
      },
    },
    {
      headers: getHeaders(),
    },
  )
    .then((response) => {
      const {data} = response

      if (data) {
        return {
          status: true,
        }
      } else {
        return {
          message: 'Error no se pudo guardar  el Lenguaje',
        }
      }
    })
    .catch((error) => {
      return {
        error,
      }
    })
}

const loginApi = (email, password, headers) => {
  return Axios.post(
    LOGIN,
    {
      user: {
        email: email,
        password: password,
      },
    },
    {
      headers: headers,
    },
  )
    .then((response) => {
      const {authorization} = response.headers

      if (response.data && authorization) {
        return {
          authorization,
          user: response.data,
        }
      } else {
        return {
          message: 'Cuenta no verificada o Usuario o Contraseña Incorrectos',
        }
      }
    })
    .catch(errorHandler('Login'))
}

const loginCoco = (email, password) => {
  return loginApi(email, password, getHeaders())
}

const verificationUser = () => {
  return loginApi('', '', getHeaders(true))
}

const registerCoco = async (user) => {
  return await Axios.post(
    SIGNUP,
    {
      user,
    },
    {
      headers: getHeaders(),
    },
  )
    .then((response) => {
      if (response.data) {
        return {
          userCreated: response.data,
        }
      } else {
        return {
          message: 'Datos incorrectos',
        }
      }
    })
    .catch((error) => {
      let errores = ''

      if (
        error?.response?.data?.errors[0]?.detail?.email[0] ===
        'has already been taken'
      ) {
        errores = 'Cuenta ya existe'
      } else if (
        error?.response?.data?.errors[0]?.detail?.email[0] === 'is invalid'
      ) {
        errores = 'El correo es inválido'
      } else if (error?.response?.data?.errors[0]?.detail?.password != null) {
        errores = ' La contraseña debe tener al menos 6 caracteres'
      }

      return {
        message: errores,
      }
    })
}

const logout = () => {
  return Axios.delete(LOGOUT, {
    headers: getHeaders(true),
  })
    .then(() => {
      return {
        status: StatusApi.OK,
      }
    })
    .catch(errorHandler('logout'))
}

const getGeneralDataUser = () => {
  return Axios.get(PROGRESS, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response.data) {
        return {
          generalData: response.data,
        }
      } else {
        return {
          status: StatusApi.NoContent,
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('getGeneralDataUser'))
}

const uploadPhoto = (avatar) => {
  let data = new FormData()
  data.append('avatar', avatar)
  return Axios.put(UPLOAD_PHOTO, data, {
    headers: getHeaders(true),
  })
    .then((response) => {
      console.log(response)
      return {
        status: StatusApi.OK,
      }
    })
    .catch(errorHandler('upload photo'))
}

const updatePassword = (currentPassword, newPassword) => {
  return Axios.patch(
    CHANGE_PASSWORD,
    {
      user: {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: newPassword,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.user) {
        return {
          user: response?.data?.user,
        }
      } else {
        return {
          status: StatusApi.Forbidden,
          message: ApiMessages.Forbidden,
        }
      }
    })
    .catch(errorHandler('updatePassword'))
}

export {
  loginApi,
  loginCoco,
  verificationUser,
  registerCoco,
  getLanguagesApi,
  confirmAccount,
  saveLanguagesApi,
  updateProfile,
  resetPassword,
  changePassword,
  acceptInvitation,
  sendUserInvitation,
  logout,
  getGeneralDataUser,
  uploadPhoto,
  updatePassword,
}
