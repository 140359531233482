import * as actions from './actionTypes'

const READY = 'READY'
// const OPEN="OPEN";
const OPENING = 'OPENING'
// const ERROR="ERROR";
// const CLOSED="CLOSED";
// const CONNECTED="CONNECTED";
// const CONNECTING="CONNECTED";
// const SUBSCRIBED="SUBSCRIBED";
const SUBSCRIBING = 'SUBSCRIBING'

var initialStore = {
  users: [],
  errors: [],
  connection: {
    state: 'CLOSED',
  },
}

const disconnectHandler = (user, users) => {
  if (!users || users.length <= 0) return []

  while (Array.isArray(users) && users.filter((u) => u.id === user.id).length > 0) {
    users.splice(users.indexOf(user), 1)
  }

  return users.length > 0 ? users : []
}

const receivedPresenceState = (users) => {
  var list = {},
    user

  for (var i = 0; i < users.length; i++) {
    user = users[i]
    let id = user.id
    let compare = null

    if (id in list) {
      compare = users
        .filter((c) => c.id === id)
        .sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))

      users.splice(
        users.findIndex(
          (c) => c.id === id && c.updated_at === compare[0].updated_at,
        ),
        1,
      )
    } else {
      list[id] = true
    }
  }

  return users
}

export default function (state = initialStore, action) {
  switch (action.type) {
    case actions.PRESENCE_CONNECTION_OPENING: {
      return {
        ...state,
        connection: {state: OPENING},
      }
    }
    case actions.PRESENCE_CONNECTION_SUBSCRIBING: {
      return {
        ...state,
        connection: {state: SUBSCRIBING},
      }
    }
    case actions.PRESENCE_CONNECTION_READY: {
      return {
        ...state,
        connection: {state: READY},
      }
    }
    case actions.RECEIVED_PRESENCE_STATE: {
      return {
        ...state,
        users: receivedPresenceState([...state.users, ...action.data]),
      }
    }
    case actions.RECEIVED_PRESENCE_DATA: {
      if (action.data && action.data.is_online) {
        return {
          ...state,
          users: [...state.users, action.data],
        }
      } else {
        return {
          ...state,
          users: disconnectHandler(action.data, ...state.users),
        }
      }
    }
    case actions.PRESENCE_CONNECTED: {
      return {
        ...state,
        users: [...state.users, action.presence],
      }
    }
    case actions.PRESENCE_DISCONNECTED: {
      return {
        ...state,
        users: disconnectHandler(action.presence, ...state.users),
      }
    }
    case actions.FETCH_PRESENCE_SUCCESS: {
      return {
        ...state,
        users: action.presence,
      }
    }
    case actions.FETCH_PRESENCE_FAILURE: {
      return {
        ...state,
        errors: [...state.errors, action.error],
      }
    }
    default: {
      return state
    }
  }
}
