import React, {useRef, useState} from 'react'
import './Nota.css'
import Modal from '../Modal'
import PostEditor from './PostEditor/PostEditor'
import editorStyles from './PostEditor/editorStyles.module.css'
import Calendar from '../Calendar/Calendar'
import {
  getNumbers,
  construirFecha,
  getDateString,
} from '../../lib/coco/generalHelpers'
import {createNote} from '../../lib/coco/itemsApi'
import useBooleanToggler, {useWorkingIndicator} from '../../hooks/useBooleanToggler'
import BotonConBordeDegradado from '../Buttons/BotonConBordeDegradado'
import BottonSelectorDomains from '../Shared/BottonSelectorDomains'
import SelectDomains from '../Shared/SelectDomains'
import {Colors} from '../Shared/helpers'
import useDomain from '../../hooks/useDomain'
import useAlert, {TOAST_TYPE} from '../../hooks/useAlert'
import Done from '../Shared/Done'
import {getAllProgress} from '../../Redux/users/actions'
import {useDispatch} from 'react-redux'

const today = new Date()

const Nota = () => {
  const {showMessage} = useAlert()
  const [notaAgregada, setNotaAgregada] = useState(false)
  const [selectedDate, setSelectedDate] = useState(today)
  const [calendar, setCalendar] = useState(false)
  const {isWorking, startWork, finishWork} = useWorkingIndicator()
  const titleRef = useRef()
  const mentionsRef = useRef()
  const {isToggled: isToggledDomains, toggle: toggleDomains} = useBooleanToggler()

  const {domainActive} = useDomain()
  const [domainSelect, setDomainSelect] = useState(domainActive)
  const dispatch = useDispatch()

  const showCalendar = () => {
    setCalendar(true)
  }

  const closeCalendar = () => {
    setCalendar(false)
  }

  const getDate = (date) => {
    const todayString = construirFecha(today)

    const selectedDate = construirFecha(date)

    if (new Date(selectedDate) < new Date(todayString)) {
      showCalendar()
      showMessage(
        `Elija otra fecha igual o posterior a hoy ${todayString}`,
        TOAST_TYPE.WARNING,
      )
    } else {
      setSelectedDate(date)
      closeCalendar()
    }
  }

  const crearNota = () => {
    if (!domainSelect) {
      showMessage('No hay un dominio seleccionado', TOAST_TYPE.WARNING)
      return
    }

    const title = titleRef.current.value
    const body = mentionsRef.current.toHtml()

    const matches = body.match(/<span[^>]*>[^>]*<\/span>/gi)

    const ids = matches?.map(function (element) {
      return parseInt(getNumbers(element))
    })

    const atUsersIds = Array.from(new Set(ids ? ids : []))

    if (
      title?.trim()?.length > 0 &&
      body?.trim()?.length > 0 &&
      selectedDate >= today
    ) {
      startWork()
      createNote(title, body, atUsersIds, selectedDate, domainSelect.id).then(
        (response) => {
          finishWork()
          const {status} = response
          if (status) {
            disposeModal()
            titleRef.current.value = ''
            mentionsRef.current.reset()
            setSelectedDate(today)
            dispatch(getAllProgress())
          } else if (response?.message) {
            showMessage(response.message, TOAST_TYPE.ERROR)
          }
        },
      )
    } else {
      titleRef.current.value = title // por algun motivo el ref value se elimina.
      showMessage(
        'La nota debe de tener un Title y un Body. Si elejiste una fecha, comprueba que esta sea mayor o igual a hoy',
        TOAST_TYPE.WARNING,
      )
    }
  }

  const disposeModal = () => {
    setNotaAgregada(true)
    setTimeout(function () {
      cerrarNotaAgregada()
    }, 5000)
  }

  const cerrarNotaAgregada = () => {
    setNotaAgregada(false)
  }

  return (
    <>
      <div className="create__note__container">
        <h1 id="main-title">Agregar una nota</h1>

        <input
          ref={titleRef}
          id="note-title"
          type="text"
          placeholder="Título"
          className="px-3 py-2 mb-3"
        />

        <div className={`${editorStyles.editor}`}>
          <PostEditor placeholder="Nota" ref={mentionsRef} get />

          <div id="selector-languages-editor">
            {isToggledDomains ? (
              <div className="selector-languages-editor-list">
                <SelectDomains
                  domainSelect={domainSelect}
                  setDomainSelect={setDomainSelect}
                  onClose={toggleDomains}
                  theme={Colors.LIGTH}
                />
              </div>
            ) : (
              <BottonSelectorDomains
                domainSelect={domainSelect}
                onClick={toggleDomains}
                color={Colors.DARK}
              />
            )}
          </div>

          <div id="calendario-y-arroba" className="mb-2 mr-2" onClick={showCalendar}>
            <div className="date-string">
              {selectedDate && getDateString(selectedDate)}
            </div>
            <img src="/images/Notas/Calendar.svg" alt="" />
          </div>
        </div>
        <BotonConBordeDegradado
          function={crearNota}
          disabled={isWorking}
          width={150}
          height={37}
          style={{marginTop: '15px'}}>
          <span>{isWorking ? 'loading...' : 'Agregar'}</span>
        </BotonConBordeDegradado>
      </div>

      {notaAgregada && (
        <Modal
          modalType="nota-agregada"
          show={notaAgregada}
          closeModal={cerrarNotaAgregada}>
          <Done message={'Nota Agregada'} />
        </Modal>
      )}

      {calendar && (
        <Modal
          modalType="calendario_modal"
          show={calendar}
          closeModal={closeCalendar}
          closeRound>
          <Calendar getDate={getDate} />
        </Modal>
      )}
    </>
  )
}

export default Nota
