import React from 'react'
import {useIntl} from 'react-intl'
import './SelecctorTypesConversation.css'

// se comento los hilos
// props
// setHilosClose,
// isHilosActive,
// setHilosOpen,

const SelecctorTypesConversation = () => {
  const intl = useIntl()
  return (
    <div className="selector-tipos-conversaciones">
      {/* <span
        onClick={setHilosOpen}
        className={`selector-tipos-conversaciones-btn ${
          isHilosActive ? 'tipos-conversaciones-activa' : ''
        }`}>
        Hilos
      </span> */}
      <span
        // onClick={setHilosClose}
        className={`selector-tipos-conversaciones-btn`}>
        {intl.formatMessage({
          id: 'chat.title',
          defaultMessage: 'Chat',
        })}
      </span>
    </div>
  )
}

export default SelecctorTypesConversation
