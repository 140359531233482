import React from 'react'
import {connect} from 'react-redux'
import AudioRecorder from './Audio/AudioRecorder'
import ListEmojis from './ListEmojis'
import IconSendMessage from '../../Icons/sendMessage.svg'
import IconLock from './Icons/lock.svg'
import IconEmogi from './Icons/emogi.gif'
import './NewMessageForm.css'

import {isTyping, stoppedTyping} from '../../../Redux/Conversations/actions'
import ImageUpload from './Upload/ImageUpload'
import {createMessage, createSource} from '../../../lib/coco/conversationApi'
import {ALERT_TYPES, smallAlert} from '../../../Services/Alert'
import TextareaAutosize from 'react-autosize-textarea'
import {StatusApi} from '../../../lib/coco/helperData'
import TypingIndicator from './TypingIndicator'
import {isConversationHilo, isConversationResolved} from '../helpers'
import {useIntl} from 'react-intl'

const IsTypinUsers = ({typing_participants = []}) => {
  const intl = useIntl()
  const getNames = () => {
    return typing_participants.map((p) => p.name || p.email).join(', ')
  }
  return (
    <>
      {typing_participants?.length > 0 && (
        <div className="common-message-typing_participants-container">
          <small className="common-message-typing_participants">
            {`${getNames()}
              ${intl.formatMessage({
                id: 'chat.is-typing',
                defaultMessage: 'is typing',
              })}
            `}

            <TypingIndicator />
          </small>
        </div>
      )}
    </>
  )
}

const IsHiloClose = () => {
  return (
    <div className="hilo-closed-title">
      <img src={IconLock} alt="hilo cerrado" />
      <span>El hilo está cerrado.</span>
    </div>
  )
}

const WriteMessage = ({
  newMessageRef,
  textMessage,
  handleKeyPress,
  handleChange,
  isMiniChat,
}) => {
  const intl = useIntl()
  return (
    <TextareaAutosize
      ref={newMessageRef}
      value={textMessage}
      onKeyPress={handleKeyPress}
      onChange={handleChange}
      style={{width: '100%'}}
      className={`text-area-send-message ${
        isMiniChat
          ? 'text-area-send-message-border-minichat'
          : 'text-area-send-message-border-docuchat'
      }`}
      placeholder={intl.formatMessage({
        id: 'chat.whrite-message',
        defaultMessage: 'Write messages...',
      })}
      autoFocus
    />
  )
}

class NewMessageForm extends React.Component {
  constructor() {
    super()

    this.state = {
      img_blob: null,
      blob_url: '',
      conversation: null,
      participant: null,
      emailMatches: [],
      lastKeyPress: 0,
      lastTickTime: 0,
      typingTimeout: 500,
      timeStamp: Date.now(),
      showEmogis: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const {
      currentUser,
      conversation_id,
      conversations,
      autoFocusMessageInput,
    } = this.props
    const conversation = conversations.find(
      (conversation) => conversation.id === conversation_id,
    )

    const {participating_users} = conversation
    const participant = participating_users.find((u) => u.id === currentUser?.id)

    this.setState({
      conversation: conversation,
      participants: participating_users,
      participant: participant,
    })

    if (participant) this.typingTimeout = setInterval(() => this.tick(), 100)

    if (autoFocusMessageInput) autoFocusMessageInput()
  }

  toogleEmogis = () => {
    this.setState({showEmogis: !this.state.showEmogis})
  }

  handleChange = (e) => {
    const {setTextMessage} = this.props
    const text = e.target.value
    // define other method than email matching
    // let peopleMatches = [//]

    //TODO: SUBRAYAR ESTOS EN EL TEXTO MIENTRAS ESCRIBAN
    let emailMatches =
      text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi) || []

    setTextMessage(text)
    this.setState({
      emailMatches: emailMatches,
      showEmogis: false,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      conversation_id,
      currentUser,
      dispatch,
      messageCitado,
      removeCitedMessage,
      textMessage,
      setTextMessage,
    } = this.props

    const {img_blob, emailMatches, participant} = this.state

    if (textMessage === '' && !img_blob) {
      return
    }

    if (participant && currentUser.id === participant.id) {
      dispatch(stoppedTyping(participant, conversation_id))
    }

    // text: markdown.toHTML(text), por si se quiere implementar markdown
    const data = {
      text: textMessage,
      email_mentions: emailMatches,
      conversation_id,
      user_id: currentUser.id,
      parent_id: messageCitado?.id ? messageCitado?.id : null,
    }

    // enviar un mensaje
    createMessage(data).then((response) => {
      if (response.status === StatusApi.OK) {
        setTextMessage('')
        this.setState({showEmogis: false})
        removeCitedMessage()
      } else {
        smallAlert(ALERT_TYPES.ERROR, `${response.message}`)
      }
    })
  }

  seleccionarEmoji = (emoji) => {
    const {setTextMessage, textMessage} = this.props
    setTextMessage(`${textMessage}${emoji.character}`)
  }

  handlePaste = (evt) => {
    evt.persist()

    let items = [],
      blob = null
    items = evt.clipboardData.items
    for (var i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') === -1) continue
      blob = items[i].getAsFile()
    }

    if (blob) {
      this.setState({img_blob: blob}, () =>
        this.setState({blob_url: URL.createObjectURL(blob)}),
      )
      let data = new FormData()
      data.append('image', blob)
      data.append('conversation_id', this.props.conversation_id)
      data.append('text', `${blob.name}`)

      // create paste image
      createSource(data)
    }
  }

  tick = () => {
    const {conversation_id, dispatch} = this.props
    const {participant, lastKeyPress, lastTickTime, typingTimeout} = this.state

    if (!participant) return

    this.setState({lastTickTime: Date.now()})

    if (
      participant &&
      participant.is_typing &&
      lastTickTime - lastKeyPress >= typingTimeout
    ) {
      this.setState({participant: {...participant, is_typing: false}}, () =>
        dispatch(stoppedTyping(participant, conversation_id)),
      )
    }
  }

  handleKeyPress = (event) => {
    const {conversation_id, dispatch} = this.props
    const {participant} = this.state

    if (event?.which === 13) {
      if (!event.shiftKey) this.handleSubmit(event)
      return
    }

    if (!participant) return

    this.setState({lastKeyPress: Date.now()}, () => {
      if (participant && !participant.is_typing) {
        this.setState({participant: {...participant, is_typing: true}}, () =>
          dispatch(isTyping(participant, conversation_id)),
        )
      }
    })
  }

  componentWillUnmount() {
    clearInterval(this.typingTimeout)
  }

  isHiloResolved = () => {
    const {conversation} = this.props

    if (isConversationHilo(conversation) && isConversationResolved(conversation)) {
      return true
    } else {
      return false
    }
  }

  renderMessageCited = () => {
    const {messageCitado, removeCitedMessage} = this.props

    if (messageCitado?.id) {
      const {user = {name: '', email: ''}} = messageCitado
      return (
        <div className="message-cited-container">
          <button className="message-cited-btn-close" onClick={removeCitedMessage}>
            x
          </button>
          <p className="message-cited-user-name">{user?.name || user?.email}</p>

          <div className="message-cited-text">{messageCitado.text}</div>
        </div>
      )
    }
  }

  render = () => {
    const {showEmogis} = this.state
    const {
      isMiniChat,
      typing_participants,
      currentUser,
      newMessageRef,
      autoFocusMessageInput,
      textMessage,
    } = this.props

    const isHiloResolved = this.isHiloResolved()

    return (
      <>
        <IsTypinUsers
          typing_participants={typing_participants.filter(
            (p) => p.id !== currentUser?.id,
          )}
        />
        {!isHiloResolved && this.renderMessageCited()}
        {!isHiloResolved ? (
          <div
            className={`new-conversation-form ${
              isMiniChat
                ? 'new-conversation-form-minichat'
                : 'new-conversation-form-docuchat'
            }`}
            onPasteCapture={this.handlePaste}>
            <form
              className={`formulario-chat ${
                isMiniChat ? 'formulario-minichat' : 'formulario-chat-docuchat'
              }`}
              onSubmit={this.handleSubmit}>
              <div onClick={this.toogleEmogis}>
                <img className="face-emogis-chat" src={IconEmogi} alt={IconEmogi} />
              </div>
              {showEmogis && (
                <ListEmojis
                  seleccionarEmoji={this.seleccionarEmoji}
                  toogleEmogis={this.toogleEmogis}
                  autoFocusMessageInput={autoFocusMessageInput}
                />
              )}

              <WriteMessage
                handleChange={this.handleChange}
                handleKeyPress={this.handleKeyPress}
                newMessageRef={newMessageRef}
                textMessage={textMessage}
                isMiniChat={isMiniChat}
              />
            </form>

            <div style={{marginBottom: '8px'}}>
              {textMessage?.length > 0 ? (
                <button
                  onClick={this.handleSubmit}
                  className="btn-send-message-audio-form">
                  <img src={IconSendMessage} alt={IconSendMessage} />
                </button>
              ) : (
                <AudioRecorder
                  conversation_id={this.props.conversation_id}
                  widthAudio="32px"
                />
              )}
            </div>

            <ImageUpload conversation_id={this.props.conversation_id} />
          </div>
        ) : (
          <IsHiloClose />
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  conversations: state.conversations.conversations,
})

export default connect(mapStateToProps)(NewMessageForm)
