import {ConversationType} from '../../lib/coco/helperData'

const clockTime = (datetime) => {
  let time = '',
    date = '',
    now = new Date(),
    d = new Date(datetime)

  date =
    (now.getDate() > d.getDate() ? `${d.getMonth() + 1}/${d.getDate()}` : '') +
    (now.getYear() > d.getYear() ? `/${d.getYear}` : '')
  date = date !== '' ? date + ' ' : date
  time =
    (d.getHours() > 12
      ? d.getHours() - 12
      : d.getHours() === 0
      ? 12
      : d.getHours()) +
    ':' +
    (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()) +
    (d.getHours() < 12 ? ' am' : ' pm')

  return (date === '' ? '' : `${date} `) + time //esta forma daba errores
  // return (date === "" ? "" : `${time}`);
}

const getMessagesNotReadedConversation = (messages = [], currentUserId) => {
  if (messages?.length > 0) {
    try {
      const result = messages.filter(
        (message) => !message?.read_by_user_ids.includes(currentUserId),
      )
      return result
    } catch (error) {
      console.debug(error)
      return []
    }
  } else {
    return []
  }
}

// ordena conversaciones por fecha de update
const getSortedConversations = (conversations = []) => {
  if (conversations?.length > 0) {
    return conversations.sort(
      (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
    )
  }

  return []
}

const isReadedConversation = (conversation, currentUserId) => {
  if (conversation?.id) {
    try {
      const {messages = []} = conversation
      const result = messages.some(
        (message) => !message?.read_by_user_ids.includes(currentUserId),
      )
      return !result
    } catch (error) {
      console.debug(error)
      return false
    }
  } else {
    return false
  }
}

// cuando hay mensajes en cualquier conversacion sin leer
const thereAreNewConversationsOrMessages = (conversations = [], currentUserId) => {
  if (conversations?.length > 0) {
    return conversations.filter(
      (conver) => !isReadedConversation(conver, currentUserId),
    )
  } else {
    return []
  }
}

const isConversationPersonal = (conversation) =>
  conversation?.conversation_type === ConversationType.PERSONAL

const isConversationGroup = (conversation) =>
  conversation?.conversation_type === ConversationType.GROUP

const isConversationHilo = (conversation) =>
  conversation?.conversation_type === ConversationType.HILO

const getOtherParticipantPersonal = (conversation, currentUserId) => {
  if (conversation?.participating_users) {
    const {participating_users = []} = conversation
    const participantUser = participating_users.find(
      (user) => user.id !== currentUserId,
    )
    return participantUser
  }
  return null
}

const getConversationWithTeacherMichael = (conversations, currentUserId) => {
  return conversations.find((c) => {
    const participant = getOtherParticipantPersonal(c, currentUserId)
    if (
      participant?.email === 'michaelsking1993@gmail.com' &&
      c?.conversation_type === ConversationType.PERSONAL
    ) {
      return c
    }
    return null
  })
}

const getFirtMessage = (conversation) => {
  if (conversation?.messages?.length > 0) {
    return conversation.messages[conversation.messages.length - 1]
  }
  return null
}

const isConversationResolved = (conversation) => {
  if (conversation?.resolved) {
    return true
  } else {
    return false
  }
}

const getNamesFromParticipants = (conversation) => {
  if (conversation?.participating_users) {
    const {participating_users = []} = conversation
    return participating_users.map((p) => p?.name || p?.email).join(', ')
  }
  return ''
}

const TypeDocuchats = {
  MINI_CHAT: 'MINI_CHAT',
  DOCUCHAT: 'DOCUCHAT',
}

const TypeCreateConversation = {
  GRUPO: 'GRUPO',
  PERSONAL: 'PERSONAL',
}

const isUserWasAdd = (users = [], userId) => {
  if (users?.length > 0) {
    const isUserInclude = users.find((u) => u?.user_id === userId)
    if (isUserInclude?.user_id) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

const isTeacherInDomainId = (domains, domainId) => {
  const dominioDelItem = domains.find((domain) => domain?.id === domainId)
  if (dominioDelItem?.is_teacher) {
    return true
  } else {
    return false
  }
}

const getUrlFileImage = (file) => {
  return URL.createObjectURL(file)
}

const isValidFormatImage = (imageFile) => {
  return /image.*/.test(imageFile?.type)
}

const getConversationImage = (conversation) => {
  if (conversation?.image?.url?.length > 0) {
    return conversation?.image?.url
  } else {
    return null
  }
}

// busca el mensaje en el scroll
const goToScrollIntoView = (idMessage) => {
  const element = document.getElementById(`message-${idMessage}`)

  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    })
    element.className = 'message-citado-temporal'
    element.style.transition = '1s all'
    changeColorsBackgroud(element)
  }
}

const changeColorsBackgroud = (element) => {
  setTimeout(() => {
    element.className = ''
  }, 2000)
}

export {
  clockTime,
  TypeDocuchats,
  TypeCreateConversation,
  thereAreNewConversationsOrMessages,
  isConversationPersonal,
  isConversationGroup,
  isConversationHilo,
  isReadedConversation,
  getSortedConversations,
  getMessagesNotReadedConversation,
  getOtherParticipantPersonal,
  getConversationWithTeacherMichael,
  getNamesFromParticipants,
  getFirtMessage,
  isUserWasAdd,
  isConversationResolved,
  isTeacherInDomainId,
  getUrlFileImage,
  isValidFormatImage,
  getConversationImage,
  goToScrollIntoView,
}
