const EMPTY_PLAYLISTS_DATA = {
  myLike: {
    author_count: 0,
    song_count: 0,
  },
  playlists: [],
}

const PLAYLIST_EMPTY = {
  id: 0,
  image_url: '',
  name: '',
  song_count: 0,
  tracks: [],
}

const EMPTY_ERROR = {
  message: '',
}

// GET ALL PLAYLIST **
const FETCH_PLAYLISTS_REQUEST_START = 'playlists/FETCH_PLAYLISTS_REQUEST_START'
const FETCH_PLAYLISTS_REQUEST_FINISH = 'playlists/FETCH_PLAYLISTS_REQUEST_FINISH'
const FETCH_PLAYLISTS_REQUEST_ERROR = 'playlists/FETCH_PLAYLISTS_REQUEST_ERROR'

// GET PLAYLIST BY ID **
const FETCH_PLAYLIST_BY_ID_REQUEST_START =
  'playlists/FETCH_PLAYLIST_BY_ID_REQUEST_START'
const FETCH_PLAYLIST_BY_ID_REQUEST_FINISH =
  'playlists/FETCH_PLAYLIST_BY_ID_REQUEST_FINISH'
const FETCH_PLAYLIST_BY_ID_REQUEST_ERROR =
  'playlists/FETCH_PLAYLIST_BY_ID_REQUEST_ERROR'

// remove track of playlist
const DELETE_TRACK_OF_PLAYLIST = 'playlists/DELETE_TRACK_OF_PLAYLIST'

const ADD_PLAYLIST = 'playlists/ADD_PLAYLIST'
const ADD_ONE_TO_PLAYLIST = 'playlists/ADD_ONE_TO_PLAYLIST'
const UPDATE_TRACKS_PLAYLIST_FROM_LIST = 'playlists/UPDATE_TRACKS_PLAYLIST_FROM_LIST'

export {
  FETCH_PLAYLISTS_REQUEST_START,
  FETCH_PLAYLISTS_REQUEST_FINISH,
  FETCH_PLAYLISTS_REQUEST_ERROR,
  FETCH_PLAYLIST_BY_ID_REQUEST_START,
  FETCH_PLAYLIST_BY_ID_REQUEST_FINISH,
  FETCH_PLAYLIST_BY_ID_REQUEST_ERROR,
  DELETE_TRACK_OF_PLAYLIST,
  UPDATE_TRACKS_PLAYLIST_FROM_LIST,
  ADD_PLAYLIST,
  ADD_ONE_TO_PLAYLIST,
  EMPTY_PLAYLISTS_DATA,
  PLAYLIST_EMPTY,
  EMPTY_ERROR,
}
