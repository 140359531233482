import React from 'react'
import useBooleanToggler from '../../hooks/useBooleanToggler'
import LayoutSetting from './LayoutSetting'
import ConfiguracionIcon from './icons/Configuracion.svg'
import Modal from '../Modal/Modal'

const SettingButtons = () => {
  const {isToggled, toggle} = useBooleanToggler()

  return (
    <>
      <button className="link-mis-cosas" onClick={toggle}>
        <img src={ConfiguracionIcon} alt="Mis-cosas" />
      </button>

      {isToggled && (
        <Modal show={isToggled} modalType="modal__setting__acount">
          <LayoutSetting closeModal={toggle} />
        </Modal>
      )}
    </>
  )
}

export default SettingButtons
