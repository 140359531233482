import React from 'react'
import './CocoPhrases.css'
import IconSolicitudEnviada from './Icons/IconSolicitudEnviada.svg'
import Axios from 'axios'
import {PEDIR_FRASES} from '../../Services/EndPointApi'
import {errorHandler, getHeaders} from '../../lib/coco/helperData'
import {useIntl} from 'react-intl'

const CocoPhrases = (props) => {
  const {requestExists, setRequestExists, word} = props

  const intl = useIntl()

  const pedirFrases = () => {
    Axios.post(`${PEDIR_FRASES}`, {word: word}, {headers: getHeaders(true)})
      .then((response) => {
        if (response.data) {
          if (response.data.message === 'éxito') setRequestExists(true)
          else setRequestExists(false)
        }
      })
      .catch(errorHandler('UpdateProfile'))
  }

  return (
    <div>
      <p id="first-text" className="mb-3">
        {intl.formatMessage({
          id: 'diccionary.not-result',
          defaultMessage: 'There are no coconut results for your search.',
        })}
      </p>

      <p id="second-text" className="mb-4">
        {intl.formatMessage({
          id: 'diccionary.not-result-description',
          defaultMessage:
            'Ask teachers for phrases so you and more students can learn better.',
        })}
      </p>

      {/*
        Otro estudiante ya ha solicitado frases con ese término, revisa, pronto habrá ejemplos de Coco.
      */}

      <div className="d-flex justify-content-center">
        {!requestExists ? (
          <button
            className="btn-orange-gradient-linear-outline btn-lg"
            onClick={pedirFrases}>
            {intl.formatMessage({
              id: 'diccionary.ask-for-phrases',
              defaultMessage: 'Ask for phrases from Coco',
            })}
          </button>
        ) : (
          //Una apariencia de botón
          <button className="btn-solicitud-de-frases-enviada btn-lg">
            {intl.formatMessage({
              id: 'diccionary.ask-for-phrases-success',
              defaultMessage: 'Request sent',
            })}
            <span>
              <img src={IconSolicitudEnviada} alt={IconSolicitudEnviada} />
            </span>
          </button>
        )}
      </div>
    </div>
  )
}

CocoPhrases.propTypes = {}

export default CocoPhrases
