import React, {useState, useEffect} from 'react'
import './Traductor.css'
import TraductorIcon from '../FlashCards/IconsPlusButton/traductor.svg'
import {
  getTranslatorAndExamples,
  loadingPhrase,
} from '../../Redux/Translator/actions'
import {useDispatch} from 'react-redux'
import ExampleSentences from './ExampleSentences'
import ModalFlashcardPreview from '../FlashCards/ModalFlashcardPreview'
import {RemoverParentesis} from '../../Services/CadenasSplit'
import BotonTraductorLoading from '../Buttons/BotonTraductorLoading'
import useTranslator from '../../hooks/useTranslator'
import useUser from '../../hooks/useUser'
import {useIntl} from 'react-intl'

const Traductor = () => {
  const intl = useIntl()

  //dispatch global
  const dispatch = useDispatch()
  const {phrase, translation, is_loading, example_sentences} = useTranslator()
  const {idioma_activo, idioma_nativo} = useUser()

  // datos locales
  const [fromLanguage, setFromLanguage] = useState({})
  const [toLanguage, setToLanguage] = useState({})
  const [oracionselect, setOracionselect] = useState('')
  const [showModalFlashcard, setShowModalFlashcard] = useState(false)

  useEffect(() => {
    setFromLanguage(idioma_nativo)
    setToLanguage(idioma_activo)
  }, [idioma_nativo, idioma_activo])

  const addFlashcard = (example, translation) => {
    example = RemoverParentesis(example)
    translation = RemoverParentesis(translation)

    setShowModalFlashcard(true)
    setOracionselect({
      example: example,
      translation: translation,
    })
  }

  const handleClose = () => {
    setShowModalFlashcard(false)
    setOracionselect('')
  }

  const reverseLanguage = () => {
    setFromLanguage(toLanguage)
    setToLanguage(fromLanguage)
  }

  const eventEnter = (event) => {
    if (event.which === 13) {
      dispatch(getTranslatorAndExamples(phrase, fromLanguage, toLanguage))
    }
  }

  return (
    <div className="contendor-plusbutton-traductor">
      {showModalFlashcard ? (
        <div className="contendor-mensaje-flashcard">
          <ModalFlashcardPreview
            showModalFlashcard={showModalFlashcard}
            palabra={{
              token: {
                term_id: '',
                form: '',
                lemma: '',
                part_of_speech: '',
                form_tag: '',
              },
            }}
            oracion={oracionselect}
            handleClose={handleClose}
            hideModal={handleClose}
            closeModalFlashcardPreview={handleClose}
          />
        </div>
      ) : (
        <>
          <div className="contenedor-traductor-general">
            <div className="contenedor-traductor-frase">
              <div className="contenedor-traductor-header">
                <div className="traductor-header-idioma-activo">
                  {/* idioma seleccionado */}
                  <span>{fromLanguage?.name || ''}</span>
                </div>

                <div className="traductor-header-cambiar-idioma">
                  <button className="btn" onClick={reverseLanguage}>
                    <img src={TraductorIcon} alt="change" />
                  </button>
                </div>
              </div>
              <textarea
                className="contenedor-traductor-text-area"
                placeholder={intl.formatMessage({
                  id: 'flashcard.enter',
                  defaultMessage:
                    'Ingresa la letra de la canción en su idioma original.',
                  description:
                    'Ingresa la letra de la canción en su idioma original.',
                })}
                onChange={(e) => dispatch(loadingPhrase(e.target.value))}
                onKeyPress={eventEnter}
                value={phrase}
              />
            </div>

            <div className="contenedor-traductor-traducir">
              <BotonTraductorLoading
                onClick={() =>
                  dispatch(
                    getTranslatorAndExamples(phrase, fromLanguage, toLanguage),
                  )
                }
                is_loading={is_loading}
                type="traductor"
              />
            </div>

            <div className="contenedor-traductor-result text-left">
              <div className="contenedor-traductor-header">
                <div className="traductor-header-idioma-traductor">
                  {/* idioma a traducir */}
                  <span>{toLanguage?.name || ''}</span>
                </div>
              </div>
              <textarea
                className="contenedor-traductor-text-area-result"
                value={translation}
              />
            </div>
          </div>

          <div className="contenedor-frases-ejemplos">
            {example_sentences?.real_examples?.length > 0 && (
              <ExampleSentences
                example_sentences={example_sentences}
                addFlashcard={addFlashcard}
                fuente="linguee"
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Traductor
