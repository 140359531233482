import React, {useState, useRef} from 'react'
import './Peculiaridades.css'
import CheckPeculiaridad from './CheckPeculiaridad'
import useClickOutside from '../../hooks/useClickOutside'

const PeculiaridadesMultiSelect = ({
  seleccionar_tag,
  eliminar_tag,
  isTagActive,
  side,
  titulo,
  is_active,
  tipo,
  lang_tags,
}) => {
  const [isShowRegions, setIsShowRegions] = useState(false)

  const myRef = useRef()

  useClickOutside(myRef, () => handleClickOutside())

  const handleClickOutside = () => {
    if (isShowRegions) {
      setIsShowRegions(false)
    }
  }

  const handleCheckboxChange = (REGION) => {
    // si ya esta agregada le elimina sino se agrega
    const funcion_tag = isTagActive(REGION, side) ? eliminar_tag : seleccionar_tag
    if (funcion_tag) {
      funcion_tag(REGION, side)
    }
  }

  return (
    <span className="contenedor-peculiaridades-multiselect" ref={myRef}>
      {
        <button
          className={`btn-peculiaridades btn-${tipo} ${
            is_active && `btn-${tipo}-active`
          } ${side === 'back' && 'black'}`}
          onClick={() => setIsShowRegions(!isShowRegions)}>
          {`#${titulo}`} <span className="region-icon">{'>'}</span>
        </button>
      }

      {isShowRegions && (
        <div className="lista-peculiaridades-multiselect">
          <div className="peculiaridades-multiselect-body">
            <ul className="peculiaridades-listado">
              {lang_tags &&
                lang_tags.length > 0 &&
                lang_tags.map((region, index) => (
                  <li key={index} className="peculiaridades-multiselect-item">
                    <CheckPeculiaridad
                      onClick={() => handleCheckboxChange(region, side)}
                      peculiaridadesChecked={isTagActive(region, side)}
                      titulo={region}
                      tipo={'region'}
                    />
                  </li>
                ))}
            </ul>
          </div>
          <div
            className="peculiaridades-multiselect-footer orange-text-gradient"
            onClick={() => setIsShowRegions(!isShowRegions)}>
            Listo
          </div>
        </div>
      )}
    </span>
  )
}

export default PeculiaridadesMultiSelect
