export default {
  en: {
    'plusbutton.translator': 'Translator',
    'plusbutton.dictionary': 'Dictionary',
    'plusbutton.message-sucess': 'Your flashcard was sent sucessfully!',
    'plusbutton.new-flashcard': 'Add another flashcard',
    'plusbutton.add': 'Add',
    'plusbutton.translate': 'Translate',
    'plusbutton.deck': 'Add a flashcard to your deck.',
    'plusbutton.put': 'Put an image on your flashcard',
    'flashcard.congratulation': 'Congratulations!',
    'flashcard.message': "You finished today's flashcards.",
    'flashcard.dont-know': "I don't know",
    'flashcard.choice': 'Select an option:',
    'flashcard.decorate': 'Decorate Flashcard',
    'flashcard.add': 'Add',
    'flashcard.phrase': 'Add this phrase to your flashcard',
    'flashcard.translate': 'Translate Phrase',
    'flashcard.fill': 'Fill the blank',
    'flashcard.word-fill': 'Select the word to fill in the blank',
    'flashcard.add-new': 'Add a new Flashcard',
    'flashcard.image': 'Search for an image',
    'flashcard.enter': "Enter the song's lyrics in their original language.",
    'flashcard.how': 'How was it?',
    'flashcard.repeat': 'repeat',
    'flashcard.good': 'good',
    'flashcard.hard': 'hard',
    'flashcard.easy': 'easy',
    'message.noshow': 'no show again',
    'message.answer-sucess': 'Great job!',
    'flashcard.phare-alrealy-add': 'The phrase was already added before.',
    'flashcard.error-translate': 'Translation error!',
    'flashcard.listening-now': 'Keep listening',
    'flashcard.add-other': 'Add another phrase',
    'flashcard.add-your-mazo': 'Add a flashcard to your deck',
    'flashcard.send': 'Send',
    'flashcard.send-success-title': 'Your flashcard was sent successfully!',
    'flashcard.send-success-description':
      'A native teacher will review it in less than 24 hours.',
    'flashcard.warning-text':
      'If you add such a long text it will be difficult to memorize. We suggest you make it shorter.',
    'flashcard.duplicate-summary':
      'There is already the same flashcard in your deck',
    'flashcard.domain-select-empty': 'There is no domain selected',
    'flashcard.languages-diferent': 'Languages must be different',
    'flashcard.write-phrase': 'Write a phrase on your flashcard',
    'flashcard.error-exits-db':
      'This flashcard has already been added, add a different one',
    'flashcard.error-create-db': 'Error creating your flashcard, try later',
  },
  es: {
    'plusbutton.translator': 'Traductor',
    'plusbutton.dictionary': 'Diccionario',
    'plusbutton.message-sucess': '¡Tu flashcard fue enviada con éxito!',
    'plusbutton.new-flashcard': 'Agregar otro flashcard',
    'plusbutton.add': 'Agregar',
    'plusbutton.translate': 'Traducir',
    'plusbutton.deck': 'Agregar una flashcard a tu mazo',
    'plusbutton.put': 'Ponle una imágen a tu flashcard',
    'flashcard.congratulation': '¡Felicidades!',
    'flashcard.message': 'terminaste las Flashcards de hoy.',
    'flashcard.dont-know': 'No sé',
    'flashcard.choice': 'Selecciona una opción:',
    'flashcard.decorate': 'Decorar Flashcard',
    'flashcard.add': 'Agregar',
    'flashcard.phrase': 'Agrega esta frase a tus flashcard',
    'flashcard.translate': 'Traducir frase',
    'flashcard.fill': 'Llenar el espacio',
    'flashcard.word-fill': 'Selecciona la palabra a llenar',
    'flashcard.add-new': 'Agregar nueva flashcard',
    'flashcard.image': 'Buscar imagen',
    'flashcard.enter': 'Ingresa la letra de la canción en su idioma original.',
    'flashcard.how': '¿Qué tal le fue?',
    'flashcard.repeat': 'repetir',
    'flashcard.good': 'bien',
    'flashcard.hard': 'dificil',
    'flashcard.easy': 'fácil',
    'message.noshow': 'No mostrar otra vez',
    'message.answer-sucess': '¡Gran Trabajo!',
    'flashcard.phare-alrealy-add': 'La frase ya fue agregada antes.',
    'flashcard.error-translate': '¡Error al traducir!',
    'flashcard.listening-now': 'Seguir escuchando',
    'flashcard.add-other': 'Agregar otra frase',
    'flashcard.add-your-mazo': 'Agrega un flashcard a tu mazo',
    'flashcard.send': 'Enviar',
    'flashcard.send-success-title': '¡Tu flashcard fue enviado con exito!',
    'flashcard.send-success-description':
      'Un profesor nativo lo revisará en menos de 24 hrs.',
    'flashcard.warning-text':
      'Si agregas un texto tan largo será difícil de memorizar. Te sugerimos hacerlo más corto.',
    'flashcard.duplicate-summary': 'Ya hay flashcard igual en tu mazo',
    'flashcard.domain-select-empty': 'No hay un dominio seleccionado',
    'flashcard.languages-diferent': 'Los idiomas deben ser distintos',
    'flashcard.write-phrase': 'Escribe una frase en tu flashcard',
    'flashcard.error-exits-db':
      'Esta flashcard ya ha sido agregada, agrega otra siferente',
    'flashcard.error-create-db': 'Error al crear tu flashcard, intenta mas tarde',
  },
  fr: {
    'plusbutton.translator': 'Traductrice',
    'plusbutton.dictionary': 'Dictionnaire',
    'plusbutton.message-sucess': 'Votre carte flash a été envoyée avec succès!',
    'plusbutton.new-flashcard': 'Ajouter une autre carte flash',
    'plusbutton.add': 'Ajouter',
    'plusbutton.translate': 'Traduire',
    'plusbutton.deck': 'Ajoutez une carte flash à votre deck',
    'plusbutton.put': 'Mettez une image sur votre flashcard',
    'flashcard.congratulation': 'Félicitations!',
    'flashcard.message': "vous avez terminé les cartes mémoire d'aujourd'hui.",
    'flashcard.dont-know': 'Je ne sais pas',
    'flashcard.choice': 'Sélectionnez une option:',
    'flashcard.decorate': 'Décorez Flashcard',
    'flashcard.add': 'Ajouter',
    'flashcard.phrase': 'Ajoutez cette phrase à vos flashcards',
    'flashcard.translate': 'Traduire la phrase',
    'flashcard.fill': "Remplissez l'espace",
    'flashcard.word-fill': 'Sélectionnez le mot à remplir',
    'flashcard.add-new': 'Ajouter une nouvelle carte flash',
    'flashcard.image': 'Rechercher Image',
    'flashcard.enter':
      "Entrez les paroles de la chanson dans leur langue d'origine.",
    'flashcard.how': "Comment c'était?",
    'flashcard.repeat': 'répéter',
    'flashcard.good': 'bien',
    'flashcard.hard': 'dificil',
    'flashcard.easy': 'fácile',
    'message.answer-sucess': 'Excellent travail',
    'flashcard.phare-alrealy-add': 'La phrase a déjà été ajoutée auparavant.',
    'flashcard.error-translate': 'Erreur de traduction!',
    'flashcard.listening-now': 'Continuez à écouter',
    'flashcard.add-other': 'Ajouter une autre phrase',
    'flashcard.add-your-mazo': 'Ajoutez une carte flash à votre deck',
    'flashcard.send': 'Envoyer',
    'flashcard.send-success-title': 'Votre carte mémoire a été envoyée avec succès!',
    'flashcard.send-success-description':
      'Un enseignant natif le révisera en moins de 24 heures.',
    'flashcard.warning-text':
      'Si vous ajoutez un texte aussi long, il sera difficile à mémoriser. Nous vous suggérons de le faire plus court.',
    'flashcard.duplicate-summary': 'Il y a déjà la même carte flash dans votre deck',
    'flashcard.domain-select-empty': 'Il n`y a pas de domaine sélectionné',
    'flashcard.languages-diferent': 'Les langues doivent être différentes',
    'flashcard.write-phrase': 'Écrivez une phrase sur votre carte mémoire',
    'flashcard.error-exits-db':
      'Cette carte mémoire a déjà été ajoutée, ajoutez-en une autre',
    'flashcard.error-create-db':
      'Erreur lors de la création de votre carte mémoire, réessayez plus tard',
  },
}
