import Axios from 'axios'
import {API_ROOT, DELETE_MESSAGE} from '../../Services/EndPointApi'
import {getToken} from '../../Services/getToken'
import {
  errorHandler,
  ApiMessages,
  getHeaders,
  StatusApi,
  ConversationType,
} from './helperData'

const readMessageList = (idsMessages) => {
  return Axios.post(
    `${API_ROOT}/read_messages`,
    {
      read_message_ids: idsMessages,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      return {
        response,
      }
    })
    .catch(errorHandler('readMessageList'))
}

const getConversationById = (conversationId) => {
  return Axios.get(
    `${API_ROOT}/conversations/show_conversation?id=${conversationId}`,
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data) {
        return {
          conversation: response?.data,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('getConversationById'))
}

const verificarItemExist = (conversationId) => {
  return Axios.get(
    `${API_ROOT}/conversations/verificar_item_exist?conversation_id=${conversationId}`,
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.item) {
        return {
          item: response?.data?.item,
        }
      } else {
        return {
          message: ApiMessages.NoContent,
        }
      }
    })
    .catch(errorHandler('verificarItemExist'))
}

const difinirConversation = (
  senderId,
  userSelectedId,
  conversationId,
  titleConversation,
  synopsis,
) => {
  return Axios.post(
    `${API_ROOT}/api/v1/assign_definition`,
    {
      conversation_id: conversationId,
      item: {
        sender_id: senderId,
        user_id: userSelectedId,
        activity_type: 'definir',
        itemizable_type: 'Conversation',
        itemizable_id: conversationId,
        title: `${titleConversation}`,
        state: 0,
        synopsis,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.item) {
        return {
          item: response?.data?.item,
        }
      } else {
        return {
          message: ApiMessages.BadRequest,
        }
      }
    })
    .catch(errorHandler('difinirConversation'))
}

const resolverAndReopenConversation = (
  conversationId,
  isResolvedConversation,
  idCurrentUser,
) => {
  const endpoint = isResolvedConversation ? 'reopen' : 'resolve'

  return Axios({
    method: 'get',
    url: `${API_ROOT}/conversations/${endpoint}?user_id=${idCurrentUser}&conversation_id=${conversationId}`,
    headers: getHeaders(true),
  })
    .then(() => {
      return {
        status: StatusApi.OK,
      }
    })
    .catch(errorHandler('resolverAndReopenConversation'))
}

const addParticipantsToConversation = (conversationId, participantsId = []) => {
  return Axios.post(
    `${API_ROOT}/add_participants`,
    {
      conversation_id: conversationId,
      user_ids: [...participantsId],
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.message) {
        return {
          data: response.data.message,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
          status: StatusApi.BadRequest,
        }
      }
    })
    .catch(errorHandler('addParticipantsToConversation'))
}

// crear conversaciones personales y de grupo
const createConversationPersonal = (
  conversationType = ConversationType.PERSONAL,
  userIds = [],
  title = '',
  imageGroup = null,
) => {
  let data = new FormData()
  data.append('user_ids', JSON.stringify([...userIds]))
  data.append('title', title)
  data.append('conversation_type', conversationType)
  data.append('image', imageGroup)

  return Axios.post(`${API_ROOT}/create_conversation`, data, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data?.id) {
        return {
          conversation: response.data,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
          status: StatusApi.BadRequest,
        }
      }
    })
    .catch(errorHandler('createConversationPersonal'))
}

const createConversation = (title, message, domainId) => {
  return Axios.post(
    `${API_ROOT}/conversations`,
    {
      conversation: {
        title,
        message,
        domain_id: domainId,
        conversation_type: ConversationType.HILO,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.id) {
        return {
          conversation: response.data,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
          status: StatusApi.BadRequest,
        }
      }
    })
    .catch(errorHandler('createConversation'))
}

const createItemHelpForHilo = (conversationId) => {
  return Axios.post(
    `${API_ROOT}/conversations/create_item_help`,
    {
      conversation_id: conversationId,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.item) {
        return {
          item: response.data.item,
        }
      } else {
        return {
          message: ApiMessages.ErrorServer,
        }
      }
    })
    .catch(errorHandler('createItemHelpForHilo'))
}

const createMessage = (data) => {
  return Axios.post(
    `${API_ROOT}/messages`,
    {
      ...data,
    },
    {
      headers: getHeaders(true),
    },
  )
    .then(() => {
      return {
        status: StatusApi.OK,
      }
    })
    .catch(errorHandler('createMessage'))
}

const deleteMessageById = (idMessage) => {
  return Axios.delete(`${DELETE_MESSAGE}${idMessage}`, {
    headers: getHeaders(true),
  })
    .then(() => {
      return {
        status: StatusApi.OK,
      }
    })
    .catch(errorHandler('deleteMessageById'))
}

const createSource = (data) => {
  return fetch(`${API_ROOT}/messages`, {
    method: 'POST',
    headers: {Authorization: getToken()},
    body: data,
  })
}

export {
  readMessageList,
  getConversationById,
  verificarItemExist,
  difinirConversation,
  resolverAndReopenConversation,
  addParticipantsToConversation,
  createConversationPersonal,
  createConversation,
  createItemHelpForHilo,
  createMessage,
  createSource,
  deleteMessageById,
}
