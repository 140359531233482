import React, {Component} from 'react'
import './RevisedFlashcards.css'
import ListadoTags from '../FlashCards/ListadoTags'

export default class RevisedFlashcards extends Component {
  // divide un array en arrays más pequeños, facilitando el poner los flashcards en grupos (/filas) de 2 en el html.
  static trozar(array, size) {
    const arr_trozado = []
    let index = 0
    while (index < array.length) {
      arr_trozado.push(array.slice(index, size + index))
      index += size
    }
    return arr_trozado
  }

  render() {
    const {flashcards} = this.props
    const flashcardsDivididas = RevisedFlashcards.trozar(flashcards, 2)
    return (
      <div className="flashcards-notification-container">
        <div className="revised-flashcards-notification-title">
          Hemos verificado {flashcards.length} flashcards para ti
        </div>
        <div className="revised-flashcards-container">
          {flashcardsDivididas.map((parDeFlashcards, index) => {
            let firstCard = parDeFlashcards[0]
            let secondCard = parDeFlashcards[1]
            let firstFrontHasCorrection =
              firstCard.title !== firstCard.phrase_translation
            let firstBackHasCorrection = firstCard.summary !== firstCard.phrase

            let secondFrontHasCorrection =
              secondCard && secondCard.title !== secondCard.phrase_translation
            let secondBackHasCorrection =
              secondCard && secondCard.summary !== secondCard.phrase

            return (
              <div className="par-de-flashcards-revisadas">
                <div className="verif-card-container">
                  <div className="card-front card-side">
                    {firstFrontHasCorrection && (
                      <span className="raya-de-correccion" />
                    )}
                    <span className="front-text-bad orange-text-gradient">
                      {firstFrontHasCorrection && firstCard.front}
                    </span>
                    <span className="front-text-good orange-text-gradient">
                      {firstCard.phrase_translation}
                    </span>
                    <div className="verif-tags">
                      <ListadoTags
                        tags={firstCard.tags}
                        region_tags={firstCard.region_tags}
                        special_note={firstCard.special_note}
                      />
                    </div>
                  </div>
                  <div className="card-back card-side right-card">
                    {firstBackHasCorrection && (
                      <span className="raya-de-correccion" />
                    )}
                    <span className="back-text-bad">
                      {firstBackHasCorrection && firstCard.summary}
                    </span>
                    <span className="back-text-good">{firstCard.phrase}</span>
                    <div className="verif-tags">
                      <ListadoTags
                        tags={firstCard.trans_tags}
                        region_tags={firstCard.trans_region_tags}
                        special_note={firstCard.trans_special_note}
                      />
                    </div>
                  </div>
                </div>
                {secondCard && (
                  <div className="verif-card-container right-container">
                    <div className="card-front card-side orange-gradient">
                      {secondFrontHasCorrection && (
                        <span className="raya-de-correccion" />
                      )}
                      <span className="front-text-bad orange-text-gradient">
                        {secondFrontHasCorrection && secondCard.front}
                      </span>
                      <span className="front-text-good orange-text-gradient">
                        {secondCard.phrase_translation}
                      </span>
                      <div className="verif-tags">
                        <ListadoTags
                          tags={secondCard.tags}
                          region_tags={secondCard.region_tags}
                          special_note={secondCard.special_note}
                        />
                      </div>
                    </div>
                    <div className="card-back card-side right-card">
                      {secondBackHasCorrection && (
                        <span className="raya-de-correccion" />
                      )}
                      <span className="back-text-bad">
                        {secondBackHasCorrection && secondCard.summary}
                      </span>
                      <span className="back-text-good">{secondCard.phrase}</span>
                      <div className="verif-tags">
                        <ListadoTags
                          tags={secondCard.trans_tags}
                          region_tags={secondCard.region_tags}
                          special_note={secondCard.trans_special_note}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
