import Axios from 'axios'
import {
  COMFIRM_USER_OUTPUT,
  CREATE_USER_OUTPUT_COMMENT,
  DISLIKE_USER_OUTPUT,
  GET_OUTPUT_ITEMS,
  GET_STUDENTS_RESPONSES,
  lIKE_USER_OUTPUT,
  SAVE_USER_OUTPUT,
  UPDATE_UNCORRECTED_USER_OUTPUT,
  USER_OUTPUTS,
  GET_PHRASE_TO_PRONOUNCE,
  // SAVE_NATIVE_AUDIO
} from '../../Services/EndPointApi'
import {ApiMessages, errorHandler, getHeaders} from './helperData'

const getOutputs = () => {
  return Axios.get(GET_OUTPUT_ITEMS, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data) {
        const {output_items, output_to_confirm} = response.data

        return {
          outputItems: output_items,
          outputToConfirm: output_to_confirm,
        }
      } else {
        return {
          message: ApiMessages.ERROR_SERVER,
        }
      }
    })
    .catch(errorHandler('getOutputs'))
}

const saveUserOutput = (userOutput, title, audio = null) => {
  let data = new FormData()
  data.append('title', title)
  data.append('output_item_id', userOutput.output_item_id)
  data.append('body', userOutput.body)
  data.append('audio_output', audio)
  return (
    Axios.post(SAVE_USER_OUTPUT, data, {
      headers: getHeaders(true),
    })
      /*fetch(SAVE_USER_OUTPUT, {
            method: 'POST',
            headers: {"Authorization": getToken()},
            body: data
        })*/
      .then((response) => {
        if (response?.data) {
          return {
            userOutput: response.data,
          }
        } else {
          return {
            message: ApiMessages.ERROR_SERVER,
          }
        }
      })
      .catch(errorHandler('saveUserOutput'))
  )
}

const sendConfirmationUserOutput = (
  idOutput,
  markedSentences,
  needsConfirmation = null,
) => {
  return Axios.put(
    COMFIRM_USER_OUTPUT,
    {
      needs_confirmation: needsConfirmation,
      user_output: {
        id: idOutput,
        marked_sentences: markedSentences,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.user_output) {
        const {user_output: userOutput} = response.data
        return {
          userOutput,
        }
      } else {
        return {
          message: ApiMessages.ERROR_SERVER,
        }
      }
    })
    .catch(errorHandler('sendConfirmationUserOutput'))
}

const updateCorrectionsUserOutputs = (id, body) => {
  return Axios.put(
    `${UPDATE_UNCORRECTED_USER_OUTPUT}${id}`,
    {body},
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data) {
        return {
          correction: response?.data,
        }
      } else {
        return {
          message: ApiMessages.ERROR_SERVER,
        }
      }
    })
    .catch(errorHandler('updateCorrectionsUserOutputs'))
}

const getResponsesOtherStudents = (outputItemId) => {
  return Axios.get(
    `${GET_STUDENTS_RESPONSES}${outputItemId}?user_output_id_notificacion=`,
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.studentResponses) {
        const {studentResponses = []} = response.data
        return {
          studentResponses,
        }
      } else {
        return {
          message: ApiMessages.ERROR_SERVER,
        }
      }
    })
    .catch(errorHandler('getResponsesOtherStudents'))
}

const createCommentUserOutput = (commestText, commentableId, commentableType) => {
  return Axios.post(
    CREATE_USER_OUTPUT_COMMENT,
    {
      comment: {
        comment: commestText,
        commentable_id: commentableId,
        commentable_type: commentableType,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response.data) {
        return {
          comment: response.data,
        }
      } else {
        return {
          message: ApiMessages.ERROR_SERVER,
        }
      }
    })
    .catch(errorHandler('createCommentUserOutput'))
}

const getUserOutputs = () => {
  return Axios.get(`${USER_OUTPUTS}?notification_id=`, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response.data) {
        const {outputs} = response.data
        return {
          outputs,
        }
      } else {
        return {
          message: ApiMessages.ERROR_SERVER,
        }
      }
    })
    .catch(errorHandler('getUserOutputs'))
}

const createUserOutputLike = (userOutputId) => {
  return Axios.post(
    lIKE_USER_OUTPUT,
    {
      user_output_like: {
        user_output_id: userOutputId,
      },
    },
    {
      headers: getHeaders(true),
    },
  )
    .then((response) => {
      if (response?.data?.user_output_like) {
        const {user_output_like: userOutputLike} = response.data
        return {
          userOutputLike,
        }
      } else {
        return {
          message: ApiMessages.ERROR_SERVER,
        }
      }
    })
    .catch(errorHandler('createUserOutputLike'))
}

const deleteUserOutputLike = (userOutputLikeId) => {
  return Axios.delete(`${DISLIKE_USER_OUTPUT}${userOutputLikeId}`, {
    headers: getHeaders(true),
  })
    .then(() => {
      return true
    })
    .catch(errorHandler('deleteUserOutputLike'))
}

const getPhraseToPronounce = () => {
  return Axios.get(GET_PHRASE_TO_PRONOUNCE, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data) {
        const {phrase, quedan} = response.data

        return {
          phrase: phrase,
          remaining_phrases: quedan,
        }
      } else {
        return {
          message: ApiMessages.ERROR_SERVER,
        }
      }
    })
    .catch(errorHandler('getPhraseToPronounce'))
}

const saveNativeAudio = (audio) => {
  let data = new FormData()
  data.append('native_audio', audio)
  return Axios.post(SAVE_USER_OUTPUT, data, {
    headers: getHeaders(true),
  })
    .then((response) => {
      if (response?.data) {
        const {phrase, next_phrase} = response.data

        return {
          phrase: phrase,
          next_phrase: next_phrase,
        }

        // TODO: Si no, deolver un mensaje.
      } else {
        return {
          message: ApiMessages.ERROR_SERVER,
        }
      }
    })
    .catch(errorHandler('saveNativeAudio'))
}

export {
  getOutputs,
  saveUserOutput,
  sendConfirmationUserOutput,
  updateCorrectionsUserOutputs,
  getResponsesOtherStudents,
  createCommentUserOutput,
  getUserOutputs,
  createUserOutputLike,
  deleteUserOutputLike,
  getPhraseToPronounce,
  saveNativeAudio,
}
