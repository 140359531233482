import React from 'react'
import './OtrosUsuarios.css'
import PropTypes from 'prop-types'
import DefaultPhotoUserIcon from '../../Icons/DefaultPhotoUser.svg'

const OtrosUsuarios = ({userResponses, updateUserResponse}) => {
  const getPhotoUser = ({foto}) => {
    if (foto?.url?.length > 0) {
      return foto?.url
    }
    return DefaultPhotoUserIcon
  }

  const UserItem = ({index, userResponse, photo}) => {
    return (
      <div
        id="foto-del-usuario"
        style={{backgroundImage: `url(${photo})`}}
        onClick={() => updateUserResponse(index)}>
        <div id="nombre-del-usuario">
          <span>{userResponse.usuario.name}</span>
        </div>
      </div>
    )
  }

  return (
    <>
      <div id="titulo-de-otros-estudiantes">
        Otros usuarios también contestaron esta pregunta.
      </div>
      <div id="container-other-users">
        {userResponses.map((userResponse, index) => {
          return (
            <UserItem
              key={index}
              index={index}
              userResponse={userResponse}
              photo={getPhotoUser(userResponse)}
            />
          )
        })}
      </div>
    </>
  )
}

OtrosUsuarios.propTypes = {
  userResponses: PropTypes.array.isRequired,
  updateUserResponse: PropTypes.func.isRequired,
}

export default OtrosUsuarios
