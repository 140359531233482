import React from 'react'
import PeculiaridadNotaEspecial from '../PeculiaridadesTags/PeculiaridadNotaEspecial'
import TagView from '../PeculiaridadesTags/TagView'

const ListadoTags = ({tags, region_tags, special_note}) => {
  const isValidTag = (ts) => ts?.length > 0

  return (
    <div style={{display: 'flex', flexFlow: 'wrap'}}>
      {isValidTag(tags) &&
        tags.map((name, index) => <TagView key={index} name={name} />)}
      {isValidTag(region_tags) &&
        region_tags.map((reg_tag, index) => <TagView key={index} name={reg_tag} />)}
      {special_note?.trim()?.length > 0 && (
        <PeculiaridadNotaEspecial tooltip={special_note} />
      )}
    </div>
  )
}

export default ListadoTags
