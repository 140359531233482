import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {
  setAllLanguages,
  setLanguageIdActive,
} from '../Redux/languages/languagesActions'

const useLanguages = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.languages, shallowEqual)
  const {languages = [], languageIdActive, activeUserLanguage} = state

  // dispatch a redux
  const dispatchSetAllLaguages = (
    languages,
    idiomaNativo,
    idiomaActivo,
    interfaceLanguage,
  ) => {
    dispatch(
      setAllLanguages(languages, idiomaNativo, idiomaActivo, interfaceLanguage),
    )
  }

  const dispatchSetLanguageIdActive = (language) => {
    dispatch(setLanguageIdActive(language))
  }
  //   fin dispatchs

  const getLanguageActive = () => {
    if (languages?.length > 0 && languageIdActive) {
      const languageActive = languages.find((lang) => lang.id === languageIdActive)
      if (languageActive) {
        return languageActive
      }
    } else {
      return activeUserLanguage || null
    }
  }

  const isLanguageActiveById = (id) => {
    const {id: idActive} = getLanguageActive()
    if (id === idActive) {
      return true
    }
  }

  const languageActive = getLanguageActive() || {unicode: 'es'}

  return {
    ...state,
    getLanguageActive,
    isLanguageActiveById,
    languages,
    languageActive,
    dispatchSetAllLaguages,
    dispatchSetLanguageIdActive,
  }
}

export default useLanguages
