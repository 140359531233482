import React from 'react'

const SearchConversations = ({placeholder, value, onChange, style = {}}) => {
  return (
    <div className="d-flex justify-content-center" style={style}>
      <input
        type="message-search"
        placeholder={placeholder}
        value={value}
        onChange={({target}) => onChange(target.value)}
      />
    </div>
  )
}

export default SearchConversations
