import React, {useEffect} from 'react'
import AppRoutes from './RoutesCoco/AppRoutes'
import {Provider} from 'react-redux'
import store from './Redux/Store'
import initStore from './Services/InitStore'
import ActionCableConnection from './Components/ActionCable/ActionCableConnection'
import {useWorkingIndicator} from './hooks/useBooleanToggler'
import {verificationUser} from './lib/coco/apis'
import SplashScreen from './Components/Animaciones/SplashScreen'
import {deleteToken} from './Services/getToken'

import IntLProviderMain from './Components/Shared/IntLProviderMain'

const App = () => {
  const {isWorking, startWork, finishWork} = useWorkingIndicator({
    initialValue: true,
  })
  useEffect(() => {
    startWork()
    verificationUser().then((response) => {
      // si la verificación es exitosa actualizamos el usuario y token
      if (response?.authorization && response?.user) {
        const {user, authorization} = response
        initStore(user, authorization)
      } else {
        deleteToken()
      }
      finishWork()
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Provider store={store}>
      <IntLProviderMain>
        {isWorking ? (
          <SplashScreen />
        ) : (
          <ActionCableConnection>
            <AppRoutes />
          </ActionCableConnection>
        )}
      </IntLProviderMain>
    </Provider>
  )
}

export default App
