import React, {lazy, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import LoadingPage from '../Components/Animaciones/LoadingPage'

const RoutePublic = lazy(() => import('../Components/Routes/RoutePublic'))
const RequestAccessForm = lazy(() =>
  import('../Components/Pages/welcome/Splash/RequestAccessForm'),
)
const OnBoarding = lazy(() => import('../Components/Pages/welcome/auth/OnBoarding'))
const Login = lazy(() => import('../Components/Pages/welcome/auth/login'))

const EmailtoReset = lazy(() =>
  import('../Components/Pages/welcome/auth/EmailtoReset'),
)
const ChangePassword = lazy(() =>
  import('../Components/Pages/welcome/auth/ChangePassword'),
)
const Splash = lazy(() => import('../Components/Pages/welcome/Splash/Splash'))

const RoutesWelcome = () => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        <RoutePublic path="/" exact component={Splash} />
        <RoutePublic
          path="/invitations/:token/:email"
          exact
          component={OnBoarding}
        />
        <RoutePublic path="/login" exact component={Login} />
        <RoutePublic path="/welcome" exact component={Splash} />
        <RoutePublic path="/request-access" exact component={RequestAccessForm} />
        <RoutePublic path="/reset_password" exact component={EmailtoReset} />
        <RoutePublic
          path="/reset_password/:token"
          exact
          component={ChangePassword}
        />
        <Route component={() => <Redirect to="/login" />} />
      </Switch>
    </Suspense>
  )
}
export default RoutesWelcome
