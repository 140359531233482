// export const getByValueFromArray = (arr, value) => {
//   const result = arr.find((n) => n?.[value])
//   if (result) {
//     return result[value]
//   } else {
//     return false
//   }
// }

export const filterLinesEmpty = (lines = []) => {
  const linesFilter = lines.filter(
    (line) =>
      line?.line?.trim()?.length !== 0 && line?.translation?.trim()?.length !== 0,
  )
  return linesFilter || []
}

const filterTrackValidateUri = (tracks = []) => {
  return tracks.filter((track) => track?.uri?.trim()?.length > 0)
}

export const removeObjetsDuplicates = (tracks = []) => {
  if (tracks?.length > 0) {
    let hash = []
    const filterTrack = filterTrackValidateUri(tracks)

    filterTrack.filter((currentTrack) => {
      const trackSaved = hash.find((t) => t?.uri === currentTrack?.uri)
      if (!trackSaved) {
        hash = [...hash, currentTrack]
      }
      return currentTrack
    })

    return hash
  }
  return []
}
