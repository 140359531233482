export default {
  en: {
    'diccionary.definitions': 'Definitions',
    'diccionary.others-diccionaries': 'in another dictionary:',
    'diccionary.add-mazo': 'Add to deck',
    'diccionary.ya-add': 'Already added',
    'diccionary.write-word': 'Write a word...',
    'diccionary.search-description': 'Look up a word or expression in the language you are studying.',
    'diccionary.not-result': 'There are no coconut results for your search.',
    'diccionary.not-result-description':
      'Ask teachers for phrases so you and more students can learn better.',
    'diccionary.ask-for-phrases': 'Ask for phrases from Coco',
    'diccionary.ask-for-phrases-success': 'Request sent',
    'diccionary.veried-by': 'Verified by:',
    'diccionary.created-by': 'Created by:',
  },
  es: {
    'diccionary.definitions': 'Definiciones',
    'diccionary.others-diccionaries': 'en otro diccionario:',
    'diccionary.add-mazo': 'Agregar al mazo',
    'diccionary.ya-add': 'Ya agregado',
    'diccionary.write-word': 'Escribe una palabra...',
    'diccionary.search-description': 'Busca una palabra o expresión en el idioma que estás estudiando.',
    'diccionary.not-result': 'No hay resultados de coco para tu búsqueda.',
    'diccionary.not-result-description':
      'Pidele frases a los profesores para que tú y más estudiantes puedan aprender mejor.',
    'diccionary.ask-for-phrases': 'Pedir frases de Coco',
    'diccionary.ask-for-phrases-success': 'Solicitud enviada',
    'diccionary.veried-by': 'Verificada por:',
    'diccionary.created-by': 'Creada por:',
  },

  fr: {
    'diccionary.definitions': 'Définitions',
    'diccionary.others-diccionaries': 'dans un autre dictionnaire:',
    'diccionary.add-mazo': 'Ajouter au deck',
    'diccionary.ya-add': 'Déjà ajouté',
    'diccionary.write-word': 'Écrivez un mot...',
    'diccionary.search-description':
      'Recherchez n`importe quel mot que vous voulez savoir.',
    'diccionary.not-result':
      'Il n`y a aucun résultat de noix de coco pour votre recherche.',
    'diccionary.not-result-description':
      'Demandez aux enseignants des phrases pour que vous et d`autres élèves puissiez mieux apprendre.',
    'diccionary.ask-for-phrases': 'Demandez des phrases à Coco',
    'diccionary.ask-for-phrases-success': 'Demande envoyée',
    'diccionary.veried-by': 'Vérifié par:',
    'diccionary.created-by': 'Créé par:',
  },
}
