import {useEffect, useRef, useState} from 'react'
import useUser from './useUser'

export function useComponentVisible(initialIsVisible, userOutputCorrected) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const [indexCurrentRef, setIndexCurrentRef] = useState(null)
  const {snippetModalStatus, flashcardModalStatus} = useUser()

  const arrayRef = useRef([])

  useEffect(() => {
    arrayRef.current = arrayRef.current.slice(0, userOutputCorrected.length)
  }, [userOutputCorrected])

  const handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      handler()
    }
  }

  const handleClickOutside = (event) => {
    if (
      arrayRef.current[indexCurrentRef] &&
      !arrayRef.current[indexCurrentRef].contains(event.target)
    ) {
      handler()
    }
  }

  const handler = () => {
    if (flashcardModalStatus || snippetModalStatus) {
      return
    } else {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    if (isComponentVisible) {
      document.addEventListener('keydown', handleHideDropdown, true)
      document.addEventListener('click', handleClickOutside, true)
      return () => {
        document.removeEventListener('keydown', handleHideDropdown, true)
        document.removeEventListener('click', handleClickOutside, true)
      }
    }
  })

  return {
    arrayRef,
    isComponentVisible,
    setIsComponentVisible,
    setIndexCurrentRef,
  }
}
