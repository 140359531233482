import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Styles
const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  width: ${(props) => props.externalRadius}px;
  height: ${(props) => props.externalRadius}px;
  background: ${(props) => props.bg};

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    width: ${(props) => props.internalRadius}px;
    height: ${(props) => props.internalRadius}px;
    border: solid 1px #1fb21f;
  }
`

// El componente a exportar, el botón ya estilizado
const YaAgregado = (props) => {
  const {externalRadius, internalRadius, background: bg} = props

  return (
    <Button
      id="btn-ya-agregado"
      bg={bg}
      externalRadius={externalRadius}
      internalRadius={internalRadius}>
      <div className="btn">
        <img
          className="img-fluid w-50"
          src="/images/PalomitaDeYaAgregado.svg"
          alt="Ya agragado"
        />
      </div>
    </Button>
  )
}

// Validación de props
YaAgregado.propTypes = {
  externalRadius: PropTypes.number,
  internalRadius: PropTypes.number,
}

export default YaAgregado
