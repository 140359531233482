import React, {useState} from 'react'
import './SettingsInformation.css'
import useForm from '../../hooks/useForm'
import {updatePassword, updateProfile} from '../../lib/coco/apis'
import useBooleanToggler, {useWorkingIndicator} from '../../hooks/useBooleanToggler'
import ReactPhoneInput from 'react-phone-input-2'
import passwordEditIcon from './icons/passwordEdit.svg'
import AvatarUploader from '../Layouts/MenuItems/AvatarUploader'

import passwordShowIcon from '../Icons/passwordShow.svg'
import passwordCloseIcon from '../Icons/passwordClose.svg'
import backArrowIcon from './icons/backArrow.svg'
import {PASSWORD, TEXT} from '../Pages/welcome/consts/screens'
import useAlert, {TOAST_TYPE} from '../../hooks/useAlert'

const EditPassword = ({toggleEditPassword, intl}) => {
  const {showMessage} = useAlert()

  const initialStatePassword = {
    currentPassword: '',
    newPassword: '',
  }

  const {isWorking, startWork, finishWork} = useWorkingIndicator()

  const [typeInputPassword, setTypeInputPassword] = useState(PASSWORD)
  const [typeInputPasswordConfirm, setTypeInputPasswordConfirm] = useState(PASSWORD)

  const {currentPassword, newPassword, handleChange, cleanForm} = useForm(
    initialStatePassword,
  )

  const changeTypeInputPassword = () => {
    setTypeInputPassword(typeInputPassword === PASSWORD ? TEXT : PASSWORD)
  }

  const changeTypeInputPasswordConfirm = () => {
    setTypeInputPasswordConfirm(
      typeInputPasswordConfirm === PASSWORD ? TEXT : PASSWORD,
    )
  }

  const isValidData = () => {
    if (currentPassword?.trim()?.length >= 6 && newPassword?.trim()?.length >= 6) {
      return true
    }
    return false
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()

    if (!isValidData()) {
      showMessage(
        intl.formatMessage({
          id: 'engrane.edit-password-incorrect',
          defaultMessage: 'The passwords are wrong!',
        }),
        TOAST_TYPE.WARNING,
        false,
      )
      return
    }

    startWork()

    updatePassword(currentPassword, newPassword).then((res) => {
      finishWork()
      if (res?.user) {
        showMessage(
          intl.formatMessage({
            id: 'engrane.edit-password-success',
            defaultMessage: 'Password edited!',
          }),
          TOAST_TYPE.SUCCESS,
          false,
        )
        cleanForm()
      } else {
        showMessage(`Error: ${res.message}`, TOAST_TYPE.ERROR, false)
      }
    })
  }

  return (
    <>
      <button className="edit__password__back" onClick={toggleEditPassword}>
        <img src={backArrowIcon} alt={backArrowIcon} />
      </button>

      <h1
        className="Layout__setting__screens__title"
        style={{marginBottom: '100px', marginTop: '144px'}}>
        {intl.formatMessage({
          id: 'engrane.edit-password-edit',
          defaultMessage: 'Edit password',
        })}
      </h1>

      <form onSubmit={handleSubmit}>
        <label className="settings__fields__labels" style={{fontSize: '18px'}}>
          {intl.formatMessage({
            id: 'engrane.edit-password-after',
            defaultMessage: 'Old Password',
          })}
        </label>
        <div
          className="settings__fields__main"
          style={{width: '100%', marginBottom: '26px'}}>
          <input
            name="currentPassword"
            type={typeInputPassword}
            onChange={handleChange}
            value={currentPassword}
            autoFocus
          />

          <button type="button" onClick={changeTypeInputPassword}>
            <img
              style={{width: '22px'}}
              src={
                typeInputPassword === PASSWORD ? passwordCloseIcon : passwordShowIcon
              }
              alt="confirm_icon"
            />
          </button>
        </div>

        <label className="settings__fields__labels" style={{fontSize: '18px'}}>
          {intl.formatMessage({
            id: 'engrane.edit-password-new',
            defaultMessage: 'New Password',
          })}
        </label>
        <div
          className="settings__fields__main"
          style={{width: '100%', marginBottom: '26px'}}>
          <input
            name="newPassword"
            type={typeInputPasswordConfirm}
            onChange={handleChange}
            value={newPassword}
          />

          <button type="button" onClick={changeTypeInputPasswordConfirm}>
            <img
              style={{width: '22px'}}
              src={
                typeInputPasswordConfirm === PASSWORD
                  ? passwordCloseIcon
                  : passwordShowIcon
              }
              alt="confirm_icon"
            />
          </button>
        </div>

        <div className="settings__button__container">
          <button
            className="settings__button__main"
            disabled={!isValidData()}
            style={!isValidData() ? {cursor: 'no-drop', opacity: 0.5} : {}}
            type="submit">
            {`${
              isWorking
                ? intl.formatMessage({
                    id: 'engrane.edit-password-saving',
                    defaultMessage: 'Saving...',
                  })
                : intl.formatMessage({
                    id: 'engrane.edit-password-save-changes',
                    defaultMessage: 'Save Changes',
                  })
            }`}
          </button>

          <button className="settings__button__calcel" onClick={toggleEditPassword}>
            {intl.formatMessage({
              id: 'engrane.edit-password-cancel',
              defaultMessage: 'Cancel',
            })}
          </button>
        </div>
      </form>
    </>
  )
}

const EditInformationUser = ({currentUser, toggleEditPassword, intl}) => {
  const {showMessage} = useAlert()

  const {
    dispachUpdateProfileData,
    name: userName,
    email: userEmail,
    profile,
    getPhotoCurrentUser,
    dispatchUpdatePhotoUrl,
  } = currentUser

  const initialState = {
    name: userName || '',
    country: profile?.nacionalidad || '',
    birthdate: profile?.fecha_nacimiento || '',
    phone: profile?.phone || '',
  }

  const {isWorking, startWork, finishWork} = useWorkingIndicator()

  const {name, country, birthdate, phone, handleChange, isFormHaveChange} = useForm(
    initialState,
  )

  const handleSubmit = (ev) => {
    ev.preventDefault()

    // si no hay cambios en el form no enviar
    if (!isFormHaveChange) {
      return
    }

    const payload = {
      nombre: name,
      nacionalidad: country,
      fecha_nacimiento: birthdate,
      phone,
    }

    startWork()
    updateProfile(currentUser.id, payload).then((res) => {
      finishWork()
      const {data} = res
      if (data) {
        dispachUpdateProfileData(data)
        showMessage(
          intl.formatMessage({
            id: 'engrane.edit-perfil-success',
            defaultMessage: 'Profile saved successfully!',
          }),
          TOAST_TYPE.SUCCESS,
          false,
        )
      } else if (res.message) {
        showMessage(`Error: ${res.message}`, TOAST_TYPE.ERROR, false)
      }
    })
  }

  return (
    <>
      <h1 className="Layout__setting__screens__title" style={{marginBottom: '30px'}}>
        {intl.formatMessage({
          id: 'engrane.seting-personal-information',
          defaultMessage: 'Personal information',
        })}
      </h1>

      <div className="settings__information__avatar">
        <AvatarUploader
          photoObject={getPhotoCurrentUser()}
          updatePhotoUrl={dispatchUpdatePhotoUrl}
          isStyleBorder={false}
          showImageCamera
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="settings__fields__inline">
          <label className="settings__fields__labels">
            {intl.formatMessage({
              id: 'engrane.edit-perfil-name',
              defaultMessage: 'NAME',
            })}
          </label>
          <div className="settings__fields__main">
            <input
              name="name"
              type="text"
              onChange={handleChange}
              value={name}
              autoFocus
            />
          </div>
        </div>

        <div className="settings__fields__inline">
          <label className="settings__fields__labels">
            {intl.formatMessage({
              id: 'engrane.edit-perfil-nacionality',
              defaultMessage: 'NATIONALITY',
            })}
          </label>
          <div className="settings__fields__main">
            <input
              name="country"
              type="text"
              onChange={handleChange}
              value={country}
            />
          </div>
        </div>

        <div className="settings__fields__inline">
          <label className="settings__fields__labels">
            {intl.formatMessage({
              id: 'engrane.edit-perfil-birthday',
              defaultMessage: 'BIRTHDAY',
            })}
          </label>
          <div className="settings__fields__main">
            <input
              name="birthdate"
              type="date"
              onChange={handleChange}
              value={birthdate}
            />
          </div>
        </div>

        <div className="settings__fields__inline">
          <label className="settings__fields__labels">
            {intl.formatMessage({
              id: 'engrane.edit-perfil-phone',
              defaultMessage: 'TELEPHONE',
            })}
          </label>
          <ReactPhoneInput
            inputExtraProps={{
              required: true,
              autoFocus: true,
            }}
            disableCountryCode={true}
            enableSearch={true}
            disableSearchIcon={true}
            onChange={(value) => handleChange({target: {name: 'phone', value}})}
            value={phone}
            containerClass={'settings__phone__container'}
            inputClass={'settings__phone__input_class'}
            buttonClass={'settings__phone__button'}
            dropdownClass={'settings__phone__dropdown'}
            searchClass={'settings__phone__search'}
            enableLongNumbers // para poder editar mas
            searchStyle={{
              border: '0',
            }}
          />
        </div>

        <div className="settings__fields__inline">
          <label className="settings__fields__labels">
            {intl.formatMessage({
              id: 'engrane.edit-perfil-email',
              defaultMessage: 'EMAIL',
            })}
          </label>
          <div className="settings__fields__main">
            <input
              name="email"
              type="email"
              value={userEmail}
              disabled
              style={{cursor: 'no-drop'}}
            />
          </div>
        </div>

        <div className="settings__fields__inline">
          <label className="settings__fields__labels">
            {intl.formatMessage({
              id: 'engrane.edit-perfil-password',
              defaultMessage: 'PASSWORD',
            })}
          </label>
          <div
            className="settings__fields__main"
            onClick={toggleEditPassword}
            style={{cursor: 'pointer'}}>
            <input
              name="password"
              type="password"
              value="*********"
              disabled
              style={{cursor: 'pointer'}}
            />

            <img
              style={{width: '33px'}}
              src={passwordEditIcon}
              alt={passwordEditIcon}
            />
          </div>
        </div>

        <div className="settings__button__container">
          <button
            className="settings__button__main"
            disabled={!isFormHaveChange}
            style={!isFormHaveChange ? {cursor: 'no-drop', opacity: 0.5} : {}}
            type="submit">
            {`${
              isWorking
                ? intl.formatMessage({
                    id: 'engrane.edit-password-saving',
                    defaultMessage: 'Saving...',
                  })
                : intl.formatMessage({
                    id: 'engrane.edit-password-save-changes',
                    defaultMessage: 'Save Changes',
                  })
            }`}
          </button>
        </div>
      </form>
    </>
  )
}

const SettingsInformation = ({currentUser, intl}) => {
  const {
    isToggled: isToggleEditPassword,
    toggle: toggleEditPassword,
  } = useBooleanToggler({initialValue: false})

  return (
    <div className="settings__information__main">
      {isToggleEditPassword ? (
        <EditPassword toggleEditPassword={toggleEditPassword} intl={intl} />
      ) : (
        <EditInformationUser
          currentUser={currentUser}
          toggleEditPassword={toggleEditPassword}
          intl={intl}
        />
      )}
    </div>
  )
}

export default SettingsInformation
