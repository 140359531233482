import React from 'react'
import {useState, useEffect} from 'react'
import IconDefaultParticipant from './Icons/DefaultParticipant.svg'

const AvatarParticipant = ({photoUrl = {url: null}, title = '', style = {}}) => {
  const [photo, setPhoto] = useState(photoUrl)

  useEffect(() => {
    setPhoto(photoUrl)
  }, [photoUrl])

  const onError = () => {
    setPhoto({url: IconDefaultParticipant})
  }

  return (
    <img
      className="photo-participants-on-conversation"
      src={photo.url ? photo.url : IconDefaultParticipant}
      alt={`${title}`}
      title={`${title}`}
      style={style}
      onError={onError}
    />
  )
}

export default AvatarParticipant
