import {
  updateLinesLemmaLearned,
  updateMeorizeIdToWordList,
} from '../../helpers/utils'
import {getOpenSong} from '../../lib/coco/InputApi'
import {
  ActualizarIndicesAgregadosEnLocalStorage,
  ActualizarLineasEnLocalStorage,
  SaveMediaTextInLocalStorage,
  ObtenerMediatextDeLocalStorage,
  SaveMediaTracksInColaStorage,
  getTracksInColaLocalStorage,
} from '../helpers'

import {
  OPEN_SONG_REQUEST_SUCCESS,
  SONG_UPDATE,
  LOADING_REQUEST_MEDIATEXT,
  UPDATE_LINES,
  UPDATE_INDICES_YA_AGREGADOS,
  MediaTextEmpty,
  OPEN_INPUT_TEXT,
  OPEN_MEDIATEXT_REQUEST_ERROR,
  ADD_TRACKS_TO_PLAYER_COLAS,
} from './actionTypes'

// setear el input text
export const openInputText = (inputText) => {
  return {
    type: OPEN_INPUT_TEXT,
    inputText,
  }
}

export const updateIndicesYaAgregados = (indicesAgregados) => (dispatch) => {
  ActualizarIndicesAgregadosEnLocalStorage(indicesAgregados)

  return dispatch({
    type: UPDATE_INDICES_YA_AGREGADOS,
    indicesAgregados,
  })
}

const defaultListWords = {
  demasiado_frecuente: [],
  muy_frecuente: [],
  frecuente: [],
  medio_frecuente: [],
  menos_frecuente: [],
  sin_frecuencia: [],
}

// para ctualizar las lineas y marcar como aprendido
export const updateLines = (wordMark, memorizeId = null) => (dispatch, getState) => {
  const mediatextState = getState()?.mediatext
  const {mediaTextSong, inputText} = mediatextState

  const newLinesMediaText = updateLinesLemmaLearned(mediaTextSong?.lines, wordMark)
  const newLinesInputText = updateLinesLemmaLearned(inputText?.lines, wordMark)

  let newWordList = defaultListWords
  let newWordListInputText = defaultListWords

  if (memorizeId) {
    // si hay un mediatext le actualizamos la lista
    if (mediaTextSong?.word_list) {
      const {word_list} = mediaTextSong
      newWordList = {
        demasiado_frecuente: updateMeorizeIdToWordList(
          word_list?.demasiado_frecuente,
          wordMark,
          memorizeId,
        ),
        muy_frecuente: updateMeorizeIdToWordList(
          word_list?.muy_frecuente,
          wordMark,
          memorizeId,
        ),
        frecuente: updateMeorizeIdToWordList(
          word_list?.frecuente,
          wordMark,
          memorizeId,
        ),
        medio_frecuente: updateMeorizeIdToWordList(
          word_list?.medio_frecuente,
          wordMark,
          memorizeId,
        ),
        menos_frecuente: updateMeorizeIdToWordList(
          word_list?.menos_frecuente,
          wordMark,
          memorizeId,
        ),
        sin_frecuencia: updateMeorizeIdToWordList(
          word_list?.sin_frecuencia,
          wordMark,
          memorizeId,
        ),
      }
    }

    if (inputText?.word_list) {
      const {word_list: wordListInputText} = inputText

      newWordListInputText = {
        demasiado_frecuente: updateMeorizeIdToWordList(
          wordListInputText?.demasiado_frecuente,
          wordMark,
          memorizeId,
        ),
        muy_frecuente: updateMeorizeIdToWordList(
          wordListInputText?.muy_frecuente,
          wordMark,
          memorizeId,
        ),
        frecuente: updateMeorizeIdToWordList(
          wordListInputText?.frecuente,
          wordMark,
          memorizeId,
        ),
        medio_frecuente: updateMeorizeIdToWordList(
          wordListInputText?.medio_frecuente,
          wordMark,
          memorizeId,
        ),
        menos_frecuente: updateMeorizeIdToWordList(
          wordListInputText?.menos_frecuente,
          wordMark,
          memorizeId,
        ),
        sin_frecuencia: updateMeorizeIdToWordList(
          wordListInputText?.sin_frecuencia,
          wordMark,
          memorizeId,
        ),
      }
    }
  }

  ActualizarLineasEnLocalStorage(newLinesMediaText, newWordList)

  return dispatch({
    type: UPDATE_LINES,
    mediaTextSong: {
      ...mediaTextSong,
      lines: newLinesMediaText,
      word_list: newWordList,
    },
    inputText: {
      ...inputText,
      lines: newLinesInputText,
      word_list: newWordListInputText,
    },
  })
}

// para actualizar lineas de eliminar de palabras aprendidas
export const updateLinesAndDesabledLearnedWords = (wordMark) => (
  dispatch,
  getState,
) => {
  const mediatextState = getState()?.mediatext
  const {mediaTextSong, inputText} = mediatextState

  // add atribute false for desabled learned
  const newLinesMediaText = updateLinesLemmaLearned(
    mediaTextSong?.lines,
    wordMark,
    false,
  )
  const newLinesInputText = updateLinesLemmaLearned(
    inputText?.lines,
    wordMark,
    false,
  )

  let newWordList = {}
  let newWordListInputText = {}

  const {word_list} = mediaTextSong
  const {word_list: wordListInputText} = inputText

  newWordList = {
    demasiado_frecuente: updateMeorizeIdToWordList(
      word_list?.demasiado_frecuente,
      wordMark,
      null,
    ),
    muy_frecuente: updateMeorizeIdToWordList(
      word_list?.muy_frecuente,
      wordMark,
      null,
    ),
    frecuente: updateMeorizeIdToWordList(word_list?.frecuente, wordMark, null),
    medio_frecuente: updateMeorizeIdToWordList(
      word_list?.medio_frecuente,
      wordMark,
      null,
    ),
    menos_frecuente: updateMeorizeIdToWordList(
      word_list?.menos_frecuente,
      wordMark,
      null,
    ),
    sin_frecuencia: updateMeorizeIdToWordList(
      word_list?.sin_frecuencia,
      wordMark,
      null,
    ),
  }

  newWordListInputText = {
    demasiado_frecuente: updateMeorizeIdToWordList(
      wordListInputText?.demasiado_frecuente,
      wordMark,
      null,
    ),
    muy_frecuente: updateMeorizeIdToWordList(
      wordListInputText?.muy_frecuente,
      wordMark,
      null,
    ),
    frecuente: updateMeorizeIdToWordList(
      wordListInputText?.frecuente,
      wordMark,
      null,
    ),
    medio_frecuente: updateMeorizeIdToWordList(
      wordListInputText?.medio_frecuente,
      wordMark,
      null,
    ),
    menos_frecuente: updateMeorizeIdToWordList(
      wordListInputText?.menos_frecuente,
      wordMark,
      null,
    ),
    sin_frecuencia: updateMeorizeIdToWordList(
      wordListInputText?.sin_frecuencia,
      wordMark,
      null,
    ),
  }

  ActualizarLineasEnLocalStorage(newLinesMediaText, newWordList)

  return dispatch({
    type: UPDATE_LINES,
    mediaTextSong: {
      ...mediaTextSong,
      lines: newLinesMediaText,
      word_list: newWordList,
    },
    inputText: {
      ...inputText,
      lines: newLinesInputText,
      word_list: newWordListInputText,
    },
  })
}

const mediaTextLoading = () => {
  return {
    type: LOADING_REQUEST_MEDIATEXT,
  }
}

const requestMediaTextError = (errors) => {
  return {
    type: OPEN_MEDIATEXT_REQUEST_ERROR,
    errors,
  }
}

export const openSong = (
  spotify_id,
  artistas,
  titulo,
  image_url = '',
  itemId = null,
) => (dispatch) => {
  dispatch(mediaTextLoading())

  getOpenSong(spotify_id, artistas, titulo, image_url, itemId).then((response) => {
    if (response?.mediaText) {
      const {mediaText} = response
      //guardar en localStorage un mediatext
      SaveMediaTextInLocalStorage(mediaText)
      return dispatch({
        type: OPEN_SONG_REQUEST_SUCCESS,
        mediaText,
      })
    } else {
      SaveMediaTextInLocalStorage(MediaTextEmpty)
      dispatch(requestMediaTextError(response?.message))
    }
  })
}

// retoma el JSON de local Storage y actualiza Redux para que el mediatext no se pierda
export const mediaTextInLocalStorage = () => (dispatch) => {
  const media_text_de_local_storage = ObtenerMediatextDeLocalStorage()

  if (media_text_de_local_storage?.mediaText?.media_text_id) {
    const {mediaText} = media_text_de_local_storage
    return dispatch({
      type: OPEN_SONG_REQUEST_SUCCESS,
      mediaText,
    })
  }
}

export const UpdateSong = (mediatext) => (dispatch) => {
  SaveMediaTextInLocalStorage(mediatext)
  return dispatch({
    type: SONG_UPDATE,
    mediaText: mediatext,
  })
}

export const getTracksFromLocalStorage = () => (dispatch) => {
  const tracks = getTracksInColaLocalStorage()
  if (tracks?.length > 0) {
    dispatch(addTracksToPlayerColas(tracks))
  }
}

export const addTracksToPlayerColas = (tracks = []) => {
  SaveMediaTracksInColaStorage(tracks)
  return {
    type: ADD_TRACKS_TO_PLAYER_COLAS,
    tracks,
  }
}
