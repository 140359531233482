import React from 'react'
// import {useDispatch} from 'react-redux'
import useLanguages from '../../../hooks/useLanguages'
import {useModalSpotifyAuth} from '../../../hooks/useSpotifyModalAuth'
import useSpotify from '../../../hooks/useSpotify'
import useMediaText from '../../../hooks/useMediaText'
import {useHistory} from 'react-router-dom'
// import {skipToNextTrack} from '../../../Redux/spotify/actions/playback'

const findNextTracks = (tracksColas = [], currentTrack) => {
  const indexCurrentTrack = tracksColas.findIndex(
    (track) => track?.uri === currentTrack?.uri,
  )

  if (tracksColas[indexCurrentTrack + 1]) {
    // retornar el siguiente track
    return tracksColas[indexCurrentTrack + 1]
  } else {
    // reinicia desde cero
    return tracksColas[0]
  }
}

const SkipToNext = () => {
  const history = useHistory()
  const {toggleSpotifyModalAuth, isSpotifyConnected} = useModalSpotifyAuth()
  const spotifyStatus = isSpotifyConnected()
  const {languageActive} = useLanguages()
  const {player, playTrackValidate} = useSpotify()
  const {tracksPlayerColas = [], dispatchOpenSong} = useMediaText()

  const lang = languageActive?.unicode ? languageActive.unicode : 'es'
  const title =
    lang === 'es'
      ? 'Siguiente'
      : lang === 'fr'
      ? 'Suivant'
      : lang === 'pt'
      ? 'Seguinte'
      : 'Next'

  const nextSong = () => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
    } else {
      const currentTrack = player?.track_window?.current_track
      const trackNext = findNextTracks(tracksPlayerColas, currentTrack)
      if (trackNext?.uri) {
        playTrackValidate(trackNext.uri)
        // hacer open song
        if (history.location.pathname === '/media_text') {
          dispatchOpenSong(trackNext, false)
        }
      }
    }
  }

  const haveNextTracks = tracksPlayerColas?.length > 1

  return (
    <button
      title={title}
      className="player__item__button"
      onClick={() => nextSong()}
      disabled={!haveNextTracks}
      style={!haveNextTracks ? {cursor: 'not-allowed', opacity: 0.5} : {}}>
      <img src="/images/Reproductor/right.svg" alt="next" />
    </button>
  )
}

export default SkipToNext
