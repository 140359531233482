import React from 'react'
import useUser from '../../../hooks/useUser'
import {getSortedConversations, getConversationWithTeacherMichael} from '../helpers'
import ConversationItem from './ConversationItem'

const sortedConversations = (conversations = [], currentUserId) => {
  if (conversations?.length > 0) {
    const teacherConversation = getConversationWithTeacherMichael(
      conversations,
      currentUserId,
    )

    const conversationsSorted = getSortedConversations(conversations).filter(
      (c) => c?.id !== teacherConversation?.id,
    )

    return {
      conversationsSorted,
      teacherConversation,
    }
  } else {
    return {
      conversationsSorted: [],
      teacherConversation: null,
    }
  }
}

const Conversations = ({
  conversations = [],
  conversationActive,
  setActiveConversation,
  style = {},
  className = '',
}) => {
  const {id: currentUserId} = useUser()
  const {
    conversationsSorted: conversationsOrder,
    teacherConversation,
  } = sortedConversations(conversations, currentUserId)

  const isThisConversationActive = (id) => Boolean(conversationActive?.id === id)

  return (
    <>
      {teacherConversation?.id && (
        <div style={{margin: '0 3% 0 3%'}}>
          <ConversationItem
            key={teacherConversation.id}
            conversation={teacherConversation}
            setActiveConversation={setActiveConversation}
            isTeacherConversation
            isActive={isThisConversationActive(teacherConversation.id)}
            currentUserId={currentUserId}
            className="contenedor-hilos-item-teacher"
            style={
              isThisConversationActive(teacherConversation.id)
                ? {borderBottom: '0px'}
                : {}
            }
          />
        </div>
      )}
      <ul
        className={`scroll-coco ${className}`}
        style={{position: 'relative', ...style, paddingTop: '0'}}>
        {conversationsOrder?.length > 0 &&
          conversationsOrder.map((conversation) => (
            <ConversationItem
              key={conversation.id}
              conversation={conversation}
              setActiveConversation={setActiveConversation}
              isActive={isThisConversationActive(conversation.id)}
              currentUserId={currentUserId}
            />
          ))}
      </ul>
    </>
  )
}

export default Conversations

// let sortedConversations = unresolvedConversations.sort(
// 	(a, b) => new Date(b.updated_at) - new Date(a.updated_at)
// );
