import React, {useEffect, useState} from 'react'
import FlashcardBack from './FlashcardBack'
import PropTypes from 'prop-types'
import FlashcardFront from './FlashcardFront'
import SuccessMessageFlashcard from './SuccessMessageFlashcard'
import './card.css'
import './TranslationFlashcardForm.css'
import {
  createFlashcardItem,
  getFlashcardYaAgregado,
} from '../../lib/coco/flashcardsApi'
import useAlert, {TOAST_TYPE} from '../../hooks/useAlert'
import {traductorBody} from '../../lib/coco/DiccionaryAPi'
import useUser from '../../hooks/useUser'
import BotonConBordeDegradado from '../Buttons/BotonConBordeDegradado'
import listeningIcon from './IconsPlusButton/listening.svg'
import plusWhiteIcon from './IconsPlusButton/plusWhite.svg'
import {useIntl} from 'react-intl'

/* Icons */
import IconWarningText from './Icons/IconWarningText.svg'

const TranslationFlashcardForm = (props) => {
  const intl = useIntl()
  const {dispatchGetAllProgress, idioma_activo, idioma_nativo} = useUser()

  const {showMessage} = useAlert()
  //Props
  const {
    editable,
    phraseTranslation: phraseTranslationProps,
    phrase: phraseProps,
    // save flashcard
    paramsItemOptionsRequest, // requiere un { item, ...extras(lang_code, etc)}
    paramsLangCodeItemRequest, // para el codigo
    // funtion
    showSuccessMessage,
    updateIndicesYaAgregados,
    showButtonsOptions,
    closeModal,
    continueAddFlashcard,
  } = props

  const [phraseTranslation, setPhraseTranslation] = useState(phraseTranslationProps)
  const [phrase, setPhrase] = useState(phraseProps ? phraseProps : '')
  const [successfulCreation, setSuccessfulCreation] = useState(false)
  const [wordNumbers, setWordNumbers] = useState(0)

  useEffect(() => {
    if (phraseTranslationProps === '') translatePhrase()

    getFlashcardYaAgregado(phraseProps).then((res) => {
      if (res?.items?.length > 0) {
        showMessage(
          `${phraseProps} ${intl.formatMessage({
            id: 'flashcard.phare-alrealy-add',
            defaultMessage: 'La frase ya fue agregada antes.',
          })}`,
          TOAST_TYPE.WARNING,
        )
      }
    })

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setWordNumbers(phrase.trim().split(' ').length)
  }, [phrase])

  const translatePhrase = () => {
    traductorBody(phraseProps, idioma_activo?.unicode, idioma_nativo?.unicode).then(
      (response) => {
        if (response.translation) {
          const {translation} = response
          setPhraseTranslation(translation)
        } else {
          showMessage(
            `${response.message}, ${intl.formatMessage({
              id: 'flashcard.error-translate',
              defaultMessage: '¡Error al traducir!',
            })}`,
            TOAST_TYPE.ERROR,
          )
        }
      },
    )
  }

  //Function: crea un flashcard con las frases seleccionadas o con los que se proporcione directamente.
  const saveFlashcard = () => {
    // item de un flashcard basico
    const item = {
      title: phraseTranslation,
      summary: phrase,
      activity_type: 'memorize', // tipo de actividad
      format: 'translation', // formato de traduccion
      ...paramsItemOptionsRequest, // agrega o sobreescribe nuevos param
    }

    // parametros mergeados
    const paramsCompletes = {
      item,
      ...paramsLangCodeItemRequest, // lang_code: wordMark?.lang_code
    }

    // funtion recive formatos de item
    createFlashcardItem(paramsCompletes).then((res) => {
      if (res?.item) {
        const memorizedTerm = res?.memorizedTerm
        setSuccessfulCreation(true)
        if (showSuccessMessage) showSuccessMessage()
        if (updateIndicesYaAgregados) updateIndicesYaAgregados(memorizedTerm)
        // update progress
        dispatchGetAllProgress()
      } else {
        showMessage(res.message, TOAST_TYPE.ERROR)
      }
    })
  }

  //Function: Es para obtener la frase traducida(white card)
  const getPhraseTranslation = (phraseTranslation) =>
    setPhraseTranslation(phraseTranslation)

  //Function: También esto es para obtener la frase(black card)
  const getPhrase = (phrase) => setPhrase(phrase)

  const FooteButtonsSuccess = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '40px',
        }}>
        <BotonConBordeDegradado
          function={closeModal}
          width={126}
          height={37}
          style={{
            marginRight: '20px',
            border: '1px #A8CCFF solid',
            background: 'linear-gradient(268.02deg, #89AAF0 0.25%, #A6CAFF 100%)',
          }}>
          <img
            src={listeningIcon}
            alt={listeningIcon}
            style={{marginRight: '20px'}}
          />
          {intl.formatMessage({
            id: 'flashcard.listening-now',
            defaultMessage: 'Seguir escuchando',
          })}
        </BotonConBordeDegradado>

        <BotonConBordeDegradado
          function={continueAddFlashcard}
          width={126}
          height={37}
          style={{
            marginLeft: '20px',
          }}>
          <img
            src={plusWhiteIcon}
            alt={plusWhiteIcon}
            style={{marginRight: '20px'}}
          />
          {intl.formatMessage({
            id: 'flashcard.add-other',
            defaultMessage: 'Agregar otra frase',
          })}
        </BotonConBordeDegradado>
      </div>
    )
  }

  return (
    <>
      {!successfulCreation ? (
        <div className="d-flex flex-column justify-content-between p-5 h-100">
          <h2 className="translation__flashcard__title">
            {intl.formatMessage({
              id: 'flashcard.add-your-mazo',
              defaultMessage: 'Agrega un flashcard a tu mazo',
            })}
          </h2>
          <div
            className="d-flex align-items-center flex-wrap"
            style={{justifyContent: 'space-evenly'}}>
            {/*Es la tarjeta negra que es usada para el idioma nativo*/}
            <FlashcardFront
              width={241.75}
              height={340.78}
              phraseTranslation={phraseTranslation}
              getPhraseTranslation={getPhraseTranslation}
              editable={editable}
            />
            {/*Es la tarjeta blanca que es usada para el idioma activo*/}
            <FlashcardBack
              width={241.75}
              height={340.78}
              phrase={phrase}
              getPhrase={getPhrase}
              editable={editable}
            />
          </div>
          {wordNumbers >= 15 && (
            <div className="warning-flashcard-container">
              <div>
                <img src={IconWarningText} alt={IconWarningText} />
              </div>
              <div className="warning-text-flashcard ml-1">
                {intl.formatMessage({
                  id: 'flashcard.warning-text',
                  defaultMessage:
                    'If you add such a long text it will be difficult to memorize. We suggest you make it shorter.',
                })}
              </div>
            </div>
          )}
          <div>
            <button
              onClick={saveFlashcard}
              className="btn btn-next-output font-weight-bold">
              {intl.formatMessage({id: 'flashcard.send', defaultMessage: 'Enviar'})}
              <img
                style={{marginLeft: '5px'}}
                src="/images/Output/send.svg"
                alt="send"
              />
            </button>
          </div>
        </div>
      ) : (
        <SuccessMessageFlashcard
          message={intl.formatMessage({
            id: 'flashcard.send-success-title',
            defaultMessage: '¡Tu flashcard fue enviado con exito!',
          })}
          description={intl.formatMessage({
            id: 'flashcard.send-success-description',
            defaultMessage: 'Un profesor nativo lo revisará en menos de 24 hrs.',
          })}
          footerComponent={showButtonsOptions ? <FooteButtonsSuccess /> : null}
        />
      )}
    </>
  )
}

TranslationFlashcardForm.propTypes = {
  phrase: PropTypes.string.isRequired,
  phraseTranslation: PropTypes.string.isRequired,
  showSuccessMessage: PropTypes.func,
  editable: PropTypes.bool,
  updateIndicesYaAgregados: PropTypes.func,
  paramsItemOptionsRequest: PropTypes.object,
  paramsLangCodeItemRequest: PropTypes.object,
  showButtonsOptions: PropTypes.bool,
}

TranslationFlashcardForm.defaultProps = {
  editable: true,
  paramsItemOptionsRequest: {},
  paramsLangCodeItemRequest: {},
  showButtonsOptions: false,
}

export default TranslationFlashcardForm
