import React, {useEffect, useRef, useState} from 'react'
import Message from './Message'
import useUser from '../../../hooks/useUser'
import {getMessagesNotReadedConversation, TypeDocuchats} from '../helpers'
import AvatarParticipant from './AvatarParticipant'
import Modal from '../../Modal'
import ReactGallery from './ReactGallery'
import {readMessageList} from '../../../lib/coco/conversationApi'

const MessagesArea = ({
  conversation,
  type = TypeDocuchats.DOCUCHAT,
  autoFocusMessageInput,
}) => {
  const {id: idCurrentUser} = useUser()
  const endMessagesRef = useRef()
  const [showGallet, setShowGallet] = useState(false)
  const [messageSelect, setMessageSelect] = useState(null)

  const showGalleyImages = (message) => {
    setShowGallet(true)
    setMessageSelect(message)
  }

  const isDocuchatActive = type === TypeDocuchats.DOCUCHAT

  const scrollToBottom = () => {
    if (endMessagesRef?.current) {
      const endMessagesRefValue = endMessagesRef.current
      endMessagesRefValue.scrollTop = endMessagesRefValue.scrollHeight
    }
  }

  return (
    <div className="scroll-coco" ref={endMessagesRef}>
      <ul>
        <OrderedMessages
          messages={conversation.messages || []}
          idCurrentUser={idCurrentUser}
          isDocuchatActive={isDocuchatActive}
          scrollToBottom={scrollToBottom}
          showGalleyImages={showGalleyImages}
          autoFocusMessageInput={autoFocusMessageInput}
        />
      </ul>

      {showGallet && conversation?.messages?.length > 0 && (
        <Modal
          modalType="modal-galley-images"
          show={showGallet}
          closeModal={() => setShowGallet(false)}
          closeRound
          closeClickOutside>
          <ReactGallery
            messages={conversation?.messages || []}
            messageSelect={messageSelect}
            closeModal={() => setShowGallet(false)}
          />
        </Modal>
      )}
    </div>
  )
}

export default MessagesArea

// helpers
const OrderedMessages = ({
  messages = [],
  idCurrentUser,
  isDocuchatActive,
  scrollToBottom,
  showGalleyImages,
  autoFocusMessageInput,
}) => {
  const [coutMessage, setCountMessages] = useState(0)

  useEffect(() => {
    const prevCounts = coutMessage
    if (messages?.length !== prevCounts) {
      setCountMessages(messages?.length > 0 ? messages.length : 0, scrollToBottom())
    }

    checkMessagesNotReaded()
    // eslint-disable-next-line
  }, [messages, coutMessage, scrollToBottom])

  const checkMessagesNotReaded = () => {
    const resultMessages = getMessagesNotReadedConversation(messages, idCurrentUser)
    if (resultMessages?.length > 0) {
      const idsMessages = resultMessages.map((message) => message.id)
      readMessageList(idsMessages)
    }
  }

  const sortedMessages = messages.sort(
    (a, b) => new Date(a.created_at) - new Date(b.created_at),
  )

  const rigthStyle = isDocuchatActive
    ? 'rigth-message-docuchat'
    : 'rigth-message-minichat'
  const leftStyle = isDocuchatActive
    ? 'left-message-docuchat'
    : 'left-message-minichat'

  return sortedMessages.map((message) => (
    <ul key={message.id} id={`message-${message.id}`}>
      {
        /*message current user*/
        message.user.id === idCurrentUser ? (
          <li key={message.id} className="message-item-li-current">
            <Message
              message={message}
              isCurrentUser={true}
              messageStyle={rigthStyle}
              messageCitadoStyle="message-citado-text"
              showGalleyImages={showGalleyImages}
              autoFocusMessageInput={autoFocusMessageInput}
            />

            <AvatarParticipant
              photoUrl={message.user?.photo_url}
              title={`${message?.user.name || message?.user.email || 'User'}`}
            />
          </li>
        ) : (
          <li key={message.id} className="message-item-li">
            <AvatarParticipant
              photoUrl={message.user?.photo_url}
              title={`${message?.user.name || message?.user.email || 'User'}`}
            />
            <Message
              message={message}
              isCurrentUser={false}
              messageStyle={leftStyle}
              messageCitadoStyle="message-citado-text-left"
              showGalleyImages={showGalleyImages}
              autoFocusMessageInput={autoFocusMessageInput}
            />
          </li>
        )
      }
    </ul>
  ))
}
