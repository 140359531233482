import React, {useState} from 'react'
import SelectFlashcardType from './SelectFlashcardType'
import Preview from './Preview'
import AddFraseSuccess from '../Messages/AddFraseSuccess'
import flechaAtras from '../Icons/flechaAtras.svg'

const TypeViewer = {
  SELECT_TYPE: 'SELECT_TYPE', //fue removido por ahora
  TRADUCTION: 'TRADUCTION',
  SPACE: 'SPACE', //fue removido por ahora
  DECORATION: 'DECORATION', //no implementado
  FINISH: 'FINISH',
}

const Modal = ({
  closeModalFlashcardPreview,
  fuente,
  mediaTextId,
  isInMediaText,
  lineIndex,
  palabra,
  oracion,
  handleClose,
  showGradient,
}) => {
  // por defecto estaba SELECT_TYPE, ahora sera SPACE
  const [ventanaActiva, setVentanaActiva] = useState(TypeViewer.TRADUCTION)

  const ButtonGoToBack = ({onClick, title = 'Ir atras'}) => {
    return (
      <button
        className="btn btn-link animacion-growing-up"
        onClick={onClick}
        title={title}>
        <img src={flechaAtras} alt="Ir atras" />
      </button>
    )
  }

  return (
    <div className="container">
      {ventanaActiva === TypeViewer.SELECT_TYPE && (
        <div className="container">
          <ButtonGoToBack onClick={closeModalFlashcardPreview} />
          <SelectFlashcardType cambiarVentana={setVentanaActiva} />
        </div>
      )}

      {ventanaActiva === TypeViewer.TRADUCTION && (
        <>
          <ButtonGoToBack onClick={closeModalFlashcardPreview} />
          <Preview
            isInMediaText={isInMediaText}
            format={'translation'}
            oracion={oracion}
            fuente={fuente}
            palabra={palabra}
            mediaTextId={mediaTextId}
            requestFlashcardSuccess={() => setVentanaActiva(TypeViewer.FINISH)}
            lineIndex={lineIndex}
            goToBack={closeModalFlashcardPreview}
          />
        </>
      )}

      {ventanaActiva === TypeViewer.SPACE && (
        <>
          <ButtonGoToBack onClick={closeModalFlashcardPreview} />
          <Preview
            isInMediaText={isInMediaText}
            format={'cloze'}
            oracion={oracion}
            fuente={fuente}
            palabra={palabra}
            mediaTextId={mediaTextId}
            requestFlashcardSuccess={() => setVentanaActiva(TypeViewer.FINISH)}
            lineIndex={lineIndex}
            goToBack={closeModalFlashcardPreview}
          />
        </>
      )}

      {ventanaActiva === TypeViewer.FINISH && (
        <>
          <ButtonGoToBack
            title="Volver al diccionario"
            onClick={closeModalFlashcardPreview}
          />
          <AddFraseSuccess
            handleClose={handleClose}
            showGradient={showGradient}
            goToBack={closeModalFlashcardPreview}
          />
        </>
      )}

      {/* {ventanaActiva === TypeViewer.DECORATION && (
          <div className="container text-center my-5">
            <button
              className="btn btn-link animacion-growing-up"
              onClick={goToBackView}
            >
              <img src="/images/IrAtras.svg" alt="ir atras" />
            </button>
            <h1 className="letra-secondary">
              <FormattedMessage
                id={"language.decorate"}
                defaultMessage={"Decorar flashcard"}
              />
            </h1>
            <button
              className="btn btn-primary"
              onClick={() => cambiarVentana(TypeViewer.FINISH)}
              disabled
            >
              OK (no implemented)
            </button>
          </div>
        )} */}
    </div>
  )
}

export default Modal
