import React from 'react'
import {connect} from 'react-redux'
import {togglePlayback} from '../../../Redux/spotify/actions/playback'
import useLanguages from '../../../hooks/useLanguages'
import {useModalSpotifyAuth} from '../../../hooks/useSpotifyModalAuth'

const PlayButton = ({
  is_playing,
  position_ms,
  player,
  loading,
  dispatch,
  showIconLoading = true,
}) => {
  const {toggleSpotifyModalAuth, isSpotifyConnected} = useModalSpotifyAuth()
  const spotifyStatus = isSpotifyConnected()

  const {languageActive} = useLanguages()
  const lang = languageActive?.unicode ? languageActive.unicode : 'es'
  const iniciar =
    lang === 'es'
      ? 'Iniciar'
      : lang === 'pt'
      ? 'Iniciar'
      : lang === 'fr'
      ? 'Commencer'
      : 'Start'
  const detener =
    lang === 'es'
      ? 'Detener'
      : lang === 'pt'
      ? 'Parar'
      : lang === 'fr'
      ? 'Arrêter'
      : 'Stop'

  const context_uri = player.context.uri

  const svg_source = is_playing
    ? '/images/Reproductor/stop.svg'
    : '/images/Reproductor/play.svg'

  const playSong = () => {
    if (!spotifyStatus) {
      toggleSpotifyModalAuth(spotifyStatus)
    } else {
      dispatch(togglePlayback(is_playing, context_uri, position_ms))
    }
  }

  return (
    <button
      title={is_playing ? detener : iniciar}
      className="player__spotify__play__button"
      onClick={() => playSong()}>
      {showIconLoading && loading ? (
        <div className="loading_player">
          <div className="spinner__player">
            <div className="mask__player">
              <div className="maskedCircle__player"></div>
            </div>
          </div>
        </div>
      ) : (
        <img src={svg_source} alt={is_playing ? 'stop' : 'play'} />
      )}
    </button>
  )
}

const mapStateToProps = (state) => ({
  is_playing: !state.spotify.player.paused,
  playing_id: state.spotify.player.track_window.current_track.id,
  position_ms: state.spotify.player.position,
  loading: state.spotify.player.loading,
  player: state.spotify.player,
})

export default connect(mapStateToProps)(PlayButton)
