import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import useUser from '../hooks/useUser'
import RoutesWelcome from './WelcomeRoutes'
import AuthRoutes from './AuthRoutes'

import '../styles/style.css'
import '../styles/generalStyles.css'
import '../Toast/bootstrap-v4.css'
import '../Toast/noty.css'

const AppRoutes = () => {
  const {isLogged: isUserLogged = false} = useUser()

  return <Router>{isUserLogged ? <AuthRoutes /> : <RoutesWelcome />}</Router>
}

export default AppRoutes
