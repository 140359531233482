import React, {useState, useEffect} from 'react'
import AudioRecorder from '../Comunidad/Chat/Audio/AudioRecorder'

import './Pronounce.css'
import {getPhraseToPronounce} from '../../lib/coco/OutputsApi'
import {useWorkingIndicator} from '../../hooks/useBooleanToggler'

const styles = {
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '125px 60px 46px 60px',
  },
  phrase: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 'x-large',
    color: '#FFFFFF',
  },
}

const Pronounce = () => {
  const [phrase, setPhrase] = useState('Have you ever been to Canada?')
  // const [audio, setAudio] = useState(null)
  const [audioCount, setAudioCount] = useState(null)

  const {isWorking, startWork, finishWork} = useWorkingIndicator()

  useEffect(() => {
    obtenerUnaFrase()
    // eslint-disable-next-line
  }, [])

  const grabar = (audio) => {
    // setAudio(audio)
    setAudioCount(audioCount + 1)
  }

  const guardar = () => {}

  const obtenerUnaFrase = () => {
    if (isWorking) return

    startWork()
    getPhraseToPronounce().then((response) => {
      console.log('RESPONSE: ', response.phrase)
      setPhrase(response.phrase)
      finishWork()
    })
  }

  return (
    <div className={'coco__cardbox__container'}>
      <div className="coco__cardbox__card" style={{marginTop: '28px'}}>
        {phrase ? (
          <div style={styles.container}>
            <div style={styles.phrase}>{phrase}</div>

            <div className="line-separator-audio-phrases" />

            <div>
              <AudioRecorder handler={grabar} widthAudio="126px" />
            </div>

            <button
              className="btn-orange-gradient-linear-outline btn-lg"
              onClick={guardar}>
              Guardar
            </button>
          </div>
        ) : (
          <div>No hay frases</div>
        )}
      </div>
    </div>
  )
}

Pronounce.propTypes = {}

export default Pronounce
