import * as actions from './actionTypes'

const initialState = {
  domains: [
    {
      name: '',
      domain_id: null,
      description: '',
      inbox: [],
      actions: {
        ready: [],
        waiting: [],
        done: [],
      },
      reviews: {
        actions: [],
        srs: [],
      },
      loading: false,
    },
  ],
  domainsSelected: {
    name: '',
    domain_id: null,
    description: '',
    inbox: [],
    actions: {
      ready: [],
      waiting: [],
      done: [],
    },
    reviews: {
      actions: [],
      srs: [],
    },
  },
  active_domain_id: null, // Podemos usar esto en vez de tener ambos dominos seleccionados
  loading: false,
  errors: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ITEMS: {
      return {
        ...state,
        loading: true,
      }
    }

    case actions.FETCH_ITEMS_SUCCESS: {
      return {
        ...state,
        domains: action.items,
        domainsSelected: action.items[0] ? action.items[0] : null,
        loading: false,
      }
    }
    case actions.FETCH_ITEMS_FAILURE: {
      return {
        ...state,
        loading: false,
        errors: [...state.errors, action.error],
      }
    }
    case actions.SELECT_DOMAIN: {
      return {
        ...state,
        domainsSelected: action.domain,
      }
    }

    case actions.ADD_ITEM_TO_DOMAIN: {
      //   const {item} = action

      //Hacer la logica para agregar el item a domain corresoindiente
      //eliminar el item del domain anterior
      return {
        ...state,
      }
    }
    case actions.SET_ACTIVE_DOMAIN: {
      return {
        ...state,
        active_domain_id: action.active_domain_id,
      }
    }
    case actions.UPDATE_ITEM_SUCCESS: {
      return state
    }
    case actions.UPDATE_ITEM_FAILURE: {
      return state
    }
    case actions.DELETE_ITEM: {
      return state
    }
    case actions.DELETE_ITEM_FAILURE: {
      return state
    }

    default:
      return state
  }
}
